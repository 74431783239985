import React from 'react';
import PropTypes from 'prop-types';
import blue from '../../../../public/images/uberCategory/blue.svg';
import diamond from '../../../../public/images/uberCategory/diamond.svg';
import gold from '../../../../public/images/uberCategory/gold.svg';
import platinum from '../../../../public/images/uberCategory/platinum.svg';

const CategoryIcon = ({ category, height }) => {
  const auxLowerCase = category ? category.toLowerCase() : blue;
  switch (auxLowerCase) {
    case 'diamond':
      return <img src={diamond} alt="category" style={{ height }} />;
    case 'gold':
      return <img src={gold} alt="category" style={{ height }} />;
    case 'platinum':
      return <img src={platinum} alt="category" style={{ height }} />;
    default:
      return <img src={blue} alt="category" style={{ height }} />;
  }
};

CategoryIcon.propTypes = {
  category: PropTypes.string,
  height: PropTypes.string,
};

CategoryIcon.defaultProps = {
  category: '',
  height: '14px',
};

export default CategoryIcon;
