import { gql } from '@apollo/client';

const GET_COUPLES = {
  query: gql`
    query GetAssignations($offset: Int, $limit: Int, $driverId: String) {
      getCouples: getAssignations(offset: $offset, limit: $limit, driverId: $driverId) {
        instances {
          drivers {
            _id
            photo
            status {
              status
            }
            plan {
              selectedShifts {
                _id
                startTime
                endTime
                name
              }
            }
            info {
              firstname
              lastname
              phone
              code
            }
          }
          exchangeAddress {
            address
            lat
            lon
          }
          parkingAddress {
            address
            floor
            lat
            lon
            parkingSlot
          }
          car {
            info {
              brand
              model
              plate
            }
          }
        }
      }
    }
  `,
  policies: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

export default GET_COUPLES;
