export const formatterKm = new Intl.NumberFormat('es-CL', {
  style: 'decimal',
  unit: 'kilometer',
  currency: 'CLP',
});

export const formatterCLP = new Intl.NumberFormat('es-CL', {
  style: 'currency',
  currency: 'CLP',
});
