import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, TextField } from '@mui/material';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';

import POST_FORGOT from '../../../graphql/querys/forgotPassword';
import BackButton from '../../BackButton';
import SubmitButton from '../../SubmitButton';

const ForgotForm = ({ setFormView, setOpen, setContent, values, setValues }) => {
  const navigate = useNavigate();
  const [handlePageChange] = useOutletContext();

  const [forgotPassword, { error, loading, data }] = useLazyQuery(POST_FORGOT);
  const [loadingButton, setLoadingButton] = useState(false);

  useEffect(() => {
    handlePageChange();
  }, []);

  useEffect(() => {
    if (!loading) {
      if (data) {
        setFormView(true);
      }
      if (error) {
        setContent('email no registrado');
        setOpen(true);
        setLoadingButton(false);
      }
    }
  }, [loading]);

  const handleChangeValues = (name, event) => {
    setValues((currentValues) => ({
      ...currentValues,
      [name]: event,
    }));
  };

  const handleClick = () => {
    setLoadingButton(true);
    forgotPassword({ variables: { email: values.email.toLowerCase() } });
  };
  return (
    <Box
      display="flex"
      justifyContent="center"
      autoComplete="off"
      flexDirection="column"
      sx={{ gap: '10px' }}
    >
      <Box>
        <TextField
          size="small"
          autoComplete="off"
          sx={{ margin: 0 }}
          fullWidth
          name="email"
          label="Ingresa tu correo electrónico"
          variant="outlined"
          value={values.email}
          onChange={(e) => handleChangeValues('email', e.target.value)}
          inputProps={{
            style: {
              fontFamily: 'Poppins-Regular',
            },
          }}
          InputLabelProps={{
            style: {
              fontFamily: 'Poppins-Regular',
              fontSize: '14px',
            },
          }}
        />
      </Box>

      <SubmitButton
        handler={handleClick}
        text="Enviar correo electrónico"
        disabled={loadingButton}
      />

      <BackButton handler={() => navigate('/signin', { replace: true })} text="Atrás" />
    </Box>
  );
};

ForgotForm.propTypes = {
  setFormView: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
  setContent: PropTypes.func.isRequired,
  values: PropTypes.shape({ email: PropTypes.string }).isRequired,
  setValues: PropTypes.func.isRequired,
};

export default ForgotForm;
