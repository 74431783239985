import React, { useEffect, useState } from 'react';
import { Box, TextField, Divider } from '@mui/material';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { useOutletContext, useNavigate } from 'react-router-dom';
import { getAuthStore, logout } from '../../../graphql/store';
import { GetUberStatusRedirect } from '../../../helpers/url';
import URL_LINK_UP_UBER from '../../../graphql/querys/auth/urlLinkUpUber';
import LINK_UBER_EMAIL from '../../../graphql/mutations/uber/linkUberEmail';
import { validateEmail } from '../../../helpers/auxFunctions';
import UberButton from '../../../components/ui/UberButton';
import hideEmail from '../../../helpers/hideEmail';
import LoadingProgress from '../../../components/ui/LoadingProgress';
import SubmitButton from '../../../components/SubmitButton';

import OnBoardingTitle from '../../../components/extras/OnboardingTitle';

const LinkUberEmail = () => {
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);
  const navigate = useNavigate();
  const [handlePageChange] = useOutletContext();
  const [linking, setLinking] = useState('email');
  const [uberEmail, setUberEmail] = useState('');
  const [errorMail, setEmailError] = useState(false);
  const [linkUberEmailUber, { loading: loadingLinkUberEmail, data: dataLinkUberEmail, error }] =
    useMutation(LINK_UBER_EMAIL.mutation, LINK_UBER_EMAIL.policies);

  const { loading: loadingUrlLinkUp, data: dataUrlLinkUpUber } = useQuery(URL_LINK_UP_UBER.query, {
    variables: {
      redirectUrl: `${
        process.env.TUCAR_AUTH_SERVICE
      }/api/users/link-uber/${GetUberStatusRedirect()}`,
    },
  });

  useEffect(() => {
    if (error) {
      navigate('/app/onboarding', { replace: true });
    }
  }, [error]);

  useEffect(() => {
    if (dataLinkUberEmail) {
      setLinking('linking');
      handlePageChange();
    }
  }, [loadingUrlLinkUp, loadingLinkUberEmail]);

  const linkUberEmail = () => {
    if (validateEmail(uberEmail)) {
      setEmailError(false);
      linkUberEmailUber({ variables: { linkUberEmailId: uid, email: uberEmail } });
    } else {
      setEmailError(true);
    }
  };

  const changeFormEmail = (emailValue) => {
    setUberEmail(emailValue);
  };

  return loadingUrlLinkUp ? (
    <LoadingProgress />
  ) : (
    <Box>
      {linking === 'email' ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: '20px',
          }}
        >
          <OnBoardingTitle
            emojiSymbol="🚘"
            title="Vincula tu cuenta de Uber"
            subtitle="Ingresa el correo de tu cuenta en Uber para vincularla."
          />
          <Divider
            sx={{
              width: '100%',
              color: '#EAEAEA',
            }}
          />
          <TextField
            size="small"
            fullWidth
            sx={{ marginTop: '0px' }}
            required
            type="text"
            variant="outlined"
            label="Correo"
            defaultValue={uberEmail}
            error={errorMail}
            helperText={errorMail ? 'El correo ingresado no es válido.' : ''}
            name="Uber email"
            onChange={(e) => changeFormEmail(e.target.value.toLowerCase())}
            InputLabelProps={{ style: { fontFamily: 'Poppins-Regular' } }}
            InputProps={{
              style: { fontFamily: 'Poppins-Regular' },
            }}
          />
          <SubmitButton disabled={!uberEmail} handler={linkUberEmail} text="Continuar" />
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: '20px',
          }}
        >
          <OnBoardingTitle
            emojiSymbol="🚘"
            title="Vincula tu cuenta de Uber"
            subtitle={`Vincularemos tu cuenta Tucar con tu cuenta de Uber asociada al correo electrónico ${hideEmail(
              uberEmail,
            )}.`}
          />
          <Divider
            sx={{
              width: '100%',
              color: '#EAEAEA',
            }}
          />
          <UberButton
            href={dataUrlLinkUpUber.urlLinkUpUber}
            onClick={logout}
            content="Vincular cuenta"
          />
        </Box>
      )}
    </Box>
  );
};

LinkUberEmail.defaultProps = {
  changeStage: () => {},
};

export default LinkUberEmail;
