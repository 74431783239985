import React from 'react';

import { Box, Typography } from '@mui/material';
import { azulTucar } from '../../../themes/variants/TucarColors';
import { DefaultContent, downStepFunction, upStepFunction } from '../helpers';

export const emptyStep = {
  nextFunction: () => {},
  prevFunction: () => {},
  component: '',
  styleElement: {},
  ubication: { transform: 'translate(-50%, -50%)', top: '50% !important' },
  title: '',
  content: null,
};

const hideAllStyle = {
  profile: {
    color: 'rgba(0,0,0,.3) !important',
    background: 'rgba(0, 0, 0, .5)',
    height: '60px',
  },
  home: {
    background: 'rgba(0, 0, 0, .5)',
    height: '60px',
    color: 'rgba(0,0,0,.3)',
  },
  menu: {
    background: 'rgba(0, 0, 0, .5)',
    height: '60px',
    color: 'rgba(0,0,0,.3)',
  },
  bell: {
    background: 'rgba(0, 0, 0, .5)',
    height: '60px',
    color: 'rgba(0,0,0,.3)',
  },
  intercome: {
    background: 'rgba(0, 0, 0, .5)',
    height: '60px',
    color: 'rgba(0,0,0,.3)',
  },
};

const MenuSteps = {
  1: {
    nextFunction: upStepFunction,
    prevFunction: () => {},
    ubication: { transform: 'translate(-50%, -50%)', top: '50% !important' },
    styleElement: {
      ...hideAllStyle,
      profile: {},
    },
    title: 'Perfil',
    content: (
      <Box sx={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
        <Typography sx={{ fontFamily: 'Poppins-Regular', fontSize: '14px', textAlign: 'center' }}>
          En perfil encontrarás toda la información de tu cuenta.
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Poppins-Regular',
            fontSize: '14px',
            textAlign: 'center',
            color: azulTucar,
          }}
        >
          Recuerda agregar tu cuenta bancaria para poder depositar tus ganancias.
        </Typography>
      </Box>
    ),
  },
  2: {
    nextFunction: upStepFunction,
    prevFunction: downStepFunction,
    ubication: {
      color: 'rgba(0,0,0,.3)',
      transform: 'translate(-50%, -50%)',
      top: '50% !important',
    },
    styleElement: {
      ...hideAllStyle,
      home: {},
    },
    title: 'Inicio',
    content: (
      <Box sx={{ display: 'inline', gap: '10px', flexDirection: 'column' }}>
        <Typography
          sx={{
            color: '#333',
            fontFamily: 'Poppins-Regular',
            fontSize: '14px',
            textAlign: 'center',
          }}
        >
          En inicio podrás ver un
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Poppins-Regular',
            fontSize: '14px',
            textAlign: 'center',
            color: azulTucar,
          }}
        >
          resumen de tu semana actual de arriendo.
        </Typography>
      </Box>
    ),
  },
  3: {
    nextFunction: upStepFunction,
    prevFunction: downStepFunction,
    ubication: { transform: 'translate(-50%, -50%)', top: '50% !important' },
    styleElement: {
      ...hideAllStyle,
      menu: {},
    },
    title: 'Menú',
    content: (
      <DefaultContent
        textBlack="En el menú encontrarás toda la información de tu suscripción, vehículo, pagos, garantía,
      beneficios, resultado semanal y contacto con soporte."
      />
    ),
  },
  4: {
    nextFunction: upStepFunction,
    prevFunction: downStepFunction,
    ubication: { transform: 'translate(-50%, -50%)', top: '50% !important' },
    styleElement: {
      ...hideAllStyle,
      bell: {},
    },
    title: 'Notificaciones',
    content: (
      <Box sx={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
        <Typography sx={{ fontFamily: 'Poppins-Regular', fontSize: '14px', textAlign: 'center' }}>
          En notificaciones te iremos avisando cuando haya novedades con respecto a tu suscripción,
          como pagos pendientes, multas, reembolsos, entre otros.
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Poppins-Regular',
            fontSize: '14px',
            textAlign: 'center',
            color: azulTucar,
          }}
        >
          Recuerda tenerlas activas para que no se te pase nada.
        </Typography>
      </Box>
    ),
  },
  5: {
    nextFunction: upStepFunction,
    prevFunction: downStepFunction,
    redirectNext: '/app/tutorial/dashboard',
    ubication: { transform: 'translate(-50%, -50%)', top: '50% !important' },
    styleElement: {
      ...hideAllStyle,
      intercome: {},
    },
    title: 'Chatbot',
    content: (
      <DefaultContent textBlack="En el chatbot podrás resolver dudas rápidas de tu suscripción." />
    ),
  },
};

export default MenuSteps;
