import React, { useEffect } from 'react';
import moment from 'moment';
import { useReactiveVar } from '@apollo/client';
import { Box, Typography } from '@mui/material';

import CalendlyWidget from './calendlyWidget';
import { getDriverStore, getLeadStore } from '../../../graphql/store';
import { blancoTucar } from '../../../themes/variants/TucarColors';
import { setMeeting } from '../../../graphql/store/lead';
import LogOutSubmit from '../../../components/auth/SubmitLogOut';
import OnBoardingTitle from '../../../components/extras/OnboardingTitle';

function getOnboardingTitle(status, meeting) {
  switch (status) {
    case 'Not booked': {
      return (
        <OnBoardingTitle
          noLineal
          emojiSymbol="🗓️"
          title="¿Cuándo te gustaría venir por el auto?"
          subtitle="Elige el día y la hora y te recibiremos en nuestras instalaciones, ubicadas a pasos del metro Manquehue."
        />
      );
    }
    case 'Absent': {
      return (
        <OnBoardingTitle
          noLineal
          title="Reagenda tu retiro"
          subtitle="Por favor coordina nuevamente para poder seguir con el proceso"
        />
      );
    }
    case 'Booked': {
      return (
        <OnBoardingTitle
          noLineal
          emojiSymbol="🗓️"
          title="Retiro agendado"
          subtitle={`Te esperamos el día ${moment(meeting.start).format(
            'DD [de] MMMM [del] YYYY',
          )} a las ${moment(meeting.start).format(
            'LT',
          )} hrs en Alonso de Córdova 5895, esquina Edipo Rey, Las Condes.`}
        />
      );
    }
    case 'Rejected':
      return (
        <OnBoardingTitle
          noLineal
          title="Reagenda tu retiro"
          subtitle="Por favor coordina nuevamente para poder seguir con el proceso"
        />
      );

    default:
      return (
        <OnBoardingTitle
          noLineal
          title="Ha ocurrido un error"
          subtitle="Vuelva a intentar más tarde ó llame a soporte para notificarnos del problema."
        />
      );
  }
}

const OnBoardingMeeting = () => {
  const { info } = useReactiveVar(getDriverStore);
  const { meeting, calendarLink } = useReactiveVar(getLeadStore);
  useEffect(() => {
    if (meeting.status === 'Booked' && new Date(meeting.end) < Date.now()) {
      setMeeting({ status: 'Pending' });
    }
  }, [meeting]);

  switch (meeting.status) {
    case 'Absent':
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: '20px',
          }}
        >
          {getOnboardingTitle(meeting.status, meeting)}
          <Typography
            sx={{
              fontFamily: 'Poppins-Regular',
              fontSize: '14px',
              paddingInline: '20px',
              color: '#696969',
            }}
          >
            ¿No puedes venir?
          </Typography>
          <CalendlyWidget
            text="Cambiar fecha de retiro"
            info={info}
            url={`${calendarLink}`}
            color={blancoTucar}
          />
        </Box>
      );
    case 'Not booked':
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: '20px',
          }}
        >
          {getOnboardingTitle(meeting.status, meeting)}
          <CalendlyWidget info={info} url={`${calendarLink}`} color={blancoTucar} />
        </Box>
      );
    case 'Booked':
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: '20px',
          }}
        >
          {getOnboardingTitle(meeting.status, meeting)}
          <Typography
            sx={{
              fontFamily: 'Poppins-Regular',
              fontSize: '14px',
              paddingInline: '20px',
              color: '#696969',
            }}
          >
            ¿No puedes venir?
          </Typography>
          <CalendlyWidget
            text="Cambiar fecha de retiro"
            info={info}
            url={`${calendarLink}`}
            color={blancoTucar}
          />
        </Box>
      );
    case 'Rejected':
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: '20px',
          }}
        >
          {getOnboardingTitle(meeting.status, meeting)}

          <CalendlyWidget
            text="Cambiar fecha de retiro"
            info={info}
            url={`${calendarLink}`}
            color={blancoTucar}
          />
        </Box>
      );
    default:
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: '20px',
          }}
        >
          {getOnboardingTitle(meeting.status, meeting)}
          <LogOutSubmit />
        </Box>
      );
  }
};

export default OnBoardingMeeting;
