import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, LinearProgress, Typography } from '@mui/material';

import { passwordComplexity } from '../../../helpers/auxFunctions';

const displayMesage = (value) => {
  const messageArray = ['', 'Muy débil', 'Débil', 'Segura', 'Muy segura'];
  if (value < 4 && messageArray[value]) {
    return `La contraseña no es lo suficientemente segura.`;
  }
  if (messageArray[value]) {
    return messageArray[value];
  }
  return '';
};

export default function ComplexityBar({ password }) {
  const [value, setValue] = useState(0);
  const color = ['#cccccc', '#d32f2f', '#fab300', '#259ed7', '#67c85a'];
  const [auxMessage, setAuxMessage] = useState('');
  useEffect(() => {
    setValue(passwordComplexity(password));
  }, [password]);

  useEffect(() => {
    const msg = displayMesage(value);
    if (msg !== auxMessage) {
      setAuxMessage(msg);
    }
  }, [value]);

  return (
    <Box>
      <Box>
        <Typography
          sx={{
            fontFamily: 'Poppins-Regular',
            fontWeight: '300',
            fontSize: '12px',
          }}
        >
          Al menos 1 letra, 1 mayúscula, 1 símbolo y 1 número.
        </Typography>
        <Typography sx={{ fontFamily: 'Poppins-Regular', fontWeight: '300', fontSize: '12px' }}>
          Mínimo 8 carácteres.
        </Typography>
      </Box>
      <LinearProgress
        data-testid="progressBox"
        variant="determinate"
        value={value * 25}
        sx={{
          width: '100%',
          height: '3px',
          backgroundColor: '#cccccc',
          '& .MuiLinearProgress-barColorPrimary': {
            backgroundColor: `${color[value]}`,
          },
        }}
        valueBuffer={100}
      />
      <Box
        sx={{
          mt: '8px',
          fontFamily: 'Poppins-Regular',
          fontWeight: '300',
          fontSize: '12px',
          color: `${color[value]}`,
          textAlign: 'right',
        }}
      >
        {auxMessage}
      </Box>
    </Box>
  );
}

ComplexityBar.propTypes = {
  password: PropTypes.string,
};

ComplexityBar.defaultProps = {
  password: '',
};
