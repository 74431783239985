import React, { useState } from 'react';
import {
  Box,
  Collapse,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
} from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import TopBar from '../../../components/ui/TopNav';
import { getAuthStore, getDriverStore } from '../../../graphql/store';
import uberLogo from '../../../../public/images/Uber_blanco.svg';
import translateUberPro from '../../../helpers/translateUberPro';

const Apps = () => {
  const { data } = useReactiveVar(getAuthStore);
  const { category } = useReactiveVar(getDriverStore);
  const [open, setOpen] = useState(true);
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Box marginBottom="80px">
      <TopBar title="Apps" />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '20px',
          gap: '20px',
        }}
      >
        <Box sx={{ borderRadius: '7px', backgroundColor: '#000000' }}>
          <Stack
            paddingLeft="15px"
            paddingRight="4px"
            borderRadius={open ? '7px 7px 0px 0px' : '7px'}
            direction="row"
            alignItems="center"
            height="45px"
            justifyContent="space-between"
          >
            <img src={uberLogo} alt="uberLogo" />

            {open ? (
              <IconButton onClick={handleClick}>
                <KeyboardArrowUpOutlinedIcon fontSize="small" sx={{ color: '#FFFFFF' }} />
              </IconButton>
            ) : (
              <IconButton onClick={handleClick}>
                <KeyboardArrowDownOutlinedIcon fontSize="small" sx={{ color: '#FFFFFF' }} />
              </IconButton>
            )}
          </Stack>

          <Collapse
            sx={{
              borderRadius: open ? '0px 0px 7px 7px' : '7px',
            }}
            in={open}
            timeout="auto"
            unmountOnExit
          >
            <Box margin="10px 10px 0px 10px" sx={{ overflowX: 'scroll' }}>
              <Table size="small">
                <TableBody>
                  <TableRow colSpan={2}>
                    <TableCell
                      size="small"
                      sx={{
                        color: '#FFFFFF',
                        borderColor: 'white',
                        fontFamily: 'Poppins-Light',
                        lineHeight: 1,
                      }}
                      align="left"
                    >
                      Nombre de usuario
                    </TableCell>
                    <TableCell
                      size="small"
                      sx={{
                        color: '#FFFFFF',
                        borderColor: 'white',
                        fontFamily: 'Poppins-Light',
                        lineHeight: 1,
                      }}
                      align="right"
                    >
                      {data.firstname} {data.lastname}
                    </TableCell>
                  </TableRow>
                  <TableRow colSpan={2}>
                    <TableCell
                      size="small"
                      sx={{
                        color: '#FFFFFF',
                        borderColor: 'white',
                        fontFamily: 'Poppins-Light',
                        lineHeight: 1,
                      }}
                      align="left"
                    >
                      Categoría Uber Pro
                    </TableCell>
                    <TableCell
                      size="small"
                      sx={{
                        color: '#FFFFFF',
                        borderColor: 'white',
                        fontFamily: 'Poppins-Light',
                      }}
                      align="right"
                    >
                      Categoría {translateUberPro(category.uberPro)}
                    </TableCell>
                  </TableRow>
                  <TableRow colSpan={2}>
                    <TableCell
                      size="small"
                      sx={{
                        color: '#FFFFFF',
                        border: '0px',
                        fontFamily: 'Poppins-Light',
                        lineHeight: 1,
                      }}
                      align="left"
                    >
                      Correo electrónico
                    </TableCell>
                    <TableCell
                      size="small"
                      sx={{
                        color: '#FFFFFF',
                        border: '0px',
                        fontFamily: 'Poppins-Light',
                        lineHeight: 1,
                      }}
                      align="right"
                    >
                      {data.email}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
            <Box margin="10px 20px 0px 20px">
              <Button
                fullWidth
                type="submit"
                target="_blank"
                href="https://www.uber.com"
                sx={{
                  textAlign: 'center',
                  backgroundColor: 'white',
                  marginTop: '10px',
                  marginBottom: '20px',
                  height: '40px',
                  color: '#333333',
                  lineHeight: 1,
                  boxShadow: '0',
                  '&:hover': { backgroundColor: '#FFFFFFCC', color: '#333333' },
                }}
                variant="contained"
              >
                ir a la página de Uber
              </Button>
            </Box>
          </Collapse>
        </Box>
      </Box>
    </Box>
  );
};

export default Apps;
