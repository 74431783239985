import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Box, IconButton } from '@mui/material';
import Chart from 'react-apexcharts';
import moment from 'moment';
import { azulTucar } from '../../../themes/variants/TucarColors';

const GraphWeek = ({ date, typeGraph, billDays, handleDay, typeDetail }) => {
  const [dayArray, setDayArray] = useState([]);
  const [dataSeries, setDataSeries] = useState([]);
  const [clickDay, setClickDay] = useState('');
  const [fakeShow, setFakeShow] = useState([]);

  useEffect(() => {
    if (dataSeries) {
      setClickDay(dayArray[0]);
    }
  }, [dataSeries]);

  useEffect(() => {
    if (date && billDays) {
      const rev = billDays.slice(0).reverse();
      const days = [0, 1, 2, 3, 4, 5, 6].map((elem) => {
        return moment(date[0]).add(elem, 'd').format('DD');
      });
      setDayArray(days);
      const auxDays = rev.reduce(
        (acc, cur) => ({ ...acc, [moment(cur.date).format('DD')]: { ...cur } }),
        {},
      );

      if (typeGraph === 'interact' && typeDetail === 'expenses') {
        const auxTag = days.map((day) => {
          if (Object.keys(auxDays).includes(day)) {
            return auxDays[day].expenses.tag;
          }
          return 0;
        });

        const auxPlan = days.map((day) => {
          if (Object.keys(auxDays).includes(day)) {
            return auxDays[day].expenses.rent;
          }
          return 0;
        });

        const auxOther = days.map((day) => {
          if (Object.keys(auxDays).includes(day)) {
            return (
              auxDays[day].expenses.fines +
              auxDays[day].expenses.guarantee +
              auxDays[day].expenses.other +
              auxDays[day].expenses.penaltys
            );
          }
          return 0;
        });

        setDataSeries([
          {
            name: 'Plan',
            data: auxPlan,
            color: '#0057B8',
          },
          {
            name: 'TAG',
            data: auxTag,
            color: '#2FB0CD',
          },
          {
            name: 'Otros',
            data: auxOther,
            color: '#CACBCB',
          },
        ]);
      } else if (typeGraph === 'interact') {
        const auxTotalUber = days.map((day) => {
          if (Object.keys(auxDays).includes(day)) {
            return auxDays[day].incomes.uber;
          }
          return 0;
        });

        const auxTotalOther = days.map((day) => {
          if (Object.keys(auxDays).includes(day)) {
            return auxDays[day].incomes.other;
          }
          return 0;
        });
        setDataSeries([
          {
            name: 'Uber',
            data: auxTotalUber,
            color: '#000000',
          },
          {
            name: 'Reembolsos',
            data: auxTotalOther,
            color: '#0057B8',
          },
        ]);
      } else {
        const auxIncomes = days.map((day) => {
          if (Object.keys(auxDays).includes(day)) {
            return (
              auxDays[day].incomes.uber +
              auxDays[day].incomes.didi +
              auxDays[day].incomes.beat +
              auxDays[day].incomes.cabify +
              auxDays[day].incomes.other
            );
          }
          return 0;
        });

        const auxExpenses = days.map((day) => {
          if (Object.keys(auxDays).includes(day)) {
            return (
              auxDays[day].expenses.fines +
              auxDays[day].expenses.guarantee +
              auxDays[day].expenses.other +
              auxDays[day].expenses.penaltys +
              auxDays[day].expenses.rent +
              auxDays[day].expenses.tag
            );
          }
          return 0;
        });
        setDataSeries([
          {
            name: 'Recaudaciones',
            data: auxIncomes,
            color: '#0057B8',
          },
          {
            name: 'Descuentos',
            data: auxExpenses,
            color: '#999999',
          },
        ]);
      }
    }
  }, [date, billDays]);

  useEffect(() => {
    const auxFakeShow = dayArray.map((elem) => {
      return (
        <Box key={elem} sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <IconButton
            disableFocusRipple
            disableRipple
            disabled={typeGraph !== 'interact'}
            size="small"
            onClick={() => {
              setClickDay(elem);
              const delay = dayArray.indexOf(elem);
              handleDay(moment(date[0]).add(delay, 'd').format('DD/MM/YY'));
            }}
            sx={{
              width: '27px',
              height: '27px',
              margin: '0px',
              padding: '0px',
              fontSize: '12px',
              fontFamily: 'Poppins-Medium',
              color: elem === clickDay ? '#FFF' : '#000',
              backgroundColor: elem === clickDay ? azulTucar : '#FFF',
              '&:disabled': {
                backgroundColor: '#FFF',
                color: '#000',
              },
              '&:hover': {
                backgroundColor: elem === clickDay ? azulTucar : '#FFF',
                color: elem === clickDay ? '#FFF' : '#000',
              },
            }}
          >
            {elem}
          </IconButton>
        </Box>
      );
    });
    setFakeShow(auxFakeShow);
  }, [clickDay]);

  return (
    <Box>
      <Box
        id="my_dataviz"
        sx={{
          position: 'relative',
          left: '50%',
          marginBottom: '10px',
          transform: 'translateX(-50%)',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '162px',
            top: '0px',
            left: '50%',
            transform: 'translateX(-50%)',
            backgroundColor: '#F3F3F3',
            zindex: '1',
            boxShadow: 2,
            borderRadius: '15px',
          }}
        />

        <Chart
          type="bar"
          width="100%"
          height={180}
          series={dataSeries}
          options={{
            chart: {
              stacked: typeGraph === 'interact',
              toolbar: {
                show: false,
              },
            },
            noData: {
              text: 'No hay información para mostrar',
              align: 'center',
              verticalAlign: 'middle',
              offsetX: 0,
              offsetY: -25,
              style: {
                color: '#AAAAAA',
                fontSize: '14px',
                fontFamily: 'Poppins-Regular',
              },
            },
            states: {
              active: {
                filter: {
                  type: 'none' /* none, lighten, darken */,
                },
              },
            },
            tooltip: {
              enabled: false,
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '50%',
              },
            },
            stroke: {
              show: true,
              width: 1,
              colors: ['transparent'],
            },
            xaxis: {
              categories: dayArray,
              labels: {
                show: true,
                style: {
                  fontFamily: 'Poppins-Medium',
                  colors: 'transparent',
                },
              },
              axisTicks: {
                show: false,
              },
              axisBorder: {
                show: false,
              },
            },
            yaxis: {
              show: false,
            },
            legend: {
              fontFamily: 'Poppins-Regular',
              labels: { colors: '#AAAAAA' },
              position: 'top',
              markers: {
                radius: 55,
                height: '7px',
                width: '7px',
              },
              onItemClick: {
                toggleDataSeries: false,
              },
            },
            dataLabels: {
              enabled: false,
            },
            grid: {
              show: true,
              xaxis: {
                lines: {
                  show: false,
                },
              },
              yaxis: {
                lines: {
                  show: false,
                },
              },
            },
          }}
        />
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
            paddingInline: '1rem',
            position: 'absolute',
            width: '100%',
            transform: 'translateY(80px)',
          }}
        >
          {fakeShow.map((elem) => elem)}
        </Box>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
            paddingInline: '1rem',
            position: 'absolute',
            width: '100%',
            transform: 'translateY(105px)',
          }}
        >
          {['Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab', 'Dom'].map((elem) => {
            return (
              <IconButton
                size="small"
                disabled
                key={elem}
                sx={{
                  width: '100%',
                  height: '27px',
                  marginTop: '0px',
                  fontSize: '10px',
                  fontFamily: 'Poppins-Regular',
                  color: '#FFF',
                  '&:disabled': {
                    color: '#AAAAAA',
                  },
                }}
              >
                {elem}
              </IconButton>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

GraphWeek.propTypes = {
  typeGraph: PropTypes.string,
  billDays: PropTypes.arrayOf(PropTypes.shape({})),
  date: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleDay: PropTypes.func,
  typeDetail: PropTypes.string,
};

GraphWeek.defaultProps = {
  typeGraph: 'show',
  billDays: [],
  handleDay: () => {},
  typeDetail: 'incomes',
};

export default GraphWeek;
