import React from 'react';
import { Box } from '@mui/material';
import { Add, Cancel } from '@mui/icons-material';
import { format } from 'date-fns';

import TucarElement from '../../../components/info/TucarElement';
import { azulTucar } from '../../../themes/variants/TucarColors';

export function ticketIcon(subject) {
  switch (subject) {
    case 'Sinister':
      return Add;
    case 'Control':
      return Add;
    case 'Garage':
      return Add;
    case 'Exchange':
      return Add;
    case 'Settlement':
      return Add;
    case 'Support':
      return Add;
    case 'Others':
      return Add;
    default:
      return Cancel;
  }
}

export function convertContent(content) {
  return content.map((ticket) => {
    const auxPrimaryContent = {
      title: ticket.subject,
      resume: ticket.type,
      auxText: format(new Date(ticket.updatedAt), 'yyyy-MM-dd'),
    };
    return (
      <TucarElement
        key={`${ticket.updatedAt}`}
        variant="collapse"
        primaryContent={auxPrimaryContent}
        IconCard={ticketIcon(ticket.subject)}
        iconCardStyle={{ color: azulTucar, transform: 'rotate(-180deg)' }}
        secondaryContent={<Box>Contenido Ticker</Box>}
      />
    );
  });
}
