import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, IconButton } from '@mui/material';

import DirectionsCarFilledOutlinedIcon from '@mui/icons-material/DirectionsCarFilledOutlined';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';

import ProfilePhoto from '../../../../public/images/profileImage.svg';

const ParnerCarCard = ({ car, contactCard }) => {
  const { model, plate, photo, name, shiftName, phone } = contactCard;

  if (car) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
          <Typography
            sx={{
              lineHeight: 1,
              color: '#185db7',
              fontSize: '13px',
              fontFamily: 'Poppins-Medium',
            }}
          >
            Modelo auto:
          </Typography>
          <Typography sx={{ lineHeight: 1, fontFamily: 'Poppins-Regular', fontSize: '12px' }}>
            {model}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            <Typography
              sx={{
                lineHeight: 1,
                color: '#185db7',
                fontSize: '13px',
                fontFamily: 'Poppins-Medium',
              }}
            >
              Patente:
            </Typography>
            <Typography sx={{ lineHeight: 1, fontFamily: 'Poppins-Regular', fontSize: '12px' }}>
              {plate}
            </Typography>
          </Box>
          <DirectionsCarFilledOutlinedIcon sx={{ color: '#185db7', fontSize: '3rem' }} />
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'end', gap: '5px' }}>
        <img
          src={photo || ProfilePhoto}
          alt="profilePhoto"
          style={{
            objectFit: 'cover',
            height: '50px',
            width: '50px',
            borderRadius: '50px',
          }}
        />
        <IconButton
          href={`https://wa.me/${phone}`}
          sx={{
            backgroundColor: '#c1d5e9',
            '&:hover': {
              backgroundColor: '#c1d5e9',
            },
          }}
        >
          <SmsOutlinedIcon sx={{ color: '#185db7' }} />
        </IconButton>
        <IconButton
          href={`tel:${phone}`}
          sx={{
            backgroundColor: '#c1d5e9',
            '&:hover': {
              backgroundColor: '#c1d5e9',
            },
          }}
        >
          <LocalPhoneOutlinedIcon sx={{ color: '#185db7' }} />
        </IconButton>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
        <Typography
          sx={{
            lineHeight: 1,
            color: '#185db7',
            fontSize: '13px',
            fontFamily: 'Poppins-Medium',
          }}
        >
          {name}
        </Typography>
        <Typography sx={{ lineHeight: 1, fontFamily: 'Poppins-Regular', fontSize: '12px' }}>
          {shiftName}
        </Typography>
      </Box>
    </>
  );
};

ParnerCarCard.propTypes = {
  car: PropTypes.bool,
  contactCard: PropTypes.shape({
    model: PropTypes.string,
    plate: PropTypes.string,
    photo: PropTypes.string,
    name: PropTypes.string,
    shiftName: PropTypes.string,
    phone: PropTypes.string,
    code: PropTypes.string,
  }),
};

ParnerCarCard.defaultProps = {
  car: false,
  contactCard: {
    model: '',
    plate: '',
    photo: '',
    name: '',
    shiftName: '',
    phone: '',
    code: '',
  },
};

export default ParnerCarCard;
