import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, TextField } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import MultiSelectEditable from '../../../components/ui/MultiSelectEditable';
import { getAuthStore, getDriverStore, getGlobalStore, setLoading } from '../../../graphql/store';
import { rutValidator, formatRut } from '../../../helpers/rutValidador';
import SubmitButton from '../../../components/SubmitButton';

export default function InfoForm({ executeMutation }) {
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);
  const { info } = useReactiveVar(getDriverStore);
  const { loading } = useReactiveVar(getGlobalStore);
  const [loadingButton, setLoadingButton] = useState(false);
  const [values, setValues] = useState({
    ...info,
    source: info.source === 'Facebook' ? '' : info.source,
    skipUpdateSource: false,
    uid,
  });

  const isEmptyObject = (obj) => {
    const basicDataCheck = ['rut', 'gender', 'parking', 'source'];
    const aux = { ...obj };
    return basicDataCheck.every(
      (key) => aux[key] !== undefined && aux[key] !== null && aux[key] !== '',
    );
  };

  useEffect(() => {
    if (uid) {
      setValues({ ...values, uid });
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [uid]);

  const handleChange = (name, event) => {
    setValues((currentValues) => ({
      ...currentValues,
      [name]: event,
    }));
  };

  const handleClick = () => {
    setLoadingButton(true);
    executeMutation(values);
  };

  return (
    <Box fullwidth display="flex" justifyContent="center">
      <Box
        sx={{
          padding: '20px',
          width: '450px',
          maxWidth: '100vw',
        }}
      >
        {loading ? null : (
          <Box sx={{ paddingInline: '0px', display: 'flex', flexDirection: 'column', gap: '15px' }}>
            <TextField
              fullWidth
              required
              type="text"
              sx={{ margin: '0px', height: '50px' }}
              variant="outlined"
              id="RUT"
              label="RUT"
              value={values.rut}
              error={values.rut ? !rutValidator(values.rut) : false}
              helperText={values.rut && !rutValidator(values.rut) ? 'Rut inválido' : ''}
              name="rut"
              onChange={(e) => handleChange('rut', formatRut(e.target.value))}
              inputProps={{ style: { fontFamily: 'Poppins-Regular' } }}
              InputLabelProps={{ style: { fontFamily: 'Poppins-Regular' } }}
            />

            <MultiSelectEditable
              handleChange={handleChange}
              label="Género"
              name="gender"
              value={values.gender}
              dataArr={[
                { name: 'Femenino', value: 'female' },
                { name: 'Masculino', value: 'male' },
                { name: 'Prefiero no decir', value: 'other' },
              ]}
              containerStyle={{ margin: '0px' }}
            />
            <MultiSelectEditable
              handleChange={handleChange}
              label="Estacionamiento privado (no calle)"
              name="parking"
              value={values.parking}
              dataArr={[
                { name: 'Sí', value: '1' },
                { name: 'No', value: '0' },
              ]}
              containerStyle={{ margin: '0px' }}
            />

            <MultiSelectEditable
              handleChange={handleChange}
              label="¿Cómo te enteraste de Tucar?"
              name="source"
              value={values.source}
              dataArr={[
                { name: 'Facebook o Instagram', value: 'Facebook o Instagram' },
                { name: 'Google', value: 'Google' },
                { name: 'TikTok', value: 'TikTok' },
                { name: 'Uber', value: 'Uber' },
                { name: 'Código QR', value: 'Código QR' },
                { name: 'Un amigo, familiar o conocido', value: 'Un amigo, familiar o conocido' },
                {
                  name: 'Una empresa reclutadora me contactó',
                  value: 'Una empresa reclutadora me contactó',
                },
              ]}
              containerStyle={{ margin: '0px' }}
            />

            <SubmitButton
              disabled={loadingButton || !isEmptyObject(values) || !rutValidator(values.rut)}
              handler={handleClick}
              text="Enviar"
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}

InfoForm.propTypes = {
  executeMutation: PropTypes.func.isRequired,
};
