import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { Typography } from '@tucar/pit-stop';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import DetailCard from './detailCard';
import { getAuthStore } from '../../../../../graphql/store';

const dataFormatter = (data) => {
  const {
    incomes: { __typename, ...restIncomes },
    uberBreakdown: { cashCollected, tips, adjustment, toll, other, netFare, taxes, promotions },
  } = data;
  const totalIncomes = Object.values(restIncomes).reduce((accIn, current) => accIn + current, 0);

  return {
    adjustment: adjustment || 0,
    promotions: promotions || 0,
    gaining: netFare + taxes || 0,
    refund: toll + other || 0,
    tips: tips || 0,
    cashCollected: cashCollected || 0,
    incomeEvent: restIncomes?.other || 0,
    total: totalIncomes,
  };
};

const IncomeSection = ({ empty, data }) => {
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);

  const { adjustment, promotions, gaining, refund, tips, cashCollected, incomeEvent, total } = empty
    ? {}
    : dataFormatter(data, uid);

  return (
    <Box
      sx={{
        width: '100%',
        paddingTop: '20px',
        gap: '10px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '10px',
          alignItems: 'center',
          paddingBottom: '6px',
        }}
      >
        <TrendingUpIcon sx={{ color: '#7FB418', fontSize: '17px' }} />
        <Typography style={{ color: '#7FB418', fontWeight: 600, fontSize: '16px' }}>
          Ingresos
        </Typography>
      </Box>

      <DetailCard title="Ganancias por Uber" mode="incomes" total={gaining || 0} />
      <DetailCard title="Otras ganancias (Ajuste)" mode="incomes" total={adjustment || 0} />
      <DetailCard title="Promociones" mode="incomes" total={promotions || 0} />
      <DetailCard title="Propinas" mode="incomes" total={tips || 0} />
      <DetailCard title="Efectivo cobrado" mode="incomes" total={cashCollected || 0} negativeMode />
      <DetailCard title="Reembolsos" mode="incomes" total={refund || 0} />
      <DetailCard title="Abono por eventos" mode="incomes" total={incomeEvent || 0} />
      <DetailCard title="Total ingresos" mode="incomes" total={total || 0} remark />
    </Box>
  );
};

IncomeSection.propTypes = {
  empty: PropTypes.bool,
  data: PropTypes.shape({}),
};

IncomeSection.defaultProps = {
  empty: false,
  data: {},
};

export default IncomeSection;
