import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Icon, Typography } from '@tucar/pit-stop';
import { azulTucar } from '../../../themes/variants/TucarColors';
import { formatterCLP } from '../../../helpers/formatters';
import PressIconButton from '../../buttons/PressIconButton';

const IconInfo = ({ title, iconName, color, amount }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <PressIconButton size="30px">
        <Icon name={iconName} color={color} size="20px" />
      </PressIconButton>
      <Typography color="#5B5D71" style={{ fontSize: '13px' }}>
        {title}
      </Typography>
      <Typography color={azulTucar} style={{ fontSize: '14px', fontWeight: 600 }}>
        {formatterCLP.format(amount)}
      </Typography>
    </Box>
  );
};

IconInfo.propTypes = {
  title: PropTypes.string,
  iconName: PropTypes.string,
  color: PropTypes.string,
  amount: PropTypes.number,
};

IconInfo.defaultProps = {
  title: '',
  iconName: 'electricCarFilled',
  color: '#E81F00',
  amount: 0,
};

export default IconInfo;
