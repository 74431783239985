import { gql } from '@apollo/client';

const GET_NOTIFICATIONS = {
  query: gql`
    query GetDriverNotifications($driverId: ID!) {
      getDriverNotifications(driverId: $driverId) {
        _id
        message
        read
        sentAt
        type
      }
    }
  `,
  policies: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: 'true',
    context: {
      ignorePattern: {
        pattern: ['notificationApi error: No notifications found for driver'],
      },
    },
  },
};

export default GET_NOTIFICATIONS;
