import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider } from '@mui/material';
import LoadingProgress from './ui/LoadingProgress';
import OnBoardingTitle from './extras/OnboardingTitle';

const LoadingScreen = ({ content }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
      }}
    >
      <OnBoardingTitle title={content} showLogo />
      <Divider
        sx={{
          width: '100%',
          color: '#EAEAEA',
        }}
      />
      <LoadingProgress allScreen={false} />
    </Box>
  );
};

LoadingScreen.propTypes = {
  content: PropTypes.string,
};

LoadingScreen.defaultProps = {
  content: 'Cargando...',
};

export default LoadingScreen;
