import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Card } from '@tucar/pit-stop';
import { getDateList } from '../../../../../helpers/dateFormat';
import DateButton from '../../../../../components/buttons/DateButton';
import IncomeSection from './incomeSection';
import OutcomeSection from './outcomeSection';
import CuotasSection from './cuotasSection';
import getUniqueKey from '../../../../../helpers/customKey';

const ResumeCard = ({ initialDate, data, debt, prePay }) => {
  const dateList = getDateList(initialDate);
  const [selectedDate, setSelectedDate] = useState(new Date(initialDate).getDate());
  const { billDays } = data || { billDays: [] };

  const availableDatesObject = {};
  billDays.forEach((billDay) => {
    const day = new Date(billDay.date).getDate();
    availableDatesObject[day] = billDay;
  });

  return (
    <Card
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '26px 18px',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          alignItems: 'center',
          flexWrap: 'wrap',
          width: '100%',
        }}
      >
        {dateList.map((date) => (
          <DateButton
            key={getUniqueKey()}
            date={date}
            mark={selectedDate === date.getDate()}
            onClick={() => setSelectedDate(date.getDate())}
          />
        ))}
      </Box>

      {Object.keys(availableDatesObject).includes(`${selectedDate}`) ? (
        <>
          <IncomeSection key={getUniqueKey()} data={availableDatesObject[selectedDate]} />
          <OutcomeSection key={getUniqueKey()} data={availableDatesObject[selectedDate]} />
          <CuotasSection
            key={getUniqueKey()}
            prePay={prePay}
            debt={debt}
            data={availableDatesObject[selectedDate]}
          />
        </>
      ) : (
        <>
          <IncomeSection key={getUniqueKey()} empty />
          <OutcomeSection key={getUniqueKey()} empty />
          <CuotasSection key={getUniqueKey()} empty />
        </>
      )}
    </Card>
  );
};

ResumeCard.propTypes = {
  initialDate: PropTypes.string,
  debt: PropTypes.number,
  prePay: PropTypes.number,
  data: PropTypes.shape({
    income: PropTypes.number,
    outcome: PropTypes.number,
    total: PropTypes.number,
    billDays: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

ResumeCard.defaultProps = {
  initialDate: '',
  debt: 0,
  prePay: 0,
  data: {
    income: 0,
    outcome: 0,
    total: 0,
    billDays: [],
  },
};

export default ResumeCard;
