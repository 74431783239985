import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useReactiveVar, useQuery } from '@apollo/client';
import { useOutletContext } from 'react-router-dom';
import { Loader, TucarTheme, Typography } from '@tucar/pit-stop';

import { getAuthStore } from '../../../../graphql/store';
import NotiBox from './NotiBox';
import GET_NOTIFICATIONS from '../../../../graphql/querys/notifications/getNotifications';
import TopNav2 from '../../../../components/ui/TopNav/newTopNav';
import checkBubble from '../../../../../public/images/bubbleIconsPng/check.png';

const translateRedirect = (type) => {
  switch (type) {
    case 'bill':
      return '/app/v2/liquidations';
    case 'exchange':
      return '/app/manageshift';
    case 'uberGoal':
    case 'info':
    case 'warning':
    default:
      return '/app/v2/notifications';
  }
};

const NewNotifications = () => {
  const [handlePageChange] = useOutletContext();

  useEffect(() => {
    handlePageChange(null, true);
  }, []);

  const [content, setContent] = useState([]);
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);

  const { loading, data, refetch } = useQuery(GET_NOTIFICATIONS.query, {
    ...GET_NOTIFICATIONS.policies,
    variables: {
      driverId: uid,
    },
  });

  useEffect(() => {
    if (!loading && data?.getDriverNotifications) {
      const aux = data.getDriverNotifications.map((elem) => {
        const { _id: elemId, message, type, sentAt, read } = elem;
        return (
          <NotiBox
            refetch={refetch}
            key={elemId}
            text={message}
            redirect={translateRedirect(type)}
            time={sentAt}
            read={read}
            notificationId={elemId}
          />
        );
      });
      setContent(aux);
    }
  }, [loading]);

  if (loading) {
    return (
      <>
        <TopNav2 title="Notificaciones" />
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        >
          <Loader size={32} pointRadio="4px" />
        </Box>
      </>
    );
  }

  return (
    <>
      <TopNav2 title="Notificaciones" />

      <Box sx={{ height: '100%' }}>
        {content.length > 0 ? (
          content
        ) : (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              gap: '73px',
              marginTop: '10%',
            }}
          >
            <img alt="checkBubble" src={checkBubble} width="206px" height="172px" />
            <Box>
              <Typography
                variant="p"
                weight="medium"
                align="center"
                style={{ fontSize: '16px', color: TucarTheme.colors.blue500, marginBottom: '14px' }}
              >
                ¡Nada por ahora!
              </Typography>
              <Box>
                <Typography align="center" variant="p" weight="light" style={{ fontSize: '14px' }}>
                  No hay notificaciones pendientes.
                </Typography>
                <Typography align="center" variant="p" weight="light" style={{ fontSize: '14px' }}>
                  ¡Todo en orden!
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default NewNotifications;
