import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card } from '@tucar/pit-stop';
import { Typography, Box } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { capitalizeDate, dateComparation, getDateList } from '../../../../helpers/dateFormat';
import { getAuthStore } from '../../../../graphql/store';
import getUniqueKey from '../../../../helpers/customKey';
import DateButton from './dateButton';
import UserShiftCard from './userShiftCard';
import { getColorShift } from '../helpers';

const ShiftCard = ({ driversData }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);
  const partner = driversData.find(({ _id: ident }) => ident !== uid);
  const current = driversData.find(({ _id: ident }) => ident === uid);
  const todayDate = (selectedDate.getDay() + 6) % 7;
  const partnerShift = partner?.plan.selectedShifts[todayDate];
  const currentShift = current?.plan.selectedShifts[todayDate];

  const userContent = () => {
    const isPartnerAMorFull = ['AM', 'ALL'].includes(partnerShift?.type);
    const userShiftCards = isPartnerAMorFull
      ? [
          { photo: partner?.photo, shift: partnerShift },
          { photo: current?.photo, shift: currentShift },
        ]
      : [
          { photo: current?.photo, shift: currentShift },
          { photo: partner?.photo, shift: partnerShift },
        ];

    return (
      <>
        {userShiftCards.map(({ photo, shift }) => (
          <UserShiftCard
            key={getUniqueKey()}
            photoImg={photo}
            shift={shift}
            color={getColorShift(shift.type)}
          />
        ))}
      </>
    );
  };

  return (
    <Card style={{ padding: '20px 0px' }}>
      <Typography
        style={{
          color: '#5B5D71',
          fontSize: '16px',
          fontWeight: 600,
        }}
      >
        Turno actual - {capitalizeDate(new Date(), 'MMMM yyyy')}
      </Typography>

      <Box
        sx={{
          paddingTop: '14px',
          paddingBottom: '27px',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-evenly',
        }}
      >
        {getDateList(new Date()).map((date) => {
          return (
            <DateButton
              key={getUniqueKey()}
              date={date}
              mark={dateComparation(selectedDate, date)}
              onClick={() => setSelectedDate(date)}
            />
          );
        })}
      </Box>

      <Box
        sx={{
          width: '100%',
          paddingInline: '20px',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
        }}
      >
        {userContent()}
      </Box>

      <Box
        sx={{
          paddingTop: '20px',
          paddingInline: '20px',
          display: 'flex',
          flexDirection: 'row',
          gap: '20px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '10px',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              background: getColorShift(currentShift.type),
              width: '10px',
              height: '10px',
              borderRadius: '100%',
            }}
          />
          <Typography>Tu</Typography>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
          <Box
            sx={{
              background: getColorShift(partnerShift.type),
              width: '10px',
              height: '10px',
              borderRadius: '100%',
            }}
          />
          <Typography>Compañero</Typography>
        </Box>
      </Box>
    </Card>
  );
};

ShiftCard.propTypes = {
  driversData: PropTypes.arrayOf(PropTypes.shape({})),
};

ShiftCard.defaultProps = {
  driversData: {},
};

export default ShiftCard;
