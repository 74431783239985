import React from 'react';
import { Button, Card } from '@tucar/pit-stop';
import { Typography, Box } from '@mui/material';
import ProfilePhoto from '../../../../../../public/images/profileImage.svg';
import { azulTucar } from '../../../../../themes/variants/TucarColors';

const ParnerCard = () => {
  const phone = '912345678';
  return (
    <Card
      style={{
        padding: '20px 30px',
        gap: '16px',
        alignItems: 'center',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px', width: '100%' }}>
        <img
          src={ProfilePhoto}
          alt="profilePhoto"
          style={{
            objectFit: 'cover',
            height: '58px',
            width: '58px',
            borderRadius: '50%',
            border: `3px solid ${azulTucar}`,
          }}
        />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: '10px',
          }}
        >
          <Typography
            sx={{ color: '#2D2F39', fontSize: '16px', fontWeight: 500, paddingBottom: '6px' }}
          >
            Tu compañero
          </Typography>
          <Typography
            sx={{
              color: azulTucar,
              fontSize: '18px',
              fontWeight: 600,
              paddingBottom: '12px',
              lineHeight: 1.2,
            }}
          >
            John Doe
          </Typography>

          <Typography
            sx={{ color: '#5B5D71', fontSize: '14px', fontWeight: 500, paddingBottom: '4px' }}
          >
            Número de teléfono
          </Typography>
          <Typography sx={{ color: azulTucar, fontSize: '16px', fontWeight: 500 }}>
            +56 {phone.slice(0, 1)} {phone.slice(1, 5)} {phone.slice(5)}
          </Typography>
        </Box>
      </Box>
      <Button>Contactar</Button>
    </Card>
  );
};

export default ParnerCard;
