import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import TopNavTutorial from '../components/topNavTutorial';
import CurrentShiftCard from './components/currentShiftCard';
import CreatePetitionButton from './components/createPetitionButton';
import ParnerCard from './components/partnerCard';
import CalendarStep from './steps/calendarStep';
import PartnerStep from './steps/partnerStep';
import CreationStep from './steps/creationStep';

const BasePetitions = ({ step, onNext }) => {
  return (
    <Box>
      <TopNavTutorial title="Turno compartido" />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '30px', paddingInline: '27px' }}>
        {step === 'calendar' && <CalendarStep onNext={onNext} />}
        <CurrentShiftCard />
        {step === 'creation' && <CreationStep onNext={onNext} />}
        <CreatePetitionButton />
        {step === 'partner' && <PartnerStep onNext={onNext} />}
        <ParnerCard />
      </Box>
    </Box>
  );
};

BasePetitions.propTypes = {
  step: PropTypes.string,
  onNext: PropTypes.func,
};

BasePetitions.defaultProps = {
  step: '',
  onNext: () => {},
};

export default BasePetitions;
