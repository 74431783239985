import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Box } from '@mui/material';
import { useQuery } from '@apollo/client';
import { Button, Loader } from '@tucar/pit-stop';
import TopNav2 from '../../../../components/ui/TopNav/newTopNav';
import BalanceLiquidation from '../detailLiquidation';
import GET_DRIVER_BILLWEEK_LIMIT_DATES from '../../../../graphql/querys/balance/getSpecificBillDay';
import GainingCard from './gainingCard';
import TotalCard from './totalCard';
import ResumeCard from './resumeCard';
import GainingView from '../../../app/balance/NewVersion';

const ResumeDetail = ({ uid, dateParam, debtParam, prePayParam, goBack }) => {
  const [screenDetail, setScreenDetail] = useState('resume');
  const initialDatetime = moment(dateParam, 'DD/MM/YYYY')
    .subtract(7, 'days')
    .format('YYYY-MM-DD[T00:00:00]');

  const finalDatetime = moment(dateParam, 'DD/MM/YYYY')
    .subtract(1, 'days')
    .format('YYYY-MM-DD[T00:00:00]');

  const { data, loading } = useQuery(GET_DRIVER_BILLWEEK_LIMIT_DATES.query, {
    ...GET_DRIVER_BILLWEEK_LIMIT_DATES.policies,
    variables: {
      uid,
      initialDatetime,
      finalDatetime,
    },
  });
  const [info, setInfo] = useState({
    incomes: 0,
    outcomes: 0,
    total: 0,
    cuotas: 0,
    billDays: [],
  });

  useEffect(() => {
    if (data?.getDriverBillDaysLimitDates) {
      const auxData = data.getDriverBillDaysLimitDates;

      const incomeTotals = auxData.reduce((acc, curr) => {
        const {
          incomes: { __typename, ...incomes },
        } = curr;
        return acc + Object.values(incomes).reduce((sumAcc, sumCurr) => sumAcc + sumCurr, 0);
      }, 0);
      const outcomeTotals = auxData.reduce((acc, curr) => {
        const {
          expenses: { __typename, guarantee, ...expenses },
        } = curr;
        return acc + Object.values(expenses).reduce((sumAcc, sumCurr) => sumAcc + sumCurr, 0);
      }, 0);
      const guaranteeTotals = auxData.reduce((acc, curr) => {
        const {
          expenses: { guarantee },
        } = curr;
        return acc + guarantee;
      }, 0);
      const installmentTotals = auxData.reduce((acc, curr) => acc + curr.installment, 0);

      setInfo({
        cuotas: installmentTotals + debtParam - prePayParam + guaranteeTotals,
        incomes: incomeTotals,
        outcomes: outcomeTotals,
        total:
          incomeTotals -
          outcomeTotals +
          prePayParam -
          debtParam -
          installmentTotals -
          guaranteeTotals,
        billDays: auxData,
      });
    }
  }, [data]);

  if (loading) {
    return (
      <>
        <TopNav2 title="Liquidaciones" handlePageChange={goBack} prevPage="/app/v2/liquidations" />
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Loader size={32} pointRadio="4px" />
        </Box>
      </>
    );
  }

  if (screenDetail === 'gaining') {
    return (
      <Box>
        <GainingView
          handlePageChangeNav={() => setScreenDetail('resume')}
          liquidationMode
          dataDate={new Date(moment(initialDatetime, 'YYYY-MM-DD[T00:00:00]'))}
        />
      </Box>
    );
  }

  if (screenDetail === 'table') {
    return (
      <Box>
        <TopNav2
          backgroundColor="#003066"
          title="Liquidaciones"
          handlePageChange={() => setScreenDetail('resume')}
          prevPage="/app/v2/liquidations"
          color="#fff"
        />
        <BalanceLiquidation
          uid={uid}
          dateParam={dateParam}
          debtParam={debtParam}
          prePayParam={prePayParam}
        />
      </Box>
    );
  }

  return (
    <Box sx={{ paddingBottom: '80px' }}>
      <TopNav2 title="Liquidaciones" handlePageChange={goBack} prevPage="/app/v2/liquidations" />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingInline: '30px',
          gap: '20px',
        }}
      >
        <GainingCard
          incomes={info.incomes}
          outcomes={info.outcomes}
          cuotas={info.cuotas}
          onClick={() => setScreenDetail('gaining')}
        />
        <TotalCard total={info.total} />
        <ResumeCard
          prePay={prePayParam}
          debt={debtParam}
          initialDate={initialDatetime}
          data={info}
        />

        <Button style={{ marginTop: '10px' }} size="small" onClick={() => setScreenDetail('table')}>
          Ver liquidación completa
        </Button>
      </Box>
    </Box>
  );
};

ResumeDetail.propTypes = {
  uid: PropTypes.string,
  dateParam: PropTypes.string,
  debtParam: PropTypes.number,
  prePayParam: PropTypes.number,
  goBack: PropTypes.func,
};

ResumeDetail.defaultProps = {
  uid: '',
  dateParam: new Date(),
  debtParam: 0,
  prePayParam: 0,
  goBack: () => {},
};

export default ResumeDetail;
