import React from 'react';
import { IconButton } from '@mui/material';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import PropTypes from 'prop-types';

const OpenIconButton = ({ open, handleClick }) => {
  return open ? (
    <IconButton
      onClick={handleClick}
      sx={{
        paddingRight: '0px',
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
      }}
    >
      <KeyboardArrowUpOutlinedIcon sx={{ margin: '0px', fontSize: '12px', color: '#333333' }} />
    </IconButton>
  ) : (
    <IconButton
      onClick={handleClick}
      sx={{
        paddingRight: '0px',
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
      }}
    >
      <KeyboardArrowDownOutlinedIcon
        sx={{
          margin: '0px',
          verticalAlign: 'start',
          fontSize: '12px',
          color: '#333333',
        }}
      />
    </IconButton>
  );
};

OpenIconButton.propTypes = {
  open: PropTypes.bool,
  handleClick: PropTypes.func,
};

OpenIconButton.defaultProps = {
  open: false,
  handleClick: () => {},
};

export default OpenIconButton;
