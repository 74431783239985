import React from 'react';
import PropTypes from 'prop-types';
import { Card, Typography } from '@tucar/pit-stop';

const StatusCard = ({ blocked, blockedBy }) => {
  if (blockedBy !== 'driver' && blocked) {
    return (
      <Card
        style={{
          background: '#FFE0DB',
          padding: '18px 20px',
          display: 'flex',
          flexDirection: 'row',
          gap: '12px',
          alignItems: 'center',
          justifyContent: 'start',
        }}
      >
        <Typography
          style={{
            color: '#FF2300',
            fontSize: '13px',
            fontWeight: 600,
            lineHeight: 1.2,
          }}
        >
          Estado actual:
        </Typography>
        <Typography
          style={{
            color: '#2D2F39',
            fontSize: '13px',
            fontWeight: 500,
            lineHeight: 1.2,
          }}
        >
          Bloqueado
        </Typography>
      </Card>
    );
  }

  return (
    <Card
      style={{
        background: blocked ? '#CCE4FF' : '#F9FDF1',
        padding: '18px 20px',
        display: 'flex',
        flexDirection: 'row',
        gap: '12px',
        alignItems: 'center',
        justifyContent: 'start',
      }}
    >
      <Typography
        style={{
          color: blocked ? '#1F89FF' : '#7FB418',
          fontSize: '13px',
          fontWeight: 600,
          lineHeight: 1.2,
        }}
      >
        Estado actual:
      </Typography>
      <Typography
        style={{
          color: '#2D2F39',
          fontSize: '13px',
          fontWeight: 500,
          lineHeight: 1.2,
        }}
      >
        {blocked ? 'Desactivado' : 'Aceptando viajes en efectivo'}
      </Typography>
    </Card>
  );
};

StatusCard.propTypes = {
  blocked: PropTypes.bool,
  blockedBy: PropTypes.string,
};

StatusCard.defaultProps = {
  blocked: false,
  blockedBy: '',
};

export default StatusCard;
