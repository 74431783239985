import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Typography, GroupButton } from '@tucar/pit-stop';
import { Box } from '@mui/material';
import { capitalizeDate, getAddDate, getDateList } from '../../../../helpers/dateFormat';
import getUniqueKey from '../../../../helpers/customKey';
import { translateShiftsByDay, complementConfig } from '../../../app/petitions/petitionsAuxs';
import ConfirmationView from '../confirmationView';
import TopNav2 from '../../../../components/ui/TopNav/newTopNav';
import SuccessScreen from '../auxScreens/successScreen';

const shiftTypeToObj = (shiftList) =>
  shiftList.map(({ type }) => {
    const shiftMap = {
      AM: { am: true, pm: false },
      PM: { am: false, pm: true },
      ALL: { am: true, pm: true },
    };
    return shiftMap[type] || { am: false, pm: false };
  });

const CreationPetitions = ({ shiftOptions, goBack, currentShift, uid, partnerUid }) => {
  const [config, setConfig] = useState(Array(7).fill({ am: false, pm: false }));
  const [errorSelection, setErrorSelection] = useState(Array(7).fill(false));
  const [auxView, setAuxView] = useState('general');
  const [mutationData, setMutationData] = useState({ petitioner: '', participants: [] });

  useEffect(() => {
    if (currentShift.length > 0) {
      const auxCurrentShift = shiftTypeToObj(currentShift);
      setConfig(auxCurrentShift);
    }
  }, [currentShift]);

  const setConfigHandle = async (index, shift) => {
    setErrorSelection((prevError) => {
      const newError = [...prevError];
      newError[index] = false;
      return newError;
    });
    setConfig((prevConfig) => {
      const newConfig = [...prevConfig];
      newConfig[index] = { ...newConfig[index], [shift]: !newConfig[index][shift] };
      return newConfig;
    });
  };

  const setColor = (index) => {
    if (errorSelection[index]) {
      return '#FF0000';
    }
    const { am, pm } = config[index];
    return am && pm ? '#95D21C' : null;
  };

  const sendPetition = async () => {
    const auxFutureShifts = translateShiftsByDay(config, shiftOptions);
    const auxComplementShifts = translateShiftsByDay(complementConfig(config), shiftOptions);

    if (Object.values(auxFutureShifts).includes(undefined)) {
      const errorAux = Object.values(auxFutureShifts).map((elem) => {
        return !elem;
      });

      await setErrorSelection(errorAux);
    } else if (Object.values(auxComplementShifts).includes(undefined)) {
      const errorAux = Object.values(auxComplementShifts).map((elem) => {
        return !elem;
      });

      await setErrorSelection(errorAux);
    } else {
      setAuxView('confirmation');
      setMutationData({
        petitioner: uid,
        participants: [
          { id: uid, futureShifts: auxFutureShifts },
          { id: partnerUid, futureShifts: auxComplementShifts },
        ],
      });
    }
  };

  if (auxView === 'success') {
    return <SuccessScreen goBack={goBack} />;
  }

  if (auxView === 'confirmation') {
    return (
      <>
        <TopNav2
          title="Confirmación"
          handlePageChange={() => setAuxView('general')}
          prevPage="/app/v2/petitions"
        />

        <ConfirmationView
          mutationData={mutationData}
          currentShift={currentShift}
          futureConfig={config}
          shiftOptions={shiftOptions}
          goBack={() => setAuxView('general')}
          onSuccess={() => setAuxView('success')}
        />
      </>
    );
  }

  return (
    <>
      <TopNav2 title="Solicitud de cambio" handlePageChange={goBack} prevPage="/app/v2/petitions" />
      <Box
        sx={{
          marginBottom: '80px',
          display: 'flex',
          flexDirection: 'column',
          gap: '30px',
          paddingInline: '37px',
        }}
      >
        <Typography
          style={{
            color: '#5B5D71',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '1.2',
            textAlign: 'center',
          }}
        >
          Selecciona los turnos que te gustaría trabajar
        </Typography>
        <Card style={{ padding: '26px 60px' }}>
          <Typography
            style={{
              color: '#1F89FF',
              lineHeight: '1.2',
              fontSize: '14px',
              fontWeight: 400,
              paddingBottom: '5px',
            }}
          >
            Periodo
          </Typography>
          <Typography
            style={{
              color: '#1F89FF',
              lineHeight: '1.2',
              fontSize: '14px',
              fontWeight: 500,
            }}
          >
            {capitalizeDate(new Date(), 'DD/MM/YYYY')} al {getAddDate(new Date(), 'DD/MM/YYYY', 6)}
          </Typography>
          <Box
            sx={{
              paddingTop: '20px',
              display: 'flex',
              flexDirection: 'column',
              gap: '7px',
              width: '100%',
            }}
          >
            {getDateList(new Date()).map((date, index) => {
              const { am, pm } = config[index];
              const isToday = date.getDate() === new Date().getDate();
              return (
                <Box
                  key={getUniqueKey()}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Box
                      sx={{
                        width: '6px',
                        height: '6px',
                        borderRadius: '14px',
                        backgroundColor: isToday ? '#1F89FF' : 'transparent',
                      }}
                    />
                    <Typography
                      style={{
                        lineHeight: 1.2,
                        color: isToday ? '#1F89FF' : '#2D2F39',
                        fontSize: '18px',
                        fontWeight: 600,
                      }}
                    >
                      {capitalizeDate(date, 'DD')}
                    </Typography>
                    <Typography
                      style={{
                        lineHeight: 1.2,
                        color: isToday ? '#1F89FF' : '#5B5D71',
                        fontSize: '14px',
                        fontWeight: 500,
                      }}
                    >
                      {capitalizeDate(date, 'ddd').replace('.', '')}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      position: 'relative',
                      zIndex: 1,
                    }}
                  >
                    <GroupButton
                      disabled={date.getDate() === new Date().getDate()}
                      padding="10px 14px"
                      data={[
                        {
                          label: 'AM',
                          active: am,
                          color: setColor(index),
                          onClick: () => setConfigHandle(index, 'am'),
                        },
                        {
                          label: 'PM',
                          active: pm,
                          color: setColor(index),
                          onClick: () => setConfigHandle(index, 'pm'),
                        },
                      ]}
                    />
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Card>
        <Box
          sx={{
            display: 'flex',
            flexAlign: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            gap: ' 20px',
          }}
        >
          <Button size="small" variant="ghost" onClick={goBack}>
            Cancelar
          </Button>
          <Button size="small" onClick={sendPetition}>
            Siguiente
          </Button>
        </Box>
      </Box>
    </>
  );
};

CreationPetitions.propTypes = {
  shiftOptions: PropTypes.shape({}),
  uid: PropTypes.string,
  partnerUid: PropTypes.string,
  goBack: PropTypes.func,
  currentShift: PropTypes.arrayOf(PropTypes.shape({})),
};

CreationPetitions.defaultProps = {
  uid: '',
  partnerUid: '',
  shiftOptions: {},
  currentShift: [],
  goBack: () => {},
};

export default CreationPetitions;
