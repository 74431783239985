import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Icon, Button, Card, Typography } from '@tucar/pit-stop';
import { Box } from '@mui/material';
import { capitalizeDate } from '../../../../helpers/dateFormat';

const PendientPetition = ({ show, data }) => {
  const { imPetitioner, expiration } = data || {};
  const navigate = useNavigate();

  if (!show) return null;

  if (imPetitioner)
    return (
      <Card style={{ padding: '32px', gap: '32px' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '22px',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              border: '1px solid rgba(102, 174, 255, 0.4)',
              background: 'rgba(102, 174, 255, 0.4)',
              borderRadius: '9px',
              padding: '6px',
              boxShadow:
                '-4px -4px 9px 0px rgba(255, 255, 255, 0.88) inset, 44px 4px 10px 0px rgba(0, 87, 184, 0.30) inset',
            }}
          >
            <Icon name="addAlertFilled" size="24px" color="#0057B8" />
          </Box>
          <Typography
            style={{ color: '#0057B8', fontWeight: 500, lineHeight: 1.2, fontSize: '16px' }}
          >
            Solicitud enviada
          </Typography>
        </Box>
        <Typography
          style={{
            color: '#5B5D71',
            fontWeight: 400,
            lineHeight: 1.2,
            fontSize: '14px',
            textAlign: 'center',
          }}
        >
          Hemos enviado la solicitud de cambio de turno a tu compañero. Pronto recibirás su
          respuesta.
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            size="small"
            onClick={() => navigate('/app/v2/petitions/current', { replace: true })}
          >
            Ver solicitud
          </Button>
        </Box>
      </Card>
    );

  return (
    <Card style={{ padding: '32px', gap: '32px' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '22px',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            border: '1px solid rgba(254, 193, 51, 0.4)',
            background:
              'linear-gradient(130deg, rgba(254, 193, 51, 0.6), rgba(255, 226, 168, 0.6))',
            borderRadius: '9px',
            padding: '6px',
          }}
        >
          <Icon name="warningRounded" size="24px" color="#FFBB00" />
        </Box>
        <Typography
          style={{ color: '#FFBB00', fontWeight: 500, lineHeight: 1.2, fontSize: '16px' }}
        >
          Solicitud de cambio
        </Typography>
      </Box>
      <Typography
        style={{
          color: '#5B5D71',
          fontWeight: 400,
          lineHeight: 1.2,
          fontSize: '14px',
          textAlign: 'center',
        }}
      >
        Tu compañero ha solicitado un cambio de turno.
      </Typography>
      <Typography
        style={{
          color: '#5B5D71',
          fontWeight: 400,
          lineHeight: 1.2,
          fontSize: '14px',
          textAlign: 'center',
        }}
      >
        Tienes hasta el {capitalizeDate(expiration, 'DD/MM/YYYY')} para responder esta solicitud.
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Button
          size="small"
          onClick={() => navigate('/app/v2/petitions/current', { replace: true })}
        >
          Ver solicitud
        </Button>
      </Box>
    </Card>
  );
};

PendientPetition.propTypes = {
  show: PropTypes.bool,
  data: PropTypes.shape({
    imPetitioner: PropTypes.bool,
    expiration: PropTypes.string,
  }),
};

PendientPetition.defaultProps = {
  show: false,
  data: {
    imPetitioner: false,
    expiration: '',
  },
};

export default PendientPetition;
