import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, TextField, Typography, Checkbox, FormControlLabel } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import PhotoCar from './PhotoCar';
import { verdeText } from '../../../../../themes/variants/TucarColors';
import { getAuthStore } from '../../../../../graphql/store';

const auxDamageList = [
  'Abolladura',
  'Rayón',
  'Vidrio roto',
  'Neumáticos',
  'Sucio por fuera',
  'Vidrio rayado',
  'Batería',
  'Otro',
];

const CarState = ({ color, setDataDamage, error, setError, optional }) => {
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);
  const [photoList, setPhotoList] = useState([-1]);
  const [data, setAuxData] = useState({
    files: [],
    uid,
    battery: false,
    damage: false,
    comment: '',
  });
  const [damageList, setDamageList] = useState([]);

  useEffect(() => {
    setDataDamage(data);
  }, [data]);

  const handleDamageList = (title) => {
    const aux = { ...data };
    if (title === 'Batería') {
      aux.battery = !aux.battery;
    } else {
      const auxList = [...damageList];
      if (!damageList.includes(title)) {
        auxList.push(title);
        setDamageList(auxList);
      } else {
        setDamageList(auxList.filter((elem) => elem !== title));
      }
      if (damageList.length === 0) {
        aux.damage = false;
      } else {
        aux.damage = true;
      }
    }
    setError();
    setAuxData(aux);
  };

  return (
    <Box
      sx={{
        height: '100%',
        minHeight: '100%',
        width: '100%',
        display: 'grid',
        gridTemplateRows: 'max-content 1fr',
        gridTemplateColumns: '100%',
        gap: '10px',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <Typography
          sx={{
            fontSize: '1rem',
            textAlign: 'left',
            fontFamily: 'Poppins-Medium',
            color,
          }}
        >
          {`Reporte de daños ${optional ? '(Opcional)' : ''}`}
        </Typography>

        {error ? (
          <Typography
            sx={{
              fontFamily: 'Poppins-Light',
              color: 'red',
              fontSize: '12px',
              textAlign: 'center',
            }}
          >
            Ocurrió un error al enviar los cambios, revisa la información e intenta nuevamente
          </Typography>
        ) : null}

        <Typography sx={{ fontSize: '14px', textAlign: 'left', fontFamily: 'Poppins-Regular' }}>
          Selecciona el o los daños que contiene el vehículo:
        </Typography>
        <Box
          sx={{
            display: 'grid',
            paddingBottom: '5px',
            gridTemplateColumns: '1fr 1fr',
          }}
        >
          {auxDamageList.map((title) => {
            return (
              <FormControlLabel
                key={title}
                sx={{
                  '& .MuiFormControlLabel-label': {
                    fontFamily: 'Poppins-Regular',
                    fontSize: '12px',
                    lineHeight: 1,
                    textAlign: 'left',
                  },
                }}
                control={
                  <Checkbox
                    onChange={() => {
                      handleDamageList(title);
                    }}
                  />
                }
                label={title}
              />
            );
          })}
        </Box>
        <Typography sx={{ fontSize: '14px', textAlign: 'left', fontFamily: 'Poppins-Regular' }}>
          Sube las fotos del daño que quieres reportar
        </Typography>

        <Box
          sx={{
            display: 'flex',
            overflowY: 'hidden',
            overflowX: 'scroll',
            paddingBottom: '10px',
            gap: '10px',
          }}
        >
          {data.files.map((elem, index) => {
            return (
              <PhotoCar
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                photoLoaded={elem[0]}
                confirmPhoto={(fileInfo) => {
                  setError();

                  const auxPhotoList = [...photoList];
                  auxPhotoList.unshift(photoList.length);
                  setPhotoList(auxPhotoList);

                  const auxFiles = [...data.files, fileInfo];
                  setAuxData({ ...data, files: auxFiles });
                }}
                removePhoto={() => {
                  const auxFiles = data.files.filter((photoArray) => !photoArray.includes(elem[0]));
                  setDataDamage({ ...data, files: auxFiles });
                  setAuxData({ ...data, files: auxFiles });
                }}
              />
            );
          })}
          <PhotoCar
            // eslint-disable-next-line react/no-array-index-key
            confirmPhoto={(fileInfo) => {
              setError();

              const auxPhotoList = [...photoList];
              auxPhotoList.unshift(photoList.length);
              setPhotoList(auxPhotoList);

              const auxFiles = [...data.files, fileInfo];
              setAuxData({ ...data, files: auxFiles });
            }}
            addPhoto
            normalSize={photoList.length > 1}
          />
        </Box>
      </Box>

      <TextField
        variant="filled"
        sx={{
          height: '100%',
          '& .MuiFilledInput-root': {
            height: '100%',
            display: 'flex',
            alignItems: 'start',
          },
        }}
        label="Déjanos tus observaciones aquí"
        multiline
        inputProps={{
          style: {
            fontFamily: 'Poppins-Regular',
          },
        }}
        InputLabelProps={{
          style: {
            fontFamily: 'Poppins-Regular',
            fontSize: '14px',
          },
        }}
        onChange={(e) => {
          setError();
          setAuxData({ ...data, comment: e.target.value });
        }}
      />
    </Box>
  );
};

CarState.propTypes = {
  optional: PropTypes.bool,
  color: PropTypes.string,
  setDataDamage: PropTypes.func,
  error: PropTypes.string,
  setError: PropTypes.func,
};

CarState.defaultProps = {
  optional: false,
  color: verdeText,
  setDataDamage: () => {},
  error: null,
  setError: () => {},
};

export default CarState;
