import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, List, ListItemButton, ListItemIcon, ListItemText, Slide } from '@mui/material';
import { ChevronRight, KeyboardArrowLeft } from '@mui/icons-material';
import { useReactiveVar } from '@apollo/client';
import commonDefaultContent, { newDefaultContent } from './content';
import { azulTucar } from '../../../themes/variants/TucarColors';
import ExternalOrInternalRedirect from './redirectComponent';
import { getDriverStore } from '../../../graphql/store';

function ExchangeContentList({ handleDrawerToggle }) {
  const { labelV2 } = useReactiveVar(getDriverStore);
  const [defaultContent, setDefaultContent] = useState(commonDefaultContent);
  const containerRef = React.useRef(null);
  const [open, setOpen] = useState({});
  const [click, setClick] = useState();
  const [main, setMain] = useState(true);
  const handleClick = (name, id) => {
    setOpen((prevState) => ({ ...prevState, [name]: !prevState[name] }));
    setMain((prevState) => !prevState);
    setClick(id);
  };

  const handleNav = (name) => {
    if (name) {
      setOpen((prevState) => ({ ...prevState, [name]: !prevState[name] }));
    }
    if (!main) {
      setMain((prevState) => !prevState);
    }
    handleDrawerToggle(false);
    setClick();
  };

  const components = defaultContent.flatMap((item) =>
    item.content ? (
      <Box key={`${item.name}`}>
        <ListItemButton sx={{ height: '45px' }} onClick={() => handleClick(item.name, item.id)}>
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText
            primaryTypographyProps={{
              style: { fontSize: '14px', fontFamily: 'Poppins-Regular', lineHeight: 1 },
            }}
            primary={item.name}
          />
          <ChevronRight sx={{ fontSize: '20px' }} />
        </ListItemButton>
      </Box>
    ) : (
      <ExternalOrInternalRedirect item={item} key={`${item.name}`} handleDrawerToggle={handleNav} />
    ),
  );
  const [viewContent, setViewContent] = useState(<List>{components}</List>);

  useEffect(() => {
    if (labelV2) {
      setDefaultContent(newDefaultContent);
    } else {
      setDefaultContent(commonDefaultContent);
    }
  }, [labelV2]);

  useEffect(() => {
    if (open && click >= 0 && defaultContent[click].content) {
      setViewContent(
        defaultContent[click].content.flatMap((item) =>
          item.subList ? (
            <Slide key={`${item.name}`} direction="left" in container={containerRef.current}>
              <Box>
                <ExternalOrInternalRedirect item={item} handleDrawerToggle={handleNav} />
              </Box>
            </Slide>
          ) : (
            <Box key={`${item.name}`}>
              <ListItemButton
                sx={{ height: '45px', color: azulTucar }}
                onClick={() => handleClick(item.name, item.id)}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    style: { fontSize: '14px', fontFamily: 'Poppins-Regular', lineHeight: 1 },
                  }}
                  primary={item.name}
                />
                <KeyboardArrowLeft sx={{ fontSize: '20px' }} />
              </ListItemButton>
            </Box>
          ),
        ),
      );
    } else {
      setViewContent(
        <Slide direction="left" in={main} container={containerRef.current}>
          <List>{components}</List>
        </Slide>,
      );
    }
  }, [open, click, defaultContent]);

  return <List>{viewContent}</List>;
}

ExchangeContentList.propTypes = {
  // eslint-disable-next-line react/require-default-props
  handleDrawerToggle: PropTypes.func.isRequired,
};

export default ExchangeContentList;
