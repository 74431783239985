export default function selectColorMode(mode) {
  switch (mode) {
    case 'electric-exclusive':
      return '#6CC8DD';
    case 'combustion-exclusive':
      return '#0057B8';
    default:
      return '#95D31C';
  }
}
