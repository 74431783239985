import { gql } from '@apollo/client';

const GET_DRIVER_SHIFTS = {
  query: gql`
    query GetDriverPlan($driverId: ID!, $uid: String!) {
      getDriverPlanV2(driverId: $driverId) {
        selectedShifts {
          _id
          name
          startTime
          endTime
          basePrice
          type
        }
        plan {
          shifts {
            _id
            name
            startTime
            endTime
            basePrice
            type
          }
        }
      }
      getCouples: getAssignations(offset: 0, limit: 1, driverId: $uid) {
        instances {
          drivers {
            _id
          }
        }
      }
    }
  `,
  policies: {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: 'true',
  },
};

export default GET_DRIVER_SHIFTS;
