import urlBase64ToUint8Array from './urlBase64ToUnit8Array';

export const register = async () => {
  navigator.serviceWorker.register('service-worker.js', {
    scope: '/',
  });
};

export const send = async (uid) => {
  const registeredSw = await navigator.serviceWorker.getRegistration();

  // Register Push
  const subscription = await registeredSw.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array(process.env.PUBLIC_VAP_ID_KEY),
  });
  // Send Push Notification
  await fetch(`${process.env.NOTIFICATION_SERVICE_URL}/driver/subscriptions`, {
    method: 'POST',
    body: JSON.stringify({ subscription, userId: uid }),
    headers: {
      'content-type': 'application/json',
    },
  });
};
