import React from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@tucar/pit-stop';
import { Box } from '@mui/material';
import checkBubble from '../../../../../public/images/bubbleIconsPng/check.png';

import { backgroundStyle, msgResultStyle } from './styles';

const SuccessScreen = ({ goBack }) => {
  return (
    <Box
      sx={{
        ...backgroundStyle,
        background:
          'linear-gradient(180deg, rgba(0, 87, 184, 0.50) 0%, rgba(217, 217, 217, 0.00) 80%)',
      }}
    >
      <img alt="checkBubble" src={checkBubble} width="152px" height="127px" />

      <Typography
        style={{
          ...msgResultStyle,
          paddingBottom: '0px',
          color: '#0057B8',
        }}
      >
        ¡Solicitud enviada!
      </Typography>
      <Typography
        style={{
          textAlign: 'center',
          fontSize: '14px',
          fontWeight: 500,
          lineHeight: 1.2,
          color: '#5B5D71',
        }}
      >
        Tu compañero revisará el cambio y te notificará si acepta o no.
      </Typography>
      <Button size="large" onClick={goBack}>
        Volver a turnos
      </Button>
    </Box>
  );
};

SuccessScreen.propTypes = {
  goBack: PropTypes.func,
};

SuccessScreen.defaultProps = {
  goBack: () => {},
};

export default SuccessScreen;
