import React from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import { TextField } from '@mui/material';

const EditPhone = ({ edit, value, handleChange, label, name }) => {
  if (!value && !edit) {
    return (
      <InputMask disabled mask="+56 9 9999 9999" maskChar={null} value={value}>
        {() => (
          <TextField
            size="small"
            disabled
            sx={{ marginBottom: '0px', marginTop: '0px' }}
            fullWidth
            type="text"
            variant="outlined"
            label={label}
            InputProps={{
              readOnly: true,
              style: { fontFamily: 'Poppins-Regular' },
            }}
            InputLabelProps={{
              style: { fontFamily: 'Poppins-Regular' },
            }}
          />
        )}
      </InputMask>
    );
  }

  if (value && !edit) {
    return (
      <InputMask disabled mask="+56 9 9999 9999" maskChar={null} value={value}>
        {(inputProps) => (
          <TextField
            {...inputProps}
            size="small"
            sx={{ marginBottom: '0px', marginTop: '0px' }}
            fullWidth
            type="text"
            variant="outlined"
            label={label}
            InputProps={{
              readOnly: true,
              style: { fontFamily: 'Poppins-Regular' },
            }}
            InputLabelProps={{
              style: { fontFamily: 'Poppins-Regular' },
            }}
          />
        )}
      </InputMask>
    );
  }

  return (
    <InputMask
      disabled={!edit}
      mask="+56 9 9999 9999"
      maskChar={null}
      value={value}
      onChange={(e) => {
        if (e.target.validity.valid || e.target.value === '') {
          handleChange(name, e.target.value.replace('+56', '').replace(/ +/g, ''));
        }
      }}
    >
      {(inputProps) => (
        <TextField
          {...inputProps}
          size="small"
          sx={{ marginBottom: '0px', marginTop: '0px' }}
          fullWidth
          type="text"
          variant="outlined"
          label={label}
          InputProps={{
            readOnly: !edit,
            style: { fontFamily: 'Poppins-Regular' },
          }}
          InputLabelProps={{
            style: { fontFamily: 'Poppins-Regular' },
          }}
        />
      )}
    </InputMask>
  );
};

export default EditPhone;

EditPhone.propTypes = {
  edit: PropTypes.bool,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string,
};

EditPhone.defaultProps = {
  edit: false,
  value: '',
  handleChange: () => {},
  label: '',
  name: '',
};
