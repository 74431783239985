import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { formatterCLP } from '../../../helpers/formatters';
import displayGenerator from './progressSections';
import TableSection from './tableSection';

const SectionKmsProgressBar = ({ billDays, setOpen }) => {
  const AllPlans = billDays.reduce(
    (acc, elem) => (acc.includes(elem.plan.planName) ? acc : [...acc, elem.plan.planName]),
    [],
  );

  const { currency } = billDays[0];

  const content =
    AllPlans.length === 1 ? (
      AllPlans.map((planName, index) => {
        const categoryDiscount = billDays[index]?.discount || 0;
        const customKey = `${planName}-${index}`;
        const kms = billDays
          .filter((elem) => elem.plan.planName === planName)
          .reduce((acc, elem) => acc + elem.km, 0);
        const { sections } = billDays.filter((elem) => elem.plan.planName === planName)[0].plan;

        return (
          <Box
            key={customKey}
            sx={{ display: 'flex', flexDirection: 'column', width: '100%', marginBottom: '10px' }}
          >
            <Typography
              sx={{
                fontFamily: 'Poppins-Medium',
                textAlign: 'center',
                fontSize: '14px',
                color: '#5B5D71',
                marginBottom: '10px',
              }}
            >
              {planName}
            </Typography>
            {displayGenerator(sections, kms, currency, categoryDiscount)}
          </Box>
        );
      })
    ) : (
      <TableSection billDays={billDays} />
    );

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
        maxWidth: '80vw',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            width: '100%',
            textAlign: 'center',
            fontSize: '16px',
            fontFamily: 'Poppins-Medium',
            color: '#5B5D71',
          }}
        >
          Costo por kilómetro
        </Typography>
        <IconButton onClick={() => setOpen(false)}>
          <ClearIcon color="#5B5D71" />
        </IconButton>
      </Box>

      <Typography
        sx={{
          width: '100%',
          textAlign: 'center',
          fontSize: '13px',
          color: '#5B5D71',
          fontFamily: 'Poppins-Regular',
        }}
      >
        El costo total del arriendo semanal es la suma del costo base del plan y el costo por km.
        Cada 400 km aplicaremos un descuento sobre el costo inicial del km.
      </Typography>
      <Box
        sx={{
          width: '100%',
        }}
      >
        {content}
      </Box>

      <Typography
        sx={{
          fontSize: '14px',
          color: '#5B5D71',
          fontFamily: 'Poppins-Regular',
          textAlign: 'center',
        }}
      >
        Los valores expresados en pesos consideran el valor de la UF de{' '}
        {formatterCLP.format(currency)}. IVA incluido.
      </Typography>
    </Box>
  );
};

SectionKmsProgressBar.propTypes = {
  billDays: PropTypes.arrayOf(
    PropTypes.shape({
      currency: PropTypes.number,
      discount: PropTypes.number,
    }),
  ),
  setOpen: PropTypes.func,
};

SectionKmsProgressBar.defaultProps = {
  billDays: [],
  setOpen: () => {},
};

export default SectionKmsProgressBar;
