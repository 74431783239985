import React from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Button, Typography } from '@tucar/pit-stop';
import rejectBubble from '../../../../../public/images/bubbleIconsPng/rejected.png';

import { backgroundStyle, msgResultStyle } from './styles';

const RejectScreen = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        ...backgroundStyle,
        background:
          'linear-gradient(180deg, rgba(255, 89, 63, 0.50) 0%, rgba(217, 217, 217, 0.00) 80%)',
      }}
    >
      <Typography
        style={{
          textAlign: 'center',
          fontSize: '18px',
          fontWeight: 600,
          lineHeight: 1.2,
          color: '#FFF',
          paddingBottom: '81px',
        }}
      >
        Solicitud rechazada
      </Typography>

      <img alt="rejectBubble" src={rejectBubble} width="180px" height="200px" />

      <Typography
        style={{
          ...msgResultStyle,
          color: '#FF593F',
        }}
      >
        Has rechazado el cambio de turno
      </Typography>

      <Button size="large" onClick={() => navigate('/app/v2/petitions', { replace: true })}>
        Volver a turnos
      </Button>
    </Box>
  );
};

export default RejectScreen;
