export const wrapperAlert = {
  position: 'fixed',
  left: '50%',
  top: '50%',
  width: 'calc(100vw - 54px)',
  transform: 'translate(-50%, -50%)',
  zIndex: 1000,
  boxShadow: '0px 0px 150vh 150vh rgba(0, 0, 0, 0.25)',
  borderRadius: '14px',
};

export const msgAlertStyle = {
  color: '#5B5D71',
  fontWeight: 400,
  lineHeight: 1.2,
  fontSize: '14px',
  textAlign: 'center',
};
