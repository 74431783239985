import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Collapse, IconButton } from '@mui/material';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';

const weekDays = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];

const ShowSelectedShifts = ({ shifts }) => {
  const [open, setOpen] = useState(false);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <Box
        onClick={() => setOpen(!open)}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderRadius: '20px',
          paddingLeft: '10px',
        }}
      >
        <Typography sx={{ fontFamily: 'Poppins-Medium', fontSize: '14px' }}>Horarios</Typography>
        <IconButton>
          {open ? (
            <KeyboardArrowUpOutlinedIcon sx={{ fontSize: '20px' }} />
          ) : (
            <KeyboardArrowDownOutlinedIcon sx={{ fontSize: '20px' }} />
          )}
        </IconButton>
      </Box>

      <Collapse
        sx={{ padding: '10px', borderRadius: '15px' }}
        in={open}
        timeout="auto"
        unmountOnExit
      >
        {shifts.map((shift, index) => {
          const weekDay = weekDays[index];
          return (
            <Box
              key={weekDay}
              sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <Typography sx={{ fontFamily: 'Poppins-Regular', fontSize: '12px' }}>
                {weekDay}
              </Typography>

              {shift.startTime !== '-' ? (
                <Typography sx={{ fontFamily: 'Poppins-Medium', fontSize: '12px' }}>
                  {shift.startTime} a {shift.endTime} hrs
                </Typography>
              ) : (
                <Typography sx={{ fontFamily: 'Poppins-Medium', fontSize: '12px' }}>
                  -- --
                </Typography>
              )}
            </Box>
          );
        })}
      </Collapse>
    </Box>
  );
};

ShowSelectedShifts.propTypes = {
  shifts: PropTypes.arrayOf(PropTypes.shape({})),
};

ShowSelectedShifts.defaultProps = {
  shifts: [],
};
export default ShowSelectedShifts;
