import React, { useEffect, useState } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { request } from 'graphql-request';
import { useNavigate } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { useMutation, useReactiveVar } from '@apollo/client';

import useSWR from 'swr';
import SubmitButton from '../../../components/SubmitButton';
import LoadingProgress from '../../../components/ui/LoadingProgress';
import { azulTucar } from '../../../themes/variants/TucarColors';
import AccordeonUnit from './accordeonText';
import documentIcon from '../../../../public/images/terms&conditions/documento.svg';
import checkIcon from '../../../../public/images/terms&conditions/check.svg';
import InfoModal from '../../../components/info/infoModal';
import GET_TERMS_AND_CONDITIONS from '../../../graphql/querys/terms&Conditions';
import UPDATE_TERMS from '../../../graphql/mutations/driver/updateTerms';
import { getAuthStore, setVerifiedList } from '../../../graphql/store';

const fetcher = (query) => request(process.env.API_TERMS_AND_CONDITIONS_URL, query);

const TermsAndCondition = () => {
  const navigate = useNavigate();
  const [check, setCheck] = useState(false);
  const [checkList, setCheckList] = useState({ all: false });
  const [open, setOpen] = useState(false);
  const { data, loading } = useSWR(GET_TERMS_AND_CONDITIONS.query, fetcher);
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0,
  });
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);
  const [updateTerms, { loading: updateLoading, data: updateData }] = useMutation(
    UPDATE_TERMS.mutation,
    UPDATE_TERMS.policies,
  );

  useEffect(() => {
    if (inView) {
      setCheck(true);
    }
  }, [inView]);

  useEffect(() => {
    if (data?.terminoYCondicione) {
      const listIds = data.terminoYCondicione.reduce((a, v) => ({ ...a, [v.id]: false }), {});
      setCheckList(listIds);
    }
  }, [data]);

  useEffect(() => {
    if (updateData) {
      navigate('/app/', { replace: true });
    }
  }, [updateData]);

  const notifyCheck = (id) => {
    setCheckList((currentValues) => ({
      ...currentValues,
      [id]: true,
    }));
  };

  const acceptMessage = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
      }}
    >
      <img
        src={checkIcon}
        alt="category"
        style={{
          width: '5rem',
        }}
      />
      <Typography sx={{ textAlign: 'center', fontFamily: 'Poppins-Medium', fontSize: '20px' }}>
        ¡Términos de Servicios aceptados!
      </Typography>
      <Typography
        variant="span"
        sx={{
          textAlign: 'center',
          fontFamily: 'Poppins-Regular',
          fontSize: '14px',
        }}
      >
        Ya puedes acceder a tu cuenta.
      </Typography>

      <SubmitButton
        text="Ir a inicio"
        handler={() => {
          setVerifiedList({ tc: true });
          updateTerms({ variables: { uid, tc: true } });
        }}
      />
    </Box>
  );

  if (!data || updateLoading || loading) {
    return (
      <Box sx={{ heigth: '100%' }}>
        <LoadingProgress allScreen />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        height: '100vh',
        maxHeight: '100vh',
        padding: '30px 25px',
        gap: '20px',
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'max-content max-content 1fr max-content',
      }}
    >
      <InfoModal open={open} handleClose={() => setOpen(false)} content={acceptMessage} />
      <Box>
        <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
          <img
            src={documentIcon}
            alt="category"
            style={{
              width: '60px',
            }}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            <Typography sx={{ fontFamily: 'Poppins-Medium', fontSize: '16px', color: azulTucar }}>
              Términos de Servicio de TUCAR
            </Typography>
            <Typography sx={{ fontFamily: 'Poppins-Medium', fontSize: '14px' }}>
              Versión Noviembre 2023
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <Divider sx={{ color: '#17171C' }} />
        <Typography
          variant="span"
          sx={{
            textAlign: 'left',
            display: 'inline-block',
            fontFamily: 'Poppins-Regular',
            fontSize: '14px',
          }}
        >
          Por favor, lee estos{' '}
          <Typography
            sx={{
              color: azulTucar,
              display: 'inline-block',
              fontFamily: 'Poppins-Medium',
              fontSize: '14px',
            }}
          >
            Términos de Servicio de Tucar{' '}
          </Typography>
          cuidadosamente. Estos Términos de Servicio contienen limitaciones de responsabilidad. Es
          conveniente imprimir una copia para tus registros.
        </Typography>
        <Divider sx={{ color: '#17171C' }} />
      </Box>

      <Box
        className="container-terms"
        sx={{
          overflowY: 'scroll',
          '&::-webkit-scrollbar': {
            width: '7px',
          },
          '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#AAACBB',
            borderRadius: '20px',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            gap: '10px',
            paddingRight: '10px',
          }}
        >
          {data?.terminoYCondicione.map((elem) => (
            <AccordeonUnit key={elem.id} data={elem} notifyCheck={notifyCheck} autoCheck={check} />
          ))}
          <Box ref={ref} sx={{ marginBottom: '10px' }} />
        </Box>
      </Box>

      <Box sx={{ marginTop: '20px' }}>
        <SubmitButton
          disabled={Object.values(checkList).includes(false) && !check}
          text="Aceptar"
          handler={() => {
            setOpen(true);
          }}
        />
      </Box>
    </Box>
  );
};

export default TermsAndCondition;
