import { gql } from '@apollo/client';

const GET_CAR_PLUG_TYPES = {
  query: gql`
    query GetCarConfigPlugTypes($carId: ID!, $skip: Boolean!) {
      getCarConfigPlugTypes(carId: $carId) @skip(if: $skip)
    }
  `,
  policies: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'ignore',
    notifyOnNetworkStatusChange: 'true',
  },
};

export default GET_CAR_PLUG_TYPES;
