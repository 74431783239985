import extraStore from './model';

export const getFirstTimeDashboard = () =>
  localStorage.getItem('firstTimeDashboard') ? localStorage.getItem('firstTimeDashboard') : false;

export const getHiddeNews = () =>
  !!(localStorage.getItem('hiddeNews') && localStorage.getItem('hiddeNews') === 'true');

export const getUpdatedAtNews = () =>
  localStorage.getItem('updatedAtNews') ? localStorage.getItem('updatedAtNews') : null;

export const getHiddeNewVersionModal = () =>
  !!(
    localStorage.getItem('hiddeNewVersionModal') &&
    localStorage.getItem('hiddeNewVersionModal') === 'true'
  );

export const getHiddeNewVersionFormModal = () =>
  !!(
    localStorage.getItem('hiddeNewVersionFormModal') &&
    localStorage.getItem('hiddeNewVersionFormModal') === 'true'
  );

export const getUpdatedAtFormModal = () =>
  localStorage.getItem('updateAtFormModal') ? localStorage.getItem('updateAtFormModal') : null;

export const getExtraStore = extraStore;
