import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Button, Card, Typography } from '@tucar/pit-stop';
import { azulTucar } from '../../../../../themes/variants/TucarColors';
import IconInfo from '../../../../../components/ui/IconInfoProfitCard';

const GainingStep = ({ onNext }) => {
  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={{ position: 'relative', zIndex: 1 }}>
        <Box
          sx={{
            position: 'absolute',
            zIndex: 2,
            transform: 'translateY(calc(-100% - 30px))',
            '::before': {
              content: '""',
              zIndex: -1,
              position: 'absolute',
              top: '-50px',
              left: '-10%',
              width: '200vw',
              height: '130vh',
              borderRadius: '100vw 100vw 0px 0px',
              transform: 'translateX(-25%)',
              background: 'linear-gradient(180deg, #EAFFFE -20%, rgba(205, 201, 241, 0.80) 100%)',
              backdropFilter: 'blur(2px)',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              paddingBottom: '30px',
              paddingInline: '10%',
            }}
          >
            <Typography
              align="left"
              color={azulTucar}
              style={{ fontSize: '18px', fontWeight: 600 }}
            >
              Ganancia total
            </Typography>
            <Typography
              color={azulTucar}
              align="left"
              style={{ fontSize: '14px', fontWeight: 400, lineHeight: '16px' }}
            >
              Aquí encontrarás un resumen de tus ganancias totales, efectivo recaudado,
              recaudaciones completas y descuentos aplicados. ¿Quieres más detalles? ¡Haz clic en
              &lsquo Ver Detalle &rsquo para un análisis completo de tu rendimiento.
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <Typography color={azulTucar} style={{ fontSize: '14px', fontWeight: 500 }}>
              Paso 7/11
            </Typography>
            <Button
              size="small"
              onClick={() => {
                onNext();
                document.getElementById('section-8').scrollIntoView({ behavior: 'auto' });
              }}
            >
              Siguiente
            </Button>
          </Box>
        </Box>

        <Card
          style={{
            position: 'absolute',
            zIndex: 2,
            gap: '20px',
            marginTop: '22px',
            padding: '22px',
            alignItems: 'unset',
            width: '100%',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography color="#5B5D71" style={{ fontSize: '16px', fontWeight: 500 }}>
              Ganancias total
            </Typography>
            <Typography color={azulTucar} style={{ fontSize: '36px', fontWeight: 600 }}>
              $123.292
            </Typography>
          </Box>
          <Box sx={{ width: '100%', display: 'flex', gap: '5px', justifyContent: 'space-evenly' }}>
            <IconInfo title="Efectivo" iconName="moneyOutlined" color="#95D31C" amount={120000} />
            <IconInfo
              title="Recaudaciones"
              iconName="electricCarFilled"
              color="#FABB03"
              amount={85000}
            />
            <IconInfo
              title="Descuentos"
              iconName="electricCarFilled"
              color="#E81F00"
              amount={85000}
            />
          </Box>
          <Button disabled style={{ width: 'max-content', alignSelf: 'end' }}>
            <Typography color="#8E90A4" style={{ fontSize: '14px', fontWeight: 600 }}>
              Ver detalle
            </Typography>
          </Button>
        </Card>
      </Box>
    </Box>
  );
};

GainingStep.propTypes = {
  onNext: PropTypes.func,
};

GainingStep.defaultProps = {
  onNext: () => {},
};

export default GainingStep;
