import React, { useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useReactiveVar, useQuery } from '@apollo/client';

import { getAuthStore } from '../../../graphql/store';
import GET_ADVICE_COUPLES from '../../../graphql/querys/exchange/getAdviceAssigned';
import TopBar from '../../../components/ui/TopNav';
import LoadingProgress from '../../../components/ui/LoadingProgress';

const CheckPetition = () => {
  const navigate = useNavigate();
  const [handlePageChange] = useOutletContext();

  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);

  const { data: assignationInfo, loading: loadingAssignationInfo } = useQuery(
    GET_ADVICE_COUPLES.query,
    {
      ...GET_ADVICE_COUPLES.policies,
      variables: {
        driverId: uid,
        labelId: 'SHIFT_CHANGE_PETITION',
        $skipGetLabel: true,
      },
    },
  );

  useEffect(() => {
    handlePageChange(<TopBar title="Solicitudes cambio de turno" />, true);
  }, []);

  useEffect(() => {
    if (assignationInfo?.getCouples?.instances?.length) {
      if (assignationInfo.getCouples.instances[0]?.drivers.length >= 2) {
        navigate('/app/petitions');
      }
    }
  }, [assignationInfo]);

  if (loadingAssignationInfo) {
    return <LoadingProgress />;
  }

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
      }}
    >
      <Typography
        sx={{ textAlign: 'center', mt: 2, fontFamily: 'Poppins-Medium', fontSize: '16px' }}
      >
        Acceso inhabilitado
      </Typography>
      <Typography
        sx={{ textAlign: 'center', mt: 2, fontFamily: 'Poppins-Regular', fontSize: '14px' }}
      >
        Las solicitudes temporales de cambio de turno sólo están habilitadas para los conductores
        con plan compartido emparejados.
      </Typography>
    </Box>
  );
};

export default CheckPetition;
