import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Button, IconButton, Typography } from '@tucar/pit-stop';
import { azulTucar } from '../../../../../themes/variants/TucarColors';

const SideBarStep = ({ onNext }) => {
  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        sx={{
          position: 'absolute',
          top: '0px',
          left: '0px',
          width: '100vw',
          height: '300px',
          zIndex: 1,
          '::before': {
            content: '""',
            zIndex: -1,
            position: 'absolute',
            top: '-50px',
            left: '-30px',
            width: '661px',
            height: '661px',
            transform: 'translate(-15%, -55%)',
            background: 'linear-gradient(180deg, #EAFFFE 47.81%, rgba(205, 201, 241, 0.80) 99.93%)',
            borderRadius: '661px',
            backdropFilter: 'blur(2px)',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: '70px',
            paddingBottom: '30px',
            width: 'calc(100vw - 50px)',
            gap: '10px',
          }}
        >
          <Typography align="left" color={azulTucar} style={{ fontSize: '18px', fontWeight: 600 }}>
            Menú de acceso directo
          </Typography>
          <Typography
            color={azulTucar}
            align="left"
            style={{ fontSize: '14px', fontWeight: 400, lineHeight: '16px' }}
          >
            ¡Explora fácilmente todas las funcionalidades! Este menú de acceso directo te llevará a
            todas las funcionalidades de la aplicación.
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
          <Typography color={azulTucar} style={{ fontSize: '14px', fontWeight: 500 }}>
            Paso 5/11
          </Typography>
          <Button
            size="small"
            onClick={() => {
              onNext();
              document.getElementById('section-6').scrollIntoView({ behavior: 'auto' });
            }}
          >
            Siguiente
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          position: 'relative',
          '::before': {
            content: '""',
            position: 'absolute',
            width: '80px',
            height: '80px',
            top: '20px',
            left: '20px',
            zIndex: 1,
            transform: 'translate(-50%, -50%)',
            borderRadius: '100px',
            background: '#FFF',
          },
        }}
      >
        <IconButton
          style={{ backgroundColor: '#FFF', position: 'absolute', zIndex: 1 }}
          iconProps={{ color: azulTucar }}
        />
      </Box>
    </Box>
  );
};

SideBarStep.propTypes = {
  onNext: PropTypes.func,
};

SideBarStep.defaultProps = {
  onNext: () => {},
};

export default SideBarStep;
