import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Box, TextField, InputAdornment, styled, Typography } from '@mui/material';
import esLocale from 'date-fns/locale/es';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { CalendarToday } from '@mui/icons-material';

const StyledTextField = styled(TextField)`
  border-radius: 4px;
  border: 1px solid var(--Blanco, #fff);
  background: #e3edf7;
  box-shadow: 4px 4px 14px 0px #d9d9d9 inset, -4px -4px 9px 0px rgba(255, 255, 255, 0.88) inset;
`;

const OneDateSelector = ({ onChange, selectedDate }) => {
  const [openCalendar, setOpenCalendar] = useState(false);

  return (
    <Box>
      <Typography
        sx={{
          color: '#5B5D71',
          fontFamily: 'Poppins',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: 1.2,
          marginBottom: '9px',
        }}
      >
        Fecha
      </Typography>
      <StyledTextField
        readOnly
        fullWidth
        size="small"
        onClick={() => setOpenCalendar(true)}
        value={selectedDate ? `${moment(selectedDate).format('DD/MM/YYYY')}` : 'DD/MM/YYYY'}
        sx={{
          margin: '0px',
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'transparent',
            },
          },
        }}
        InputProps={{
          style: {
            fontFamily: 'Poppins-Regular',
            borderRadius: '4px',
            padding: '10px 15px',
            alignItems: 'center',
            border: '0px',
            color: '#5B5D71',
          },
          startAdornment: (
            <InputAdornment position="end" sx={{ paddingRight: '10px' }}>
              <CalendarToday />
            </InputAdornment>
          ),
        }}
      />

      <LocalizationProvider adapterLocale={esLocale} dateAdapter={AdapterDateFns}>
        <MobileDatePicker
          fullWidth
          disableOpenPicker
          open={openCalendar}
          disableFuture
          componentsProps={{
            actionBar: {
              actions: [],
            },
          }}
          value={selectedDate}
          showDaysOutsideCurrentMonth
          renderInput={() => {
            return null;
          }}
          onChange={(date) => {
            onChange(date);
            setOpenCalendar(false);
          }}
        />
      </LocalizationProvider>
    </Box>
  );
};

OneDateSelector.propTypes = {
  onChange: PropTypes.func,
  selectedDate: PropTypes.instanceOf(Date),
};

OneDateSelector.defaultProps = {
  onChange: () => {},
  selectedDate: null,
};

export default OneDateSelector;
