import React from 'react';
import { LinearProgress, TucarTheme } from '@tucar/pit-stop';
import { Box, Typography } from '@mui/material';
import { formatterCLP } from '../../../helpers/formatters';

const getSectionPercentage = (section, kms) => {
  const { endKm, startKm } = section;
  if (kms >= endKm) {
    return 100;
  }
  if (kms > startKm && kms < endKm) {
    return Math.abs(((kms - startKm) * 100) / Math.abs(startKm - endKm));
  }
  return 0;
};

const ProgressSection = (sections, kms, currency, discount) => {
  return sections.map((section) => {
    const { startKm, endKm, variable } = section;
    const value = getSectionPercentage(section, kms);
    const discountValue = 1 - discount / 100;
    return (
      <Box
        key={`${startKm}-${endKm}`}
        sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px', alignItems: 'center' }}>
            <Typography
              sx={{
                fontSize: '13px',
                color: '#5B5D71',
                fontFamily: 'Poppins-Regular',
                lineHeight: 1.2,
                width: 'max-content',
              }}
            >
              {startKm} a {endKm} km
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Poppins-Regular',
                fontSize: '13px',
                color: value === 100 ? '#95D21C' : `${value === 0 ? '#5B5D71' : '#0057B8'}`,
              }}
            >
              ({value.toFixed(0)}%)
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            {discountValue !== 1 && (
              <Typography
                sx={{
                  fontSize: '10px',
                  color: TucarTheme.colors.error500,
                  fontFamily: 'Poppins-Medium',
                  textDecoration: 'line-through',
                }}
              >
                {formatterCLP.format(Math.ceil(variable * currency))}
              </Typography>
            )}
            <Typography
              sx={{
                fontSize: '13px',
                color: '#5B5D71',
                fontFamily: 'Poppins-Medium',
                lineHeight: 1.2,
                textAlign: 'end',
              }}
            >
              {formatterCLP.format(Math.ceil(variable * currency * discountValue))} por km
            </Typography>
          </Box>
        </Box>

        <LinearProgress value={value || 0} size="12px" />
      </Box>
    );
  });
};

export default ProgressSection;
