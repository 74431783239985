import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';

const PressIconButton = ({ children, size = '55px' }) => (
  <IconButton
    disabled
    sx={{
      '&:disabled': {
        backgroundColor: '#FFF',
      },
      width: size,
      height: size,
      boxShadow:
        '4px 4px 14px 0px #D9D9D9 inset, -4px -4px 9px 0px rgba(255, 255, 255, 0.88) inset',
    }}
  >
    {children}
  </IconButton>
);

PressIconButton.propTypes = {
  size: PropTypes.string,
  children: PropTypes.node,
};

PressIconButton.defaultProps = {
  size: '55px',
  children: null,
};

export default PressIconButton;
