import { gql } from '@apollo/client';

const GET_GAININGS = {
  query: gql`
    query GetGainingBillDaysLimitDates(
      $uid: ID!
      $initialDatetime: String
      $finalDatetime: String
    ) {
      getDriverBillDaysLimitDates(
        uid: $uid
        initialDatetime: $initialDatetime
        finalDatetime: $finalDatetime
      ) {
        installment
        date
        km
        traveledSections {
          startKm
          endKm
          variable
          traveled
        }
        currency
        category
        discount
        uber {
          category
          amount
          datetime
        }
        tag {
          amount
          address
          datetimeWindow
        }
        expenses {
          fines
          penaltys
          guarantee
          rent
          tag
          other
        }
        plan {
          startDate
          planName
          sections {
            variable
            endKm
            startKm
          }
          plan {
            name
            _id
            sections {
              variable
              endKm
              startKm
            }
          }
          selectedShifts {
            basePrice
          }
        }
        incomes {
          uber
          didi
          cabify
          beat
          other
        }
        events {
          chargeDate
          createdAt
          updatedAt
          title
          type
          amount
          description
          subject
          status
          comments
          installments
          car {
            _id
            info {
              plate
            }
          }
          charged {
            billRef
            rol
            ref
          }
          credited {
            billRef
            rol
            ref
          }
          files {
            url
            name
            ext
          }
        }
        uberBreakdown {
          adjustment
          cashCollected
          netFare
          other
          taxes
          promotions
          tips
          toll
        }
      }
    }
  `,
  policies: {
    fetchPolicy: 'cache-and-network',
  },
};

export default GET_GAININGS;
