import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';

import { CssTab, CssTabs } from './styles';

const Tabs = ({ tabStyle, currentTabName, setCurrentTabFunction, tabs, containerStyle }) => {
  const handleTabsChange = (_, value) => {
    setCurrentTabFunction(value);
  };

  return (
    <Box style={{ ...containerStyle }}>
      <CssTabs
        onChange={handleTabsChange}
        scrollButtons="auto"
        value={currentTabName}
        variant="scrollable"
      >
        {tabs.map((tab) => (
          <CssTab
            sx={
              tab.value === currentTabName
                ? { ...tabStyle, fontFamily: 'Poppins-Regular', textTransform: 'initial' }
                : { fontFamily: 'Poppins-Regular', textTransform: 'initial' }
            }
            className="PoppinsRegular"
            icon={tab.icon}
            iconPosition="start"
            key={tab.value}
            label={tab.label}
            value={tab.value}
          />
        ))}
      </CssTabs>
    </Box>
  );
};

Tabs.propTypes = {
  tabStyle: PropTypes.shape({}),
  currentTabName: PropTypes.string.isRequired,
  setCurrentTabFunction: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.element,
    }),
  ).isRequired,
  containerStyle: PropTypes.shape({}),
};

Tabs.defaultProps = {
  containerStyle: { marginBottom: '40px' },
  tabStyle: {},
};

export default Tabs;
