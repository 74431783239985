import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Divider } from '@mui/material';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { azulTucar } from '../../../themes/variants/TucarColors';
import { formatterCLP } from '../../../helpers/formatters';

const TravelerSection = ({ step, money, verticalLine }) => {
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '5px',
            alignItems: 'center',
          }}
        >
          <CheckCircleOutlinedIcon
            sx={{ width: '1rem', color: azulTucar, verticalAlign: 'center' }}
          />
          <Typography
            sx={{
              fontFamily: 'Poppins-Light ',
              fontSize: '0.75rem',
              textAlign: 'left',
              lineHeight: 1.2,
            }}
          >
            Viajes completados
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              fontFamily: 'Poppins-Light',
              fontSize: '0.75rem',
              textAlign: 'right',
              lineHeight: 1.2,
            }}
          >
            Ganancia
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          height: verticalLine ? '2.125rem' : 'auto',
        }}
      >
        <Box sx={{ display: 'flex', gap: '10px', paddingLeft: '7px' }}>
          <Divider
            orientation="vertical"
            sx={{ backgroundColor: azulTucar, visibility: verticalLine ? 'visible' : 'hidden' }}
          />

          <Typography
            sx={{
              fontFamily: 'Poppins-Medium',
              fontSize: '1rem',
              textAlign: 'left',
              color: azulTucar,
            }}
          >
            {step}
          </Typography>
        </Box>
        <Typography
          sx={{
            fontFamily: 'Poppins-Medium',
            fontSize: '1rem',
            textAlign: 'left',
            color: azulTucar,
          }}
        >
          {formatterCLP.format(money)}
        </Typography>
      </Box>
    </Box>
  );
};

TravelerSection.defaultProps = {
  step: 0,
  money: 0,
  verticalLine: true,
};

TravelerSection.propTypes = {
  step: PropTypes.number,
  money: PropTypes.number,
  verticalLine: PropTypes.bool,
};

export default TravelerSection;
