import { gql } from '@apollo/client';
import insertDataLayer from '../../../helpers/GTM';
import { setAuth } from '../../store';

const SIGN_UP_UBER = {
  mutation: gql`
    mutation Mutation($code: String!, $redirectUrl: String!) {
      signUpUber(code: $code, redirectUrl: $redirectUrl) {
        accessToken
        refreshToken
        data {
          uid
          email
          firstname
          lastname
          userType
          verified
        }
      }
    }
  `,
  policies: {
    onCompleted: (response) => {
      if (response.signUpUber) {
        const { accessToken, refreshToken, data } = response.signUpUber;
        setAuth({ accessToken, refreshToken, data });
        insertDataLayer();

        const dataLayer = window.dataLayer || [];
        dataLayer.push({
          event: 'Registro Uber GTM',
          userId: data?.uid || '',
          date: new Date(),
        });
      }
    },

    errorPolicy: 'all',
  },
};

export default SIGN_UP_UBER;
