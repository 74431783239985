import React, { useEffect, useState } from 'react';
import { InView } from 'react-intersection-observer';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';

import { Box, Typography } from '@mui/material';
import LoadingProgress from '../LoadingProgress';

const InfinityView = ({
  queryObject,
  auxVars,
  primaryKey,
  cantergiani,
  formatContent,
  noContentText,
}) => {
  const [loadView, setLoadView] = useState(false);
  const [content, setContent] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [dataContent, setDataContent] = useState([]);
  const matches = useMediaQuery('(max-width:400px)');

  const { loading, data, fetchMore } = useQuery(queryObject.query, {
    ...queryObject.policies,
    variables: {
      ...auxVars,
      page: pageNumber,
      limit: 10,
    },
  });

  useEffect(() => {
    if (data && data[primaryKey] && (content.length === 0 || content.length < totalDocuments)) {
      if (cantergiani) {
        setContent([...content, ...data[primaryKey].data]);
        setTotalDocuments(parseInt(data[primaryKey].metadata.total_docuements, 10));
      } else {
        // console.log('El formato no es el usado por cantergiani');
      }
    }
  }, [loading, data]);

  useEffect(() => {
    if (content) {
      setDataContent(formatContent(content));
    }
  }, [content]);

  if (loading && content.length === 0) {
    return <LoadingProgress />;
  }

  if ((!loading && content.length < 1) || dataContent.length === 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: matches ? '80vh' : '50vh',
        }}
      >
        <Typography sx={{ fontFamily: 'Poppins-Regular', fontSize: '16px' }}>
          {noContentText}
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      {dataContent}
      {loadView || content.length !== totalDocuments ? (
        <Box>
          <LoadingProgress marginTop="10px" />
        </Box>
      ) : null}
      <InView
        onChange={async (inView) => {
          if (inView && content.length !== totalDocuments) {
            setLoadView(true);
            await fetchMore({
              variables: {
                page: pageNumber + 1,
              },
            }).then(() => {
              setPageNumber(pageNumber + 1);
              setLoadView(false);
            });
          }
        }}
      />
    </Box>
  );
};

InfinityView.propTypes = {
  formatContent: PropTypes.func.isRequired,
  primaryKey: PropTypes.string.isRequired,
  queryObject: PropTypes.shape({
    query: PropTypes.shape({}),
    policies: PropTypes.shape({}),
  }).isRequired,
  auxVars: PropTypes.shape({}).isRequired,
  noContentText: PropTypes.string,
  cantergiani: PropTypes.bool,
};

InfinityView.defaultProps = {
  cantergiani: true,
  noContentText: '',
};

export default InfinityView;
