import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import SubmitButton from '../../SubmitButton';

export default function ConfirmModal({ open, setOpen, title, content, handleConfirm }) {
  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmSelection = () => {
    setOpen(false);
    handleConfirm();
  };
  return (
    <Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ style: { borderRadius: '15px', textAlign: 'center' } }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ fontFamily: 'Poppins-Regular', lineHeight: 1.2 }}
        >
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ fontFamily: 'Poppins-Regular', lineHeight: 1.2 }}
          >
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'space-around' }}>
          <SubmitButton height="30px" text="Confirmar" handler={handleConfirmSelection} />
          <SubmitButton
            height="30px"
            text="Cancelar"
            handler={handleClose}
            backgroundColor="#F3F3F3"
            color="#333333"
          />
        </DialogActions>
      </Dialog>
    </Box>
  );
}

ConfirmModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
};

ConfirmModal.defaultProps = {
  content: '',
};
