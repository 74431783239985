import { gql } from '@apollo/client';

const GET_TAGS = {
  query: gql`
    query GetDiverTags($uid: ID!, $initialDatetime: String, $finalDatetime: String) {
      getDriverBillDaysLimitDates(
        uid: $uid
        initialDatetime: $initialDatetime
        finalDatetime: $finalDatetime
      ) {
        tag {
          amount
          address
          datetimeWindow
        }
      }
    }
  `,
  policies: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
};

export default GET_TAGS;
