import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Tabs, BarGraph, MultiBarGraph } from '@tucar/pit-stop';
import { getTotals, getGraphData, getGraphMultiData } from './auxFunctions';
import GraphText from './GraphText';
import GraphLabel from './GraphLabel';

const GraphOptions = ({ billDays, selectedDate }) => {
  const [tab, setTab] = useState('both');
  const [totalIncomes, setTotalIncomes] = useState(0);
  const [totalExpenses, setTotalExpenses] = useState(0);
  const [totalBoth, setTotalBoth] = useState(0);
  const [incomeData, setIncomeData] = useState([]);
  const [expenseData, setExpenseData] = useState([]);
  const [bothData, setBothData] = useState([]);
  const [maxIncome, setMaxIncome] = useState(0);
  const [maxExpense, setMaxExpense] = useState(0);

  useEffect(() => {
    if (selectedDate) {
      const {
        totalIncomes: auxTotalIncomes,
        totalExpenses: auxTotalExpenses,
        totalBoth: auxTotalBoth,
      } = getTotals(billDays);

      const auxMaxIncome = billDays.reduce((acc, e) => {
        const totalIncome = Object.values(e.incomes).reduce(
          (acc2, value) => (typeof value !== 'string' ? acc2 + value : acc2),
          0,
        );
        return acc > totalIncome ? acc : totalIncome;
      }, 0);

      const auxMaxExpense = billDays.reduce((acc, e) => {
        const totalExpense = Object.values(e.expenses).reduce(
          (acc2, value) => (typeof value !== 'string' ? acc2 + value : acc2),
          0,
        );
        return acc > totalExpense ? acc : totalExpense;
      }, 0);

      const auxIncomeData = getGraphData(billDays, selectedDate, auxMaxIncome, 'incomes');
      const auxExpenseData = getGraphData(billDays, selectedDate, auxMaxExpense, 'expenses');
      const auxBothData = getGraphMultiData(billDays, selectedDate);

      setMaxIncome(auxMaxIncome > 0 ? auxMaxIncome : 250000);
      setMaxExpense(auxMaxExpense > 0 ? auxMaxExpense : 250000);
      setBothData(auxBothData);
      setIncomeData(auxIncomeData);
      setExpenseData(auxExpenseData);
      setTotalIncomes(auxTotalIncomes);
      setTotalExpenses(auxTotalExpenses);
      setTotalBoth(auxTotalBoth > 0 ? auxTotalBoth : 250000);
    }
  }, [billDays, selectedDate]);

  const getTotalText = () => {
    switch (tab) {
      case 'incomes':
        return (
          <GraphText
            amount={totalIncomes}
            positive
            msg="Total de ganancias recaudadas sin incluir cargos asociados."
          />
        );
      case 'expenses':
        return (
          <GraphText
            amount={totalExpenses}
            positive={false}
            msg="Total de gastos realizados incluyendo cargos asociados."
          />
        );
      default:
        return (
          <GraphText
            amount={totalIncomes - totalExpenses}
            positive={totalIncomes - totalExpenses >= 0}
            msg="Total de movimientos financieros incluyendo cargos."
          />
        );
    }
  };

  return (
    <Box>
      <Tabs
        size="small"
        currentTab={tab}
        onChange={(event) => setTab(event.target.value)}
        tabs={[
          { value: 'incomes', label: 'Ingresos' },
          { value: 'expenses', label: 'Egresos' },
          { value: 'both', label: 'Ambos' },
        ]}
        style={{ paddingInline: 50 }}
      />

      <Box
        sx={{
          paddingTop: '20px',
          paddingInline: '30px',
          marginBottom: '20px',
        }}
      >
        {getTotalText()}
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {tab !== 'both' ? (
          <Box sx={{ paddingLeft: '10px', transform: 'translateY(-60px)' }}>
            <BarGraph
              initialLabelType="$"
              barWidth="18px"
              data={tab === 'incomes' ? incomeData : expenseData}
              total={tab === 'incomes' ? maxIncome : maxExpense}
              maxHeight="300px"
              step={5}
              color={tab === 'incomes' ? '#2189FF' : '#FF593F'}
            />
            <GraphLabel opacityBlue={tab !== 'incomes'} opacityRed={tab === 'incomes'} />
          </Box>
        ) : (
          <Box sx={{ paddingLeft: '15px' }}>
            <MultiBarGraph
              initialLabelType="$"
              barWidth="10px"
              gap="15px"
              data={bothData}
              total={totalBoth}
              maxHeight="250px"
              colors={['#2189FF', '#FF593F']}
              step={5}
            />
            <GraphLabel />
          </Box>
        )}
      </Box>
    </Box>
  );
};

GraphOptions.propTypes = {
  billDays: PropTypes.arrayOf(PropTypes.shape({})),
  selectedDate: PropTypes.instanceOf(Date),
};

GraphOptions.defaultProps = {
  billDays: [],
  selectedDate: new Date(),
};

export default GraphOptions;
