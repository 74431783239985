import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useMutation, useReactiveVar } from '@apollo/client';
import { Card, Typography, Switch, Icon, Modal } from '@tucar/pit-stop';
import BLOCK_DRIVER_CASH from '../../../../graphql/mutations/driver/blockDriverCash';
import { getAuthStore } from '../../../../graphql/store';

const SwitchCard = ({ blocked, setBlocked, blockedBy }) => {
  const cardStyle = {
    padding: '14px 22px',
    display: 'flex',
    flexDirection: 'row',
    gap: '14px',
    alignItems: 'center',
    justifyContent: 'start',
  };
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);
  const [showError, setShowError] = useState(false);
  const [blockCash, { loading, data, error }] = useMutation(BLOCK_DRIVER_CASH.mutation, {
    ...BLOCK_DRIVER_CASH.policies,
    variables: {
      driverId: uid,
    },
  });

  const handleBlockCash = () => {
    blockCash({ variables: { block: !blocked } });
  };

  useEffect(() => {
    if (!loading && data && data?.blockDriverCash?.block !== blocked) {
      setBlocked(data?.blockDriverCash?.block);
    }
    if (!loading && error) {
      setShowError(true);
      setBlocked(blocked);
    }
  }, [loading]);

  if (loading) {
    return (
      <Card
        style={{
          ...cardStyle,
          background: '#C6C8D2',
        }}
      >
        <Switch disabled checked={blocked} />
        <Typography
          style={{
            color: '#71758E',
            fontSize: '13px',
            fontWeight: 600,
            lineHeight: 1.2,
          }}
        >
          {blocked ? 'Viajes en efectivo desactivado' : 'Desactivar viajes en efectivo'}
        </Typography>
      </Card>
    );
  }

  if (blockedBy !== 'driver' && blocked) {
    return (
      <Box>
        <Card
          style={{
            ...cardStyle,
            background: '#C6C8D2',
          }}
        >
          <Switch disabled checked />
          <Typography
            style={{
              color: '#71758E',
              fontSize: '13px',
              fontWeight: 600,
              lineHeight: 1.2,
            }}
          >
            Desactivar viajes en efectivo
          </Typography>
        </Card>

        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '6px', marginTop: '14px' }}>
          <Icon name="errorRounded" color="#FF2300" size="20px" />
          <Typography
            style={{
              color: '#FF593F',
              fontSize: '12px',
              fontWeight: 500,
              lineHeight: 1.2,
            }}
          >
            Tu opción de recibir pagos en efectivo está actualmente bloqueada por un administrador.
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Modal
        open={showError}
        onClose={() => setShowError(false)}
        noBody
        title="Error"
        variant="alert"
        type="error"
        style={{ padding: '15px' }}
      >
        <Typography style={{ fontSize: '14px', lineHeight: 1.2 }}>
          Ocurrió un error al enviar la solicitud, intente nuevamente.
        </Typography>
      </Modal>

      <Card
        style={{
          ...cardStyle,
          background: blocked ? '#E3EDF7' : '#F6FBFF',
        }}
      >
        <Switch checked={blocked} onChange={handleBlockCash} />
        <Typography
          style={{
            color: '#2D2F39',
            fontSize: '13px',
            fontWeight: blocked ? 600 : 500,
            lineHeight: 1.2,
          }}
        >
          {blocked ? 'Viajes en efectivo desactivado' : 'Desactivar viajes en efectivo'}
        </Typography>
      </Card>

      {!blocked && (
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '6px', marginTop: '14px' }}>
          <Icon name="warningRounded" color="#FB0" size="20px" />
          <Typography
            style={{
              color: '#FFBB00',
              fontSize: '12px',
              fontWeight: 500,
              lineHeight: 1.2,
            }}
          >
            Al desactivar, no aceptarás pagos en efectivo, lo que puede reducir tus viajes. Puedes
            reactivar esta opción en cualquier momento.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

SwitchCard.propTypes = {
  blocked: PropTypes.bool,
  setBlocked: PropTypes.func,
  blockedBy: PropTypes.string,
};

SwitchCard.defaultProps = {
  blocked: false,
  setBlocked: () => {},
  blockedBy: '',
};

export default SwitchCard;
