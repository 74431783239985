import { azulTucar } from './TucarColors';

const button = {
  variants: [
    {
      props: { type: 'submit' },
      style: {
        height: '40px',
        borderRadius: 50,
        boxShadow: 0,
        color: '#FFFFFF',
        fontFamily: 'Poppins-Medium',
        fontWeight: '500',
        textTransform: 'initial',
        textAlign: 'center',
        lineHeight: 1,
        '&:hover': {
          opacity: 80,
          boxShadow: 0,
        },
      },
    },
    {
      props: { variant: 'text' },
      style: {
        height: '40px',
        color: '#999999',
        backgroundColor: '#FFFFFF',
        fontFamily: 'Poppins-Regular',
        fontSize: '14px',
        shadow: 0,
        textTransform: 'initial',
        '&:hover': {
          color: '#EDEBEB',
          backgroundColor: '#FFFFFF',
          boxShadow: 0,
        },
      },
    },
    {
      props: { className: 'lined' },
      style: {
        color: azulTucar,
        borderRadius: '50px',
        fontFamily: 'Poppins-Regular',
        fontWeight: '600',
        boxShadow: 0,
        textTransform: 'initial',
      },
    },
  ],
};

export default button;
