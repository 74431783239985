import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useOutletContext } from 'react-router-dom';

import { Box, Typography, Divider, IconButton } from '@mui/material';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';

import { getAuthStore, getDriverStore } from '../../../../graphql/store';
import TopBar from '../../../../components/ui/TopNav';
import ProfilePhoto from '../../../../../public/images/profileImage.svg';
import PersonalInfo from './PersonalInfo';
import AccountingInfo from './Accounting';
import Documents from './Documents';
import { azulTucar } from '../../../../themes/variants/TucarColors';
import GET_DRIVER_PHOTO from '../../../../graphql/querys/driver/getPhoto';
import ErrorModal from '../../../../components/ui/ErrorModal';

const OldProfile = () => {
  const [handlePageChange] = useOutletContext();

  useEffect(() => {
    handlePageChange(<TopBar title="Perfil" />, true);
  }, []);

  const {
    data: { uid, firstname, lastname, email },
  } = useReactiveVar(getAuthStore);
  const { photo } = useReactiveVar(getDriverStore);
  const [photoDriver, setPhotoDriver] = useState(photo);
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState('');

  const [getDriverPhoto, { data, loading }] = useLazyQuery(
    GET_DRIVER_PHOTO.query,
    GET_DRIVER_PHOTO.policies,
  );

  useEffect(() => {
    if (data) {
      setPhotoDriver(data.getDriver.photo);
    }
  }, [loading, data]);

  const handleUpload = (file) => {
    const dataUp = new FormData();
    dataUp.append('files', file);
    dataUp.append('id', uid);
    const config = {
      method: 'post',
      url: `${process.env.DOCUMENT_GATEWAY_URL}/api/v1/cl/driver/attach-photo`,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Apikey ${process.env.DOCUMENT_GATEWAY_API_KEY}`,
      },
      data: dataUp,
    };
    axios(config)
      .then(async () => {
        await getDriverPhoto({ variables: { uid } });
      })
      .catch(() => {
        setContent(
          'Ups, ha ocurrido un error de nuestra parte. Contacta a soporte pinchando en el chat',
        );
        setOpen(true);
      });
  };

  return (
    <Box>
      <ErrorModal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        content={content}
        title="Error al subir imagen"
      />

      <Box
        marginBottom="20px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Box sx={{ position: 'relative', marginBottom: '10px' }}>
          <IconButton
            type="submit"
            component="label"
            sx={{ position: 'absolute', right: '0px', bottom: '0px' }}
          >
            <input
              hidden
              accept="image/*"
              onChange={(e) => {
                handleUpload(e.target.files[0]);
              }}
              type="file"
            />
            <AddCircleSharpIcon
              sx={{ borderRadius: '55px', backgroundColor: '#FFF', color: azulTucar }}
            />
          </IconButton>
          <img
            src={photoDriver || ProfilePhoto}
            alt="profilePhoto"
            style={{ objectFit: 'cover', height: '120px', width: '120px', borderRadius: '60px' }}
          />
        </Box>

        <Typography sx={{ lineHeight: 1, marginTop: '10px' }} fontFamily="Poppins-Medium">
          {firstname} {lastname}
        </Typography>
        <Typography
          sx={{ fontSize: '12px', lineHeight: 1, marginTop: '5px', color: '#999999' }}
          fontFamily="Poppins-Regular"
        >
          {email}
        </Typography>
      </Box>
      <Divider
        sx={{
          marginBottom: '20px',
          marginTop: '20px',
          borderBottomWidth: '2px',
          borderBottomColor: '#F9F9F9',
        }}
      />
      <PersonalInfo />
      <Documents setMessageError={setContent} setOpenError={setOpen} />
      <AccountingInfo />
    </Box>
  );
};

export default OldProfile;
