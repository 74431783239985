import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './src/App';
import reportWebVitals from './reportWebVitals';
import { register } from './src/helpers/sw/connection';

if ('serviceWorker' in navigator) {
  // eslint-disable-next-line no-console
  register().catch((e) => console.log(e));
}

ReactDOM.createRoot(document.getElementById('app')).render(<App />);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// eslint-disable-next-line no-console
if (process.env.MODE === 'development') reportWebVitals(console.log);
