import React, { useEffect } from 'react';
import { Box, Divider, useMediaQuery } from '@mui/material';
import { useNavigate, useOutletContext } from 'react-router-dom';
import WaitNextProcess from '../../../components/extras/nextStep';
import OnBoardingTitle from '../../../components/extras/OnboardingTitle';

function Thanks() {
  const navigate = useNavigate();
  const [handlePageChange] = useOutletContext();
  const match = useMediaQuery('(max-width:700px)');

  useEffect(() => {
    handlePageChange();
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'column wrap',
        height: '100%',
        justifyContent: 'center',
        gap: '20px',
        backgroundColor: '#FFF',
      }}
    >
      <Box
        sx={{
          paddingInline: match ? '20px' : '0px',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
        }}
      >
        <OnBoardingTitle
          title="¡Cuenta creada con éxito!"
          subtitle="Ahora vamos a completar tu información."
          showLogo
        />
        <Divider
          sx={{
            width: '100%',
            color: '#EAEAEA',
          }}
        />
      </Box>
      <WaitNextProcess
        text=""
        handlerButton={() => {
          navigate('/app/onboarding', { replace: true });
        }}
      />
    </Box>
  );
}

export default Thanks;
