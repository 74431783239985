import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, ButtonGroup, Button, IconButton } from '@mui/material';
import { useReactiveVar, useMutation } from '@apollo/client';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { format } from 'date-fns';

import { getAuthStore } from '../../../graphql/store';
import UPDATE_SHIFT_PETITION from '../../../graphql/mutations/plan/updateShiftPetition';
import LoadingProgress from '../../../components/ui/LoadingProgress';
import {
  standardWeekDaysNames,
  weekObjOneLetter,
  textColorStatus,
  translatePetitionStatus,
} from './petitionsAuxs';

const cardStyle = {
  width: '100%',
  minHeight: '160px',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  alignItems: 'center',
  padding: '15px 10px',
  borderRadius: '15px',
  boxShadow: '2px 5px 5px #ddd, -1px 1px 5px #ddd, -1px -1px 5px #ddd, 1px -1px 5px #ddd',
};

const PetitionBox = ({ petition, shiftInfo, reload }) => {
  const {
    _id: petitionId,
    status,
    createdAt,
    petitioner,
    participants,
    rollbackDate,
    expiration,
  } = petition;

  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);

  const imResolver = uid !== petitioner && status === 'created';
  const myConfig =
    uid !== petitioner
      ? participants.filter((elem) => elem.id !== petitioner)[0]
      : participants.filter((elem) => elem.id === petitioner)[0];

  const myShifts = myConfig.futureShifts.reduce((acc, { _id: idShift }) => [...acc, idShift], []);
  const dayChange = (new Date(createdAt).getDay() + 6) % 7;

  const getCustomDate = (delay) => {
    const newDate = new Date(createdAt);
    newDate.setDate(newDate.getDate() + delay);
    return `${newDate.getDate()}`;
  };

  const twistedWeek = standardWeekDaysNames
    .slice(dayChange)
    .concat(standardWeekDaysNames.slice(0, dayChange));

  const ColorMapSorted = twistedWeek.map((weekDayName, index) => {
    const weekObject = {
      monday: 0,
      tuesday: 1,
      wednesday: 2,
      thursday: 3,
      friday: 4,
      saturday: 5,
      sunday: 6,
    };
    return {
      shift: shiftInfo[myShifts[weekObject[weekDayName]]],
      weekDay: weekObjOneLetter[(index + dayChange) % 7],
      dayChange,
      changeDate: getCustomDate(index),
    };
  });

  const ColorMap = ColorMapSorted.slice(-dayChange).concat(ColorMapSorted.slice(0, -dayChange));
  Object.values(myConfig.futureShifts).map((shiftId) => shiftInfo[shiftId]);

  const [updatePetition, { data, loading, error }] = useMutation(
    UPDATE_SHIFT_PETITION.mutation,
    UPDATE_SHIFT_PETITION.policies,
  );

  const buttonContent = ColorMap.map((colorConf, index) => {
    const disableCondition = index === dayChange || !['created', 'accepted'].includes(status);
    return (
      <ButtonGroup
        key={`${getCustomDate(index)}`}
        orientation="vertical"
        aria-label="outlined primary button group"
      >
        <Button
          disabled
          disableRipple
          size="small"
          variant="text"
          sx={{ color: 'black', '&:disabled': { color: 'black' } }}
        >
          {colorConf.changeDate}
        </Button>
        <Button
          disabled={disableCondition}
          disableElevation
          disableRipple
          sx={{ borderRadius: '4px 4px 0px 0px !important' }}
          size="small"
          variant={['AM', 'ALL'].includes(colorConf.shift) ? 'contained' : 'outlined'}
        >
          AM
        </Button>
        <Button
          disabled={disableCondition}
          disableElevation
          disableRipple
          size="small"
          variant={['PM', 'ALL'].includes(colorConf.shift) ? 'contained' : 'outlined'}
        >
          PM
        </Button>
      </ButtonGroup>
    );
  });

  const responsePetition = (accept) => {
    updatePetition({
      variables: {
        participantId: uid,
        petitionId,
        accepted: accept,
      },
    });
  };

  useEffect(() => {
    if (!loading && data?.updateShiftChangePetition) {
      reload();
    }
  }, [loading, data]);

  if (error) {
    return (
      <Box sx={{ ...cardStyle, alignItems: 'center', justifyContent: 'center' }}>
        <Typography
          sx={{
            textAlign: 'center',
            fontFamily: 'Poppins-Regular',
            fontSize: '14px',
            color: '#000',
            width: '100%',
          }}
        >
          Ocurrió un error, inténtalo de nuevo más tarde.
        </Typography>
      </Box>
    );
  }

  if (loading) {
    return (
      <Box sx={{ ...cardStyle, height: '160px' }}>
        <LoadingProgress allScreen={false} heightContainer="160px" />
      </Box>
    );
  }

  return (
    <Box sx={cardStyle}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: imResolver ? 'max-content 1fr max-content' : '1fr',
          alignItems: 'center',
          width: '100%',
        }}
      >
        {imResolver && (
          <IconButton size="large" onClick={() => responsePetition(true)}>
            <CheckCircleIcon color="success" sx={{ fontSize: '30px' }} />
          </IconButton>
        )}

        <Typography
          sx={{
            textAlign: 'center',
            fontFamily: 'Poppins-SemiBold',
            fontSize: '18px',
            color: textColorStatus(status),
          }}
        >
          {translatePetitionStatus(status)}
        </Typography>

        {imResolver && (
          <IconButton size="large" onClick={() => responsePetition(false)}>
            <CancelIcon color="error" sx={{ fontSize: '30px' }} />
          </IconButton>
        )}
      </Box>

      {!['returned', 'rejected', 'expired'].includes(status) && (
        <Typography
          sx={{
            textAlign: 'center',
            fontFamily: 'Poppins-Regular',
            fontSize: '14px',
            color: '#000',
            width: '100%',
          }}
        >
          {status === 'created'
            ? `Vence el ${format(new Date(expiration), 'dd/MM/yyyy')}`
            : `Válida hasta ${format(new Date(rollbackDate), 'dd/MM/yyyy')}`}
        </Typography>
      )}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '3px',
          flexWrap: 'wrap',
        }}
      >
        {buttonContent}
      </Box>
    </Box>
  );
};

PetitionBox.propTypes = {
  petition: PropTypes.shape({
    _id: PropTypes.string,
    rollbackDate: PropTypes.string,
    expiration: PropTypes.string,
    status: PropTypes.string,
    createdAt: PropTypes.string,
    petitioner: PropTypes.string,
    participants: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  shiftInfo: PropTypes.shape({}),
  reload: PropTypes.func,
};

PetitionBox.defaultProps = {
  petition: { _id: '', status: '', createdAt: '', petitioner: '', participants: [] },
  shiftInfo: {},
  reload: () => {},
};

export default PetitionBox;
