/* eslint-disable import/prefer-default-export */
import { docsStore } from './model';

export const setDocs = (newDocs) => {
  const newDocsDict = {};
  for (let i = 0; i < newDocs.length; i += 1) {
    const { name } = newDocs[i];
    newDocsDict[name] = newDocs[i];
  }
  docsStore({ ...docsStore(), ...newDocsDict });
};

export const resetDocs = () => {
  docsStore({
    ...docsStore(),
    cedulaIdentidadFront: false,
    cedulaIdentidadBack: false,
    certificadoAntecedentes: false,
    hojaVida: false,
    licenciaConducirFront: false,
    licenciaConducirBack: false,
  });
};
