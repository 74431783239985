import React from 'react';
import PropTypes from 'prop-types';

import { Box, Typography } from '@mui/material';
import endFormImage from '../../../../../../public/images/shared-form/end-form.svg';
import endFormReceiver from '../../../../../../public/images/shared-form/end-bye.svg';
import { verdeIcon, verdeText } from '../../../../../themes/variants/TucarColors';

const EndForm = ({ color, data }) => {
  if (color === verdeIcon) {
    return (
      <Box
        sx={{
          height: '100%',
          minHeight: '100%',
          width: '100%',
          display: 'grid',
          gridTemplateRows: 'max-content 1fr',
        }}
      >
        <Box
          sx={{
            height: 'max-content',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          <Typography
            sx={{
              fontSize: '1rem',
              textAlign: 'left',
              fontFamily: 'Poppins-Medium',
              color,
            }}
          >
            ¡Listo, tu turno ha finalizado!
          </Typography>
          <Typography sx={{ fontSize: '14px', textAlign: 'left', fontFamily: 'Poppins-Regular' }}>
            Has terminado tu turno exitosamente. Recuerda siempre dejar el auto puntual, limpio y
            con un nivel de batería de al menos un 60%.
          </Typography>
        </Box>
        <Box
          sx={{
            height: '100%',
            minHeight: '100%',
            borderRadius: '55px',
            backgroundImage: `url(${endFormReceiver})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        />
      </Box>
    );
  }
  return (
    <Box
      sx={{
        height: '100%',
        minHeight: '100%',
        width: '100%',
        display: 'grid',
        gridTemplateRows: 'max-content 1fr',
      }}
    >
      <Box
        sx={{
          height: 'max-content',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
        }}
      >
        <Typography
          sx={{
            fontSize: '1rem',
            textAlign: 'left',
            fontFamily: 'Poppins-Medium',
            color,
          }}
        >
          ¡Ya estás listo, a manejar!
        </Typography>
        <Typography sx={{ fontSize: '14px', textAlign: 'left', fontFamily: 'Poppins-Regular' }}>
          {`Tu turno ha iniciado, Recuerda que a las ${data?.shiftInfo?.endTime} deberás entregar el auto en el lugar que
          te indiquemos.`}
        </Typography>
      </Box>
      <Box
        sx={{
          height: '100%',
          minHeight: '100%',
          borderRadius: '55px',
          backgroundImage: `url(${endFormImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      />
    </Box>
  );
};

EndForm.propTypes = {
  color: PropTypes.string,
  data: PropTypes.shape({
    shiftInfo: PropTypes.shape({
      startTime: PropTypes.string,
      endTime: PropTypes.string,
    }),
  }),
};

EndForm.defaultProps = {
  color: verdeText,
  data: {
    shiftInfo: {
      startTime: '-',
      endTime: '-',
    },
  },
};
export default EndForm;
