import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { BoxFlex, Button } from '@tucar/pit-stop';
import { useQuery, useReactiveVar } from '@apollo/client';
import InfoModal from '../infoModal';
import { getAuthStore } from '../../../graphql/store';
import { getHiddeNewVersionModal, setNewVersion, resetExtra } from '../../../graphql/store/extra';
import Emoji from '../../extras/emojis';
import GET_LABEL from '../../../graphql/querys/getLabel';

const NewVersionModal = () => {
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);

  const newVersion = getHiddeNewVersionModal() || false;
  const [open, setOpen] = useState(false);

  const { loading, data } = useQuery(GET_LABEL.query, {
    ...GET_LABEL.policies,
    variables: {
      labelId: 'DRIVER_APP_V2_TEST_2',
    },
  });

  const defaultMessage = (
    <Box
      sx={{
        padding: '20px 10px',
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <BoxFlex gap="5px" alignItems="center">
        <Typography
          sx={{
            textAlign: 'center',
            fontFamily: 'Poppins-SemiBold',
            fontSize: '20px',
            color: '#1F89FF',
          }}
        >
          ¡Hola, socio conductor!
        </Typography>
        <Emoji symbol="🚗" label="emoji" size="20px" />
      </BoxFlex>

      <Typography
        variant="span"
        sx={{
          textAlign: 'center',
          display: 'inline-block',
          fontFamily: 'Poppins-Medium',
          fontSize: '13px',
          color: '#5B5D71',
        }}
      >
        Este{' '}
        <Typography
          sx={{
            color: '#1F89FF',
            display: 'inline-block',
            fontFamily: 'Poppins-Medium',
            fontSize: '13px',
          }}
        >
          miercoles 4 de septiembre
        </Typography>
        , la aplicación Driver App se actualizará automáticamente con un nuevo diseño más intuitivo
        y fácil de usar. Ahora podrás acceder a tus funciones favoritas de manera más rápida y
        sencilla.
      </Typography>

      <Typography
        sx={{
          textAlign: 'center',
          fontFamily: 'Poppins-Medium',
          fontSize: '13px',
          color: '#5B5D71',
          marginBottom: '20px',
        }}
      >
        ¡Conduce con confianza y disfruta de las mejoras!
      </Typography>

      <Typography
        sx={{
          textAlign: 'center',
          fontFamily: 'Poppins-Medium',
          fontSize: '13px',
          color: '#5B5D71',
          marginBottom: '20px',
        }}
      >
        Equipo Tucar
      </Typography>

      <Button
        variant="inverted"
        onClick={() => {
          setNewVersion(true);
          setOpen(false);
        }}
      >
        ¡Me parece bien!
      </Button>
    </Box>
  );

  useEffect(() => {
    resetExtra();
  }, []);

  useEffect(() => {
    if (!loading && !newVersion && data?.getLabel) {
      setOpen(!data.getLabel.elements.includes(uid) || false);
    }
  }, [newVersion, loading]);

  if (loading || !data) return null;

  return (
    <InfoModal
      bgColor="linear-gradient(117deg, rgba(255, 255, 255, 0.00) 33.23%, rgba(255, 255, 255, 0.20) 61.83%), #E6EFF8"
      open={open}
      handleClose={() => setOpen(false)}
      content={defaultMessage}
    />
  );
};

export default NewVersionModal;
