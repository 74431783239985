export const plateFormatterHelper = (plate) => {
  const numberLetter = {
    b: 1,
    c: 2,
    d: 3,
    f: 4,
    g: 5,
    h: 6,
    j: 7,
    k: 8,
    l: 9,
    p: 0,
    r: 2,
    s: 3,
    t: 4,
    v: 5,
    w: 6,
    x: 7,
    y: 8,
    z: 9,
  };
  const valuesPos = [2, 3, 4, 5, 6, 7];
  const plateLetters = plate.slice(0, 4);
  const plateNumbers = plate.slice(4, 6);
  const ND =
    numberLetter[plateLetters[0].toLowerCase()].toString() +
    numberLetter[plateLetters[1].toLowerCase()].toString() +
    numberLetter[plateLetters[2].toLowerCase()].toString() +
    numberLetter[plateLetters[3].toLowerCase()].toString();
  const NV = (ND + plateNumbers).toString();
  const SP = NV.split('').reverse().join('');
  let R = 0;
  for (let index = 0; index < SP.length; index += 1) {
    R += parseInt(SP.charAt(index), 10) * valuesPos[index];
  }
  R %= 11;
  if (R === 0) {
    return `${plate}-${R}`;
  }

  const K = 11 - R;
  if (K === 10) {
    return `${plate}-K`;
  }
  return `${plate}-${K}`;
};

export const formatDate = (date) => {
  const translateDay = {
    1: 'Lunes',
    2: 'Martes',
    3: 'Miercoles',
    4: 'Jueves',
    5: 'Viernes',
    6: 'Sábado',
    7: 'Domingo',
  };
  const dt = new Date(date).getDay();
  const infoTime = date.split('T')[1].slice(0, -3);
  const infoDate = date.split('T')[0].split('-');
  const output = `${translateDay[dt]} ${infoDate[2]}/${infoDate[1]} - ${infoTime}`;

  return output;
};

export const addDays = (days) => {
  let date = new Date();
  const res = date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const tzoffset = new Date().getTimezoneOffset() * 60000; // offset in milliseconds

  date = new Date(res);
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);

  const localISOTime = new Date(date - tzoffset).toISOString().slice(0, -5);

  return localISOTime;
};
