import { gql } from '@apollo/client';
import { setPlan } from '../../store';

const GET_DRIVER_PLAN = {
  query: gql`
    query GetDriverPlan($driverId: ID!, $country: String!) {
      getConfigAccounting(country: $country) {
        currency {
          current
        }
        uberDiscount {
          Blue
          Diamond
          Gold
          Platinum
        }
      }
      getDriverBillDaysParsedByWeek(uid: $driverId, limit: 1, offset: 0) {
        instances {
          billDays {
            km
          }
          finalDatetime
          initialDatetime
        }
      }
      getDriverPlanV2(driverId: $driverId) {
        driver {
          createdAt
        }
        startDate
        selectedShifts {
          name
          startTime
          endTime
          basePrice
        }
        plan {
          electric
          name
          exclusive
          active
          includes
          excludes
          shifts {
            name
            startTime
            endTime
            basePrice
          }
          sections {
            startKm
            endKm
            variable
          }
        }
      }
    }
  `,
  policies: {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: 'true',
    onCompleted: (response) => {
      if (response.getDriverPlanV2) {
        setPlan(response.getDriverPlanV2);
      }
    },
  },
};

export default GET_DRIVER_PLAN;
