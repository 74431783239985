import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Box } from '@mui/material';
import WelcomeStep from './welcomeStep';
import NavBarTutorial from '../components/navBarTutorial';
import BaseDashboard from './BaseDashboard';
import { getStepNumber } from './aux';
import FinishStep from './finishStep';

const TutorialNewDashboard = () => {
  const [handlePageChange] = useOutletContext();
  useEffect(() => {
    handlePageChange(false, false, 'tutorial');
    document.getElementById('section-0').scrollIntoView({ behavior: 'auto' });
  }, []);

  const [navBarStep, setNavBarStep] = useState('');
  const [dashboardStep, setDashboardStep] = useState('');

  useEffect(() => {
    if (navBarStep !== '') {
      document
        .getElementById(`section-${getStepNumber(navBarStep)}`)
        .scrollIntoView({ behavior: 'auto' });
    }
  }, [navBarStep]);

  return (
    <Box sx={{ scrollSnapType: 'y mandatory', overflowY: 'none' }}>
      <Box
        id="section-0"
        sx={{
          position: 'relative',
          width: '100vw',
          height: 'calc(100vh)',
          overflow: 'hidden',
        }}
      >
        <WelcomeStep onNext={() => setNavBarStep('dashboard')} />
        <BaseDashboard />
        <NavBarTutorial />
      </Box>

      <Box
        id="section-1"
        sx={{ position: 'relative', width: '100vw', height: 'calc(100vh)', overflow: 'hidden' }}
      >
        <BaseDashboard />
        <NavBarTutorial step={navBarStep} onNext={() => setNavBarStep('subscription')} />
        <NavBarTutorial />
      </Box>

      <Box
        id="section-2"
        sx={{ position: 'relative', width: '100vw', height: 'calc(100vh)', overflow: 'hidden' }}
      >
        <BaseDashboard />
        <NavBarTutorial step={navBarStep} onNext={() => setNavBarStep('gainings')} />
        <NavBarTutorial />
      </Box>

      <Box
        id="section-3"
        sx={{ position: 'relative', width: '100vw', height: 'calc(100vh)', overflow: 'hidden' }}
      >
        <BaseDashboard />
        <NavBarTutorial step={navBarStep} onNext={() => setNavBarStep('liquidations')} />
        <NavBarTutorial />
      </Box>

      <Box
        id="section-4"
        sx={{ position: 'relative', width: '100vw', height: 'calc(100vh)', overflow: 'hidden' }}
      >
        <BaseDashboard />
        <NavBarTutorial
          step={navBarStep}
          onNext={() => {
            setNavBarStep('');
            setDashboardStep('SideBar');
          }}
        />
        <NavBarTutorial />
      </Box>

      <Box
        id="section-5"
        sx={{ position: 'relative', width: '100vw', height: 'calc(100vh)', overflow: 'hidden' }}
      >
        <BaseDashboard step={dashboardStep} onNext={() => setDashboardStep('Calendar')} />
        <NavBarTutorial />
      </Box>

      <Box
        id="section-6"
        sx={{ position: 'relative', width: '100vw', height: 'calc(100vh)', overflow: 'hidden' }}
      >
        <BaseDashboard step={dashboardStep} onNext={() => setDashboardStep('Gainings')} />
        <NavBarTutorial />
      </Box>

      <Box
        id="section-7"
        sx={{ position: 'relative', width: '100vw', height: 'calc(100vh)', overflow: 'hidden' }}
      >
        <BaseDashboard step={dashboardStep} onNext={() => setDashboardStep('Waiting')} />
      </Box>

      <Box
        id="section-8"
        sx={{ position: 'relative', width: '100vw', height: 'calc(100vh)', overflow: 'hidden' }}
      >
        <BaseDashboard step={dashboardStep} onNext={() => setDashboardStep('Shortcuts')} />
        <NavBarTutorial />
      </Box>

      <Box
        id="section-9"
        sx={{
          position: 'relative',
          width: '100vw',
          height: 'calc(100vh)',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column-reverse',
        }}
      >
        <BaseDashboard step={dashboardStep} onNext={() => setDashboardStep('UberKms')} />
        <NavBarTutorial />
      </Box>

      <Box
        id="section-10"
        sx={{
          position: 'relative',
          width: '100vw',
          height: 'calc(100vh)',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column-reverse',
        }}
      >
        <BaseDashboard step={dashboardStep} onNext={() => setDashboardStep('KmsGraph')} />
        <NavBarTutorial />
      </Box>

      <Box
        id="section-11"
        sx={{
          position: 'relative',
          width: '100vw',
          height: 'calc(100vh)',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column-reverse',
        }}
      >
        <BaseDashboard step={dashboardStep} onNext={() => setDashboardStep('Finish')} />
      </Box>

      <Box
        id="section-end"
        sx={{
          position: 'relative',
          width: '100vw',
          height: 'calc(100vh)',
          overflow: 'hidden',
        }}
      >
        <FinishStep />
      </Box>
    </Box>
  );
};

export default TutorialNewDashboard;
