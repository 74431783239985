import { gql } from '@apollo/client';

const LOCK_CAR = {
  mutation: gql`
    mutation LockCar($carId: ID!) {
      lockCar(carId: $carId)
    }
  `,
  policies: {
    fetchPolicy: 'no-cache',
  },
};

export default LOCK_CAR;
