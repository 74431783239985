import React from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Button, Icon, Typography } from '@tucar/pit-stop';
import { useReactiveVar } from '@apollo/client';
import { getDriverStore } from '../../../../../graphql/store';

const ShortCuts = () => {
  const navigate = useNavigate();
  const { status } = useReactiveVar(getDriverStore);
  const disableButtons = status.substatus === 'new rent queue';

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '15px',
        marginTop: '30px',
        width: '100%',
        justifyContent: 'center',
      }}
    >
      <Button
        disabled={disableButtons}
        size="small"
        onClick={() => navigate('/app/v2/trips', { replace: true })}
        style={{ padding: '12px 16px' }}
        contentStyle={{ columnGap: '4px' }}
      >
        <Icon name="cardTravelFilled" color={disableButtons ? '#8E90A4' : '#5B5D71'} size="14px" />
        <Typography
          color={(!disableButtons && '#5B5D71') || null}
          style={{ fontSize: '14px', fontWeight: 500 }}
        >
          Viajes
        </Typography>
      </Button>
      <Button
        disabled={disableButtons}
        size="small"
        style={{ padding: '12px 16px' }}
        onClick={() => navigate('/app/v2/tags', { replace: true })}
        contentStyle={{ columnGap: '4px' }}
      >
        <Icon
          name="directionsCarOutlined"
          color={disableButtons ? '#8E90A4' : '#5B5D71'}
          size="14px"
        />
        <Typography
          color={(!disableButtons && '#5B5D71') || null}
          style={{ fontSize: '14px', fontWeight: 500 }}
        >
          TAG
        </Typography>
      </Button>
      <Button
        disabled={disableButtons}
        size="small"
        onClick={() => navigate('/app/v2/vouchers', { replace: true })}
        style={{ padding: '12px 16px' }}
        contentStyle={{ columnGap: '4px' }}
      >
        <Icon
          name="attachMoneyOutlined"
          color={disableButtons ? '#8E90A4' : '#5B5D71'}
          size="14px"
        />
        <Typography
          color={(!disableButtons && '#5B5D71') || null}
          style={{ fontSize: '14px', fontWeight: 500 }}
        >
          Boletas
        </Typography>
      </Button>
    </Box>
  );
};

export default ShortCuts;
