import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useOutletContext } from 'react-router-dom';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { useReactiveVar, useQuery } from '@apollo/client';

import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';

import TopBar from '../../../components/ui/TopNav';

import Map from '../manageShift/Map';
import CardButtonDash from '../../../components/buttons/CardButtonDash';
import { getAuthStore, getExchangeStore } from '../../../graphql/store';
import LoadingProgress from '../../../components/ui/LoadingProgress';
import { verdeIcon, verdeText } from '../../../themes/variants/TucarColors';
import { plateFormatterHelper } from '../../../helpers/plateFormatterHelper';

import ParnerCarCard from '../manageShift/ParnerCarInfo';
import ModalExchange from '../ModalExchange';
import formatGetCouplesData from './auxFunctions';
import GET_COUPLES from '../../../graphql/querys/exchange/getCouples';
import LockUnlockCarButton from '../../../components/buttons/LockUnlockCar';

const ManageShared = () => {
  const [first, setFirst] = useState(true);
  const [handlePageChange] = useOutletContext();
  const matches = useMediaQuery('(max-width:300px)');
  const today = moment().day();
  const [content, setContent] = useState();
  const [formatInfo, setFormatInfo] = useState();
  const [open, setOpen] = useState(false);
  const [loadingButton, setLoadingButton] = useState(true);
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);
  const { completeForm, received } = useReactiveVar(getExchangeStore);

  const { data, loading, refetch } = useQuery(GET_COUPLES.query, {
    ...GET_COUPLES.policies,
    variables: {
      driverId: uid,
      limit: 1,
      offset: 0,
    },
  });

  useEffect(() => {
    if (completeForm && content && !open) {
      setOpen(true);
      setContent({ ...content, steps: [], im: received ? 'Receiver' : 'Deliverer' });
    }
  }, [received, completeForm, content]);

  useEffect(() => {
    if (formatInfo) {
      const auxContent = {};

      if (formatInfo.status === 'rent') {
        auxContent.color = verdeIcon;
        auxContent.title = 'Lugar de entrega del auto';
        auxContent.infoCard =
          formatInfo.meShift.startTime !== '-'
            ? {
                title: 'Hora de entrega:',
                content: `A las ${formatInfo.meShift.endTime} hrs.`,
              }
            : null;

        if (formatInfo.nextDriver) {
          auxContent.contactCard = {
            car: false,
            photo: formatInfo.nextDriver.photo,
            name: `${formatInfo.nextDriver.info.firstname} ${formatInfo.nextDriver.info.lastname}`,
            phone: formatInfo.nextDriver.info.phone,
            shiftName: formatInfo.nextDriver.todayShift.name,
          };
        } else if (formatInfo.carInfo) {
          auxContent.contactCard = {
            car: true,
            model: `${formatInfo.carInfo.brand} ${formatInfo.carInfo.model}`,
            plate: plateFormatterHelper(formatInfo.carInfo.plate),
          };
        }
      } else {
        auxContent.color = verdeText;
        auxContent.title = 'Lugar de retiro del auto';
        auxContent.infoCard =
          formatInfo.meShift.startTime !== '-'
            ? {
                title: 'Hora de retiro:',
                content: `A las ${formatInfo.meShift.startTime} hrs.`,
              }
            : null;
        if (formatInfo.prevDriver) {
          auxContent.contactCard = {
            car: false,
            photo: formatInfo.prevDriver.photo,
            name: `${formatInfo.prevDriver.info.firstname} ${formatInfo.prevDriver.info.lastname}`,
            phone: formatInfo.prevDriver.info.phone,
            shiftName: formatInfo.prevDriver.todayShift.name,
          };
        } else if (formatInfo.carInfo) {
          auxContent.contactCard = {
            car: true,
            model: `${formatInfo.carInfo.brand} ${formatInfo.carInfo.model}`,
            plate: plateFormatterHelper(formatInfo.carInfo.plate),
          };
        }
      }

      auxContent.map = {
        address: formatInfo.direction.address,
        location: {
          lat: parseFloat(formatInfo.direction.lat),
          lng: parseFloat(formatInfo.direction.lon),
        },
      };

      auxContent.shiftInfo = {
        startTime: formatInfo.meShift.startTime,
        endTime: formatInfo.meShift.endTime,
      };

      setContent({ ...auxContent });
    }
  }, [formatInfo]);

  useEffect(() => {
    if (!loading && data?.getCouples?.instances.length > 0) {
      const auxData = data.getCouples.instances[0];
      setLoadingButton(false);
      setFormatInfo(formatGetCouplesData(auxData, (today + 6) % 7, uid));
    }
  }, [data, loading]);

  useEffect(() => {
    handlePageChange(<TopBar title="Administrar turno" />, true);
  }, [open]);

  const timerContent = content?.infoCard ? (
    <Box
      sx={{
        display: 'flex',
        alignSelf: 'baseline',
        flexDirection: 'column',
        gap: '5px',
        marginTop: '5px',
      }}
    >
      <Typography
        sx={{
          textAlign: 'left',
          fontSize: '14px',
          lineHeight: 1,
          fontFamily: 'Poppins-SemiBold',
          color: '#7ecddf',
        }}
      >
        {content?.infoCard.title}
      </Typography>
      <Typography
        sx={{
          textAlign: 'left',
          fontSize: '12px',
          lineHeight: 1,
          fontFamily: 'Poppins-Regular',
          color: '#757575',
        }}
      >
        {content?.infoCard.content}
      </Typography>
    </Box>
  ) : null;

  if (loading || !content) {
    return <LoadingProgress allScreen={false} heightContainer="100%" />;
  }

  if (!timerContent) {
    return (
      <Box
        sx={{
          paddingInline: '20px',
          paddingBottom: '20px',
          height: '100%',
          minHeight: '100%',
          width: '100%',
          display: 'grid',
          gap: '10px',
          gridTemplateRows: 'max-content 1fr',
        }}
      >
        <LockUnlockCarButton />

        <Box sx={{ display: 'flex', flex: '1', alignItems: 'center', justifyContent: 'center' }}>
          <Typography
            sx={{
              fontFamily: 'Poppins-Regular',
            }}
          >
            Hoy no tienes turno
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        paddingInline: '20px',
        paddingBottom: '40px',
        height: '100%',
        minHeight: '100%',
        width: '100%',
        display: 'grid',
        gap: '10px',
        gridTemplateRows: 'max-content 1fr max-content',
      }}
    >
      <ModalExchange
        open={open}
        setOpen={setOpen}
        data={content}
        first={first}
        setFirst={setFirst}
        refetchData={refetch}
        loadingButton={loadingButton}
      />

      <Box>
        <LockUnlockCarButton />

        <Typography
          sx={{
            marginTop: '10px',
            height: '100%',
            verticalAlign: 'middle',
            fontFamily: 'Poppins-Medium',
            color: content.color,
          }}
        >
          {content?.title}
        </Typography>
      </Box>

      <Map location={content.map.location} address={content.map.address} color={content.color} />

      <Box xs={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
        <Box
          sx={{
            paddingTop: '10px',
            display: 'flex',
            gap: '10px',
            flexDirection: matches ? 'column' : 'row',
          }}
        >
          <CardButtonDash
            contentDirection="column"
            toGo={false}
            IconElement={TimerOutlinedIcon}
            styleProps={{ container: { width: matches ? '100%' : '50%' } }}
            colors={{ secondary: '#eef5f7', primary: '#7ecdde' }}
            content={timerContent}
            iconSize="2.5rem"
          />

          <Box
            sx={{
              width: matches ? '100%' : '50%',
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              overflowX: 'scroll',
              backgroundColor: '#dbe6f0',
              padding: '10px',
              borderRadius: '15px',
              justifyContent: 'center',
            }}
          >
            <ParnerCarCard car={content?.contactCard?.car} contactCard={content?.contactCard} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ManageShared;
