import React from 'react';
import PropTypes from 'prop-types';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, Typography, Modal, IconButton } from '@mui/material';
import { grisTucar2 } from '../../../themes/variants/TucarColors';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '90vw',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '15px',
  boxShadow: '0px 11px 22px #C1C1C1',
  textAlign: 'right',
  pt: 2,
  px: 2,
  pb: 3,
  height: '60vh',
};

const VideoModal = ({ open, handleClose, title, srcVideo }) => {
  return (
    <Modal open={open}>
      <Box sx={{ ...style }}>
        <IconButton sx={{ position: 'fixed', right: '10px', top: '10px' }} onClick={handleClose}>
          <ClearIcon color={grisTucar2} />
        </IconButton>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: '20px',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Poppins-Regular',
              fontSize: '1.2rem',
              lineHeight: 1.2,
              textAlign: 'center',
            }}
          >
            {title}
          </Typography>
          <Box sx={{ overflow: 'hidden', width: '90%', height: '50%' }}>
            <iframe
              width="100%"
              height="100%"
              src={srcVideo}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

VideoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  srcVideo: PropTypes.string.isRequired,
  title: PropTypes.string,
};

VideoModal.defaultProps = {
  title: '',
};
export default VideoModal;
