import React from 'react';
import PropTypes from 'prop-types';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import { Box } from '@mui/material';
import { azulTucar } from '../../../themes/variants/TucarColors';

const IconTitleCard = ({
  IconBubble,
  IconBubbleStyle,
  complexIconBubble,
  noIconBubble,
  containerStyle,
  contentText,
  title,
  spaceTop,
}) => {
  return (
    <Box
      sx={{
        paddingTop: noIconBubble ? '0' : spaceTop,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          ...IconBubbleStyle.container,
          visibility: noIconBubble ? 'hidden' : 'visible',
          position: 'absolute',
          top: '0%',
          left: '50%',
          width: '60px',
          height: '60px',
          borderRadius: '55px',
          boxShadow: '0',
        }}
      >
        {complexIconBubble ? (
          <Box
            sx={{
              ...IconBubbleStyle.icon,
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {IconBubble}
          </Box>
        ) : (
          <IconBubble
            sx={{
              position: 'absolute',
              ...IconBubbleStyle.icon,
              transform: 'translate(50%, 50%)',
              fontSize: '30px',
            }}
          />
        )}
      </Box>

      <Box
        sx={{
          ...containerStyle,
          width: '100%',
        }}
      >
        {title}

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          {contentText}
        </Box>
      </Box>
    </Box>
  );
};

IconTitleCard.propTypes = {
  spaceTop: PropTypes.string,
  IconBubble: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  contentText: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.node]).isRequired,
  complexIconBubble: PropTypes.bool,
  noIconBubble: PropTypes.bool,
  containerStyle: PropTypes.shape({}),
  IconBubbleStyle: PropTypes.shape({
    container: PropTypes.shape({}),
    icon: PropTypes.shape({}),
  }),
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

IconTitleCard.defaultProps = {
  spaceTop: '0px',
  title: null,
  IconBubble: DoneRoundedIcon,
  complexIconBubble: false,
  noIconBubble: false,
  containerStyle: { borderRadius: '7px', height: '130px', backgroundColor: azulTucar },
  IconBubbleStyle: {
    container: { backgroundColor: '#FFF', transform: 'translate(-50%, 0%)' },
    icon: { color: azulTucar },
  },
};

export default IconTitleCard;
