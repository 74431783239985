import exchangeStore from './model';

export const setCompleteForm = (completeForm) => {
  exchangeStore({ ...exchangeStore(), completeForm });
};

export const setReceived = (received) => {
  exchangeStore({ ...exchangeStore(), received });
};

export const setExchangeStore = (newExchange) => {
  exchangeStore({ ...exchangeStore(), ...newExchange });
};

export const resetExchange = () => {
  exchangeStore({ ...exchangeStore(), loading: false, error: false });
};
