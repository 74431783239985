import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Box, Collapse, IconButton } from '@mui/material';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';

import { formatterCLP } from '../../../../helpers/formatters';
import { azulTucar, blancoTucar } from '../../../../themes/variants/TucarColors';
import SubmitButton from '../../../../components/SubmitButton';
import IncludeCheckBox from '../../../../components/ui/checkCancelListBox';
import VideoModal from '../../../../components/extras/VideoModal';

const ContentPlanCard = ({
  onBoarding,
  buttonDisabled,
  showButton,
  fontColor,
  basePrice,
  currency,
  handleButton,
  tableContent,
  buttonColor,
  planId,
  shiftType,
  planIncludes,
  planExcludes,
}) => {
  const [open, setOpen] = useState(false);
  const [openVideo, setOpenVideo] = useState(false);

  const sendInfo = () => {
    handleButton(planId, shiftType);
  };
  return (
    <Box sx={{ display: 'flex', gap: '15px', flexDirection: 'column', width: '100%' }}>
      <VideoModal
        key="videoElectric"
        srcVideo="https://www.youtube.com/embed/0ccDYwHXQKI"
        title="¿Cómo funciona?"
        open={openVideo}
        handleClose={() => {
          setOpenVideo(false);
        }}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
        <Typography sx={{ fontSize: '14px' }} fontFamily="Poppins-Medium">
          Desde
        </Typography>
        <Typography
          variant="span"
          sx={{ display: 'inline-block', lineHeight: 1, color: fontColor, fontSize: '28px' }}
          fontFamily="Poppins-Medium"
        >
          {formatterCLP.format(basePrice * currency)}
          <Typography
            sx={{
              display: 'inline-block',
              color: '#adadad',
              lineHeight: 1,
              paddingLeft: '5px',
              fontSize: '12px',
            }}
            fontFamily="Poppins-Regular"
          >
            /semanal ({basePrice.toFixed(2)} UF)
          </Typography>
        </Typography>
        {tableContent.length > 0 && (
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography
              sx={{
                display: 'inline-block',
                lineHeight: 1,
                paddingLeft: '5px',
                fontSize: '13px',
              }}
              fontFamily="Poppins-Regular"
            >
              + variable por kilómetro adicional
            </Typography>
          </Box>
        )}
      </Box>

      <Collapse
        sx={{
          marginLeft: '0px',
        }}
        in
        timeout="auto"
        unmountOnExit
      >
        <Box margin="0px 0px 0px 0px" sx={{ overflowX: 'scroll' }}>
          {tableContent.map((elem) => elem)}
        </Box>
      </Collapse>

      {onBoarding && shiftType.includes('shared') && (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography sx={{ fontFamily: 'Poppins-Regular' }}>¿Cómo funciona?</Typography>
          <IconButton
            onClick={() => {
              setOpenVideo(true);
            }}
          >
            <KeyboardArrowDownOutlinedIcon />
          </IconButton>
        </Box>
      )}

      {(planIncludes.length > 0 || planExcludes.length > 0) && (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography sx={{ fontFamily: 'Poppins-Regular' }}>¿Qué incluye?</Typography>
          <IconButton onClick={() => setOpen(!open)}>
            {!open ? <KeyboardArrowDownOutlinedIcon /> : <KeyboardArrowUpOutlinedIcon />}
          </IconButton>
        </Box>
      )}
      <Collapse sx={{ overflow: 'scroll' }} in={open} timeout="auto" unmountOnExit>
        <IncludeCheckBox
          backgroundColor="#FFF"
          checkColor={fontColor}
          title=""
          include={planIncludes}
          excluye={planExcludes}
          combustion={false}
        />
      </Collapse>

      {showButton ? (
        <SubmitButton
          disabled={buttonDisabled}
          text={buttonDisabled ? 'No disponible' : '¡Lo quiero!'}
          color={blancoTucar}
          backgroundColor={buttonColor}
          handler={sendInfo}
        />
      ) : null}
    </Box>
  );
};

export default ContentPlanCard;

ContentPlanCard.propTypes = {
  onBoarding: PropTypes.bool,
  buttonDisabled: PropTypes.bool,
  showButton: PropTypes.bool,
  basePrice: PropTypes.number,
  currency: PropTypes.number,
  handleButton: PropTypes.func,
  tableContent: PropTypes.arrayOf(PropTypes.shape({})),
  buttonColor: PropTypes.string,
  fontColor: PropTypes.string,
  planId: PropTypes.string,
  shiftType: PropTypes.string,
  planIncludes: PropTypes.arrayOf(PropTypes.string),
  planExcludes: PropTypes.arrayOf(PropTypes.string),
};

ContentPlanCard.defaultProps = {
  onBoarding: false,
  buttonDisabled: false,
  showButton: true,
  basePrice: 0,
  currency: 0,
  fontColor: '#FFF',
  handleButton: () => {},
  tableContent: [],
  buttonColor: azulTucar,
  planId: '',
  shiftType: '',
  planIncludes: [],
  planExcludes: [],
};
