import React from 'react';
import { Box, Typography } from '@mui/material';
import { azulTucar } from '../../../themes/variants/TucarColors';
import { DefaultContent, upStepFunction, downStepFunction, focusBoxTutorial } from '../helpers';

const defaultTabsOutcomes = {
  container: {
    paddingTop: 0,
  },
  Tabs: {
    backgroundColor: 'rgba(0, 0, 0, .5)',
    paddingBottom: '20px',
    paddingTop: '10px',
  },
  firstTab: {
    fontFamily: 'Poppins-Regular',
    textTransform: 'initial',
    background: 'rgba(0, 0, 0, .5)',
    boxShadow: '0 0 0 max(100vh, 100vw) rgba(0, 0, 0, .5)',
  },
};

const defaultOutcomes = {
  ...defaultTabsOutcomes,
  TucarElement: {
    secondaryContent: {
      ...focusBoxTutorial,
      borderRadius: '7px',
    },
  },
  incomesBox: {
    display: 'none',
  },
};

const AllSteps = {
  1: {
    nextFunction: upStepFunction,
    prevFunction: () => {},
    styleElement: {
      Tabs: {
        ...focusBoxTutorial,
        border: '4px solid #FFF',
        borderRadius: '1rem',
        paddingBottom: '20px',
        paddingTop: '10px',
      },
    },
    title: 'Liquidaciones',
    content: (
      <DefaultContent
        textBlack="En Liquidaciones encontrarás el historial de todas las liquidaciones emitidas desde que te
      incorporas a la flota. En la parte superior encontrarás 3 diferentes estados."
      />
    ),
  },
  2: {
    nextFunction: upStepFunction,
    prevFunction: downStepFunction,
    styleElement: {
      Tabs: {
        ...focusBoxTutorial,
        backgroundColor: 'rgba(0, 0, 0, .5)',
        paddingBottom: '20px',
      },
      firstTab: {
        ...focusBoxTutorial,
        fontFamily: 'Poppins-Regular',
        textTransform: 'initial',
        marginInline: '10px',
        borderRadius: '1rem',
      },
    },
    title: 'Pendientes',
    content: (
      <DefaultContent
        textBlack="En “Pendientes” siempre va a aparecer una sola liquidación, que será la última emitida y
    vigente por pagar. Esta"
        textColor=" se emite todos los lunes y muestra el resultado obtenido de lo manejado la semana
    anterior."
      />
    ),
  },
  3: {
    nextFunction: upStepFunction,
    prevFunction: downStepFunction,
    styleElement: {
      ...defaultTabsOutcomes,
      primaryContent: {
        resume: {
          ...focusBoxTutorial,
          padding: '10px',
          borderRadius: '7px',
        },
      },
    },
    title: 'Fecha liquidación',
    content: (
      <DefaultContent
        textBlack="Al lado izquierdo aparece el nombre de la liquidación con la"
        textColor=" fecha de la semana que corresponde ese resultado."
      />
    ),
  },
  4: {
    nextFunction: upStepFunction,
    prevFunction: downStepFunction,
    styleElement: {
      ...defaultTabsOutcomes,
      TucarElement: {
        rightBox: {
          ...focusBoxTutorial,
          padding: '10px 0px 10px 10px',
          borderRadius: '7px',
        },
      },
    },
    title: 'Fecha de emisión',
    content: (
      <DefaultContent
        textBlack="Al lado derecho, te mostraremos el resultado semanal obtenido con la"
        textColor=" fecha de emisión de la liquidación."
      />
    ),
  },
  5: {
    nextFunction: upStepFunction,
    prevFunction: downStepFunction,
    styleElement: {
      ...defaultOutcomes,
      incomesBox: {
        ...focusBoxTutorial,
        padding: '5px',
        borderRadius: '7px',
      },
    },
    title: 'Recaudaciones',
    content: (
      <DefaultContent
        textBlack="En “Recaudaciones” verás los ingresos obtenidos manejando en apps de transporte de
      pasajeros y reembolsos que realicemos de Tucar hacia ti. De momento solo operamos con la
      app de Uber Driver."
      />
    ),
  },
  6: {
    nextFunction: upStepFunction,
    prevFunction: downStepFunction,
    styleElement: {
      ...defaultOutcomes,
    },
    title: 'Descuentos',
    content: (
      <DefaultContent textBlack="En “Descuentos”, verás todos los costos asociados a tu arriendo." />
    ),
  },
  7: {
    nextFunction: upStepFunction,
    prevFunction: downStepFunction,
    component: '',
    styleElement: {
      ...defaultOutcomes,
      row: {
        km: {
          ...focusBoxTutorial,
          borderRadius: '7px',
        },
      },
    },
    title: 'Km recorridos',
    content: (
      <DefaultContent
        textBlack="Km recorridos son todos los kilómetros que anduviste desde el lunes al domingo de la
      semana anterior."
      />
    ),
  },
  8: {
    nextFunction: upStepFunction,
    prevFunction: downStepFunction,
    component: '',
    styleElement: {
      ...defaultOutcomes,
      row: {
        rent: {
          ...focusBoxTutorial,
          borderRadius: '7px',
        },
      },
    },
    title: 'Costo del plan',
    content: (
      <DefaultContent
        textBlack="Costo del plan es el costo base semanal, más el costo variable, el que dependerá de los km
        recorridos."
      />
    ),
  },
  9: {
    nextFunction: upStepFunction,
    prevFunction: downStepFunction,
    component: '',
    styleElement: {
      ...defaultOutcomes,
      row: {
        tag: {
          ...focusBoxTutorial,
          borderRadius: '7px',
        },
      },
    },
    title: 'TAG',
    content: <DefaultContent textBlack="El TAG se calcula por medio del GPS del auto." />,
  },
  10: {
    nextFunction: upStepFunction,
    prevFunction: downStepFunction,
    component: '',
    styleElement: {
      ...defaultOutcomes,
      row: {
        fines: {
          ...focusBoxTutorial,
          borderRadius: '7px',
        },
      },
    },
    title: 'Multas',
    content: (
      <DefaultContent
        textBlack="Las multas de tránsito son infracciones o partes emitidos al vehículo mientras esté en tu
    poder."
      />
    ),
  },
  11: {
    nextFunction: upStepFunction,
    prevFunction: downStepFunction,
    component: '',
    styleElement: {
      ...defaultOutcomes,
      row: {
        penaltys: {
          ...focusBoxTutorial,
          borderRadius: '7px',
        },
      },
    },
    title: 'Penalizaciones',
    content: (
      <DefaultContent
        textBlack="Las Penalizaciones son multas emitidas por Tucar por incumplimiento de los Términos de
        Servicio."
      />
    ),
  },
  12: {
    nextFunction: upStepFunction,
    prevFunction: downStepFunction,
    component: '',
    styleElement: {
      ...defaultOutcomes,
      row: {
        km: { display: 'none' },
        guarantee: {
          ...focusBoxTutorial,
          borderRadius: '7px',
        },
      },
    },
    title: 'Garantía',
    content: (
      <DefaultContent
        textBlack="En Garantía irán apareciendo semanalmente las cuotas pendientes en caso de que hayas
        pactado al iniciar tu arriendo."
      />
    ),
  },
  13: {
    nextFunction: upStepFunction,
    prevFunction: downStepFunction,
    component: '',
    styleElement: {
      ...defaultOutcomes,
      row: {
        km: { display: 'none' },
        rent: { display: 'none' },
        debt: {
          ...focusBoxTutorial,
          borderRadius: '7px',
        },
      },
    },
    title: 'Deudas',
    content: (
      <DefaultContent
        textBlack="Si no alcanzaste a pagar la liquidación anterior, en Deudas verás reflejado el monto que
        quedó pendiente de pago."
      />
    ),
  },
  14: {
    nextFunction: upStepFunction,
    prevFunction: downStepFunction,
    component: '',
    styleElement: {
      ...defaultOutcomes,
      row: {
        km: { display: 'none' },
        rent: { display: 'none' },
        tag: { display: 'none' },
        other: {
          ...focusBoxTutorial,
          borderRadius: '7px',
        },
      },
    },
    title: 'Otros',
    content: (
      <DefaultContent
        textBlack="En otros cargos aparecerán cobros asociados a diferentes eventos que ocurran durante tu
        arriendo. Estos pueden ser deducibles, reajustes de liquidaciones y reparaciones, entre
        otras."
      />
    ),
  },
  15: {
    nextFunction: upStepFunction,
    prevFunction: downStepFunction,
    component: '',
    styleElement: {
      ...defaultOutcomes,
      outcomesBox: { display: 'none' },
      totalWeek: {
        ...focusBoxTutorial,
        borderRadius: '7px',
      },
    },
    title: 'Resultado semanal',
    content: (
      <Box sx={{ display: 'inline-block', textAlign: 'center' }}>
        <Typography
          sx={{
            display: 'inline',
            fontFamily: 'Poppins-Regular',
            fontSize: '14px',
          }}
        >
          El Resultado semanal es la diferencia entre las recaudaciones con los descuentos. Si el
          resultado es
        </Typography>
        <Typography
          sx={{
            display: 'inline',
            fontFamily: 'Poppins-Regular',
            fontSize: '14px',
            color: azulTucar,
          }}
        >
          {' '}
          negativo, deberás pagar durante el día martes.
        </Typography>
        <Typography
          sx={{
            display: 'inline',
            fontFamily: 'Poppins-Regular',
            fontSize: '14px',
          }}
        >
          {' '}
          Si el resultado es
        </Typography>
        <Typography
          sx={{
            display: 'inline',
            fontFamily: 'Poppins-Regular',
            fontSize: '14px',
            color: azulTucar,
          }}
        >
          {' '}
          positivo,
        </Typography>
        <Typography
          sx={{
            display: 'inline',
            fontFamily: 'Poppins-Regular',
            fontSize: '14px',
          }}
        >
          {' '}
          nosotros
        </Typography>
        <Typography
          sx={{
            display: 'inline',
            fontFamily: 'Poppins-Regular',
            fontSize: '14px',
            color: azulTucar,
          }}
        >
          {' '}
          te reembolsaremos tus ganancias el día jueves.
        </Typography>
      </Box>
    ),
  },
  16: {
    nextFunction: upStepFunction,
    prevFunction: downStepFunction,
    component: '',
    styleElement: {
      ...defaultOutcomes,
      outcomesBox: { display: 'none' },
      goToBalance: {
        ...focusBoxTutorial,
        borderRadius: '7px',
      },
    },
    title: 'Detalle',
    content: (
      <DefaultContent
        textBlack="Si quieres ver el detalle por día del resultado de la semana anterior, selecciona “Ver
      balance”."
      />
    ),
  },
  17: {
    nextFunction: (setter, step, setCurrentTab) => {
      setter(step + 1);
      setCurrentTab('paid');
    },
    prevFunction: downStepFunction,
    component: '',
    styleElement: {
      ...defaultOutcomes,
      outcomesBox: { display: 'none' },
      goToPayment: {
        ...focusBoxTutorial,
        borderRadius: '7px',
      },
    },
    title: 'Detalle',
    content: (
      <Box sx={{ display: 'inline-block', textAlign: 'center' }}>
        <Typography
          sx={{
            display: 'inline',
            fontFamily: 'Poppins-Regular',
            fontSize: '14px',
          }}
        >
          Si el resultado es
        </Typography>
        <Typography
          sx={{
            display: 'inline',
            fontFamily: 'Poppins-Regular',
            fontSize: '14px',
          }}
        >
          {' '}
          negativo, deberás pagar
        </Typography>
        <Typography
          sx={{
            display: 'inline',
            fontFamily: 'Poppins-Regular',
            fontSize: '14px',
            color: azulTucar,
          }}
        >
          {' '}
          el monto adeudado a Tucar, ingresando a la plataforma de pago a través del botón “Pagar” o
          directamente en el menú, en la sección de Pagos.
        </Typography>
      </Box>
    ),
  },
  18: {
    nextFunction: upStepFunction,
    prevFunction: (setter, step, setCurrentTab) => {
      setter(step - 1);
      setCurrentTab('pendient');
    },
    component: '',
    styleElement: {
      Tabs: {
        ...focusBoxTutorial,
        backgroundColor: 'rgba(0, 0, 0, .5)',
        paddingBottom: '20px',
      },
      firstTab: {
        ...focusBoxTutorial,
        fontFamily: 'Poppins-Regular',
        textTransform: 'initial',
        marginInline: '10px',
        borderRadius: '1rem',
      },
    },
    title: 'Pagadas',
    content: (
      <DefaultContent
        textBlack="En “Pagadas” encontrarás el historial de las liquidaciones pasadas que han sido pagadas,
      ya sea por ti (resultado negativo) o por Tucar (resultado positivo)."
      />
    ),
  },
  19: {
    nextFunction: (setter, step, setCurrentTab) => {
      setter(step + 1);
      setCurrentTab('overdue');
    },
    prevFunction: downStepFunction,
    component: '',
    styleElement: {
      ...defaultOutcomes,
      TucarElement: {
        iconButtonStyle: {
          ...focusBoxTutorial,
        },
      },
    },
    title: 'Detalle',
    content: (
      <DefaultContent
        textBlack="Si tocas la liquidación, se abrirá el desglose. Para ver el detalle por día, puedes
        seleccionar “Ver balance”."
      />
    ),
  },
  20: {
    nextFunction: upStepFunction,
    prevFunction: (setter, step, setCurrentTab) => {
      setter(step - 1);
      setCurrentTab('paid');
    },
    component: '',
    styleElement: {
      Tabs: {
        ...focusBoxTutorial,
        backgroundColor: 'rgba(0, 0, 0, .5)',
        paddingBottom: '20px',
      },
      firstTab: {
        ...focusBoxTutorial,
        fontFamily: 'Poppins-Regular',
        textTransform: 'initial',
        marginInline: '10px',
        borderRadius: '1rem',
      },
    },
    title: 'Vencidas',
    content: (
      <DefaultContent
        textBlack="En “Vencidas” encontrarás las liquidaciones que no fueron pagadas en su totalidad a tiempo
        y su plazo de pago ha caducado. Éstas"
        textColor=" aparecerán como deuda en la próxima liquidación emitida."
      />
    ),
  },
  21: {
    nextFunction: () => {},
    prevFunction: downStepFunction,
    component: '',
    styleElement: {
      ...defaultOutcomes,
      TucarElement: {
        iconButtonStyle: {
          ...focusBoxTutorial,
        },
      },
    },
    title: 'Detalle',
    content: (
      <DefaultContent
        textBlack="Si tocas la liquidación, se abrirá el desglose. Para ver el detalle por día, puedes
      seleccionar “Ver balance”."
      />
    ),
  },
};

export default AllSteps;
