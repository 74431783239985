export default function translateDocsName(name) {
  switch (name) {
    case 'cedulaIdentidadFront':
      return 'Carnet de identidad (frente)';
    case 'cedulaIdentidadBack':
      return 'Carnet de identidad (reverso)';
    case 'licenciaConducirFront':
      return 'Licencia de conducir (frente)';
    case 'licenciaConducirBack':
      return 'Licencia de conducir (reverso)';
    case 'certificadoAntecedentes':
      return 'Antecedentes penales';
    case 'hojaVida':
      return 'Hoja de vida del conductor';
    case 'padron':
      return 'Padron';
    case 'anotacionesVigentes':
      return 'Anotaciones vigentes';
    case 'poderNotarial':
      return 'Poder notarial';
    case 'permisoCirculacion':
      return 'Permiso de circulación';
    case 'soap':
      return 'SOAP';
    case 'revisionTecnicaMecanica':
      return 'Revisión técnica mecánica';
    case 'revisionTecnicaGases':
      return 'Revisión técnica gases';
    default:
      return '-';
  }
}
