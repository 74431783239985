/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, MobileStepper } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import { useMutation } from '@apollo/client';

import SubmitButton from '../../../components/SubmitButton';
import { verdeIcon, verdeText } from '../../../themes/variants/TucarColors';

import COMPLETE_STEP from '../../../graphql/mutations/exchange/completeStep';
import { handleUploadDamageReport, handleUploadPhotos, translateSteps } from './functions';
import { setCompleteForm, setReceived } from '../../../graphql/store';

const InitForm = ({ data, handerBack, loadingButton, refetchData }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [stepsForm, setStepsForm] = useState([]);
  const [stepsList, setStepsList] = useState([]);
  const [optionalListSteps, setOptionalList] = useState([]);
  const [dataDamage, setDataDamage] = useState();
  const [dataPhoto, setDataPhoto] = useState();
  const [selfLoad, setSelfLoad] = useState(false);

  const formColor = data.im === 'Receiver' ? verdeText : verdeIcon;
  const [codeValue, setCodeValue] = useState('');
  const [error, setError] = useState();
  const [completeStep] = useMutation(COMPLETE_STEP.mutation, COMPLETE_STEP.policies);

  const removeStep = () => {
    if (stepsForm.length > 1) {
      const actualStep = stepsList[activeStep];
      const newStepsForm = stepsForm.filter(({ stepKey }) => stepKey !== actualStep);
      const newStepsList = stepsList.filter((step) => step !== actualStep);
      setStepsList(newStepsList);
      setStepsForm(newStepsForm);
      setSelfLoad(false);
    } else {
      refetchData();
    }
  };

  async function nextActionStep(step) {
    setSelfLoad(true);
    if (step === 'endForm') {
      handerBack({ openAgain: false });
      setCompleteForm(false);
      setReceived();
      return true;
    }

    if (step === 'PickUp') {
      completeStep({
        variables: { step: stepsList[activeStep], driverId: data.id, exchangeId: data.exchangeId },
      })
        .then(() => {
          removeStep();
        })
        .catch((e) => {
          refetchData();
          console.log('error al enviar pickUp', e);
        });
      return true;
    }

    if (step === 'LeftCharging') {
      completeStep({
        variables: { step: stepsList[activeStep], driverId: data.id, exchangeId: data.exchangeId },
      })
        .then(() => {
          removeStep();
        })
        .catch((e) => {
          refetchData();
          console.log('error al enviar LeftCharging', e);
        });
      return true;
    }

    if (step === 'Photos') {
      await handleUploadPhotos(
        { ...dataPhoto, exchangeId: data.exchangeId },
        setSelfLoad,
        removeStep,
      ).catch(() => {
        setError(stepsList[activeStep]);
        setSelfLoad(false);
      });
      return true;
    }

    if (step === 'DamageReport') {
      await handleUploadDamageReport(
        { ...dataDamage, exchangeId: data.exchangeId },
        setSelfLoad,
        removeStep,
      ).catch(() => {
        setError(stepsList[activeStep]);
        refetchData();
        setSelfLoad(false);
      });
      return true;
    }

    if (step === 'CodeExchange') {
      completeStep({
        variables: {
          step: stepsList[activeStep],
          driverId: data.id,
          data: { code: codeValue },
          exchangeId: data.exchangeId,
        },
      })
        .then(() => {
          removeStep();
        })
        .catch((e) => {
          setError(stepsList[activeStep]);
          console.log('error al enviar security code', e);
        });
      return true;
    }

    completeStep({
      variables: { step: stepsList[activeStep], driverId: data.id, exchangeId: data.exchangeId },
    })
      .then(() => {
        removeStep();
      })
      .catch((e) => {
        setError(stepsList[activeStep]);
        console.log('error step', stepsList[activeStep], e);
      });
    return true;
  }

  const handleComplete = async () => {
    setSelfLoad(true);
    setError();
    await nextActionStep(stepsList[activeStep]);
  };

  useEffect(() => {
    if (data?.steps) {
      setActiveStep(0);
      const {
        stepsForm: auxStepsForm,
        stepsList: auxStepsList,
        optionalList: auxOptionalList,
      } = translateSteps(data.steps, data.im);
      setOptionalList(auxOptionalList);
      setStepsList(auxStepsList);
      setStepsForm(auxStepsForm);
      setSelfLoad(false);
    }
  }, [data]);

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ height: '100%', padding: '10px' }}>
      <Box sx={{ height: '100%', display: 'grid', gridTemplateRows: '1fr max-content' }}>
        <SwipeableViews
          containerStyle={{
            height: '100%',
            minHeight: '100%',
          }}
          slideStyle={{ padding: '10px' }}
          resistance
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {stepsForm.map(({ stepKey, StepComponent }) => (
            <StepComponent
              data={data}
              optional={optionalListSteps[activeStep] || false}
              setDataDamage={setDataDamage}
              setDataPhoto={setDataPhoto}
              key={stepKey}
              error={error}
              setError={setError}
              securityCode={data.im === 'Receiver' ? data.securityCode : ''}
              color={formColor}
              setCodeValue={setCodeValue}
            />
          ))}
        </SwipeableViews>

        <Box
          sx={{
            width: '100%',
            justifyContent: 'end',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <MobileStepper
              variant="dots"
              steps={stepsForm.length}
              position="static"
              activeStep={activeStep}
              sx={{
                maxWidth: 400,
                flexGrow: 1,
                '& .MuiMobileStepper-dotActive': { bgcolor: formColor },
              }}
            />
          </Box>

          {stepsList[activeStep] === 'endForm' ? (
            <SubmitButton
              disabled={loadingButton || selfLoad}
              variant="contained"
              color="#fff"
              hoverColor="#fff"
              backgroundColor={formColor}
              text={loadingButton || selfLoad ? 'Cargando' : 'Finalizar'}
              handler={handleComplete}
            />
          ) : null}

          {stepsList[activeStep] !== 'endForm' &&
          !(data.im === 'Receiver' && stepsList[activeStep] === 'CodeExchange') ? (
            <SubmitButton
              disabled={
                loadingButton ||
                selfLoad ||
                (stepsList[activeStep] === 'Photos' && dataPhoto?.files.length !== 4)
              }
              variant="outlined"
              color={error ? 'red' : formColor}
              hoverColor={error ? 'red' : formColor}
              backgroundColor="#FFF"
              text={loadingButton || selfLoad ? 'Cargando' : `${error ? 'Error' : 'Siguiente'}`}
              handler={handleComplete}
            />
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default InitForm;

InitForm.propTypes = {
  handerBack: PropTypes.func,
  data: PropTypes.shape({
    exchangeId: PropTypes.string,
    steps: PropTypes.arrayOf(PropTypes.shape({})),
    securityCode: PropTypes.string,
    im: PropTypes.string,
    id: PropTypes.string,
  }),
  loadingButton: PropTypes.bool,
  refetchData: PropTypes.func,
};

InitForm.defaultProps = {
  handerBack: () => {},
  data: {},
  loadingButton: true,
  refetchData: () => {},
};
