import { gql } from '@apollo/client';

const GET_CASH_BLOCKING = {
  query: gql`
    query GetDriverCashBlocking($driverId: ID!) {
      getDriverCashBlocking(driverId: $driverId) {
        blockByType
        blockByRef
        block
      }
      getBond(id: $driverId) {
        uberSupplier
      }
    }
  `,
  policies: {
    errorPolicy: 'ignore',
    fetchPolicy: 'no-cache',
  },
};

export default GET_CASH_BLOCKING;
