/* eslint-disable import/prefer-default-export */
import { planStore } from './model';

export const setPlan = (newPlan) => {
  planStore({ ...planStore(), ...newPlan });
};

export const resetPlan = () => {
  planStore({
    ...planStore(),
    planId: false,
    planType: false,
    startDate: false,
    discount: false,
    selectedShifts: [],
  });
};
