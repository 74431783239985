import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { Typography } from '@tucar/pit-stop';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import DetailCard from './detailCard';
import { getAuthStore } from '../../../../../graphql/store';

const dataFormatter = (data) => {
  const {
    installment,
    expenses: { guarantee },
  } = data;

  return {
    installment: installment || 0,
    guarantee: guarantee || 0,
  };
};

const CuotasSection = ({ empty, data, debt, prePay }) => {
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);

  const { installment, guarantee } = empty ? {} : dataFormatter(data, uid);

  if (empty || (debt === 0 && prePay === 0 && installment === 0 && guarantee === 0)) {
    return null;
  }

  return (
    <Box
      sx={{
        width: '100%',
        paddingTop: '20px',
        gap: '10px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '10px',
          alignItems: 'center',
          paddingBottom: '6px',
        }}
      >
        <TrendingUpIcon sx={{ color: '#0496FF', fontSize: '17px' }} />
        <Typography style={{ color: '#0496FF', fontWeight: 600, fontSize: '16px' }}>
          Cuotas
        </Typography>
      </Box>

      <DetailCard title="Deuda anterior" mode="cuotas" total={debt || 0} negativeMode />
      <DetailCard title="Deducible" mode="cuotas" total={installment || 0} negativeMode />
      <DetailCard title="Garantía" mode="cuotas" total={guarantee || 0} />
      <DetailCard title="Prepago" mode="cuotas" total={prePay || 0} />
    </Box>
  );
};

CuotasSection.propTypes = {
  empty: PropTypes.bool,
  data: PropTypes.shape({}),
  debt: PropTypes.number,
  prePay: PropTypes.number,
};

CuotasSection.defaultProps = {
  empty: false,
  data: {},
  debt: 0,
  prePay: 0,
};

export default CuotasSection;
