import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import PhotoCar from '../CarState/PhotoCar';
import { verdeText } from '../../../../../themes/variants/TucarColors';
import { getAuthStore } from '../../../../../graphql/store';

const photoList = [
  { title: 'Parte delantera', name: 'front' },
  { title: 'Parte trasera', name: 'back' },
  { title: 'Lateral izquierdo', name: 'left' },
  { title: 'Lateral derecho', name: 'right' },
];

const PhotosView = ({ color, setDataPhoto, optional }) => {
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);
  const [data, setData] = useState({ files: [], uid });

  return (
    <Box
      sx={{
        height: '100%',
        minHeight: '100%',
        width: '100%',
        display: 'grid',
        gridTemplateRows: 'max-content 1fr',
        gridTemplateColumns: '100%',
        gap: '10px',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <Typography
          sx={{
            fontSize: '1rem',
            textAlign: 'left',
            fontFamily: 'Poppins-Medium',
            color,
          }}
        >
          {`Reporte de estado del auto ${optional ? '(Opcional)' : ''}`}
        </Typography>

        <Typography sx={{ fontSize: '14px', textAlign: 'left', fontFamily: 'Poppins-Regular' }}>
          Sube 4 fotos para registrar el estado del auto por dentro y fuera:
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'grid',
          gap: '10px',
          gridTemplateColumns: '1fr 1fr',
          gridTemplateRows: '1fr 1fr',
          paddingBottom: '10px',
        }}
      >
        {photoList.map((elem) => {
          return (
            <PhotoCar
              key={elem.name}
              photoStep
              title={elem.title}
              name={elem.name}
              confirmPhoto={(fileInfo) => {
                const auxFiles = [...data.files];
                auxFiles.push(fileInfo);

                setDataPhoto({ ...data, files: auxFiles });
                setData({ ...data, files: auxFiles });
              }}
              removePhoto={(namePhoto) => {
                const auxFiles = data.files.filter((photoArray) => !photoArray.includes(namePhoto));
                setDataPhoto({ ...data, files: auxFiles });
                setData({ ...data, files: auxFiles });
              }}
            />
          );
        })}
      </Box>
    </Box>
  );
};

PhotosView.propTypes = {
  color: PropTypes.string,
  setDataPhoto: PropTypes.func,
  optional: PropTypes.bool,
};

PhotosView.defaultProps = {
  color: verdeText,
  setDataPhoto: () => {},
  optional: false,
};

export default PhotosView;
