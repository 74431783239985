import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import moment from 'moment';

import { azulTucar } from '../../../themes/variants/TucarColors';
import { formatterCLP } from '../../../helpers/formatters';

const DetailBox = ({ content, typeDetail }) => {
  const total = content.reduce((acc, curr) => {
    return acc + curr.amount;
  }, 0);

  const mappingContent = content.map((elem, ident) => {
    return (
      <Box
        // eslint-disable-next-line react/no-array-index-key
        key={`${elem.title}-${elem.date}-${ident}`}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
          paddingInline: '20px',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', paddingRight: '20px' }}>
          <Typography
            sx={{
              fontFamily: 'Poppins-Regular',
              color: '#808080',
              fontSize: '12px',
              lineHeight: 1,
            }}
          >
            {elem.title}
          </Typography>
          <Typography sx={{ fontFamily: 'Poppins-Regular', color: '#AAAAAA', fontSize: '12px' }}>
            {moment(elem.date).format('DD/MM/YYYY HH:mm')}
          </Typography>
        </Box>
        <Typography sx={{ fontFamily: 'Poppins-Regular', color: '#808080', fontSize: '14px' }}>
          {formatterCLP.format(elem.amount)}
        </Typography>
      </Box>
    );
  });

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px',
        }}
      >
        <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
          <Typography sx={{ fontSize: '14px', fontFamily: 'Poppins-Medium', color: azulTucar }}>
            Total de {typeDetail} del día
          </Typography>
        </Box>

        <Typography
          sx={{ fontSize: '14px', fontFamily: 'Poppins-Medium', color: azulTucar }}
          noWrap
        >
          {formatterCLP.format(total)}
        </Typography>
      </Box>
      <Box sx={{ overflow: 'auto', display: 'flex', flexDirection: 'column', gap: '10px' }}>
        {mappingContent}
      </Box>
    </Box>
  );
};

DetailBox.propTypes = {
  typeDetail: PropTypes.string,
  content: PropTypes.arrayOf(PropTypes.shape({})),
};

DetailBox.defaultProps = {
  typeDetail: 'incomes',
  content: [],
};

export default DetailBox;
