import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useReactiveVar } from '@apollo/client';
import { Box, Typography } from '@mui/material';

import { GetUberStatusRedirect } from '../../../helpers/url';
import QueryParams from '../../../components/QueryParams';
import LINK_UP_UBER from '../../../graphql/mutations/uber/linkUpUber';
import { getAuthStore, getGlobalStore, setLoading } from '../../../graphql/store';
import LoadingProgress from '../../../components/ui/LoadingProgress';

const LinkUpUber = () => {
  const query = QueryParams();
  const navigate = useNavigate();
  const { loading } = useReactiveVar(getGlobalStore);
  const { accessToken } = useReactiveVar(getAuthStore);
  const [linkUpUber, { error, data }] = useMutation(LINK_UP_UBER.mutation, {
    ...LINK_UP_UBER.policies,
    variables: {
      code: query.get('code'),
      redirectUrl: `${
        process.env.TUCAR_AUTH_SERVICE
      }/api/users/link-uber/${GetUberStatusRedirect()}`,
    },
  });

  useEffect(() => {
    if (error || data) {
      navigate('/app/onboarding', { replace: true });
    }
  }, [error, data]);

  useEffect(() => {
    if (!accessToken) {
      setLoading(true);
      linkUpUber();
    } else {
      setLoading(false);
      navigate('/thanks', { replace: true });
    }
  }, [accessToken]);

  return <Box>{loading ? <LoadingProgress /> : <Typography>Bienvenido</Typography>}</Box>;
};

export default LinkUpUber;
