import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useNavigate } from 'react-router-dom';
import { useReactiveVar, useQuery } from '@apollo/client';
import { Typography, Box } from '@mui/material';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import DirectionsCarFilledOutlinedIcon from '@mui/icons-material/DirectionsCarFilledOutlined';
import CardButtonDash from '../../components/buttons/CardButtonDash';
import { getAuthStore } from '../../graphql/store';
import GET_EXCHANGE_ADVICE from '../../graphql/querys/exchange/getAdviceExchange';
import LoadingProgress from '../../components/ui/LoadingProgress';

const TurnButton = ({ changeDirection }) => {
  const navigate = useNavigate();
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore) || { data: { uid: '' } };

  const { data, loading } = useQuery(GET_EXCHANGE_ADVICE.query, {
    ...GET_EXCHANGE_ADVICE.policies,
    variables: {
      driverId: uid,
      limit: 1,
      offset: 0,
    },
  });

  const [recevier, serReceiver] = useState(false);
  const [show, setShow] = useState(false);

  const textContainer = (
    <Typography
      variant="span"
      sx={{
        display: 'inline-block',
        fontFamily: 'Poppins-Regular',
        lineHeight: 1.2,
        fontSize: '0.875rem',
      }}
    >
      Completa aquí el{' '}
      <Typography
        sx={{
          display: 'inline-block',
          fontFamily: 'Poppins-SemiBold',
          lineHeight: 1.2,
          fontSize: '0.875rem',
        }}
      >
        formulario
      </Typography>{' '}
      para dar
      <Typography
        sx={{
          display: 'inline',
          fontFamily: 'Poppins-SemiBold',
          lineHeight: 1.2,
          fontSize: '0.875rem',
        }}
      >
        {recevier ? ' inicio ' : ' término '}
      </Typography>
      a tu turno.
    </Typography>
  );

  const textContainerShared = (
    <Typography
      variant="span"
      sx={{
        display: 'inline-block',
        fontFamily: 'Poppins-Regular',
        lineHeight: 1.2,
        fontSize: '0.875rem',
      }}
    >
      Encuentra aquí las{' '}
      <Typography
        sx={{
          display: 'inline-block',
          fontFamily: 'Poppins-SemiBold',
          lineHeight: 1.2,
          fontSize: '0.875rem',
        }}
      >
        funciones
      </Typography>{' '}
      para
      <Typography
        sx={{
          display: 'inline',
          fontFamily: 'Poppins-SemiBold',
          lineHeight: 1.2,
          fontSize: '0.875rem',
        }}
      >
        {' '}
        administrar el auto{' '}
      </Typography>
      en tu turno.
    </Typography>
  );

  useEffect(() => {
    if (data?.getExchanges?.total > 0) {
      const currentInstance = data.getExchanges.instances.filter(
        (elem) => elem.status === 'Started' || elem.status === 'Pending',
      );

      if (currentInstance.length > 0) {
        const { _id: recevierId } = currentInstance[0].receiver?.driver || { _id: '' };
        serReceiver(uid === recevierId);
        setShow(true);
      }
    }
  }, [data]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <LoadingProgress allScreen={false} heightContainter="100%" />
      </Box>
    );
  }

  if (show)
    return (
      <CardButtonDash
        handleGo={() => {
          navigate('/app/manageshift', { replace: true });
        }}
        colors={{ primary: '#225d3b', secondary: '#e6eddb' }}
        sendText="Completar"
        IconElement={CachedOutlinedIcon}
        content={textContainer}
      />
    );

  return (
    <CardButtonDash
      contentDirection={changeDirection ? 'column' : 'row'}
      handleGo={() => {
        navigate('/app/manageshare', { replace: true });
      }}
      colors={{ primary: '#225d3b', secondary: '#e6eddb' }}
      sendText="Ver más"
      IconElement={DirectionsCarFilledOutlinedIcon}
      content={textContainerShared}
    />
  );
};

TurnButton.propTypes = {
  changeDirection: PropTypes.bool,
};

TurnButton.defaultProps = {
  changeDirection: false,
};

export default TurnButton;
