import { addDays } from 'date-fns';
import moment from 'moment';
import { getStartOfWeek } from '../auxFunctions';

export const formatterString = (string) => string.charAt(0).toUpperCase() + string.slice(1, -1);

export const completeList = (currentDates, selectedDate) => {
  const currentList = [];

  let firstDate = new Date(getStartOfWeek(selectedDate, false));

  for (let i = 0; i < 7; i += 1) {
    if (!currentDates.includes(firstDate)) {
      currentList.unshift(firstDate);
      firstDate = addDays(firstDate, 1);
    }
  }

  return currentList;
};

export const getGraphMultiData = (data, selectedDate) => {
  const currentData = data.map((e) => ({
    aux: new Date(e.date),
    label: moment(e.date).format('DD'),
    title: formatterString(moment(e.date).format('ddd')),
    values: [
      Object.values(e.incomes).reduce(
        (acc, value) => (typeof value !== 'string' ? acc + value : acc),
        0,
      ),
      Object.values(e.expenses).reduce(
        (acc, value) => (typeof value !== 'string' ? acc + value : acc),
        0,
      ),
    ],
  }));

  const dates = completeList(
    data.map((e) => new Date(e.date)),
    selectedDate,
  );

  dates.map((date) => {
    if (!currentData.some((barData) => barData.label === moment(date).format('DD'))) {
      currentData.push({
        aux: date,
        label: moment(date).format('DD'),
        title: formatterString(moment(date).format('ddd')),
        values: [0, 0],
      });
    }
    return date;
  });

  currentData.sort((a, b) => a.aux - b.aux);

  return currentData;
};

export const getGraphData = (data, selectedDate, total, version = 'incomes') => {
  const currentData = data.map((e) => {
    const totalData = Object.values(version === 'incomes' ? e.incomes : e.expenses).reduce(
      (acc, value) => (typeof value !== 'string' ? acc + value : acc),
      0,
    );
    return {
      aux: new Date(e.date),
      percent: totalData / total,
      label: moment(e.date).format('DD'),
      title: formatterString(moment(e.date).format('ddd')),
      value: Math.floor(totalData),
    };
  });

  const dates = completeList(
    data.map((e) => new Date(e.date)),
    selectedDate,
  );

  dates.map((date) => {
    if (!currentData.some((barData) => barData.label === moment(date).format('DD'))) {
      currentData.push({
        aux: date,
        percent: 0,
        label: moment(date).format('DD'),
        title: formatterString(moment(date).format('ddd')),
        value: 0,
      });
    }
    return date;
  });

  currentData.sort((a, b) => a.aux - b.aux);
  return currentData;
};

export const getTotals = (billDays) => {
  const totalIncomes = billDays.reduce(
    (acc, e) =>
      acc +
      Object.values(e.incomes).reduce(
        (acc2, value) => (typeof value !== 'string' ? acc2 + value : acc2),
        0,
      ),
    0,
  );

  const totalExpenses = billDays.reduce(
    (acc, e) =>
      acc +
      Object.values(e.expenses).reduce(
        (acc2, value) => (typeof value !== 'string' ? acc2 + value : acc2),
        0,
      ),
    0,
  );

  const totalBoth = billDays.reduce((acc, e) => {
    const totalIncome = Object.values(e.incomes).reduce(
      (acc2, value) => (typeof value !== 'string' ? acc2 + value : acc2),
      0,
    );
    const totalExpense = Object.values(e.expenses).reduce(
      (acc2, value) => (typeof value !== 'string' ? acc2 + value : acc2),
      0,
    );
    if (totalIncome > totalExpense) {
      return acc > totalIncome ? acc : totalIncome;
    }
    return acc > totalExpense ? acc : totalExpense;
  }, 0);

  return { totalIncomes, totalExpenses, totalBoth };
};
