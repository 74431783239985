import { azulTucar, celesteTucar } from './TucarColors';

const typography = {
  variants: [
    {
      props: { variant: 'h1' },
      style: {
        textAlign: 'center',
        fontSize: 45,
        fontFamily: 'Poppins-Bold',
        letterSpacing: 0,
      },
    },
    {
      props: { variant: 'h2' },
      style: {
        color: '#333',
        textAlign: 'center',
        fontSize: '24px',
        fontFamily: 'Poppins-Medium',
        letterSpacing: 0,
      },
    },
    {
      props: { variant: 'h3' },
      style: {
        color: '#333',
        textAlign: 'center',
        fontSize: '16px',
        fontFamily: 'Poppins-Regular',
        letterSpacing: 0,
        lineHeight: 1.3,
      },
    },
    {
      props: { variant: 'remark' },
      style: {
        color: azulTucar,
        textAlign: 'center',
        fontSize: 16,
        fontFamily: 'Poppins-Regular',
        fontWeight: '600',
        letterSpacing: 0,
      },
    },
    {
      props: { variant: 'remark', type: 'link' },
      style: {
        color: celesteTucar,
      },
    },
    {
      props: { variant: 'text' },
      style: {
        color: '#000000',
        fontSize: 16,
        backgroundColor: 'transparent',
        fontFamily: 'Poppins-Regular',
        fontWeight: '400',
        textAlign: 'center',
        letterSpacing: 0,
      },
    },
    {
      props: { variant: 'p' },
      style: {
        color: '#000000',
        fontSize: 14,
        fontFamily: 'Poppins-Regular',
        backgroundColor: 'transparent',
        fontWeight: '300',
        letterSpacing: 0,
      },
    },
  ],
};

export default typography;
