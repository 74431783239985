import { gql } from '@apollo/client';

const GET_PAYMENTS = {
  query: gql`
    query GetPayments($ref: String, $status: [String]) {
      getPayments(ref: $ref, status: $status) {
        instances {
          title
          amount
          date
          payer
          description
        }
      }
    }
  `,
  policies: {
    fetchPolicy: 'network-only',
  },
};

export default GET_PAYMENTS;
