import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Box, Typography } from '@mui/material';
import { useReactiveVar, useQuery } from '@apollo/client';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useNavigate, useOutletContext } from 'react-router-dom';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';

import TopBar from '../../../components/ui/TopNav';
import IconTitleCard from '../../../components/info/IconTitleCard';
import { getAuthStore, getDriverStore } from '../../../graphql/store';
import { azulTucar } from '../../../themes/variants/TucarColors';
import LoadingProgress from '../../../components/ui/LoadingProgress';
import { formatterCLP } from '../../../helpers/formatters';
import GET_DRIVER_GUARANTEE from '../../../graphql/querys/plan/getGuarantee';
import ButtonCardIcon from './ButtonCardIcon';
import ErrorModal from '../../../components/ui/ErrorModal';
import GET_INSTALLMENTS from '../../../graphql/querys/guarantee/getInstallments';
import TucarElement from '../../../components/info/TucarElement';

const SubscriptionPlan = ({ onBoarding }) => {
  const [handlePageChange] = useOutletContext();

  useEffect(() => {
    if (!onBoarding) {
      handlePageChange(<TopBar title="Garantía" />, true);
    }
  }, []);

  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);
  const {
    info: { country },
  } = useReactiveVar(getDriverStore);
  const navigate = useNavigate();
  const [errorOpen, setErrorOpen] = useState(false);
  const [installments, setInstallments] = useState([]);
  const [userGuarantee, setUserGuarantee] = useState(0);
  const [debt, setDebt] = useState(0);
  const { data, loading, error } = useQuery(GET_DRIVER_GUARANTEE.query, {
    ...GET_DRIVER_GUARANTEE.policies,
    variables: {
      uid,
      country,
    },
  });
  const { data: installmentData, loading: installmentLoading } = useQuery(GET_INSTALLMENTS.query, {
    ...GET_INSTALLMENTS.policies,
    variables: { ref: uid },
  });

  useEffect(() => {
    if (!loading) {
      if (error) {
        setErrorOpen(true);
      }
    }
  }, [loading]);

  useEffect(() => {
    if (data?.getConfigAccounting && data?.getDriverAccounting) {
      setDebt(data.getConfigAccounting.guarantee.total - data.getDriverAccounting.guarantee.total);
      setUserGuarantee(data.getDriverAccounting.guarantee.origin.driver);
    }

    if (error) {
      setErrorOpen(true);
    }
  }, [data]);

  useEffect(() => {
    if (installmentData?.getInstallments) {
      const auxComponents = installmentData?.getInstallments.map((installment) => {
        return (
          <TucarElement
            key={installment.id}
            IconCard={InsertDriveFileOutlinedIcon}
            variant="card"
            primaryContent={{
              title: `Cuota ${moment(installment.chargeDate).format('DD [de] MMM [del] YYYY')}`,
              auxText: `${formatterCLP.format(installment.amount)}`,
              resume: 'Garantía',
              detail: '',
            }}
          />
        );
      });
      setInstallments(auxComponents.reverse());
    }
  }, [installmentData]);

  const contentCard = (
    <Box sx={{ display: 'flex', gap: '15px', flexDirection: 'column', width: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
        <Typography
          variant="span"
          sx={{
            textAlign: 'left',
            lineHeight: 1,
            color: 'white',
            fontSize: '20px',
          }}
          fontFamily="Poppins-Medium"
        >
          Total: {formatterCLP.format(userGuarantee)}
        </Typography>
      </Box>
    </Box>
  );

  if (!data || !data?.getDriverAccounting || loading || installmentLoading) {
    return <LoadingProgress allScreen={false} heightContainer="100%" />;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <ErrorModal
        open={errorOpen}
        onClose={() => {
          setErrorOpen(false);
        }}
        content="Favor de contactar con soporte para resolver el problema."
        title="Error en Garantía"
      />

      {debt < 0 && onBoarding && (
        <ButtonCardIcon
          onClick={() => {
            navigate('/app/payment', { replace: true });
          }}
          text={`Tienes una deuda de ${formatterCLP.format(
            debt,
          )} en la garantía. No podrás continuar manejando si no tienes tu garantía integra`}
          auxText="Pagar garantía"
          styleElement={{
            backgroundColor: '#EF483608',
            border: '1px solid',
            borderColor: '#EF4836',
          }}
          iconElem={<ErrorOutlineIcon sx={{ fontSize: '50px', color: '#EF4836' }} />}
          colorLink="#EF4836"
        />
      )}

      <Box sx={{ margin: '0px 30px' }}>
        <IconTitleCard
          noIconBubble
          containerStyle={{
            padding: '30px',
            minHeight: '100px',
            borderRadius: '15px',
            backgroundColor: azulTucar,
          }}
          contentText={contentCard}
        />
      </Box>

      <Typography sx={{ marginY: '20px', fontFamily: 'Poppins-Regular', fontSize: '14px' }}>
        Cuotas restantes:
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          overflowY: 'scroll',
        }}
      >
        {installments}
      </Box>
    </Box>
  );
};

SubscriptionPlan.propTypes = {
  onBoarding: PropTypes.bool,
};

SubscriptionPlan.defaultProps = {
  onBoarding: false,
};

export default SubscriptionPlan;
