import { gql } from '@apollo/client';

const UNLOCK_CAR = {
  mutation: gql`
    mutation UnlockCar($carId: ID!) {
      unlockCar(carId: $carId)
    }
  `,
  policies: {
    fetchPolicy: 'no-cache',
  },
};

export default UNLOCK_CAR;
