import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { useReactiveVar, useQuery } from '@apollo/client';
import { useOutletContext } from 'react-router-dom';
import moment from 'moment';

import TopBar from '../../../../components/ui/TopNav';
import { getAuthStore, getDriverStore } from '../../../../graphql/store';
import GET_DRIVER_PLAN from '../../../../graphql/querys/plan/getPlan';
import LoadingProgress from '../../../../components/ui/LoadingProgress';
import IncludeCheckBox from '../../../../components/ui/checkCancelListBox';
import PlanItemInfo from '../../../onBoarding/plan/auxComponents/PlanItemInfo';
import ErrorModal from '../../../../components/ui/ErrorModal';
import getPlanDisponibilityHours from '../../../../helpers/getPlanDisponibilityHours';
import ShowSelectedShifts from './ShowSelectedShifts';

moment.locale('es');

function selectInfoPlan(electric, exclusive, time) {
  if (electric && exclusive) {
    return { mode: 'electric-exclusive', color: '#6CC8DD', title: { primary: '', secondary: '' } };
  }
  if (!electric && exclusive) {
    return {
      mode: 'combustion-exclusive',
      color: '#0057B8',
      title: { primary: '', secondary: '' },
    };
  }

  return {
    mode: 'electric-shared',
    color: '#95D31C',
    title: {
      primary: `Disponibilidad ${getPlanDisponibilityHours(time.startTime, time.endTime)}hrs`,
      secondary: `${time.startTime} - ${time.endTime} hrs`,
    },
  };
}

const OldSubscriptionPlan = ({ onBoarding }) => {
  const [handlePageChange] = useOutletContext();
  const [infoPlan, setInfoPlan] = useState({
    mode: '',
    color: '',
    title: { primary: '', secondary: '' },
  });
  useEffect(() => {
    if (!onBoarding) {
      handlePageChange(<TopBar title="Plan de arriendo" />, true);
    }
  }, []);
  const [uberDiscount, setUberDiscount] = useState(0);

  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);
  const {
    category,
    info: { country },
    status,
  } = useReactiveVar(getDriverStore);
  const { uberPro } = category || { uberPro: 'Blue' };
  const [currency, setCurrency] = useState(0);
  const [time, setTime] = useState();
  const [prices, setPrices] = useState({ minimum: 0, maximum: 0, base: 0 });
  const [include, setInclude] = useState({ yes: [], no: [] });
  const [currentPlan, setCurrentPlan] = useState({});
  const [errorOpen, setErrorOpen] = useState(false);
  const { data, loading, error } = useQuery(GET_DRIVER_PLAN.query, {
    ...GET_DRIVER_PLAN.policies,
    variables: {
      driverId: uid,
      country,
    },
    context: {
      ...(['churned', 'revoked', 'rejected', 'on boarding'].includes(status?.status) && {
        ignorePattern: {
          pattern: ['notificationApi error: No notifications found for driver'],
        },
      }),
    },
  });

  useEffect(() => {
    if (!loading) {
      if (error) {
        setErrorOpen(true);
      }
    }
  }, [loading]);

  useEffect(() => {
    if (data && data.getDriverPlanV2) {
      const planAux = data.getDriverPlanV2.plan;
      const today = moment().day() - 1;

      const auxTimes = {
        startTime: data.getDriverPlanV2.selectedShifts[today].startTime,
        endTime: data.getDriverPlanV2.selectedShifts[today].endTime,
      };

      const auxInfoPlan = selectInfoPlan(
        planAux.electric,
        planAux.exclusive || planAux.shifts.length === 1,
        auxTimes,
      );

      setCurrentPlan({
        ...planAux,
        selectedShift: data.getDriverPlanV2.selectedShifts[today].name,
        selectedShifts: data.getDriverPlanV2.selectedShifts,
      });
      setInfoPlan(auxInfoPlan);
      setUberDiscount((uberPro && data?.getConfigAccounting?.uberDiscount[uberPro]) || 0);
      setCurrency(data.getConfigAccounting.currency.current);
      setTime(data.getDriverPlanV2.startDate);
      setInclude({ yes: planAux.includes, no: planAux.excludes });
      setPrices({
        base: data.getDriverPlanV2.selectedShifts[0].basePrice,
        minimum: planAux.sections[0].variable,
        maximum: planAux.sections.slice(-1)[0].variable,
      });
    }
    if (error) {
      setErrorOpen(true);
    }
  }, [data, loading]);

  if (!data?.getDriverPlanV2 || loading) {
    return (
      <Box sx={{ height: '100%' }}>
        <ErrorModal
          open={errorOpen}
          onClose={() => {
            setErrorOpen(false);
          }}
          content="Favor de contactar con soporte para resolver el problema."
          title="Error en plan de arriendo"
        />
        <LoadingProgress allScreen={false} heightContainer="100%" />
      </Box>
    );
  }
  return (
    <Box sx={{ marginBottom: '30px' }}>
      <ErrorModal
        open={errorOpen}
        onClose={() => {
          setErrorOpen(false);
        }}
        content="Favor de contactar con soporte para resolver el problema."
        title="Error en plan de arriendo"
      />
      <Box sx={{ margin: '0px 0px 0px 30px' }}>
        <Typography
          variant="span"
          sx={{ display: 'inline-block', lineHeight: 1 }}
          fontFamily="Poppins-Medium"
        >
          {currentPlan.name}
        </Typography>
        <Typography fontFamily="Poppins-Medium" sx={{ fontSize: '12px' }}>
          / Seleccionado desde el {moment(time).format('DD [de] MMMM, YYYY')}
        </Typography>
      </Box>

      <Box
        sx={{
          paddingInline: onBoarding ? '0px' : '20px',
          display: 'flex',
          flexDirection: 'column',
          gap: '30px',
        }}
      >
        <PlanItemInfo
          discount={uberDiscount}
          onBoarding={false}
          mode={infoPlan.mode}
          currency={currency}
          basePrice={prices.base}
          sections={currentPlan.sections}
          specialTitle={infoPlan.title}
        />

        <ShowSelectedShifts shifts={currentPlan.selectedShifts} />

        <IncludeCheckBox
          combustion={false}
          title="¿Qué incluye el plan?"
          include={include.yes}
          excluye={include.no}
          checkColor={infoPlan.color}
        />
      </Box>
    </Box>
  );
};

OldSubscriptionPlan.propTypes = {
  onBoarding: PropTypes.bool,
};

OldSubscriptionPlan.defaultProps = {
  onBoarding: false,
};
export default OldSubscriptionPlan;
