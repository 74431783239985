import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useReactiveVar, useQuery } from '@apollo/client';
import { Item, Icon, CircularProgress, Card, Loader } from '@tucar/pit-stop';
import autito from '../../../../../../public/images/cars/autoPromedio.png';

import { getAuthStore, getDriverStore } from '../../../../../graphql/store';
import GET_RENT from '../../../../../graphql/querys/car/getRent';
import GET_CAR_CONFIG from '../../../../../graphql/querys/plan/getCarConfig';
import { getDescription } from './carDescriptions';
import DocBox from '../../../../../components/info/DocBox';
import carViewStyles from './styles';
import EmptyView from '../../../../../components/extras/EmptyView';

const CarInfo = () => {
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);

  const { rent } = useReactiveVar(getDriverStore);

  const { loading: rentLoading } = useQuery(GET_RENT.query, {
    ...GET_RENT.policies,
    variables: { uid },
  });

  const { loading: carInfoLoading, data: carInfoData } = useQuery(GET_CAR_CONFIG.query, {
    ...GET_CAR_CONFIG.policies,
    skip: !rent?.carId,
    variables: {
      carId: rent?.carId,
    },
  });

  const [carInfo, setCarInfo] = useState({
    name: '',
    year: 0,
    performance: 0,
    description: '',
    charging: false,
    charge: 0,
    odometer: 0,
    documents: [],
  });

  useEffect(() => {
    if (
      carInfoData &&
      (carInfoData?.car?.carConfig || carInfoData?.getDocument || carInfoData?.getCarBasicTelemetry)
    ) {
      const config = carInfoData.car.carConfig;
      const description = getDescription(config.brand, config.model);
      const telemetryInfo = carInfoData?.getCarBasicTelemetry;

      const auxOdometer = telemetryInfo?.odometer?.odometer || 0;

      setCarInfo({
        name: `${config.brand} ${config.model}`,
        year: config.year,
        performance: config.performance,
        description,
        documents: carInfoData.getDocument?.files || [],
        charging: telemetryInfo?.charging || false,
        charge: telemetryInfo?.soc || 0,
        odometer: auxOdometer
          .toFixed(0)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
      });
    }
  }, [carInfoData]);

  if (rentLoading || carInfoLoading)
    return (
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Loader size={32} pointRadio="4px" />
      </Box>
    );

  if (!rent?.carId) {
    return (
      <Box sx={carViewStyles.centerView}>
        <EmptyView />
      </Box>
    );
  }

  return (
    <Box sx={carViewStyles.container}>
      <Typography sx={carViewStyles.carInfoName}>{carInfo.name}</Typography>

      <CircularProgress
        onClick={() => {}}
        photoSrc={autito}
        loadingMode={carInfo?.charging}
        value={carInfo?.charge > 0 ? carInfo?.charge : 0}
        size="230px"
      />

      {carInfo?.charging ? (
        <Box sx={{ display: 'flex', gap: '5px' }}>
          <Typography sx={carViewStyles.regularText}>El auto se está</Typography>
          <Typography sx={carViewStyles.mediumText}>cargando</Typography>
        </Box>
      ) : (
        <Box sx={{ display: 'flex', gap: '5px' }}>
          <Typography sx={carViewStyles.mediumText}>Carga restante</Typography>
          <Typography sx={carViewStyles.mediumText}>del vehículo</Typography>
        </Box>
      )}

      <Box sx={{ display: 'flex', gap: '24px' }}>
        <Item style={{ padding: '10px' }}>
          <Icon color="#5B5D71" size="30px" name="electricCarOutlined" />
        </Item>
        <Item style={{ padding: '10px' }}>
          <Icon color="#5B5D71" size="30px" name="speedOutlined" />
        </Item>
        <Item style={{ padding: '10px' }}>
          <Icon color="#5B5D71" size="30px" name="gearBoxOutlined" />
        </Item>
      </Box>

      {carInfo?.odometer > 0 && (
        <>
          <Typography sx={carViewStyles.kmTitle}>KM recorridos</Typography>
          <Box sx={{ display: 'grid', gridTemplateColumns: '1fr', width: '100%' }}>
            <Card>
              <Typography sx={carViewStyles.odometerInfo}>{carInfo?.odometer} km</Typography>
            </Card>
          </Box>
        </>
      )}

      {carInfo.description && (
        <Card style={carViewStyles.descriptionCard}>
          <Typography sx={carViewStyles.deductibleTitle}>Equipamiento</Typography>
          <Typography sx={{ textAlign: 'left', fontSize: '14px', fontFamily: 'Poppins-Regular' }}>
            {carInfo.description}
          </Typography>
        </Card>
      )}

      <Typography
        sx={{
          marginTop: '20px',
          ...carViewStyles.deductibleTitle,
        }}
      >
        Nuestros autos cuentan con dos tipos de deducibles en caso de siniestros:
      </Typography>

      <Box sx={carViewStyles.scrollBox}>
        <Card style={carViewStyles.deductibleCard}>
          <Typography sx={carViewStyles.deductibleTitle}>Deducible general</Typography>
          <Typography sx={carViewStyles.deducibleDescription}>
            Cobertura total para cualquier daño parcial al vehículo por 10 UF. Reembolsaremos la
            diferencia si el costo de reparación es menor.
          </Typography>
        </Card>
        <Card style={{ width: '225px', padding: '20px', justifyContent: 'unset', gap: '10px' }}>
          <Typography sx={carViewStyles.deductibleTitle}>Deducible pérdida total</Typography>
          <Typography sx={carViewStyles.deducibleDescription}>
            Si tienes un accidente que cause la pérdida total del vehículo, solo pagarás el valor
            del deducible, el cual tiene un valor de 30 UF. Contamos con facilidades de pago.
          </Typography>
        </Card>
      </Box>

      {carInfo?.documents.length && (
        <Box sx={{ width: '100%' }}>
          <Typography sx={carViewStyles.documentTitle}>Documentos</Typography>

          <Box
            sx={{
              ...carViewStyles.scrollDocsBox,
              gridTemplateColumns: `repeat(${carInfo.documents.length},1fr)`,
            }}
          >
            {carInfo.documents.map((elem) => (
              <DocBox key={elem.name} fileObj={elem} carDocs />
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CarInfo;
