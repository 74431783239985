/* eslint-disable import/no-mutable-exports */
import { ApolloClient, createHttpLink, InMemoryCache, concat, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import * as Sentry from '@sentry/react';

import REFRESH_TOKEN from '../graphql/querys/auth/refreshToken';
import { isExpired, setExpirationDate, getAuthStore, setAuth, setError } from '../graphql/store';

let client;

const httpLink = createHttpLink({ uri: process.env.API_GATEWAY });

const refreshTokenQuery = async (refreshToken) =>
  client.query({
    query: REFRESH_TOKEN.query,
    variables: { refreshToken, authProvider: 'Bearer' },
    ...REFRESH_TOKEN.policies,
  });

const buildErrorScope = (scope, operation, response) => {
  if (operation) {
    scope.setTag('kind', operation?.operationName);
    scope.setExtra('query', operation?.query?.loc?.source);
    scope.setExtra('variables', operation?.variables);
  }
  scope.setExtra('response', response?.data);
};

const handleAccessTokenError = () => {
  setAuth({
    accessToken: false,
    refreshToken: false,
    data: {
      email: false,
      firstname: false,
      lastname: false,
      uid: false,
      userType: false,
      verified: false,
    },
  });
  setError(true);
};

const getAccessToken = async (errorOrigin) => {
  const { refreshToken } = getAuthStore();
  if ((refreshToken && isExpired()) || errorOrigin) {
    try {
      setExpirationDate();
      const { data: queryData } = await refreshTokenQuery(refreshToken);
      const { accessToken, refreshToken: newRefreshToken, data } = queryData.refreshToken || {};
      if (data.userType.toLowerCase() === 'driver') {
        setAuth({ accessToken, refreshToken: newRefreshToken, data });
        return { accessToken };
      }
      handleAccessTokenError();
      return { accessToken: null };
    } catch {
      handleAccessTokenError();
      return { accessToken: null };
    }
  }
  return getAuthStore();
};

const authMiddleware = setContext(async (_, { headers, forRefreshToken }) => {
  const { accessToken } = forRefreshToken ? {} : await getAccessToken();
  return {
    headers: {
      ...headers,
      Authorization: accessToken && !forRefreshToken ? `Bearer ${accessToken}` : '',
    },
  };
});

const cache = new InMemoryCache({
  typePolicies: {
    getEventsByUser: {
      merge: true,
    },
  },
});

const errorLink = onError(({ graphQLErrors, networkError, operation, response, forward }) => {
  if (graphQLErrors) {
    const {
      headers: oldHeaders,
      forRefreshToken,
      ignoreError,
      ignorePattern,
    } = operation.getContext();
    graphQLErrors.forEach(async ({ extensions, message, locations, path }) => {
      if (extensions.code === 'UNAUTHENTICATED') {
        const { accessToken } = await getAccessToken(true);
        operation.setContext({
          headers: {
            ...oldHeaders,
            authorization: accessToken && !forRefreshToken ? `Bearer ${accessToken}` : '',
          },
        });
        return forward(operation);
      }
      if (ignoreError) {
        return null;
      }
      if (ignorePattern) {
        const { pattern = [], mandatory = true } = ignorePattern || {};
        if (mandatory && pattern.every((term) => message?.includes(term))) return null;
        if (!mandatory && pattern.some((term) => message?.includes(term))) return null;
      }
      return Sentry.withScope((scope) => {
        buildErrorScope(scope, operation, response);
        Sentry.captureMessage(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        );
      });
    });
  }
  if (networkError) {
    Sentry.withScope((scope) => {
      buildErrorScope(scope, operation, response);
      Sentry.captureException(`[Network error]: ${networkError}`);
    });
    Sentry.captureException(`[Network error]: ${networkError}`);
  }
});

client = new ApolloClient({
  cache,
  link: from([errorLink, concat(authMiddleware, httpLink)]),
});

export default client;
