import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useOutletContext, useNavigate } from 'react-router-dom';
import { Box, Divider } from '@mui/material';
import hideEmail from '../../../helpers/hideEmail';
import OnBoardingTitle from '../../extras/OnboardingTitle';
import SubmitButton from '../../SubmitButton';

const MailSent = ({ email }) => {
  const [handlePageChange] = useOutletContext();
  const navigate = useNavigate();

  useEffect(() => {
    handlePageChange();
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'column wrap',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        gap: '20px',
      }}
    >
      <OnBoardingTitle
        title="Correo enviado"
        subtitle={`Enviamos un correo electrónico a ${hideEmail(
          email,
        )} con un enlace, para que puedas acceder a tu cuenta`}
        showLogo
      />
      <Divider
        sx={{
          width: '100%',
          color: '#EAEAEA',
        }}
      />
      <SubmitButton
        text="Volver al inicio"
        handler={() => navigate('/signin', { replace: true })}
      />
    </Box>
  );
};

MailSent.propTypes = {
  email: PropTypes.string.isRequired,
};

export default MailSent;
