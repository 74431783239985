import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  ListItemButton,
  ListItemIcon,
  IconButton,
  ListItemText,
  Typography,
  Divider,
  Collapse,
} from '@mui/material';
import { KeyboardArrowUpOutlined, KeyboardArrowDownOutlined } from '@mui/icons-material';

const TucarElement = ({
  openManualModal,
  variant,
  primaryContent,
  primaryContentStyle,
  IconCard,
  iconCardStyle,
  secondaryContent,
  handleRedirect,
  secondaryContentStyle,
  IconImg,
  TucarElementStyle,
}) => {
  const [open, setOpen] = useState(openManualModal);
  const handleClick = () => {
    setOpen(!open);
  };

  const primaryVariant = () => {
    switch (variant) {
      case 'card':
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'end',
            }}
          >
            <Typography
              sx={{
                ...primaryContentStyle.auxText,
                fontFamily: 'Poppins-Medium',
                fontSize: '12px',
              }}
            >
              {primaryContent.auxText}
            </Typography>
            <Typography
              sx={{
                ...primaryContentStyle.detail,
                fontFamily: 'Poppins-Light',
                fontSize: '10px',
              }}
            >
              {primaryContent.detail}
            </Typography>
          </Box>
        );
      case 'collapse':
        return (
          <Box>
            {open ? (
              <IconButton
                onClick={handleClick}
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                }}
              >
                <KeyboardArrowUpOutlined
                  sx={{ margin: '0px', fontSize: '16px', color: '#333333' }}
                />
              </IconButton>
            ) : (
              <IconButton
                onClick={handleClick}
                sx={{
                  ...TucarElementStyle?.iconButtonStyle,

                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                }}
              >
                <KeyboardArrowDownOutlined
                  sx={{ margin: '0px', verticalAlign: 'start', fontSize: '16px', color: '#333333' }}
                />
              </IconButton>
            )}
          </Box>
        );
      case 'redirect':
        return (
          <IconButton
            onClick={handleRedirect}
            sx={{ position: 'absolute', top: '0px', right: '0px' }}
          >
            <KeyboardArrowUpOutlined
              fontSize="small"
              sx={{ color: '#333333', transform: 'rotate(90deg)' }}
            />
          </IconButton>
        );
      default:
        return null;
    }
  };

  const primaryResume = () => {
    switch (variant) {
      case 'collapse':
        return (
          <Box sx={{ paddingRight: '30px' }}>
            <Box>
              <Typography
                noWrap={!open}
                sx={{
                  maxWidth: '40vw',
                  fontFamily: 'Poppins-Light',
                  fontSize: '12px',
                  lineHeight: 1,
                  color: '#AAAAAA',
                  ...primaryContentStyle.resume,
                }}
              >
                {primaryContent.resume}
              </Typography>
            </Box>
          </Box>
        );
      case 'redirect':
        return (
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              sx={{
                ...primaryContentStyle.auxText,
                fontFamily: 'Poppins-Light',
                fontSize: '12px',
              }}
            >
              {primaryContent.auxText}
            </Typography>
          </Box>
        );
      default:
        return (
          <Box sx={{ paddingRight: '30px' }}>
            <Typography
              sx={{
                fontFamily: 'Poppins-Light',
                color: '#AAAAAA',
                fontSize: '12px',
                ...primaryContentStyle.resume,
              }}
            >
              {primaryContent.resume}
            </Typography>
          </Box>
        );
    }
  };

  const primaryAux = () => {
    switch (variant) {
      case 'collapse':
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'end',
              justifyContent: 'space-between',
              height: '100%',
            }}
          >
            <Typography
              sx={{
                ...primaryContentStyle.auxText,
                fontFamily: 'Poppins-Medium',
                fontSize: '12px',
              }}
            >
              {primaryContent.auxText}
            </Typography>
            <Typography
              sx={{
                ...primaryContentStyle.detail,
                color: '#AAAAAA',
                fontFamily: 'Poppins-Light',
                fontSize: '10px',
              }}
            >
              {primaryContent.detail}
            </Typography>
          </Box>
        );

      case 'redirect':
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'end',
              alignItems: 'end',
              height: '100%',
            }}
          >
            <Typography
              sx={{
                ...primaryContentStyle.detail,
                fontFamily: 'Poppins-Light',
                fontSize: '12px',
              }}
            >
              {primaryContent.detail}
            </Typography>
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        marginBottom: '10px',
        minHeight: '50px',
      }}
    >
      <Box
        sx={{
          minHeight: '50px',
        }}
      >
        <ListItemButton
          disableTouchRipple
          sx={{
            alignItems: 'start',
            padding: '0px',
            minHeight: '40px',
            '&:hover': {
              backgroundColor: '#FFF',
            },
          }}
          onClick={handleClick}
        >
          <ListItemIcon sx={{ display: 'flex', alignItems: 'start', justifyContent: 'center' }}>
            <IconButton
              disabled
              disableRipple
              disableFocusRipple
              sx={{
                padding: '0px',
                '&:disabled': {
                  color: 'currentcolor',
                },
              }}
            >
              {IconImg ? (
                <img
                  src={IconImg}
                  alt="category"
                  style={{
                    width: '35px',
                    ...iconCardStyle,
                  }}
                />
              ) : (
                <IconCard
                  sx={{
                    fontSize: '35px',
                    ...iconCardStyle,
                  }}
                />
              )}{' '}
            </IconButton>
          </ListItemIcon>

          <Box width="100%" display="flex" justifyContent="space-between">
            <Box display="flex" flexDirection="column">
              <ListItemText
                primaryTypographyProps={{
                  noWrap: !open,
                  style: {
                    ...primaryContentStyle.title,
                    maxWidth: '50vw',
                    fontSize: '12px',
                    fontFamily: 'Poppins-Medium',
                    lineHeight: 1,
                  },
                }}
                primary={primaryContent.title}
              />
              {primaryResume()}
            </Box>
            <Box sx={{ ...TucarElementStyle.rightBox }} display="flex">
              <Box>{primaryAux()}</Box>
              <Box>{primaryVariant()}</Box>
            </Box>
          </Box>
        </ListItemButton>
      </Box>
      <Collapse
        sx={{
          ...secondaryContentStyle,
        }}
        in={open}
        timeout="auto"
        unmountOnExit
      >
        <Box
          margin="10px 0px 0px 0px"
          sx={{ overflowX: 'scroll', ...TucarElementStyle.secondaryContent }}
        >
          {secondaryContent}
        </Box>
      </Collapse>
      <Divider sx={{ marginTop: '5px' }} />
    </Box>
  );
};

TucarElement.propTypes = {
  IconImg: PropTypes.string,
  IconCard: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  iconCardStyle: PropTypes.shape({}),
  variant: PropTypes.string.isRequired,
  primaryContent: PropTypes.shape({
    title: PropTypes.string.isRequired,
    auxText: PropTypes.string,
    resume: PropTypes.string,
    detail: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  primaryContentStyle: PropTypes.shape({
    title: PropTypes.shape({}),
    auxText: PropTypes.shape({}),
    resume: PropTypes.shape({}),
    detail: PropTypes.shape({}),
  }),
  secondaryContent: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.object]),
  secondaryContentStyle: PropTypes.shape({}),
  handleRedirect: PropTypes.func,
  TucarElementStyle: PropTypes.shape({
    rightBox: PropTypes.shape({}),
    secondaryContent: PropTypes.shape({}),
    iconButtonStyle: PropTypes.shape({}),
  }),
  openManualModal: PropTypes.bool,
};

TucarElement.defaultProps = {
  openManualModal: false,
  IconImg: '',
  handleRedirect: () => {},
  iconCardStyle: {},
  primaryContentStyle: {
    title: {},
    auxText: {},
    resume: {},
    detail: {},
  },
  secondaryContentStyle: { marginLeft: '40px' },
  secondaryContent: <Box />,
  TucarElementStyle: {
    rightBox: {},
    secondaryContent: {},
    iconButtonStyle: {},
  },
};

export default TucarElement;
