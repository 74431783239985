export const setNews = (news = true) => {
  localStorage.setItem('hiddeNews', news);
};
export const setNewVersion = (news = true) => {
  localStorage.setItem('hiddeNewVersionModal', news);
};

export const setNewVersionForm = (showForm = true) => {
  localStorage.setItem('hiddeNewVersionFormModal', showForm);
};

export const setUpdatedAtFormModal = () => {
  localStorage.setItem('updateAtFormModal', true);
};

export const resetNewVersionForm = () => {
  const currentDate = new Date();
  if (currentDate.getDay() === 0 && localStorage.getItem('hiddeNewVersionFormModal')) {
    if (localStorage.getItem('updateAtFormModal') !== 'true') {
      setNewVersionForm(false);
      setUpdatedAtFormModal();
    }
  }
};

export const setFirstTimeDashboard = (firstTimeDashboard = true) => {
  localStorage.setItem('firstTimeDashboard', firstTimeDashboard);
};

export const setUpdatedAt = () => {
  localStorage.setItem('updateAtNews', new Date().toISOString().slice(0, 10));
};

export const resetExtra = () => {
  const currentDate = new Date();
  if (currentDate.getUTCMonth() === 10 && currentDate.getUTCFullYear() === 2023) {
    const getDay = () => (currentDate.getDay() + 6) % 7;
    if ((getDay() === 0 || getDay() === 3) && localStorage.getItem('hiddeNews')) {
      if (localStorage.getItem('updateAtNews') !== new Date().toISOString().slice(0, 10)) {
        setNews(false);
      }
    }
  }
};
