import { TucarTheme } from '@tucar/pit-stop';

const carViewStyles = {
  centerView: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    marginBottom: '50px',
    paddingInline: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '20px',
  },
  carInfoName: {
    color: TucarTheme.colors.gray700,
    fontSize: '20px',
    fontFamily: 'Poppins-Semibold',
  },
  regularText: {
    color: TucarTheme.colors.gray700,
    fontFamily: 'Poppins-Regular',
    fontSize: '16px',
  },
  mediumText: {
    color: TucarTheme.colors.gray700,
    fontFamily: 'Poppins-Medium',
    fontSize: '16px',
  },
  scrollBox: {
    gap: '20px',
    display: 'grid',
    gridTemplateColumns: 'repeat(2,1fr)',
    overflowX: 'scroll',
    width: '90vw',
    paddingTop: '20px',
    paddingBottom: '20px',
    paddingRight: '20px',
    overflowY: 'hidden',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  scrollDocsBox: {
    gap: '20px',
    display: 'grid',
    overflowX: 'scroll',
    width: '90vw',
    padding: '20px 20px 20px 0px',
    overflowY: 'hidden',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  documentTitle: {
    color: TucarTheme.colors.gray700,
    fontSize: '18px',
    fontFamily: 'Poppins-Semibold',
    lineHeight: 1.2,
  },
  deducibleDescription: {
    fontSize: '14px',
    fontFamily: 'Poppins-Regular',
    lineHeight: 1.2,
    color: TucarTheme.colors.gray700,
  },
  deductibleTitle: {
    width: '100%',
    textAlign: 'left',
    fontSize: '16px',
    fontFamily: 'Poppins-Medium',
    color: TucarTheme.colors.gray700,
    lineHeight: 1.2,
  },
  deductibleCard: {
    background:
      'linear-gradient(0deg, #EBF5FF 0%, #EBF5FF 100%), linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%)',
    width: '225px',
    padding: '20px',
    gap: '10px',
    justifyContent: 'unset',
  },
  descriptionCard: {
    width: '100%',
    padding: '20px',
    paddingBottom: '30px',
    alignItems: 'unset',
    gap: '10px',
  },
  odometerInfo: {
    color: TucarTheme.colors.blue500,
    fontSize: '24px',
    fontFamily: 'Poppins-Semibold',
    textAlign: 'center',
    padding: '16px 0px',
  },
  kmTitle: {
    width: '100%',
    textAlign: 'left',
    color: TucarTheme.colors.gray700,
    fontFamily: 'Poppins-Semibold',
    marginTop: '20px',
  },
};

export default carViewStyles;
