import { gql } from '@apollo/client';

const SELECT_BANK = {
  mutation: gql`
    mutation SelectBankAccount($uid: String!) {
      selectBankAccount(uid: $uid) {
        accountType
        account
        bank
        email
        name
        rut
      }
    }
  `,
};

export default SELECT_BANK;
