import { gql } from '@apollo/client';

import { setAuth } from '../../store';

const LINK_UP_UBER = {
  mutation: gql`
    mutation Mutation($code: String!, $redirectUrl: String!) {
      linkUberAccount(code: $code, redirectUrl: $redirectUrl) {
        accessToken
        refreshToken
        data {
          uid
          email
          firstname
          lastname
          userType
          verified
        }
      }
    }
  `,
  policies: {
    onCompleted: (response) => {
      if (response.linkUberAccount) {
        const { accessToken, refreshToken, data } = response.linkUberAccount;
        setAuth({ accessToken, refreshToken, data });
      }
    },
    errorPolicy: 'all',
  },
};

export default LINK_UP_UBER;
