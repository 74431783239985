import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import StandardButton from './StandartButton';

const ErrorModal = ({ open, onClose, content, title }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="error-dialog"
      aria-describedby="probabbly-a-server-side-error-happened"
    >
      <DialogTitle
        className="PoppinsSemiBold"
        style={{ backgroundColor: '#d32f2f', color: '#fff' }}
        id="alert-dialog-title"
      >
        {title}
      </DialogTitle>
      <DialogContent
        className="PoppinsRegular"
        style={{ backgroundColor: '#f9f9f9', padding: '15px 20px' }}
      >
        <DialogContentText id="alert-dialog-description" style={{ color: '#000000' }}>
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ backgroundColor: '#f9f9f9' }}>
        <StandardButton className="PoppinsRegular" backgroundColor="#f9f9f9" onClick={onClose}>
          Cerrar
        </StandardButton>
      </DialogActions>
    </Dialog>
  );
};

ErrorModal.propTypes = {
  // eslint-disable-next-line react/require-default-props
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
  title: PropTypes.string,
};

ErrorModal.defaultProps = {
  title: 'Ha ocurrido un error',
};

export default ErrorModal;
