import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Typography, Button } from '@tucar/pit-stop';
import { azulTucar } from '../../../../../themes/variants/TucarColors';
import checkBubble from '../../../../../../public/images/bubbleIconsPng/check.png';

const FinalStep = ({ onNext }) => {
  return (
    <Box>
      <Box
        sx={{
          position: 'absolute',
          top: '0px',
          left: '0px',
          width: '100vw',
          height: '100vh',
          background:
            'linear-gradient(0deg, rgba(240, 240, 243, 1) 0%, rgba(0, 87, 184, 0.5) 100%)',
          backdropFilter: 'blur(2px)',
          paddingInline: '27px',
          zIndex: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '30px',
        }}
      >
        <img alt="checkBubble" src={checkBubble} width="152px" height="127px" />

        <Typography
          style={{
            fontSize: '24px',
            fontWeight: 600,
            lineHeight: 1.2,
            color: '#0057B8',
            paddingTop: '40px',
            textAlign: 'center',
          }}
        >
          ¡Solicitud enviada!
        </Typography>
        <Typography
          style={{
            textAlign: 'center',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: 1.2,
            color: '#5B5D71',
          }}
        >
          Tu compañero revisará el cambio y te notificará si acepta o no.
        </Typography>
        <Button size="large">Volver a turnos</Button>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '30px 20px',
            paddingTop: '0px',
            width: 'calc(100vw - 50px)',
            gap: '10px',
          }}
        >
          <Typography
            align="center"
            color={azulTucar}
            style={{ fontSize: '18px', fontWeight: 600 }}
          >
            Espera su respuesta 💡
          </Typography>
          <Typography
            color={azulTucar}
            align="center"
            style={{ fontSize: '14px', fontWeight: 400, lineHeight: '16px' }}
          >
            ¡Listo para coordinar con tu compañero! 🔄 Después de enviar la solicitud, tu compañero
            la revisará y te dará respuesta en el rango de tiempo establecido. ¡No dudes en
            conversar con él si tienes preguntas o ajustes!
          </Typography>
          <Typography
            color={azulTucar}
            align="center"
            style={{ fontSize: '14px', fontWeight: 400, lineHeight: '16px' }}
          >
            ¡Esperamos que este tutorial haya sido de gran utilidad para ti! 🤝🚗
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            paddingBottom: '30px',
            width: '100%',
          }}
        >
          <Typography color={azulTucar} style={{ fontSize: '14px', fontWeight: 500 }}>
            Paso 6/6
          </Typography>
          <Button
            size="small"
            onClick={() => {
              onNext();
            }}
          >
            Finalizar
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

FinalStep.propTypes = {
  onNext: PropTypes.func,
};

FinalStep.defaultProps = {
  onNext: () => {},
};

export default FinalStep;
