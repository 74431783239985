import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';

import StandardButtonStyle from './styles';

const StandardButton = ({ children, onClick, height, width, fontSize, ...props }) => {
  return (
    <Button
      className="PoppinsSemiBold"
      onClick={onClick}
      component={StandardButtonStyle}
      style={{
        fontFamily: 'Poppins-Medium',
        height,
        width,
        fontSize,
        lineHeight: '100%',
        ...props,
      }}
    >
      {children}
    </Button>
  );
};

StandardButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  onClick: PropTypes.func.isRequired,
  height: PropTypes.string,
  width: PropTypes.string,
  fontSize: PropTypes.string,
};

StandardButton.defaultProps = {
  width: '86px',
  height: '34px',
  fontSize: '14px',
};

export default StandardButton;
