import React from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Typography } from '@tucar/pit-stop';
import { azulTucar } from '../../../../../../themes/variants/TucarColors';

function EventGetTextBorder({ color, last, title, content, url }) {
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        paddingLeft: '60px',
        paddingBottom: last ? 'unset' : '20px',
      }}
    >
      <Box
        sx={{
          display: last ? 'none' : 'block',
          position: 'absolute',
          height: '100%',
          width: '1px',
          top: '10px',
          left: '20px',
          backgroundColor: color,
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          height: '9px',
          width: '9px',
          borderRadius: '15px',
          top: '5px',
          left: '16px',
          backgroundColor: color,
        }}
      />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          gap: '5px',
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'start',
          }}
        >
          <Typography style={{ color: '#71758E', fontSize: '14px', fontWeight: 500 }}>
            {title}
          </Typography>
          {url && (
            <IconButton
              size="small"
              type="submit"
              target="_blank"
              href={url}
              sx={{
                transform: 'translateY(-5px)',
                padding: '0px',
                display: 'flex',
                alignItems: 'center',
                color: '#000',
                fontFamily: 'Poppins-Light',
                fontSize: '12px',
              }}
            >
              <VisibilityIcon size="small" sx={{ color: azulTucar }} />
            </IconButton>
          )}
        </Box>

        <Typography
          style={{
            color: '#2D2F39',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: 1.2,
            overflow: 'scroll hidden',
            textOverflow: 'clip',
            width: '100%',
          }}
        >
          {content}
        </Typography>
      </Box>
    </Box>
  );
}

EventGetTextBorder.propTypes = {
  last: PropTypes.bool,
  url: PropTypes.string,
  color: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
};

EventGetTextBorder.defaultProps = {
  color: '',
  last: false,
  url: null,
  title: '',
  content: '',
};

export default EventGetTextBorder;
