import React, { createRef, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Loader } from '@tucar/pit-stop';
import { useLazyQuery } from '@apollo/client';
import { useOutletContext } from 'react-router-dom';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { MapContextProvider } from './mapContext';
import GET_CHARGING_STATIONS from '../../../../graphql/querys/map/getChargingStations';
import { renderMapMarkers } from './auxFunctions';
import EmptyView from '../../../../components/extras/EmptyView';
import MapSelection from './mapSelection';
import LocationPin from './pinsSVG/locationPin.svg';

const ChargersMap = ({ params }) => {
  const [handlePageChange] = useOutletContext();
  const [getStations, { loading, data, error }] = useLazyQuery(GET_CHARGING_STATIONS.query, {
    ...GET_CHARGING_STATIONS.policies,
    variables: { ...params, status: 'Available' },
  });

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY,
  });

  const [chargerToFocus, setChargerToFocus] = useState(null);
  const mapRef = createRef();
  const [zoom, setZoom] = useState(13);
  const [center, setCenter] = useState({
    lat: -33.4066744,
    lng: -70.5762731,
  });
  const [currentLocation, setCurrentLocation] = useState(null);
  const [markers, setMarkers] = useState(null);
  const [carStatusFilterOptions, setCarStatusFilterOptions] = useState([]);

  const handleFocus = useCallback((carData, location) => {
    setChargerToFocus(carData);
    if (location) {
      setCenter(location);
    }
  }, []);

  const handleZoomChanged = useCallback(() => {
    const newZoom = mapRef.current.getZoom();
    setZoom(newZoom);
  }, [mapRef]);

  const onMapLoad = () => {
    navigator?.geolocation.getCurrentPosition(({ coords: { latitude: lat, longitude: lng } }) => {
      const pos = { lat, lng };
      setCurrentLocation(pos);
      setCenter(pos);
    });
  };

  useEffect(() => {
    handlePageChange(false, true);
    getStations({ variables: { ...params, status: 'Available' } });
  }, []);

  useEffect(() => {
    getStations({ variables: { ...params, status: 'Available' } });
  }, [params]);

  useEffect(() => {
    if (!loading && data) {
      setMarkers(data?.getChargingStations || []);
    }
  }, [loading]);

  if (error) {
    return (
      <Box sx={{ height: '100vh' }}>
        <EmptyView />
      </Box>
    );
  }

  if (!isLoaded || (loading && !markers)) {
    return (
      <Box sx={{ height: '100vh' }}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Loader size={32} pointRadio="4px" />
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <MapSelection
        data={chargerToFocus}
        onClose={() => {
          handleFocus(null);
        }}
      />

      <MapContextProvider
        carToFocus={chargerToFocus}
        focusCar={handleFocus}
        carStatusFilter={{
          filterOptions: carStatusFilterOptions,
          setFilterOptions: setCarStatusFilterOptions,
        }}
      >
        <Box>
          <GoogleMap
            mapContainerStyle={{ height: '100vh' }}
            center={center}
            zoom={zoom}
            options={{
              minZoom: 2,
              mapTypeControl: false,
              fullscreenControl: false,
              streetViewControl: false,
              zoomControl: false,
            }}
            onLoad={(map) => {
              mapRef.current = map;
              map.addListener('zoom_changed', handleZoomChanged);
              onMapLoad(map);
            }}
          >
            {renderMapMarkers(markers)}
            {currentLocation && (
              <Marker
                position={currentLocation}
                icon={{
                  url: LocationPin,
                  scale: 7,
                }}
              />
            )}
          </GoogleMap>
        </Box>
      </MapContextProvider>
    </Box>
  );
};

ChargersMap.propTypes = {
  params: PropTypes.shape({}),
};

ChargersMap.defaultProps = {
  params: {},
};

export default ChargersMap;
