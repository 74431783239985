import { gql } from '@apollo/client';

const UPDATE_SHIFT_PETITION = {
  mutation: gql`
    mutation UpdateShiftChangePetition(
      $participantId: String!
      $petitionId: String!
      $accepted: Boolean!
    ) {
      updateShiftChangePetition(
        participantId: $participantId
        petitionId: $petitionId
        accepted: $accepted
      ) {
        _id
        status
        petitioner
      }
    }
  `,
  policies: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
};

export default UPDATE_SHIFT_PETITION;
