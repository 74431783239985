import axios from 'axios';
import CarState from './steps/CarState';
import SecurityCodeView from './steps/SecurityCode';
import EndFormView from './steps/EndForm';
import ChargingView from './steps/Charging';
import CableSecuredView from './steps/CableSecured';
import DoorsClosedView from './steps/DoorsClosed';
import PhotosView from './steps/Photos';
import RetireCar from './steps/RetireCar';
import LeftCharging from './steps/LeftChargin';

function getComponent(type) {
  switch (type) {
    case 'LeftCharging':
      return { stepKey: 'LeftCharging', StepComponent: LeftCharging };
    case 'PickUp':
      return { stepKey: 'PickUp', StepComponent: RetireCar };
    case 'DamageReport':
      return { stepKey: 'DamageReport', StepComponent: CarState };
    case 'Photos':
      return { stepKey: 'Photos', StepComponent: PhotosView };
    case 'CodeExchange':
      return { stepKey: 'CodeExchange', StepComponent: SecurityCodeView };
    case 'Charging':
      return { stepKey: 'Charging', StepComponent: ChargingView };
    case 'CableSecured':
      return { stepKey: 'CableSecured', StepComponent: CableSecuredView };
    case 'DoorsClosed':
      return { stepKey: 'DoorsClosed', StepComponent: DoorsClosedView };
    default:
      return { stepKey: 'endForm', StepComponent: EndFormView };
  }
}

export function translateSteps(steps, im) {
  const nameOptionalSteps = steps
    .map((elem) => {
      if (elem.domain.includes(im) && !elem.status) {
        return { type: elem.type, optional: elem.optional };
      }
      return null;
    })
    .filter((elem) => elem);
  const nameSteps = nameOptionalSteps.map((elem) => elem?.type);
  const optionalSteps = nameOptionalSteps.map((elem) => elem?.optional);
  const auxStepsForm = nameSteps.map((elem) => getComponent(elem));

  if (nameSteps.length === 0) {
    nameSteps.push('endForm');
    auxStepsForm.push(getComponent('endForm'));
    optionalSteps.push(false);
  }

  return { stepsForm: auxStepsForm, stepsList: nameSteps, optionalList: optionalSteps };
}

export const handleUploadDamageReport = async (data, setSelfLoad, refetchData) => {
  setSelfLoad(true);
  const dataUp = new FormData();
  data?.files.forEach((elem) => {
    dataUp.append('files', elem[0], elem[1]);
  });

  dataUp.append('exchangeId', data.exchangeId);
  dataUp.append('driverId', data.uid);
  dataUp.append('step', 'DamageReport');
  dataUp.append('battery', data.battery);
  dataUp.append('damage', data.damage);
  dataUp.append('comment', data.comment || 'Sin comentarios');

  const config = {
    method: 'post',
    url: `${process.env.DOCUMENT_GATEWAY_URL}/api/v1/cl/exchange/step/damages`,
    data: dataUp,
  };

  if (process.env.MODE === 'development') {
    config.headers = {
      'Content-Type': 'multipart/form-data',
    };
  } else {
    config.headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: `Apikey ${process.env.DOCUMENT_GATEWAY_API_KEY}`,
    };
  }

  await axios(config)
    .then(() => {
      setSelfLoad(false);
      refetchData();
      return true;
    })
    .catch(() => {
      return false;
    });

  return null;
};

export const handleUploadPhotos = async (data, setSelfLoad, refetchData) => {
  setSelfLoad(true);
  const dataUp = new FormData();
  data?.files.forEach((elem) => {
    dataUp.append('files', elem[0], elem[1]);
  });

  dataUp.append('exchangeId', data.exchangeId);
  dataUp.append('driverId', data.uid);
  dataUp.append('step', 'Photos');

  const config = {
    method: 'post',
    url: `${process.env.DOCUMENT_GATEWAY_URL}/api/v1/cl/exchange/step/photos`,
    data: dataUp,
  };

  if (process.env.MODE === 'development') {
    config.headers = {
      'Content-Type': 'multipart/form-data',
    };
  } else {
    config.headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: `Apikey ${process.env.DOCUMENT_GATEWAY_API_KEY}`,
    };
  }

  await axios(config)
    .then(() => {
      setSelfLoad(false);
      refetchData();
      return true;
    })
    .catch(() => {
      return false;
    });
  return null;
};
