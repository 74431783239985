import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Card, Button, CircularGraph, Typography } from '@tucar/pit-stop';
import { formatterCLP } from '../../../../../helpers/formatters';

const GainingCard = ({ incomes, outcomes, cuotas, onClick }) => {
  return (
    <Card
      style={{
        padding: '16px 24px',
        gap: '10px',
        width: '100%',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row-reverse',
          gap: '15px',
          width: '100%',
          flexWrap: 'wrap',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
          <Typography
            style={{
              color: '#5B5D71',
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: 1.2,
              paddingBottom: '3px',
            }}
          >
            Ganancia semanal
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
              <Box
                sx={{ width: '7px', height: '7px', borderRadius: '100%', background: '#7FB418' }}
              />
              <Typography
                style={{ color: '#7FB418', fontSize: '13px', fontWeight: 500, lineHeight: 1.2 }}
              >
                Ingresos
              </Typography>
              <Typography
                style={{ color: '#000', fontSize: '13px', fontWeight: 400, lineHeight: 1.2 }}
              >
                {formatterCLP.format(incomes)}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
            <Box
              sx={{ width: '7px', height: '7px', borderRadius: '100%', background: '#FF7B66' }}
            />
            <Typography
              style={{ color: '#FF7B66', fontSize: '13px', fontWeight: 500, lineHeight: 1.2 }}
            >
              Egresos
            </Typography>
            <Typography
              style={{ color: '#000', fontSize: '13px', fontWeight: 400, lineHeight: 1.2 }}
            >
              {formatterCLP.format(outcomes)}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
            <Box
              sx={{ width: '7px', height: '7px', borderRadius: '100%', background: '#0496FF' }}
            />
            <Typography
              style={{ color: '#0496FF', fontSize: '13px', fontWeight: 500, lineHeight: 1.2 }}
            >
              Cuotas
            </Typography>
            <Typography
              style={{ color: '#000', fontSize: '13px', fontWeight: 400, lineHeight: 1.2 }}
            >
              {formatterCLP.format(cuotas)}
            </Typography>
          </Box>
        </Box>

        <CircularGraph
          title="Ganancias"
          size={140}
          content="Resultado semanal"
          hideLabels
          innerProps={{
            textPosition: 'top',
            textSize: 11,
            numberSize: 13,
          }}
          initialLabelType="$"
          total={incomes - outcomes - cuotas}
          data={[
            {
              name: 'Ganancias',
              value: incomes,
              color: '#7FB418',
            },
            {
              name: 'Deuda',
              value: outcomes,
              color: '#FF7B66',
            },
            {
              name: 'Cuotas',
              value: cuotas,
              color: '#0496FF',
            },
          ].filter((item) => item.value > 0)}
        />
      </Box>
      <Button size="small" style={{ justifySelf: 'center' }} onClick={onClick}>
        Ver ganancias
      </Button>
    </Card>
  );
};

GainingCard.propTypes = {
  incomes: PropTypes.number,
  outcomes: PropTypes.number,
  cuotas: PropTypes.number,
  onClick: PropTypes.func,
};

GainingCard.defaultProps = {
  incomes: 0,
  outcomes: 0,
  cuotas: 0,
  onClick: () => {},
};

export default GainingCard;
