import { styled } from '@mui/system';
import { azulTucar } from '../../../themes/variants/TucarColors';

const boxBackground = '#fff';

const StandardButton = styled('button')`
  font-family: Poppins, sans-serif;
  font-weight: 400;
  background-color: ${boxBackground};
  color: ${azulTucar};
  transition: all 150ms ease;
  cursor: pointer;
  border: 2px solid ${azulTucar};
  border-radius: 25px;
  line-height: 100%;
  text-transform: none;
  &:hover {
    background-color: ${azulTucar};
    color: #fff;
  }
`;

export default StandardButton;
