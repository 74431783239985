import React from 'react';
import PropTypes from 'prop-types';
import TableGenerator from '../../../../components/info/TableGenerator';
import BoxParagraph from './BoxParagraph';

const TableBox = ({ data, ident }) => {
  const content = data.map((elem, index) => {
    const identifier = `${ident}-list-item-${index}`;
    const rightContent = elem.children[0].children[0].children[0];
    const leftContent = elem.children[1].children[0].children[0];
    return {
      ident: identifier,
      right: <BoxParagraph data={rightContent} />,
      left: <BoxParagraph data={leftContent} />,
    };
  });

  return (
    <TableGenerator
      key={`${ident}-table`}
      content={content}
      tableStyle={{
        container: { padding: '10px 10px 10px 10px' },
        tableRow: { height: '30px', color: '#333333' },
        tableCell: {
          padding: 0,
          gap: '20px',
          verticalAlign: 'baseline',
          fontSize: '14px',
        },
      }}
    />
  );
};

TableBox.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  ident: PropTypes.string,
};

TableBox.defaultProps = {
  data: [],
  ident: '',
};

export default TableBox;
