import React, { useState } from 'react';
import { useReactiveVar, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Card, Typography } from '@tucar/pit-stop';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { getDriverStore } from '../../../../../graphql/store';
import PressIconButton from '../../../../../components/buttons/PressIconButton';
import UNLOCK_CAR from '../../../../../graphql/mutations/car/unlockCar';
import LOCK_CAR from '../../../../../graphql/mutations/car/lockCar';

const sleep = (ms = 30000) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

const DoorControlCard = ({ rentVersion, exclusiveMode, disabled, loadingData }) => {
  const { rent } = useReactiveVar(getDriverStore);

  const [unlockCar] = useMutation(UNLOCK_CAR.mutation, {
    ...UNLOCK_CAR.policies,
    skip: !rent?.carId,
    variables: { carId: rent?.carId },
  });

  const [lockCar] = useMutation(LOCK_CAR.mutation, {
    ...LOCK_CAR.policies,
    skip: !rent?.carId,
    variables: { carId: rent?.carId },
  });

  const [loading, setLoading] = useState(false);

  const handleCar = async (openCar = false) => {
    if (disabled) return;
    try {
      setLoading(true);
      if (openCar) {
        await lockCar();
      } else {
        await unlockCar();
      }
      await sleep(5000);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  if (rentVersion) {
    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          alignItems: 'center',
        }}
      >
        <Box
          onClick={() => handleCar(false)}
          sx={{
            opacity: loadingData || disabled || loading ? 0.5 : 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <LockOpenIcon sx={{ color: '#7FB418', fontSize: '23px' }} />
          {loading || loadingData ? (
            <Typography
              style={{
                color: '#2D2F39',
                fontSize: '12px',
                fontWeight: 500,
                lineHeight: 1.2,
                textAlign: 'center',
              }}
            >
              Cargando
            </Typography>
          ) : (
            <Typography
              style={{
                color: '#2D2F39',
                fontSize: '12px',
                fontWeight: 500,
                lineHeight: 1.2,
                textAlign: 'center',
              }}
            >
              Abrir puerta
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            height: '40px',
            width: '1px',
            border: '1px solid #66AEFF',
            marginInline: '5px',
            background: '#66AEFF',
          }}
        />
        <Box
          onClick={() => handleCar(true)}
          sx={{
            opacity: loadingData || disabled || loading ? 0.5 : 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <LockIcon sx={{ color: '#FF7B66', fontSize: '23px' }} />
          {loading || loadingData ? (
            <Typography
              style={{
                color: '#2D2F39',
                fontSize: '12px',
                fontWeight: 500,
                lineHeight: 1.2,
                textAlign: 'center',
              }}
            >
              Cargando
            </Typography>
          ) : (
            <Typography
              style={{
                color: '#2D2F39',
                fontSize: '12px',
                fontWeight: 500,
                lineHeight: 1.2,
                textAlign: 'center',
              }}
            >
              Cerrar puerta
            </Typography>
          )}
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '15px',
        flexDirection: exclusiveMode ? 'row' : 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Card
        disabled={loadingData || loading || disabled}
        onClick={() => handleCar(false)}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '6px 16px',
          width: '100%',
          backgroundColor: loadingData || loading || disabled ? '#CFD0D8' : '#FFF',
        }}
      >
        <PressIconButton size="40px">
          <LockOpenIcon
            sx={{
              color: loadingData || loading || disabled ? '#CFD0D8' : '#7FB418',
              fontSize: '26px',
            }}
          />
        </PressIconButton>

        {loading || loadingData ? (
          <Typography
            color="#8E90A4"
            style={
              exclusiveMode
                ? { fontSize: '14px', fontWeight: 500, textAlign: 'center' }
                : { fontSize: '16px', fontWeight: 600, marginTop: '10px' }
            }
          >
            Cargando
          </Typography>
        ) : (
          <Typography
            color={disabled ? '#8E90A4' : '#5B5D71'}
            style={{ fontSize: '14px', fontWeight: 500, lineHeight: 1.2, textAlign: 'center' }}
          >
            Abrir puerta
          </Typography>
        )}
      </Card>

      <Card
        disabled={loadingData || loading || disabled}
        onClick={() => handleCar(true)}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '6px 16px',
          width: '100%',
          backgroundColor: loadingData || loading || disabled ? '#CFD0D8' : '#FFF',
        }}
      >
        <PressIconButton size="40px">
          <LockIcon
            sx={{
              color: loadingData || loading || disabled ? '#CFD0D8' : '#FF7B66',
              fontSize: '26px',
            }}
          />
        </PressIconButton>

        {loading || loadingData ? (
          <Typography
            color="#8E90A4"
            style={
              exclusiveMode
                ? { fontSize: '14px', fontWeight: 500, textAlign: 'center' }
                : { fontSize: '16px', fontWeight: 600, marginTop: '10px' }
            }
          >
            Cargando
          </Typography>
        ) : (
          <Typography
            color={disabled ? '#8E90A4' : '#5B5D71'}
            style={{ fontSize: '14px', fontWeight: 500, lineHeight: 1.2, textAlign: 'center' }}
          >
            Cerrar puerta
          </Typography>
        )}
      </Card>
    </Box>
  );
};

DoorControlCard.propTypes = {
  disabled: PropTypes.bool,
  exclusiveMode: PropTypes.bool,
  rentVersion: PropTypes.bool,
  loadingData: PropTypes.bool,
};

DoorControlCard.defaultProps = {
  disabled: false,
  exclusiveMode: false,
  rentVersion: false,
  loadingData: false,
};
export default DoorControlCard;
