import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import RequestPageOutlinedIcon from '@mui/icons-material/RequestPageOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import TucarElement from '../../../../components/info/TucarElement';
import { formatterCLP } from '../../../../helpers/formatters';
import ResumeBox from '../../../../components/info/ResumeBox';
import GoTo from '../../../../components/buttons/goTo';
import { azulTucar } from '../../../../themes/variants/TucarColors';
import arrowTucar from '../../../../../public/images/customIcons/arrow/arrow_blue.svg';
import CardButtonDash from '../../../../components/buttons/CardButtonDash';

const LiquidationsBox = ({ content, styleElement, openModal }) => {
  const navigate = useNavigate();

  return (
    <Box sx={{ height: '100%' }}>
      {content.length > 0 ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '0px 20px 0px 20px',
            gap: '5px',
          }}
        >
          {content.map((liquidation) => {
            const auxPrimaryContent = {
              title: liquidation.title,
              resume: liquidation.comment,
              detail: liquidation.status,
              auxText: liquidation.positive
                ? `+${formatterCLP.format(liquidation.amount)}`
                : `-${formatterCLP.format(liquidation.amount)}`,
            };
            return (
              <TucarElement
                openManualModal={openModal}
                TucarElementStyle={styleElement?.TucarElement}
                key={`${liquidation.date}-${liquidation.title}`}
                variant="collapse"
                IconCard={RequestPageOutlinedIcon}
                primaryContent={auxPrimaryContent}
                primaryContentStyle={{
                  title: {
                    color: liquidation.positive ? azulTucar : '#333333',
                  },
                  auxText: {
                    color: liquidation.positive ? azulTucar : '#333',
                  },
                  resume: {
                    ...styleElement?.primaryContent?.resume,
                  },
                }}
                iconCardStyle={{
                  color: liquidation.positive ? azulTucar : '#333333',
                }}
                secondaryContentStyle={{ paddingLeft: '15px' }}
                secondaryContent={
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                    <Box sx={{ ...styleElement?.incomesBox }}>
                      <ResumeBox
                        showButton={false}
                        title="Recaudaciones"
                        data={liquidation.incomes}
                        iconTitle={
                          <img
                            src={arrowTucar}
                            alt="category"
                            style={{
                              width: '18px',
                              transform: 'rotate(180deg)',
                            }}
                          />
                        }
                      />
                    </Box>
                    <Box sx={{ ...styleElement?.outcomesBox }}>
                      <ResumeBox
                        showButton={false}
                        title="Descuentos"
                        data={liquidation.expenses}
                        iconTitle={
                          <img
                            src={arrowTucar}
                            alt="category"
                            style={{
                              width: '18px',
                            }}
                          />
                        }
                      />
                    </Box>
                    <Box
                      sx={{
                        ...styleElement?.totalWeek,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                        <PaidOutlinedIcon
                          sx={{ fontSize: '20px', transform: 'rotate(180deg)', color: azulTucar }}
                        />
                        <Typography
                          sx={{ fontSize: '16px', fontFamily: 'Poppins-Medium', color: azulTucar }}
                        >
                          Resultado semanal
                        </Typography>
                      </Box>

                      <Typography
                        sx={{ fontSize: '16px', fontFamily: 'Poppins-Medium', color: azulTucar }}
                        noWrap
                      >
                        {auxPrimaryContent.auxText}
                      </Typography>
                    </Box>

                    {liquidation.status === 'Vencida' && !liquidation.positive ? (
                      <CardButtonDash
                        toGo={false}
                        colors={{ primary: '#d32f2f', secondary: 'rgb(253, 237, 237)' }}
                        content={
                          <Typography
                            sx={{
                              fontFamily: 'Poppins-Regular',
                              color: '#d32f2f',
                              lineHeight: 1.2,
                              fontSize: '0.75rem',
                              textAlign: 'left',
                            }}
                          >
                            El periodo de pago ha vencido. El monto adeudado se cargará en tu
                            próxima liquidación como deuda.
                          </Typography>
                        }
                      />
                    ) : null}
                    {liquidation.status === 'Vencida' && liquidation.positive ? (
                      <CardButtonDash
                        toGo={false}
                        colors={{ primary: '#E1A020', secondary: '#FCF5E8' }}
                        content={
                          <Typography
                            sx={{
                              fontFamily: 'Poppins-Regular',
                              color: '#E1A020',
                              lineHeight: 1.2,
                              fontSize: '0.75rem',
                              textAlign: 'left',
                            }}
                          >
                            El periodo de pago ha vencido. El monto pendiente se abonará en tu
                            próxima liquidación como un reembolso.
                          </Typography>
                        }
                      />
                    ) : null}
                    {liquidation.status === 'Pendiente' && liquidation.positive ? (
                      <CardButtonDash
                        toGo={false}
                        colors={{ secondary: 'rgb(237, 247, 237)', primary: 'rgb(30, 70, 32)' }}
                        content={
                          <Typography
                            sx={{
                              fontFamily: 'Poppins-Regular',
                              color: 'rgb(30, 70, 32)',
                              lineHeight: 1.2,
                              fontSize: '0.75rem',
                              textAlign: 'left',
                            }}
                          >
                            Tus ganancias serán depositadas el día jueves durante el día.
                          </Typography>
                        }
                      />
                    ) : null}
                    {liquidation.status === 'Pendiente' && !liquidation.positive ? (
                      <CardButtonDash
                        toGo={false}
                        colors={{ primary: '#E1A020', secondary: '#FCF5E8' }}
                        content={
                          <Typography
                            sx={{
                              fontFamily: 'Poppins-Regular',
                              color: '#E1A020',
                              lineHeight: 1.2,
                              fontSize: '0.75rem',
                              textAlign: 'left',
                            }}
                          >
                            Deberás pagar el monto adeudado a más tardar el martes a las 23:59 hrs.
                          </Typography>
                        }
                      />
                    ) : null}
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'end',
                        gap: '10px',
                      }}
                    >
                      <Box sx={{ ...styleElement?.goToBalance }}>
                        <GoTo
                          text="Ver balance"
                          onClick={() => {
                            navigate(
                              `/app/liquidations/balance?date=${liquidation.date}&prePay=${liquidation.prePay}&debt=${liquidation.debt}`,
                              {
                                replace: true,
                              },
                            );
                          }}
                        />
                      </Box>
                      {liquidation.status === 'Pendiente' && !liquidation.positive ? (
                        <Box sx={{ ...styleElement?.goToPayment }}>
                          <GoTo
                            text="Pagar"
                            onClick={() => {
                              navigate('/app/payment', { replace: true });
                            }}
                          />
                        </Box>
                      ) : null}
                    </Box>
                  </Box>
                }
              />
            );
          })}
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Typography sx={{ fontFamily: 'Poppins-Regular', fontSize: '16px' }}>
            No tienes liquidaciones emitidas
          </Typography>
        </Box>
      )}
    </Box>
  );
};

LiquidationsBox.propTypes = {
  openModal: PropTypes.bool,
  content: PropTypes.arrayOf(PropTypes.shape({})),
  styleElement: PropTypes.shape({
    TucarElement: PropTypes.shape({}),
    primaryContent: PropTypes.shape({
      resume: PropTypes.shape({}),
    }),
    incomesBox: PropTypes.shape({}),
    outcomesBox: PropTypes.shape({}),
    totalWeek: PropTypes.shape({}),
    goToPayment: PropTypes.shape({}),
    goToBalance: PropTypes.shape({}),
  }),
};

LiquidationsBox.defaultProps = {
  openModal: false,
  content: [],
  styleElement: {
    primaryContent: { resume: {} },
    TucarElement: {},
    incomesBox: {},
    outcomesBox: {},
    totalWeek: {},
    goToPayment: {},
    goToBalance: {},
  },
};

export default LiquidationsBox;
