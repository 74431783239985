import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useReactiveVar, useLazyQuery } from '@apollo/client';
import { getAuthStore } from '../graphql/store';
import LoadingScreen from './LoadingScreen';
import GET_INIT_DRIVER from '../graphql/querys/driver/getInitDriver';
import checkOnboarding from '../helpers/onBoard';
import ErrorScreen from './ErrorScreen';
import { send } from '../helpers/sw/connection';
import ConnectionError from './extras/ConnectionError';

const AuthGuard = () => {
  const [loadScreen, setLoadScreen] = useState(true);
  const [offline, setOffline] = useState(true);
  const navigate = useNavigate();
  const {
    accessToken,
    data: { uid },
  } = useReactiveVar(getAuthStore);

  const [getInitDriver, { loading, error, data }] = useLazyQuery(
    GET_INIT_DRIVER.query,
    GET_INIT_DRIVER.policies,
  );

  useEffect(() => {
    if (!window.navigator.onLine) {
      setOffline(true);
    } else {
      setOffline(false);
    }
  }, [window.navigator.onLine]);

  useEffect(() => {
    if (uid) {
      getInitDriver({
        variables: { uid },
      });
      if ('serviceWorker' in navigator) {
        // eslint-disable-next-line no-console
        send(uid).catch((e) => console.log(e));
      }
    }
  }, []);

  useEffect(() => {
    if (!accessToken) {
      navigate('/', { replace: true });
      setLoadScreen(false);
    }
  }, [accessToken]);

  useEffect(() => {
    if (!loading) {
      if (data && data.getDriver) {
        const newDocs = data.getDriver.docs.files;
        const newDocsDict = {};
        for (let i = 0; i < newDocs.length; i += 1) {
          const { name } = newDocs[i];
          newDocsDict[name] = newDocs[i];
        }
        const planExclusive = data.getDriver.plan?.plan?.shifts.length === 1;
        const noLineal = true;

        const { onBoarding } = checkOnboarding(
          data.getDriver.verifiedList,
          newDocsDict,
          planExclusive,
          noLineal,
        );

        if (!accessToken) {
          navigate('/', { replace: true });
          setLoadScreen(false);
        } else if (onBoarding && data?.getDriver?.status?.status === 'on boarding') {
          navigate('/app/onboarding', { replace: true });
          setLoadScreen(false);
        } else {
          navigate('/app/v2/dashboard', { replace: true });
          setLoadScreen(false);
        }
      }
    }
  }, [loading]);

  if (offline) return <ConnectionError />;

  if (error || (data && !data.getDriver)) return <ErrorScreen />;

  if (loadScreen) return <LoadingScreen content="...Autenticando..." />;

  return <Outlet />;
};

export default AuthGuard;
