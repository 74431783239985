import React, { useEffect, useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import { useOutletContext } from 'react-router-dom';
import { Box, Divider, Typography } from '@mui/material';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { azulTucar } from '../../../themes/variants/TucarColors';
import logotipo from '../../../../public/images/logotipo.svg';
import menuOnBoarding from './menu';
import BasicInfo from './basicInfo';
import LoadingProgress from '../../../components/ui/LoadingProgress';
import OnBoardingApps from '../apps';
import OnBoardingDocuments from '../docs';
import OnBoardingPlan from '../plan';
import OnBoardingMeeting from '../meeting';
import SubmitButton from '../../../components/SubmitButton';
import OnBoardingInfo from '../info';
import OnBoardingBankAccount from '../bankAccount';
import LogOut from '../../../components/auth/LogOutButton';
import WaitingNotifications from '../../app/waitingList/waitingNotifications';
import OnBoardingGuarantee from '../guarantee';

const defaultStyle = { flex: 1, justifyContent: 'center', alignItems: 'center', display: 'flex' };

const onBoardingFunnelComponents = [
  {
    stepKey: 'basicInfo',
    OnBoardComponent: BasicInfo,
    styleContent: { flex: 1, display: 'flex', flexDirection: 'column', padding: '0px 10px' },
  },
  {
    stepKey: 'menu',
    OnBoardComponent: menuOnBoarding,
    styleContent: { flex: 1, display: 'flex' },
  },
  {
    stepKey: 'loading',
    OnBoardComponent: LoadingProgress,
  },
  {
    stepKey: 'app',
    OnBoardComponent: OnBoardingApps,
    styleContent: { flex: 1, display: 'flex', flexDirection: 'column' },
  },
  {
    stepKey: 'docs',
    OnBoardComponent: OnBoardingDocuments,
    styleContent: { flex: 1, display: 'flex', flexDirection: 'column' },
  },
  {
    stepKey: 'plan',
    OnBoardComponent: OnBoardingPlan,
    styleContent: { flex: 1, display: 'flex', flexDirection: 'column', padding: '0px 10px' },
  },
  {
    stepKey: 'meeting',
    OnBoardComponent: OnBoardingMeeting,
    styleContent: { flex: 1, display: 'flex', flexDirection: 'column' },
  },
  {
    stepKey: 'info',
    OnBoardComponent: OnBoardingInfo,
    styleContent: { flex: 1, display: 'flex', flexDirection: 'column' },
  },
  {
    stepKey: 'bank',
    OnBoardComponent: OnBoardingBankAccount,
    styleContent: { flex: 1, display: 'flex', flexDirection: 'column', width: '100%' },
  },
  {
    stepKey: 'guarantee',
    OnBoardComponent: OnBoardingGuarantee,
    styleContent: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
  },
  {
    stepKey: 'notification',
    OnBoardComponent: WaitingNotifications,
    styleContent: {
      overflowY: 'scroll',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
  },
];

const NoLinealOnBoarding = () => {
  const [step, setStep] = useState('basicInfo');
  const [handlePageChange] = useOutletContext();

  useEffect(() => {
    handlePageChange();
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: '#fff',
        display: 'grid',
        gridTemplateRows: 'max-content 1fr max-content',
        height: '100%',
        width: '100%',
        padding: '0px 20px 20px 20px',
        gap: '20px',
      }}
    >
      <Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '4rem',
            padding: '10px 10px 0px 10px',
          }}
        >
          <img src={logotipo} alt="category" style={{ height: '32px' }} />
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: step === 'notification' ? '#E9EFF8' : '#fff',
                padding: '10px',
                borderRadius: '30px',
              }}
              onClick={() => {
                if (step === 'notification') {
                  setStep('menu');
                } else {
                  setStep('notification');
                }
              }}
            >
              <FeatherIcon icon="bell" stroke={azulTucar} />
            </Box>
            <LogOut noLinealOnboarding />
          </Box>
        </Box>
        <Divider />
      </Box>

      <Box
        sx={{
          overflowY: 'scroll',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          flex: 1,
        }}
      >
        {onBoardingFunnelComponents.map(({ stepKey, OnBoardComponent, styleContent }) =>
          stepKey === step ? (
            <Box key={stepKey} sx={styleContent || defaultStyle}>
              <OnBoardComponent setStep={setStep} allScreen={false} onBoarding />
            </Box>
          ) : null,
        )}
      </Box>

      <Box>
        {!['menu', 'basicInfo'].includes(step) ? (
          <SubmitButton text="Volver al menú de registro" handler={() => setStep('menu')} />
        ) : (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                justifyContent: 'center',
              }}
            >
              <SupportAgentIcon sx={{ color: azulTucar, fontSize: '40px' }} />
              <Typography sx={{ fontFamily: 'Poppins-Regular', fontSize: '13px' }}>
                ¿Tienes problemas para completar el registro o dudas de nuestro servicio?
              </Typography>
            </Box>
            <SubmitButton handler={() => window.Intercom('show')} text="Contactar a un ejecutivo" />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default NoLinealOnBoarding;
