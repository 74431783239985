import { gql } from '@apollo/client';

const GET_UBER_TRIPS = {
  query: gql`
    query GetDiverUberTrips($uid: ID!, $initialDatetime: String, $finalDatetime: String) {
      getDriverBillDaysLimitDates(
        uid: $uid
        initialDatetime: $initialDatetime
        finalDatetime: $finalDatetime
      ) {
        uber {
          category
          amount
          datetime
        }
      }
    }
  `,
  policies: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
};

export default GET_UBER_TRIPS;
