import { gql } from '@apollo/client';

const EDIT_NOTIFICATION = {
  mutation: gql`
    mutation EditDriverNotification($driverId: ID!, $notificationId: ID!) {
      editDriverNotification(driverId: $driverId, notificationId: $notificationId)
    }
  `,
  policies: {
    errorPolicy: 'all',
  },
};

export default EDIT_NOTIFICATION;
