import React, { useEffect, useState } from 'react';
import { Box, ButtonGroup, Button, Typography } from '@mui/material';
import { useReactiveVar, useQuery, useMutation } from '@apollo/client';
import { useNavigate, useOutletContext } from 'react-router-dom';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import TopBar from '../../../components/ui/TopNav';
import { getAuthStore } from '../../../graphql/store';
import LoadingProgress from '../../../components/ui/LoadingProgress';
import SubmitButton from '../../../components/SubmitButton';
import CREATE_SHIFT_PETITION from '../../../graphql/mutations/plan/createShiftPetition';
import GET_DRIVER_SHIFTS from '../../../graphql/querys/plan/getDriverShifts';
import {
  translateShiftsByDayV1,
  complementConfig,
  weekDaysTranslate,
  addDayToday,
} from './petitionsAuxs';

const CreateShiftPetition = () => {
  const navigate = useNavigate();
  const [handlePageChange] = useOutletContext();
  const [options, setOptions] = useState([]);
  const [partnerUid, setPartnerUid] = useState('');
  const [config, setConfig] = useState(Array(7).fill({ am: false, pm: false }));

  const today = (new Date().getDay() + 6) % 7;
  const AuxWeekList = [...Array(7).keys()].map((i) => addDayToday(i));
  const weekList = AuxWeekList.slice(-today).concat(AuxWeekList.slice(0, -today));

  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);

  const { data: getDriverShifts, loading: loadingGetDriverShifts } = useQuery(
    GET_DRIVER_SHIFTS.query,
    {
      ...GET_DRIVER_SHIFTS.policies,
      variables: {
        driverId: uid,
        uid,
      },
    },
  );

  const [createPetition, { data: dataCreatePetition, loading: loadingCreatePetition }] =
    useMutation(CREATE_SHIFT_PETITION.mutation, CREATE_SHIFT_PETITION.policies);
  const [errorSelection, setErrorSelection] = useState(Array(7).fill(false));
  const sendPetition = async () => {
    const futureShifts = translateShiftsByDayV1(config, options);
    const complementShifts = translateShiftsByDayV1(complementConfig(config), options);

    if (Object.values(futureShifts).includes(undefined)) {
      const errorAux = Object.values(futureShifts).map((elem) => {
        return !elem;
      });
      await setErrorSelection(errorAux);
    } else if (Object.values(complementShifts).includes(undefined)) {
      const errorAux = Object.values(complementShifts).map((elem) => {
        return !elem;
      });
      await setErrorSelection(errorAux);
    } else {
      createPetition({
        variables: {
          petitioner: uid,
          participants: [
            { id: uid, futureShifts },
            { id: partnerUid, futureShifts: complementShifts },
          ],
        },
      });
    }
  };

  useEffect(() => {
    if (!loadingCreatePetition && dataCreatePetition?.createShiftChangePetition) {
      navigate('/app/petitions', { replace: true });
    }
  }, [loadingCreatePetition, dataCreatePetition]);

  useEffect(() => {
    handlePageChange(
      <TopBar prevPage="/app/petitions" title="Crear solicitud de intercambio" />,
      true,
    );
  }, []);

  useEffect(() => {
    if (!loadingGetDriverShifts && getDriverShifts?.getDriverPlanV2) {
      const actualShifts = getDriverShifts.getDriverPlanV2?.selectedShifts;
      const possibleShifts = getDriverShifts.getDriverPlanV2?.plan?.shifts;
      const partner = getDriverShifts.getCouples?.instances[0]?.drivers.filter(
        ({ _id: driverId }) => driverId !== uid,
      )[0];
      const optionsObject = possibleShifts.reduce((acc, shiftInfo) => {
        const { _id: idShift, type } = shiftInfo;
        return { ...acc, [type]: idShift };
      }, {});

      const { _id: partnerId } = partner;
      setPartnerUid(partnerId);
      setOptions(optionsObject);
      setConfig(
        actualShifts.map((elem) => {
          const { type } = elem;
          if (type === 'AM') return { am: true, pm: false };
          if (type === 'PM') return { am: false, pm: true };
          if (type === 'ALL') return { am: true, pm: true };
          return { am: false, pm: false };
        }),
      );
    }
  }, [getDriverShifts, loadingGetDriverShifts]);

  if (loadingGetDriverShifts || loadingCreatePetition) {
    return <LoadingProgress />;
  }
  const setConfigHandle = async (index, shift) => {
    if (errorSelection[index]) {
      const auxError = [...errorSelection];
      auxError[index] = false;
      await setErrorSelection(auxError);
    }

    const aux = [...config];
    aux[index][shift] = !aux[index][shift];
    await setConfig(aux);
  };

  return (
    <Box
      sx={{
        display: 'grid',
        gap: '10px',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        paddingInline: '20px',
      }}
    >
      <Typography sx={{ textAlign: 'center', fontFamily: 'Poppins-Regular', fontSize: '14px' }}>
        Selecciona los turnos que te gustaría trabajar.
      </Typography>

      <Typography sx={{ textAlign: 'center', fontFamily: 'Poppins-Regular', fontSize: '14px' }}>
        Recuerda que tu compañero tendrá una configuración complementaria a la que elijas.
      </Typography>

      <Typography
        variant="span"
        sx={{
          textAlign: 'center',
          fontFamily: 'Poppins-Light',
          fontSize: '13px',
          verticalAlign: 'center',
        }}
      >
        Si necesitas ayuda, presiona <HelpOutlineIcon sx={{ fontSize: '13px' }} /> en la esquina
        superior derecha de la pantalla para abrir el tutorial.
      </Typography>

      <Box
        sx={{
          gap: '10px',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
        }}
      >
        {weekList.map((dateElem, index) => {
          const { am, pm } = config[index];
          return (
            <Box
              key={`${dateElem.getDate()}`}
              sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
            >
              <Box sx={{ display: 'flex', gap: '10px' }}>
                <Typography sx={{ fontFamily: 'Poppins-Medium', fontSize: '14px' }}>
                  {weekDaysTranslate(dateElem)}
                </Typography>
                <Typography sx={{ fontFamily: 'Poppins-Regular', fontSize: '14px' }}>
                  {dateElem.getDate()}
                </Typography>
              </Box>
              <Box>
                <ButtonGroup disabled={dateElem.getDate() === new Date().getDate()}>
                  <Button
                    variant={am ? 'contained' : 'outlined'}
                    color={errorSelection[index] ? 'error' : 'primary'}
                    onClick={() => setConfigHandle(index, 'am')}
                  >
                    AM
                  </Button>
                  <Button
                    variant={pm ? 'contained' : 'outlined'}
                    color={errorSelection[index] ? 'error' : 'primary'}
                    onClick={() => setConfigHandle(index, 'pm')}
                  >
                    PM
                  </Button>
                </ButtonGroup>
              </Box>
            </Box>
          );
        })}
      </Box>

      <SubmitButton
        disabled={loadingCreatePetition}
        handler={sendPetition}
        text={loadingCreatePetition ? 'cargando' : 'Enviar solicitud'}
      />
    </Box>
  );
};

export default CreateShiftPetition;
