import React from 'react';
import { Box, Typography } from '@mui/material';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import TopBar from '../../../components/ui/TopNav';
import TableGenerator from '../../../components/info/TableGenerator';
import IconTitleCard from '../../../components/info/IconTitleCard';

const PaymentProof = () => {
  const content = [
    { right: 'Monto', left: '50000' },
    { right: 'Descripción', left: 'Traspaso a Tucar SpA' },
    { right: 'Fecha movimiento', left: '01/08/22' },
    { right: 'Nombre destinatario', left: 'Juan Carlos Gana' },
    { right: 'Cuenta destinatario', left: '001234567899' },
    { right: 'Nombre origen', left: 'Tucar SpA' },
    { right: 'Rut de origen', left: '99.999.999-9' },
    { right: 'Cuenta origen', left: '00123456789' },
    { right: 'Comentarios', left: 'pago 1/5 garantía' },
  ];

  const contentIconCard = (
    <>
      <Typography sx={{ color: '#FFF', fontFamily: 'Poppins-Regular', fontSize: '18px' }}>
        ¡Transferencia exitosa!
      </Typography>
      <Typography sx={{ color: '#FFF', fontFamily: 'Poppins-Regular', fontSize: '12px' }}>
        Detalles de la transferencia
      </Typography>
    </>
  );

  return (
    <Box marginBottom="80px" minHeight="80vh">
      <TopBar title="Comprobante de pago" />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: '15px',
          gap: '20px',
          transform: 'translateY(10%)',
        }}
      >
        <IconTitleCard IconBubble={DoneRoundedIcon} contentText={contentIconCard} />
        <TableGenerator content={content} />
      </Box>
    </Box>
  );
};

export default PaymentProof;
