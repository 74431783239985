import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card } from '@tucar/pit-stop';
import { Typography, Box } from '@mui/material';
import ProfilePhoto from '../../../../../public/images/profileImage.svg';
import { azulTucar } from '../../../../themes/variants/TucarColors';

const ParnerCard = ({ parnerData }) => {
  const { code, phone, firstname, lastname } = parnerData.info;
  const { photo } = parnerData;

  return (
    <Card style={{ padding: '20px 30px', gap: '16px', alignItems: 'center' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
        <img
          src={photo || ProfilePhoto}
          alt="profilePhoto"
          style={{
            objectFit: 'cover',
            height: '58px',
            width: '58px',
            borderRadius: '50%',
            border: `3px solid ${azulTucar}`,
          }}
        />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: '10px',
          }}
        >
          <Typography
            sx={{ color: '#2D2F39', fontSize: '16px', fontWeight: 500, paddingBottom: '6px' }}
          >
            Tu compañero
          </Typography>
          <Typography
            sx={{
              color: azulTucar,
              fontSize: '18px',
              fontWeight: 600,
              paddingBottom: '12px',
              lineHeight: 1.2,
            }}
          >
            {firstname} {lastname}
          </Typography>

          <Typography
            sx={{ color: '#5B5D71', fontSize: '14px', fontWeight: 500, paddingBottom: '4px' }}
          >
            Número de teléfono
          </Typography>
          <Typography sx={{ color: azulTucar, fontSize: '16px', fontWeight: 500 }}>
            {code} {phone.slice(0, 1)} {phone.slice(1, 5)} {phone.slice(5)}
          </Typography>
        </Box>
      </Box>
      <Button
        disabled={!phone || phone === ''}
        onClick={() => window.open(`tel:${code}${phone}`, '_self')}
      >
        Contactar
      </Button>
    </Card>
  );
};

ParnerCard.propTypes = {
  parnerData: PropTypes.shape({
    photo: PropTypes.string,
    info: PropTypes.shape({
      code: PropTypes.string,
      phone: PropTypes.string,
      firstname: PropTypes.string,
      lastname: PropTypes.string,
    }),
  }),
};

ParnerCard.defaultProps = {
  parnerData: {
    photo: '',
    info: {
      code: '+XX',
      phone: 'XXXXXXXXX',
      firstname: 'Nombre',
      lastname: 'Apellido',
    },
  },
};

export default ParnerCard;
