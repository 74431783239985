import moment from 'moment';
import { subDays, addDays } from 'date-fns';

export const getStartOfWeek = (date, format = true) => {
  let startOfWeek = new Date(date);
  startOfWeek = subDays(startOfWeek, (date.getDay() + 6) % 7);
  const newStartOfWeek = new Date(
    startOfWeek.getFullYear(),
    startOfWeek.getMonth(),
    startOfWeek.getDate(),
    0,
    0,
    0,
  );
  if (format) {
    return moment(newStartOfWeek).format('DD/MM/YYYY');
  }
  return moment(newStartOfWeek).format('YYYY-MM-DD[T]HH:mm:ss');
};

export const getEndOfWeek = (date, format = true) => {
  let endOfWeek = new Date(date);
  endOfWeek = addDays(endOfWeek, 6 - ((date.getDay() + 6) % 7));
  const newEndOfWeek = new Date(
    endOfWeek.getFullYear(),
    endOfWeek.getMonth(),
    endOfWeek.getDate(),
    23,
    59,
    59,
  );
  if (format) {
    return moment(newEndOfWeek).format('DD/MM/YYYY');
  }
  return moment(newEndOfWeek).format('YYYY-MM-DD[T]hh:mm:ss');
};
