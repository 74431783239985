import { gql } from '@apollo/client';

const GET_EXCHANGE_ADVICE = {
  query: gql`
    query getExchanges($driverId: String, $offset: Int, $limit: Int) {
      getExchanges(driverId: $driverId, limit: $limit, offset: $offset) {
        total
        instances {
          _id
          time
          status
          receiver {
            driver {
              _id
            }
          }
          deliverer {
            driver {
              _id
            }
          }
        }
      }
    }
  `,
  policies: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
};

export default GET_EXCHANGE_ADVICE;
