import React from 'react';
import { Card } from '@tucar/pit-stop';
import { Typography, Box } from '@mui/material';
import { capitalizeDate, getDateList } from '../../../../../helpers/dateFormat';
import getUniqueKey from '../../../../../helpers/customKey';
import ProfilePhoto from '../../../../../../public/images/profileImage.svg';

const CurrentShiftCard = () => {
  return (
    <Card style={{ padding: '20px 0px' }}>
      <Typography
        style={{
          color: '#5B5D71',
          fontSize: '16px',
          fontWeight: 600,
        }}
      >
        Turno actual - {capitalizeDate(new Date(), 'MMMM yyyy')}
      </Typography>

      <Box
        sx={{
          paddingTop: '14px',
          paddingBottom: '27px',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-evenly',
        }}
      >
        {getDateList(new Date()).map((date, index) => {
          const mark = index === 0;
          return (
            <Box
              key={getUniqueKey()}
              sx={{
                borderRadius: '100%',
                background: mark ? '#FFF' : 'transparent',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: mark ? '8px 12px' : '8px 0px',
                justifyContent: 'center',
                boxShadow: mark
                  ? '-4px -4px 9px 0px rgba(255, 255, 255, 0.88) inset, 2px -5px 14px 0px #D9D9D9 inset'
                  : 'none',
              }}
            >
              <Typography
                style={{
                  color: mark ? '#1F89FF' : '#2D2F39',
                  fontSize: '12px',
                  fontWeight: 400,
                  lineHeight: 1.2,
                }}
              >
                {capitalizeDate(date, 'ddd').replace('.', '')}
              </Typography>
              <Typography
                style={{
                  color: mark ? '#1F89FF' : '#2D2F39',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: 1.2,
                }}
              >
                {capitalizeDate(date, 'DD')}
              </Typography>
            </Box>
          );
        })}
      </Box>

      <Box
        sx={{
          width: '100%',
          paddingInline: '20px',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
        }}
      >
        <Card
          style={{
            width: '100%',
            padding: '20px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            gap: '5px',
          }}
        >
          <img
            src={ProfilePhoto}
            alt="profilePhoto"
            style={{
              objectFit: 'cover',
              height: '58px',
              width: '58px',
              borderRadius: '50%',
              border: `3px solid #1F89FF`,
            }}
          />
          <Box style={{ display: 'flex', flexDirection: 'column', gap: '6px', marginLeft: '10px' }}>
            <Typography
              style={{
                color: '#2D2F39',
                fontSize: '16px',
                fontWeight: 500,
              }}
            >
              Turno AM
            </Typography>
            <Typography
              style={{
                color: '#1F89FF',
                fontSize: '16px',
                fontWeight: 600,
              }}
            >
              08:00 AM - 15:00 PM
            </Typography>
          </Box>
        </Card>
        <Card
          style={{
            width: '100%',
            padding: '20px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            gap: '5px',
          }}
        >
          <img
            src={ProfilePhoto}
            alt="profilePhoto"
            style={{
              objectFit: 'cover',
              height: '58px',
              width: '58px',
              borderRadius: '50%',
              border: `3px solid #95D31C`,
            }}
          />
          <Box style={{ display: 'flex', flexDirection: 'column', gap: '6px', marginLeft: '10px' }}>
            <Typography
              style={{
                color: '#2D2F39',
                fontSize: '16px',
                fontWeight: 500,
              }}
            >
              Turno AM
            </Typography>
            <Typography
              style={{
                color: '#95D31C',
                fontSize: '16px',
                fontWeight: 600,
              }}
            >
              15:00 PM - 22:00 PM
            </Typography>
          </Box>
        </Card>
      </Box>

      <Box
        sx={{
          paddingTop: '20px',
          paddingInline: '20px',
          display: 'flex',
          flexDirection: 'row',
          gap: '20px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '10px',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              background: '#95D31C',
              width: '10px',
              height: '10px',
              borderRadius: '100%',
            }}
          />
          <Typography>Tu</Typography>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
          <Box
            sx={{
              background: '#1F89FF',
              width: '10px',
              height: '10px',
              borderRadius: '100%',
            }}
          />
          <Typography>Compañero</Typography>
        </Box>
      </Box>
    </Card>
  );
};

CurrentShiftCard.propTypes = {};

CurrentShiftCard.defaultProps = {};

export default CurrentShiftCard;
