import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useOutletContext } from 'react-router-dom';

import { useReactiveVar, useQuery } from '@apollo/client';

import TopBar from '../../../../components/ui/TopNav';
import { getAuthStore, getDriverStore } from '../../../../graphql/store';
import CarSheetInfoCard from './carSheetInfo';
import GET_RENT from '../../../../graphql/querys/car/getRent';
import LoadingProgress from '../../../../components/ui/LoadingProgress';

const CarSheetView = () => {
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);
  const { rent } = useReactiveVar(getDriverStore);
  const [handlePageChange] = useOutletContext();

  const { loading } = useQuery(GET_RENT.query, {
    ...GET_RENT.policies,
    variables: { uid },
  });

  useEffect(() => {
    handlePageChange(<TopBar title="Ficha técnica" />, true);
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <LoadingProgress allScreen={false} heightContainer="100%" />
      </Box>
    );
  }

  if (rent?.carId) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <CarSheetInfoCard carId={rent.carId} />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <Typography sx={{ fontFamily: 'Poppins-Regular', fontSize: '16px' }}>
        No tienes un auto asignado
      </Typography>
    </Box>
  );
};
export default CarSheetView;
