const hideEmail = (normalEmail) => {
  return normalEmail.replace(/(.{1})(.*)(?=@)/, (_, gp2, gp3) => {
    for (let i = 0; i < gp3.length; i += 1) {
      // eslint-disable-next-line no-param-reassign
      gp2 += '*';
    }
    return gp2;
  });
};

export default hideEmail;
