/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableRow,
  TableCell,
  tableCellClasses,
  IconButton,
  Collapse,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import { formatterCLP, formatterKm } from '../../../helpers/formatters';
import { azulTucar } from '../../../themes/variants/TucarColors';
import PopoverTucar from '../popoverInfo';

export function separateSeccions(sections, content) {
  if (sections.length === 1) {
    const aux = sections[0];
    const costoKmAux = Math.ceil(aux.variable * content.currency * (1 - content.discount / 100));
    return [
      {
        tramo: `${aux.startKm} a ${aux.endKm} km`,
        costoKm: `${formatterCLP.format(costoKmAux)}`,
        tramoKm: `${formatterKm.format(content.totalKm)}`,
        costoKmAll: costoKmAux * content.totalKm,
        discount: content.discount,
      },
    ];
  }
  const auxSections = sections.map((aux) => {
    const costoKmAux = Math.ceil(aux.variable * content.currency * (1 - content.discount / 100));
    if (aux.traveled > 0) {
      return {
        tramo: `${aux.startKm} a ${aux.endKm} km`,
        costoKm: `${formatterCLP.format(costoKmAux)}`,
        tramoKm: `${formatterKm.format(aux.traveled)}`,
        costoKmAll: costoKmAux * aux.traveled,
        discount: content.discount,
      };
    }
    if (content.totalKm === 0)
      return {
        tramo: `${aux.startKm} a ${aux.endKm} km`,
        costoKm: `${formatterCLP.format(costoKmAux)}`,
        tramoKm: `${formatterKm.format(0)}`,
        costoKmAll: aux.variable * content.currency * 0,
        discount: content.discount,
      };
    return false;
  });
  return auxSections.filter((elem) => elem);
}

const RentDetailTable = ({ content, backgroundColor }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const tableCellStyle = {
    [`& .${tableCellClasses.root}`]: {
      borderBottom: 'none',
    },
    borderColor: backgroundColor,
    paddingInline: '0px',
    fontSize: '12px',
    fontFamily: 'Poppins-Light',
    color: '#AAAAAA',
    lineHeight: 1,
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        backgroundColor,
        borderRadius: '7px',
      }}
    >
      {content.tablePrimal.map((elem) => {
        return (
          <Box key={`${elem.right}`}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography
                  sx={{
                    color: '#808080',
                    fontFamily: 'Poppins-Regular',
                    fontSize: '12px',
                  }}
                >
                  {elem.right}
                </Typography>
                {elem.popText ? <PopoverTucar popText={elem.popText} /> : null}
              </Box>
              {elem.collapse ? (
                <Box
                  sx={{ display: 'flex', gap: '0px', alignItems: 'center', justifyContent: 'end' }}
                >
                  <Typography
                    sx={{ color: '#808080', fontFamily: 'Poppins-Regular', fontSize: '12px' }}
                  >
                    {elem.left}
                  </Typography>
                  {open ? (
                    <IconButton
                      onClick={handleClick}
                      sx={{
                        paddingRight: '0px',
                        display: 'flex',
                        justifyContent: 'end',
                        alignItems: 'center',
                      }}
                    >
                      <KeyboardArrowUpOutlinedIcon
                        sx={{ margin: '0px', fontSize: '12px', color: '#333333' }}
                      />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={handleClick}
                      sx={{
                        paddingRight: '0px',
                        display: 'flex',
                        justifyContent: 'end',
                        alignItems: 'center',
                      }}
                    >
                      <KeyboardArrowDownOutlinedIcon
                        sx={{
                          margin: '0px',
                          verticalAlign: 'start',
                          fontSize: '12px',
                          color: '#333333',
                        }}
                      />
                    </IconButton>
                  )}
                </Box>
              ) : (
                <Typography
                  sx={{ color: '#808080', fontFamily: 'Poppins-Regular', fontSize: '12px' }}
                >
                  {elem.left}
                </Typography>
              )}
            </Box>

            {elem.collapse ? (
              <Collapse
                sx={{
                  margin: '0px',
                  padding: '0px',
                  borderRadius: open ? '0px 0px 7px 7px' : '7px',
                }}
                in={open}
                timeout="auto"
                unmountOnExit
              >
                <Box margin="0px" sx={{ overflowX: 'scroll' }}>
                  <Table size="small">
                    <TableBody>
                      <TableRow colSpan={4}>
                        <TableCell size="small" sx={{ ...tableCellStyle }} align="center">
                          Tramo
                        </TableCell>
                        <TableCell size="small" sx={{ ...tableCellStyle }} align="center">
                          Costo x km
                        </TableCell>
                        <TableCell size="small" sx={{ ...tableCellStyle }} align="center">
                          Tramo
                        </TableCell>
                        <TableCell size="small" sx={{ ...tableCellStyle }} align="center">
                          Costo kms
                        </TableCell>
                      </TableRow>

                      {elem.collapse.map((item, index) => {
                        return (
                          // eslint-disable-next-line react/no-array-index-key
                          <TableRow key={`${item.tramo}-${index}`} colSpan={4}>
                            <TableCell size="small" sx={{ ...tableCellStyle }} align="center">
                              {item.tramo}
                            </TableCell>
                            <TableCell size="small" sx={{ ...tableCellStyle }} align="center">
                              {item.costoKm}{' '}
                            </TableCell>
                            <TableCell size="small" sx={{ ...tableCellStyle }} align="center">
                              {item.tramoKm}
                            </TableCell>
                            <TableCell size="small" sx={{ ...tableCellStyle }} align="center">
                              {formatterCLP.format(item.costoKmAll)}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            ) : null}
          </Box>
        );
      })}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '10px',
        }}
      >
        <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
          <Typography sx={{ fontSize: '14px', fontFamily: 'Poppins-Medium', color: azulTucar }}>
            Total día con descuento
          </Typography>
        </Box>

        <Typography
          sx={{ fontSize: '14px', fontFamily: 'Poppins-Medium', color: azulTucar }}
          noWrap
        >
          {formatterCLP.format(content.total)}
        </Typography>
      </Box>
    </Box>
  );
};

RentDetailTable.propTypes = {
  content: PropTypes.shape({}),
  backgroundColor: PropTypes.string,
};

RentDetailTable.defaultProps = {
  backgroundColor: '#F3F3F3',
  content: {},
};

export default RentDetailTable;
