import { gql } from '@apollo/client';

const GET_RENT_QUEUE_STATUS = {
  query: gql`
    query GetRentQueueStatus($uid: ID!) {
      getDriver(uid: $uid) {
        createdAt
        docs {
          updatedAt
          files {
            status
          }
        }
      }
    }
  `,
  policies: {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: 'true',
    errorPolicy: 'all',
  },
};

export default GET_RENT_QUEUE_STATUS;
