import React from 'react';
import PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';

import { getDriverStore } from '../../../graphql/store';
import CardButtonDash from '../CardButtonDash';

const BankButton = ({ styleProps, tutorial, contentDirection }) => {
  const navigate = useNavigate();
  const { labelV2 } = useReactiveVar(getDriverStore);

  const textContainer = (
    <Typography
      variant="span"
      sx={{
        display: 'inline-block',
        fontFamily: 'Poppins-Regular',
        lineHeight: 1.2,
        fontSize: '0.875rem',
      }}
    >
      Agrega tu{' '}
      <Typography
        sx={{
          display: 'inline-block',
          fontFamily: 'Poppins-SemiBold',
          lineHeight: 1.2,
          fontSize: '0.875rem',
        }}
      >
        cuenta bancaria
      </Typography>{' '}
      para que{' '}
      <Typography
        sx={{
          display: 'inline',
          fontFamily: 'Poppins-SemiBold',
          lineHeight: 1.2,
          fontSize: '0.875rem',
        }}
      >
        depositemos tus ganancias
      </Typography>{' '}
      en Uber.
    </Typography>
  );

  return (
    <CardButtonDash
      IconElement={CreditCardOutlinedIcon}
      tutorial={tutorial}
      colors={{ primary: '#40B6D4', secondary: '#EFFCFF' }}
      sendText="Agregar cuenta"
      content={textContainer}
      styleProps={{ container: styleProps }}
      handleGo={() => navigate(labelV2 ? '/app/v2/profile' : '/app/profile', { replace: true })}
      contentDirection={contentDirection}
    />
  );
};

BankButton.propTypes = {
  contentDirection: PropTypes.string,
  tutorial: PropTypes.bool,
  styleProps: PropTypes.shape({}),
};

BankButton.defaultProps = {
  contentDirection: 'row',
  tutorial: false,
  styleProps: {},
};

export default BankButton;
