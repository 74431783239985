import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Card, Typography, Icon } from '@tucar/pit-stop';
import PressIconButton from '../../buttons/PressIconButton';
import { formatterCLP } from '../../../helpers/formatters';

function trasnlateUberCategory(category) {
  switch (category) {
    case 'fare':
      return 'Tarifa';
    case 'other':
      return 'Otro';
    case 'tips':
      return 'Propina';
    case 'promotion':
      return 'Promoción';
    default:
      return 'Tarifa';
  }
}

const UberCard = ({ uberTrip }) => {
  return (
    <Card
      style={{
        position: 'relative',
        alignItems: 'unset',
      }}
    >
      <Box
        style={{
          content: '',
          height: '100%',
          width: '12px',
          borderRadius: '12px 0 0 12px',
          position: 'absolute',
          top: 0,
          left: 0,
          backgroundColor: '#1F89FF',
        }}
      />
      <Box sx={{ padding: '20px 28px 20px 40px', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
          <PressIconButton size="40px">
            <Icon color="#1F89FF" size="17px" name="calendarTodayOutlined" />
          </PressIconButton>
          <Typography
            style={{
              color: '#71758E',
              fontSize: '13px',
              fontWeight: 500,
            }}
          >
            Fecha - {moment(uberTrip.datetime).format('DD/MM/YYYY')}
          </Typography>
        </Box>

        <Box
          sx={{
            marginTop: '20px',
            display: 'flex',
            gap: '12px',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            style={{
              color: '#2D2F39',
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: 1.2,
            }}
          >
            Ganancia total
          </Typography>
          <Typography
            style={{
              color: '#1F89FF',
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: 1.2,
            }}
          >
            {formatterCLP.format(uberTrip.amount)}
          </Typography>
        </Box>

        <Box
          sx={{
            marginTop: '8px',
            display: 'flex',
            gap: '12px',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            style={{
              color: '#2D2F39',
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: 1.2,
            }}
          >
            Tipo de pago
          </Typography>
          <Typography
            style={{
              color: '#1F89FF',
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: 1.2,
            }}
          >
            {trasnlateUberCategory(uberTrip.category)}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};

UberCard.propTypes = {
  uberTrip: PropTypes.shape({
    datetime: PropTypes.string,
    amount: PropTypes.number,
    category: PropTypes.string,
  }),
};

UberCard.defaultProps = {
  uberTrip: {
    datetime: '',
    amount: 0,
    category: 'fare',
  },
};

export default UberCard;
