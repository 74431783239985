import { gql } from '@apollo/client';

const GET_PETITIONS = {
  query: gql`
    query GetShiftChangePetitions(
      $driver: String
      $start: String
      $end: String
      $offset: Int
      $status: ShiftChangePetitionStatus
      $limit: Int
      $sortBy: String
    ) {
      getShiftChangePetitions(
        driver: $driver
        start: $start
        end: $end
        offset: $offset
        status: $status
        sortBy: $sortBy
        limit: $limit
      ) {
        offset
        limit
        total
        instances {
          _id
          petitioner
          status
          createdAt
          closedDate
          expiration
          rollbackDate
          participants {
            id
            accepted
            futureShifts {
              name
              startTime
              endTime
              type
              _id
            }
            pastShifts {
              _id
              name
              startTime
              endTime
              basePrice
              type
            }
          }
        }
      }
    }
  `,
  policies: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
};

export default GET_PETITIONS;
