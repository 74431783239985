import { gql } from '@apollo/client';

const URL_SIGN_IN_UBER = {
  query: gql`
    query Query($redirectUrl: String!) {
      urlSingInUber(redirectUrl: $redirectUrl)
    }
  `,
  policies: {},
};

export default URL_SIGN_IN_UBER;
