import { gql } from '@apollo/client';
import { getAuthStore } from '../../store';
import { setDocs } from '../../store/docs/setters';

const GET_DOCUMENTS = {
  query: gql`
    query Query($uid: ID!) {
      getDocument(uid: $uid) {
        _id
        files {
          name
          status
          expire
          extension
          url
        }
      }
    }
  `,
  policies: {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: (response) => {
      const {
        data: { uid },
      } = getAuthStore();
      // eslint-disable-next-line no-underscore-dangle
      if (response?.getDocument?._id === uid) {
        const newDocs = response.getDocument;
        setDocs(newDocs.files);
      }
    },
    errorPolicy: 'all',
  },
};

export default GET_DOCUMENTS;
