import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useOutletContext } from 'react-router-dom';
import { Box } from '@mui/material';
import { Loader, Typography } from '@tucar/pit-stop';
import { useReactiveVar, useLazyQuery } from '@apollo/client';
import { getAuthStore } from '../../../graphql/store';

import GET_RENT_QUEUE_STATUS from '../../../graphql/querys/driver/getRentQueueStatus';
import TopNav2 from '../../../components/ui/TopNav/newTopNav';
import { azulTucar } from '../../../themes/variants/TucarColors';
import StepRentStatus from './StepRentStatus';
import getUniqueKey from '../../../helpers/customKey';

const RentQueueStatus = () => {
  const [handlePageChange] = useOutletContext();
  const [currentData, setCurrentData] = useState([]);

  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);

  const [getStatus, { loading, data }] = useLazyQuery(GET_RENT_QUEUE_STATUS.query, {
    ...GET_RENT_QUEUE_STATUS.policies,
    variables: { uid },
  });

  useEffect(() => {
    handlePageChange(null, true);
    getStatus();
  }, []);

  useEffect(() => {
    if (data && data?.getDriver) {
      const { docs, createdAt: registerAt } = data.getDriver;
      const registerDate = moment(registerAt).format('DD MMMM YYYY');

      const documentData =
        docs?.updatedAt &&
        docs?.files.length > 0 &&
        docs.filter((elem) => elem.status !== 'aproved').length === 0
          ? moment(docs?.updatedAt).format('DD MMMM YYYY')
          : 'Pendiente';

      setCurrentData([
        {
          title: 'En espera de vehículo',
          date: 'Pendiente',
          completed: false,
          icon: 'carRentalFilled',
        },
        {
          title: 'En lista de espera',
          date: '-',
          completed: true,
          icon: 'notInterestedOutlined',
        },
        {
          title: 'Documentación aceptada',
          date: documentData,
          completed: documentData !== 'Pendiente',
          icon: 'textSnippetOutlined',
        },
        {
          title: 'Registro',
          date: registerDate,
          completed: true,
          icon: 'logoutFilled',
        },
      ]);
    }
  }, [loading]);

  if (loading || currentData.length === 0) {
    return (
      <Box>
        <TopNav2 title="Estado de solicitud" />
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Loader size={32} pointRadio="4px" />
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <TopNav2 title="Estado de solicitud" />
      <Box
        sx={{
          padding: '47px 30px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
          <Typography
            color="#5B5D71"
            style={{ fontSize: '14px', fontWeight: 500, lineHeight: 1.2 }}
          >
            Ingreso de solicitud
          </Typography>
          <Typography
            color={azulTucar}
            style={{ fontSize: '16px', fontWeight: 500, lineHeight: 1.2 }}
          >
            10 Octubre 2021
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
          <Typography
            align="right"
            color="#5B5D71"
            style={{ fontSize: '14px', fontWeight: 500, lineHeight: 1.2 }}
          >
            Hora
          </Typography>
          <Typography
            align="right"
            color={azulTucar}
            style={{ fontSize: '16px', fontWeight: 500, lineHeight: 1.2 }}
          >
            00:00 hrs.
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          marginLeft: '30px',
          width: 'max-content',
          padding: '8px 12px',
          backgroundColor: azulTucar,
          borderRadius: '4px',
          border: '1px solid #FFF',
          boxShadow:
            '4px 4px 20px 0px rgba(111, 140, 176, 0.41), -6px -6px 20px 0px #FFF, 2px 2px 4px 0px rgba(114, 142, 171, 0.10)',
        }}
      >
        <Typography
          align="center"
          color="#FFF"
          style={{
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: 1.2,
          }}
        >
          En espera de vehículo
        </Typography>
      </Box>
      <Box
        sx={{
          paddingInline: '30px',
          marginTop: '42px',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
        }}
      >
        {currentData.map((step, index) => {
          return (
            <StepRentStatus
              key={getUniqueKey()}
              title={step.title}
              date={step.date}
              completed={step.completed}
              icon={step.icon}
              last={index === currentData.length - 1}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default RentQueueStatus;
