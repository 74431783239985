import React, { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { Box } from '@mui/material';
import TopBar from '../../../components/ui/TopNav';
import { getPlanStore } from '../../../graphql/store';
import FullDashboard from './fullDash';
import TermsAndConditionModal from '../../../components/info/termsAndCondition';
import NewVersionModal from '../../../components/info/NewVersionModal';

const Dashboard = () => {
  const [handlePageChange] = useOutletContext();

  const { plan } = useReactiveVar(getPlanStore);
  useEffect(() => {
    handlePageChange(<TopBar title="Inicio" home addTutorial />, true);
  }, []);

  return (
    <Box>
      <NewVersionModal />
      <TermsAndConditionModal />
      <FullDashboard shared={!plan?.exclusive} />
    </Box>
  );
};

export default Dashboard;
