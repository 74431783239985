import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
  TableContainer,
} from '@mui/material';
import { formatterCLP } from '../../../helpers/formatters';

const headerTextStyle = {
  padding: '10px 20px',
  borderBottom: '1px solid #AAACBB',
  fontFamily: 'Poppins-Medium',
  fontSize: '13px',
  color: '#0057B8',
  lineHeight: 1.2,
};

const textStyle = {
  fontFamily: 'Poppins-Regular',
  fontSize: '13px',
  color: '#2D2F39',
  lineHeight: 1.2,
  border: 0,
};

const TableSection = ({ billDays }) => {
  if (billDays.length === 0)
    return (
      <Box>
        <Typography>Ocurrió un error cargando tu información</Typography>
      </Box>
    );

  const allTraveledInfo = billDays
    .map((billDay) => {
      const {
        currency,
        discount,
        traveledSections,
        plan: { plan },
      } = billDay;
      return traveledSections.map((traveledSection) => {
        const { startKm, endKm, variable, traveled } = traveledSection;
        const variablePrice = variable * currency * (1 - discount / 100);
        return [
          plan.name,
          `${startKm}-${endKm}`,
          variablePrice,
          traveled,
          variablePrice * traveled,
        ];
      });
    })
    .reduce((acc, elem) => [...acc, ...elem], []);

  const reducedTraveledInfo = allTraveledInfo.reduce((acc, curr) => {
    const currentKey = `${curr[0]}/${curr[1]}`;
    if (!(currentKey in acc)) {
      acc[currentKey] = {
        name: curr[0],
        range: curr[1],
        price: curr[2],
        traveled: curr[3],
        total: curr[4],
      };
    } else {
      acc[currentKey].traveled += curr[3];
      acc[currentKey].total += curr[4];
    }
    return acc;
  }, {});

  return (
    <TableContainer sx={{ borderRadius: '14px' }}>
      <Table sx={{ minWidth: '100%' }}>
        <TableHead>
          <TableRow>
            <TableCell align="center" sx={headerTextStyle}>
              Tipo de plan
            </TableCell>
            <TableCell align="center" sx={headerTextStyle}>
              Rango KM
            </TableCell>
            <TableCell align="center" sx={headerTextStyle}>
              Precio por kilómetro
            </TableCell>
            <TableCell align="center" sx={headerTextStyle}>
              Kilómetros recorridos
            </TableCell>
            <TableCell align="center" sx={headerTextStyle}>
              Precio total
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.values(reducedTraveledInfo).map((elem) => (
            <TableRow
              key={`${elem.name}/${elem.range}`}
              sx={{
                '&:nth-of-type(even)': {
                  background: '#F6F6F6',
                },
              }}
            >
              <TableCell align="center" sx={textStyle}>
                {elem.name}
              </TableCell>
              <TableCell align="center" sx={textStyle}>
                {elem.range}
              </TableCell>
              <TableCell align="center" sx={textStyle}>
                {formatterCLP.format(elem.price)}
              </TableCell>
              <TableCell align="center" sx={textStyle}>
                {elem.traveled}km
              </TableCell>
              <TableCell align="center" sx={textStyle}>
                {formatterCLP.format(elem.total)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

TableSection.propTypes = {
  billDays: PropTypes.arrayOf(PropTypes.shape({})),
};

TableSection.defaultProps = {
  billDays: [],
};

export default TableSection;
