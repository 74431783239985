import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Button } from '@mui/material';
import Logo from '../../Logo';
import Emoji from '../emojis';

const OnBoardingTitle = ({
  noLineal,
  title,
  showLogo,
  specialEmoji,
  subtitle,
  linkEmergency,
  emojiSymbol,
}) => {
  if (noLineal) {
    return (
      <Box sx={{ width: '100%', paddingInline: '20px' }}>
        <Typography sx={{ fontFamily: 'Poppins-Medium', textAlign: 'start', marginBottom: '10px' }}>
          {title}
        </Typography>
        <Typography sx={{ fontFamily: 'Poppins-Regular', fontSize: '14px', color: '#696969' }}>
          {subtitle}
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '20px',
        }}
      >
        {showLogo ? <Logo marginTop="0px" margin="0px" height="48px" /> : null}
        {specialEmoji ? <Emoji symbol={specialEmoji} label="emoji" /> : null}
        <Typography sx={{ fontFamily: 'Poppins-Regular' }} variant="h2">
          {title} <Emoji symbol={emojiSymbol} label="emoji" />
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {subtitle && (
            <Typography sx={{ fontFamily: 'Poppins-Regular', paddingInline: '10px' }} variant="h3">
              {subtitle}
            </Typography>
          )}
          {linkEmergency?.link && (
            <Button
              variant="text"
              size="small"
              sx={{
                marginInline: '5px',
                lineHeight: 1,
                padding: '0px',
                textAlign: 'initial',
                fontFamily: 'Poppins-Light',
                textDecoration: 'underline',
                textDecorationColor: '#333333',
                color: '#333333',
              }}
              onClick={() => {
                window.open(linkEmergency?.link, '_blank', 'noopener,noreferrer');
              }}
            >
              {linkEmergency?.text}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

OnBoardingTitle.propTypes = {
  noLineal: PropTypes.bool,
  title: PropTypes.string,
  showLogo: PropTypes.bool,
  specialEmoji: PropTypes.string,
  subtitle: PropTypes.string,
  linkEmergency: PropTypes.shape({ link: PropTypes.string, text: PropTypes.string }),
  emojiSymbol: PropTypes.string,
};

OnBoardingTitle.defaultProps = {
  noLineal: false,
  title: '',
  showLogo: false,
  specialEmoji: '',
  subtitle: '',
  linkEmergency: { link: '', text: '' },
  emojiSymbol: '',
};

export default OnBoardingTitle;
