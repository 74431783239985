import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useReactiveVar, useQuery } from '@apollo/client';
import { useOutletContext } from 'react-router-dom';

import { getAuthStore } from '../../../../graphql/store';
import GET_NOTIFICATIONS from '../../../../graphql/querys/notifications/getNotifications';
import LoadingProgress from '../../../../components/ui/LoadingProgress';
import NotiBox from '../../notifications/OldVersion/OldNotiBox';

const WaitingNotifications = () => {
  const [handlePageChange] = useOutletContext();

  useEffect(() => {
    handlePageChange();
  }, []);

  const [content, setContent] = useState([]);
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);

  const { loading, data, refetch } = useQuery(GET_NOTIFICATIONS.query, {
    ...GET_NOTIFICATIONS.policies,
    variables: {
      driverId: uid,
    },
  });

  useEffect(() => {
    if (!loading && data?.getDriverNotifications) {
      const aux = data.getDriverNotifications.map((elem) => {
        const { _id: elemId, message, sentAt, read } = elem;
        return (
          <NotiBox
            refetch={refetch}
            key={elemId}
            text={message}
            redirect=""
            time={sentAt}
            read={read}
            notificationId={elemId}
          />
        );
      });
      setContent(aux);
    }
  }, [loading]);

  if (loading) {
    return <LoadingProgress allScreen={false} heightContainer="100%" />;
  }

  return (
    <Box sx={{ height: '100%' }}>
      {content.length > 0 ? (
        <Box sx={{ dispaly: 'flex', flexDirection: 'column', gap: '10px' }}>{content}</Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <Typography sx={{ fontFamily: 'Poppins-Regular', fontSize: '16px' }}>
            No tienes notificaciones
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default WaitingNotifications;
