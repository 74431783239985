import RectangleColors from '../../../../../public/images/background/Rectangle.png';

export const backgroundStyle = {
  paddingInline: '30px',
  position: 'relative',
  zIndex: 1,
  '::before': {
    content: '""',
    position: 'absolute',
    zIndex: -1,
    top: '0px',
    backgroundImage: `url(${RectangleColors})`,
    backgroundSize: 'cover',
    left: '0px',
    borderRadius: '0px 0px 30px 30px',
    height: '270px',
    width: '100vw',
  },
};

export const currentData = {
  totalExpenses: 85000,
  totalCollections: 85000,
  totalGaining: 150000,
  totalCash: 120000,
  kmTotal: 100,
  kmUber: 89,
  uberPercentage: Math.round((89 / 100) * 100),
  kmByDay: [
    {
      value: 75,
      label: '01',
      title: 'lun',
    },
    {
      value: 83,
      label: '02',
      title: 'mar',
    },
    {
      value: 36,
      label: '03',
      title: 'mié',
    },
    {
      value: 39,
      label: '04',
      title: 'jue',
    },
    {
      value: 48,
      label: '05',
      title: 'vie',
    },
  ],
};

export const getStepNumber = (step) => {
  switch (step) {
    case 'dashboard':
      return 1;
    case 'subscription':
      return 2;
    case 'gainings':
      return 3;
    case 'liquidations':
      return 4;
    case 'next':
      return 5;
    default:
      return 0;
  }
};

export const getStepTitle = (step) => {
  switch (step) {
    case 'dashboard':
      return 'Dashboard';
    case 'subscription':
      return 'Suscripción';
    case 'gainings':
      return 'Ganancias';
    case 'liquidations':
      return 'Liquidaciones';
    default:
      return '';
  }
};

export const getStepDescription = (step) => {
  switch (step) {
    case 'dashboard':
      return 'Acá tienes acceso al dasboard central. Monitorea tus kilómetros, accede a herramientas clave y toma obtiene la visualización de datos desde aquí.';
    case 'subscription':
      return 'Tu plan de suscripción personalizado te muestra tu arriendo y los detalles de tu vehículo eléctrico asignado. Accede y gestiona fácilmente tu plan y vehículo desde un mismo lugar.';
    case 'gainings':
      return 'La sección de ganancias te brinda una visión detallada de tus ingresos diarios, mostrando el dinero recaudado y un desglose de eventos y sanciones asociadas.';
    case 'liquidations':
      return 'Aquí encontrarás un resumen semanal de tu plan de arriendo, con detalles sobre los kilómetros recorridos, ganancias y multas asociadas a tu tipo de plan y vehículo asignado.';
    default:
      return '';
  }
};
