import { gql } from '@apollo/client';

const GET_LABEL = {
  query: gql`
    query GetLabel($labelId: ID!) {
      getLabel(_id: $labelId) {
        elements
      }
    }
  `,
  policies: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
};

export default GET_LABEL;
