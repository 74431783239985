import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Button, Icon, Typography } from '@tucar/pit-stop';
import { azulTucar } from '../../../../../themes/variants/TucarColors';

const ShortcutStep = ({ onNext }) => {
  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={{ position: 'relative', zIndex: 1 }}>
        <Box
          sx={{
            position: 'absolute',
            zIndex: 2,
            top: 'calc(100% + 100px)',
            '::before': {
              content: '""',
              zIndex: -1,
              position: 'absolute',
              bottom: '-50px',
              left: '-10%',
              width: '200vw',
              height: '130vh',
              borderRadius: '0px 0px 100vw 100vw',
              transform: 'translateX(-25%)',
              background: 'linear-gradient(180deg, #EAFFFE -20%, rgba(205, 201, 241, 0.80) 100%)',
              backdropFilter: 'blur(2px)',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              paddingBottom: '30px',
              paddingInline: '10%',
            }}
          >
            <Typography
              align="left"
              color={azulTucar}
              style={{ fontSize: '18px', fontWeight: 600 }}
            >
              Accesos rápidos
            </Typography>
            <Typography
              color={azulTucar}
              align="left"
              style={{ fontSize: '14px', fontWeight: 400, lineHeight: '16px' }}
            >
              Encuentra rápidamente tus tags, pagos y visualiza tus viajes desde un solo lugar.
              Simplifica tu experiencia con estos accesos directos
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <Typography color={azulTucar} style={{ fontSize: '14px', fontWeight: 500 }}>
              Paso 9/11
            </Typography>
            <Button
              size="small"
              onClick={() => {
                onNext();
                document.getElementById('section-10').scrollIntoView({ behavior: 'auto' });
              }}
            >
              Siguiente
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            position: 'absolute',
            zIndex: 2,
            display: 'flex',
            gap: '15px',
            marginTop: '30px',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <Button size="small" style={{ padding: '12px 16px' }} contentStyle={{ columnGap: '4px' }}>
            <Icon name="cardTravelFilled" color="#5B5D71" size="14px" />
            <Typography color="#5B5D71" style={{ fontSize: '14px', fontWeight: 500 }}>
              Viajes
            </Typography>
          </Button>
          <Button size="small" style={{ padding: '12px 16px' }} contentStyle={{ columnGap: '4px' }}>
            <Icon name="directionsCarOutlined" color="#5B5D71" size="14px" />
            <Typography color="#5B5D71" style={{ fontSize: '14px', fontWeight: 500 }}>
              TAG
            </Typography>
          </Button>
          <Button size="small" style={{ padding: '12px 16px' }} contentStyle={{ columnGap: '4px' }}>
            <Icon name="attachMoneyOutlined" color="#5B5D71" size="14px" />
            <Typography color="#5B5D71" style={{ fontSize: '14px', fontWeight: 500 }}>
              Boletas
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

ShortcutStep.propTypes = {
  onNext: PropTypes.func,
};

ShortcutStep.defaultProps = {
  onNext: () => {},
};

export default ShortcutStep;
