import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { azulTucar, celesteTucar } from '../../../themes/variants/TucarColors';

const IncludeCheckBox = ({ title, include, excluye, combustion, checkColor, backgroundColor }) => {
  return (
    <Box sx={{ height: '100%', overflowY: 'scroll', backgroundColor, borderRadius: '15px' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '10px 20px 20px 10px',
          gap: '10px',
        }}
      >
        {title && (
          <Typography sx={{ fontSize: '12px', fontFamily: 'Poppins-Medium' }}>{title}</Typography>
        )}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'left',
            gap: '10px',
            flexDirection: 'column',
          }}
        >
          {include.map((elem) => {
            return (
              <Box key={elem} sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <CheckCircleRoundedIcon
                  sx={{
                    fontFamily: 'Poppins-Regular',
                    fontSize: '14px',
                    color: combustion ? azulTucar : checkColor,
                  }}
                />
                <Typography
                  sx={{
                    fontFamily: 'Poppins-Regular',
                    fontSize: '12px',
                    lineHeight: 1,
                  }}
                >
                  {elem}
                </Typography>
              </Box>
            );
          })}
          {excluye.map((elem) => {
            return (
              <Box key={elem} sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <CancelOutlinedIcon
                  sx={{ fontFamily: 'Poppins-Regular', fontSize: '14px', color: '#AAAAAA' }}
                />

                <Typography
                  sx={{
                    fontFamily: 'Poppins-Regular',
                    fontSize: '12px',
                    color: '#AAAAAA',
                    lineHeight: 1,
                  }}
                >
                  {elem}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

IncludeCheckBox.propTypes = {
  title: PropTypes.string,
  include: PropTypes.arrayOf(PropTypes.string),
  excluye: PropTypes.arrayOf(PropTypes.string),
  combustion: PropTypes.bool,
  checkColor: PropTypes.string,
  backgroundColor: PropTypes.string,
};

IncludeCheckBox.defaultProps = {
  backgroundColor: '#F3F3F3',
  checkColor: celesteTucar,
  combustion: true,
  title: '',
  include: [],
  excluye: [],
};

export default IncludeCheckBox;
