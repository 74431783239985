import React from 'react';
import { IconButton, Typography } from '@tucar/pit-stop';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

import { azulTucar } from '../../../../themes/variants/TucarColors';

const PetitionTutorialButton = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ display: 'inline' }}>
        <Typography
          style={{
            color: '#5A6571',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: 1.2,
            paddingRight: '5px',
          }}
        >
          ¿Tienes dudas? ve el
        </Typography>
        <Typography
          style={{ color: azulTucar, fontSize: '14px', fontWeight: 600, lineHeight: 1.2 }}
        >
          tutorial aquí
        </Typography>
      </Box>

      <IconButton
        onClick={() => navigate('/app/v2/tutorial/petitions', { replace: true })}
        style={{ background: '#FFF', width: '46px', height: '46px' }}
        iconProps={{ name: 'playArrowFilled', size: '38px', color: azulTucar }}
      />
    </Box>
  );
};

export default PetitionTutorialButton;
