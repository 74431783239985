/* eslint-disable import/prefer-default-export */
import { makeVar } from '@apollo/client';

export const leadStore = makeVar({
  admin: {
    ref: false,
    hubspotId: false,
  },
  hubspot: {
    id: false,
    deal: false,
  },
  meeting: {
    end: false,
    start: false,
    reschedule: false,
    cancel: false,
    link: false,
    status: false,
    id: false,
  },
});
