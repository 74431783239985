import React, { useEffect, useState } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useReactiveVar, useLazyQuery } from '@apollo/client';

import { getAuthStore, getDriverStore } from '../../../graphql/store';
import TopBar from '../../../components/ui/TopNav';
import LoadingProgress from '../../../components/ui/LoadingProgress';
import SubmitButton from '../../../components/SubmitButton';
import PetitionBox from './petitionBox';
import GET_SHIFT_PETITIONS_V1 from '../../../graphql/querys/exchange/getShiftChangePetitionsV1';

const ShiftPetitions = () => {
  const navigate = useNavigate();
  const [handlePageChange] = useOutletContext();
  const [petitionInfo, setPetitionInfo] = useState([]);
  const [shiftOptions, setShiftOptions] = useState([]);
  const [createPetition, setCreatePetition] = useState(false);
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);
  const { labelSubscription } = useReactiveVar(getDriverStore);
  const [getPetitionsInfo, { data: petitionsInfo, loading: loadingPetitionsInfo }] = useLazyQuery(
    GET_SHIFT_PETITIONS_V1.query,
    {
      ...GET_SHIFT_PETITIONS_V1.policies,
      variables: {
        uid,
        driver: uid,
        sortBy: '-createdAt',
        offset: 0,
        limit: 3,
      },
    },
  );

  useEffect(() => {
    handlePageChange(
      <TopBar
        prevPage={labelSubscription ? '/app/subscription' : '/app/dashboard'}
        title="Solicitudes cambio de turno"
      />,
      true,
    );
    getPetitionsInfo();
  }, []);

  useEffect(() => {
    if (!loadingPetitionsInfo && petitionsInfo?.getShiftChangePetitions) {
      const posibleShifts = petitionsInfo.getDriverPlanV2?.plan?.shifts;
      const optionsObject = posibleShifts.reduce((acc, shiftInfo) => {
        const { _id: idShift, type } = shiftInfo;
        return { ...acc, [idShift]: type };
      }, {});
      setShiftOptions(optionsObject);

      const { instances } = petitionsInfo.getShiftChangePetitions;
      setPetitionInfo(instances || []);
      setCreatePetition(instances.filter((elem) => ['created'].includes(elem.status)).length > 0);
    }
  }, [loadingPetitionsInfo, petitionsInfo]);

  if (loadingPetitionsInfo) {
    return <LoadingProgress />;
  }

  return (
    <Box sx={{ paddingInline: '20px', display: 'flex', flexDirection: 'column', gap: '20px' }}>
      <Typography sx={{ fontFamily: 'Poppins-Regular', lineHeight: 1.2, fontSize: '14px' }}>
        En este espacio puedes solicitarle a tu pareja un cambio de turno temporal a lo largo de la
        semana.
      </Typography>
      <Typography sx={{ fontFamily: 'Poppins-Regular', lineHeight: 1.2, fontSize: '14px' }}>
        Debes tener en consideración que las solicitudes se hacen con, al menos, un día de
        anticipación y que, una vez aceptado, será válido hasta que se acepte un nuevo cambio ó se
        complete el último turno cambiado.
      </Typography>
      <Typography sx={{ fontFamily: 'Poppins-Regular', lineHeight: 1.2, fontSize: '14px' }}>
        Tu turno elegido en cada petición estarán marcados por el color azul, mientras que el de tu
        compañero en blanco.
      </Typography>
      <SubmitButton
        disabled={createPetition}
        handler={() => navigate('/app/petitions/create', { replace: true })}
        text="Solicitar cambio de turno"
      />
      <Divider />
      <SubmitButton handler={() => getPetitionsInfo()} text="Recargar" />
      {petitionInfo.length > 0 ? (
        <Typography sx={{ fontFamily: 'Poppins-Medium', lineHeight: 1.2, fontSize: '14px' }}>
          Lista de peticiones
        </Typography>
      ) : null}
      {petitionInfo.length ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          {petitionInfo.map((petition) => {
            const { _id: ident } = petition;
            return (
              <PetitionBox
                key={ident}
                petition={petition}
                shiftInfo={shiftOptions}
                reload={() => getPetitionsInfo()}
              />
            );
          })}
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: '20px',
          }}
        >
          <Typography sx={{ fontFamily: 'Poppins-Medium', lineHeight: 1.2, fontSize: '16px' }}>
            No existen peticiones creadas
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default ShiftPetitions;
