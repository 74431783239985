import React, { useEffect, useState } from 'react';
import { useOutletContext, useNavigate } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { Box } from '@mui/material';
import moment from 'moment';

import { getLeadStore, getDriverStore, getPlanStore, getAuthStore } from '../../../graphql/store';
import { getFirstTimeDashboard, setFirstTimeDashboard } from '../../../graphql/store/extra';
import EmptyDashboard from './emptyDash';
import LoadingProgress from '../../../components/ui/LoadingProgress';
import RevokedDashboard from './RevokedDashboard';

moment.locale('es');

function emptyDashOptions(exclusive, meeting) {
  switch (true) {
    case meeting === 'Booked':
      return <EmptyDashboard />;
    case !exclusive && meeting !== 'Booked':
      return <EmptyDashboard shared notBooked />;
    default:
      return <EmptyDashboard notBooked />;
  }
}

const WaitingList = () => {
  const [handlePageChange] = useOutletContext();
  const navigate = useNavigate();

  const firstTimeDashboard = getFirstTimeDashboard() || false;

  const { data } = useReactiveVar(getAuthStore);
  const { meeting } = useReactiveVar(getLeadStore);
  const { status } = useReactiveVar(getDriverStore);
  const { plan } = useReactiveVar(getPlanStore);

  const exclusiveShifts = plan ? plan?.exclusive || plan?.shifts.length === 1 : false;
  const conditionFullDashboard = status?.status
    ? !['on boarding', 'rejected'].includes(status?.status)
    : false;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!firstTimeDashboard) {
      const dataLayer = window.dataLayer || [];
      dataLayer.push({
        event: 'Registro Completo GTM',
        userId: data?.uid || '',
        date: new Date(),
      });
      setFirstTimeDashboard(true);
    }
  }, [firstTimeDashboard]);

  useEffect(() => {
    if (conditionFullDashboard) {
      navigate('/app/dashboard', { replace: true });
    } else {
      handlePageChange();
      setLoading(false);
    }
  }, []);

  if (loading) {
    return (
      <Box>
        <LoadingProgress />
      </Box>
    );
  }
  if (status?.status === 'rejected') {
    return <RevokedDashboard />;
  }
  return emptyDashOptions(exclusiveShifts, meeting?.status);
};

export default WaitingList;
