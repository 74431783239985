import React from 'react';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { azulTucar, negroTucar } from '../../../themes/variants/TucarColors';
import PopoverTucar from '../popoverInfo';
import Emoji from '../../extras/emojis';
import { formatterCLP } from '../../../helpers/formatters';

const UberProGuaranteeInfo = ({ guarantee }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '0px 0px 0px 0px',
        gap: '10px',
      }}
    >
      <Box
        sx={{
          borderRadius: '15px',
          boxShadow: '0px 3px 6px #0000001A',
          width: '100%',
          padding: '8px 16px 8px 16px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
          <Typography sx={{ fontFamily: 'Poppins-Medium', textAlign: 'center' }}>
            Garantía a pagar:
          </Typography>
          <PopoverTucar popText="La garantía será devuelta posterior a la devolución del vehículo en 2 partes, siempre y cuando no haya una deuda con Tucar. Una semana después de devolver el vehículo, se  reembolsarán $200.000 de la garantía y 60 días posterior a la devolución, se devolverán los $150.000 restantes" />
        </Box>
        <Box>
          <Typography
            sx={{
              fontFamily: 'Poppins-Medium',
              fontSize: '1rem',
              textAlign: 'center',
              color: azulTucar,
            }}
          >
            {formatterCLP.format(guarantee)}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          borderRadius: '15px',
          lineHeight: 1.2,
          boxShadow: '0px 3px 6px #0000001A',
          width: '100%',
          padding: '8px 16px 8px 16px',
        }}
      >
        <Typography
          sx={{
            display: 'inline',
            lineHeight: 1,
            fontSize: '0.875rem',
            marginRight: '0.3rem',
          }}
          fontFamily="Poppins-Medium"
        >
          ¡Contamos con facilidad de
        </Typography>
        <Typography
          fontFamily="Poppins-Medium"
          sx={{ display: 'inline', fontSize: '0.875rem' }}
          color={azulTucar}
        >
          pago en cuotas!{' '}
        </Typography>
        <Typography
          sx={{
            display: 'inline',
            fontFamily: 'Poppins-Regular',
            fontSize: '0.875rem',
            color: negroTucar,
          }}
        >
          Menciónalo cuando uno de nuestros ejecutivos te contacte
          <Emoji symbol="👩‍💼" label="contact" />
        </Typography>
      </Box>
    </Box>
  );
};

UberProGuaranteeInfo.propTypes = {
  guarantee: PropTypes.number,
};

UberProGuaranteeInfo.defaultProps = {
  guarantee: 0,
};

export default UberProGuaranteeInfo;
