import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, Typography, Divider, LinearProgress } from '@mui/material';
import { FileUpload } from '@mui/icons-material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { azulTucar, rojoTucar, verdeText } from '../themes/variants/TucarColors';

const fontSize = '30px';

const iconStatusDoc = {
  Pending: <AccessTimeIcon sx={{ fontSize, color: azulTucar }} />,
  Approved: <CheckCircleOutlineIcon sx={{ fontSize, color: verdeText }} />,
  Nothing: <ErrorOutlineIcon sx={{ fontSize, color: '#7E7E7E' }} />,
  Errors: <ErrorOutlineIcon sx={{ fontSize, color: '#D6983C' }} />,
  Rejected: <HighlightOffIcon sx={{ fontSize, color: rojoTucar }} />,
};

const translationStatus = {
  Pending: { text: 'En revisión', color: azulTucar },
  Approved: { text: 'Completado', color: verdeText },
  Nothing: { text: 'Pendiente', color: '#7E7E7E' },
  Rejected: { text: 'rechazado', color: rojoTucar },
  Errors: { text: 'Error en los documentos', color: '#D6983C' },
};
const translateStatus = (status) => {
  if (status) {
    const { text, color } = translationStatus[status];
    return (
      <Box>
        <Typography sx={{ fontFamily: 'Poppins-Regular', fontSize: '12px', color }}>
          {text}
        </Typography>
      </Box>
    );
  }
  return null;
};

const UploadFileButton = ({ label, status, url, fileType, fileName, handler, disableDelete }) => {
  const [uploaded, setUploaded] = useState(false);

  useEffect(() => {
    if (status !== 'Nothing') {
      setUploaded(false);
    }
  }, [status]);

  if (uploaded) {
    return (
      <Box>
        <Box
          sx={{
            width: '100%',
            height: '50px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <LinearProgress />
        </Box>
        <Divider />
      </Box>
    );
  }

  const inputConf = (
    <input
      type="file"
      id="icon-button-file"
      accept={fileType}
      hidden
      onChange={(e) => {
        setUploaded(true);
        handler(fileName, e.target.files[0]);
      }}
    />
  );

  return (
    <Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'max-content 1fr max-content',
          paddingY: '7px',
          gap: '5px',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {iconStatusDoc[status]}
        </Box>
        <Box
          sx={{ display: 'flex', flexDirection: 'column', gap: '5px', justifyContent: 'center' }}
        >
          <Typography sx={{ lineHeight: 1 }} fontFamily="Poppins-Medium" fontSize="14px">
            {label}
          </Typography>
          {translateStatus(status)}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <label htmlFor="icon-button-file">
            {status === 'Nothing' && (
              <IconButton color="primary" aria-label="upload picture" component="label">
                {inputConf}
                <FileUpload color="action" />
              </IconButton>
            )}
            {status === 'Pending' && (
              <Box sx={{ display: 'flex' }}>
                <IconButton size="small" type="submit" target="_blank" href={url}>
                  <VisibilityIcon size="small" sx={{ padding: 0 }} />
                </IconButton>
                <IconButton
                  disabled={disableDelete}
                  sx={{ padding: 0 }}
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                >
                  {inputConf}
                  <AutorenewIcon
                    color="action"
                    size="small"
                    sx={{ padding: 0, transform: 'rotate(90deg)' }}
                  />
                </IconButton>
              </Box>
            )}
            {status === 'Errors' && (
              <IconButton
                disabled={disableDelete}
                sx={{ padding: 0 }}
                color="primary"
                aria-label="upload picture"
                component="label"
              >
                {inputConf}
                <AutorenewIcon
                  color="action"
                  size="small"
                  sx={{ padding: 0, transform: 'rotate(90deg)' }}
                />
              </IconButton>
            )}
            {status === 'Approved' && (
              <IconButton size="small" type="submit" target="_blank" href={url}>
                <VisibilityIcon size="small" sx={{ padding: 0 }} />
              </IconButton>
            )}
          </label>
        </Box>
      </Box>
      <Divider />
    </Box>
  );
};

UploadFileButton.propTypes = {
  label: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  handler: PropTypes.func.isRequired,
  fileType: PropTypes.string,
  disableDelete: PropTypes.bool,
  status: PropTypes.string,
  url: PropTypes.string,
};

UploadFileButton.defaultProps = {
  disableDelete: false,
  status: 'Nothing',
  fileType: 'application/pdf, image/*',
  url: '',
};

export default UploadFileButton;
