import React from 'react';
import PropTypes from 'prop-types';
import { Card, Typography } from '@tucar/pit-stop';
import { formatterCLP } from '../../../../../helpers/formatters';

const TotalCard = ({ total }) => {
  const totalPositive = total > 0;

  return (
    <Card
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '16px 20px',
        width: '100%',
      }}
    >
      <Typography
        style={{
          color: totalPositive ? '#0496FF' : '#FF7B66',
          fontWeight: 600,
          fontSize: '20px',
          lineHeight: 1.2,
        }}
      >
        {totalPositive ? 'Saldo a favor' : 'Total a pagar'}
      </Typography>
      <Typography
        style={{
          color: totalPositive ? '#0496FF' : '#FF7B66',
          fontWeight: 600,
          fontSize: '20px',
          lineHeight: 1.2,
        }}
      >
        {formatterCLP.format(total)}
      </Typography>
    </Card>
  );
};

TotalCard.propTypes = {
  total: PropTypes.number,
};

TotalCard.defaultProps = {
  total: 0,
};

export default TotalCard;
