import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, TextField, InputAdornment, IconButton } from '@mui/material';

import { EditOutlined } from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { useReactiveVar } from '@apollo/client';

import {
  getAuthStore,
  getDriverStore,
  getGlobalStore,
  setLoading,
} from '../../../../../graphql/store';
import country from '../../../../../helpers/bd/chile';
import MultiSelectEditable from '../../../../../components/ui/MultiSelectEditable';
import SubmitButton from '../../../../../components/SubmitButton';
import EditPhone from '../../../../../components/ui/EditPhone';

export default function PersonalForm({ executeMutation }) {
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);
  const { info } = useReactiveVar(getDriverStore);
  const { loading } = useReactiveVar(getGlobalStore);
  const [loadingButton, setLoadingButton] = useState(false);
  const [values, setValues] = useState({
    ...info,
    uid,
  });
  const [edit, setEdit] = useState(false);
  const isEmptyObject = (obj) => {
    const aux = { ...obj };
    if (Object.keys(aux).includes('adrapt')) {
      delete aux.adrapt;
      delete aux.emergencyPhone;
      delete aux.secondPhone;
    }
    return Object.values(aux).every((value) => value !== '');
  };

  const regions = country;
  const provincias = !values.region
    ? []
    : country.filter((region) => region.name === values.region)[0].provincias;
  const communes = !values.city
    ? []
    : provincias.filter((citys) => citys.name === values.city)[0].comunas;

  useEffect(() => {
    if (uid) {
      setValues({ ...values, source: 'Not-Edit', uid });
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [uid]);

  const handleChange = (name, event) => {
    if (name === 'region') {
      setValues((currentValues) => ({
        ...currentValues,
        city: '',
        commune: '',
        [name]: event,
      }));
    } else if (name === 'city') {
      setValues((currentValues) => ({
        ...currentValues,
        commune: '',
        [name]: event,
      }));
    } else {
      setValues((currentValues) => ({
        ...currentValues,
        [name]: event,
      }));
    }
  };

  const handleClick = () => {
    setEdit(false);
    setLoadingButton(false);
    executeMutation(values);
  };

  const translateParking = (value) => {
    switch (value) {
      case '0':
        return 'No';
      case '1':
        return 'Si';
      default:
        return '';
    }
  };

  const translateGender = (value) => {
    switch (value) {
      case 'female':
        return 'Femenino';
      case 'male':
        return 'Masculino';
      default:
        return 'Prefiero no decir';
    }
  };

  return (
    <Box fullwidth display="flex" justifyContent="center">
      <Box sx={{ width: '450px', paddingInline: '40px', maxWidth: '100vw' }}>
        {loading ? null : (
          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '15px' }}>
            <TextField
              fullWidth
              disabled={edit}
              size="small"
              type="text"
              sx={{ margin: '0px' }}
              variant="outlined"
              label="RUT"
              value={values.rut}
              name="rut"
              inputProps={{ readOnly: true, style: { fontFamily: 'Poppins-Regular' } }}
              InputLabelProps={{ style: { fontFamily: 'Poppins-Regular' } }}
            />

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDatePicker
                disabled={edit}
                readOnly
                fullWidth
                label="Fecha de nacimiento"
                value={values.birth}
                name="birth"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    variant="outlined"
                    fullWidth
                    sx={{
                      marginTop: '0px',
                      marginBottom: '0',
                      input: { fontFamily: 'Poppins-Regular' },
                      label: { fontFamily: 'Poppins-Regular' },
                    }}
                  />
                )}
                onChange={() => {}}
              />
            </LocalizationProvider>

            <EditPhone
              value={values.phone}
              edit={edit}
              handleChange={handleChange}
              label="Teléfono"
              name="phone"
            />

            <EditPhone
              value={values.secondPhone}
              edit={edit}
              handleChange={handleChange}
              label="Teléfono secundario"
              name="secondPhone"
            />

            <EditPhone
              value={values.emergencyPhone}
              edit={edit}
              handleChange={handleChange}
              label="Teléfono de emergencia"
              name="emergencyPhone"
            />

            <MultiSelectEditable
              size="small"
              handleChange={handleChange}
              label="Género"
              name="gender"
              value={edit ? values.gender : translateGender(values.gender)}
              dataArr={[
                { name: 'Femenino', value: 'female' },
                { name: 'Masculino', value: 'male' },
                { name: 'Prefiero no decir', value: 'other' },
              ]}
              edit={edit}
              containerStyle={{ margin: '0px' }}
            />

            <MultiSelectEditable
              size="small"
              handleChange={handleChange}
              label="Estacionamiento privado (no calle)"
              name="parking"
              value={edit ? values.parking : translateParking(values.parking)}
              dataArr={[
                { name: 'Sí', value: '1' },
                { name: 'No', value: '0' },
              ]}
              edit={edit}
              containerStyle={{ margin: '0px' }}
            />

            <MultiSelectEditable
              size="small"
              handleChange={handleChange}
              label="Región"
              name="region"
              value={values.region}
              edit={edit}
              dataArr={regions}
              containerStyle={{ paddingBottom: '0px' }}
            />

            <MultiSelectEditable
              size="small"
              handleChange={handleChange}
              label="Provincia"
              name="city"
              value={values.city}
              edit={edit}
              dataArr={provincias}
              containerStyle={{ paddingBottom: '0px' }}
            />

            <MultiSelectEditable
              size="small"
              handleChange={handleChange}
              label="Comuna"
              name="commune"
              value={values.commune}
              edit={edit}
              dataArr={communes}
              containerStyle={{ paddingBottom: '0px' }}
            />

            <TextField
              fullWidth
              size="small"
              type="text"
              sx={{ marginTop: '0px', marginBottom: '0' }}
              variant="outlined"
              id="Direccion"
              label="Dirección"
              value={values.address}
              name="address"
              onChange={(e) => handleChange('address', e.target.value)}
              InputLabelProps={{ style: { fontFamily: 'Poppins-Regular' } }}
              InputProps={{
                style: { fontFamily: 'Poppins-Regular' },
                readOnly: !edit,
                endAdornment: edit ? (
                  <InputAdornment position="end">
                    <IconButton>
                      <EditOutlined />
                    </IconButton>
                  </InputAdornment>
                ) : null,
              }}
            />

            <TextField
              fullWidth
              size="small"
              type="text"
              sx={{ marginTop: '0px', marginBottom: '0' }}
              variant="outlined"
              label="Número"
              value={values.adrnumber}
              name="adrnumber"
              onChange={(e) => handleChange('adrnumber', e.target.value)}
              InputLabelProps={{ style: { fontFamily: 'Poppins-Regular' } }}
              InputProps={{
                style: { fontFamily: 'Poppins-Regular' },
                readOnly: !edit,
                endAdornment: edit ? (
                  <InputAdornment position="end">
                    <IconButton>
                      <EditOutlined />
                    </IconButton>
                  </InputAdornment>
                ) : null,
              }}
            />

            <TextField
              fullWidth
              size="small"
              disabled={!edit}
              type="text"
              sx={{ marginTop: '0px', marginBottom: '0' }}
              variant="outlined"
              id="Casa/depto"
              label="Casa/depto"
              value={values.adrapt}
              name="adrapt"
              onChange={(e) => handleChange('adrapt', e.target.value)}
              InputLabelProps={{ style: { fontFamily: 'Poppins-Regular' } }}
              InputProps={{
                style: { fontFamily: 'Poppins-Regular' },
                readOnly: !edit,
                endAdornment: edit ? (
                  <InputAdornment position="end">
                    <IconButton>
                      <EditOutlined />
                    </IconButton>
                  </InputAdornment>
                ) : null,
              }}
            />

            {edit ? (
              <Box sx={{ marginBottom: '40px' }} display="flex" justifyContent="space-around">
                <SubmitButton
                  text="Cancelar"
                  backgroundColor="#F3F3F3"
                  color="#333333"
                  hoverColor="#333333"
                  handler={() => {
                    const aux = {
                      ...info,
                      uid,
                    };
                    setValues(aux);
                    setEdit(false);
                  }}
                />

                <Box marginX="10px" />
                <SubmitButton
                  disabled={loadingButton || !isEmptyObject(values)}
                  text="Guardar"
                  handler={handleClick}
                />
              </Box>
            ) : (
              <Box sx={{ marginBottom: '40px' }}>
                <SubmitButton text="Editar información" handler={() => setEdit(true)} />
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}

PersonalForm.propTypes = {
  executeMutation: PropTypes.func.isRequired,
};
