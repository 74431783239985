import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import { Box, Button, TextField } from '@mui/material';

import MultiSelectEditable from '../MultiSelectEditable';
import { rutValidator, formatRut } from '../../../helpers/rutValidador';
import { validateEmail } from '../../../helpers/auxFunctions';
import { azulTucar } from '../../../themes/variants/TucarColors';
import banks from '../../../pages/app/profile/OldVersion/Accounting/banks';

const OldBankAccountForm = ({ values, setValues, handleSend }) => {
  const [emailError, setEmailError] = useState(false);

  const handleChange = (name, event) => {
    setValues((currentValues) => ({
      ...currentValues,
      [name]: event,
    }));
  };

  const isNotEmptyObject = (obj) => {
    return Object.values(obj).every((value) => value !== '');
  };

  const checkSend = () => {
    if (validateEmail(values.email)) {
      setEmailError(false);
      handleSend();
    } else {
      setEmailError(true);
    }
  };

  function avaliableSubmit() {
    if (isNotEmptyObject(values) && rutValidator(values.rut) && !emailError) {
      return true;
    }
    return false;
  }

  const types = !values.bank ? [] : banks.filter((bank) => bank.name === values.bank)[0].types;

  return (
    <Box>
      <Box margin="10px 20px 0px 20px" display="flex" flexDirection="column" gap="15px">
        <MultiSelectEditable
          size="small"
          handleChange={handleChange}
          name="bank"
          label="Banco"
          value={values.bank}
          dataArr={banks}
          required={false}
          containerStyle={{
            textAlign: 'left',
            textOverflow: 'ellipsis',
          }}
        />
        <MultiSelectEditable
          disabled={!values.bank}
          size="small"
          handleChange={handleChange}
          name="accountType"
          label="Tipo de cuenta"
          value={values.accountType}
          dataArr={types}
          required={false}
          containerStyle={{ textAlign: 'left', textOverflow: 'ellipsis' }}
        />

        <InputMask
          mask="9999 9999 9999 9999"
          maskChar={null}
          value={values.account}
          onChange={(e) => {
            if (e.target.validity.valid || e.target.value === '')
              handleChange('account', e.target.value);
          }}
        >
          {() => (
            <TextField
              size="small"
              fullWidth
              type="text"
              sx={{ margin: '0px' }}
              variant="outlined"
              label="Número de cuenta"
              inputProps={{ style: { fontFamily: 'Poppins-Regular' } }}
              InputLabelProps={{ style: { fontFamily: 'Poppins-Regular' } }}
            />
          )}
        </InputMask>

        <TextField
          size="small"
          fullWidth
          type="text"
          sx={{ margin: '0px' }}
          variant="outlined"
          label="Nombre del titular"
          value={values.name}
          onChange={(e) => handleChange('name', e.target.value)}
          inputProps={{ style: { fontFamily: 'Poppins-Regular' } }}
          InputLabelProps={{ style: { fontFamily: 'Poppins-Regular' } }}
        />
        <TextField
          size="small"
          fullWidth
          type="text"
          sx={{ margin: '0px' }}
          variant="outlined"
          label="Rut del titular"
          value={values.rut}
          error={values.rut ? !rutValidator(values.rut) : false}
          helperText={values.rut && !rutValidator(values.rut) ? 'Rut inválido' : ''}
          onChange={(e) => handleChange('rut', formatRut(e.target.value))}
          inputProps={{ style: { fontFamily: 'Poppins-Regular' } }}
          InputLabelProps={{ style: { fontFamily: 'Poppins-Regular' } }}
        />
        <TextField
          size="small"
          fullWidth
          type="text"
          sx={{ margin: '0px' }}
          variant="outlined"
          label="Correo electrónico"
          value={values.email}
          error={emailError}
          helperText={emailError ? 'email inválido' : ''}
          onChange={(e) => handleChange('email', e.target.value)}
          inputProps={{ style: { fontFamily: 'Poppins-Regular' } }}
          InputLabelProps={{ style: { fontFamily: 'Poppins-Regular' } }}
        />
      </Box>
      <Box margin="10px 20px 0px 20px">
        <Button
          fullWidth
          type="submit"
          disabled={!avaliableSubmit()}
          sx={{
            backgroundColor: azulTucar,
            marginTop: '10px',
            marginBottom: '20px',
            height: '40px',
            lineHeight: 1,
            boxShadow: '0',
          }}
          variant="contained"
          onClick={checkSend}
        >
          Guardar Cuenta
        </Button>
      </Box>
    </Box>
  );
};

OldBankAccountForm.propTypes = {
  setValues: PropTypes.func.isRequired,
  handleSend: PropTypes.func.isRequired,
  values: PropTypes.shape({
    bank: PropTypes.string,
    accountType: PropTypes.string,
    account: PropTypes.string,
    name: PropTypes.string,
    rut: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
};

export default OldBankAccountForm;
