import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Typography } from '@mui/material';

const IconInfoBox = ({ icon, title }) => {
  return (
    <IconButton
      disabled
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: '#FFF',
        borderRadius: '15px',
        '&:disabled': {
          backgroundColor: '#FFF',
          color: '#808080',
        },
      }}
    >
      {icon}
      <Typography sx={{ fontSize: '12px', fontFamily: 'Poppins-Light' }} noWrap>
        {title}
      </Typography>
    </IconButton>
  );
};

IconInfoBox.propTypes = {
  icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
};

export default IconInfoBox;
