import { Loader } from '@googlemaps/js-api-loader';

let googleApiClient;

const getGoogleMapsApiClient = async () => {
  if (googleApiClient) return googleApiClient;
  const loader = new Loader({
    apiKey: process.env.GOOGLE_MAPS_API_KEY,
    version: 'weekly',
    libraries: ['places'],
  });
  googleApiClient = await loader.load();
  return googleApiClient;
};

export default getGoogleMapsApiClient;
