import React from 'react';
import PropTypes from 'prop-types';

import { Typography, Box } from '@mui/material';
import EvStationOutlinedIcon from '@mui/icons-material/EvStationOutlined';
import LocalGasStationOutlinedIcon from '@mui/icons-material/LocalGasStationOutlined';

import IconTitleCard from '../../../../components/info/IconTitleCard';
import ContentPlanCard from './contentPlanCard';
import PopoverTucar from '../../../../components/info/popoverInfo';
import { formatterCLP } from '../../../../helpers/formatters';

function selectPlanMode(mode, name, disponibilityHours, onBoarding, disableElement) {
  const getPlanIconBubble = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography sx={{ fontFamily: 'Poppins-SemiBold', fontSize: '20px', lineHeight: '1' }}>
          {disponibilityHours}
        </Typography>
        <Typography sx={{ lineHeight: '1' }}>hrs</Typography>
      </Box>
    );
  };

  const auxNamePlan = name.split(' ');
  const namePlan = auxNamePlan.slice(0, -1).join(' ');
  const categoryPlan = auxNamePlan.slice(-1).join(' ');

  switch (mode) {
    case 'electric-exclusive':
      return {
        title: {
          primary: namePlan,
          secondary: categoryPlan,
          secondaryIcon: <EvStationOutlinedIcon sx={{ fontSize: '3rem' }} />,
        },
        iconBubble: onBoarding ? getPlanIconBubble() : EvStationOutlinedIcon,
        iconBubbleColor: disableElement ? '#ccc' : '#6CC8DD',
      };
    case 'combustion-exclusive':
      return {
        title: {
          primary: namePlan,
          secondary: categoryPlan,
          secondaryIcon: <LocalGasStationOutlinedIcon sx={{ fontSize: '3rem' }} />,
        },
        iconBubble: onBoarding ? getPlanIconBubble() : LocalGasStationOutlinedIcon,
        iconBubbleColor: disableElement ? '#ccc' : '#0057B8',
      };
    default:
      return {
        title: {
          primary: namePlan,
          secondary: categoryPlan,
          secondaryIcon: <EvStationOutlinedIcon sx={{ fontSize: '3rem' }} />,
        },
        iconBubble: onBoarding ? getPlanIconBubble() : EvStationOutlinedIcon,
        iconBubbleColor: disableElement ? '#ccc' : '#95D31C',
      };
  }
}

function tableContentKmsCreator(sections, currency, discount) {
  const auxTable = sections.map((elem, index) => {
    return (
      <Box
        key={`${elem.startKm}-${elem.endKm}`}
        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Typography sx={{ fontFamily: 'Poppins-Medium', fontSize: '12px' }}>
          {index === sections.length - 1 ? 'Más de' : 'Entre'} {elem.startKm}
          {index === sections.length - 1 ? ' km' : ` y ${elem.endKm} km`}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ fontFamily: 'Poppins-Medium', fontSize: '12px' }}>
            {formatterCLP.format(currency * elem.variable * (1 - discount / 100))}/km
          </Typography>
          <PopoverTucar
            onBoarding
            popText={`(${elem.variable * (1 - discount / 100)} UF/km adicional)`}
          />
        </Box>
      </Box>
    );
  });

  return auxTable.filter((elem) => elem);
}

const PlanItemInfo = ({
  onBoarding,
  planId,
  shiftId,
  name,
  mode,
  currency,
  disponibilityHours,
  basePrice,
  handleButton,
  sections,
  specialTitle,
  planIncludes,
  planExcludes,
  discount,
  disableElement,
}) => {
  const config = selectPlanMode(mode, name, disponibilityHours, onBoarding, disableElement);
  const tableContent = tableContentKmsCreator(sections, currency, discount);
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box sx={{ width: '100%' }}>
        <Box sx={{ position: 'relative' }}>
          {!onBoarding && (
            <Box
              sx={{ width: '100%', paddingInline: '0px', display: 'flex', justifyContent: 'right' }}
            >
              <Box
                sx={{
                  visibility: discount ? 'visible' : 'hidden',
                  backgroundColor: config.iconBubbleColor,
                  width: '50%',
                  padding: '5px 0px 5px 0px',
                  transform: 'translate(20px,45px)',
                  borderRadius: '0px 20px 0px 0px',
                }}
              >
                <Typography
                  sx={{
                    textAlign: 'center',
                    fontFamily: 'Poppins-Regular',
                    fontSize: '12px',
                    color: '#fff',
                    verticalAlign: 'center',
                  }}
                >
                  {discount}% de dcto
                </Typography>
              </Box>
            </Box>
          )}
          <IconTitleCard
            IconBubble={config.iconBubble}
            IconBubbleStyle={{
              container: {
                transform: onBoarding
                  ? 'translate(-50%, -110%) !important'
                  : 'translate(-50%, 0%) ',
                backgroundColor: config.iconBubbleColor,
              },
              icon: { color: '#FFF' },
            }}
            complexIconBubble={onBoarding}
            containerStyle={{
              padding: '20px 0px 0px 0px',
              minHeight: '130px',
              borderRadius: '15px',
              backgroundColor: 'transparent',
            }}
            title={
              <Box
                sx={{
                  ...(onBoarding
                    ? {
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }
                    : {}),
                }}
              >
                <Box sx={{ padding: onBoarding ? '10px 1ﬁ0px 0px 20px' : '50px 20px 0px 20px' }}>
                  <Typography sx={{ textAlign: 'center', fontFamily: 'Poppins-Regular' }}>
                    {!specialTitle.primary ? config.title.primary : specialTitle.primary}
                  </Typography>
                  <Typography variant="h2" sx={{ color: config.iconBubbleColor }}>
                    {!specialTitle.secondary ? config.title.secondary : specialTitle.secondary}
                  </Typography>
                </Box>
                {onBoarding && (
                  <Box sx={{ color: config.iconBubbleColor }}>
                    {!specialTitle.secondaryIcon
                      ? config.title.secondaryIcon
                      : specialTitle.secondaryIcon}
                  </Box>
                )}
              </Box>
            }
            contentText={
              <ContentPlanCard
                onBoarding={onBoarding}
                discount={discount}
                planIncludes={planIncludes}
                planExcludes={planExcludes}
                showButton={onBoarding}
                buttonDisabled={disableElement}
                planId={planId}
                shiftType={shiftId || mode}
                fontColor={config.iconBubbleColor}
                basePrice={basePrice * (1 - discount / 100)}
                currency={currency}
                handleButton={handleButton}
                tableContent={tableContent}
                buttonColor={config.iconBubbleColor}
              />
            }
          />
        </Box>
      </Box>
    </Box>
  );
};

export default PlanItemInfo;

PlanItemInfo.propTypes = {
  mode: PropTypes.string,
  name: PropTypes.string,
  currency: PropTypes.number,
  disponibilityHours: PropTypes.number,
  basePrice: PropTypes.number,
  handleButton: PropTypes.func,
  sections: PropTypes.arrayOf(PropTypes.shape({})),
  planId: PropTypes.string,
  shiftId: PropTypes.string,
  onBoarding: PropTypes.bool,
  specialTitle: PropTypes.shape({
    primary: PropTypes.string,
    secondary: PropTypes.string,
    secondaryIcon: PropTypes.node,
  }),
  planIncludes: PropTypes.arrayOf(PropTypes.string),
  planExcludes: PropTypes.arrayOf(PropTypes.string),
  discount: PropTypes.number,
  disableElement: PropTypes.bool,
};

PlanItemInfo.defaultProps = {
  specialTitle: { primary: '', secondary: '' },
  onBoarding: true,
  name: '',
  shiftId: '',
  planId: '',
  mode: '',
  currency: 0,
  disponibilityHours: 0,
  basePrice: 0,
  handleButton: () => {},
  sections: [],
  planIncludes: [],
  planExcludes: [],
  discount: 0,
  disableElement: false,
};
