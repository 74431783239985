import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import SubmitButton from '../../SubmitButton';

const WaitNextProcess = ({ text, handlerButton, hideButton }) => {
  return (
    <Box sx={{ paddingInline: '20px' }}>
      <Typography
        sx={{
          fontFamily: 'Poppins-Regular',
          fontSize: '16px',
          textAlign: 'center',
          marginBottom: '20px',
        }}
      >
        {text}
      </Typography>
      {hideButton ? null : <SubmitButton handler={handlerButton} text="Continuar" />}{' '}
    </Box>
  );
};

WaitNextProcess.propTypes = {
  text: PropTypes.string,
  handlerButton: PropTypes.func,
  hideButton: PropTypes.bool,
};

WaitNextProcess.defaultProps = {
  text: '',
  handlerButton: () => {},
  hideButton: false,
};

export default WaitNextProcess;
