import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import NavigationBar from '../app';
import { getAuthStore } from '../../graphql/store';

const GeneralLayuout = ({ onBoarding }) => {
  const [topBar, setTopBar] = useState(null);
  const [navBar, setNavBar] = useState(false);
  const [modeApp, setModeApp] = useState('');
  const match = useMediaQuery('(max-width:1300px)');
  const { data } = useReactiveVar(getAuthStore);

  const handlePageChange = (newTopBar = null, newNavBar = false, newModeApp = '') => {
    setTopBar(newTopBar);
    setNavBar(newNavBar);
    setModeApp(newModeApp);
  };

  return (
    <Box sx={onBoarding ? {} : { height: '100vh' }}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: match ? '1fr' : 'repeat(5,1fr) !important',
          gridTemplateRows: onBoarding
            ? 'fit-content(1fr)'
            : `${topBar ? '60px' : '0px'} 1fr ${modeApp === 'tutorial' ? '0px' : '60px'}`,
          width: '100%',
          height: '100%',
          backgroundColor: topBar ? '#FFF' : '#F0F0F3',
        }}
      >
        <Box
          sx={{
            gridRow: 1,
            gridColumnStart: match ? 1 : 2,
            gridColumnEnd: match ? 'auto' : 5,
          }}
        >
          {!onBoarding ? topBar : null}
        </Box>

        <Box
          sx={{
            gridRow: 2,
            gridColumnStart: match ? 1 : 2,
            gridColumnEnd: match ? 'auto' : 5,
            overflowY: modeApp === 'tutorial' ? 'hidden' : 'auto',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          <Outlet context={[handlePageChange]} />
        </Box>

        <Box sx={{ gridColumnStart: match ? 1 : 2, gridColumnEnd: match ? 'auto' : 5 }}>
          {navBar || (modeApp !== 'tutorial' && data?.id) ? <NavigationBar newVersion /> : null}
        </Box>
      </Box>
    </Box>
  );
};

export default GeneralLayuout;

GeneralLayuout.propTypes = {
  onBoarding: PropTypes.bool,
};

GeneralLayuout.defaultProps = {
  onBoarding: false,
};
