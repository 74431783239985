import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import { formatterCLP } from '../../../helpers/formatters';
import CardButtonDash from '../CardButtonDash';

const PayButton = ({ styleProps, amount, title, tutorial, contentDirection }) => {
  const navigate = useNavigate();

  const textContainer = (
    <Typography
      variant="span"
      sx={{
        display: 'inline-block',
        fontFamily: 'Poppins-Regular',
        lineHeight: 1.2,
        fontSize: '0.875rem',
      }}
    >
      Tienes pendiente{' '}
      <Typography
        sx={{
          display: 'inline-block',
          fontFamily: 'Poppins-SemiBold',
          lineHeight: 1.2,
          fontSize: '0.875rem',
        }}
      >
        {formatterCLP.format(amount)}
      </Typography>{' '}
      de{' '}
      <Typography
        sx={{
          display: 'inline',
          fontFamily: 'Poppins-SemiBold',
          lineHeight: 1.2,
          fontSize: '0.875rem',
        }}
      >
        {title}
      </Typography>
    </Typography>
  );

  return (
    <CardButtonDash
      tutorial={tutorial}
      colors={{ primary: '#E1A020', secondary: '#FCF5E8' }}
      sendText="Pagar"
      content={textContainer}
      styleProps={{ container: styleProps.payment }}
      handleGo={() => navigate('/app/payment', { replace: true })}
      contentDirection={contentDirection}
    />
  );
};

PayButton.propTypes = {
  contentDirection: PropTypes.string,
  amount: PropTypes.number,
  title: PropTypes.string,
  tutorial: PropTypes.bool,
  styleProps: PropTypes.shape({
    payment: PropTypes.shape({}),
  }),
};

PayButton.defaultProps = {
  contentDirection: 'row',
  amount: 0,
  title: '',
  tutorial: false,
  styleProps: { payment: {} },
};

export default PayButton;
