import { gql } from '@apollo/client';
import { setRent } from '../../store/driver';

const GET_RENT = {
  query: gql`
    query GetDriver($uid: ID!) {
      getDriver(uid: $uid) {
        rent {
          carId
        }
      }
    }
  `,
  policies: {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: 'true',
    onCompleted: (response) => {
      if (response.getDriver) {
        const { rent } = response.getDriver;
        setRent(rent);
      }
    },
    context: {
      ignorePattern: {
        pattern: ['Error fetching driver rent'],
      },
    },
  },
};

export default GET_RENT;
