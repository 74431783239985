import { gql } from '@apollo/client';

const GET_TERMS_AND_CONDITIONS = {
  query: gql`
    query termAndConditions {
      terminoYCondicione(first: 100) {
        id
        title
        subtitle {
          raw
        }
      }
    }
  `,
};

export default GET_TERMS_AND_CONDITIONS;
