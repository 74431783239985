import React from 'react';
import { Box } from '@mui/material';
import { Button, Typography } from '@tucar/pit-stop';
import { useReactiveVar } from '@apollo/client';
import Emoji from '../../../../../components/extras/emojis';
import lockImg from '../../../../../../public/images/background/lock3d.png';
import { getDriverStore } from '../../../../../graphql/store';

const RejectedScreen = () => {
  const { status } = useReactiveVar(getDriverStore);
  if (status.status !== 'rejected') return null;

  return (
    <Box
      sx={{
        position: 'absolute',
        zIndex: 3,
        top: 0,
        left: 0,
        height: '100vh',
        width: '100vw',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '30px',
        backdropFilter: 'blur(2px)',
        background:
          'linear-gradient(180deg, rgba(234, 255, 254, 0.90) 0%, rgba(205, 201, 241, 0.90) 100%)',
      }}
    >
      <Box
        sx={{
          paddingInline: '30px',
          alignSelf: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Typography
          align="center"
          color="#1F89FF"
          style={{ lineHeight: 1.2, fontSize: '28px', fontWeight: 600 }}
        >
          Te encuentras bloqueado <Emoji symbol="😢" label="emoji" />
        </Typography>
        <img
          src={lockImg}
          alt="block-img"
          height="30%"
          width="fit-content"
          style={{ alignSelf: 'center' }}
        />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            paddingInline: '40px',
            alignItems: 'center',
          }}
        >
          <Typography
            align="center"
            color="#5B5D71"
            style={{ lineHeight: 1.2, fontSize: '16px', fontWeight: 500 }}
          >
            Comunícate con nosotros para entregarte más información.
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          bottom: '100px',
          position: 'relative',
          zIndex: 2,
          alignSelf: 'center',
          '::before': {
            content: '""',
            position: 'absolute',
            top: '-100%',
            left: '50%',
            transform: 'translate(-50%, -50px)',
            height: '544px',
            width: '544px',
            borderRadius: '544px',
            border: '2px solid #FFF',
            background: 'rgba(255, 255, 255, 0.30)',
            backdropFilter: 'blur(2px)',
            zIndex: 0,
          },
        }}
      >
        <Button onClick={() => window.Intercom('show')}> Enviar mensaje</Button>
      </Box>
    </Box>
  );
};

export default RejectedScreen;
