import { gql } from '@apollo/client';
import { setAuth } from '../../store';

const SIGN_IN_UBER = {
  query: gql`
    query Query($code: String!, $redirectUrl: String!) {
      signInUber(code: $code, redirectUrl: $redirectUrl) {
        accessToken
        refreshToken
        data {
          uid
          email
          firstname
          lastname
          userType
          verified
        }
      }
    }
  `,
  policies: {
    onCompleted: (response) => {
      if (response.signInUber) {
        const { accessToken, refreshToken, data } = response.signInUber;
        setAuth({ accessToken, refreshToken, data });
      }
    },
    errorPolicy: 'all',
  },
};

export default SIGN_IN_UBER;
