import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { TableCell, TableRow, Collapse } from '@mui/material';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';

import { formatterCLP } from '../../../../../helpers/formatters';

import stylesTable from '../../style';

const IncomeEventsTable = ({ billDays, incomeEvents, handleModalContent }) => {
  const [open, setOpen] = useState(true);

  return (
    <>
      <TableRow onClick={() => setOpen(!open)}>
        <TableCell
          sx={{
            ...stylesTable.headerColumn,
            ...stylesTable.stickyHeader,
            borderBottom: '0px solid transparent',
          }}
        >
          Abono por eventos
          <ChevronRightRoundedIcon
            sx={{
              ...stylesTable.iconArrow,
              right: '5px',
              transform: open ? 'translateY(-50%) rotate(90deg)' : 'translateY(-50%) rotate(0deg)',
              transition: 'transform 0.3s ease',
            }}
          />
        </TableCell>
        {billDays.map((_, index) => {
          const key = `Abono-${index}`;
          return <TableCell key={key} />;
        })}
        <TableCell sx={{ ...stylesTable.positiveText, ...stylesTable.totalCell }} />
      </TableRow>

      <TableRow onClick={() => handleModalContent('incomeEvents')}>
        <TableCell
          sx={{
            ...stylesTable.stickyHeader,
            ...stylesTable.headerText,
            borderBottom: '0px solid transparent',
          }}
        >
          <Collapse sx={stylesTable.collapseTitle} in={open} timeout="auto" unmountOnExit>
            Eventos
            <ChevronRightRoundedIcon
              sx={{
                ...stylesTable.iconArrow,
              }}
            />
          </Collapse>
        </TableCell>
        {billDays.map((billday, index) => {
          const key = `Abono-${index}`;
          const { events } = billday;
          const totalDay = incomeEvents.reduce((acc, event) => {
            if (events.includes(event)) {
              return acc + event.amount;
            }
            return acc;
          }, 0);

          return (
            <TableCell key={key} align="center" sx={stylesTable.regularText}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                {formatterCLP.format(totalDay)}
              </Collapse>
            </TableCell>
          );
        })}
        <TableCell align="center" sx={{ ...stylesTable.regularText, ...stylesTable.totalCell }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {formatterCLP.format(incomeEvents.reduce((acc, curr) => curr.amount + acc, 0))}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

IncomeEventsTable.propTypes = {
  billDays: PropTypes.arrayOf(PropTypes.shape({})),
  incomeEvents: PropTypes.arrayOf(PropTypes.shape({})),
  handleModalContent: PropTypes.func,
};

IncomeEventsTable.defaultProps = {
  billDays: [],
  incomeEvents: [],
  handleModalContent: () => {},
};

export default IncomeEventsTable;
