import React from 'react';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import leftCharging from '../../../../../../public/images/shared-form/left-charging.svg';
import { verdeIcon } from '../../../../../themes/variants/TucarColors';

const LeftCharging = ({ data, optional }) => {
  return (
    <Box
      sx={{
        height: '100%',
        minHeight: '100%',
        width: '100%',
        display: 'grid',
        gridTemplateRows: 'max-content 1fr',
      }}
    >
      <Box sx={{ height: 'max-content' }}>
        <Typography
          sx={{
            fontSize: '1rem',
            textAlign: 'left',
            fontFamily: 'Poppins-Medium',
            color: verdeIcon,
          }}
        >
          {`Deja el vehículo ${optional ? '(Opcional)' : ''}`}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
          <Box sx={{ display: 'inline', lineHeight: 1.2, textAlign: 'left' }}>
            <Typography
              variant="span"
              fontFamily="Poppins-Regular"
              sx={{ fontSize: '12px', display: 'inline', lineHeight: 1.2 }}
            >
              Debes dirigirte a{' '}
              <Typography
                fontFamily="Poppins-Medium"
                sx={{ display: 'inline', lineHeight: 1.2, fontSize: '12px' }}
              >
                {data?.map?.address}
              </Typography>
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          height: '100%',
          minHeight: '100%',
          borderRadius: '55px',
          backgroundImage: `url(${leftCharging})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      />
    </Box>
  );
};

LeftCharging.propTypes = {
  optional: PropTypes.bool,
  data: PropTypes.shape({
    map: PropTypes.shape({
      address: PropTypes.string,
    }),
  }),
};

LeftCharging.defaultProps = {
  optional: false,
  data: { map: { address: '-' } },
};

export default LeftCharging;
