import React, { useEffect, useState } from 'react';
import { Card } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useReactiveVar, useQuery } from '@apollo/client';

import { getAuthStore } from '../../../graphql/store';
import GET_ADVICE_COUPLES from '../../../graphql/querys/exchange/getAdviceAssigned';
import SubmitButton from '../../../components/SubmitButton';
import { azulTucar } from '../../../themes/variants/TucarColors';

const PetitionButton = () => {
  const navigate = useNavigate();
  const [showView, setShowView] = useState(false);

  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);

  const { data: assignationInfo, loading: loadingAssignationInfo } = useQuery(
    GET_ADVICE_COUPLES.query,
    {
      ...GET_ADVICE_COUPLES.policies,
      variables: {
        driverId: uid,
        labelId: 'SHIFT_CHANGE_PETITION',
        $skipGetLabel: true,
      },
    },
  );

  useEffect(() => {
    if (assignationInfo?.getCouples?.instances?.length) {
      if (assignationInfo.getCouples.instances[0]?.drivers.length >= 2) {
        setShowView(true);
      }
    }
  }, [assignationInfo]);

  if (loadingAssignationInfo || !showView) {
    return null;
  }

  return (
    <Card
      onClick={() => {
        navigate('/app/v2/petitions');
      }}
      sx={{
        background: `linear-gradient(to right, ${azulTucar}, #006FEB)`,
        borderRadius: '15rem',
      }}
      elevation={3}
    >
      <SubmitButton text="Solicitar cambio de turno" backgroundColor="transparent" />
    </Card>
  );
};

export default PetitionButton;
