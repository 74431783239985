import { Security } from '@mui/icons-material';
import Estado from '../../public/images/banks/isotipos/BancoEstado.svg';
import Santander from '../../public/images/banks/isotipos/Santander.svg';
import BCI from '../../public/images/banks/isotipos/Bci.svg';
import Consorcio from '../../public/images/banks/isotipos/Consorcio.svg';
import Falabella from '../../public/images/banks/isotipos/Falabella.svg';
import Itau from '../../public/images/banks/isotipos/Itau.svg';
import Scotiabank from '../../public/images/banks/isotipos/Scotiabank.svg';
import BICE from '../../public/images/banks/isotipos/BICE.svg';
import Chile from '../../public/images/banks/isotipos/Chile.svg';
import Coopeuch from '../../public/images/banks/isotipos/Coopeuch.svg';
import Paris from '../../public/images/banks/isotipos/Paris.svg';
import HSBC from '../../public/images/banks/isotipos/HSBC.svg';
import Internacional from '../../public/images/banks/isotipos/Internacional.svg';
import Corpbanca from '../../public/images/banks/isotipos/Corpbanca.svg';
import Ripley from '../../public/images/banks/isotipos/Ripley.svg';

function bankImage(bank) {
  switch (bank) {
    case 'Banco Santander':
      return Santander;
    case 'Banco Itaú':
      return Itau;
    case 'Banco Crédito e Inversiones':
      return BCI;
    case 'Banco Estado':
      return Estado;
    case 'Banco Falabella':
      return Falabella;
    case 'Banco Security':
      return Security;
    case 'Scotiabank':
      return Scotiabank;
    case 'Banco Consorcio':
      return Consorcio;
    case 'Banco BICE':
      return BICE;
    case 'Banco de Chile / Edwards-Citi':
      return Chile;
    case 'Coopeuch':
      return Coopeuch;
    case 'HSBC Bank':
      return HSBC;
    case 'Banco París':
      return Paris;
    case 'Banco Internacional':
      return Internacional;
    case 'Corpbanca':
      return Corpbanca;
    case 'Banco Ripley':
      return Ripley;
    default:
      return Estado;
  }
}

export default bankImage;
