import { gql } from '@apollo/client';

const GET_VOUCHERS = {
  query: gql`
    query GetVoucher($uid: ID, $offset: Int, $limit: Int) {
      getVouchers(userId: $uid, offset: $offset, limit: $limit) {
        offset
        limit
        total
        instances {
          _id
          url
          folio
          expirationDate
          totals {
            netAmount
            iva
            totalAmount
          }
          details {
            number
            name
            quantity
            price
            amount
          }
          discounts {
            title
            number
            amount
          }
          sentDate
        }
      }
    }
  `,
  policies: {
    fetchPolicy: 'network-only',
  },
};

export default GET_VOUCHERS;
