import { Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const BoxParagraph = ({ data }) => {
  const style = {
    fontFamily: data.bold ? 'Poppins-SemiBold' : 'Poppins-Regular',
    fontSize: '14px',
  };

  return <Typography sx={{ ...style }}>{data.text}</Typography>;
};

BoxParagraph.propTypes = {
  data: PropTypes.shape({
    bold: PropTypes.bool,
    text: PropTypes.string,
  }),
};

BoxParagraph.defaultProps = {
  data: {
    bold: false,
    text: '',
  },
};

export default BoxParagraph;
