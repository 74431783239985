import { Typography, Box } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const MixParagraph = ({ data, ident }) => {
  return (
    <Box
      key={`${ident}-mix-Paragraph`}
      variant="span"
      sx={{
        display: 'block',
      }}
    >
      {data.map((elem, index) => {
        const identifier = `${ident}-${index}-mixParagraph`;
        return (
          <Typography
            key={identifier}
            display="inline"
            sx={{
              fontFamily: elem?.bold ? 'Poppins-SemiBold' : 'Poppins-Regular',
              fontSize: '14px',
              textDecoration: elem?.underline ? 'underline' : 'none',
            }}
          >
            {elem.text}
          </Typography>
        );
      })}
    </Box>
  );
};

MixParagraph.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  ident: PropTypes.string,
};

MixParagraph.defaultProps = {
  data: [],
  ident: '',
};

export default MixParagraph;
