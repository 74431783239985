import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import VerificationInput from 'react-verification-input';
import { verdeText } from '../../../../../themes/variants/TucarColors';
import addSecurityCode from '../../../../../../public/images/shared-form/enter-OTP.svg';
import giveSecurityCode from '../../../../../../public/images/shared-form/give-OTP.svg';
import './securityCode.css';

const SecurityCode = ({ optional, securityCode, color, setCodeValue, error, setError }) => {
  const [value, setValue] = useState(securityCode);

  const handleValue = (e) => {
    if (!securityCode) {
      setCodeValue(e.toUpperCase());
      setValue(e.toUpperCase());
      setError();
    }
  };

  if (securityCode) {
    return (
      <Box
        sx={{
          height: '100%',
          minHeight: '100%',
          display: 'grid',
          gridTemplateRows: 'max-content 1fr',
          gridTemplateColumns: '1fr',
        }}
      >
        <Box
          sx={{
            height: 'max-content',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          <Typography
            sx={{
              fontSize: '1rem',
              textAlign: 'left',
              fontFamily: 'Poppins-Medium',
              color,
            }}
          >
            {`Código de seguridad ${optional ? '(Opcional)' : ''}`}
          </Typography>
          <Typography sx={{ fontSize: '14px', textAlign: 'left', fontFamily: 'Poppins-Regular' }}>
            Entrega este código al conductor del turno anterior, para que lo ingrese en su
            formulario de finalización de turno.
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography
              sx={{ fontFamily: 'Poppins-Medium', fontSize: '2rem', letterSpacing: '5px' }}
            >
              {securityCode}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            height: '100%',
            minHeight: '100%',
            borderRadius: '55px',
            backgroundImage: `url(${giveSecurityCode})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        height: '100%',
        minHeight: '100%',
        display: 'grid',
        gridTemplateRows: 'max-content 1fr',
        gridTemplateColumns: '1fr',
      }}
    >
      <Box
        sx={{
          height: 'max-content',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
        }}
      >
        <Typography
          sx={{
            fontSize: '1rem',
            textAlign: 'left',
            fontFamily: 'Poppins-Medium',
            color,
          }}
        >
          {`Código de seguridad ${optional ? '(Opcional)' : ''}`}
        </Typography>
        <Typography sx={{ fontSize: '14px', textAlign: 'left', fontFamily: 'Poppins-Regular' }}>
          Ingresa el código que se muestra en la app de tu compañero al comenzar turno:
        </Typography>
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <VerificationInput
              value={value}
              onChange={handleValue}
              classNames={{
                container: 'container',
                character: `character ${error ? 'error' : ''}`,
              }}
              length={4}
              validChars="0-9A-Za-z-"
            />
          </Box>
          <Typography
            sx={{
              display: error ? 'auto' : 'none',
              fontFamily: 'Poppins-Light',
              fontSize: '12px',
              color: 'red',
              textAlign: 'center',
            }}
          >
            Error en el código de seguridad, intente nuevamente.
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          height: '100%',
          minHeight: '100%',
          borderRadius: '55px',
          backgroundImage: `url(${addSecurityCode})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      />
    </Box>
  );
};

SecurityCode.propTypes = {
  optional: PropTypes.bool,
  securityCode: PropTypes.string,
  color: PropTypes.string,
  setCodeValue: PropTypes.func,
  error: PropTypes.string,
  setError: PropTypes.func,
};

SecurityCode.defaultProps = {
  optional: false,
  error: '',
  securityCode: '',
  color: verdeText,
  setCodeValue: () => {},
  setError: () => {},
};

export default SecurityCode;
