import React from 'react';
import PropTypes from 'prop-types';
import { useMutation, useReactiveVar } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { Box, Divider, Typography, Button } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import moment from 'moment';
import EDIT_NOTIFICATION from '../../../../graphql/mutations/notifications/editNotifications';
import { getAuthStore } from '../../../../graphql/store';

moment.locale('es');

const NotiBox = ({ text, redirect, time, read, refetch, notificationId }) => {
  const navigate = useNavigate();
  const [readNotification] = useMutation(EDIT_NOTIFICATION.mutation, EDIT_NOTIFICATION.policies);
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);

  return (
    <Box sx={{ height: 'max-content' }}>
      <Button
        disabled={read}
        sx={{
          backgroundColor: read ? '#FCFCFC' : '#0057B81A',
          height: 'max-content',
          alignItems: 'center',
          display: 'flex',
          border: '2px',
          color: '#333',
          justifyContent: 'center',
          padding: '20px 10px 10px 10px',
          gap: '10px',
          textAlign: 'left',
          '&:focus': {
            color: '#333',
          },
          '&:disabled': {
            color: '#333',
          },
        }}
        onClick={async () => {
          await readNotification({ variables: { driverId: uid, notificationId } });
          await refetch();
          navigate(redirect, { replace: true });
        }}
      >
        <CircleIcon
          sx={{
            fontSize: '0.8rem',
            color: read ? '#FCFCFC' : '#0057B8',
            paddingBottom: '5px',
            marginRight: '10px',
          }}
        />

        <Box>
          <Typography
            component="span"
            sx={{
              display: 'inline-block',
              fontSize: '0.8rem',
              fontFamily: 'Poppins-Medium',
              letterSpacing: 0,
              lineHeight: 1.2,
            }}
          >
            {text}
            <Typography
              sx={{
                textAlign: 'rigth',
                display: 'inline-block',
                fontFamily: 'Poppins-Light',
                fontSize: '0.8rem',
                marginLeft: '5px',
              }}
            >
              {` - ${moment(time).fromNow()}`}
            </Typography>
          </Typography>
        </Box>
      </Button>

      <Divider />
    </Box>
  );
};

NotiBox.propTypes = {
  text: PropTypes.string.isRequired,
  redirect: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  read: PropTypes.bool.isRequired,
  refetch: PropTypes.func.isRequired,
  notificationId: PropTypes.string.isRequired,
};

export default NotiBox;
