import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useReactiveVar, useQuery } from '@apollo/client';
import moment from 'moment';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import { useOutletContext, useSearchParams } from 'react-router-dom';

import TopBar from '../../../../components/ui/TopNav';
import { getAuthStore } from '../../../../graphql/store';
import GET_DRIVER_BILLWEEK from '../../../../graphql/querys/driver/getBillWeek';
import DateSelector from '../../../../components/choices/DateSelector';
import LoadingProgress from '../../../../components/ui/LoadingProgress';
import { formatterCLP, formatterKm } from '../../../../helpers/formatters';

import ResumeBox from '../../../../components/info/ResumeBox';
import GraphWeek from '../../../../components/info/GraphWeek';
import { azulTucar } from '../../../../themes/variants/TucarColors';
import DetailBalance from './detailBalance';
import arrowTucar from '../../../../../public/images/customIcons/arrow/arrow_blue.svg';
import UberBreakdown from '../../../../components/info/UberBreakdown';

moment.locale('es');

function formatAllInfoBalance(week) {
  const weekDate = `${moment(week.initialDatetime).format('DD')} al ${moment(
    week.finalDatetime,
  ).format('DD [de] MMMM, YYYY')}`;

  const totalApps = week.billDays.reduce((acc, elem) => {
    return acc + elem.incomes.uber + elem.incomes.didi + elem.incomes.cabify + elem.incomes.beat;
  }, 0);

  const otherIncomes = week.billDays.reduce((acc, elem) => {
    return acc + elem.incomes.other;
  }, 0);

  const totalRent = week.billDays.reduce((acc, elem) => {
    return acc + elem.expenses.rent;
  }, 0);

  const totalTag = week.billDays.reduce((acc, elem) => {
    return acc + elem.expenses.tag;
  }, 0);

  const totalFines = week.billDays.reduce((acc, elem) => {
    return acc + elem.expenses.fines;
  }, 0);

  const totalPenaltys = week.billDays.reduce((acc, elem) => {
    return acc + elem.expenses.penaltys;
  }, 0);

  const totalGuarantee = week.billDays.reduce((acc, elem) => {
    return acc + elem.expenses.guarantee;
  }, 0);

  const totalOtherExpenses = week.billDays.reduce((acc, elem) => {
    return acc + elem.expenses.other;
  }, 0);

  const totalExpenses =
    totalRent + totalTag + totalFines + totalPenaltys + totalGuarantee + totalOtherExpenses;

  const kmWeek = week.billDays.reduce((acc, elem) => {
    return acc + elem.km;
  }, 0);

  const totalUberBreakdown = week.billDays.reduce(
    (acc, elem) => {
      return {
        adjustment: acc.adjustment + elem.uberBreakdown.adjustment,
        cashCollected: acc.cashCollected + elem.uberBreakdown.cashCollected,
        netFare: acc.netFare + elem.uberBreakdown.netFare,
        other: acc.other + elem.uberBreakdown.other,
        promotions: acc.promotions + elem.uberBreakdown.promotions,
        taxes: acc.taxes + elem.uberBreakdown.taxes,
        tips: acc.tips + elem.uberBreakdown.tips,
        toll: acc.toll + elem.uberBreakdown.toll,
      };
    },
    {
      adjustment: 0,
      cashCollected: 0,
      netFare: 0,
      other: 0,
      promotions: 0,
      taxes: 0,
      tips: 0,
      toll: 0,
    },
  );

  return {
    billDays: week.billDays,
    dateRange: [
      moment(week.initialDatetime).format('YYYY-MM-DD[T00:00:00]'),
      moment(week.finalDatetime).add(6, 'd').format('YYYY-MM-DD[T00:00:00]'),
    ],
    id: weekDate,
    total: totalApps + otherIncomes - totalExpenses,
    incomes: [
      {
        right: 'Ingresos en Apps',
        left: formatterCLP.format(totalApps),
        collapse: totalApps > 0 && (
          <UberBreakdown
            content={{ breakdown: { ...totalUberBreakdown } }}
            backgroundColor="#FFF"
          />
        ),
      },
      { right: 'Reembolsos', left: formatterCLP.format(otherIncomes) },
      { right: 'Total', left: formatterCLP.format(totalApps + otherIncomes) },
    ],
    expenses: [
      { right: 'Km recorridos', left: formatterKm.format(kmWeek) },
      { right: 'Costo del plan', left: formatterCLP.format(totalRent) },
      { right: 'TAG', left: formatterCLP.format(totalTag) },
      { right: 'Multas de tránsito', left: formatterCLP.format(totalFines) },
      {
        right: 'Penalizaciones',
        left: formatterCLP.format(totalPenaltys),
        popText:
          'Cargos emitidos por incumplimiento de los Términos de Servicio durante el arriendo. Pueden ser por combustible, suciedad, atrasos, inasistencia a la cita de mantención, pieza faltante al auto, entre otros.',
      },
      { right: 'Garantía', left: formatterCLP.format(totalGuarantee) },
      {
        right: 'Otros cargos',
        left: formatterCLP.format(totalOtherExpenses),
        popText: 'Ajustes en las liquidaciones y cobros de deducible, entre otros.',
      },
      {
        right: 'Total',
        left: formatterCLP.format(totalExpenses),
      },
    ],
  };
}

const BalanceView = () => {
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);
  const [date, setDate] = useState('');
  const [dates, setDates] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [offsetPag, setOffsetPag] = useState(0);

  const {
    data: allWeeks,
    loading,
    fetchMore,
  } = useQuery(GET_DRIVER_BILLWEEK.query, {
    ...GET_DRIVER_BILLWEEK.policies,
    variables: {
      uid,
      limit: 20,
      offset: offsetPag,
    },
  });

  // eslint-disable-next-line no-unused-vars
  const [searchParams, _] = useSearchParams();

  const [details, setDetails] = useState(null);
  const [billDays, setBillDays] = useState([]);
  const [incomesInfo, setIncomesInfo] = useState([]);
  const [expensesInfo, setExpensesInfo] = useState([]);
  const [allInfo, setAllInfo] = useState([]);
  const [total, setTotal] = useState(0);
  const [content, setContent] = useState([]);

  // eslint-disable-next-line no-shadow
  const handleChange = (_, value) => {
    setDate(value);
  };

  useEffect(() => {
    if (allInfo) {
      const aux = allInfo.filter((elem) => elem.id === date);
      if (aux.length > 0) {
        setIncomesInfo(aux[0].incomes);
        setExpensesInfo(aux[0].expenses);
        setDateRange(aux[0].dateRange);
        setBillDays(aux[0].billDays);
        setTotal(aux[0].total);
      }
    }
  }, [date]);
  const [loadViewDate, setLoadViewDate] = useState(false);

  useEffect(() => {
    if (!loading && allWeeks && allWeeks.getDriverBillDaysParsedByWeek) {
      if (content.length < totalDocuments || content.length === 0) {
        setContent([...content, ...allWeeks.getDriverBillDaysParsedByWeek.instances]);
        setTotalDocuments(allWeeks.getDriverBillDaysParsedByWeek.total);
        setLoadViewDate(false);
      }
    }
  }, [loading]);

  useEffect(() => {
    if (content.length > 0) {
      const allDatesAux = content.map((elem, index) => {
        return {
          id: index,
          name: `${moment(elem.initialDatetime).format('DD')} al ${moment(
            elem.finalDatetime,
          ).format('DD [de] MMMM, YYYY')}`,
        };
      });

      const allInfoAux = content.map((week) => {
        return formatAllInfoBalance(week);
      });

      setAllInfo(allInfoAux);
      setDates(allDatesAux);

      const dateQuery = searchParams.get('date');
      if (dateQuery) {
        const objectSelected = allInfoAux.filter(
          (bill) => moment(bill.dateRange[0]).format('DD/MM/YYYY') === dateQuery,
        );

        setDate(objectSelected[0]?.id || allDatesAux[0].name);
      } else {
        setDate(allDatesAux[0].name);
      }
    }
  }, [content]);

  const handleFunctionView = async () => {
    if (dates.length < totalDocuments && !loading) {
      setLoadViewDate(true);
      await fetchMore({
        variables: {
          offset: offsetPag + 1,
        },
      });
      setOffsetPag(offsetPag + 1);
    }
    return true;
  };

  const [handlePageChange] = useOutletContext();

  useEffect(() => {
    if (details) {
      handlePageChange(
        <TopBar
          handleExtra={() => {
            setDetails(null);
          }}
          prevPage={`/app/balance?date=${moment(dateRange[0]).format('DD/MM/YYYY')}`}
          title={`Detalles de ${details === 'incomes' ? 'recaudaciones' : 'descuentos'}`}
        />,
        true,
      );
    } else handlePageChange(<TopBar title="Balance" />, true);
  }, [details]);

  if (loading && content.length === 0) {
    return <LoadingProgress allScreen={false} heightContainer="100%" />;
  }
  return details !== null ? (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
        }}
      >
        <Box
          sx={{
            padding: '10px 20px 0px 20px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <DateSelector
            handleChange={handleChange}
            name="data"
            date={date}
            dateArray={dates}
            fontSize="16px"
            fontFamily="Poppins-Medium"
            inViewFunction={handleFunctionView}
            infinity
            loadView={loadViewDate}
          />
        </Box>

        <DetailBalance dateRange={dateRange} billDays={billDays} typeDetail={details} />
      </Box>
    </Box>
  ) : (
    <Box sx={{ height: '100%' }}>
      {date ? (
        <Box
          sx={{
            padding: '10px 20px 20px 20px',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          <DateSelector
            handleChange={handleChange}
            name="data"
            date={date}
            dateArray={dates}
            fontSize="16px"
            fontFamily="Poppins-Medium"
            inViewFunction={handleFunctionView}
            infinity
            loadView={loadViewDate}
          />

          <GraphWeek date={dateRange} billDays={billDays} />

          <ResumeBox
            onClickButton={() => {
              setDetails('incomes');
            }}
            title="Recaudaciones"
            data={incomesInfo}
            iconTitle={
              <img
                src={arrowTucar}
                alt="category"
                style={{
                  width: '18px',
                  transform: 'rotate(180deg)',
                }}
              />
            }
          />
          <ResumeBox
            onClickButton={() => {
              setDetails('expenses');
            }}
            title="Descuentos"
            data={expensesInfo}
            iconTitle={
              <img
                src={arrowTucar}
                alt="category"
                style={{
                  width: '18px',
                }}
              />
            }
          />

          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
              <PaidOutlinedIcon
                sx={{ fontSize: '20px', transform: 'rotate(180deg)', color: azulTucar }}
              />
              <Typography sx={{ fontSize: '16px', fontFamily: 'Poppins-Medium', color: azulTucar }}>
                Resultado semanal
              </Typography>
            </Box>

            <Typography
              sx={{ fontSize: '16px', fontFamily: 'Poppins-Medium', color: azulTucar }}
              noWrap
            >
              {formatterCLP.format(total)}
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Typography sx={{ fontFamily: 'Poppins-Regular', fontSize: '16px' }}>
            No hay balance para mostrar
          </Typography>
        </Box>
      )}
    </Box>
  );
};
export default BalanceView;
