import { gql } from '@apollo/client';

const COMPLETE_STEP = {
  mutation: gql`
    mutation Mutation(
      $step: String
      $data: ExchangeStepData
      $driverId: String!
      $exchangeId: String!
    ) {
      completeStep(step: $step, data: $data, driverId: $driverId, exchangeId: $exchangeId) {
        status
        deliverer {
          status
        }
        receiver {
          status
        }
        steps {
          domain
          status
          type
        }
      }
    }
  `,
  pocilies: {},
};

export default COMPLETE_STEP;
