import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography } from '@tucar/pit-stop';
import { Box } from '@mui/material';
import handsBubble from '../../../../../public/images/bubbleIconsPng/hands.png';

import { backgroundStyle, msgResultStyle } from './styles';

const AcceptScreen = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        ...backgroundStyle,
        background:
          'linear-gradient(180deg, rgba(0, 87, 184, 0.50) 0%, rgba(217, 217, 217, 0.00) 80%)',
      }}
    >
      <Typography
        style={{
          textAlign: 'center',
          fontSize: '18px',
          fontWeight: 600,
          lineHeight: 1.2,
          color: '#FFF',
          paddingBottom: '81px',
        }}
      >
        Solicitud aprobada
      </Typography>

      <img alt="handsBubble" src={handsBubble} width="152px" height="127px" />

      <Typography
        style={{
          ...msgResultStyle,
          color: '#0057B8',
        }}
      >
        ¡Turno cambiado exitosamente!
      </Typography>

      <Button size="large" onClick={() => navigate('/app/v2/petitions', { replace: true })}>
        Volver a turnos
      </Button>
    </Box>
  );
};

export default AcceptScreen;
