import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@mui/material';
import { capitalizeDate } from '../../../helpers/dateFormat';

const DateButton = ({ date, mark, onClick }) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        borderRadius: '100%',
        background: mark ? '#FFF' : 'transparent',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: mark ? '8px 12px' : '8px 0px',
        justifyContent: 'center',
        boxShadow: mark
          ? '-4px -4px 9px 0px rgba(255, 255, 255, 0.88) inset, 2px -5px 14px 0px #D9D9D9 inset'
          : 'none',
      }}
    >
      <Typography
        style={{
          color: mark ? '#1F89FF' : '#2D2F39',
          fontSize: '12px',
          fontWeight: 400,
          lineHeight: 1.2,
        }}
      >
        {capitalizeDate(date, 'ddd').replace('.', '')}
      </Typography>
      <Typography
        style={{
          color: mark ? '#1F89FF' : '#2D2F39',
          fontSize: '14px',
          fontWeight: 600,
          lineHeight: 1.2,
        }}
      >
        {capitalizeDate(date, 'DD')}
      </Typography>
    </Box>
  );
};

DateButton.propTypes = {
  date: PropTypes.instanceOf(Date),
  mark: PropTypes.bool,
  onClick: PropTypes.func,
};

DateButton.defaultProps = {
  date: new Date(),
  mark: false,
  onClick: () => {},
};

export default DateButton;
