import { gql } from '@apollo/client';

import { setDriver, setLead, setPlan, setDocs } from '../../store';
import { setPhoto, setRent, setLabelV2 } from '../../store/driver';

const GET_INIT_DRIVER = {
  query: gql`
    query Query($uid: ID!) {
      getDriver(uid: $uid) {
        _id
        photo
        createdAt
        updatedAt
        rent {
          carId
        }
        info {
          address
          adrapt
          adrnumber
          birth
          city
          code
          commune
          country
          email
          firstname
          lastname
          phone
          region
          rut
          situation
          gender
          parking
          secondPhone
          emergencyPhone
        }
        status {
          banned
          status
          deleted
          enabled
          verified
          substatus
        }
        verifiedList {
          auth
          tc
          bank
          docs
          info
          meeting
          plan
          app
          guarantee
        }
        docs {
          _id
          files {
            name
            status
            expire
            extension
            url
          }
        }
        lead {
          _id
          source
          crmIdPrefix
          calendarLink
          meeting {
            status
            id
            link
            cancel
            reschedule
            start
            end
          }
        }
        plan {
          _id
          plan {
            exclusive
            electric
            name
            active
            shifts {
              _id
            }
          }
          planId
          selectedShifts {
            _id
            startTime
            endTime
            basePrice
            type
          }
        }
        uber {
          _id
          category {
            uberPro
            discount
          }
          firstname
          lastname
          email
          phone
          code
        }
        docs {
          files {
            name
            status
            expire
            extension
            url
          }
        }
      }
    }
  `,
  policies: {
    fetchPolicy: 'no-cache',
    onCompleted: (response) => {
      if (response.getDriver) {
        const { info, status, verifiedList, docs, lead, plan, uber, photo, rent } =
          response.getDriver;

        setLabelV2(true);
        info.source = lead?.source || 'Facebook';
        if (uber) {
          setDriver({ info, status, verifiedList, category: uber.category });
        } else {
          setDriver({ info, status, verifiedList });
        }
        setRent(rent);
        setPhoto(photo);
        setDocs(docs.files);
        setLead(lead);
        setPlan(plan);
      }
    },
    errorPolicy: 'ignore',
    context: {
      ignorePattern: {
        pattern: [
          'Error fetching driver rent',
          'Error fetching driver plan',
          'Error fetching uber driver',
        ],
        mandatory: false,
      },
    },
  },
};

export default GET_INIT_DRIVER;
