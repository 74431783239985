import { gql } from '@apollo/client';

const GET_DRIVER_BANKS = {
  query: gql`
    query getDriverAccounting($uid: ID!) {
      getDriverAccounting(uid: $uid) {
        _id
        bankAccount {
          _id
          accountType
          account
          bank
          email
          name
          rut
        }
        bankAccounts {
          _id
          accountType
          account
          bank
          email
          name
          rut
        }
      }
    }
  `,
  policies: {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: 'true',
    errorPolicy: 'all',
  },
};

export default GET_DRIVER_BANKS;
