import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, TextField, InputAdornment, IconButton, Radio } from '@mui/material';
import { EditOutlined } from '@mui/icons-material';
import { useReactiveVar, useQuery, useMutation } from '@apollo/client';
import { useOutletContext } from 'react-router-dom';

import TopBar from '../../../components/ui/TopNav';
import { getAuthStore, getDriverStore } from '../../../graphql/store';
import GET_DRIVER_PAYMENT from '../../../graphql/querys/plan/getPayment';
import LoadingProgress from '../../../components/ui/LoadingProgress';
import { formatterCLP } from '../../../helpers/formatters';
import SubmitButton from '../../../components/SubmitButton';
import SEND_PAYMENT from '../../../graphql/mutations/plan/createPayment';
import ErrorModal from '../../../components/ui/ErrorModal';

const Payment = ({ onBoarding }) => {
  const cantergianiFix = false;
  const [handlePageChange] = useOutletContext();

  useEffect(() => {
    if (!onBoarding) {
      handlePageChange(<TopBar title="Pagos" />, true);
    }
  }, []);

  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);
  const {
    info: { country },
  } = useReactiveVar(getDriverStore);
  const [amount, setAmount] = useState(0);
  const [errorOpen, setErrorOpen] = useState(false);

  const {
    data,
    loading: paymentLoading,
    error: paymentError,
  } = useQuery(GET_DRIVER_PAYMENT.query, {
    ...GET_DRIVER_PAYMENT.policies,
    variables: {
      driverId: uid,
      country: country || 'Chile',
    },
  });
  const [paymentData, setPaymentData] = useState([]);
  const [loadButton, setLoadButton] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState({});

  const [sendPayment, { loading, error }] = useMutation(
    SEND_PAYMENT.mutation,
    SEND_PAYMENT.policies,
  );

  const handleSend = () => {
    setLoadButton(true);
  };

  useEffect(() => {
    if (!loading && loadButton) {
      if (error) {
        setErrorOpen(true);
      }
      setLoadButton(false);
    }
  }, [loading]);

  useEffect(() => {
    if (loadButton && amount > 0) {
      sendPayment({
        variables: {
          driverId: uid,
          amount: parseInt(amount, 10),
          info: {
            title: selectedPayment.title,
            description: selectedPayment.description,
            settledCollection: selectedPayment.settledCollection,
            settledRef: selectedPayment.settledRef,
          },
        },
      });
    }
  }, [loadButton]);

  useEffect(() => {
    if (data?.getDriverPayment) {
      setPaymentData(data.getDriverPayment);
    }

    if (paymentError) {
      setErrorOpen(true);
    }
  }, [paymentLoading, data]);

  const handleChange = (newValue) => {
    const aux = newValue.replace('.', '').replace('$', '');
    setAmount(aux);
  };

  const handleSelection = (payment) => {
    setSelectedPayment(payment);
    setAmount(payment.amount);
  };

  if (paymentLoading || !data) {
    return <LoadingProgress />;
  }

  if (cantergianiFix) {
    return (
      <Box sx={{ position: 'relative', height: '100%' }}>
        <Typography
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            fontFamily: 'Poppins-Medium',
            fontSize: '16px',
            textAlign: 'center',
          }}
        >
          Estamos trabajando en mejorar nuestro servicio, por favor intente más tarde.
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ height: '100%' }}>
      <ErrorModal
        open={errorOpen}
        onClose={() => {
          setErrorOpen(false);
        }}
        content="Favor de contactar con soporte para resolver el problema."
        title="Error en pagos"
      />
      {paymentData.lenght ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Typography sx={{ fontFamily: 'Poppins-Medium', fontSize: '16px' }}>
            No hay ningún pago pendiente
          </Typography>
        </Box>
      ) : (
        <Box>
          <Box sx={{ paddingInline: '20px' }}>
            <Typography
              sx={{ marginBottom: '20px', fontFamily: 'Poppins-Regular', fontSize: '14px' }}
            >
              Selecciona la deuda que deseas pagar o abonar.
            </Typography>

            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: '10px', marginBottom: '20px' }}
            >
              {paymentData.map((payment) => {
                return (
                  <Box key={payment.settledRef} sx={{ display: 'flex', gap: '10px' }}>
                    <Radio
                      sx={{ margin: 0 }}
                      checked={selectedPayment.settledRef === payment.settledRef}
                      onChange={() => handleSelection(payment)}
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Box>
                        <Typography sx={{ fontFamily: 'Poppins-Medium', fontSize: '12px' }}>
                          {payment.title}
                        </Typography>
                      </Box>
                      <Typography sx={{ fontFamily: 'Poppins-Regular', fontSize: '14px' }}>
                        {formatterCLP.format(Math.abs(payment.amount))}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            </Box>

            {onBoarding && (
              <Typography sx={{ marginY: '10px', fontFamily: 'Poppins-Regular', fontSize: '14px' }}>
                La garantía será devuelta en 2 partes siempre que no hayan una deuda con Tucar. La
                primera semana te devolveremos $200.000 y después de 60 días los $150.000 restantes.
              </Typography>
            )}

            <TextField
              disabled={!selectedPayment?.settledRef}
              fullWidth
              size="small"
              type="text"
              sx={{ marginTop: '10px', marginBottom: '20px' }}
              variant="outlined"
              value={formatterCLP.format(amount).replace('$', '') || 0}
              name="amount"
              onChange={(e) => handleChange(e.target.value)}
              label=" Total a pagar (CLP)"
              InputProps={{
                pattern: '[0-9]*',
                style: { fontFamily: 'Poppins-Regular' },
                startAdornment: (
                  <InputAdornment position="start" sx={{ paddingLeft: '8px' }}>
                    $
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <EditOutlined />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            {amount > 80000 ? (
              <Typography
                sx={{ marginBottom: '20px', fontFamily: 'Poppins-Regular', fontSize: '14px' }}
              >
                Si es tu primer pago, recuerda que no debe ser mayor a $100.000 en una única
                transferencia.
              </Typography>
            ) : null}
            <Typography
              sx={{ marginBottom: '20px', fontFamily: 'Poppins-Regular', fontSize: '14px' }}
            >
              Escribe el monto que deseas cancelar y haz click en el botón para redirigirte a la
              plataforma de pago.
            </Typography>

            <SubmitButton
              disabled={!selectedPayment?.settledRef || amount <= 0 || loadButton}
              text={loadButton ? 'Cargando' : 'Pagar'}
              handler={handleSend}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

Payment.propTypes = {
  onBoarding: PropTypes.bool,
};

Payment.defaultProps = {
  onBoarding: false,
};
export default Payment;
