import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Typography, Box } from '@mui/material';
import { useQuery, useReactiveVar, NetworkStatus } from '@apollo/client';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import CardButtonDash from '../CardButtonDash';
import ViewModal from '../../extras/ViewModal';
import { getAuthStore } from '../../../graphql/store';
import GET_DOCUMENTS from '../../../graphql/querys/docs/getDocuments';
import UploadFileButton from '../../UploadFileButton';
import translateDocsName from '../../../helpers/translateDocsName';
import LoadingProgress from '../../ui/LoadingProgress';
import OnBoardingTitle from '../../extras/OnboardingTitle';

const fileNameList = [
  'cedulaIdentidadFront',
  'cedulaIdentidadBack',
  'licenciaConducirFront',
  'licenciaConducirBack',
  'certificadoAntecedentes',
  'hojaVida',
];

const DocsButton = ({ styleProps, tutorial, contentDirection }) => {
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState([]);

  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);
  const {
    data: docsData,
    refetch,
    loading,
    networkStatus,
  } = useQuery(GET_DOCUMENTS.query, {
    variables: { uid },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (docsData?.getDocument) {
      const docsExist = docsData.getDocument.files.map((doc) => doc.name);

      const newDocumentObjects = docsData.getDocument.files.reduce((prev, curr, index) => {
        const { status, name } = curr;
        const newObject = {
          status,
          name,
          fileType: 'application/pdf, image/*',
          loadingRefetch: false,
          id: index,
        };
        return { ...prev, [name]: newObject };
      }, {});

      const aux = fileNameList.map((elem, ident) => {
        if (docsExist.includes(elem)) return newDocumentObjects[elem];

        return {
          status: null,
          name: elem,
          fileType: 'application/pdf, image/*',
          loadingRefetch: false,
          id: ident,
        };
      });
      if (aux.length === 0) {
        setOpen(false);
      }
      setContent(aux);
    }
  }, [loading, docsData]);

  const textContainer = (
    <Typography
      variant="span"
      sx={{
        display: 'inline-block',
        fontFamily: 'Poppins-Regular',
        lineHeight: 1.2,
        fontSize: '0.875rem',
      }}
    >
      Sube tus{' '}
      <Typography
        sx={{
          display: 'inline-block',
          fontFamily: 'Poppins-SemiBold',
          lineHeight: 1.2,
          fontSize: '0.875rem',
        }}
      >
        documentos
      </Typography>{' '}
      y{' '}
      <Typography
        sx={{
          display: 'inline',
          fontFamily: 'Poppins-SemiBold',
          lineHeight: 1.2,
          fontSize: '0.875rem',
        }}
      >
        antecedentes
      </Typography>{' '}
      para verificar que cumplas con los requisitos.
    </Typography>
  );

  const handleUpload = (name, file) => {
    const data = new FormData();
    data.append('files', file, `${name}.${file.name.split('.').pop()}`);
    data.append('id', uid);
    const config = {
      method: 'post',
      url: `${process.env.DOCUMENT_GATEWAY_URL}/api/v1/cl/document/attach`,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Apikey ${process.env.DOCUMENT_GATEWAY_API_KEY}`,
      },
      data,
    };
    axios(config)
      .then(() => {
        refetch();
      })
      .catch(() => {
        setOpen(true);
      });
  };

  if (loading || networkStatus === NetworkStatus.refetch) {
    return (
      <Box sx={{ minHeight: 'max-content' }}>
        <LoadingProgress allScreen={false} />
      </Box>
    );
  }

  return (
    <>
      <ViewModal
        open={open}
        setOpen={setOpen}
        content={
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '25px',
              alignItems: 'center',
              height: '100%',
              marginTop: '30px',
            }}
          >
            <OnBoardingTitle
              title="Documentos y antecedentes"
              subtitle="Asegúrate de que sean legibles y estén vigentes. Pueden encontrar algunos en:"
              emojiSymbol="📄"
              linkEmergency={{
                link: 'https://www.registrocivil.cl/principal/servicios-en-linea',
                text: 'www.registrocivil.cl',
              }}
            />
            <Box>
              {content.map((file, index) => {
                if (file.status !== 'Pending')
                  return (
                    <UploadFileButton
                      disableDelete
                      status={file.status}
                      key={file.name}
                      label={translateDocsName(file.name)}
                      fileType={file.fileType}
                      fileName={file.name}
                      handler={handleUpload}
                      notLast={index !== content.length - 1}
                    />
                  );
                return null;
              })}
            </Box>
          </Box>
        }
      />
      <CardButtonDash
        IconElement={DescriptionOutlinedIcon}
        tutorial={tutorial}
        colors={{ primary: '#40B6D4', secondary: '#EFFCFF' }}
        sendText="Subir documentos"
        content={textContainer}
        styleProps={{
          container: { ...styleProps, visibility: content.length > 0 ? 'visible' : 'hidden' },
        }}
        handleGo={() => setOpen(true)}
        contentDirection={contentDirection}
      />
    </>
  );
};

DocsButton.propTypes = {
  contentDirection: PropTypes.string,
  tutorial: PropTypes.bool,
  styleProps: PropTypes.shape({}),
};

DocsButton.defaultProps = {
  contentDirection: 'row',
  tutorial: false,
  styleProps: {},
};

export default DocsButton;
