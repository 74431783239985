const dataQuery = {
  getDriverBillWeek: [
    {
      date: '2023-01-23T00:00:00',
      km: [0, 0, 0, 0, 0, 0, 0],
      expenses: {
        fines: 0,
        penaltys: 0,
        tag: 0,
        other: 94050,
        guarantee: null,
        rent: 35371,
      },
      incomes: {
        uber: 296049,
        didi: 0,
        cabify: 0,
        beat: 0,
        other: 8500,
      },
      installment: 0,
      debt: 15128,
      balance: -15128,
      payBill: 0,
      settled: false,
      prePay: 0,
    },
    {
      date: '2023-01-16T00:00:00',
      km: [234, 37, 32, 0, 0, 0, 0],
      expenses: {
        fines: 0,
        penaltys: 0,
        tag: 1810,
        other: 0,
        guarantee: null,
        rent: 83325,
      },
      incomes: {
        uber: 283050,
        didi: 0,
        cabify: 0,
        beat: 0,
        other: 0,
      },
      installment: 0,
      debt: 0,
      balance: 197915,
      payBill: -197915,
      settled: true,
      prePay: 0,
    },
    {
      date: '2023-01-09T00:00:00',
      km: [222, 192, 152, 151, 270, 16, 250],
      expenses: {
        fines: 0,
        penaltys: 0,
        tag: 6112,
        other: 44903,
        guarantee: null,
        rent: 162715,
      },
      incomes: {
        uber: 261203,
        didi: 0,
        cabify: 0,
        beat: 0,
        other: 0,
      },
      installment: 0,
      debt: 0,
      balance: 47473,
      payBill: -47473,
      settled: false,
      prePay: 0,
    },
  ],
};

export default dataQuery;
