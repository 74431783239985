import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Button } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const ButtonCardIcon = ({ text, auxText, onClick, styleElement, iconElem, colorLink }) => {
  return (
    <Button
      variant="outlined"
      sx={{
        ...styleElement,
        borderRadius: '7px',
        display: 'flex',
        padding: '15px',
        margin: '30px',
        alignItems: 'center',
        gap: '15px',
        textTransform: 'initial',
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
          ...styleElement,
        },
      }}
      onClick={onClick}
    >
      {iconElem}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <Typography
          sx={{
            fontFamily: 'Poppins-Regular',
            fontSize: '12px',
            textAlign: 'left',
            color: 'black',
            lineHeight: '1.2',
          }}
        >
          {text}
        </Typography>
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'right' }}>
          <Box
            sx={{
              display: 'flex',
              height: '8px',
              padding: '0px',
              gap: '5px',
            }}
          >
            <Typography
              sx={{
                display: 'inline-block',
                lineHeight: 1,
                paddingLeft: '5px',
                fontSize: '12px',
                color: colorLink,
              }}
              fontFamily="Poppins-Medium"
            >
              {auxText}
            </Typography>
            <Box
              sx={{
                backgroundColor: colorLink,
                borderRadius: '50px',
                height: '10px',
                width: '10px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <KeyboardArrowRightIcon size="small" sx={{ fontSize: '10px', fill: '#FFF' }} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Button>
  );
};

ButtonCardIcon.propTypes = {
  text: PropTypes.string,
  auxText: PropTypes.string,
  colorLink: PropTypes.string,
  onClick: PropTypes.func,
  styleElement: PropTypes.shape({}),
  iconElem: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.object]),
};

ButtonCardIcon.defaultProps = {
  iconElem: null,
  styleElement: {},
  onClick: () => {},
  auxText: '',
  text: '',
  colorLink: '',
};

export default ButtonCardIcon;
