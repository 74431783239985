import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useReactiveVar, useLazyQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { Card, Button, Typography } from '@tucar/pit-stop';
import { Collapse, Box } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import DashboardIcon from '@mui/icons-material/Dashboard';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DoorControlCard from '../../../pages/app/dashBoard/newDashboard/sections/DoorControlCard';
import SwipeShift from '../../buttons/SwipeButton/SwipeShift';
import { getDriverStore } from '../../../graphql/store';
import { baseButtonStyle, locationButtonStyle } from './styles';
import GET_LABEL from '../../../graphql/querys/getLabel';

const DropdownManager = ({ setFilter, dashboardMode }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [disabledManage, setDisabledManage] = useState(true);
  const { rent } = useReactiveVar(getDriverStore);
  const [getBillDays, { loading, data }] = useLazyQuery(GET_LABEL.query, {
    ...GET_LABEL.policies,
    variables: {
      labelId: 'REMOTE_CONTROL_DOORS',
    },
  });

  useEffect(() => {
    if (rent?.carId) {
      getBillDays();
    }
  }, [rent]);

  useEffect(() => {
    if (data && data?.getLabel) {
      const { elements } = data.getLabel;
      if (elements && elements.length > 0) {
        const existInArray = elements.includes(rent?.carId);
        setDisabledManage(!existInArray);
      } else setDisabledManage(true);
    }
  }, [data]);

  return (
    <Card
      style={{
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '8px',
          }}
        >
          <Box
            sx={dashboardMode ? baseButtonStyle : locationButtonStyle}
            onClick={() => navigate('/app/v2/chargers', { replace: true })}
          >
            <MyLocationIcon sx={{ color: dashboardMode ? '#5A6571' : '#FFF', fontSize: '23px' }} />
            {!dashboardMode && <Typography style={{ color: '#FFF' }}>Mapa</Typography>}
          </Box>
          <Box
            sx={dashboardMode ? locationButtonStyle : baseButtonStyle}
            onClick={() => navigate('/app/v2/dashboard', { replace: true })}
          >
            <DashboardIcon sx={{ color: dashboardMode ? '#FFF' : '#5A6571', fontSize: '23px' }} />
            {dashboardMode && <Typography style={{ color: '#FFF' }}>Dashboard</Typography>}{' '}
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '10px',
          }}
        >
          <Button
            onClick={setFilter}
            style={{
              display: dashboardMode ? 'none' : 'flex',
              padding: '10px',
              height: 'auto',
              backgroundColor: '#1F89FF',
            }}
            startAdornment={<FilterListIcon sx={{ color: '#FFF', fontSize: '20px' }} />}
          />
          <Button
            onClick={() => setOpen(!open)}
            style={{ padding: '10px', height: 'auto', display: !rent?.carId ? 'none' : 'flex' }}
            startAdornment={
              <KeyboardArrowDownIcon
                sx={{
                  color: '#5B5D71',
                  fontSize: '20px',
                  transform: open ? 'rotate(-180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.3s',
                }}
              />
            }
          />
        </Box>
      </Box>

      <Collapse in={open} sx={{ width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            marginTop: '30px',
            width: '100%',
          }}
        >
          <DoorControlCard rentVersion loadingData={loading} disabled={disabledManage} />
          <SwipeShift />
        </Box>
      </Collapse>
    </Card>
  );
};

export default DropdownManager;

DropdownManager.propTypes = {
  setFilter: PropTypes.func,
  dashboardMode: PropTypes.bool,
};

DropdownManager.defaultProps = {
  setFilter: () => {},
  dashboardMode: false,
};
