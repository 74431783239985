import { TaskOutlined } from '@mui/icons-material';

const uberProDetails = [
  {
    title: {
      blue: 'No tienes dcto. en la suscripción',
      gold: 'Tienes un 5% de dct. en la suscripción',
      platinum: 'Tienes un 10% de dcto. en la suscripción',
      diamond: 'Tienes un 15% de dcto. en la suscripción',
    },
    filter: {
      blue: ['blue'],
      gold: ['blue', 'gold'],
      platinum: ['blue', 'gold', 'platinum'],
      diamond: ['blue', 'gold', 'platinum', 'diamond'],
    },
    dcto: {
      blue: '0% de dcto.',
      gold: '5% de dcto.',
      platinum: '10% de dcto.',
      diamond: '15% de dcto.',
    },
    colors: {
      blue: '#0057B8',
      gold: '#FEC23E',
      platinum: '#8EA3A9',
      diamond: '#010101',
    },
    icons: TaskOutlined,
  },
];

export default uberProDetails;
