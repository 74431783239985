/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  FormControl,
  FormControlLabel,
  Radio,
  Divider,
  RadioGroup,
  Box,
  Typography,
} from '@mui/material';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';

import SubmitButton from '../../../../components/SubmitButton';
import OnBoardingTitle from '../../../../components/extras/OnboardingTitle';
import IconTitleCard from '../../../../components/info/IconTitleCard';
import selectColorMode from './selectColorMode';
import LocationInputField from './LocationInputField';

function compare(a, b) {
  if (a.startTime < b.startTime) {
    return -1;
  }
  if (a.startTime > b.startTime) {
    return 1;
  }
  return 0;
}

const SelectContentPlan = ({ planId, mode, shifts, selectPlan }) => {
  const [value, setValue] = useState('');
  const [exchangeAddress, setExchangeAddress] = useState(null);

  const handleRadioChange = (event) => {
    setValue(event.target.value);
  };

  const sendEleccion = () => {
    selectPlan(planId, value, exchangeAddress);
  };
  const copyList = [...shifts].filter((shift) => shift.available);
  copyList.sort(compare);

  const infoBoxes = copyList.map((shift) => {
    return (
      <Box key={shift.name}>
        <Box>
          <FormControlLabel
            disableTypography
            sx={{ fontFamily: 'Poppins-Regular', fontSize: '1rem' }}
            value={shift._id}
            control={
              <Radio
                sx={{
                  '&.Mui-checked': { color: selectColorMode(mode) },
                }}
              />
            }
            label={`${shift.name}:`}
          />
          <Box sx={{ display: 'flex', alignItems: 'start' }}>
            <Radio sx={{ paddingLeft: 0, visibility: 'hidden' }} />
            <Typography sx={{ fontFamily: 'Poppins-Light' }}>
              De {shift.startTime} - {shift.endTime} hrs.
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  });

  const contentSelectTime = (
    <Box sx={{ padding: '20px' }}>
      <FormControl>
        <RadioGroup
          onChange={handleRadioChange}
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="female"
          name="radio-buttons-group"
        >
          {infoBoxes}
        </RadioGroup>
      </FormControl>

      <Box>
        <Typography sx={{ marginBottom: '20px', fontFamily: 'Poppins-Light', fontSize: '14px' }}>
          Señala un lugar de preferencia para intercambiar el vehículo. Esto será tomado en
          consideración al momento de elegir una pareja para ti aunque puede cambiar.
        </Typography>
        <LocationInputField
          label="Dirección de intercambio"
          placeholder="Ingrese una dirección"
          name="exchangeAddress"
          style={{ flex: '1  1 100%', marginBottom: '20px' }}
          value={exchangeAddress || ''}
          selectorValue={exchangeAddress || ''}
          onChange={(newValue) => {
            setExchangeAddress(newValue);
          }}
        />
      </Box>
      <SubmitButton
        backgroundColor={selectColorMode(mode)}
        disabled={!value || !exchangeAddress}
        text="Continuar"
        handler={sendEleccion}
      />
    </Box>
  );

  return (
    <Box
      sx={{
        marginTop: '30px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        justifyContent: 'center',
        alignItems: 'center',
        paddingInline: '10px',
        marginBottom: '10px',
      }}
    >
      <OnBoardingTitle
        title="Elige el turno"
        subtitle="Selecciona el horario en que te gustaría usar el auto para manejar en apps"
      />
      <Divider
        sx={{
          width: '100%',
          color: '#EAEAEA',
        }}
      />
      <IconTitleCard
        title={
          <Box sx={{ paddingTop: '20px ' }}>
            <Typography
              sx={{ textAlign: 'center', fontFamily: 'Poppins-Regular', fontSize: '1.2rem' }}
            >
              Horarios:
            </Typography>
          </Box>
        }
        spaceTop="10%"
        IconBubble={GroupsOutlinedIcon}
        IconBubbleStyle={{
          container: { backgroundColor: '#B6E853', transform: 'translate(-50%, 0%) !important' },
          icon: { color: '#FFF' },
        }}
        containerStyle={{
          padding: '20px 0px 20px 0px',
          minHeight: '130px',
          borderRadius: '15px',
          backgroundColor: '#FFF',
          boxShadow: 2,
        }}
        contentText={contentSelectTime}
      />
    </Box>
  );
};

export default SelectContentPlan;

SelectContentPlan.propTypes = {
  mode: PropTypes.string,
  selectPlan: PropTypes.func,
  shifts: PropTypes.arrayOf(PropTypes.shape({})),
  planId: PropTypes.string,
};

SelectContentPlan.defaultProps = {
  planId: '',
  mode: '',
  selectPlan: () => {},
  shifts: [],
};
