import moment from 'moment';

function getDashboardInfo(elem) {
  const { billDays } = elem;
  const incomes = billDays.reduce((acc, bill) => {
    return (
      acc +
      bill.incomes.uber +
      bill.incomes.didi +
      bill.incomes.cabify +
      bill.incomes.beat +
      bill.incomes.other
    );
  }, 0);
  const expenses = billDays.reduce((acc, bill) => {
    return (
      acc +
      bill.expenses.fines +
      bill.expenses.penaltys +
      bill.expenses.guarantee +
      bill.expenses.rent +
      bill.expenses.tag +
      bill.expenses.other
    );
  }, 0);
  const kmTucar = billDays.reduce((acc, bill) => {
    return acc + bill.km;
  }, 0);
  const kmOther = billDays.reduce((acc, bill) => {
    return acc + bill.km - bill.kmUber;
  }, 0);
  const kmUber = billDays.reduce((acc, bill) => {
    return acc + bill.kmUber;
  }, 0);

  return {
    initialDatetime: elem.initialDatetime,
    billDays,
    date: moment(elem.initialDatetime).format('DD [de] MMM, YYYY'),
    incomes,
    expenses,
    kmTucar,
    kmUber,
    kmOther,
    total: incomes - expenses,
  };
}

export default getDashboardInfo;
