import { azulTucar, rojoTucar, grisTucar } from './TucarColors';

const textfield = {
  variants: [
    {
      props: { variant: 'outlined' },
      style: {
        fontFamily: 'Poppins-Regular',
        fontSize: '14px',
        '&.MuiOutlinedInput-root.Mui-error': {
          borderColor: rojoTucar,
        },
        '& .input:hover': {
          borderColor: azulTucar,
        },
        '& .MuiInputLabel-root': {
          paddingInline: '8px',
          paddingLeft: '1px',
          fontSize: '14px',
          backgroundColor: 'transparent',
        },
        '& .MuiFormHelperText-root': {
          fontFamily: 'Poppins-Regular',
          lineHeight: 1,
          marginTop: '8px',
        },
        '& .MuiOutlinedInput-root': {
          borderRadius: '55px',
          paddingInline: '8px',
          fontSize: '14px',
          '&:hover fieldset': {
            borderColor: azulTucar,
          },
          '& fieldset': {
            borderColor: grisTucar,
          },
          '&.Mui-focused.Mui-error fieldset': {
            borderColor: rojoTucar,
          },
          '&.Mui-error fieldset': {
            borderColor: rojoTucar,
          },
        },
      },
    },
  ],
};

export default textfield;
