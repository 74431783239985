import React, { useState } from 'react';
import { Card } from '@tucar/pit-stop';
import PropTypes from 'prop-types';
import { Box, Typography, Collapse, IconButton } from '@mui/material';
import { KeyboardArrowUpOutlined, KeyboardArrowDownOutlined } from '@mui/icons-material';

import { capitalizeDate } from '../../../../helpers/dateFormat';
import getUniqueKey from '../../../../helpers/customKey';
import { showTime } from '../confirmationView/auxFunctions';

const DifferenceCollapse = ({ differenceShift }) => {
  const [open, setOpen] = useState(false);
  if (differenceShift.length === 0)
    return (
      <Typography
        style={{
          color: '#1F89FF',
          fontSize: '16px',
          fontWeight: 600,
          lineHeight: '1.2',
          textAlign: 'center',
        }}
      >
        No existen cambios entre la solicitud y el plan actual
      </Typography>
    );

  return (
    <Card
      style={{
        padding: '24px 36px',
        width: '100%',
      }}
    >
      <Box
        onClick={() => setOpen(!open)}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Typography
          style={{
            color: '#1F89FF',
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: 1.2,
          }}
        >
          Días solicitados
        </Typography>

        <Box>
          {open ? (
            <IconButton
              sx={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
              }}
            >
              <KeyboardArrowUpOutlined sx={{ margin: '0px', fontSize: '24px', color: '#1F89FF' }} />
            </IconButton>
          ) : (
            <IconButton
              sx={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
              }}
            >
              <KeyboardArrowDownOutlined
                sx={{
                  margin: '0px',
                  verticalAlign: 'start',
                  fontSize: '24px',
                  color: '#1F89FF',
                }}
              />
            </IconButton>
          )}
        </Box>
      </Box>
      <Collapse sx={{ width: '100%' }} in={open} timeout="auto" unmountOnExit>
        <Box>
          {differenceShift.map((shift) => {
            return (
              <Box
                key={getUniqueKey()}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '7px',
                  paddingTop: '27px',
                }}
              >
                <Typography
                  style={{
                    width: '100%',
                    fontSize: '16px',
                    fontWeight: 600,
                    color: '#2D2F39',
                    lineHeight: 1.2,
                    paddingBottom: '7px',
                  }}
                >
                  Día: {capitalizeDate(shift.date, 'dddd DD/MM')}
                </Typography>
                <Typography
                  style={{
                    width: '100%',
                    fontSize: '14px',
                    fontWeight: 500,
                    color: '#5B5D71',
                    lineHeight: 1.2,
                  }}
                >
                  Turno actual: {showTime(shift.currentTime)}
                </Typography>
                <Typography
                  style={{
                    width: '100%',
                    fontSize: '14px',
                    fontWeight: 500,
                    color: '#5B5D71',
                    lineHeight: 1.2,
                  }}
                >
                  Turno propuesto: {showTime(shift.futureTime)}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Collapse>
    </Card>
  );
};

DifferenceCollapse.propTypes = {
  differenceShift: PropTypes.arrayOf(PropTypes.shape({})),
};

DifferenceCollapse.defaultProps = {
  differenceShift: [],
};

export default DifferenceCollapse;
