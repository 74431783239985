import React, { useState, useRef } from 'react';
import { Box, IconButton, Popover, Typography } from '@mui/material';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import PropTypes from 'prop-types';

const PopoverTucar = ({ popText, onBoarding }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickPop = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePop = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton onClick={handleClickPop} sx={{ padding: '0px 0px 0px 5px' }}>
        <HelpOutlineOutlinedIcon
          sx={{ color: onBoarding ? '#adadad' : '#333', fontSize: '1.3rem', paddingBottom: '5px' }}
        />
      </IconButton>

      <Popover
        disableRestoreFocus
        open={Boolean(anchorEl)}
        ref={useRef(null)}
        anchorEl={anchorEl}
        onClose={handleClosePop}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '0px 15px 15px 15px',
          },
        }}
      >
        <Typography
          sx={{
            padding: 1,
            fontFamily: 'Poppins-Regular',
            fontSize: '0.75rem',
          }}
        >
          {popText}
        </Typography>
      </Popover>
    </Box>
  );
};

PopoverTucar.propTypes = {
  popText: PropTypes.string,
  onBoarding: PropTypes.bool,
};

PopoverTucar.defaultProps = {
  popText: '',
  onBoarding: false,
};
export default PopoverTucar;
