import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Typography } from '@tucar/pit-stop';
import { azulTucar } from '../../../themes/variants/TucarColors';
import { formatterCLP } from '../../../helpers/formatters';

function VoucherTextBorder({ color, last, title, ident, discount = false, price }) {
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        paddingLeft: '60px',
        width: '100%',
        paddingBottom: last ? 'unset' : '20px',
      }}
    >
      <Box
        sx={{
          display: last ? 'none' : 'block',
          position: 'absolute',
          height: '100%',
          width: '1px',
          top: '10px',
          left: '20px',
          backgroundColor: color,
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          height: '9px',
          width: '9px',
          borderRadius: '15px',
          top: '5px',
          left: '16px',
          backgroundColor: color,
        }}
      />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          gap: '5px',
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'start',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          <Typography style={{ color: '#71758E', fontSize: '14px', fontWeight: 500 }}>
            {discount ? `Descuento Nº${ident}` : `Detalle Nº${ident}`}
          </Typography>
          <Typography
            style={{
              color: azulTucar,
              fontSize: '16px',
              lineHeight: 1.2,
              fontWeight: 500,
              textAlign: 'start',
            }}
          >
            {title}
          </Typography>
          <Box
            sx={{
              padding: '12px 20px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '10px',
              position: 'relative',
              gap: '5px',
              boxShadow:
                '4px 4px 20px 0px rgba(111, 140, 176, 0.41), -6px -6px 20px 0px #FFF, 2px 2px 4px 0px rgba(114, 142, 171, 0.10)',
              '::before': {
                content: '""',
                zIndex: 0,
                position: 'absolute',
                width: '100%',
                height: '100%',
                borderRadius: '10px',
                left: 0,
                top: 0,
                background: 'linear-gradient(300deg, #FFFFFF 20%, #C1D9F4 100%)',
              },
              '::after': {
                content: '""',
                position: 'absolute',
                zIndex: 0,
                top: '1px',
                bottom: '1px',
                left: '1px',
                right: '1px',
                borderRadius: '9px',
                background:
                  'linear-gradient(268deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.00) 52.74%), linear-gradient(270deg, #50CAFF 0%, #0478FF 100%)',
              },
            }}
          >
            <Typography
              style={{
                zIndex: 1,
                color: '#FFF',
                fontSize: '14px',
                fontWeight: 500,
              }}
            >
              $
            </Typography>
            <Typography
              style={{
                zIndex: 1,
                color: '#FFF',
                fontSize: '14px',
                fontWeight: 500,
              }}
            >
              {formatterCLP.format(price).replace('$', '')}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

VoucherTextBorder.propTypes = {
  last: PropTypes.bool,
  color: PropTypes.string,
  title: PropTypes.string,
  ident: PropTypes.number,
  discount: PropTypes.bool,
  price: PropTypes.number,
};

VoucherTextBorder.defaultProps = {
  color: '',
  last: false,
  title: '',
  ident: 0,
  discount: false,
  price: 0,
};

export default VoucherTextBorder;
