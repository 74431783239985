import { createTheme } from '@mui/material/styles';
import typography from './variants/MuiTypography';
import button from './variants/MuiButton';
import textfield from './variants/MuiTextField';
import { azulTucar, grisTucar } from './variants/TucarColors';

const tucarTheme = createTheme({
  palette: {
    primary: {
      main: azulTucar,
      dark: azulTucar,
      grey: grisTucar,
      backgroundApp: '#F0F0F3',
    },
    secondary: {
      main: '#1F89FF',
    },
    error: {
      main: '#FF593F',
    },
  },

  components: {
    MuiTypography: typography,
    MuiButton: button,
    MuiTextField: textfield,
  },
});

export default tucarTheme;
