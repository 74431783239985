import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

import TableGenerator from '../TableGenerator';
import GoTo from '../../buttons/goTo';
import { azulTucar } from '../../../themes/variants/TucarColors';

const ResumeBox = ({ data, iconTitle, title, onClickButton, showButton }) => {
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: showButton ? 'space-between' : 'start',
          alignItems: 'center',
        }}
      >
        <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center', color: azulTucar }}>
          {iconTitle}
          <Typography sx={{ fontSize: '14px', fontFamily: 'Poppins-Medium', color: azulTucar }}>
            {title}
          </Typography>
        </Box>

        {showButton ? <GoTo text="Ver detalle" onClick={onClickButton} /> : null}
      </Box>
      <TableGenerator
        content={data}
        backgroundColor="#FFF"
        tableStyle={{
          container: { padding: '0px' },
        }}
        lastColor="black"
        lastSize="14px"
        lastFamily="Poppins-Medium"
      />
    </Box>
  );
};

ResumeBox.propTypes = {
  onClickButton: PropTypes.func,
  iconTitle: PropTypes.element.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  title: PropTypes.string.isRequired,
  showButton: PropTypes.bool,
};

ResumeBox.defaultProps = {
  onClickButton: () => {},
  showButton: true,
};

export default ResumeBox;
