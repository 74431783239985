import { gql } from '@apollo/client';

const REMOVE_BANK = {
  mutation: gql`
    mutation DeleteBankAccount($uid: String!) {
      deleteBankAccount(uid: $uid)
    }
  `,
};

export default REMOVE_BANK;
