import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useOutletContext } from 'react-router-dom';
import { useQuery, useReactiveVar } from '@apollo/client';
import moment from 'moment';

import { getAuthStore } from '../../../../graphql/store';
import TopBar from '../../../../components/ui/TopNav';
import { formatterCLP, formatterKm } from '../../../../helpers/formatters';
import GET_LIQUIDATIONS from '../../../../graphql/querys/balance/getLiquidations';
import LoadingProgress from '../../../../components/ui/LoadingProgress';
import Tabs from '../../../../components/ui/Tabs';
import LiquidationsBox from './liquidationBox';
import SubmitButton from '../../../../components/SubmitButton';

function transformLiquidation(elem) {
  const totalApps = elem.incomes.uber + elem.incomes.didi + elem.incomes.cabify + elem.incomes.beat;
  const totalExpenses = Object.values(elem.expenses).reduce((a, b) => {
    if (typeof b === 'number') {
      return a + b;
    }
    return a;
  }, 0);

  const kmWeek = elem.km.reduce((acc, curr) => acc + curr, 0);
  const now = moment();
  const input = moment(elem.date);
  const isThisWeek = now.isoWeek() === input.isoWeek();

  const totalExpensesWIncomes = totalExpenses + elem.installment + elem.debt;
  const totalIncomes = totalApps + elem.incomes.other + elem.prePay;
  return {
    title: 'Liquidación',
    amount: Math.abs(elem.balance),
    positive: elem.balance > 0,
    prePay: elem.prePay,
    debt: elem.debt,
    comment: moment(elem.date).format('DD-MM-YYYY'),
    date: moment(elem.date).format('DD/MM/YYYY'),
    status: elem.settled ? 'Pagado' : `${isThisWeek ? 'Pendiente' : 'Vencida'}`,
    incomes: [
      { right: 'Ingresos en Apps', left: formatterCLP.format(totalApps) },
      { right: 'Reembolsos', left: formatterCLP.format(elem.incomes.other) },
      { right: 'Pago por adelantado', left: formatterCLP.format(elem.prePay) },
      {
        right: 'Total',
        left: formatterCLP.format(totalIncomes),
      },
    ],
    expenses: [
      { right: 'Km recorridos', left: formatterKm.format(kmWeek) },
      { right: 'Costo del plan', left: formatterCLP.format(elem.expenses.rent) },
      { right: 'TAG', left: formatterCLP.format(elem.expenses.tag) },
      { right: 'Multas de tránsito', left: formatterCLP.format(elem.expenses.fines) },
      {
        right: 'Penalizaciones',
        left: formatterCLP.format(elem.expenses.penaltys),
        popText:
          'Cargos emitidos por incumplimiento de los Términos de Servicio durante el arriendo. Pueden ser por combustible, suciedad, atrasos, inasistencia a la cita de mantención, pieza faltante al auto, entre otros.',
      },
      { right: 'Garantía', left: formatterCLP.format(elem.expenses.guarantee) },
      { right: 'Deudas', left: formatterCLP.format(elem.debt) },
      {
        right: 'Otros cargos',
        left: formatterCLP.format(elem.expenses.other),
        popText: 'Ajustes en las liquidaciones y cobros de deducible, entre otros.',
      },
      {
        right: 'Cuotas',
        left: formatterCLP.format(elem.installment),
        popText: 'Cuotas pactadas de pago de garantía ó deducible',
      },
      {
        right: 'Total',
        left: formatterCLP.format(totalExpensesWIncomes),
      },
    ],
  };
}

const Liquidations = () => {
  const [handlePageChange] = useOutletContext();

  useEffect(() => {
    handlePageChange(
      <TopBar title="Liquidaciones" addTutorial linkTutorial="/app/tutorial/liquidations" />,
      true,
    );
  }, []);

  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);
  const { loading, data, error, refetch } = useQuery(GET_LIQUIDATIONS.query, {
    ...GET_LIQUIDATIONS.policies,
    variables: {
      uid,
    },
  });
  const tabs = [
    { value: 'pendient', label: 'Pendientes' },
    { value: 'paid', label: 'Pagadas' },
    { value: 'overdue', label: 'Vencidas' },
  ];
  const [content, setContent] = useState({});

  const [currentTab, setCurrentTab] = useState('pendient');
  const tabComponent = {
    pendient: <LiquidationsBox key="pendient" content={content.pendient} openModal />,
    paid: <LiquidationsBox key="paid" content={content.paid} />,
    overdue: <LiquidationsBox key="overdue" content={content.overdue} />,
  };

  useEffect(() => {
    if (data) {
      const auxData = data.getDriverBillWeek;
      const auxContent = auxData.map((liquidation) => {
        return transformLiquidation(liquidation);
      });

      const firstPendientIndex = auxContent.findIndex((elem) => elem.status === 'Pendiente');
      const auxPendientes =
        firstPendientIndex !== -1 ? auxContent.splice(firstPendientIndex, 1) : [];

      setContent({
        pendient: auxPendientes,
        paid: auxContent.filter((elem) => elem.status === 'Pagado'),
        overdue: auxContent.filter((elem) => elem.status === 'Vencida'),
      });
    }
  }, [data, loading]);

  if (loading) {
    return <LoadingProgress allScreen={false} heightContainer="100%" />;
  }

  if (error) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          height: '100%',
          padding: '20px',
          gap: '20px',
        }}
      >
        <Typography sx={{ fontFamily: 'Poppins-Regular', fontSize: '14px', textAlign: 'center' }}>
          Ocurrió un error al cargar tu información, intenta nuevamente ó contacta a soporte
          pinchando en el chat.
        </Typography>
        <Box sx={{ width: '50%' }}>
          <SubmitButton text="Recargar" handler={() => refetch()} />
        </Box>
      </Box>
    );
  }
  return (
    <Box sx={{ height: '100%', display: 'grid', gridTemplateRows: 'max-content 1fr' }}>
      <Tabs
        containerStyle={{ marginBottom: '20px', height: 'max-content' }}
        currentTabName={currentTab}
        setCurrentTabFunction={setCurrentTab}
        tabs={tabs}
      />
      <Box sx={{ overflow: 'auto', height: '100%' }}>{tabComponent[currentTab]}</Box>
    </Box>
  );
};

export default Liquidations;
