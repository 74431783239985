import { gql } from '@apollo/client';

const GET_CHARGING_STATIONS = {
  query: gql`
    query GetChargingStations($plugTypes: [String], $currentType: String, $status: String) {
      getChargingStations(plugTypes: $plugTypes, currentType: $currentType, status: $status) {
        provider {
          timezone
          name
          country
        }
        connectors {
          plugType
          ocppUpdatedAt
          ocppStatus
          maxCapacity
          currentType
          chargerPointId
          _id
        }
        lon
        lat
        addressAlias
        address
        _id
      }
    }
  `,
  policies: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: 'true',
    pollInterval: 10000,
  },
};

export default GET_CHARGING_STATIONS;
