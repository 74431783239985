import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, IconButton } from '@mui/material';
import moment from 'moment';
import { Card, Item, Icon, TucarTheme } from '@tucar/pit-stop';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LoopIcon from '@mui/icons-material/Loop';
import { azulTucar } from '../../../themes/variants/TucarColors';
import LoadingProgress from '../../ui/LoadingProgress';
import translateDocsName from '../../../helpers/translateDocsName';

function translateDocState(state) {
  if (state === 'Pending') {
    return (
      <Typography sx={{ color: '#FFDD00' }} fontSize="12px" fontFamily="Poppins-Medium">
        En revisión
      </Typography>
    );
  }
  if (state === 'Expired') {
    return (
      <Typography sx={{ color: '#D80000' }} fontSize="12px" fontFamily="Poppins-Medium">
        Vencido
      </Typography>
    );
  }
  if (state === 'Approved') {
    return (
      <Typography sx={{ color: '#95D31C' }} fontSize="14px" fontFamily="Poppins-Medium">
        Aprobado
      </Typography>
    );
  }
  return (
    <Typography sx={{ color: '#D80000' }} fontSize="12px" fontFamily="Poppins-Medium">
      Rechazado
    </Typography>
  );
}

const DocBox = ({ fileObj, handleUpload, carDocs }) => {
  return (
    <Card style={{ padding: 0, alignItems: 'unset' }}>
      <Box>
        {fileObj.loadingRefetch ? (
          <Box margin="20px 20px 20px 20px">
            <LoadingProgress allScreen={false} marginTop="0px" />
          </Box>
        ) : (
          <Box
            margin="20px"
            sx={{
              display: 'grid',
              gridTemplateColumns: 'max-content 1fr max-content',
              gap: '20px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Item style={{ height: '55px', width: '55px' }}>
                <Icon name="wallpaperOutlined" color={TucarTheme.colors.gray700} />
              </Item>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              {!carDocs && (
                <Typography
                  sx={{
                    textAlign: 'start',
                    fontFamily: 'Poppins-Medium',
                    fontSize: '14px',
                    color: '#585D71',
                  }}
                >
                  Subido el {fileObj.expire ? moment(fileObj.expire).format('DD/MM/YY') : '-'}
                </Typography>
              )}
              <Typography
                sx={{
                  textAlign: 'start',
                  fontFamily: 'Poppins-Medium',
                  fontSize: '16px',
                  color: azulTucar,
                  lineHeight: 1,
                }}
              >
                {translateDocsName(fileObj.name)}
              </Typography>
              {!carDocs && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {translateDocState(fileObj.status)}
                </Box>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              {fileObj.status === 'Approved' || fileObj?.carDocs ? null : (
                <IconButton
                  size="small"
                  component="label"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: '#000',
                    fontFamily: 'Poppins-Light',
                    fontSize: '12px',
                  }}
                >
                  <input
                    hidden
                    accept={fileObj.fileType}
                    onChange={(e) => {
                      handleUpload(e.target.files[0], fileObj.name, fileObj.id);
                    }}
                    type="file"
                  />
                  <LoopIcon size="small" sx={{ color: azulTucar }} />
                </IconButton>
              )}
              <IconButton
                size="small"
                type="submit"
                target="_blank"
                href={fileObj.url}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: '#000',
                  fontFamily: 'Poppins-Light',
                  fontSize: '12px',
                }}
              >
                <VisibilityIcon size="small" sx={{ color: azulTucar }} />
              </IconButton>
            </Box>
          </Box>
        )}
      </Box>
    </Card>
  );
};

DocBox.propTypes = {
  carDocs: PropTypes.bool,
  handleUpload: PropTypes.func,
  fileObj: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    expire: PropTypes.string,
    status: PropTypes.string,
    extension: PropTypes.string,
    url: PropTypes.string,
    loadingRefetch: PropTypes.bool,
    fileType: PropTypes.string,
    carDocs: PropTypes.bool,
  }).isRequired,
};

DocBox.defaultProps = {
  handleUpload: () => {},
  carDocs: false,
};

export default DocBox;
