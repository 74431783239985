import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Divider, Modal, IconButton, useMediaQuery } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useMutation, useReactiveVar } from '@apollo/client';
import { getAuthStore } from '../../../graphql/store';

import {
  azulTucar,
  negroTucar,
  grisTucar2,
  blancoTucar,
} from '../../../themes/variants/TucarColors';
import SubmitButton from '../../../components/SubmitButton';
import TravelerSection from '../../../components/info/TravelerSection';
import Emoji from '../../../components/extras/emojis';
import SEND_UBER_HERO from '../../../graphql/mutations/uber/uberHero';
import ErrorModal from '../../../components/ui/ErrorModal';

const styleLaptop = {
  position: 'fixed',
  width: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '15px',
  boxShadow: '0px 11px 22px #C1C1C1',
  pt: 2,
  px: 5,
  pb: 3,
  minHeight: 'max-content',
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '90vw',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '15px',
  boxShadow: '0px 11px 22px #C1C1C1',
  textAlign: 'right',
  pt: 2,
  px: 2,
  pb: 3,
  height: '80vh',
  minHeight: 'max-content',
};

const UberHeroModal = ({ open, handleClose }) => {
  const match = useMediaQuery('(max-width:700px)');
  const [success, setSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);
  const [sendUberHero, { loading, data }] = useMutation(
    SEND_UBER_HERO.mutation,
    SEND_UBER_HERO.policies,
  );

  const contactSuccess = async () => {
    try {
      await sendUberHero({ variables: { uid } });
    } catch (e) {
      setOpenError(true);
    }
  };

  useEffect(() => {
    if (!loading) {
      if (data) {
        setSuccess(true);
      }
    }
  }, [loading]);

  return (
    <Modal
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      hideBackdrop
      open={open}
    >
      <Box>
        <ErrorModal
          open={openError}
          onClose={() => {
            setOpenError(false);
          }}
          content="Ups, ha ocurrido un error de nuestra parte. Contacta a soporte pinchando en el chat"
          title="Error al solicitar asistencia"
        />
        <Box sx={match ? { ...style } : { ...styleLaptop }}>
          <IconButton sx={{ position: 'fixed', right: '10px', top: '10px' }} onClick={handleClose}>
            <ClearIcon color={grisTucar2} />
          </IconButton>

          <Box
            sx={{
              display: 'flex',
              height: '100%',
              maxWidth: '100%',
            }}
          >
            {success ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  gap: '3rem',
                }}
              >
                <Emoji symbol="✅" label="emoji" />
                <Typography
                  sx={{
                    fontFamily: 'Poppins-Medium',
                    fontSize: '1rem',
                    textAlign: 'center',
                    lineHeight: 1.2,
                    color: negroTucar,
                  }}
                >
                  Un ejecutivo de Tucar te contactará a la brevedad para crear tu cuenta de Uber
                </Typography>
              </Box>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'end',
                  justifyContent: 'center',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    gap: '1.2rem',
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: 'Poppins-SemiBold',
                      fontSize: '1rem',
                      color: negroTucar,
                      textAlign: 'center',
                    }}
                  >
                    ¡Mientras más viajes realices, obtendrás más ganancias!
                  </Typography>

                  <Divider
                    sx={{
                      width: '100%',
                      backgroundColor: blancoTucar,
                    }}
                  />

                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography
                      sx={{
                        fontFamily: 'Poppins-Medium',
                        fontSize: '1rem',
                        textAlign: 'center',
                        lineHeight: 1.2,
                      }}
                    >
                      Total de ganancias
                    </Typography>
                    <Typography
                      sx={{ fontFamily: 'Poppins-SemiBold', fontSize: '1rem', color: azulTucar }}
                    >
                      $240.000 CLP
                    </Typography>
                  </Box>
                  <Box
                    sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '0rem' }}
                  >
                    <TravelerSection step={25} money={48000} />
                    <TravelerSection step={50} money={120000} />
                    <TravelerSection step={100} money={240000} verticalLine={false} />
                  </Box>

                  <Divider
                    sx={{
                      width: '100%',
                      backgroundColor: blancoTucar,
                    }}
                  />

                  <Typography
                    sx={{
                      fontFamily: 'Poppins-Light',
                      fontSize: '0.875rem',
                      lineHeight: 1.2,
                      textAlign: 'left',
                    }}
                  >
                    * Recuerda que tienes 30 días corridos para completar los viajes y obtener la
                    bonificación.
                  </Typography>

                  <SubmitButton
                    disabled={loading}
                    text="Solicitar asistencia"
                    handler={contactSuccess}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

UberHeroModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default UberHeroModal;
