const translateCurrentTypeObj = {
  AC: 'Corriente Alterna',
  DC: 'Corriente Continua',
};

const translatePlugTypeObj = {
  Unknown: 'Desconocido',
};

const translateStatusObj = {
  Available: 'Disponible',
  Preparing: 'Preparando',
  Charging: 'Cargando',
  SuspendedEVSE: 'Suspendido por el cargador',
  SuspendedEV: 'Suspendido por el vehículo',
  Finishing: 'Finalizando',
  Reserved: 'Reservado',
  Faulted: 'Averiado',
  Unavailable: 'No disponible',
};

export const translateCurrentType = (currentType) => {
  return currentType.map((item) => translateCurrentTypeObj[item] || 'Desconocido');
};

export const translatePlugType = (plugType) => {
  return plugType.map((item) => translatePlugTypeObj[item] || item);
};

export const translateStatus = (status) => {
  return status.map((item) => translateStatusObj[item] || 'Desconocido');
};

export const translateOneStatus = (status) => {
  return translateStatusObj[status] || 'Desconocido';
};
