import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@tucar/pit-stop';
import { useReactiveVar, useMutation } from '@apollo/client';
import { Box } from '@mui/material';
import UPDATE_SHIFT_PETITION from '../../../../graphql/mutations/plan/updateShiftPetition';
import { getAuthStore } from '../../../../graphql/store';
import ErrorAlert from '../../../../components/v2/Alerts/Warnings';
import SuccessAlert from '../../../../components/v2/Alerts/Success';
import ConfirmAlert from '../../../../components/v2/Alerts/Confirm';

const UpdatePetition = ({ screenMode, petitionId, imPetitioner, onClose, onAccept }) => {
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);
  const [isError, setIsError] = useState(false);
  const [isDeletion, setIsDeletion] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [successDelete, setSuccessDelete] = useState(false);

  const [updatePetition, { data, loading, error }] = useMutation(
    UPDATE_SHIFT_PETITION.mutation,
    UPDATE_SHIFT_PETITION.policies,
  );

  const responsePetition = (accept) => {
    updatePetition({
      variables: {
        participantId: uid,
        petitionId,
        accepted: accept,
      },
    });
  };

  useEffect(() => {
    if (!loading && data) {
      if (data?.updateShiftChangePetition !== null) {
        if (imPetitioner) {
          setSuccessDelete(true);
        } else if (isDeletion) {
          onClose();
        } else {
          onAccept();
        }
      } else {
        setIsError(true);
      }
    }
    if (!loading && error) {
      setIsError(true);
    }
  }, [data, loading]);

  return (
    <>
      <ErrorAlert
        isOpen={isError}
        onClose={() => setIsError(false)}
        title={`Error al ${imPetitioner ? 'eliminar' : 'actualizar la'} solicitud`}
        msg={`Ocurrió un error al ${
          imPetitioner ? 'eliminar' : 'actualizar'
        } la solicitud de cambio de turno, intenta nuevamente ó contacta a soporte para reportar el error.`}
      />

      <SuccessAlert
        isOpen={successDelete}
        onClose={() => {
          setSuccessDelete(false);
          onClose();
        }}
        title="Solicitud eliminada"
        msg="El cambio de turno solicitado ha sido eliminado."
      />

      <ConfirmAlert
        isOpen={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        onAccept={() => {
          responsePetition(false);
          setConfirmDelete(false);
        }}
        title={imPetitioner ? 'Eliminar solicitud' : 'Rechazar solicitud'}
        msg={
          imPetitioner
            ? '¿Estás seguro de que deseas eliminar la solicitud de cambio de turno?'
            : 'Estás a punto de rechazar la solicitud enviada por tu compañero ¿estás seguro de esta acción?'
        }
      />

      {imPetitioner ? (
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
          <Button loading={loading} onClick={() => setConfirmDelete(true)}>
            Eliminar solicitud
          </Button>
        </Box>
      ) : (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: screenMode ? 'reverse-row' : 'row',
            justifyContent: 'space-evenly',
          }}
        >
          {loading ? (
            <Button loading={loading} />
          ) : (
            <>
              <Button
                onClick={() => {
                  responsePetition(true);
                  setIsDeletion(false);
                }}
              >
                Aceptar
              </Button>
              <Button
                variant={screenMode ? 'ghost' : 'primary'}
                onClick={() => {
                  setConfirmDelete(true);
                  setIsDeletion(true);
                }}
              >
                Rechazar
              </Button>
            </>
          )}
        </Box>
      )}
    </>
  );
};

UpdatePetition.propTypes = {
  petitionId: PropTypes.string,
  imPetitioner: PropTypes.bool,
  onClose: PropTypes.func,
  onAccept: PropTypes.func,
  screenMode: PropTypes.bool,
};

UpdatePetition.defaultProps = {
  petitionId: '',
  imPetitioner: false,
  onClose: () => {},
  onAccept: () => {},
  screenMode: false,
};

export default UpdatePetition;
