import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FeatherIcon from 'feather-icons-react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useReactiveVar } from '@apollo/client';
import { Box, Typography, Divider } from '@mui/material';
import { getDocsStore } from '../../../graphql/store';
import { azulTucar, rojoTucar } from '../../../themes/variants/TucarColors';

function translateOnboardingStep(type) {
  switch (type) {
    case 'info':
      return 'Completar información personal';
    case 'bank':
      return 'Agregar cuenta bancaria';
    case 'guarantee':
      return 'Pagar garantía';
    case 'meeting':
      return 'Agendar retiro del auto';
    case 'plan':
      return 'Elegir plan de arriendo';
    case 'app':
      return 'Vincular cuenta Uber';
    case 'docs':
      return 'Subir documentos y antecedentes';
    case 'referral':
      return 'Programa de referidos';
    default:
      return null;
  }
}

function docsStatusStep(statusDocs, setStepIcon, setSubtitleStatus) {
  switch (true) {
    case statusDocs.includes('Rejected'):
      setStepIcon(<HighlightOffIcon sx={{ fontSize: '30px', color: rojoTucar }} />);
      setSubtitleStatus({ text: 'Rechazado', color: rojoTucar });
      return null;
    case statusDocs.includes('Errors'):
      setStepIcon(<ErrorOutlineIcon sx={{ fontSize: '30px', color: '#D6983C' }} />);
      setSubtitleStatus({ text: 'Error en los documentos', color: '#D6983C' });
      return null;
    case statusDocs.includes('Pending'):
      setStepIcon(<AccessTimeIcon sx={{ fontSize: '30px', color: azulTucar }} />);
      setSubtitleStatus({ text: 'En revisión', color: azulTucar });
      return null;
    default:
      return null;
  }
}

const StepOnboardingComponent = ({ type, check, setStep }) => {
  const title = translateOnboardingStep(type);
  const docs = useReactiveVar(getDocsStore);
  const [subtitleStatus, setSubtitleStatus] = useState(
    check ? { text: 'Completado', color: '#95D31C' } : { text: 'Pendiente', color: '#7E7E7F' },
  );

  const [StepIcon, setStepIcon] = useState(
    <FeatherIcon
      icon={check ? 'check-circle' : 'alert-circle'}
      size="30px"
      stroke={check ? '#95D31C' : '#7E7E7F'}
    />,
  );

  useEffect(() => {
    if (type !== 'referral') {
      setSubtitleStatus(
        check ? { text: 'Completado', color: '#95D31C' } : { text: 'Pendiente', color: '#7E7E7F' },
      );
      setStepIcon(
        <FeatherIcon
          icon={check ? 'check-circle' : 'alert-circle'}
          size="30px"
          stroke={check ? '#95D31C' : '#7E7E7F'}
        />,
      );
    } else {
      setSubtitleStatus(
        check ? { text: 'Completado', color: '#95D31C' } : { text: 'Opcional', color: '#7E7E7F' },
      );
      setStepIcon(
        <FeatherIcon
          icon={check ? 'check-circle' : 'users'}
          size="30px"
          stroke={check ? '#95D31C' : '#7E7E7F'}
        />,
      );
    }
  }, [check]);

  useEffect(() => {
    if (type === 'docs' && !Object.values(docs).includes(false)) {
      const statusDocs = Object.values(docs).map((elem) => elem.status);
      docsStatusStep(statusDocs, setStepIcon, setSubtitleStatus);
    }
  }, []);

  if (!title) {
    return null;
  }

  return (
    <Box
      onClick={() => setStep(type)}
      sx={{
        padding: '5px',
        display: 'grid',
        gridTemplateColumns: 'max-content 1fr',
        gap: '20px',
        alignItems: 'center',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        {StepIcon}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box>
            <Typography sx={{ fontFamily: 'Poppins-Medium', fontSize: '14px', lineHeight: 1.2 }}>
              {title}
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Poppins-Regular',
                fontSize: '12px',
                color: subtitleStatus.color,
              }}
            >
              {subtitleStatus.text}
            </Typography>
          </Box>
          {type !== 'referral' && <FeatherIcon icon="chevron-right" size="16px" />}
        </Box>

        <Divider />
      </Box>
    </Box>
  );
};

StepOnboardingComponent.propTypes = {
  type: PropTypes.string,
  check: PropTypes.bool,
  setStep: PropTypes.func,
};

StepOnboardingComponent.defaultProps = {
  type: '',
  check: false,
  setStep: () => {},
};
export default StepOnboardingComponent;
