import { gql } from '@apollo/client';
import { setPlan } from '../../store';

const EDIT_DRIVER_PLAN = {
  mutation: gql`
    mutation EditDriverPlan(
      $driverId: ID!
      $planId: ID!
      $shiftId: ID
      $selectedShifts: [String]!
      $exchangeAddress: String
    ) {
      editPreference(
        driverId: $driverId
        planId: $planId
        shiftId: $shiftId
        exchangeAddress: $exchangeAddress
      ) {
        _id
      }
      editDriverPlanV2(
        driverId: $driverId
        planId: $planId
        selectedShifts: $selectedShifts
        exchangeAddress: $exchangeAddress
      ) {
        _id
        planId
        startDate
        selectedShifts {
          basePrice
          name
          endTime
          startTime
        }
        plan {
          electric
          exclusive
          name
          shifts {
            _id
          }
        }
      }
    }
  `,
  policies: {
    onCompleted: (response) => {
      if (response.editDriverPlanV2) {
        const { editDriverPlanV2 } = response;
        setPlan({ ...editDriverPlanV2 });
      }
    },
    errorPolicy: 'ignore',
  },
};

export default EDIT_DRIVER_PLAN;
