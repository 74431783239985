import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Typography } from '@tucar/pit-stop';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import DetailCard from './detailCard';

const defaultValues = {
  penaltys: 0,
  planCost: 0,
  tag: 0,
  trafficFines: 0,
  otherCharges: 0,
  deductible: 0,
  total: 0,
};

const dataFormatter = (data) => {
  const {
    expenses: { __typename, guarantee, ...restExpenses },
  } = data;
  const { fines, other, penaltys, rent, tag } = restExpenses;
  const totalExpenses = Object.values(restExpenses).reduce((accIn, current) => accIn + current, 0);

  return {
    penaltys: penaltys || 0,
    planCost: rent || 0,
    tag: tag || 0,
    trafficFines: fines || 0,
    otherCharges: other || 0,
    total: totalExpenses || 0,
  };
};

const OutcomeSection = ({ empty, data }) => {
  const { penaltys, planCost, tag, trafficFines, otherCharges, total } = empty
    ? defaultValues
    : dataFormatter(data);

  return (
    <Box
      sx={{
        width: '100%',
        paddingTop: '20px',
        gap: '10px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '10px',
          alignItems: 'center',
          paddingBottom: '16px',
        }}
      >
        <TrendingUpIcon sx={{ color: '#FF7B66', fontSize: '17px' }} />
        <Typography style={{ color: '#FF7B66', fontWeight: 600, fontSize: '16px' }}>
          Egresos
        </Typography>
      </Box>

      <DetailCard title="Sanciones por Tucar" total={penaltys || 0} mode="outcomes" />
      <DetailCard title="Costo del plan" total={planCost || 0} mode="outcomes" />
      <DetailCard title="TAG" total={tag || 0} mode="outcomes" />
      <DetailCard title="Multas de tránsito" total={trafficFines || 0} mode="outcomes" />
      <DetailCard title="Otros cargos" total={otherCharges || 0} mode="outcomes" />
      <DetailCard title="Total egresos" total={total || 0} remark mode="outcomes" />
    </Box>
  );
};

OutcomeSection.propTypes = {
  empty: PropTypes.bool,
  data: PropTypes.shape({}),
};

OutcomeSection.defaultProps = {
  empty: false,
  data: {},
};

export default OutcomeSection;
