import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Card, Icon, Button, Typography } from '@tucar/pit-stop';
import PressIconButton from '../../../../../components/buttons/PressIconButton';
import { formatterCLP } from '../../../../../helpers/formatters';
import { azulTucar } from '../../../../../themes/variants/TucarColors';

const EventCard = ({ eventInfo, setShowDetails }) => {
  return (
    <Card
      style={{
        position: 'relative',
        alignItems: 'unset',
        overflow: 'hidden',
      }}
    >
      <Box
        style={{
          content: '',
          height: '100%',
          width: '12px',
          borderRadius: '12px 0 0 12px',
          position: 'absolute',
          top: 0,
          left: 0,
          backgroundColor: '#1F89FF',
        }}
      />
      <Box sx={{ padding: '20px 28px 20px 30px', display: 'flex', flexDirection: 'column' }}>
        <Box
          sx={{
            display: 'grid',
            gap: '20px',
            gridTemplateColumns: 'max-content 1fr',
            alignItems: 'start',
          }}
        >
          <PressIconButton size="40px">
            <Icon color="#1F89FF" size="17px" name="calendarTodayOutlined" />
          </PressIconButton>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <Typography
              style={{
                color: '#71758E',
                fontSize: '13px',
                fontWeight: 500,
              }}
            >
              {moment(eventInfo.chargeDate, 'YYYY-MM-DD[T]HH:mm:ss').format('DD/MM/YYYY')}
            </Typography>

            <Typography
              style={{
                color: azulTucar,
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: 1.2,
              }}
            >
              {eventInfo.title}
            </Typography>

            <Typography
              style={{
                color: '#5B5D71',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: 1.2,
                overflow: 'scroll hidden',
              }}
            >
              {eventInfo.description}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            borderTop: '1px solid #C6C8D2',
            marginTop: '15px',
            display: 'flex',
            alignItems: 'end',
            justifyContent: 'center',
            gap: '50px',
          }}
        >
          <Box
            sx={{
              marginTop: '12px',
              width: 'min-content',
              padding: '6px 26px',
              borderRadius: '10px',
              border: '1px solid #D6E3F3',
              background:
                'linear-gradient(268deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.00) 52.74%), linear-gradient(270deg, #50CAFF 0%, #0478FF 100%)',
              boxShadow:
                '4px 4px 20px 0px rgba(111, 140, 176, 0.41), -6px -6px 20px 0px #FFF, 2px 2px 4px 0px rgba(114, 142, 171, 0.10)',
            }}
          >
            <Typography
              style={{
                color: '#FFF',
                fontSize: '14px',
                fontWeight: 500,
              }}
            >
              {formatterCLP.format(Math.ceil(eventInfo.amount))}
            </Typography>
          </Box>

          <Button
            style={{ padding: '12px' }}
            startAdornment={<Icon name="keyboardArrowRightRounded" size="24px" />}
            onClick={() => {
              setShowDetails(eventInfo);
            }}
          />
        </Box>
      </Box>
    </Card>
  );
};

EventCard.propTypes = {
  setShowDetails: PropTypes.func,
  eventInfo: PropTypes.shape({
    chargeDate: PropTypes.string,
    amount: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
  }),
};

EventCard.defaultProps = {
  setShowDetails: () => {},
  eventInfo: {
    chargeDate: '',
    amount: 0,
    title: '',
    description: '',
  },
};

export default EventCard;
