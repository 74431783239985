import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, TextField, InputAdornment } from '@mui/material';
import { CalendarToday } from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { format } from 'date-fns';
import esLocale from 'date-fns/locale/es';
import { useReactiveVar } from '@apollo/client';
import MultiSelectEditable from '../../../../components/ui/MultiSelectEditable';
import {
  getAuthStore,
  getDriverStore,
  getGlobalStore,
  setLoading,
} from '../../../../graphql/store';
import country from '../../../../helpers/bd/chile';
import SubmitButton from '../../../../components/SubmitButton';
import LocationInputField from '../../plan/auxComponents/LocationInputField';

export const getAge = (dateString) => {
  try {
    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age -= 1;
    }
    return age;
  } catch (er) {
    return 0;
  }
};

const BasicInfoForm = ({ executeMutation }) => {
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);
  const { info } = useReactiveVar(getDriverStore);
  const { loading } = useReactiveVar(getGlobalStore);
  const [loadingButton, setLoadingButton] = useState(false);
  const [values, setValues] = useState({
    ...info,
    uid,
  });
  const isEmptyObject = (obj) => {
    const basicDataCheck = ['address', 'adrnumber', 'birth', 'city', 'commune', 'region'];
    const aux = { ...obj };
    return basicDataCheck.every(
      (key) => aux[key] !== undefined && aux[key] !== null && aux[key] !== '',
    );
  };

  const regions = country;
  const provincias = !values.region
    ? []
    : country.filter((region) => region.name === values.region)[0].provincias;

  const communes = !values.city
    ? []
    : provincias.filter((citys) => citys.name === values.city)[0].comunas;

  useEffect(() => {
    if (uid) {
      setValues({ ...values, uid });
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [uid]);

  const handleChange = (name, event) => {
    if (name === 'region') {
      setValues((currentValues) => ({
        ...currentValues,
        city: '',
        commune: '',
        [name]: event,
      }));
    } else if (name === 'city') {
      setValues((currentValues) => ({
        ...currentValues,
        commune: '',
        [name]: event,
      }));
    } else {
      setValues((currentValues) => ({
        ...currentValues,
        [name]: event,
      }));
    }
  };

  const handleDateChange = (newValue) => {
    if (newValue) {
      newValue.setDate(newValue.getDate() + 1);
      const result = format(newValue, 'yyyy-MM-dd');
      setValues((currentValues) => ({
        ...currentValues,
        birth: result,
      }));
    }
  };

  const handleClick = () => {
    setLoadingButton(true);
    if (getAge(values.birth) >= 23 && values.region === 'Metropolitana de Santiago') {
      executeMutation(values);
    } else {
      executeMutation({
        uid,
        birth: values.birth,
        region: values.region,
        city: values.city,
        commune: values.commune,
        address: values.address,
        adrnumber: values.adrnumber,
        adrapt: values.adrapt,
      });
    }
  };

  function birthError() {
    if (getAge(info.birth) < 23 && info.birth === values.birth && values.birth) return true;
    return false;
  }

  function regionError() {
    if (
      values.region &&
      info.region !== 'Metropolitana de Santiago' &&
      values.region === info.region
    ) {
      return true;
    }
    return false;
  }

  return (
    <Box display="flex" justifyContent="center">
      <Box
        sx={{
          padding: '20px',
          width: '100%',
          maxWidth: '100vw',
        }}
      >
        {loading ? null : (
          <Box sx={{ paddingInline: '0px', display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <LocalizationProvider adapterLocale={esLocale} dateAdapter={AdapterDateFns}>
              <MobileDatePicker
                id="Fecha de nacimiento"
                label="Fecha de nacimiento"
                format="yyyy-MM-dd"
                value={values.birth}
                name="birth"
                renderInput={(params) => {
                  return (
                    <TextField
                      required
                      {...params}
                      error={birthError()}
                      helperText={birthError() ? 'Debes ser mayor de 22 años' : ''}
                      sx={{ margin: '0px', height: '50px' }}
                      InputLabelProps={{ style: { fontFamily: 'Poppins-Regular' } }}
                      InputProps={{
                        style: { fontFamily: 'Poppins-Regular' },
                        endAdornment: (
                          <InputAdornment position="end" sx={{ paddingRight: '10px' }}>
                            <CalendarToday />
                          </InputAdornment>
                        ),
                      }}
                    />
                  );
                }}
                onChange={handleDateChange}
              />
            </LocalizationProvider>
            <MultiSelectEditable
              handleChange={handleChange}
              label="Región"
              name="region"
              error={regionError()}
              textError="Lamentablemente no operamos en esa zona, solo RM."
              value={values.region}
              dataArr={regions}
              containerStyle={{ margin: '0px', paddingTop: birthError() ? '15px' : '0px' }}
            />
            <MultiSelectEditable
              handleChange={handleChange}
              label="Ciudad"
              name="city"
              value={values.city}
              dataArr={provincias}
              containerStyle={{ margin: '0px' }}
            />
            <MultiSelectEditable
              handleChange={handleChange}
              label="Comuna"
              name="commune"
              value={values.commune}
              dataArr={communes}
              containerStyle={{ margin: '0px' }}
            />

            <LocationInputField
              label="Dirección"
              placeholder="Dirección"
              name="address"
              style={{ flex: '1  1 100%', marginBottom: '0px', height: '30px' }}
              value={values.address || ''}
              selectorValue={values.address || ''}
              onChange={(newValue) => {
                handleChange('address', newValue);
              }}
            />

            <TextField
              required
              type="text"
              sx={{ margin: '0px', height: '50px' }}
              variant="outlined"
              label="Número"
              defaultValue={values.adrnumber}
              name="adrnumber"
              onChange={(e) => handleChange('adrnumber', e.target.value)}
              inputProps={{ style: { fontFamily: 'Poppins-Regular' } }}
              InputLabelProps={{ style: { fontFamily: 'Poppins-Regular' } }}
            />
            <TextField
              type="text"
              sx={{ margin: '0px', height: '50px' }}
              variant="outlined"
              id="Casa/depto"
              label="Casa/depto"
              defaultValue={values.adrapt}
              name="adrapt"
              onChange={(e) => handleChange('adrapt', e.target.value)}
              inputProps={{ style: { fontFamily: 'Poppins-Regular' } }}
              InputLabelProps={{ style: { fontFamily: 'Poppins-Regular' } }}
            />

            <SubmitButton
              disabled={loadingButton || !isEmptyObject(values)}
              handler={handleClick}
              text="Enviar"
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

BasicInfoForm.propTypes = {
  executeMutation: PropTypes.func.isRequired,
};

export default BasicInfoForm;
