import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Typography } from '@tucar/pit-stop';
import TopNav2 from '../../../../../components/ui/TopNav/newTopNav';
import { getEndOfWeek, getStartOfWeek } from '../auxFunctions';
import TagCard from '../../../../../components/v2/TagCard';
import getUniqueKey from '../../../../../helpers/customKey';
import EmptyView from '../../../../../components/extras/EmptyView';

const TagCards = ({ liquidationMode, selectedDate, data, setPage }) => {
  const tagList = data.reduce((acc, curr) => [...acc, ...curr.tag], []);

  if (tagList.length === 0) {
    return (
      <Box>
        <TopNav2
          title="TAGS"
          prevPage={liquidationMode ? '/app/v2/liquidations' : '/app/v2/balance'}
          handlePageChange={() => {
            setPage(null);
          }}
        />
        <EmptyView />
      </Box>
    );
  }

  return (
    <Box>
      <TopNav2
        title="TAGS"
        prevPage={liquidationMode ? '/app/v2/liquidations' : '/app/v2/balance'}
        handlePageChange={() => {
          setPage(null);
        }}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography
          style={{
            color: '#5B5D71',
            fontWeight: 400,
            lineHeight: 1.2,
            fontSize: '14px',
          }}
        >
          Periodo
        </Typography>
        <Typography
          style={{
            color: '#5B5D71',
            fontWeight: 500,
            lineHeight: 1.2,
            fontSize: '14px',
          }}
        >
          {getStartOfWeek(selectedDate)} al {getEndOfWeek(selectedDate)}
        </Typography>
      </Box>

      <Box
        sx={{
          paddingTop: '40px',
          paddingBottom: '40px',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          paddingInline: '30px',
        }}
      >
        {tagList.map((tagInfo) => (
          <TagCard key={getUniqueKey()} tagInfo={tagInfo} />
        ))}
      </Box>
    </Box>
  );
};

TagCards.propTypes = {
  selectedDate: PropTypes.instanceOf(Date),
  data: PropTypes.arrayOf(PropTypes.shape({})),
  setPage: PropTypes.func,
  liquidationMode: PropTypes.bool,
};

TagCards.defaultProps = {
  selectedDate: new Date(),
  data: [],
  setPage: () => {},
  liquidationMode: false,
};

export default TagCards;
