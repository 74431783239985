import { gql } from '@apollo/client';

const GET_DRIVER_GUARANTEE = {
  query: gql`
    query Guarantee($uid: ID!, $country: String!) {
      getConfigAccounting(country: $country) {
        guarantee {
          total
        }
      }
      getDriverAccounting(uid: $uid) {
        guarantee {
          total
          amount
          tucarContribution
          origin {
            driver
            tucar
          }
        }
      }
    }
  `,
  policies: {},
};

export default GET_DRIVER_GUARANTEE;
