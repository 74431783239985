import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Typography } from '@mui/material';
import {
  LocalGasStationOutlined,
  Battery4BarOutlined,
  DirectionsCarOutlined,
  ColorLensOutlined,
  EventOutlined,
  EvStation,
} from '@mui/icons-material';
import { useQuery } from '@apollo/client';

import TableGenerator from '../../../../components/info/TableGenerator';
import IconInfoBox from '../../../../components/info/IconInfoBox';
import { celesteTucar, azulTucar } from '../../../../themes/variants/TucarColors';
import autito from '../../../../../public/images/cars/autoPromedio.png';

import { OLD_GET_CAR_CONFIG } from '../../../../graphql/querys/plan/getCarConfig';
import LoadingProgress from '../../../../components/ui/LoadingProgress';
import electricTransmissionIcon from '../../../../../public/images/customIcons/electric/cambios_linea.svg';
import automaticTransmissionIcon from '../../../../../public/images/customIcons/automatic/cambios_linea.svg';
import automaticSpeedIcon from '../../../../../public/images/customIcons/automatic/Rendimiento_linea.svg';
import { plateFormatterHelper } from '../../../../helpers/plateFormatterHelper';

const CarSheetInfoCard = ({ carId }) => {
  const [typeCar, setTypeCar] = useState('gasoline');
  const { loading, data } = useQuery(OLD_GET_CAR_CONFIG.query, {
    ...OLD_GET_CAR_CONFIG.policies,
    variables: {
      carId,
    },
  });

  const [styleProps, setStyleProps] = useState({
    color: azulTucar,
    fuelText: 'Bencina',
    transmissionIcon: automaticTransmissionIcon,
    fuelIcon: <LocalGasStationOutlined sx={{ color: azulTucar }} />,
    chargeIcon: (
      <img
        src={automaticSpeedIcon}
        alt="category"
        style={{
          width: '24px',
        }}
      />
    ),
  });

  const [content, setContent] = useState([]);

  useEffect(() => {
    if (data && data.car.carConfig) {
      setContent([
        { right: 'Patente', left: plateFormatterHelper(data.car.info.plate) },
        { right: 'Motor', left: `${data.car.carConfig.motor} lt` },
        { right: 'Capacidad', left: `${data.car.carConfig.capacity} lt` },
      ]);

      if (data.car.carConfig.fuel !== 'gasoline') {
        setTypeCar('electric');
        setStyleProps({
          color: celesteTucar,
          fuelText: 'Eléctrico',
          transmissionIcon: electricTransmissionIcon,
          fuelIcon: <EvStation sx={{ color: celesteTucar }} />,
          chargeIcon: <Battery4BarOutlined sx={{ color: celesteTucar }} />,
        });
      }
    }
  }, [data]);

  if (!data || loading) {
    return (
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <LoadingProgress allScreen={false} heightContainer="100%" />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        height: '100%',
        padding: '10px 20px 20px 20px',
        display: 'grid',
        gridTemplateRows: 'max-content 1fr',
        gap: '60px',
        overflowY: 'auto',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            variant="span"
            sx={{ textAlign: 'left', display: 'inline-block', lineHeight: 1, fontSize: '18px' }}
            fontFamily="Poppins-Medium"
          >
            {data.car.carConfig.brand}
          </Typography>
          <Typography
            variant="span"
            sx={{
              color: styleProps.color,
              textAlign: 'left',
              display: 'inline-block',
              lineHeight: 1,
              fontSize: '18px',
            }}
            fontFamily="Poppins-Medium"
          >
            {data.car.carConfig.model}
          </Typography>
        </Box>
        <Stack
          sx={{ textAlign: 'center' }}
          gap="5px"
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <ColorLensOutlined sx={{ color: '#808080', fontSize: '16px' }} />
            <Typography
              sx={{
                color: '#808080',
                fontFamily: 'Poppins-Light',
                fontSize: '12px',
                lineHeight: 1,
                textAlign: 'baseline',
              }}
            >
              Color {data.car.info.color}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <EventOutlined sx={{ verticalAlign: 'center', color: '#808080', fontSize: '16px' }} />
            <Typography
              sx={{
                color: '#808080',
                fontFamily: 'Poppins-Light',
                fontSize: '12px',
                lineHeight: 1,
              }}
            >
              {`Año ${data.car.carConfig.year}`}
            </Typography>
          </Box>
        </Stack>
      </Box>

      <Box sx={{ paddingTop: '30px' }}>
        <Box sx={{ position: 'relative' }}>
          <Box sx={{ width: '100%', position: 'absolute', transform: 'translateY(-60%)' }}>
            <img
              src={data.car.carConfig.image ? data.car.carConfig.image : autito}
              alt="category"
              style={{ width: '100%', height: '150px' }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor: '#F3F3F3',
            borderRadius: '15px',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          <Typography
            sx={{
              padding: '30px 20px 0px 20px',
              fontFamily: 'Poppins-Medium',
              fontSize: '16px',
              color: '#333333',
            }}
          >
            Especificaciones
          </Typography>

          <Box
            sx={{
              justifyContent: 'center',
              display: 'grid',
              borderRadius: '15px',
              paddingInline: '20px',
              gap: '13px',
              gridAutoFlow: 'column',
              gridTemplateRows: 'repeat(2,1fr)',
              gridTemplateColumns: 'repeat(2,1fr)',
            }}
          >
            <IconInfoBox icon={styleProps.fuelIcon} title={styleProps.fuelText} />
            <IconInfoBox
              icon={
                <img
                  src={styleProps.transmissionIcon}
                  alt="category"
                  style={{
                    width: '24px',
                  }}
                />
              }
              title={`${data.car.carConfig.transmission}`}
            />
            <IconInfoBox
              icon={styleProps.chargeIcon}
              title={`${data.car.carConfig.performance} ${typeCar === 'gasoline' ? 'km/lt' : 'km'}`}
            />
            <IconInfoBox
              icon={<DirectionsCarOutlined sx={{ color: styleProps.color }} />}
              title={data.car.carConfig.category}
            />
          </Box>
          <TableGenerator
            content={content}
            tableStyle={{
              tableRow: { height: '30px', color: '#333333' },
              tableCell: { verticalAlign: 'center', fontSize: '12px' },
              container: {
                padding: '0px 20px 20px 20px',
                marginBottom: '20px',
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

CarSheetInfoCard.propTypes = {
  carId: PropTypes.string.isRequired,
};

export default CarSheetInfoCard;
