import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

const GraphLabel = ({ opacityBlue, opacityRed }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '5px',
        marginTop: '10px',
        alignItems: 'center',
        justifyContent: 'end',
        paddingRight: '30px',
      }}
    >
      <Box
        sx={{
          opacity: opacityBlue ? 0.5 : 1,
          paddingInline: '8px',
          backgroundColor: '#2189FF',
          borderRadius: '4px',
        }}
      >
        <Typography
          sx={{
            color: '#FFF',
            fontSize: '10px',
            fontWeight: 600,
            textTransform: 'uppercase',
            fontFamily: 'Poppins-Regular',
          }}
        >
          Ingresos del conductor
        </Typography>
      </Box>
      <Box
        sx={{
          opacity: opacityRed ? 0.5 : 1,
          paddingInline: '8px',
          backgroundColor: '#FF593F',
          borderRadius: '4px',
        }}
      >
        <Typography
          sx={{
            color: '#FFF',
            fontSize: '10px',
            fontWeight: 600,
            textTransform: 'uppercase',
            fontFamily: 'Poppins-Regular',
          }}
        >
          Egresos
        </Typography>
      </Box>
    </Box>
  );
};

GraphLabel.propTypes = {
  opacityBlue: PropTypes.bool,
  opacityRed: PropTypes.bool,
};

GraphLabel.defaultProps = {
  opacityBlue: false,
  opacityRed: false,
};

export default GraphLabel;
