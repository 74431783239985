import { styled } from '@mui/material/styles';
import { Autocomplete, TextField } from '@mui/material';
import { azulTucar } from '../../../../themes/variants/TucarColors';

const getHeightValue = (props) => {
  const DEFAULT = '50px';
  const customValue = props.style?.MuiOutlinedStyles?.height;
  const isMultiple = props.multiple || false;

  if (isMultiple) {
    return customValue && props.value?.length ? customValue : DEFAULT;
  }
  return customValue || DEFAULT;
};

export const StyledSelector = styled(Autocomplete)((props) => ({
  '& input:valid + fieldset': {
    borderColor: azulTucar,
  },
  '&:hover fieldset': {
    borderColor: azulTucar,
    borderWidth: 2,
  },
  '& .MuiInputBase-input': {
    fontSize: '14px',
  },
  '&.Mui-disabled fieldset': {
    borderColor: '#ccc !important',
  },
  '& .MuiOutlinedInput-root': {
    fontFamily: 'Poppins-Regular',
    height: getHeightValue(props),
    '&.Mui-disabled:hover fieldset': {
      borderColor: '#ccc',
      borderWidth: 1,
    },
    '&.Mui-disabled fieldset': {
      borderColor: '#ccc',
    },
    '&:hover fieldset': {
      borderColor: azulTucar,
    },
    '&.Mui-focused fieldset': {
      borderColor: azulTucar,
    },
  },
}));

export const AutocompleteInput = styled(TextField)({
  '& label': {
    fontFamily: 'Poppins-Regular',
    fontSize: '14px',
  },
  '& label.Mui-focused': {
    color: azulTucar,
  },
  '& label.Mui-focused.Mui-error ': {
    color: '#d32f2f',
  },
  '& fieldset': {
    borderColor: azulTucar,
  },
  '& input:valid + fieldset': {
    borderColor: azulTucar,
  },
  '&:hover fieldset': {
    borderColor: azulTucar,
    borderWidth: 2,
  },
  '& .MuiInputBase-input': {
    fontSize: '14px',
  },
  '&.Mui-disabled :hover fieldset': {
    borderColor: '#ccc !important',
    borderWidth: 1,
  },
  '& .MuiFormHelperText-root': {
    borderColor: '#d32f2f',
  },
  '& .MuiOutlinedInput-root': {
    fontFamily: 'Poppins-Regular',
    height: '50px',
    '&.Mui-disabled:hover fieldset': {
      borderColor: '#ccc',
      borderWidth: 1,
    },
    '&:hover fieldset': {
      borderColor: azulTucar,
    },
    '&.Mui-error:hover fieldset': {
      borderColor: '#d32f2f',
    },
    '&.Mui-focused fieldset': {
      borderColor: azulTucar,
    },
    '&.Mui-error :hover fieldset': {
      borderColor: '#d32f2f',
    },
    '&.Mui-focused.Mui-error fieldset': {
      borderColor: '#d32f2f',
    },
  },
});
