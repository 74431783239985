import { gql } from '@apollo/client';

const GET_LIQUIDATIONS = {
  query: gql`
    query GetDriverBillWeek($uid: ID!) {
      getDriverBillWeek(uid: $uid) {
        _id
        date
        km
        expenses {
          fines
          penaltys
          tag
          other
          guarantee
          rent
        }
        incomes {
          uber
          didi
          cabify
          beat
          other
        }
        installment
        debt
        balance
        payBill
        settled
        prePay
      }
    }
  `,
  policies: {
    fetchPolicy: 'no-cache',
  },
};

export default GET_LIQUIDATIONS;
