import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { IconButton, Typography } from '@tucar/pit-stop';
import esLocale from 'date-fns/locale/es';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { azulTucar } from '../../../../../themes/variants/TucarColors';
import { getStartOfWeek, getEndOfWeek } from '../../../../../helpers/dateFormat';

const TopBarDashboard = ({ setSelectedDate, setOpenSide, selectedDate }) => {
  const navigate = useNavigate();

  const [openCalendar, setOpenCalendar] = useState(false);

  return (
    <Box
      sx={{
        paddingTop: '50px',
        gap: '10px',
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <IconButton
        onClick={() => setOpenSide(true)}
        style={{ backgroundColor: '#FFF' }}
        iconProps={{ color: azulTucar, name: 'menuOutlined' }}
      />
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          gap: '12px',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <Typography color={azulTucar} style={{ fontSize: '14px', fontWeight: 600 }}>
          {getStartOfWeek(selectedDate, 'DD/MM')} - {getEndOfWeek(selectedDate, 'DD/MM')}
        </Typography>
        <IconButton
          style={{ backgroundColor: '#E3EDF7' }}
          iconProps={{ color: azulTucar, name: 'calendarTodayOutlined', size: '18px' }}
          onClick={() => setOpenCalendar(true)}
        />
      </Box>
      <IconButton
        onClick={() => navigate('/app/v2/notifications', { replace: true })}
        variant="ghost"
        iconProps={{ name: 'notificationsNoneOutlined', color: '#17171C' }}
      />

      <LocalizationProvider adapterLocale={esLocale} dateAdapter={AdapterDateFns}>
        <MobileDatePicker
          fullWidth
          disableOpenPicker
          open={openCalendar}
          disableFuture
          componentsProps={{
            actionBar: {
              actions: [],
            },
          }}
          value={selectedDate}
          showDaysOutsideCurrentMonth
          renderInput={() => {
            return null;
          }}
          onChange={(date) => {
            setSelectedDate(date);
            setOpenCalendar(false);
          }}
        />
      </LocalizationProvider>
    </Box>
  );
};

TopBarDashboard.propTypes = {
  setSelectedDate: PropTypes.func,
  setOpenSide: PropTypes.func,
  selectedDate: PropTypes.instanceOf(Date),
};

TopBarDashboard.defaultProps = {
  setSelectedDate: () => {},
  setOpenSide: () => {},
  selectedDate: new Date(),
};

export default TopBarDashboard;
