/* eslint-disable import/prefer-default-export */
import { makeVar } from '@apollo/client';

export const docsStore = makeVar({
  cedulaIdentidadFront: false,
  cedulaIdentidadBack: false,
  certificadoAntecedentes: false,
  hojaVida: false,
  licenciaConducirFront: false,
  licenciaConducirBack: false,
});
