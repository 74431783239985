import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Box, Button, ClickAwayListener } from '@mui/material';
import { Card, Typography, Icon } from '@tucar/pit-stop';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { formatterCLP } from '../../../helpers/formatters';
import { azulTucar } from '../../../themes/variants/TucarColors';
import PressIconButton from '../../../components/buttons/PressIconButton';
import InfoModal from '../../../components/info/infoModal';
import VoucherTextBorder from './voucherTextBorder';
import getUniqueKey from '../../../helpers/customKey';

const VoucherBox = ({ data }) => {
  const { _id: voucherId, url, folio, expirationDate, totals, sentDate, details, discounts } = data;
  const price = totals?.totalAmount || 0;
  const [openModal, setOpenModal] = useState(false);

  const contentModal = (
    <ClickAwayListener onClickAway={() => setOpenModal(false)}>
      <Box
        sx={{
          padding: '20px 10px',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {details.map((detail, index) => (
          <VoucherTextBorder
            key={getUniqueKey()}
            color={azulTucar}
            title={detail.name}
            last={index === details.length - 1 && discounts.length === 0}
            ident={index}
            price={detail.price}
          />
        ))}
        {discounts.map((discount, index) => (
          <VoucherTextBorder
            key={getUniqueKey()}
            color="#FF593F"
            title={discount.title}
            last={index === discounts.length - 1}
            ident={index}
            price={discount.amount}
            discount
          />
        ))}
        <Button
          size="small"
          type="submit"
          target="_blank"
          href={url}
          sx={{
            width: 'max-content',
            minWidth: 'unset',
            marginTop: '30px',
            padding: '12px',
            borderRadius: '10px',
            background: '#E3EDF7',
            boxShadow: '4px 4px 20px 0px rgba(111, 140, 176, 0.41), -6px -6px 20px 0px #FFF',
          }}
        >
          <Typography style={{ color: '#5B5D71', fontSize: '16px', fontWeight: 600 }}>
            Descargar boleta
          </Typography>
        </Button>
      </Box>
    </ClickAwayListener>
  );
  if (voucherId === '') {
    return null;
  }

  return (
    <>
      <InfoModal open={openModal} content={contentModal} />
      <Card
        key={voucherId}
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          position: 'relative',
          borderRadius: '14px',
        }}
      >
        <Box
          sx={{
            padding: '26px 12px 26px 25px',
            width: '100%',
            '::before': {
              content: '""',
              position: 'absolute',
              height: '100%',
              width: '11px',
              borderTopLeftRadius: '14px',
              borderBottomLeftRadius: '14px',
              left: 0,
              top: 0,
              background: '#1F89FF',
            },
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '15px' }}>
            <PressIconButton size="40px">
              <Icon name="gpsFixedOutlined" color="#0057B8" size="17px" />
            </PressIconButton>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
              <Typography
                style={{
                  fontSize: '14px',
                  color: '#71758E',
                  fontWeight: 500,
                }}
              >
                {moment(sentDate).format('DD/MM/YYYY')}
              </Typography>

              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                  style={{
                    color: azulTucar,
                    fontSize: '16px',
                    fontWeight: 500,
                    lineHeight: '1.2',
                  }}
                >
                  Folio #{folio}
                </Typography>
              </Box>
              <Typography
                style={{
                  color: '#5B5D71',
                  fontSize: '14px',
                }}
              >
                Fecha expiración {moment(expirationDate).format('DD/MM/YYYY')}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              width: '100%',
              borderTop: '1px solid #C6C8D2',
              margin: '10px 0px',
            }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
            <Box
              sx={{
                padding: '12px 20px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '10px',
                position: 'relative',
                gap: '5px',
                boxShadow:
                  '4px 4px 20px 0px rgba(111, 140, 176, 0.41), -6px -6px 20px 0px #FFF, 2px 2px 4px 0px rgba(114, 142, 171, 0.10)',
                '::before': {
                  content: '""',
                  zIndex: 0,
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  borderRadius: '10px',
                  left: 0,
                  top: 0,
                  background: 'linear-gradient(300deg, #FFFFFF 20%, #C1D9F4 100%)',
                },
                '::after': {
                  content: '""',
                  position: 'absolute',
                  zIndex: 0,
                  top: '1px',
                  bottom: '1px',
                  left: '1px',
                  right: '1px',
                  borderRadius: '9px',
                  background:
                    'linear-gradient(268deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.00) 52.74%), linear-gradient(270deg, #50CAFF 0%, #0478FF 100%)',
                },
              }}
            >
              <Typography
                style={{
                  zIndex: 1,
                  color: '#FFF',
                  fontSize: '14px',
                  fontWeight: 500,
                }}
              >
                $
              </Typography>
              <Typography
                style={{
                  zIndex: 1,
                  color: '#FFF',
                  fontSize: '14px',
                  fontWeight: 500,
                }}
              >
                {formatterCLP.format(price).replace('$', '')}
              </Typography>
            </Box>
            <Button
              size="small"
              onClick={() => setOpenModal(true)}
              sx={{
                width: 'min-content',
                minWidth: 'unset',
                padding: '12px',
                borderRadius: '10px',
                background: '#E3EDF7',
                boxShadow: '4px 4px 20px 0px rgba(111, 140, 176, 0.41), -6px -6px 20px 0px #FFF',
              }}
            >
              <VisibilityIcon sx={{ color: '#5B5D71' }} />
            </Button>
            <Button
              size="small"
              type="submit"
              target="_blank"
              href={url}
              sx={{
                width: 'min-content',
                minWidth: 'unset',
                padding: '12px',
                borderRadius: '10px',
                background: '#E3EDF7',
                boxShadow: '4px 4px 20px 0px rgba(111, 140, 176, 0.41), -6px -6px 20px 0px #FFF',
              }}
            >
              <Icon name="getAppRounded" size="20px" color="#5B5D71" />
            </Button>
          </Box>
        </Box>
      </Card>
    </>
  );
};

VoucherBox.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string,
    url: PropTypes.string,
    folio: PropTypes.string,
    expirationDate: PropTypes.string,
    totals: PropTypes.shape({
      totalAmount: PropTypes.number,
    }),
    sentDate: PropTypes.string,
    details: PropTypes.arrayOf(PropTypes.shape({})),
    discounts: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

VoucherBox.defaultProps = {
  data: {
    _id: '',
    url: '',
    folio: '',
    expirationDate: '',
    totals: {
      totalAmount: 0,
    },
    sentDate: '',
    details: [],
    discounts: [],
  },
};

export default VoucherBox;
