import React from 'react';
import PropTypes from 'prop-types';
import { Card, Icon, Typography } from '@tucar/pit-stop';
import PressIconButton from '../../../../components/buttons/PressIconButton';

import { azulTucar } from '../../../../themes/variants/TucarColors';
import { formatterCLP } from '../../../../helpers/formatters';

const CardButton = ({ icon, label, total, onClick, disabled }) => {
  const shadowStyle = total > 0 && !disabled ? {} : { boxShadow: 'none' };
  return (
    <Card
      onClick={total > 0 && !disabled ? onClick : null}
      style={{ ...shadowStyle, padding: '16px', width: '150px', minHeight: '150px' }}
    >
      <PressIconButton>
        <Icon color={azulTucar} size="20px" name={icon} />
      </PressIconButton>
      <Typography
        align="center"
        style={{
          paddingInline: '18px',
          paddingTop: '8px',
          fontSize: '13px',
          fontWeight: 500,
          color: '#2D2F39',
          lineHeight: 1.2,
          paddingBottom: '8px',
        }}
      >
        {label}
      </Typography>
      <Typography
        align="center"
        style={{
          fontSize: '18px',
          fontWeight: 600,
          color: azulTucar,
          lineHeight: 1.2,
        }}
      >
        {formatterCLP.format(total)}
      </Typography>
    </Card>
  );
};

CardButton.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
  total: PropTypes.number,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

CardButton.defaultProps = {
  icon: '',
  label: '',
  total: 0,
  onClick: () => {},
  disabled: false,
};

export default CardButton;
