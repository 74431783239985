import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { useReactiveVar, useQuery } from '@apollo/client';

import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import ReactSwipeButton from 'react-swipe-button';

import TopBar from '../../../components/ui/TopNav';

import Map from './Map';
import CardButtonDash from '../../../components/buttons/CardButtonDash';
import {
  getAuthStore,
  getExchangeStore,
  setCompleteForm,
  setReceived,
} from '../../../graphql/store';
import LoadingProgress from '../../../components/ui/LoadingProgress';

import ParnerCarCard from './ParnerCarInfo';
import ModalExchange from '../ModalExchange';
import formatGetExchangeData from './auxFunctions';
import './swipeStyle.css';

import GET_EXCHANGE from '../../../graphql/querys/exchange/getExchange';
import LockUnlockCarButton from '../../../components/buttons/LockUnlockCar';

const ManageShift = () => {
  const [first, setFirst] = useState(true);
  const [handlePageChange] = useOutletContext();
  const matches = useMediaQuery('(max-width:300px)');
  const today = moment().day();
  const [content, setContent] = useState();
  const [open, setOpen] = useState(false);
  const [loadingButton, setLoadingButton] = useState(true);
  const [turnShiftButton, setTurnShiftButton] = useState();
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);
  const { received } = useReactiveVar(getExchangeStore);

  const navigate = useNavigate();
  const { data, loading, refetch } = useQuery(GET_EXCHANGE.query, {
    ...GET_EXCHANGE.policies,
    variables: {
      driverId: uid,
      limit: 1,
      offset: 0,
    },
  });

  useEffect(() => {
    if (content && received === null) {
      setReceived(content?.im === 'Receiver');
    }
  }, [content]);

  useEffect(() => {
    if (loading) {
      setLoadingButton(true);
    }

    if (!loading && data?.getExchanges?.instances) {
      const currentInstance = data?.getExchanges?.instances.filter(
        (elem) => elem.status === 'Started' || elem.status === 'Pending',
      );

      if (!loading && currentInstance.length > 0) {
        const auxData = currentInstance[0];
        setLoadingButton(false);
        setContent(formatGetExchangeData(auxData, (today + 6) % 7, uid));
      } else if (currentInstance.length === 0) {
        if (received !== null) {
          setCompleteForm(true);
        }
        setLoadingButton(false);
        navigate('/app/manageshare', { replace: true });
      }
    }
  }, [data, loading]);

  useEffect(() => {
    handlePageChange(<TopBar title="Administrar turno" />, true);
  }, []);

  useEffect(() => {
    if (!open) {
      setTurnShiftButton(
        <ReactSwipeButton
          preventScrollOnSwipe
          trackTouch
          text={content?.swipeButton.before}
          text_unlocked={content?.swipeButton.after}
          color={content?.color}
          onSuccess={() => {
            setOpen(true);
          }}
        />,
      );
    } else {
      setTurnShiftButton();
    }
  }, [open, content]);

  const timerContent = (
    <Box
      sx={{
        display: 'flex',
        alignSelf: 'baseline',
        flexDirection: 'column',
        gap: '5px',
        marginTop: '5px',
      }}
    >
      <Typography
        sx={{
          textAlign: 'left',
          fontSize: '13px',
          lineHeight: 1,
          fontFamily: 'Poppins-SemiBold',
          color: '#7ecddf',
        }}
      >
        {content?.infoCard.title}
      </Typography>
      <Typography
        sx={{
          textAlign: 'left',
          fontSize: '12px',
          lineHeight: 1,
          fontFamily: 'Poppins-Regular',
          color: '#757575',
        }}
      >
        {content?.infoCard.content}
      </Typography>
    </Box>
  );

  if (loading || !content) {
    return <LoadingProgress allScreen={false} heightContainer="100%" />;
  }

  return (
    <Box
      sx={{
        paddingInline: '20px',
        paddingBottom: '50px',
        height: '100%',
        minHeight: '100%',
        width: '100%',
        display: 'grid',
        gap: '10px',
        gridTemplateRows: 'max-content 1fr max-content',
      }}
    >
      <ModalExchange
        open={open}
        setOpen={setOpen}
        data={content}
        first={first}
        setFirst={setFirst}
        refetchData={refetch}
        loadingButton={loadingButton}
      />
      <Box>
        <LockUnlockCarButton />

        <Typography
          sx={{
            marginTop: '10px',
            height: '100%',
            verticalAlign: 'middle',
            fontFamily: 'Poppins-Medium',
            color: content.color,
          }}
        >
          {content?.title}
        </Typography>
      </Box>

      <Map location={content.map.location} address={content.map.address} color={content.color} />

      <Box xs={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
        <Box
          sx={{
            paddingTop: '10px',
            display: 'flex',
            gap: '10px',
            flexDirection: matches ? 'column' : 'row',
          }}
        >
          <CardButtonDash
            contentDirection="column"
            toGo={false}
            IconElement={TimerOutlinedIcon}
            styleProps={{ container: { width: matches ? '100%' : '50%' } }}
            colors={{ secondary: '#eef5f7', primary: '#7ecdde' }}
            content={timerContent}
            iconSize="2.5rem"
          />

          <Box
            sx={{
              width: matches ? '100%' : '50%',
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              overflowX: 'scroll',
              backgroundColor: '#dbe6f0',
              padding: '10px',
              borderRadius: '15px',
              justifyContent: 'center',
            }}
          >
            <ParnerCarCard car={content?.contactCard?.car} contactCard={content?.contactCard} />
          </Box>
        </Box>

        <Box sx={{ paddingTop: '10px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
          {turnShiftButton}
        </Box>
      </Box>
    </Box>
  );
};

export default ManageShift;
