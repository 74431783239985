import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  MobileStepper,
  Button,
  Box,
  Typography,
  Divider,
  Modal,
  useMediaQuery,
  IconButton,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

import { negroTucar, grisTucar2, blancoTucar } from '../../../themes/variants/TucarColors';

const styleLaptop = {
  position: 'fixed',
  width: '50%',
  alignItems: 'rigth',
  height: 'max-content',
  bgcolor: 'background.paper',
  borderRadius: '15px',
  boxShadow: '0px 11px 22px #C1C1C1',
  bottom: '20%',
  pt: 2,
  px: 5,
  pb: 3,
};

const style = {
  position: 'absolute',
  bottom: '2%',
  left: '50%',
  maxWidth: '100vw',
  width: '90vw',
  height: 'max-content',
  bgcolor: 'background.paper',
  borderRadius: '15px',
  boxShadow: '0px 11px 22px #C1C1C1',
  textAlign: 'right',
  pt: 2,
  px: 2,
  pb: 3,
};

const TutorialModal = ({
  redirectPrev,
  redirectNext,
  position,
  activeStep,
  numSteps,
  open,
  title,
  content,
  prev,
  next,
  exitLink,
}) => {
  const navigate = useNavigate();

  const match = useMediaQuery('(max-width:700px)');
  return (
    <Modal
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      hideBackdrop
      open={open}
    >
      <Box>
        <Box sx={match ? { ...style, ...position } : { ...styleLaptop, ...position }}>
          <IconButton
            sx={{ position: 'fixed', right: '0px', top: '0px' }}
            onClick={() => navigate(exitLink, { replace: true })}
          >
            <ClearIcon color={grisTucar2} />
          </IconButton>
          <Box
            sx={{
              display: 'flex',
              maxWidth: '100%',
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  gap: '0.5rem',
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Poppins-SemiBold',
                    fontSize: '1rem',
                    color: negroTucar,
                    textAlign: 'center',
                    lineHeight: 1,
                  }}
                >
                  {title}
                </Typography>

                <Divider
                  sx={{
                    width: '100%',
                    backgroundColor: blancoTucar,
                  }}
                />
                <Box>{content}</Box>

                <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                  <MobileStepper
                    variant="progress"
                    steps={numSteps}
                    position="static"
                    activeStep={activeStep - 1}
                    sx={{ width: '100%', minWidth: '100%', flexGrow: 1, justifyContent: 'center' }}
                  />
                </Box>
                <Box sx={{ display: 'flex', gap: '20px' }}>
                  <Button
                    fullWidth
                    disableElevation
                    sx={{ height: '25px' }}
                    type="submit"
                    onClick={() => {
                      prev();
                    }}
                    variant="contained"
                    disabled={activeStep === 1 && !redirectPrev}
                  >
                    Anterior
                  </Button>

                  <Button
                    fullWidth
                    disableElevation
                    sx={{ height: '25px' }}
                    type="submit"
                    onClick={() => {
                      next();
                    }}
                    variant="contained"
                    disabled={numSteps === activeStep && !redirectNext}
                  >
                    Siguiente
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

TutorialModal.propTypes = {
  activeStep: PropTypes.number,
  numSteps: PropTypes.number,
  open: PropTypes.bool.isRequired,
  prev: PropTypes.func,
  next: PropTypes.func,
  title: PropTypes.string,
  content: PropTypes.node,
  position: PropTypes.shape({}),
  redirectPrev: PropTypes.string,
  redirectNext: PropTypes.string,
  exitLink: PropTypes.string,
};

TutorialModal.defaultProps = {
  exitLink: '/app/dashboard',
  redirectPrev: '',
  redirectNext: '',
  activeStep: 0,
  numSteps: 0,
  title: '',
  content: null,
  prev: () => {},
  next: () => {},
  position: { transform: 'translate(-50%, 0%)' },
};

export default TutorialModal;
