import { gql } from 'graphql-request';

const GET_BLOG_ENTRIES = {
  query: gql`
    {
      posts {
        id
        title
        datePublished
        slug
        content {
          html
        }
        author {
          name
          avatar {
            url
          }
        }
        coverPhoto {
          publishedAt
          createdBy {
            id
          }
          url
        }
      }
    }
  `,
};

export default GET_BLOG_ENTRIES;
