export const azulTucar = '#0057b8';
export const rojoTucar = '#d32f2f';
export const grisTucar = '#cccccc';
export const celesteTucar = '#2FB0CD'; // '#6cc8dd';
export const blancoTucar = '#F9F9F9';
export const uberColor = '#000000';
export const negroTucar = '#333333';

export const grisTucar2 = '#C1C1C1';

export const verdeText = '#92aa50';
export const verdeBackground = '#e6eddb';
export const verdeIcon = '#225d3b';
