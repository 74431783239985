// eslint-disable-next-line import/prefer-default-export
export function GetBaseUrl() {
  return window.location.href.split('/#')[0];
}

export function GetUberStatusRedirect() {
  if (process.env.MODE === 'development') {
    return 'local';
    // eslint-disable-next-line no-else-return
  } else if (process.env.MODE === 'staging') {
    return 'dev';
  }
  return 'prod';
}
