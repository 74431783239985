import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import moment from 'moment';

import { Box, Typography, Stack } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  AccountBalanceWalletOutlined,
  DirectionsCarOutlined,
  CurrencyExchangeOutlined,
  CarCrashOutlined,
} from '@mui/icons-material';

import { azulTucar } from '../../../themes/variants/TucarColors';
import TopBar from '../../../components/ui/TopNav';
import StandardDashButton from '../../../components/ui/StandardDashButton';
import DateSelector from '../../../components/choices/DateSelector';
import PayButton from '../../../components/buttons/PayButton';
import DriverGraph from '../../app/dashBoard/DriverGraph';
import ResumeTable from '../../app/dashBoard/ResumeTable';
import TutorialModal from '../modal';
import { allInfoSet, dates } from './info';
import AllSteps from './steps';
import { emptyStep } from '../helpers';

moment.locale('es');

const TutorialDashboard = () => {
  const [step, setStep] = useState(1);
  const [handlePageChange] = useOutletContext();
  const matches = useMediaQuery('(max-width:420px)');
  const minMatches = useMediaQuery('(max-width:300px)');
  const largePhone = useMediaQuery('(max-height:750px)');
  const [date, setDate] = useState('26 de dic., 2022');
  const [stepObject, setStepObject] = useState(emptyStep);
  const navigation = useNavigate();
  useEffect(() => {
    handlePageChange(<TopBar title="Tutorial" prevPage="/app/dashboard" tutorial />, true);
  }, []);

  const handleChange = (_, value) => {
    setDate(value);
  };

  useEffect(() => {
    if (Object.keys(AllSteps).some((key) => key === `${step}`)) {
      setStepObject(AllSteps[step]);
    }
  }, [step]);

  const handleNext = () => {
    stepObject.nextFunction(setStep, step);
  };

  const handlePrev = () => {
    if (stepObject.redirectPrev) {
      navigation(stepObject.redirectPrev, { replace: true });
    }
    stepObject.prevFunction(setStep, step);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        scrollPadding: '500px',
        flexDirection: 'column',
        gap: '1rem',
        padding: '20px',
        paddingTop: '20px',
      }}
    >
      <TutorialModal
        redirectPrev={stepObject?.redirectPrev}
        numSteps={Object.keys(AllSteps).length}
        activeStep={step}
        position={stepObject.ubication}
        next={handleNext}
        prev={handlePrev}
        open
        title={stepObject.title}
        content={stepObject.content}
      />

      <Box
        sx={
          step === 1
            ? { display: 'auto', opacity: 100 }
            : {
                opacity: 0,
                display: 'none',
                transition: 'opacity .4s, display 1s',
              }
        }
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box display="inline">
            <Typography
              variant="span"
              sx={{ display: 'inline', lineHeight: 1, fontSize: '1rem' }}
              fontFamily="Poppins-Medium"
            >
              ¡Hola,
            </Typography>
            <Typography
              sx={{
                display: 'inline',
                color: azulTucar,
                lineHeight: 1,
                fontSize: '1rem',
                paddingLeft: '0.4rem',
              }}
              fontFamily="Poppins-Medium"
            >
              conductor!
            </Typography>
          </Box>
          <Box sx={{ ...stepObject?.styleElement?.dataSelect }} display="flex" alignItems="center">
            <DateSelector
              name="data"
              date={date}
              dateArray={dates}
              fontSize="0.85rem"
              handleChange={handleChange}
            />
          </Box>
        </Stack>
      </Box>

      <Box sx={{ display: step <= 3 ? 'auto' : 'none' }}>
        <ResumeTable
          styleProps={stepObject.styleElement}
          tutorial
          date={date}
          allInfo={allInfoSet}
        />
      </Box>

      <Box sx={{ display: step <= 5 ? 'auto' : `${!largePhone ? 'auto' : 'none'}` }}>
        <DriverGraph styleProps={stepObject.styleElement} selecteDate={date} allInfo={allInfoSet} />
      </Box>
      <Box>
        <PayButton
          styleProps={stepObject.styleElement}
          tutorial
          amount={350000}
          title="Pago de garantía"
        />
      </Box>

      <Box
        sx={{
          ...stepObject?.styleElement?.shortcut,
          display: 'inline-grid',
          gridTemplateColumns: minMatches ? '1fr' : `${matches ? '1fr 1fr' : '1fr 1fr 1fr'}`,
          gridTemplateRows: '1fr 1fr',
          gap: '1rem 1rem',
        }}
      >
        <StandardDashButton Icon={DirectionsCarOutlined} text="Plan de arriendo" />
        <StandardDashButton Icon={AccountBalanceWalletOutlined} text="Liquidaciones" />
        <StandardDashButton Icon={CarCrashOutlined} text="Eventos" />
        <StandardDashButton Icon={CurrencyExchangeOutlined} text="Movimientos" />
      </Box>
    </Box>
  );
};

export default TutorialDashboard;
