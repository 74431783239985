import { gql } from '@apollo/client';

const GET_SHIFT_PETITIONS_V1 = {
  query: gql`
    query GetShiftPetitions(
      $uid: ID!
      $driver: String
      $sortBy: String
      $offset: Int
      $limit: Int
    ) {
      getDriverPlanV2(driverId: $uid) {
        plan {
          shifts {
            _id
            name
            startTime
            endTime
            basePrice
            type
          }
        }
      }
      getShiftChangePetitions(driver: $driver, sortBy: $sortBy, offset: $offset, limit: $limit) {
        instances {
          _id
          petitioner
          participants {
            id
            accepted
            futureShifts {
              _id
              type
            }
          }
          expiration
          rollbackDate
          closedDate
          status
          updatedAt
          createdAt
        }
        offset
        limit
        total
      }
    }
  `,
  policies: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
};
export default GET_SHIFT_PETITIONS_V1;
