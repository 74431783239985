import { gql } from '@apollo/client';
import insertDataLayer from '../../../helpers/GTM';
import { setAuth, setLoading, setError } from '../../store';

const SIGN_UP_TUCAR = {
  mutation: gql`
    mutation SignUp(
      $code: String!
      $source: String!
      $client: String!
      $country: String!
      $userType: String!
      $firstname: String!
      $lastname: String!
      $phone: String!
      $email: String!
      $password: String!
      $confirmPassword: String!
    ) {
      signUp(
        code: $code
        source: $source
        client: $client
        country: $country
        userType: $userType
        firstname: $firstname
        lastname: $lastname
        phone: $phone
        email: $email
        password: $password
        confirmPassword: $confirmPassword
      ) {
        accessToken
        refreshToken
        data {
          uid
          email
          firstname
          lastname
          userType
          verified
        }
      }
    }
  `,
  policies: {
    onCompleted: (response) => {
      if (response.signUp) {
        const { accessToken, refreshToken, data } = response.signUp;
        setAuth({ accessToken, refreshToken, data });
        setLoading(false);
        setError(false);
        insertDataLayer();
        const dataLayer = window.dataLayer || [];
        dataLayer.push({
          event: 'Registro Tucar GTM',
          userId: data?.uid || '',
          date: new Date(),
        });
      }
      if (response.errors) {
        setError(true);
      }
    },
    errorPolicy: 'all',
  },
};

export default SIGN_UP_TUCAR;
