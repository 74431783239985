import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Card, Typography } from '@tucar/pit-stop';

const SumatoryCost = ({ kms }) => {
  return (
    <Card
      style={{
        padding: '23px 38px',
        background:
          'linear-gradient(308deg, rgba(255, 255, 255, 0.53) 2.36%, rgba(255, 255, 255, 0.00) 61.95%), #E3EDF7',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '11px', width: '100%' }}>
        <Typography style={{ fontSize: '14px' }} weight="light">
          Total de km recorridos: <Typography style={{ fontSize: '14px' }}>{kms} km</Typography>
        </Typography>
      </Box>
    </Card>
  );
};

SumatoryCost.propTypes = {
  kms: PropTypes.number,
};

SumatoryCost.defaultProps = {
  kms: 0,
};

export default SumatoryCost;
