import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Typography, Icon } from '@tucar/pit-stop';

import PressIconButton from '../../../components/buttons/PressIconButton';
import { azulTucar } from '../../../themes/variants/TucarColors';

const StepRentStatus = ({ title, date, completed, icon, last }) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '18px 1fr',
        gap: '20px',
        alignItems: 'center',
        position: 'relative',
        opacity: completed ? 0.5 : 1,
        '::before': {
          content: '""',
          visibility: last ? 'hidden' : 'visible',
          position: 'absolute',
          top: 'calc(50% + 10px)',
          left: '8px',
          height: '100%',
          width: '1px',
          border: `1px dashed #1D7CE7`,
        },
      }}
    >
      <Icon
        color={completed ? '#7FB418' : azulTucar}
        name={completed ? 'checkCircleRounded' : 'errorRounded'}
        size="18px"
      />
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '14px' }}>
        <PressIconButton>
          <Icon color={completed ? '#7FB418' : azulTucar} name={icon} size="30px" />
        </PressIconButton>
        <Box
          sx={{ display: 'flex', flexDirection: 'column', gap: '5px', justifyContent: 'center' }}
        >
          <Typography
            color={completed ? '#7FB418' : azulTucar}
            style={{ fontSize: '16px', fontWeight: 600, lineHeight: 1.2 }}
          >
            {title}
          </Typography>
          <Typography
            color="#5B5D71"
            style={{ fontSize: '14px', fontWeight: 500, lineHeight: 1.2 }}
          >
            {date}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

StepRentStatus.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  completed: PropTypes.bool,
  icon: PropTypes.string,
  last: PropTypes.bool,
};

StepRentStatus.defaultProps = {
  title: '',
  date: '',
  completed: false,
  icon: '',
  last: false,
};

export default StepRentStatus;
