function checkOnboarding(verifiedList, docs, exclusivePlan, noLineal) {
  /*
  The Driver must have completed:
    1. Information (Check in verifiedList).
    2. Upload a files subset (about him):
       - cedulaIdentidad
       - licenciaConducir
    3. Link a Uber account.
    4. Upload a files subset (about behavior):
       - certificadoAntecedentes
       - hojaDeVida
    5. Select a plan.
    6. Schedule and attend a meeting.
  */

  // Check data
  if (!verifiedList || !docs) {
    return { field: 'loading', onBoarding: true };
  }

  if (noLineal && Object.values(verifiedList).includes(false)) {
    return { field: '-', onBoarding: true };
  }
  // // Check info
  if (!verifiedList.info) {
    return { field: 'info', onBoarding: true };
  }

  // Check Uber account
  if (!verifiedList.app) {
    return { field: 'app', onBoarding: true };
  }

  // Check plan
  if (!verifiedList.plan) {
    return { field: 'plan', onBoarding: true };
  }

  // Check files about him
  if (
    !docs.cedulaIdentidadFront ||
    !docs.cedulaIdentidadBack ||
    !docs.licenciaConducirFront ||
    !docs.licenciaConducirBack ||
    !docs.certificadoAntecedentes ||
    !docs.hojaVida
  ) {
    return { field: 'docs', onBoarding: true };
  }

  // Check meeting
  if (!verifiedList.meeting && exclusivePlan) {
    return { field: 'meeting', onBoarding: true };
  }

  if (!verifiedList.docs) {
    return { field: 'revisionDocs', onBoarding: true };
  }

  return { field: false, onBoarding: false };
}

export default checkOnboarding;
