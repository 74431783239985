import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Box, ListItemText, ListItemIcon, ListItemButton, Collapse } from '@mui/material';
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import { azulTucar } from '../../../../../themes/variants/TucarColors';
import PersonalForm from './personalForm';
import UPDATE_INFO_DRIVER from '../../../../../graphql/mutations/driver/updateInfoDriver';
import ErrorModal from '../../../../../components/ui/ErrorModal';

const PersonalInfo = () => {
  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  // eslint-disable-next-line no-unused-vars
  const [updateDriverInfo, { loading, error }] = useMutation(
    UPDATE_INFO_DRIVER.mutation,
    UPDATE_INFO_DRIVER.policies,
  );
  const executeMutation = (variables) => {
    updateDriverInfo({
      variables,
    }).catch(() => {});
  };

  useEffect(() => {
    if (!loading) {
      if (error) {
        setOpenError(true);
      }
    }
  }, [loading]);

  return (
    <Box>
      <ErrorModal
        open={openError}
        onClose={() => {
          setOpenError(false);
        }}
        content="Ups, ha ocurrido un error de nuestra parte. Contacta a soporte presionando el chat."
        title="Error al enviar información"
        emojiSymbol="🤔"
      />
      <Box>
        <ListItemButton
          sx={{ height: '45px', color: open ? azulTucar : '#000' }}
          onClick={() => handleClick()}
        >
          <ListItemIcon>
            <ContactPageOutlinedIcon sx={{ color: azulTucar }} />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{
              style: { fontSize: '14px', fontFamily: 'Poppins-Regular', lineHeight: 1 },
            }}
            primary="Información personal"
          />
          {open ? (
            <KeyboardArrowUpOutlinedIcon sx={{ fontSize: '20px' }} />
          ) : (
            <KeyboardArrowDownOutlinedIcon sx={{ fontSize: '20px' }} />
          )}
        </ListItemButton>
      </Box>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box margin="30px 0px 0px 0px">
          <PersonalForm executeMutation={executeMutation} />
        </Box>
      </Collapse>
    </Box>
  );
};

export default PersonalInfo;
