import { gql } from '@apollo/client';
import { setAuth, setError } from '../../store';

const REFRESH_TOKEN = {
  query: gql`
    query RefreshToken($refreshToken: String!, $authProvider: String) {
      refreshToken(refreshToken: $refreshToken, authProvider: $authProvider) {
        accessToken
        refreshToken
        data {
          uid
          email
          firstname
          lastname
          userType
          verified
        }
      }
    }
  `,
  policies: {
    onCompleted: ({ refreshToken: refreshTokenData, errors }) => {
      if (refreshTokenData) {
        if (refreshTokenData.data.userType.toLowerCase() === 'driver') {
          const { accessToken, refreshToken, data } = refreshTokenData;
          setAuth({ accessToken, refreshToken, data });
        } else {
          setAuth({
            accessToken: false,
            refreshToken: false,
            data: {
              email: false,
              firstname: false,
              lastname: false,
              uid: false,
              userType: false,
              verified: false,
            },
          });
          setError(true);
        }
      }
      if (errors) {
        setAuth({
          accessToken: false,
          refreshToken: false,
          data: {
            email: false,
            firstname: false,
            lastname: false,
            uid: false,
            userType: false,
            verified: false,
          },
        });
        setError(true);
      }
    },
    onError: (apolloError) => {
      if (apolloError) {
        setAuth({
          accessToken: false,
          refreshToken: false,
          data: {
            email: false,
            firstname: false,
            lastname: false,
            uid: false,
            userType: false,
            verified: false,
          },
        });
      }
    },
    errorPolicy: 'all',
    context: {
      forRefreshToken: true,
    },
  },
};

export default REFRESH_TOKEN;
