import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Typography, Button, Card } from '@tucar/pit-stop';
import { Box } from '@mui/material';

import { msgAlertStyle, wrapperAlert } from '../styles';

const SuccessAlert = ({ title, msg, isOpen, onClose }) => {
  return (
    <Box
      sx={{
        display: isOpen ? 'flex' : 'none',
        ...wrapperAlert,
      }}
    >
      <Card style={{ padding: '32px', gap: '32px' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '22px',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              border: '1px solid rgba(102, 174, 255, 0.4)',
              background: 'rgba(102, 174, 255, 0.4)',
              borderRadius: '9px',
              padding: '6px',
              boxShadow:
                '-4px -4px 9px 0px rgba(255, 255, 255, 0.88) inset, 44px 4px 10px 0px rgba(0, 87, 184, 0.30) inset',
            }}
          >
            <Icon name="addAlertFilled" size="24px" color="#0057B8" />
          </Box>
          <Typography
            style={{ color: '#0057B8', fontWeight: 500, lineHeight: 1.2, fontSize: '16px' }}
          >
            {title}
          </Typography>
        </Box>
        <Typography style={msgAlertStyle}>{msg}</Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button size="small" onClick={onClose}>
            Aceptar
          </Button>
        </Box>
      </Card>
    </Box>
  );
};

SuccessAlert.propTypes = {
  title: PropTypes.string,
  msg: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

SuccessAlert.defaultProps = {
  title: '',
  msg: '',
  isOpen: false,
  onClose: () => {},
};

export default SuccessAlert;
