import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { Card, TucarTheme } from '@tucar/pit-stop';
import CircleIcon from '@mui/icons-material/Circle';
import { formatterCLP } from '../../../../../helpers/formatters';
import ProgressSection from '../../../../../components/info/SectionKms/progressSections';

const SectionKms = ({ sections, kms, currency, discount }) => {
  return (
    <Card style={{ padding: '38px 20px', gap: '30px' }}>
      <Typography
        sx={{ fontSize: '16px', fontFamily: 'Poppins-Medium', color: TucarTheme.colors.gray700 }}
      >
        Costo por kilómetro
      </Typography>
      <Typography
        sx={{ fontSize: '13px', color: TucarTheme.colors.gray700, fontFamily: 'Poppins-Regular' }}
      >
        El costo total del arriendo semanal es la suma del costo base del plan y el costo por km.
        Cada 400 km aplicaremos un descuento sobre el costo inicial del km.
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          width: '100%',
          paddingInline: '10px',
        }}
      >
        {ProgressSection(sections, kms, currency, discount)}
      </Box>
      <Box sx={{ display: 'grid', width: '100%', gridTemplateColumns: '1fr 1fr' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <CircleIcon sx={{ fontSize: '17px', color: '#95D21C' }} />
          <Typography
            sx={{
              fontSize: '13px',
              fontFamily: 'Poppins-Regular',
              color: TucarTheme.colors.gray700,
            }}
          >
            Completado
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <CircleIcon sx={{ fontSize: '17px', color: TucarTheme.colors.blue500 }} />
          <Typography
            sx={{
              fontSize: '13px',
              fontFamily: 'Poppins-Regular',
              color: TucarTheme.colors.gray700,
            }}
          >
            En proceso
          </Typography>
        </Box>
      </Box>
      <Typography
        sx={{
          fontSize: '14px',
          color: TucarTheme.colors.gray700,
          fontFamily: 'Poppins-Regular',
          textAlign: 'center',
        }}
      >
        Los valores expresados en pesos consideran el valor de la UF de{' '}
        {formatterCLP.format(currency)}. IVA incluido.
      </Typography>
    </Card>
  );
};

SectionKms.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.shape({})),
  kms: PropTypes.number,
  currency: PropTypes.number,
  discount: PropTypes.number,
};

SectionKms.defaultProps = {
  sections: [],
  kms: 0,
  currency: 0,
  discount: 0,
};

export default SectionKms;
