import { gql } from '@apollo/client';

const POST_LOGOUT = {
  mutation: gql`
    mutation SignOut($refreshToken: String!) {
      signOut(refreshToken: $refreshToken)
    }
  `,
  policies: {
    errorPolicy: 'all',
  },
};

export default POST_LOGOUT;
