import { gql } from '@apollo/client';

const GET_CAR_APPOINTMENTS = {
  query: gql`
    query Car($carId: ID!) {
      car(carId: $carId) {
        appointments {
          appointments {
            garage {
              location
            }
            services {
              serviceType
              name
              spareParts {
                name
                quantity
              }
            }
            stage
            appointmentDatetime
          }
        }
      }
    }
  `,
  policies: { fetchPolicy: 'no-cache', notifyOnNetworkStatusChange: 'true' },
};

export default GET_CAR_APPOINTMENTS;
