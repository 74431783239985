const getCeilAbsoluteValue = (initialValue) => Math.ceil(Math.abs(initialValue));

const getPlanDisponibilityHours = (startTime, endTime) => {
  const [startTimeHours, startTimeMinutes] = startTime.split(':');
  const [endTimeHours, endTimeMinutes] = endTime.split(':');
  const startDate = new Date(null, null, null, startTimeHours, startTimeMinutes, 0, 0);
  const endDate = new Date(null, null, null, endTimeHours, endTimeMinutes, 0, 0);
  return endDate > startDate
    ? getCeilAbsoluteValue((endDate.getTime() - startDate.getTime()) / 36e5)
    : 24 - getCeilAbsoluteValue((startDate.getTime() - endDate.getTime()) / 36e5);
};
export default getPlanDisponibilityHours;
