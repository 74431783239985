import moment from 'moment';
import { useReactiveVar } from '@apollo/client';
import RectangleColors from '../../../../../public/images/background/Rectangle.png';
import InRentRectangle from '../../../../../public/images/background/rentRectangle.png';
import { azulTucar } from '../../../../themes/variants/TucarColors';
import { logout, getDriverStore } from '../../../../graphql/store';

export function formatData(data) {
  const maxKm = data.reduce((acc, elem) => (elem.km > acc ? elem.km : acc), 0);
  const kmTotal = data.reduce((acc, elem) => acc + elem.km, 0);
  const kmUber = data.reduce((acc, elem) => acc + elem.kmUber, 0);
  const uberPercentage = Math.round((kmUber * 100) / kmTotal) || 0;
  const kmByDay = data
    .map((elem) => {
      return {
        km: elem.km,
        value: elem.km,
        label: moment(new Date(elem.date)).format('DD'),
        title: moment(new Date(elem.date)).format('ddd'),
      };
    })
    .reverse();
  const totalCash = data.reduce((acc, elem) => acc + elem.uberBreakdown.cashCollected, 0);

  const totalCollections = data.reduce((acc, { incomes }) => acc + incomes.uber + incomes.other, 0);
  const totalExpenses = data.reduce(
    (acc, { expenses }) =>
      acc + expenses.fines + expenses.penaltys + expenses.tag + expenses.rent + expenses.other,
    0,
  );

  return {
    totalExpenses,
    totalCollections,
    totalGaining: totalCollections - totalExpenses,
    totalCash,
    kmTotal,
    kmUber,
    uberPercentage,
    kmByDay,
    maxKm,
  };
}

export const getDateFormat = (date) => moment(new Date(date)).format('DD/MM');

export const backgroundStyle = (inRent) => ({
  paddingInline: '30px',
  position: 'relative',
  zIndex: 1,
  '::before': {
    content: '""',
    position: 'absolute',
    zIndex: -1,
    top: '0px',
    backgroundImage: `url(${inRent ? InRentRectangle : RectangleColors})`,
    backgroundSize: 'cover',
    left: '0px',
    borderRadius: '0px 0px 30px 30px',
    height: '270px',
    width: '100%',
  },
});

export const basicSectionsSideBar = (navigate) => {
  const { labelV2 } = useReactiveVar(getDriverStore);

  return [
    {
      icon: 'homeFilled',
      title: 'Dashboard',
      color: azulTucar,
      onClick: () => navigate('/app/v2/dashboard'),
    },
    {
      icon: 'moneyOutlined',
      title: 'Bloqueo efectivo',
      onClick: () => navigate('/app/v2/cash-block'),
    },
    {
      icon: 'nextPlanFilled',
      title: 'Plan de arriendo',
      onClick: () => navigate(labelV2 ? '/app/v2/subscription' : '/app/subscription'),
    },
    {
      icon: 'electricalServicesFilled',
      title: 'Mapa de carga',
      onClick: () => navigate('/app/v2/chargers'),
    },
    labelV2
      ? {
          icon: 'directionsCarFilled',
          title: 'Vehículo',
          onClick: () => navigate('/app/v2/subscription'),
        }
      : {
          icon: 'directionsCarFilled',
          title: 'Vehículo',
          sections: [
            {
              title: 'Información del vehículo',
              onClick: () => navigate('/app/car/carsheet'),
            },
            {
              title: 'Mantenciones',
              onClick: () => navigate('/app/car/maintenance'),
            },
            {
              title: 'Deducible',
              onClick: () => navigate('/app/car/deductibles'),
            },
            {
              title: 'Documentos',
              onClick: () => navigate('/app/car/documents'),
            },
          ],
        },
    {
      icon: 'moneyOutlined',
      title: 'Boletas',
      onClick: () => navigate('/app/v2/vouchers'),
    },
    {
      icon: 'starRateFilled',
      title: 'Turno',
      sections: [
        {
          title: 'Solicitudes cambio de turno',
          onClick: () => navigate('/app/v2/petitions'),
        },
        {
          title: 'Administrar turno',
          onClick: () => navigate('/app/manageshift'),
        },
      ],
    },
    {
      icon: 'attachMoneyOutlined',
      title: 'Ganancias',
      onClick: () => navigate('/app/v2/balance'),
    },

    {
      icon: 'arrowDropDownCircleFilled',
      title: 'Reembolsos',
      onClick: () => navigate('/app/v2/incomes-events'),
    },
    {
      icon: 'cardTravelFilled',
      title: 'Viajes',
      onClick: () => navigate('/app/v2/trips'),
    },
    {
      icon: 'localFireDepartmentFilled',
      title: 'Sanciones por Tucar',
      onClick: () => navigate('/app/v2/exchange-events'),
    },
    {
      icon: 'reportFilled',
      title: 'Multas de tránsito',
      onClick: () => navigate('/app/v2/control-events'),
    },
    {
      icon: 'taxiAlertFilled',
      title: 'Otros cargos',
      onClick: () => navigate('/app/v2/expenses-events'),
    },
    {
      icon: 'libraryBooksFilled',
      title: 'Liquidaciones',
      onClick: () => navigate('/app/v2/liquidations'),
    },
    {
      icon: 'localCarWashFilled',
      title: 'TAG',
      onClick: () => navigate('/app/v2/tags'),
    },
  ];
};

export const bottomSideBar = [
  {
    icon: 'supportAgentOutlined',
    title: 'Configuración',
    sections: [
      {
        title: 'Contactar a ejecutivo',
        onClick: () => window.Intercom('show'),
      },
      {
        onClick: () => window.open('https://tucar.com/preguntas-frecuentes'),
        title: 'Preguntas frecuentes',
      },
    ],
  },
  {
    icon: 'logoutFilled',
    title: 'Cerrar sesión',
    color: '#CC1C00',
    onClick: () => {
      logout();
      window.location.reload();
    },
  },
];
