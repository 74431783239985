/* eslint-disable no-alert */
import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { azulTucar, blancoTucar } from '../themes/variants/TucarColors';

const SubmitButton = ({
  href,
  text,
  handler,
  height,
  disabled,
  color,
  hoverColor,
  backgroundColor,
  variant,
}) => (
  <Button
    type="submit"
    variant={variant}
    href={href}
    onClick={handler}
    disabled={disabled}
    sx={{
      height,
      color,
      borderColor: color,
      backgroundColor,
      fontSize: '14px',
      width: '100%',
      boxShadow: 0,
      '&:hover': { backgroundColor, borderColor: color, color: hoverColor, boxShadow: 0 },
    }}
  >
    {text}
  </Button>
);

SubmitButton.defaultProps = {
  handler: () => {},
  href: '',
  text: 'Continuar',
  height: '40px',
  disabled: false,
  backgroundColor: azulTucar,
  color: blancoTucar,
  hoverColor: blancoTucar,
  variant: 'contained',
};

SubmitButton.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  href: PropTypes.string,
  handler: PropTypes.func,
  height: PropTypes.string,
  disabled: PropTypes.bool,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  variant: PropTypes.string,
};

export default SubmitButton;
