import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Card, Button } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import useMediaQuery from '@mui/material/useMediaQuery';

import arrowTucar from '../../../../public/images/customIcons/arrow/arrow_blue.svg';
import arrowBlack from '../../../../public/images/customIcons/arrow/arrow.svg';

import { formatterCLP } from '../../../helpers/formatters';
import { azulTucar } from '../../../themes/variants/TucarColors';

function ResumeTable({ date, allInfo, tutorial, styleProps }) {
  const navigate = useNavigate();
  const [info, setInfo] = useState([]);
  const minMatches = useMediaQuery('(max-width:300px)');

  useEffect(() => {
    const aux = allInfo.filter((elem) => elem.date === date);
    if (aux.length > 0) {
      setInfo(aux[0]);
    }
  }, [date, allInfo]);

  return (
    <Box sx={{ ...styleProps.container }}>
      <Card
        sx={{
          ...styleProps.buttonContainer,
          background: `linear-gradient(to right, ${azulTucar}, #006FEB)`,
          padding: '0.9rem',
          borderRadius: '1rem',
        }}
        elevation={3}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="start"
          flexDirection="column"
          gap="0.1rem"
        >
          <Typography
            sx={{
              fontSize: '1rem',
              color: '#FFF',
              fontFamily: 'Poppins-Medium',
            }}
          >
            Resultado semanal
          </Typography>
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              sx={{
                fontSize: '1.5rem',
                color: '#FFF',
                fontFamily: 'Poppins-Medium',
              }}
            >
              {`${info.total ? formatterCLP.format(info.total) : '$0'}`}
            </Typography>
            <Button
              size="small"
              disabled={tutorial}
              sx={{
                ...styleProps.button,
                height: '28px',
                borderRadius: '55px',
                background: 'rgba(255, 255, 255, 0.3)',
                textTransform: 'initial',
                display: 'flex',
                gap: '5px',
                padding: '10px',
                alignItems: 'center',
                '&:hover': {
                  background: 'rgba(255, 255, 255, 0.3)',
                },
              }}
              onClick={() =>
                navigate(
                  `/app/balance?date=${moment(date, 'DD [de] MMM[,] YYYY').format('DD/MM/YYYY')}`,
                  { replace: true },
                )
              }
            >
              <Typography
                sx={{
                  fontFamily: 'Poppins-Regular',
                  fontSize: minMatches ? '0.65rem' : '0.875rem',
                  color: '#FFF',
                  verticalAlign: 'middle',
                  padding: 0,
                  lineHeight: 1,
                }}
              >
                Ver balance
              </Typography>

              <KeyboardArrowRightIcon
                size="small"
                sx={{
                  fontSize: '0.75rem',
                  color: azulTucar,
                  backgroundColor: '#FFF',
                  borderRadius: '55px',
                }}
              />
            </Button>
          </Box>
        </Box>
      </Card>
      <Box
        display="grid"
        gridTemplateColumns={minMatches ? '1fr' : 'repeat(2, 1fr)'}
        marginTop="20px"
      >
        <Box
          display="flex"
          justifyContent={minMatches ? 'start' : 'center'}
          alignItems="center"
          gap="0.7rem"
        >
          <img
            src={arrowTucar}
            alt="category"
            style={{
              width: '2rem',
              transform: 'rotate(180deg)',
              color: azulTucar,
            }}
          />
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Typography
              sx={{
                fontSize: '0.9rem',
                fontFamily: 'Poppins-Light',
                color: azulTucar,
              }}
            >
              Recaudaciones
            </Typography>
            <Typography
              sx={{
                fontSize: '1.2rem',
                fontFamily: 'Poppins-Medium',
                color: azulTucar,
              }}
            >
              {info.incomes >= 0 ? `+${formatterCLP.format(info.incomes)}` : '-'}
            </Typography>
          </Box>
        </Box>

        <Box
          display="flex"
          justifyContent={minMatches ? 'start' : 'center'}
          alignItems="center"
          gap="0.7rem"
        >
          <img
            src={arrowBlack}
            alt="category"
            style={{
              width: '2rem',
              color: azulTucar,
            }}
          />
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Typography
              sx={{
                fontSize: '0.9rem',
                fontFamily: 'Poppins-Light',
              }}
            >
              Descuentos
            </Typography>
            <Typography
              sx={{
                fontSize: '1.2rem',
                fontFamily: 'Poppins-Medium',
              }}
            >
              {info.expenses >= 0 ? `-${formatterCLP.format(info.expenses)}` : '-'}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

ResumeTable.propTypes = {
  tutorial: PropTypes.bool,
  date: PropTypes.string.isRequired,
  allInfo: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      incomes: PropTypes.number,
      expenses: PropTypes.number,
      total: PropTypes.number,
    }),
  ).isRequired,
  styleProps: PropTypes.shape({
    container: PropTypes.shape({}),
    button: PropTypes.shape({}),
    buttonContainer: PropTypes.shape({}),
  }),
};

ResumeTable.defaultProps = {
  tutorial: false,
  styleProps: { container: {}, button: {}, buttonContainer: {} },
};

export default ResumeTable;
