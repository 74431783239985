import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import PermDeviceInformationIcon from '@mui/icons-material/PermDeviceInformation';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ArchiveIcon from '@mui/icons-material/Archive';
import { useReactiveVar } from '@apollo/client';
import TopNav2 from '../../../../../components/ui/TopNav/newTopNav';
import getUniqueKey from '../../../../../helpers/customKey';
import { formatterCLP } from '../../../../../helpers/formatters';
import { getAuthStore } from '../../../../../graphql/store';
import EventCustomColorCard from './detailsComponents/EventCustomColorCard';

const EventDetails = ({ data, setShowDetails, prevPage }) => {
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);

  const {
    car,
    description,
    chargeDate,
    updatedAt,
    files,
    amount,
    credited: { ref: creditedUid },
  } = data;

  const contentList = [
    {
      icon: <DataUsageIcon sx={{ color: '#1F89FF', fontSize: '17px' }} />,
      color: '#1F89FF',
      title: 'Datos',
      content: [
        // eslint-disable-next-line no-underscore-dangle
        { title: 'Auto asignado', msg: car ? car._id : 'Sin auto asignado al evento' },
        { title: 'Motivo', msg: description },
      ],
    },
    {
      icon: <PermDeviceInformationIcon sx={{ color: '#6CC7DC', fontSize: '17px' }} />,
      color: '#6CC7DC',
      title: 'Información general',
      content: [
        { title: 'Fecha', msg: moment(chargeDate).format('DD/MM/YYYY [-] HH:mm [hrs.]') },
        {
          title: 'Última actualización',
          msg: moment(updatedAt).format('DD/MM/YYYY [-] HH:mm [hrs.]'),
        },
      ],
    },
    {
      icon: <AttachMoneyIcon sx={{ color: '#7FB418', fontSize: '17px' }} />,
      color: '#7FB418',
      title: 'Valor',
      content: [
        { title: 'Monto', msg: `Valor total ${formatterCLP.format(amount)}` },
        { title: 'Tipo de transacción', msg: creditedUid === uid ? 'Abono' : 'Cobro' },
      ],
    },
    files.length > 0 && {
      icon: <ArchiveIcon sx={{ color: '#FFC832', fontSize: '17px' }} />,
      color: '#FFC832',
      title: 'Documentos asociados',
      content: files.map((file, index) => {
        return { title: `Archivo #${index + 1}`, msg: '', url: file.url };
      }),
    },
  ];

  return (
    <Box>
      <TopNav2
        title={data.title}
        prevPage={prevPage || '/app/v2/balance'}
        handlePageChange={() => {
          setShowDetails(null);
        }}
      />
      <Box
        sx={{
          paddingBottom: '50px',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          paddingInline: '30px',
        }}
      >
        {contentList.map((elem) =>
          elem ? (
            <EventCustomColorCard
              key={getUniqueKey()}
              color={elem.color}
              title={elem.title}
              content={elem.content}
              icon={elem.icon}
            />
          ) : null,
        )}
      </Box>
    </Box>
  );
};

EventDetails.propTypes = {
  prevPage: PropTypes.string,
  setShowDetails: PropTypes.func,
  data: PropTypes.shape({
    title: PropTypes.string,
    updatedAt: PropTypes.string,
    chargeDate: PropTypes.string,
    description: PropTypes.string,
    comments: PropTypes.arrayOf(PropTypes.string),
    amount: PropTypes.number,
    car: PropTypes.shape({
      _id: PropTypes.string,
    }),
    files: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
      }),
    ),
    credited: PropTypes.shape({
      ref: PropTypes.string,
    }),
  }),
};

EventDetails.defaultProps = {
  prevPage: '',
  setShowDetails: () => {},
  data: {
    title: '',
    files: [],
    amount: 0,
    comments: [],
    updatedAt: '',
    chargeDate: '',
    description: '',
    car: {
      _id: '',
    },
    credited: {
      ref: '',
    },
  },
};

export default EventDetails;
