import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Box, Typography } from '@mui/material';
import { useReactiveVar, useQuery } from '@apollo/client';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';

import IconTitleCard from '../../../components/info/IconTitleCard';
import { getAuthStore, getDriverStore } from '../../../graphql/store';
import { azulTucar } from '../../../themes/variants/TucarColors';
import LoadingProgress from '../../../components/ui/LoadingProgress';
import { formatterCLP } from '../../../helpers/formatters';
import GET_DRIVER_GUARANTEE from '../../../graphql/querys/plan/getGuarantee';
import translateUberPro from '../../../helpers/translateUberPro';
import GET_INSTALLMENTS from '../../../graphql/querys/guarantee/getInstallments';
import TucarElement from '../../../components/info/TucarElement';
import OnBoardingTitle from '../../../components/extras/OnboardingTitle';

const GuaranteeShow = () => {
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);
  const {
    category,
    info: { country },
  } = useReactiveVar(getDriverStore);
  const [installments, setInstallments] = useState([]);
  const [userGuarantee, setUserGuarantee] = useState(0);
  const [personalMessage, setPersonalMessage] = useState();
  const { data, loading } = useQuery(GET_DRIVER_GUARANTEE.query, {
    ...GET_DRIVER_GUARANTEE.policies,
    variables: {
      uid,
      country,
    },
  });
  const { data: installmentData, loading: installmentLoading } = useQuery(GET_INSTALLMENTS.query, {
    ...GET_INSTALLMENTS.policies,
    variables: { ref: uid },
  });

  useEffect(() => {
    if (data?.getConfigAccounting && data?.getDriverAccounting) {
      setUserGuarantee(data.getDriverAccounting.guarantee.origin.driver);
      if (category?.discount) {
        setPersonalMessage(
          `¡Tienes un ${
            category.discount
          }% de descuento en el total de tu garantía por ser Uber ${translateUberPro(
            category.uberPro,
          )}!`,
        );
      }
    }
  }, [data]);

  useEffect(() => {
    if (installmentData?.getInstallments) {
      const auxComponents = installmentData?.getInstallments.map((installment) => {
        return (
          <TucarElement
            key={installment.id}
            IconCard={InsertDriveFileOutlinedIcon}
            variant="card"
            primaryContent={{
              title: `Cuota ${moment(installment.chargeDate).format('DD [de] MMM [del] YYYY')}`,
              auxText: `${formatterCLP.format(installment.amount)}`,
              resume: 'Garantía',
              detail: '',
            }}
          />
        );
      });
      setInstallments(auxComponents.reverse().slice(0, 20));
    }
  }, [installmentData]);

  const contentCard = (
    <Box sx={{ display: 'flex', gap: '15px', flexDirection: 'column', width: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
        <Typography
          variant="span"
          sx={{
            textAlign: 'left',
            lineHeight: 1,
            color: 'white',
            fontSize: '20px',
          }}
          fontFamily="Poppins-Medium"
        >
          Total: {formatterCLP.format(userGuarantee)}
        </Typography>
        <Typography
          sx={{
            color: 'white',
            lineHeight: 1,
            fontSize: '12px',
          }}
          fontFamily="Poppins-Regular"
        >
          {personalMessage}
        </Typography>
      </Box>
    </Box>
  );

  if (!data || !data?.getDriverAccounting || loading || installmentLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <LoadingProgress allScreen={false} heightContainer="100%" />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateRows: 'max-content 1fr',
        height: 'inherit',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <OnBoardingTitle title="Garantía pagada" noLineal />
        <Box>
          <IconTitleCard
            noIconBubble
            containerStyle={{
              padding: '30px',
              borderRadius: '15px',
              backgroundColor: azulTucar,
            }}
            contentText={contentCard}
          />
        </Box>

        <Box>
          <Typography sx={{ paddingY: '20px', fontFamily: 'Poppins-Regular', fontSize: '14px' }}>
            Cuotas restantes:
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'scroll',
            gap: '10px',
            width: '100%',
          }}
        >
          {installments}
        </Box>
      </Box>
    </Box>
  );
};

export default GuaranteeShow;
