import React, { useContext, createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

const MapContext = createContext();

export const MapContextProvider = ({ children, carToFocus, focusCar, carStatusFilter }) => {
  const [searchActive, setSearchActive] = useState(false);
  const contextValue = useMemo(
    () => ({ setSearchActive, searchActive, carStatusFilter, carToFocus, focusCar }),
    [carToFocus, focusCar, carStatusFilter, searchActive, setSearchActive],
  );

  return <MapContext.Provider value={contextValue}>{children}</MapContext.Provider>;
};

MapContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  carToFocus: PropTypes.shape({}),
  focusCar: PropTypes.func,
  carStatusFilter: PropTypes.shape({
    filterOptions: PropTypes.arrayOf(PropTypes.shape()),
    setFilterOptions: PropTypes.func,
  }),
};

MapContextProvider.defaultProps = {
  carToFocus: {},
  focusCar: () => {},
  carStatusFilter: {},
};

export const useMapContext = () => useContext(MapContext);
