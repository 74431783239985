import { azulTucar } from '../../../themes/variants/TucarColors';

const textColor = {
  created: azulTucar,
  accepted: 'green',
};

const petitionTranslations = {
  created: 'Pendiente',
  accepted: 'Aceptado',
  rejected: 'Rechazado',
  expired: 'Expirado',
  completed: 'Completado',
};

export const weekObjOneLetter = ['L', 'M', 'W', 'J', 'V', 'S', 'D'];

export const standardWeekDaysNames = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

export const translateShiftsByDayV1 = (config, options) => {
  const futureShifts = config.reduce((acc, { am, pm }, index) => {
    if (am && pm) return { ...acc, [standardWeekDaysNames[index]]: options?.ALL };
    if (am) return { ...acc, [standardWeekDaysNames[index]]: options?.AM };
    if (pm) return { ...acc, [standardWeekDaysNames[index]]: options?.PM };
    return { ...acc, [standardWeekDaysNames[index]]: options?.FREE };
  }, {});

  const auxTable = [...Array(7).keys()].map(
    (element) => futureShifts[standardWeekDaysNames[element]],
  );
  return auxTable;
};

export const translateShiftsByDay = (config, options) => {
  const futureShifts = config.reduce((acc, { am, pm }, index) => {
    if (am && pm) return { ...acc, [standardWeekDaysNames[index]]: options?.ALL?.id };
    if (am) return { ...acc, [standardWeekDaysNames[index]]: options?.AM?.id };
    if (pm) return { ...acc, [standardWeekDaysNames[index]]: options?.PM?.id };
    return { ...acc, [standardWeekDaysNames[index]]: options?.FREE?.id };
  }, {});

  const auxTable = [...Array(7).keys()].map(
    (element) => futureShifts[standardWeekDaysNames[element]],
  );
  return auxTable;
};

export const complementConfig = (configAux) => {
  return configAux.map((elem) => {
    return { am: !elem.am, pm: !elem.pm };
  });
};

export const weekDaysTranslate = (dateElem) => {
  const dayKey = (dateElem.getDay() + 6) % 7;
  const weekObj = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
  return weekObj[dayKey];
};

export const weekDaysTranslateOneLetter = (dateElem) => {
  const dayKey = (dateElem.getDay() + 6) % 7;
  return weekObjOneLetter[dayKey];
};

export const addDayToday = (days) => {
  const date = new Date();
  date.setDate(date.getDate() + days);
  return date;
};

export const translatePetitionStatus = (status) => {
  return petitionTranslations[status] || 'Completado';
};

export const textColorStatus = (status) => {
  return textColor[status] || '#ccc';
};
