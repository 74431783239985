import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import UberButton from '../../../components/ui/UberButton';

const ActionDecider = ({ changeStage, setOpenModal }) => {
  const linkUber = () => {
    changeStage('link');
  };

  const modalUberHero = () => {
    setOpenModal(true);
  };

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      <UberButton onClick={modalUberHero} content="Crear cuenta Uber" />
      <UberButton onClick={linkUber} content="Vincular cuenta Uber" />
    </Box>
  );
};

ActionDecider.defaultProps = {
  changeStage: () => {},
  setOpenModal: () => {},
};

ActionDecider.propTypes = {
  changeStage: PropTypes.func,
  setOpenModal: PropTypes.func,
};

export default ActionDecider;
