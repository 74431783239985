import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton } from '@mui/material';
import { Button, Checkbox, Typography } from '@tucar/pit-stop';
import CloseIcon from '@mui/icons-material/Close';
import { azulTucar } from '../../../themes/variants/TucarColors';

const MapFilterSlide = ({ open, onAccept, onClear, onClose, statusProp }) => {
  const [status, setStatus] = useState(statusProp);

  const handleClose = () => {
    setStatus(statusProp);
    onClose();
  };

  const handleClear = () => {
    setStatus({});
    onClear();
  };

  const handleAccept = () => {
    if (!status) onClear();
    onAccept(status);
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        zIndex: 3,
        top: '0px',
        right: '0px',
        transform: `translateX(${open ? '0%' : '100%'})`,
        transition: 'transform 0.5s',
        width: 'max-content',
        height: '100vh',
        backgroundColor: '#fff',
        padding: '63px 24px',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        borderRight: '1px solid rgba(0, 0, 0, 0.10)',
      }}
    >
      <Box sx={{ display: 'flex', gap: '36px', alignItems: 'center' }}>
        <Typography style={{ fontWeight: 600, fontSize: '16px', color: azulTucar }}>
          Filtros de búsqueda
        </Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon sx={{ color: '#5B5D71' }} />
        </IconButton>
      </Box>
      <Box
        sx={{
          height: '2px',
          width: '100%',
          backgroundColor: '#F6F6F6',
          margin: '36px 0px',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          width: '100%',
        }}
      >
        <Checkbox
          checked={status === 'DC'}
          onChange={(isChecked) => setStatus(isChecked ? 'DC' : null)}
          label="Carga rápida"
        />
        <Checkbox
          checked={status === 'AC'}
          onChange={(isChecked) => setStatus(isChecked ? 'AC' : null)}
          label="Carga lenta"
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          paddingTop: '90px',
        }}
      >
        <Button onClick={handleClear} size="small" variant="ghost">
          Limpiar
        </Button>
        <Button onClick={handleAccept} size="small">
          Aplicar
        </Button>
      </Box>
    </Box>
  );
};

MapFilterSlide.propTypes = {
  open: PropTypes.bool,
  onAccept: PropTypes.func,
  onClear: PropTypes.func,
  onClose: PropTypes.func,
  statusProp: PropTypes.string,
};

MapFilterSlide.defaultProps = {
  open: false,
  statusProp: null,
  onAccept: () => {},
  onClear: () => {},
  onClose: () => {},
};

export default MapFilterSlide;
