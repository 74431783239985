import { azulTucar } from '../../../themes/variants/TucarColors';

export const getColorShift = (type) => {
  if (type === 'PM') return '#95D31C';
  if (type === 'AM') return '#1F89FF';
  if (type === 'ALL' || type === 'TC') return '#142239';
  if (type === 'FREE' || type === 'TL') return '#CFD0D8';
  return azulTucar;
};

export const translateShift = (type) => {
  if (type === 'ALL') return 'TC';
  if (type === 'FREE') return 'TL';
  return type;
};

export const translateLongShift = (type) => {
  if (type === 'ALL' || type === 'TC') return 'Turno Completo';
  if (type === 'FREE' || type === 'TL') return 'Turno Libre';
  if (type === 'AM') return 'Turno AM';
  if (type === 'PM') return 'Turno PM';
  return type;
};

export const translateStatus = (status) => {
  switch (status) {
    case 'created':
    case 'pending':
      return 'Pendiente';
    case 'accepted':
      return 'Aceptada';
    case 'rejected':
      return 'Rechazada';
    default:
      return 'Completado';
  }
};

export const statusColor = (status) => {
  switch (status) {
    case 'created':
    case 'pending':
      return '#FFC107';
    case 'accepted':
      return '#1F89FF';
    case 'rejected':
      return '#FF2300';
    default:
      return '#71758E';
  }
};

export const getPartner = (driversData, uid) => {
  const partner = driversData.find(({ _id: ident }) => ident !== uid);
  return partner;
};

export const getPartnerId = (driversData, uid) => {
  const partner = driversData.find(({ _id: ident }) => ident !== uid);
  const { _id: ident } = partner || { _id: '' };
  return ident;
};
