import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import LogOutSubmit from './auth/SubmitLogOut';

const ErrorScreen = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#FFFFFF',
        borderRadius: '9px',
        minHeight: '100vh',
        paddingInline: '20px',
      }}
    >
      <Box sx={{ width: '450px', maxWidth: '100vw' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: '0px 20px 20px 20px',
            paddingInline: '20px',
          }}
        >
          <Typography sx={{ marginTop: '35px', fontFamily: 'Poppins-Medium' }} variant="h2">
            Ha ocurrido un error
          </Typography>
          <Typography sx={{ marginTop: '35px', fontFamily: 'Poppins-Regular' }} variant="h3">
            Vuelva a intentar más tarde o llame a soporte para notificarnos del problema.
          </Typography>
        </Box>
        <Divider
          sx={{
            marginBottom: '20px',
            marginTop: '20px',
            borderBottomWidth: '1px',
            borderBottomColor: '#F9F9F9',
          }}
        />
        <LogOutSubmit />
      </Box>
    </Box>
  );
};

export default ErrorScreen;
