import React from 'react';
import PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { IconButton, TucarTheme } from '@tucar/pit-stop';
import { getDriverStore } from '../../../graphql/store';

const TopNav2 = ({ prevPage, title, handlePageChange, color, backgroundColor }) => {
  const navigate = useNavigate();
  const isString = (value) => typeof value === 'string' || value instanceof String;
  const { labelV2 } = useReactiveVar(getDriverStore);

  return (
    <Box
      sx={{
        backgroundColor,
        paddingTop: '50px',
        paddingBottom: '30px',
        display: 'grid',
        paddingInline: '30px',
        gridTemplateColumns: '40px 1fr 40px',
      }}
    >
      <IconButton
        size="small"
        iconProps={{
          name: 'leftArrowSpecial',
          size: '18px',
          color: '#A8AABA',
        }}
        onClick={() => {
          handlePageChange();
          navigate(labelV2 && prevPage === '/app/dashboard' ? '/app/v2/dashboard' : prevPage, {
            replace: true,
          });
        }}
      />
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {isString(title) ? (
          <Typography
            align="center"
            fontFamily="Poppins-SemiBold"
            sx={{ color: color || TucarTheme.colors.gray700, lineHeight: 1.2, paddingLeft: '20px' }}
          >
            {title}
          </Typography>
        ) : (
          title
        )}
      </Box>
    </Box>
  );
};

TopNav2.propTypes = {
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  prevPage: PropTypes.string,
  handlePageChange: PropTypes.func,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
};

TopNav2.defaultProps = {
  title: '',
  prevPage: '/app/dashboard',
  handlePageChange: () => {},
  color: TucarTheme.colors.gray700,
  backgroundColor: 'transparent',
};

export default TopNav2;
