import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import moment from 'moment';

import {
  Box,
  TableCell,
  TableRow,
  Typography,
  TableHead,
  Table,
  TableContainer,
  TableBody,
} from '@mui/material';
import { Loader } from '@tucar/pit-stop';
import GET_DRIVER_BILLWEEK_LIMIT_DATES from '../../../../graphql/querys/balance/getSpecificBillDay';
import { formatterCLP } from '../../../../helpers/formatters';
import SectionKmsProgressBar from '../../../../components/info/SectionKms';
import InfoModal from '../../../../components/info/infoModal';
import TagList from '../../../../components/info/TagList';
import EventList from '../eventList';
import stylesTable from '../style';
import UberBreakdownTable from './uberBreackdown';
import ExpensesTable from './expenses';
import IncomeEventsTable from './incomeEvents';
import { azulTucar } from '../../../../themes/variants/TucarColors';
import ChargesTable from './charge';

const BalanceLiquidation = ({ uid, dateParam, debtParam, prePayParam }) => {
  const [billDays, setBillDays] = useState([]);
  const [date, setDate] = useState('');
  const [total, setTotal] = useState(0);
  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [incomeEvents, setIncomeEvents] = useState([]);
  const [outcomeEvents, setOutcomeEvents] = useState([]);
  const [tagsInfo, setTagsInfo] = useState([]);

  const initialDatetime = moment(dateParam, 'DD/MM/YYYY')
    .subtract(7, 'days')
    .format('YYYY-MM-DD[T00:00:00]');

  const finalDatetime = moment(dateParam, 'DD/MM/YYYY')
    .subtract(1, 'days')
    .format('YYYY-MM-DD[T00:00:00]');

  const { data: allBillDays, loading } = useQuery(GET_DRIVER_BILLWEEK_LIMIT_DATES.query, {
    ...GET_DRIVER_BILLWEEK_LIMIT_DATES.policies,
    variables: {
      uid,
      initialDatetime,
      finalDatetime,
    },
  });

  const handleModalContent = (type) => {
    switch (type) {
      case 'kmCost':
        setModalContent(<SectionKmsProgressBar billDays={billDays} setOpen={setOpen} />);
        setOpen(true);
        return 'kmCost';
      case 'tags':
        setModalContent(<TagList tags={tagsInfo} setOpen={setOpen} />);
        setOpen(true);
        return 'tags';
      case 'incomeEvents':
        setModalContent(
          <EventList titleView="Eventos de abono" events={incomeEvents} setOpen={setOpen} />,
        );
        setOpen(true);
        return 'incomeEvents';
      case 'fineEvents':
        setModalContent(
          <EventList
            titleView="Eventos de multas"
            events={outcomeEvents}
            filterFunc={(event) => event.subject === 'Control'}
            setOpen={setOpen}
          />,
        );
        setOpen(true);
        return 'fineEvents';
      case 'penaltysEvents':
        setModalContent(
          <EventList
            titleView="Eventos de sanciones"
            events={outcomeEvents}
            filterFunc={(event) => event.subject === 'Exchange'}
            setOpen={setOpen}
          />,
        );
        setOpen(true);
        return 'penaltysEvents';
      case 'otherEvents':
        setModalContent(
          <EventList
            titleView="Otros eventos de cobro"
            events={outcomeEvents}
            filterFunc={(event) => !['Control', 'Exchange'].includes(event.subject)}
            setOpen={setOpen}
          />,
        );
        setOpen(true);
        return 'penaltysEvents';
      default:
        setModalContent(<Box>Sucedió un error</Box>);
        setOpen(true);
        return 'default';
    }
  };

  useEffect(() => {
    if (allBillDays && allBillDays?.getDriverBillDaysLimitDates) {
      const billDaysAux = allBillDays.getDriverBillDaysLimitDates.toReversed();
      setBillDays(billDaysAux);
      setDate(
        `${moment(initialDatetime, 'YYYY-MM-DD[T00:00:00]').format('DD')} al ${moment(
          finalDatetime,
          'YYYY-MM-DD[T00:00:00]',
        ).format('DD [de] MMMM, YYYY')}`,
      );
      const eventsOutcomeAux = billDaysAux.reduce(
        (acc, elem) => [
          ...acc,
          ...elem.events
            .map((e) => {
              if (e.charged.ref === uid) return e;
              return null;
            })
            .filter((e) => e !== null),
        ],
        [],
      );
      const eventsIncomeAux = billDaysAux.reduce(
        (acc, elem) => [
          ...acc,
          ...elem.events
            .map((e) => {
              if (e.charged.ref !== uid) return e;
              return null;
            })
            .filter((e) => e !== null),
        ],
        [],
      );
      setIncomeEvents(eventsIncomeAux);
      setOutcomeEvents(eventsOutcomeAux);
      setTagsInfo(billDaysAux.reduce((acc, elem) => [...acc, ...elem.tag], []));

      const totalAux = billDaysAux.reduce((acc, elem) => {
        const {
          incomes: { __typename: incomeType, ...restIncomes },
          expenses: { __typename: expenseType, ...restExpenses },
          installment,
        } = elem;
        const totalIncomes = Object.values(restIncomes).reduce(
          (accIn, current) => accIn + current,
          0,
        );
        const totalExpenses = Object.values(restExpenses).reduce(
          (accEx, current) => accEx + current,
          0,
        );
        return acc + totalIncomes - totalExpenses - installment;
      }, 0);

      setTotal(totalAux + prePayParam - debtParam);
    }
  }, [allBillDays]);

  if (loading) {
    return (
      <Box sx={stylesTable.floatingCenterScreen}>
        <Loader size={32} pointRadio="4px" />
      </Box>
    );
  }
  if (!date) {
    return (
      <Box sx={stylesTable.floatingCenterScreen}>
        <Typography sx={{ fontFamily: 'Poppins-Regular', textAlign: 'center', fontSize: '16px' }}>
          No hay balance para mostrar
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <InfoModal open={open} content={modalContent} />
      <Box
        sx={{
          background: 'linear-gradient(180deg,#003066 50%, #FFF 50%)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          gap: '20px',
          paddingY: '20px',
        }}
      >
        <Box
          sx={{
            backgroundColor: '#FFF',
            marginInline: '20px',
            height: '50px',
            borderRadius: '4px',
            border: '2px solid #E3EDF7',
            boxShadow:
              '4px 4px 20px 0px rgba(111, 140, 176, 0.41), 2px 2px 4px 0px rgba(114, 142, 171, 0.10)',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Poppins-Medium',
              fontSize: '18px',
              textAlign: 'center',
              lineHeight: '50px',
              color: total > 0 ? '#7FB418' : '#FF7B66',
            }}
          >
            Total {formatterCLP.format(total)}
          </Typography>
        </Box>

        <TableContainer
          sx={{
            borderTopLeftRadius: '14px',
            maxWidth: '100vw',
            '.MuiTableCell-root': { border: '0px solid transparent' },
          }}
        >
          <Table style={{ left: 0, minWidth: '100%', background: '#FFF', borderRadius: '14px' }}>
            <TableHead>
              <TableRow
                sx={{
                  background: '#0057B8',
                }}
              >
                <TableCell
                  sx={{
                    ...stylesTable.stickyHeader,
                    backgroundColor: '#0057B8',
                    display: 'block',
                    boxShadow: '-2px 10px 15px -1px rgba(60, 60, 60, 1.2)',
                  }}
                >
                  <Typography sx={stylesTable.headerCell}>Fecha</Typography>
                </TableCell>
                {billDays.map((elem) => {
                  const dayNumber = moment(elem.date).format('DD/MM');
                  return (
                    <TableCell
                      key={dayNumber}
                      align="center"
                      sx={{
                        ...stylesTable.headerCell,
                        padding: '10px 20px',
                      }}
                    >
                      {dayNumber}
                    </TableCell>
                  );
                })}
                <TableCell
                  align="center"
                  sx={{
                    ...stylesTable.headerCell,
                    padding: '10px 20px',
                    borderLeft: '2px solid #ccc !important',
                    '&:last-child': {
                      borderTopRightRadius: '14px',
                    },
                  }}
                >
                  Total
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <UberBreakdownTable billDays={billDays} />

              <TableRow sx={{ borderTop: '1px solid #7FB418 !important' }}>
                <TableCell
                  sx={{
                    ...stylesTable.positiveText,
                    ...stylesTable.stickyHeader,
                    padding: '10px 40px',
                  }}
                >
                  Total recaudaciones en Uber
                </TableCell>

                {billDays.map((elem, index) => {
                  const {
                    incomes: { uber },
                  } = elem;
                  const key = `TotalIngresos-${index}`;
                  return (
                    <TableCell
                      key={key}
                      align="center"
                      sx={{
                        ...stylesTable.positiveText,
                      }}
                    >
                      {formatterCLP.format(uber)}
                    </TableCell>
                  );
                })}

                <TableCell
                  align="center"
                  sx={{
                    ...stylesTable.positiveText,
                    borderLeft: '2px solid #ccc !important',
                  }}
                >
                  {formatterCLP.format(billDays.reduce((acc, elem) => elem.incomes.uber + acc, 0))}
                </TableCell>
              </TableRow>

              <IncomeEventsTable
                billDays={billDays}
                incomeEvents={incomeEvents}
                handleModalContent={handleModalContent}
              />

              <TableRow sx={{ borderTop: '1px solid #7FB418 !important' }}>
                <TableCell
                  sx={{
                    ...stylesTable.positiveText,
                    ...stylesTable.stickyHeader,
                    padding: '10px 40px',
                  }}
                >
                  Total abono por eventos
                </TableCell>

                {billDays.map((billday, index) => {
                  const key = `Abono-${index}`;
                  const { events } = billday;
                  const totalDay = incomeEvents.reduce((acc, event) => {
                    if (events.includes(event)) {
                      return acc + event.amount;
                    }
                    return acc;
                  }, 0);

                  return (
                    <TableCell key={key} align="center" sx={stylesTable.positiveText}>
                      {formatterCLP.format(totalDay)}
                    </TableCell>
                  );
                })}

                <TableCell
                  align="center"
                  sx={{
                    ...stylesTable.positiveText,
                    borderLeft: '2px solid #ccc !important',
                  }}
                >
                  {formatterCLP.format(incomeEvents.reduce((acc, curr) => curr.amount + acc, 0))}
                </TableCell>
              </TableRow>

              <ExpensesTable billDays={billDays} handleModalContent={handleModalContent} />
              <TableRow sx={{ borderTop: '1px solid #FF2300 !important' }}>
                <TableCell
                  sx={{
                    ...stylesTable.stickyHeader,
                    ...stylesTable.negativeText,
                    padding: '10px 40px',
                  }}
                >
                  Total costos
                </TableCell>
                {billDays.map((elem, index) => {
                  const key = `total-${index}`;
                  const {
                    expenses: { rent },
                  } = elem;

                  return (
                    <TableCell
                      key={key}
                      align="center"
                      sx={{
                        ...stylesTable.negativeText,
                      }}
                    >
                      {formatterCLP.format(rent)}
                    </TableCell>
                  );
                })}
                <TableCell
                  align="center"
                  sx={{ ...stylesTable.negativeText, ...stylesTable.totalCell }}
                >
                  {formatterCLP.format(billDays.reduce((acc, elem) => elem.expenses.rent + acc, 0))}
                </TableCell>
              </TableRow>

              <ChargesTable billDays={billDays} handleModalContent={handleModalContent} />
              <TableRow sx={{ borderTop: '1px solid #FF2300 !important' }}>
                <TableCell
                  sx={{
                    ...stylesTable.stickyHeader,
                    ...stylesTable.negativeText,
                    padding: '10px 40px',
                  }}
                >
                  Total cargos
                </TableCell>
                {billDays.map((elem, index) => {
                  const key = `total-${index}`;
                  const {
                    expenses: { penaltys, tag, fines, other },
                  } = elem;

                  return (
                    <TableCell
                      key={key}
                      align="center"
                      sx={{
                        ...stylesTable.negativeText,
                      }}
                    >
                      {formatterCLP.format(penaltys + tag + fines + other)}
                    </TableCell>
                  );
                })}
                <TableCell
                  align="center"
                  sx={{ ...stylesTable.negativeText, ...stylesTable.totalCell }}
                >
                  {formatterCLP.format(
                    billDays.reduce(
                      (acc, elem) =>
                        elem.expenses.penaltys +
                        elem.expenses.tag +
                        elem.expenses.fines +
                        elem.expenses.other +
                        acc,
                      0,
                    ),
                  )}
                </TableCell>
              </TableRow>

              <TableRow
                sx={{ borderTop: `1px solid ${azulTucar}`, borderBottom: `1px solid ${azulTucar}` }}
              >
                <TableCell
                  sx={{
                    ...stylesTable.negativeText,
                    ...stylesTable.stickyHeader,
                    padding: '10px 20px',
                    color: azulTucar,
                  }}
                >
                  Total diario (ingresos, costos y cargos)
                </TableCell>
                {billDays.map((elem, index) => {
                  const key = `total-real-${index}`;
                  const {
                    incomes: { __typename: incomeType, ...restIncomes },
                    expenses: { __typename: expenseType, guarantee, ...restExpenses },
                  } = elem;
                  const totalIncomes = Object.values(restIncomes).reduce(
                    (acc, current) => acc + current,
                    0,
                  );
                  const totalExpenses = Object.values(restExpenses).reduce(
                    (acc, current) => acc + current,
                    0,
                  );

                  return (
                    <TableCell
                      key={key}
                      align="center"
                      sx={{
                        ...stylesTable.positiveText,
                        color: azulTucar,
                      }}
                    >
                      {formatterCLP.format(totalIncomes - totalExpenses)}
                    </TableCell>
                  );
                })}

                <TableCell
                  align="center"
                  sx={{
                    ...stylesTable.positiveText,
                    color: azulTucar,
                    ...stylesTable.totalCell,
                  }}
                >
                  {formatterCLP.format(
                    billDays.reduce((acc, elem) => {
                      const {
                        incomes: { __typename: incomeType, ...restIncomes },
                        expenses: { __typename: expenseType, guarantee, ...restExpenses },
                      } = elem;
                      const totalIncomes = Object.values(restIncomes).reduce(
                        (accIn, current) => accIn + current,
                        0,
                      );
                      const totalExpenses = Object.values(restExpenses).reduce(
                        (accEx, current) => accEx + current,
                        0,
                      );
                      return totalIncomes - totalExpenses + acc;
                    }, 0),
                  )}
                </TableCell>
              </TableRow>

              <TableRow sx={{ backgroundColor: '#E3EDF7' }}>
                <TableCell sx={{ ...stylesTable.headerColumn, ...stylesTable.stickyHeader }}>
                  Deuda anterior
                </TableCell>
                {billDays.map((_, index) => {
                  const key = `debt-${index}`;
                  return <TableCell key={key} />;
                })}

                <TableCell
                  align="center"
                  sx={{ ...stylesTable.regularText, ...stylesTable.totalCell }}
                >
                  {formatterCLP.format(debtParam)}
                </TableCell>
              </TableRow>

              <TableRow sx={{ backgroundColor: '#E3EDF7' }}>
                <TableCell sx={{ ...stylesTable.headerColumn, ...stylesTable.stickyHeader }}>
                  Prepago
                </TableCell>
                {billDays.map((_, index) => {
                  const key = `prepay-${index}`;
                  return <TableCell key={key} />;
                })}
                <TableCell
                  align="center"
                  sx={{ ...stylesTable.regularText, ...stylesTable.totalCell }}
                >
                  {formatterCLP.format(prePayParam)}
                </TableCell>
              </TableRow>

              <TableRow sx={{ backgroundColor: '#E3EDF7' }}>
                <TableCell sx={{ ...stylesTable.headerColumn, ...stylesTable.stickyHeader }}>
                  Garantía
                </TableCell>
                {billDays.map((_, index) => {
                  const key = `guarantee-${index}`;
                  return <TableCell key={key} />;
                })}
                <TableCell
                  align="center"
                  sx={{ ...stylesTable.regularText, ...stylesTable.totalCell }}
                >
                  {formatterCLP.format(
                    billDays.reduce(
                      (acc, current) => (acc >= 0 ? current.expenses.guarantee : acc),
                      0,
                    ),
                  )}
                </TableCell>
              </TableRow>

              <TableRow sx={{ backgroundColor: '#E3EDF7' }}>
                <TableCell sx={{ ...stylesTable.headerColumn, ...stylesTable.stickyHeader }}>
                  Deducible
                </TableCell>
                {billDays.map((_, index) => {
                  const key = `installment-${index}`;
                  return <TableCell key={key} />;
                })}
                <TableCell
                  align="center"
                  sx={{ ...stylesTable.regularText, ...stylesTable.totalCell }}
                >
                  {formatterCLP.format(
                    billDays.reduce(
                      (acc, current) => (acc >= 0 ? acc + current.installment : acc),
                      0,
                    ),
                  )}
                </TableCell>
              </TableRow>

              <TableRow sx={{ borderTop: '1px solid #17171C !important' }}>
                <TableCell
                  sx={{
                    ...stylesTable.headerColumn,
                    ...stylesTable.stickyHeader,
                    padding: '10px 20px',
                  }}
                >
                  Total de haberes / deberes
                </TableCell>
                {billDays.map((_, index) => {
                  const key = `total-real-${index}`;
                  return <TableCell key={key} />;
                })}
                <TableCell
                  align="center"
                  sx={{ ...stylesTable.regularText, ...stylesTable.totalCell }}
                >
                  {formatterCLP.format(total)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

BalanceLiquidation.propTypes = {
  uid: PropTypes.string,
  dateParam: PropTypes.string,
  debtParam: PropTypes.number,
  prePayParam: PropTypes.number,
};

BalanceLiquidation.defaultProps = {
  uid: '',
  dateParam: '',
  debtParam: 0,
  prePayParam: 0,
};

export default BalanceLiquidation;
