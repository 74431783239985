import { gql } from '@apollo/client';
import { setVerifiedList } from '../../store';

const UPDATE_TERMS = {
  mutation: gql`
    mutation UpdateVerifiedListDriver($uid: ID!, $tc: Boolean) {
      updateVerifiedListDriver(uid: $uid, tc: $tc) {
        verifiedList {
          tc
        }
      }
    }
  `,
  policies: {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    onCompleted: (response) => {
      if (response.updateVerifiedListDriver) {
        const { verifiedList } = response.updateVerifiedListDriver;
        setVerifiedList(verifiedList);
      }
    },
    errorPolicie: 'ignore',
  },
};

export default UPDATE_TERMS;
