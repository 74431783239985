import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Box, Typography, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { formatterCLP } from '../../../helpers/formatters';

const TagList = ({ tags, setOpen }) => {
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            width: '100%',
            textAlign: 'center',
            fontSize: '16px',
            fontFamily: 'Poppins-Medium',
            color: '#5B5D71',
          }}
        >
          TAGs semanales
        </Typography>
        <IconButton onClick={() => setOpen(false)}>
          <ClearIcon color="#5B5D71" />
        </IconButton>
      </Box>

      {tags.length === 0 ? (
        <Box sx={{ marginTop: '20px' }}>
          <Typography
            sx={{
              textAlign: 'left',
              fontFamily: 'Poppins-Medium',
              fontSize: '14px',
              color: '#5B5D71',
              lineHeight: 1.2,
            }}
          >
            No existen Tags registrados
          </Typography>
        </Box>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', padding: '10px' }}>
          {tags.map((tag, index) => {
            const customKey = `${index}-${tag.datetimeWindow}`;
            return (
              <Box
                key={customKey}
                sx={{
                  padding: '26px 24px',
                  borderRadius: '14px',
                  boxShadow:
                    '4px 4px 20px 0px rgba(111, 140, 176, 0.41), -6px -6px 20px 0px #FFF, 2px 2px 4px 0px rgba(114, 142, 171, 0.10)',
                }}
              >
                <Typography
                  sx={{
                    textAlign: 'left',
                    fontFamily: 'Poppins-Medium',
                    fontSize: '14px',
                    color: '#5B5D71',
                    lineHeight: 1.2,
                  }}
                >
                  {moment(tag.datetimeWindow).format('DD/MM/YYYY')} a las{' '}
                  {moment(tag.datetimeWindow).format('HH:mm')}
                </Typography>
                <Typography
                  sx={{
                    textAlign: 'left',
                    fontFamily: 'Poppins-Bold',
                    fontSize: '16px',
                    color: '#0057B8',
                    lineHeight: 1.2,
                  }}
                >
                  {tag.address}
                </Typography>
                <Typography
                  sx={{
                    textAlign: 'left',
                    fontFamily: 'Poppins-Medium',
                    fontSize: '14px',
                    color: '#5B5D71',
                    lineHeight: 1.2,
                  }}
                >
                  Total: {formatterCLP.format(tag.amount)}
                </Typography>
              </Box>
            );
          })}
        </Box>
      )}
    </Box>
  );
};

TagList.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.shape({})),
  setOpen: PropTypes.func,
};

TagList.defaultProps = {
  tags: [],
  setOpen: () => {},
};

export default TagList;
