import React from 'react';
import PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';
import { Box, Typography, Divider, Drawer, Toolbar, List } from '@mui/material';

import tucarLogo from '../../../../public/images/logotipo.svg';
import ProfilePhoto from '../../../../public/images/profileImage.svg';
import { getAuthStore, getDriverStore } from '../../../graphql/store';
import ExchangeContentList from './changeContent';
import OldLogOut from '../../../components/auth/OldLogOutButton';

function SideBar({ mobile: { mobileOpen, setMobileOpen } }) {
  const { data } = useReactiveVar(getAuthStore);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const { photo } = useReactiveVar(getDriverStore);
  const drawer = (
    <Box margin="10px">
      <Toolbar
        sx={{ display: 'flex', justifyContent: 'center', marginTop: '10px', marginBottom: '15px' }}
      >
        <img src={tucarLogo} alt="tucarWhiteLogo" style={{ height: '30px' }} />
      </Toolbar>
      <Box
        marginBottom="20px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="row"
      >
        <img
          src={photo || ProfilePhoto}
          alt="profilePhoto"
          style={{
            objectFit: 'cover',
            height: '60px',
            width: '60px',
            borderRadius: '50px',
            marginRight: '10px',
          }}
        />
        <Box>
          <Typography sx={{ lineHeight: 1 }} fontFamily="Poppins-Medium">
            {data.firstname} {data.lastname}
          </Typography>
          <Typography fontFamily="Poppins-Light" fontSize="14px">
            {' '}
            {data.email}{' '}
          </Typography>
          <OldLogOut />
        </Box>
      </Box>
      <Divider />
      <List>
        <ExchangeContentList handleDrawerToggle={setMobileOpen} />
      </List>
    </Box>
  );

  const contenedor = document.getElementById('drawer-container');

  return (
    <Drawer
      anchor="bottom"
      variant="temporary"
      open={mobileOpen}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true,
        container: contenedor,
        style: { position: 'fixed' },
      }}
      PaperProps={{
        style: {
          borderRadius: '15px 15px 0px 0px',
        },
      }}
    >
      {drawer}
    </Drawer>
  );
}

SideBar.propTypes = {
  // eslint-disable-next-line react/require-default-props
  mobile: PropTypes.shape({
    mobileOpen: PropTypes.bool,
    setMobileOpen: PropTypes.func,
  }),
};

export default SideBar;
