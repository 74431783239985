import React, { useEffect, useState } from 'react';
import { Box, Divider, useMediaQuery } from '@mui/material';
import { useOutletContext } from 'react-router-dom';

import ErrorModal from '../../../components/ui/ErrorModal';
import MailSent from '../../../components/auth/mailSent';
import OnBoardingTitle from '../../../components/extras/OnboardingTitle';
import ForgotForm from '../../../components/auth/ForgotForm';

const Forgot = () => {
  const [formView, setFormView] = useState(false);
  const [handlePageChange] = useOutletContext();
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState('');
  const [values, setValues] = useState({ email: '' });
  const match = useMediaQuery('(max-width:700px)');

  useEffect(() => {
    handlePageChange();
  }, []);

  return (
    <Box sx={{ height: '100vh', backgroundColor: '#FFF' }}>
      <ErrorModal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        content={content}
      />
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'column wrap',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
          gap: '20px',
          paddingInline: match ? '20px' : '0px',
        }}
      >
        {!formView ? (
          <>
            <OnBoardingTitle
              title="¿Olvidaste tu contraseña?"
              subtitle="Ingresa tu correo para recibir un enlace con el cual podrás restablecer tu contraseña"
              showLogo
            />
            <Divider
              sx={{
                width: '100%',
                color: '#EAEAEA',
              }}
            />
            <ForgotForm
              setFormView={setFormView}
              setOpen={setOpen}
              setContent={setContent}
              values={values}
              setValues={setValues}
            />
          </>
        ) : (
          <>{values.email ? <MailSent email={values.email} /> : null}</>
        )}
      </Box>
    </Box>
  );
};

export default Forgot;
