import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Marker, InfoWindow } from '@react-google-maps/api';
import { Box, Typography } from '@mui/material';

const momentTimezone = require('moment-timezone');

const MapMarker = ({ position, title, setCenter, icon }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleOpen = () => {
    setIsOpen((wasOpen) => !wasOpen);
    if (isOpen) {
      setCenter(position);
    }
  };

  return (
    <Marker position={position} title={title} onClick={handleToggleOpen} icon={icon}>
      {isOpen && (
        <InfoWindow onCloseClick={handleToggleOpen}>
          <Box>
            <Typography className="PoppinsRegular">{title}</Typography>
          </Box>
        </InfoWindow>
      )}
    </Marker>
  );
};

MapMarker.propTypes = {
  position: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }).isRequired,
  title: PropTypes.string.isRequired,
  updateData: PropTypes.shape({
    timestamp: PropTypes.string,
    received: PropTypes.string,
  }),
  setCenter: PropTypes.func.isRequired,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
};

MapMarker.defaultProps = {
  icon: undefined,
  updateData: {
    timestamp: momentTimezone().tz('America/Santiago').format('YYYY-MM-DD HH:mm:ss'),
    received: momentTimezone().tz('America/Santiago').format('YYYY-MM-DD HH:mm:ss'),
  },
};

export default MapMarker;
