import { driverStore } from './model';

export const setInfo = (info) => {
  driverStore({ ...driverStore(), info });
};

export const setRent = (rent) => {
  driverStore({ ...driverStore(), rent });
};

export const setPhoto = (photo) => {
  driverStore({ ...driverStore(), photo });
};

export const setStatus = (status) => {
  driverStore({ ...driverStore(), status });
};

export const setVerifiedList = (verifiedList) => {
  driverStore({ ...driverStore(), verifiedList });
};

export const setCategory = (category) => {
  driverStore({ ...driverStore(), category });
};

export const setLabelV2 = (labelV2 = false) => {
  driverStore({ ...driverStore(), labelV2 });
};

export const setDriver = ({ info, status, verifiedList, category, photo, rent }) => {
  driverStore({
    ...driverStore(),
    info,
    status,
    verifiedList,
    category,
    photo,
    rent,
  });
};

export const resetDriver = () => {
  driverStore({
    ...driverStore(),
    labelV2: false,
    photo: '',
    rent: {
      carId: '',
    },
    info: {
      address: '',
      adrapt: '',
      adrnumber: '',
      birth: '',
      city: '',
      code: '',
      commune: '',
      country: '',
      email: '',
      firstname: '',
      lastname: '',
      phone: '',
      region: '',
      rut: '',
      gender: '',
      parking: '',
      secondPhone: '',
      emergencyPhone: '',
      situation: false,
      source: '',
    },
    status: {
      banned: false,
      deleted: false,
      enabled: false,
      substatus: false,
      status: false,
      verified: false,
    },
    verifiedList: null,
    category: {
      uberPro: false,
      discount: false,
    },
  });
};
