import React from 'react';
import PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { getDriverStore } from '../../../../graphql/store';
import { azulTucar } from '../../../../themes/variants/TucarColors';
import StepOnboardingComponent from '../StepOnboarding';

const filterSteps = ['auth', 'meeting'];

const menuOnBoarding = ({ setStep }) => {
  const {
    verifiedList,
    info: { firstname },
  } = useReactiveVar(getDriverStore);

  const notReady = Object.entries(verifiedList).filter(
    (verified) => !verified[1] && !filterSteps.includes(verified[0]),
  );
  const ready = Object.entries(verifiedList).filter(
    (verified) => verified[1] && !filterSteps.includes(verified[0]),
  );

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateRows: 'max-content 1fr max-content',
        gap: '10px',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <Typography sx={{ fontFamily: 'Poppins-Medium', textAlign: 'start', color: azulTucar }}>
          ¡Hola, {firstname}!
        </Typography>
        <Typography sx={{ fontFamily: 'Poppins-Regular', fontSize: '13px' }}>
          Completa los siguientes pasos para terminar tu registro.
        </Typography>
      </Box>

      <Box sx={{ maxHeight: '100%', display: 'grid', gridTemplateRows: 'auto', gap: '10px' }}>
        {notReady.length > 0 && (
          <Typography
            sx={{
              fontFamily: 'Poppins-Medium',
              textAlign: 'start',
              color: azulTucar,
              fontSize: '15px',
            }}
          >
            Pasos restantes:
          </Typography>
        )}
        {notReady.map((elem) => {
          return typeof elem[1] === 'boolean' ? (
            <StepOnboardingComponent
              setStep={setStep}
              key={elem[0]}
              type={elem[0]}
              check={elem[1]}
            />
          ) : null;
        })}

        {ready.length > 0 && (
          <Typography
            sx={{
              fontFamily: 'Poppins-Medium',
              textAlign: 'start',
              color: azulTucar,
              fontSize: '15px',
            }}
          >
            Completados:
          </Typography>
        )}
        {ready.map((elem) => {
          return typeof elem[1] === 'boolean' ? (
            <StepOnboardingComponent
              setStep={setStep}
              key={elem[0]}
              type={elem[0]}
              check={elem[1]}
            />
          ) : null;
        })}
      </Box>
    </Box>
  );
};

menuOnBoarding.propTypes = {
  setStep: PropTypes.func,
};

menuOnBoarding.defaultProps = {
  setStep: () => {},
};
export default menuOnBoarding;
