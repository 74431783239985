import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  FormHelperText,
} from '@mui/material';

export default function MultiSelectEditable({
  size,
  handleChange,
  label,
  name,
  value,
  edit,
  dataArr,
  required,
  formStyle,
  containerStyle,
  disabled,
  error,
  textError,
}) {
  return (
    <Box fullWidth sx={{ ...containerStyle, textAlign: 'left', textOverflow: 'ellipsis' }}>
      {edit ? (
        <FormControl
          error={error}
          size={size}
          fullWidth
          required={required}
          disabled={!dataArr || disabled}
          sx={{ ...formStyle, '& .MuiOutlinedInput-root': { paddingInline: '8px' } }}
        >
          <InputLabel
            sx={{
              fontFamily: 'Poppins-Regular',
              fontSize: '14px',
              marginRight: '5px',
              backgroundColor: '#FFF',
            }}
          >
            {label}
          </InputLabel>
          {label ? (
            <Select
              sx={{ borderRadius: '55px', height: '50px' }}
              value={value || ''}
              label={label}
              onChange={(e) => handleChange(name, e.target.value)}
            >
              {dataArr.map((data, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <MenuItem value={data.value || data.name} key={index}>
                  <Typography fontFamily="Poppins-Regular" sx={{ fontSize: '14px' }} noWrap>
                    {data.name}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          ) : (
            <Select
              sx={{ borderRadius: '55px' }}
              value={value || ''}
              onChange={(e) => handleChange(name, e.target.value)}
            >
              {dataArr.map((data) => (
                <MenuItem value={data.value || data.name} key={data.id}>
                  <Typography fontFamily="Poppins-Regular" noWrap>
                    {data.name}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          )}
          {error ? (
            <FormHelperText sx={{ fontFamily: 'Poppins-Regular', lineHeight: 1 }} error>
              {textError}
            </FormHelperText>
          ) : null}
        </FormControl>
      ) : (
        <TextField
          fullWidth
          size={size}
          type="text"
          sx={{ margin: '0px', borderRadius: '55px' }}
          variant="outlined"
          label={label}
          value={value}
          InputLabelProps={{ style: { fontFamily: 'Poppins-Regular' } }}
          InputProps={{
            style: { fontFamily: 'Poppins-Regular' },
            readOnly: true,
          }}
        />
      )}
    </Box>
  );
}

MultiSelectEditable.propTypes = {
  handleChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  dataArr: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  edit: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  formStyle: PropTypes.shape({}),
  containerStyle: PropTypes.shape({}),
  error: PropTypes.bool,
  textError: PropTypes.string,
};

MultiSelectEditable.defaultProps = {
  value: '',
  error: false,
  disabled: false,
  size: 'medium',
  label: '',
  textError: '',
  edit: true,
  required: true,
  formStyle: {},
  containerStyle: {
    paddingTop: '30px',
    marginBottom: '0',
  },
};
