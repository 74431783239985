import React from 'react';
import PropTypes from 'prop-types';
import { Typography, IconButton } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { azulTucar } from '../../../themes/variants/TucarColors';

const GoTo = ({ text, onClick }) => {
  return (
    <IconButton
      onClick={onClick}
      sx={{
        display: 'flex',
        gap: '5px',
        justifyContent: 'center',
        backgroundColor: '#FFF',
        borderRadius: '7px',
      }}
    >
      <Typography sx={{ fontSize: '12px', fontFamily: 'Poppins-Medium', color: azulTucar }} noWrap>
        {text}
      </Typography>
      <KeyboardArrowRightIcon
        sx={{
          fontSize: '10px',
          backgroundColor: azulTucar,
          color: '#FFF',
          borderRadius: '50px',
        }}
      />
    </IconButton>
  );
};

GoTo.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
};

GoTo.defaultProps = {
  text: '',
  onClick: () => {},
};

export default GoTo;
