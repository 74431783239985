import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';

import FeatherIcon from 'feather-icons-react';
import { Box, Typography, Divider } from '@mui/material';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

import { azulTucar } from '../../../themes/variants/TucarColors';
import logotipo from '../../../../public/images/logotipo.svg';

import SubmitButton from '../../../components/SubmitButton';
import LogOut from '../../../components/auth/LogOutButton';
import WaitingNotifications from './waitingNotifications';

const RevokedDashboard = () => {
  const [handlePageChange] = useOutletContext();

  useEffect(() => {
    handlePageChange();
  }, []);
  const contentNotification = <WaitingNotifications />;
  const [click, setClick] = useState(false);
  const [content, setContent] = useState();

  useEffect(() => {
    if (click) {
      setContent(contentNotification);
    } else {
      setContent(
        <Typography sx={{ fontFamily: 'Poppins-Regular', fontSize: '14px', textAlign: 'center' }}>
          Cuenta rechazada
        </Typography>,
      );
    }
  }, [click]);
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateRows: 'max-content max-content 1fr max-content',
        gap: '10px',
        flex: 1,
        background: '#FFF',
        height: '100vh',
        paddingInline: '20px',
        paddingBottom: '30px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '4rem',
          padding: '10px 10px 0px 10px',
        }}
      >
        <img src={logotipo} alt="category" style={{ height: '32px' }} />
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: click ? '#E9EFF8' : '#fff',
              padding: '10px',
              borderRadius: '30px',
            }}
            onClick={() => {
              setClick(!click);
            }}
          >
            <FeatherIcon icon="bell" stroke={azulTucar} />
          </Box>
          <LogOut noLinealOnboarding />
        </Box>
      </Box>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '10px',
          justifyContent: 'center',
          padding: '10px',
        }}
      >
        {content}
      </Box>
      <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            justifyContent: 'center',
            padding: '10px',
          }}
        >
          <SupportAgentIcon sx={{ color: azulTucar, fontSize: '40px' }} />
          <Typography sx={{ fontFamily: 'Poppins-Regular', fontSize: '13px' }}>
            Para obtener más información contáctanos.
          </Typography>
        </Box>
        <SubmitButton handler={() => window.Intercom('show')} text="Contactar a un ejecutivo" />
      </Box>
    </Box>
  );
};

export default RevokedDashboard;
