const cadDescriptions = {
  Kia: { 'Soluto Lx 1.4': '', Morning: '', 'Niro EV': '' },
  Maple: {
    '30x': 'Cámara de retroceso, sensores de reversa, pantalla táctil, bluetooth, control crucero',
    '60s EV': '',
  },
  Maxus: {
    Euniq6:
      'Cámara de retroceso, sensores de reversa, pantalla táctil de 12" Apple CarPlay, bluetooth, control crucero, frenado automático emergencia, alarma colisión frontal y cambio de carril, monitor punto ciego, asientos de cuero con ajuste eléctrico y calefaccionados.',
    'Euniq 6':
      'Cámara de retroceso, sensores de reversa, pantalla táctil de 12" Apple CarPlay, bluetooth, control crucero, frenado automático emergencia, alarma colisión frontal y cambio de carril, monitor punto ciego, asientos de cuero con ajuste eléctrico y calefaccionados.',
  },
  Volkswagen: { 'Voyage 1.6': ' bluetooth, puerto USB' },
  Hyundai: {
    'Grand i10 Hb 1.2': '',
    'Grand i10 Sdn 1.2': '',
    Atos: 'Cámara de retroceso, sensores de reversa, pantalla táctil, Radio 7" Apple Carplay & Android Auto, bluetooth, puerto USB',
    'Grand I10 Gls 1.2':
      'Cámara de retroceso, sensores de reversa, pantalla táctil, Radio 8" Apple Carplay & Android Auto, bluetooth, puerto USB',
  },
  Chevrolet: {
    'Sail 1.5': 'Cámara de retroceso, sensores de reversa, pantalla táctil, bluetooth, puerto USB',
    'Prisma 1.4 Lt': '',
  },
  Chery: {
    'Arrizo 3':
      'Cámara de retroceso, sensores de reversa, pantalla táctil, conectividad cloud drive, puerto USB, Bluetooth',
  },
  Nissan: {
    Versa:
      'Cámara de retroceso, sensores de reversa, pantalla táctil, Apple Carplay & Android Auto, bluetooth, puerto USB',
  },
  Suzuki: { 'S-presso': '' },
  Citroen: { 'C-elysée': '' },
  Peugeot: { 208: '' },
  Ferrari: { California: '' },
  MG: { 'ZS Ev': '' },
};

export const getDescription = (brand, model) => {
  if (cadDescriptions[brand] && cadDescriptions[brand][model]) {
    return cadDescriptions[brand][model];
  }
  return '';
};

export default cadDescriptions;
