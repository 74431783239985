import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Loader } from '@tucar/pit-stop';
import { Box } from '@mui/material';
import PersonalForm from './personalForm';
import UPDATE_INFO_DRIVER from '../../../../../graphql/mutations/driver/updateInfoDriver';
import ErrorModal from '../../../../../components/ui/ErrorModal';

const PersonalInfo = () => {
  const [openError, setOpenError] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [updateDriverInfo, { loading, error }] = useMutation(
    UPDATE_INFO_DRIVER.mutation,
    UPDATE_INFO_DRIVER.policies,
  );
  const executeMutation = (variables) => {
    updateDriverInfo({
      variables,
    }).catch(() => {});
  };

  useEffect(() => {
    if (!loading) {
      if (error) {
        setOpenError(true);
      }
    }
  }, [loading]);

  return (
    <Box>
      <ErrorModal
        open={openError}
        onClose={() => {
          setOpenError(false);
        }}
        content="Ups, ha ocurrido un error de nuestra parte. Contacta a soporte presionando el chat."
        title="Error al enviar información"
        emojiSymbol="🤔"
      />
      {loading ? (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
          }}
        >
          <Loader size={32} pointRadio="4px" />
        </Box>
      ) : (
        <PersonalForm executeMutation={executeMutation} />
      )}
    </Box>
  );
};

export default PersonalInfo;
