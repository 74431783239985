import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { IconButton, TucarTheme } from '@tucar/pit-stop';

const TopNavTutorial = ({ title, color, backgroundColor }) => {
  const isString = (value) => typeof value === 'string' || value instanceof String;

  return (
    <Box
      sx={{
        backgroundColor,
        paddingTop: '50px',
        paddingBottom: '30px',
        display: 'grid',
        paddingInline: '30px',
        gridTemplateColumns: '40px 1fr 40px',
      }}
    >
      <IconButton
        size="small"
        iconProps={{
          name: 'leftArrowSpecial',
          size: '18px',
          color: '#A8AABA',
        }}
      />
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {isString(title) ? (
          <Typography
            align="center"
            fontFamily="Poppins-SemiBold"
            sx={{ color: color || TucarTheme.colors.gray700, lineHeight: 1.2, paddingLeft: '20px' }}
          >
            {title}
          </Typography>
        ) : (
          title
        )}
      </Box>
    </Box>
  );
};

TopNavTutorial.propTypes = {
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
};

TopNavTutorial.defaultProps = {
  title: '',
  color: TucarTheme.colors.gray700,
  backgroundColor: 'transparent',
};

export default TopNavTutorial;
