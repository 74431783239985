import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Button, Box, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

export default function ExternalOrInternalRedirect({ item, handleDrawerToggle }) {
  return (
    <Box>
      {item.external ? (
        <Button
          disableRipple
          disableFocusRipple
          target="_blank"
          href={item.path}
          onClick={() => handleDrawerToggle(item.name)}
          sx={{ padding: 0, color: '#000', '&:hover': { color: '#000' } }}
        >
          <ListItemButton sx={{ height: '45px' }}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                style: { fontSize: '14px', fontFamily: 'Poppins-Regular', lineHeight: 1 },
              }}
              primary={item.name}
            />
          </ListItemButton>
        </Button>
      ) : (
        <NavLink to={item.path} style={{ color: 'black', textDecoration: 'none' }}>
          <ListItemButton onClick={() => handleDrawerToggle(item.name)} sx={{ height: '45px' }}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                style: { fontSize: '14px', fontFamily: 'Poppins-Regular', lineHeight: 1 },
              }}
              primary={item.name}
            />
          </ListItemButton>
        </NavLink>
      )}
    </Box>
  );
}

ExternalOrInternalRedirect.propTypes = {
  item: PropTypes.shape({
    external: PropTypes.bool,
    path: PropTypes.string,
    icon: PropTypes.element,
    name: PropTypes.string,
  }).isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
};
