import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Stack } from '@mui/material';
import { useReactiveVar, useQuery } from '@apollo/client';
import moment from 'moment';
import {
  AccountBalanceWalletOutlined,
  DirectionsCarOutlined,
  CarCrashOutlined,
  ReceiptLongOutlined,
} from '@mui/icons-material';

import { getAuthStore, getDriverStore } from '../../../graphql/store';
import { azulTucar } from '../../../themes/variants/TucarColors';
import getDashboardInfo from '../../../helpers/Dashboard/getDashboardInfo';

import GET_DRIVER_BILLWEEK_RESUME from '../../../graphql/querys/driver/getBillWeekResume';
import GET_DRIVER_PAYMENT from '../../../graphql/querys/plan/getPayment';
import StandardDashButton from '../../../components/ui/StandardDashButton';
import LoadingProgress from '../../../components/ui/LoadingProgress';
import DateSelector from '../../../components/choices/DateSelector';
import DriverGraph from './DriverGraph';
import ResumeTable from './ResumeTable';
import PayButton from '../../../components/buttons/PayButton';
import BankButton from '../../../components/buttons/BankButton';
import TurnButton from '../../exchange/TurnButton';
import DocsButton from '../../../components/buttons/DocsButton';
import LockUnlockCarButton from '../../../components/buttons/LockUnlockCar';

const statusToFilter = ['churned', 'rent queue', 'revoked'];

const FullDashboard = ({ shared }) => {
  const { data } = useReactiveVar(getAuthStore);
  const {
    info: { country },
    verifiedList,
    status,
  } = useReactiveVar(getDriverStore);
  const navigate = useNavigate();
  const [showPayButton, setShowPayButton] = useState(null);
  const [allInfo, setAllInfo] = useState([]);
  const [date, setDate] = useState('');
  const [dates, setDates] = useState([]);
  const handleChange = (_, value) => {
    setDate(value);
  };

  const { data: allWeekInfo, loading } = useQuery(GET_DRIVER_BILLWEEK_RESUME.query, {
    variables: {
      uid: data.uid,
      limit: 3,
      offset: 0,
    },
  });

  const { data: paymentData, loading: paymentLoading } = useQuery(GET_DRIVER_PAYMENT.query, {
    ...GET_DRIVER_PAYMENT.policies,
    variables: {
      driverId: data.uid,
      country,
    },
  });

  useEffect(() => {
    if (paymentData && paymentData?.getDriverPayment) {
      if (paymentData.getDriverPayment.length > 0) {
        setShowPayButton({
          amount: paymentData.getDriverPayment[0].amount,
          title: paymentData.getDriverPayment[0].title,
        });
      }
    }
  }, [paymentLoading, paymentData]);

  useEffect(() => {
    if (allWeekInfo) {
      const allDatesAux = allWeekInfo.getDriverBillDaysParsedByWeek.instances.map((elem, index) => {
        return { id: index, name: moment(elem.initialDatetime).format('DD [de] MMM, YYYY') };
      });

      if (allDatesAux.length > 0) {
        setDates(allDatesAux);
        setDate(allDatesAux[0].name);

        const allInfoAux = allWeekInfo.getDriverBillDaysParsedByWeek.instances.map((elem) => {
          return getDashboardInfo(elem);
        });
        setAllInfo(allInfoAux);
      }
    }
  }, [loading, allWeekInfo]);
  if (!verifiedList?.bank || !verifiedList?.docs) {
    return (
      <Box
        sx={{
          display: 'grid',
          gridTemplateRows: 'max-content 1fr',
          height: '100%',
          padding: '20px',
          paddingTop: '0px',
        }}
      >
        <Box sx={{ height: 'max-content' }} display="inline">
          <Typography
            variant="span"
            sx={{ display: 'inline', lineHeight: 1, fontSize: '1rem' }}
            fontFamily="Poppins-Medium"
          >
            ¡Hola,
          </Typography>
          <Typography
            sx={{
              display: 'inline',
              color: azulTucar,
              lineHeight: 1,
              fontSize: '1rem',
              paddingLeft: '0.4rem',
            }}
            fontFamily="Poppins-Medium"
          >
            {data.firstname}!
          </Typography>
        </Box>

        <Box
          sx={{
            height: '100%',
            minHeight: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '20px',
          }}
        >
          {showPayButton && <PayButton amount={showPayButton.amount} title={showPayButton.title} />}
          {!verifiedList?.bank && <BankButton />}
          {!verifiedList?.docs && <DocsButton />}
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Box sx={{ marginBottom: '20px' }}>
        <LockUnlockCarButton />
      </Box>

      {!loading ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            padding: '20px',
            paddingTop: '0px',
          }}
        >
          <Stack
            sx={{ alignSelf: 'flex-start', width: '100%' }}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="inline">
              <Typography
                variant="span"
                sx={{ display: 'inline', lineHeight: 1, fontSize: '1rem' }}
                fontFamily="Poppins-Medium"
              >
                ¡Hola,
              </Typography>
              <Typography
                sx={{
                  display: 'inline',
                  color: azulTucar,
                  lineHeight: 1,
                  fontSize: '1rem',
                  paddingLeft: '0.4rem',
                }}
                fontFamily="Poppins-Medium"
              >
                {data.firstname}!
              </Typography>
            </Box>

            {date ? (
              <Box display="flex" alignItems="center">
                <DateSelector
                  handleChange={handleChange}
                  name="data"
                  date={date}
                  dateArray={dates}
                  fontSize="0.85rem"
                />
              </Box>
            ) : null}
          </Stack>

          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns:
                shared && showPayButton && !statusToFilter.includes(status.status)
                  ? '1fr 1fr'
                  : '1fr',
              gap: '20px',
            }}
          >
            {shared && !statusToFilter.includes(status.status) ? (
              <TurnButton changeDirection={!!showPayButton} />
            ) : null}

            {showPayButton ? (
              <PayButton
                contentDirection={shared ? 'column' : 'row'}
                amount={showPayButton.amount}
                title={showPayButton.title}
              />
            ) : null}
          </Box>

          <ResumeTable date={date} allInfo={allInfo} />

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <DriverGraph selecteDate={date} allInfo={allInfo} />
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '1rem',
            }}
          >
            <StandardDashButton
              Icon={DirectionsCarOutlined}
              onClick={() => navigate('/app/subscription', { replace: true })}
              text="Subscripción"
            />
            <StandardDashButton
              Icon={AccountBalanceWalletOutlined}
              text="Liquidaciones"
              onClick={() => navigate('/app/liquidations', { replace: true })}
            />
            <StandardDashButton
              onClick={() => navigate('/app/events', { replace: true })}
              Icon={CarCrashOutlined}
              text="Eventos"
            />
            <StandardDashButton
              onClick={() => navigate('/app/v2/vouchers', { replace: true })}
              Icon={ReceiptLongOutlined}
              text="Boletas"
            />
          </Box>
        </Box>
      ) : (
        <LoadingProgress />
      )}
    </>
  );
};

FullDashboard.propTypes = {
  shared: PropTypes.bool,
};

FullDashboard.defaultProps = {
  shared: false,
};
export default FullDashboard;
