import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Box, Typography, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { formatterCLP } from '../../../helpers/formatters';

const EventList = ({ events, titleView, filterFunc, setOpen }) => {
  const eventsFilter = events.filter(filterFunc);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            width: '100%',
            textAlign: 'center',
            fontSize: '16px',
            fontFamily: 'Poppins-Medium',
            color: '#5B5D71',
          }}
        >
          {titleView}
        </Typography>
        <IconButton onClick={() => setOpen(false)}>
          <ClearIcon color="#5B5D71" />
        </IconButton>
      </Box>

      {eventsFilter.length === 0 ? (
        <Box sx={{ marginTop: '20px' }}>
          <Typography
            sx={{
              textAlign: 'left',
              fontFamily: 'Poppins-Medium',
              fontSize: '14px',
              color: '#5B5D71',
              lineHeight: 1.2,
            }}
          >
            No existen eventos registrados
          </Typography>
        </Box>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', padding: '10px' }}>
          {eventsFilter.map((event, index) => {
            const customKey = `${index}-${event.createdAt}`;
            return (
              <Box
                key={customKey}
                sx={{
                  padding: '26px 24px',
                  borderRadius: '14px',
                  boxShadow:
                    '4px 4px 20px 0px rgba(111, 140, 176, 0.41), -6px -6px 20px 0px #FFF, 2px 2px 4px 0px rgba(114, 142, 171, 0.10)',
                }}
              >
                <Typography
                  sx={{
                    textAlign: 'left',
                    fontFamily: 'Poppins-Medium',
                    fontSize: '14px',
                    color: '#5B5D71',
                    lineHeight: 1.2,
                  }}
                >
                  Total: {formatterCLP.format(event.amount)}
                </Typography>
                <Typography
                  sx={{
                    textAlign: 'left',
                    fontFamily: 'Poppins-Bold',
                    fontSize: '16px',
                    color: '#0057B8',
                    lineHeight: 1.2,
                  }}
                >
                  {event.title}
                </Typography>
                <Typography
                  sx={{
                    textAlign: 'left',
                    fontFamily: 'Poppins-Medium',
                    fontSize: '14px',
                    color: '#5B5D71',
                    lineHeight: 1.2,
                  }}
                >
                  {moment(event.chargeDate).format('hh:mm - DD/MM/YYYY')}
                </Typography>
                <Typography
                  sx={{
                    textAlign: 'left',
                    fontFamily: 'Poppins-Medium',
                    fontSize: '14px',
                    color: '#5B5D71',
                    lineHeight: 1.2,
                  }}
                >
                  {event.description}
                </Typography>
              </Box>
            );
          })}
        </Box>
      )}
    </Box>
  );
};

EventList.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape({})),
  setOpen: PropTypes.func,
  filterFunc: PropTypes.func,
  titleView: PropTypes.string,
};

EventList.defaultProps = {
  events: [],
  setOpen: () => {},
  filterFunc: () => true,
  titleView: '',
};

export default EventList;
