import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton } from '@mui/material';
import { Button, Typography } from '@tucar/pit-stop';
import CloseIcon from '@mui/icons-material/Close';
import BoltIcon from '@mui/icons-material/Bolt';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import getUniqueKey from '../../../../helpers/customKey';
import { translateOneStatus } from '../auxFunctions';
import { capitalizeDate } from '../../../../helpers/dateFormat';

const wrapperStyle = {
  position: 'fixed',
  zIndex: 2,
  bottom: '80px',
  right: '0px',
  transition: 'transform 0.5s',
  height: 'max-content',
  width: '100vw',
  backgroundColor: '#fff',
  padding: '40px 24px',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  borderRadius: '20px 20px 0px 0px',
};

const dottedLine = (text) => (
  <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px', alignItems: 'center' }}>
    <Box sx={{ background: '#000E1F', width: '4px', height: '4px', borderRadius: '100%' }} />
    <Typography style={{ fontSize: '12px', fontWeight: 500, color: '#002752' }}>{text}</Typography>
  </Box>
);

const MapSelection = ({ data, onClose }) => {
  const [open, setOpen] = useState(false);
  const { provider, addressAlias, address, lat, lon, connectors } = data || {};
  const [selectedPin, setSelectedPin] = useState(null);

  const handleClose = () => {
    setOpen(false);
    setSelectedPin(null);
    onClose();
  };

  useEffect(() => {
    if (data) {
      setOpen(true);
      if (selectedPin) setSelectedPin(null);
    } else if (open) setOpen(false);
  }, [data]);

  if (selectedPin) {
    return (
      <Box sx={wrapperStyle}>
        <Box
          sx={{
            display: 'flex',
            gap: '20px',
            alignItems: 'start',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0px' }}>
            <Typography
              style={{ fontWeight: 600, fontSize: '16px', color: '#1F89FF', lineHeight: 1.2 }}
            >
              {selectedPin?.plugType} - {selectedPin?.maxCapacity?.toFixed(1) || 0} kW
            </Typography>
            <Typography
              style={{ fontWeight: 500, fontSize: '12px', color: '#002752', lineHeight: 1.2 }}
            >
              {selectedPin?.currentType === 'AC' ? 'Carga lenta' : 'Carga rápida'}
            </Typography>
          </Box>

          <IconButton onClick={handleClose}>
            <CloseIcon sx={{ color: '#5B5D71' }} />
          </IconButton>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography
            style={{ paddingBottom: '2px', color: '#000E1F', fontSize: '14px', fontWeight: 600 }}
          >
            Especificaciones
          </Typography>

          {dottedLine(`Estado ${translateOneStatus(selectedPin?.ocppStatus).toLowerCase()}`)}
          {dottedLine(`Conector del tipo ${selectedPin?.plugType}`)}
          {dottedLine(`Capacidad máxima de ${selectedPin?.maxCapacity?.toFixed(1) || 0} kW`)}
          {dottedLine(
            `Actualizado el ${capitalizeDate(selectedPin?.ocppUpdatedAt, 'dddd DD de MMMM HH:mm')}`,
          )}
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        ...wrapperStyle,
        transform: `translateY(${open ? '0%' : '100%'})`,
      }}
    >
      <Box
        sx={{ display: 'flex', gap: '20px', alignItems: 'start', justifyContent: 'space-between' }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0px', alignItems: 'start' }}>
          <Typography
            style={{ fontWeight: 600, fontSize: '16px', color: '#1F89FF', lineHeight: 1.2 }}
          >
            Cargador {provider?.name || ''}
          </Typography>
          <Typography
            style={{ fontWeight: 600, fontSize: '16px', color: '#1F89FF', lineHeight: 1.2 }}
          >
            {addressAlias}
          </Typography>
          <Typography
            style={{ fontWeight: 500, fontSize: '12px', color: '#002752', lineHeight: 1.2 }}
          >
            Estación de carga
          </Typography>
        </Box>

        <IconButton onClick={handleClose}>
          <CloseIcon sx={{ color: '#5B5D71' }} />
        </IconButton>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
        }}
      >
        {connectors?.map((connector) => {
          const { maxCapacity, ocppStatus, plugType, currentType } = connector || {};
          return (
            <Box
              key={getUniqueKey()}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '10px',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '10px',
                  alignItems: 'center',
                  justifyContent: 'start',
                }}
              >
                <BoltIcon sx={{ color: '#5B5D71' }} />
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0px' }}>
                  <Typography
                    style={{ fontWeight: 500, fontSize: '12px', color: '#002752', lineHeight: 1.2 }}
                  >
                    {plugType} - {maxCapacity?.toFixed(1) || 0} kW
                  </Typography>
                  <Typography
                    style={{ fontWeight: 400, fontSize: '12px', color: '#002752', lineHeight: 1.2 }}
                  >
                    {currentType === 'AC' ? 'Carga lenta' : 'Carga rápida'}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '10px',
                  alignItems: 'center',
                  justifyContent: 'start',
                }}
              >
                <Box
                  sx={{
                    padding: '16px',
                    backgroundColor: ocppStatus === 'Available' ? '#F9FDF1' : '#CFD0D8',
                    borderRadius: '50%',
                  }}
                >
                  <Box
                    sx={{
                      width: '8px',
                      height: '8px',
                      backgroundColor: ocppStatus === 'Available' ? '#95D31C' : '#5B5D71',
                      borderRadius: '50%',
                    }}
                  />
                </Box>
                <Button
                  onClick={() => setSelectedPin(connector)}
                  style={{ padding: '8px', height: '35px', width: '35px' }}
                >
                  Ver
                </Button>
              </Box>
            </Box>
          );
        })}
      </Box>

      <Box
        sx={{
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
          alignItems: 'center',
          borderTop: '1px solid #66AEFF',
          paddingTop: '16px',
          gap: '16px',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
          <LocationOnIcon sx={{ color: '#1F89FF' }} />
          <Typography
            style={{ color: '#5B5D71', fontSize: '14px', fontWeight: 500, lineHeight: 1.2 }}
          >
            {address}
          </Typography>
        </Box>
        <Button
          onClick={() => {
            window.open(`http://maps.google.com/maps?z=12&t=m&q=loc:${lat}+${lon}`, '_blank');
          }}
          size="small"
        >
          Ir a google Maps
        </Button>
      </Box>
    </Box>
  );
};

MapSelection.propTypes = {
  data: PropTypes.shape({
    provider: PropTypes.shape({
      name: PropTypes.string,
    }),
    addressAlias: PropTypes.string,
    address: PropTypes.string,
    lat: PropTypes.number,
    lon: PropTypes.number,
    connectors: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  onClose: PropTypes.func,
};

MapSelection.defaultProps = {
  data: {
    provider: {
      name: '',
    },
    addressAlias: '',
    address: '',
    lat: 0,
    lon: 0,
    connectors: [],
  },
  onClose: () => {},
};

export default MapSelection;
