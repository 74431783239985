import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { TableCell, TableRow, Collapse } from '@mui/material';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';

import { formatterCLP } from '../../../../../helpers/formatters';

import stylesTable from '../../style';

const ChargesTable = ({ billDays, handleModalContent }) => {
  const [open, setOpen] = useState(true);

  const totalInfo = billDays.reduce(
    (acc, billDay, index) => {
      const {
        km,
        installment,
        expenses: { rent, fines, penaltys, other },
        currency,
        discount,
        plan: { selectedShifts },
        tag,
      } = billDay;
      const { basePrice } = selectedShifts[index];
      const calculatedRent = ((basePrice * currency) / 7) * (1 - discount / 100);

      const totalTag = tag.reduce((accTag, currentTag) => {
        return accTag + currentTag.amount;
      }, 0);
      const totalRent = km > 0 ? rent - calculatedRent : rent;
      const totalBasePrice = km > 0 ? ((basePrice * currency) / 7) * (1 - discount / 100) : rent;
      return {
        totalBasePrice: totalBasePrice + acc.totalBasePrice,
        km: km + acc.km,
        totalRent: totalRent + acc.totalRent,
        installment: installment + acc.installment,
        totalTag: totalTag + acc.totalTag,
        totalFines: fines + acc.totalFines,
        totalPenaltys: penaltys + acc.totalPenaltys,
        totalOther: other + acc.totalOther,
      };
    },
    {
      totalBasePrice: 0,
      km: 0,
      totalRent: 0,
      installment: 0,
      totalTag: 0,
      totalFines: 0,
      totalPenaltys: 0,
      totalOther: 0,
    },
  );

  return (
    <>
      <TableRow onClick={() => setOpen(!open)}>
        <TableCell
          sx={{
            position: 'relative',
            ...stylesTable.headerColumn,
            ...stylesTable.stickyHeader,
          }}
        >
          Cargos
          <ChevronRightRoundedIcon
            sx={{
              ...stylesTable.iconArrow,
              right: '5px',
              transform: open ? 'translateY(-50%) rotate(90deg)' : 'translateY(-50%) rotate(0deg)',
              transition: 'transform 0.3s ease',
            }}
          />
        </TableCell>
        {billDays.map((_, index) => {
          const key = `Charges-${index}`;
          return <TableCell key={key} />;
        })}
        <TableCell sx={{ ...stylesTable.totalCell }} />
      </TableRow>

      <TableRow>
        <TableCell
          sx={{
            ...stylesTable.stickyHeader,
            ...stylesTable.headerText,
          }}
        >
          <Collapse sx={stylesTable.collapseTitle} in={open} timeout="auto" unmountOnExit>
            Deducible
          </Collapse>
        </TableCell>
        {billDays.map((elem, index) => {
          const key = `deducible-${index}`;
          const { installment } = elem;
          return (
            <TableCell key={key} align="center" sx={{ ...stylesTable.regularText }}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                {formatterCLP.format(installment)}
              </Collapse>
            </TableCell>
          );
        })}

        <TableCell align="center" sx={{ ...stylesTable.regularText, ...stylesTable.totalCell }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {formatterCLP.format(totalInfo.installment)}
          </Collapse>
        </TableCell>
      </TableRow>

      <TableRow sx={{ backgroundColor: '#E3EDF7' }}>
        <TableCell
          sx={{
            display: 'relative',
            ...stylesTable.stickyHeader,
            ...stylesTable.headerText,
          }}
          onClick={() => handleModalContent('tags')}
        >
          <Collapse sx={stylesTable.collapseTitle} in={open} timeout="auto" unmountOnExit>
            TAG
            <ChevronRightRoundedIcon sx={stylesTable.iconArrow} />
          </Collapse>
        </TableCell>
        {billDays.map((elem, index) => {
          const key = `tag-${index}`;
          const { tag } = elem;
          const totalTag = tag.reduce((acc, currentTag) => {
            return acc + currentTag.amount;
          }, 0);

          return (
            <TableCell key={key} align="center" sx={{ ...stylesTable.regularText }}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                {formatterCLP.format(totalTag)}
              </Collapse>
            </TableCell>
          );
        })}
        <TableCell align="center" sx={{ ...stylesTable.regularText, ...stylesTable.totalCell }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {formatterCLP.format(totalInfo.totalTag)}
          </Collapse>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell
          sx={{
            position: 'relative',
            ...stylesTable.stickyHeader,
            ...stylesTable.headerText,
          }}
          onClick={() => handleModalContent('fineEvents')}
        >
          <Collapse sx={stylesTable.collapseTitle} in={open} timeout="auto" unmountOnExit>
            Multas
            <ChevronRightRoundedIcon sx={stylesTable.iconArrow} />
          </Collapse>
        </TableCell>

        {billDays.map((elem, index) => {
          const key = `fines-${index}`;
          const {
            expenses: { fines },
          } = elem;

          return (
            <TableCell key={key} align="center" sx={{ ...stylesTable.regularText }}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                {formatterCLP.format(fines)}
              </Collapse>
            </TableCell>
          );
        })}
        <TableCell align="center" sx={{ ...stylesTable.regularText, ...stylesTable.totalCell }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {formatterCLP.format(totalInfo.totalFines)}
          </Collapse>
        </TableCell>
      </TableRow>

      <TableRow sx={{ backgroundColor: '#E3EDF7' }}>
        <TableCell
          sx={{
            position: 'relative',
            ...stylesTable.stickyHeader,
            ...stylesTable.headerText,
          }}
          onClick={() => handleModalContent('penaltysEvents')}
        >
          <Collapse sx={stylesTable.collapseTitle} in={open} timeout="auto" unmountOnExit>
            Penalizaciones
            <ChevronRightRoundedIcon sx={stylesTable.iconArrow} />
          </Collapse>
        </TableCell>
        {billDays.map((elem, index) => {
          const key = `fines-${index}`;
          const {
            expenses: { penaltys },
          } = elem;

          return (
            <TableCell key={key} align="center" sx={{ ...stylesTable.regularText }}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                {formatterCLP.format(penaltys)}
              </Collapse>
            </TableCell>
          );
        })}
        <TableCell align="center" sx={{ ...stylesTable.regularText, ...stylesTable.totalCell }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {formatterCLP.format(totalInfo.totalPenaltys)}
          </Collapse>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell
          sx={{
            position: 'relative',
            ...stylesTable.stickyHeader,
            ...stylesTable.headerText,
          }}
          onClick={() => handleModalContent('otherEvents')}
        >
          <Collapse sx={stylesTable.collapseTitle} in={open} timeout="auto" unmountOnExit>
            Otros
            <ChevronRightRoundedIcon sx={stylesTable.iconArrow} />
          </Collapse>
        </TableCell>
        {billDays.map((elem, index) => {
          const key = `other-expenses-${index}`;
          const {
            expenses: { other },
          } = elem;

          return (
            <TableCell key={key} align="center" sx={{ ...stylesTable.regularText }}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                {formatterCLP.format(other)}
              </Collapse>
            </TableCell>
          );
        })}
        <TableCell align="center" sx={{ ...stylesTable.regularText, ...stylesTable.totalCell }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {formatterCLP.format(totalInfo.totalOther)}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

ChargesTable.propTypes = {
  billDays: PropTypes.arrayOf(PropTypes.shape({})),
  handleModalContent: PropTypes.func,
};

ChargesTable.defaultProps = {
  billDays: [],
  handleModalContent: () => {},
};

export default ChargesTable;
