import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Box, ListItemText, ListItemIcon, ListItemButton, Collapse } from '@mui/material';
import { useQuery, useReactiveVar } from '@apollo/client';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import { getAuthStore } from '../../../../../graphql/store';
import { azulTucar } from '../../../../../themes/variants/TucarColors';
import GET_DOCUMENTS from '../../../../../graphql/querys/docs/getDocuments';
import LoadingProgress from '../../../../../components/ui/LoadingProgress';
import DocBox from './DocBox';

const Documents = ({ setMessageError, setOpenError }) => {
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState([]);
  const [change, setChange] = useState(-1);

  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);

  const {
    data: docsData,
    refetch,
    loading,
  } = useQuery(GET_DOCUMENTS.query, {
    variables: {
      uid,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      if (content.length > 0 && change !== -1) {
        content[change].loadingRefetch = false;
      }
    },
  });

  const handleClick = () => {
    setOpen(!open);
    refetch();
  };

  const handleUpload = (file, name, ident) => {
    content[ident].loadingRefetch = true;
    setChange(ident);
    const data = new FormData();
    data.append('files', file, `${name}.${file.name.split('.').pop()}`);
    data.append('id', uid);
    const config = {
      method: 'post',
      url: `${process.env.DOCUMENT_GATEWAY_URL}/api/v1/cl/document/attach`,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Apikey ${process.env.DOCUMENT_GATEWAY_API_KEY}`,
      },
      data,
    };
    axios(config)
      .then(() => {
        refetch();
      })
      .catch((error) => {
        setMessageError(error.message);
        setOpenError(true);
      });
  };

  useEffect(() => {
    if (docsData && docsData.getDocument && content.length === 0) {
      const aux = docsData.getDocument.files.map((elem, ident) => {
        const auxObj = { ...elem };
        // eslint-disable-next-line no-param-reassign
        auxObj.fileType = 'application/pdf, image/*';
        auxObj.loadingRefetch = false;
        auxObj.id = ident;
        return auxObj;
      });
      setContent(aux);
    }
  }, [loading, docsData]);

  return (
    <Box>
      <Box>
        <ListItemButton
          sx={{ height: '45px', color: open ? azulTucar : '#000' }}
          onClick={() => handleClick()}
        >
          <ListItemIcon>
            <DescriptionOutlinedIcon sx={{ color: azulTucar }} />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{
              style: { fontSize: '14px', fontFamily: 'Poppins-Regular', lineHeight: 1 },
            }}
            primary="Documentos y antecedentes"
          />
          {open ? (
            <KeyboardArrowUpOutlinedIcon sx={{ fontSize: '20px' }} />
          ) : (
            <KeyboardArrowDownOutlinedIcon sx={{ fontSize: '20px' }} />
          )}
        </ListItemButton>
      </Box>
      <Collapse in={open} timeout="auto" unmountOnExit sx={{ overflowY: 'scroll' }}>
        <Box margin="15px 10px 40px 10px" display="flex" gap="15px" flexDirection="column">
          {content.length > 0 ? (
            content.map((doc) => (
              <DocBox key={doc.name} fileObj={doc} handleUpload={handleUpload} />
            ))
          ) : (
            <LoadingProgress allScreen={false} />
          )}
        </Box>
      </Collapse>
    </Box>
  );
};

Documents.propTypes = {
  setMessageError: PropTypes.func.isRequired,
  setOpenError: PropTypes.func.isRequired,
};

export default Documents;
