import { gql } from '@apollo/client';

const GET_CAR_CONNECTION = {
  query: gql`
    query GetCarBasicTelemetry($carId: ID!) {
      getCarBasicTelemetry(carId: $carId) {
        connected
      }
    }
  `,
  policies: {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: 'true',
  },
};

export default GET_CAR_CONNECTION;
