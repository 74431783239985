import { gql } from '@apollo/client';

const GET_INSTALLMENTS = {
  query: gql`
    query GetInstallments($ref: String) {
      getInstallments(ref: $ref) {
        amount
        chargeDate
        type
        id: _id
      }
    }
  `,
  policies: {
    errorPolicy: 'ignore',
  },
};

export default GET_INSTALLMENTS;
