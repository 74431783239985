import React from 'react';
import MapMarker from './mapMarker';

export const tucarOfficeLocation = {
  lat: -33.4082811,
  lng: -70.5678114,
};

export const renderMapMarkers = (markers = []) => {
  if (!markers || !markers.length) {
    return null;
  }

  return markers.map((marker) => {
    const { _id: ident, lat, lon: lng } = marker || {};
    return (
      <MapMarker
        data={marker}
        key={ident}
        ident={ident}
        position={{
          lat: parseFloat(lat),
          lng: parseFloat(lng),
        }}
      />
    );
  });
};
