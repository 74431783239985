export const baseButtonStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '8px',
  borderRadius: '6px',
};

export const locationButtonStyle = {
  ...baseButtonStyle,
  background: '#0496FF',
  gap: '5px',
};
