import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { Box, IconButton, Typography } from '@mui/material';

const PhotoCar = ({
  title,
  name,
  photoLoaded,
  confirmPhoto,
  addPhoto,
  normalSize,
  photoStep,
  removePhoto,
}) => {
  const [photo, setPhoto] = useState();
  const handleUpload = (file) => {
    if (!addPhoto) {
      setPhoto(file);
    }
    confirmPhoto([file, name ? `${name}.${file.name.split('.').pop()}` : file.name]);
  };

  const handleRemove = () => {
    removePhoto(name ? `${name}.${photo.name.split('.').pop()}` : photo?.name || photoLoaded?.name);
    setPhoto();
  };

  if (addPhoto && !photo) {
    return (
      <IconButton
        type="submit"
        component="label"
        sx={{
          height: '50px',
          width: normalSize ? '50px' : '100%',
          border: '2px dashed #ccc',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '7px',
        }}
      >
        <input
          hidden
          type="file"
          accept="image/*"
          capture="environment"
          onChange={(e) => {
            handleUpload(e.target.files[0], name);
          }}
        />
        <AddAPhotoOutlinedIcon
          sx={{
            color: '#ccc',
            transform: 'scaleX(-1)',
            backgroundColor: '#FFF',
          }}
        />
      </IconButton>
    );
  }

  if (photoStep) {
    return (
      <Box
        sx={{
          display: 'grid',
          gridTemplateRows: '1fr max-content',
          gap: '10px',
        }}
      >
        <Box sx={{ position: 'relative' }}>
          {photo ? (
            <IconButton
              onClick={handleRemove}
              sx={{
                borderRadius: '55px',
                opacity: '80%',
                backgroundColor: '#FFF',
                position: 'absolute',
                bottom: '5px',
                right: '5px',
                zIndex: 10,
              }}
            >
              <DeleteOutlinedIcon />
            </IconButton>
          ) : null}
          <IconButton
            disabled={!!photo}
            type="submit"
            component="label"
            sx={{
              border: photo ? 'none' : '2px dashed #ccc',
              borderRadius: '7px',
              height: '100%',
              width: '100%',
              backgroundColor: '#FFF',
              padding: 0,
            }}
          >
            <input
              hidden
              type="file"
              accept="image/*"
              capture="environment"
              onChange={(e) => {
                handleUpload(e.target.files[0]);
              }}
            />
            {photo ? (
              <img
                style={{ borderRadius: '7px', height: '100%', width: '100%' }}
                src={URL.createObjectURL(photo)}
                alt="photo1"
              />
            ) : (
              <AddAPhotoOutlinedIcon
                sx={{ height: '40%', width: '40%', transform: 'scaleX(-1)' }}
              />
            )}
          </IconButton>
        </Box>
        <Typography
          sx={{
            textAlign: 'center',
            lineHeight: 1,
            fontFamily: 'Poppins-Regular',
            fontSize: '13px',
          }}
        >
          {title}
        </Typography>
      </Box>
    );
  }
  return (
    <Box
      sx={{
        width: '50px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '5px',
      }}
    >
      <Box sx={{ position: 'relative' }}>
        {photo || photoLoaded ? (
          <IconButton
            onClick={handleRemove}
            sx={{
              borderRadius: '55px',
              opacity: '80%',
              backgroundColor: '#FFF',
              position: 'absolute',
              bottom: '5px',
              right: '5px',
              zIndex: 55,
              width: '10px',
              height: '10px',
              '&:hover': {
                borderRadius: '55px',
                opacity: '80%',
                backgroundColor: '#FFF',
                zIndex: 55,
                width: '10px',
                height: '10px',
              },
            }}
          >
            <DeleteOutlinedIcon sx={{ fontSize: '10px' }} />
          </IconButton>
        ) : null}
        <IconButton
          disabled={!!photoLoaded}
          type="submit"
          component="label"
          sx={{
            border: photoLoaded ? 'none' : '2px dashed #ccc',
            borderRadius: '7px',
            width: '50px',
            height: '50px',
            backgroundColor: '#FFF',
            padding: 0,
          }}
        >
          <input
            hidden
            type="file"
            accept="image/*"
            capture="environment"
            onChange={(e) => {
              handleUpload(e.target.files[0], name);
            }}
          />
          <img
            style={{ borderRadius: '7px', height: '50px', width: '50px' }}
            src={URL.createObjectURL(photoLoaded)}
            alt="photo1"
          />
        </IconButton>
      </Box>
      <Typography
        sx={{
          textAlign: 'center',
          lineHeight: 1,
          fontFamily: 'Poppins-Regular',
          fontSize: '12px',
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};

export default PhotoCar;

PhotoCar.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  confirmPhoto: PropTypes.func,
  addPhoto: PropTypes.bool,
  normalSize: PropTypes.bool,
  photoStep: PropTypes.bool,
  removePhoto: PropTypes.func,
  photoLoaded: PropTypes.shape({
    name: PropTypes.string,
  }),
};

PhotoCar.defaultProps = {
  title: '',
  name: '',
  confirmPhoto: () => {},
  addPhoto: false,
  normalSize: false,
  photoStep: false,
  removePhoto: () => {},
  photoLoaded: null,
};
