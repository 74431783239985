import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { formatterCLP } from '../../../helpers/formatters';
import { azulTucar } from '../../../themes/variants/TucarColors';
import TableGenerator from '../TableGenerator';

const UberBreakdown = ({ content, backgroundColor }) => {
  const [tableContent, setTableContent] = useState([]);

  useEffect(() => {
    if (content?.breakdown && Object.values(content?.breakdown).filter((elem) => elem > 0).length) {
      const { netFare, taxes, toll, cashCollected, other, tips, adjustment, promotions } =
        content.breakdown;

      const auxTableContent = [
        {
          right: 'Ganancias de uber',
          left: `+${formatterCLP.format(netFare + taxes)}`,
        },
        {
          right: 'Reembolsos',
          left: `+${formatterCLP.format(toll)}`,
        },
        {
          right: 'Efectivo Cobrado',
          left: `-${formatterCLP.format(cashCollected)}`,
        },
        {
          right: 'Otros',
          left: `+${formatterCLP.format(other)}`,
        },
        {
          right: 'Propinas',
          left: `+${formatterCLP.format(tips)}`,
        },
        {
          right: 'Reajustes',
          left: `+${formatterCLP.format(adjustment)}`,
        },
        {
          right: 'Promociones',
          left: `+${formatterCLP.format(promotions)}`,
        },
      ];
      setTableContent(auxTableContent);
    }
  }, [content]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        backgroundColor,
        borderRadius: '7px',
      }}
    >
      <TableGenerator
        content={tableContent}
        backgroundColor="#FFF"
        tableStyle={{
          container: { padding: '0px' },
        }}
        lastColor="black"
        lastSize="12px"
        lastFamily="Poppins-Light"
      />
      {content?.total && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '10px',
          }}
        >
          <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
            <Typography sx={{ fontSize: '14px', fontFamily: 'Poppins-Medium', color: azulTucar }}>
              Recaudado por Tucar
            </Typography>
          </Box>

          <Typography
            sx={{ fontSize: '14px', fontFamily: 'Poppins-Medium', color: azulTucar }}
            noWrap
          >
            ={formatterCLP.format(content.total)}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

UberBreakdown.propTypes = {
  content: PropTypes.shape({
    total: PropTypes.number,
    breakdown: PropTypes.shape({
      netFare: PropTypes.number,
      taxes: PropTypes.number,
      toll: PropTypes.number,
      cashCollected: PropTypes.number,
      other: PropTypes.number,
      tips: PropTypes.number,
      adjustment: PropTypes.number,
      promotions: PropTypes.number,
    }),
    oldContent: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  backgroundColor: PropTypes.string,
};

UberBreakdown.defaultProps = {
  backgroundColor: '#F3F3F3',
  content: {
    total: 0,
    breakdown: {
      netFare: 0,
      taxes: 0,
      toll: 0,
      cashCollected: 0,
      other: 0,
      tips: 0,
      adjustment: 0,
      promotions: 0,
    },
    oldContent: [],
  },
};

export default UberBreakdown;
