import React from 'react';
import PropTypes from 'prop-types';
import UberButton from '../../../../components/ui/UberButton';

const SignUpUber = ({ urlSingUpUber }) => {
  return <UberButton href={urlSingUpUber} content="Regístrate con Uber" />;
};

SignUpUber.propTypes = {
  // eslint-disable-next-line react/require-default-props
  urlSingUpUber: PropTypes.string,
};

export default SignUpUber;
