import { gql } from '@apollo/client';

const GET_GUARANTEE_INFO = {
  query: gql`
    query GetConfigAccounting($country: String!) {
      getConfigAccounting(country: $country) {
        guarantee {
          total
        }
      }
    }
  `,
};

export default GET_GUARANTEE_INFO;
