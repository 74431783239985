import React, { useState, useEffect } from 'react';
import { useReactiveVar, useQuery } from '@apollo/client';
import moment from 'moment';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import { Box, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useOutletContext } from 'react-router-dom';

import arrowTucar from '../../../../../public/images/customIcons/arrow/arrow_blue.svg';
import GET_DRIVER_BILLWEEK_LIMIT_DATES from '../../../../graphql/querys/balance/getSpecificBillDay';
import { getAuthStore } from '../../../../graphql/store';
import TopBar from '../../../../components/ui/TopNav';
import LoadingProgress from '../../../../components/ui/LoadingProgress';
import DetailBalance from '../../balance/OldVersion/detailBalance';
import ResumeBox from '../../../../components/info/ResumeBox';
import GraphWeek from '../../../../components/info/GraphWeek';
import { formatterCLP, formatterKm } from '../../../../helpers/formatters';
import { azulTucar } from '../../../../themes/variants/TucarColors';
import UberBreakdown from '../../../../components/info/UberBreakdown';

const BalanceLiquidation = () => {
  const [handlePageChange] = useOutletContext();
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);
  const [details, setDetails] = useState(null);
  const [billDays, setBillDays] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [date, setDate] = useState('');
  const [incomesInfo, setIncomesInfo] = useState([]);
  const [expensesInfo, setExpensesInfo] = useState([]);
  const [total, setTotal] = useState(0);
  const matches = useMediaQuery('(max-width:400px)');

  const urlWin = window.location.toString().split('?')[1].split('&');
  const dateParam = urlWin[0].split('=')[1];
  const debtParam = parseInt(urlWin[2].split('=')[1], 10);
  const prePayParam = parseInt(urlWin[1].split('=')[1], 10);

  const initialDatetime = moment(dateParam, 'DD/MM/YYYY')
    .subtract(7, 'days')
    .format('YYYY-MM-DD[T00:00:00]');

  const finalDatetime = moment(dateParam, 'DD/MM/YYYY')
    .subtract(1, 'days')
    .format('YYYY-MM-DD[T00:00:00]');

  const { data: allBillDays, loading } = useQuery(GET_DRIVER_BILLWEEK_LIMIT_DATES.query, {
    ...GET_DRIVER_BILLWEEK_LIMIT_DATES.policies,
    variables: {
      uid,
      initialDatetime,
      finalDatetime,
    },
  });

  useEffect(() => {
    if (details) {
      handlePageChange(
        <TopBar
          handleExtra={() => {
            setDetails(null);
          }}
          prevPage="/app/liquidations"
          title={`Detalles de ${details === 'incomes' ? 'recaudaciones' : 'descuentos'}`}
        />,
        true,
      );
    } else
      handlePageChange(
        <TopBar prevPage="/app/liquidations" title="Balance de Liquidación" />,
        true,
      );
  }, [details]);

  useEffect(() => {
    if (allBillDays) {
      const billDaysAux = allBillDays.getDriverBillDaysLimitDates;
      setBillDays(billDaysAux);
      setDate(
        `${moment(initialDatetime, 'YYYY-MM-DD[T00:00:00]').format('DD')} al ${moment(
          finalDatetime,
          'YYYY-MM-DD[T00:00:00]',
        ).format('DD [de] MMMM, YYYY')}`,
      );
      setDateRange([initialDatetime, finalDatetime]);

      const totalApps = billDaysAux.reduce((acc, elem) => {
        return (
          acc + elem.incomes.uber + elem.incomes.didi + elem.incomes.cabify + elem.incomes.beat
        );
      }, 0);

      const otherIncomes = billDaysAux.reduce((acc, elem) => {
        return acc + elem.incomes.other;
      }, 0);

      const totalRent = billDaysAux.reduce((acc, elem) => {
        return acc + elem.expenses.rent;
      }, 0);

      const totalTag = billDaysAux.reduce((acc, elem) => {
        return acc + elem.expenses.tag;
      }, 0);

      const totalFines = billDaysAux.reduce((acc, elem) => {
        return acc + elem.expenses.fines;
      }, 0);

      const totalPenaltys = billDaysAux.reduce((acc, elem) => {
        return acc + elem.expenses.penaltys;
      }, 0);

      const totalGuarantee = billDaysAux.reduce((acc, elem) => {
        return acc + elem.expenses.guarantee;
      }, 0);

      const totalOtherExpenses = billDaysAux.reduce((acc, elem) => {
        return acc + elem.expenses.other;
      }, 0);

      const totalExpenses =
        totalRent +
        totalTag +
        totalFines +
        totalPenaltys +
        totalGuarantee +
        totalOtherExpenses +
        debtParam;

      const kmWeek = billDaysAux.reduce((acc, elem) => {
        return acc + elem.km;
      }, 0);

      const totalUberBreakdown = billDaysAux.reduce(
        (acc, elem) => {
          return {
            adjustment: acc.adjustment + elem.uberBreakdown.adjustment,
            cashCollected: acc.cashCollected + elem.uberBreakdown.cashCollected,
            netFare: acc.netFare + elem.uberBreakdown.netFare,
            other: acc.other + elem.uberBreakdown.other,
            promotions: acc.promotions + elem.uberBreakdown.promotions,
            taxes: acc.taxes + elem.uberBreakdown.taxes,
            tips: acc.tips + elem.uberBreakdown.tips,
            toll: acc.toll + elem.uberBreakdown.toll,
          };
        },
        {
          adjustment: 0,
          cashCollected: 0,
          netFare: 0,
          other: 0,
          promotions: 0,
          taxes: 0,
          tips: 0,
          toll: 0,
        },
      );

      setIncomesInfo([
        {
          right: 'Ingresos en Apps',
          left: formatterCLP.format(totalApps),
          collapse: totalApps > 0 && (
            <UberBreakdown
              content={{ breakdown: { ...totalUberBreakdown } }}
              backgroundColor="#FFF"
            />
          ),
        },
        { right: 'Reembolsos', left: formatterCLP.format(otherIncomes) },
        { right: 'Prepago', left: formatterCLP.format(prePayParam) },
        { right: 'Total', left: formatterCLP.format(totalApps + otherIncomes + prePayParam) },
      ]);
      setExpensesInfo([
        { right: 'Km recorridos', left: formatterKm.format(kmWeek) },
        { right: 'Costo del plan', left: formatterCLP.format(totalRent) },
        { right: 'TAG', left: formatterCLP.format(totalTag) },
        { right: 'Multas de tránsito', left: formatterCLP.format(totalFines) },
        { right: 'Penalizaciones', left: formatterCLP.format(totalPenaltys) },
        { right: 'Garantía', left: formatterCLP.format(totalGuarantee) },
        { right: 'Otros cargos', left: formatterCLP.format(totalOtherExpenses) },
        { right: 'Deudas', left: formatterCLP.format(debtParam) },
        {
          right: 'Total',
          left: formatterCLP.format(totalExpenses),
        },
      ]);
      setTotal(totalApps + otherIncomes - totalExpenses);
    }
  }, [allBillDays]);

  if (loading) {
    return <LoadingProgress />;
  }

  return details !== null ? (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
        }}
      >
        <DetailBalance dateRange={dateRange} billDays={billDays} typeDetail={details} />
      </Box>
    </Box>
  ) : (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: '20px',
        paddingY: '20px',
      }}
    >
      {date ? (
        <Box
          sx={{
            padding: '0px 20px 0px 20px',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          <GraphWeek date={dateRange} billDays={billDays} />

          <ResumeBox
            onClickButton={() => {
              setDetails('incomes');
            }}
            title="Recaudaciones"
            data={incomesInfo}
            iconTitle={
              <img
                src={arrowTucar}
                alt="category"
                style={{
                  width: '18px',
                  transform: 'rotate(180deg)',
                }}
              />
            }
          />
          <ResumeBox
            onClickButton={() => {
              setDetails('expenses');
            }}
            title="Descuentos"
            data={expensesInfo}
            iconTitle={
              <img
                src={arrowTucar}
                alt="category"
                style={{
                  width: '18px',
                }}
              />
            }
          />

          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
              <PaidOutlinedIcon
                sx={{ fontSize: '20px', transform: 'rotate(180deg)', color: azulTucar }}
              />
              <Typography sx={{ fontSize: '16px', fontFamily: 'Poppins-Medium', color: azulTucar }}>
                Resultado semanal
              </Typography>
            </Box>

            <Typography
              sx={{ fontSize: '16px', fontFamily: 'Poppins-Medium', color: azulTucar }}
              noWrap
            >
              {formatterCLP.format(total)}
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: matches ? '80vh' : '50vh',
          }}
        >
          <Typography sx={{ fontFamily: 'Poppins-Regular', fontSize: '16px' }}>
            No hay balance para mostrar
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default BalanceLiquidation;
