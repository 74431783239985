import { gql } from '@apollo/client';

const GET_EVENTS = {
  query: gql`
    query GetEventsByUser($userId: String!, $page: Int, $limit: Int) {
      getEventsByUser(userId: $userId, page: $page, limit: $limit) {
        metadata {
          page_length
          total_pages
          actual_page
          total_docuements
        }
        data {
          positive
          _id
          amount
          description
          credited {
            billRef
            rol
            ref
          }
          charged {
            ref
            rol
            billRef
          }
          title
          type
          comments
          subject
          ref
          car
          status
          createdAt
        }
      }
    }
  `,
  policies: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
};

export default GET_EVENTS;
