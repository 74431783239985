import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Typography } from '@tucar/pit-stop';
import TopNav2 from '../../../../../components/ui/TopNav/newTopNav';
import { getEndOfWeek, getStartOfWeek } from '../auxFunctions';
import EventCard from './EventCard';
import getUniqueKey from '../../../../../helpers/customKey';
import EventDetails from './EventDetails';
import EmptyView from '../../../../../components/extras/EmptyView';

const EventCards = ({ liquidationMode, selectedDate, data, setPage, eventType }) => {
  const [showDetails, setShowDetails] = useState(null);
  const eventList = data.filter((event) => (eventType ? event.subject === eventType : true));

  const defaultContent = (
    <Box>
      <TopNav2
        title="Eventos"
        prevPage={liquidationMode ? '/app/v2/liquidations' : '/app/v2/balance'}
        handlePageChange={() => setPage(null)}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography
          style={{
            color: '#5B5D71',
            fontWeight: 400,
            lineHeight: 1.2,
            fontSize: '14px',
          }}
        >
          Periodo
        </Typography>
        <Typography
          style={{
            color: '#5B5D71',
            fontWeight: 500,
            lineHeight: 1.2,
            fontSize: '14px',
          }}
        >
          {getStartOfWeek(selectedDate)} al {getEndOfWeek(selectedDate)}
        </Typography>
      </Box>

      <Box
        sx={{
          paddingTop: '40px',
          paddingBottom: '40px',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          paddingInline: '30px',
        }}
      >
        {eventList.map((eventInfo) => (
          <EventCard key={getUniqueKey()} eventInfo={eventInfo} setShowDetails={setShowDetails} />
        ))}
      </Box>
    </Box>
  );

  const [content, setContent] = useState(defaultContent);

  useEffect(() => {
    if (showDetails) {
      setContent(
        <EventDetails
          prevPage={liquidationMode ? '/app/v2/liquidations' : null}
          data={showDetails}
          setShowDetails={setShowDetails}
        />,
      );
    } else {
      setContent(defaultContent);
    }
  }, [showDetails]);

  if (eventList.length === 0) {
    return (
      <Box>
        <TopNav2
          title="Eventos"
          prevPage={liquidationMode ? '/app/v2/liquidations' : '/app/v2/balance'}
          handlePageChange={() => {
            setPage(null);
          }}
        />
        <EmptyView />
      </Box>
    );
  }

  return content;
};

EventCards.propTypes = {
  selectedDate: PropTypes.instanceOf(Date),
  data: PropTypes.arrayOf(PropTypes.shape({})),
  setPage: PropTypes.func,
  eventType: PropTypes.string,
  liquidationMode: PropTypes.bool,
};

EventCards.defaultProps = {
  selectedDate: new Date(),
  data: [],
  eventType: '',
  liquidationMode: false,
  setPage: () => {},
};

export default EventCards;
