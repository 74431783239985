import React from 'react';
import { Box, IconButton, Typography, CircularProgress } from '@mui/material';
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';
import { azulTucar } from '../../../themes/variants/TucarColors';
import { formatterKm } from '../../../helpers/formatters';

const BarChart = ({ weekGraph, dataSeries, dayArray, totalKm, totalTucar, totalUber, click }) => {
  const values = {
    Uber: totalKm > 0 ? Math.max((totalUber * 100) / totalKm, 0) : 0,
    Otros: totalKm > 0 ? Math.max((totalTucar * 100) / totalKm, 0) : 0,
  };
  const kms = { Uber: totalUber, Otros: totalTucar, Total: totalKm };
  const colors = { Uber: '#000', Otros: azulTucar, Total: '#AAAAAA' };
  if (weekGraph) {
    return (
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            minHeight: '100%',
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            minWidth: '100%',
            justifyContent: 'center',
          }}
        >
          <CircularProgress
            size={190}
            variant="determinate"
            sx={{
              position: 'absolute',
              color: '#ccc',
              display: 'flex',
              alignSelf: 'center',
            }}
            thickness={3}
            value={100}
          />
          <CircularProgress
            size={190}
            variant="determinate"
            sx={{
              color: click === 'Uber' || click === 'Total' ? '#000' : '#ccc',
              position: 'absolute',
              transform: `rotate(${-(values.Uber * 360) / 100 - 90}deg) !important`,
            }}
            thickness={3}
            value={values.Uber}
          />

          <CircularProgress
            size={190}
            variant="determinate"
            value={values.Otros}
            thickness={3}
            sx={{
              position: 'absolute',
              color: click === 'Otros' || click === 'Total' ? azulTucar : '#ccc',
              display: 'flex',
            }}
          />

          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography
              fontSize="20px"
              variant="caption"
              component="div"
              fontFamily="Poppins-Medium"
              lineHeight={1}
              color={colors[click]}
            >
              {click}
            </Typography>
            <Typography
              fontSize="22px"
              variant="caption"
              component="div"
              fontFamily="Poppins-Medium"
              lineHeight={1.5}
            >
              {`${formatterKm.format(kms[click])} km`}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'fit-content(1fr)',
        gridTemplateRows: '25px auto 1.688rem',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography
          sx={{ color: azulTucar, lineHeight: 1, fontSize: '1.3rem' }}
          fontFamily="Poppins-Medium"
        >
          {formatterKm.format(totalKm)} km
        </Typography>
        <Typography
          sx={{
            lineHeight: 1,
            fontSize: '0.75rem',
          }}
          fontFamily="Poppins-Regular"
        >
          recorridos en la semana
        </Typography>
      </Box>
      <Chart
        type="bar"
        width="100%"
        height={150}
        series={dataSeries}
        options={{
          chart: {
            type: 'bar',
            offsetY: 0,
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          noData: {
            text: 'No hay información para mostrar',
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
              color: '#AAAAAA',
              fontSize: '0.875rem',
              fontFamily: 'Poppins-Regular',
            },
          },
          states: {
            active: {
              filter: {
                type: 'none',
              },
            },
          },
          tooltip: {
            inverseOrder: false,
            enabled: true,
            fillSeriesColor: false,
            theme: 'light',
            style: {
              fontSize: '12px',
              fontFamily: 'Poppins-Medium',
            },
            y: {
              title: {
                formatter: (seriesName) => {
                  if (seriesName.includes('Uber')) {
                    return 'Uber';
                  }
                  return 'Otros';
                },
              },
              formatter: (value) => {
                return `${value} km`;
              },
            },
            x: {
              show: false,
            },
            marker: {
              show: true,
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '50%',
              dataLabels: {
                position: 'top',
              },
            },
          },
          stroke: {
            show: true,
            width: 1,
            colors: ['transparent'],
          },
          xaxis: {
            categories: dayArray,
            labels: {
              rotateAlways: false,
              show: dayArray.length > 0,
              style: {
                fontFamily: 'Poppins-Medium',
                fontSize: '0.625rem',
              },
            },
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
            },
          },
          yaxis: {
            show: false,
          },
          legend: {
            show: false,
            fontFamily: 'Poppins-Regular',
            labels: { colors: '#AAAAAA' },
            position: 'top',
            markers: {
              radius: 55,
              height: '0.438rem',
              width: '0.438rem',
            },
            onItemClick: {
              toggleDataSeries: false,
            },
          },
          dataLabels: {
            formatter: (val) => {
              if (val > 0) {
                return `${val} km`;
              }
              return '-';
            },
            enabled: false,
            textAnchor: 'middle',
            offsetY: -10,
            style: {
              colors: ['#AAAAAA'],
              fontFamily: 'Poppins-Light',
              fontSize: '0.625rem',
            },
          },
          grid: {
            show: true,
            xaxis: {
              lines: {
                show: false,
              },
            },
            yaxis: {
              lines: {
                show: false,
              },
            },
          },
        }}
      />
      <Box
        sx={{
          visibility: 'visible',
          display: 'inline-grid',
          gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
          paddingInline: '10px',
          width: '100%',
          transform: 'translateY(-1rem)',
        }}
      >
        {['Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab', 'Dom'].map((elem) => {
          return (
            <IconButton
              size="small"
              disabled
              key={elem}
              sx={{
                width: '100%',
                height: '1.688rem',
                marginTop: '0px',
                color: '#FFF',
                '&:disabled': {
                  color: '#AAAAAA',
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: '0.625rem',
                  fontFamily: 'Poppins-Regular',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {elem}
              </Typography>
            </IconButton>
          );
        })}
      </Box>
    </Box>
  );
};

BarChart.propTypes = {
  click: PropTypes.string,
  weekGraph: PropTypes.bool.isRequired,
  totalKm: PropTypes.number.isRequired,
  totalTucar: PropTypes.number.isRequired,
  totalUber: PropTypes.number.isRequired,
  dataSeries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dayArray: PropTypes.arrayOf(PropTypes.string).isRequired,
};

BarChart.defaultProps = {
  click: 'Total',
};

export default BarChart;
