import React, { useState, useEffect } from 'react';
import { Link as reactLink, useNavigate, useOutletContext } from 'react-router-dom';
import { TextField, Button, Box, Typography, Link } from '@mui/material';
import { useLazyQuery } from '@apollo/client';

import SIGN_IN_TUCAR from '../../../../graphql/querys/auth/signInTucar';
import getErrorMsgGraphql from '../../../../helpers/getErrorMsgGraphql';

const SignInForm = () => {
  const [handlePageChange] = useOutletContext();

  useEffect(() => {
    handlePageChange();
  }, []);

  const [signInWithTucar, { error, loading, data }] = useLazyQuery(
    SIGN_IN_TUCAR.query,
    SIGN_IN_TUCAR.policies,
  );

  const [loadingButton, setLoadingButton] = useState(false);
  const [values, setValues] = useState({ email: '', password: '' });
  const [errorValues, setErrorValues] = useState({ email: false, password: false });
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleChange = (name, event) => {
    setValues({ ...values, [name]: event });
    if (event) {
      setErrorValues({ email: false, password: false });
    }
  };

  const handleError = (msg = 'Correo o contraseña incorrecta') => {
    if (msg.includes('Invalid email')) {
      setErrorMessage('Correo electrónico incorrecto');
      setErrorValues((infoErrors) => ({
        ...infoErrors,
        email: true,
      }));
    } else if (msg.includes('Incorrect password')) {
      setErrorMessage('Contraseña incorrecta');
      setErrorValues((infoErrors) => ({
        ...infoErrors,
        password: true,
      }));
    } else {
      setErrorMessage('Correo o contraseña incorrecta');
      setErrorValues((infoErrors) => ({
        ...infoErrors,
        password: true,
      }));
    }
  };

  const handleClick = () => {
    setLoadingButton(true);
    signInWithTucar({
      variables: values,
    });
  };

  useEffect(() => {
    if (!loading && data) {
      navigate('/app/', { replace: true });
    } else if (error) {
      handleError(getErrorMsgGraphql(error));
      setLoadingButton(false);
    } else {
      if (data && !values.email) {
        handleError('email invalid');
      }
      if (data && !values.password) {
        handleError('password invalid');
      }
      setLoadingButton(false);
    }
  }, [loading, data]);

  return (
    <Box
      component="form"
      onSubmit={(event) => event.preventDefault()}
      display="flex"
      justifyContent="center"
      autoComplete="off"
      flexDirection="column"
      sx={{ gap: '10px' }}
    >
      <Box>
        <TextField
          size="small"
          autoComplete="off"
          data-testid="emailBox"
          sx={{ margin: 0 }}
          fullWidth
          name="email"
          label="Correo electrónico"
          variant="outlined"
          error={errorValues.email}
          helperText={errorValues.email ? `${errorMessage}` : ''}
          onChange={(e) => handleChange('email', e.target.value.toLowerCase())}
          inputProps={{
            style: {
              fontFamily: 'Poppins-Regular',
            },
          }}
          InputLabelProps={{
            style: {
              fontFamily: 'Poppins-Regular',
              fontSize: '14px',
            },
          }}
        />
      </Box>

      <Box>
        <TextField
          size="small"
          sx={{ margin: 0 }}
          autoComplete="off"
          data-testid="passwordBox"
          fullWidth
          name="password"
          variant="outlined"
          label="Contraseña"
          type="password"
          error={errorValues.password}
          helperText={errorValues.password ? `${errorMessage}` : ''}
          onChange={(e) => handleChange('password', e.target.value)}
          inputProps={{
            style: {
              fontFamily: 'Poppins-Regular',
            },
          }}
          InputLabelProps={{
            style: {
              fontFamily: 'Poppins-Regular',
              fontSize: '14px',
            },
          }}
        />

        <Button
          autoComplete="off"
          type="submit"
          data-testid="submit"
          fullWidth
          variant="contained"
          onClick={handleClick}
          disabled={loadingButton}
          sx={{
            fontSize: '14px',
            marginTop: '10px',
            boxShadow: 0,
            textAlign: 'center',
            lineHeight: 1,
          }}
        >
          Iniciar sesión
        </Button>

        <Box sx={{ textAlign: 'center', marginTop: '15px' }}>
          <Link color="#000000" component={reactLink} data-testid="forgotLink" to="/forgot">
            <Typography
              sx={{ fontSize: '14px', fontFamily: 'Poppins-Light', color: '#333333' }}
              variant="text"
              type="link"
            >
              ¿Olvidaste tu contraseña?
            </Typography>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default SignInForm;
