function translateUberPro(uberPro) {
  const auxLowerCase = uberPro ? uberPro.toLowerCase() : '';
  switch (auxLowerCase) {
    case 'diamond':
      return 'Diamante';
    case 'platinum':
      return 'Platino';
    case 'gold':
      return 'Oro';
    default:
      return 'Azul';
  }
}

export default translateUberPro;
