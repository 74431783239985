import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { getDriverStore } from '../../../graphql/store';

import InfoModal from '../infoModal';
import SubmitButton from '../../SubmitButton';
import warningIcon from '../../../../public/images/terms&conditions/alert.svg';

const TermsAndConditionModal = () => {
  const { verifiedList } = useReactiveVar(getDriverStore);
  const [open, setOpen] = useState(!verifiedList?.tc);
  const navigate = useNavigate();
  const defaultMessage = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
        alignItems: 'center',
        padding: '20px',
      }}
    >
      <img
        src={warningIcon}
        alt="category"
        style={{
          width: '5rem',
        }}
      />
      <Typography sx={{ textAlign: 'center', fontFamily: 'Poppins-Medium', fontSize: '20px' }}>
        Cambios en los Términos de Servicio
      </Typography>
      <Typography
        variant="span"
        sx={{
          textAlign: 'center',
          display: 'inline-block',
          fontFamily: 'Poppins-Regular',
          fontSize: '14px',
        }}
      >
        Hemos realizado cambios en las coberturas del seguro (10.5).
      </Typography>
      <Typography
        variant="span"
        sx={{
          textAlign: 'center',
          display: 'inline-block',
          fontFamily: 'Poppins-Regular',
          fontSize: '14px',
        }}
      >
        Antes de continuar, por favor revisa y acepta los cambios para poder acceder a tu cuenta. Tu
        seguridad y experiencia en nuestra aplicación son nuestra prioridad. Gracias por tu
        comprensión y colaboración.
      </Typography>

      <SubmitButton
        text="Continuar"
        handler={() => {
          navigate('/terms-and-conditions', { replace: true });
        }}
      />
    </Box>
  );

  return <InfoModal open={open} handleClose={() => setOpen(false)} content={defaultMessage} />;
};

export default TermsAndConditionModal;
