import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import moment from 'moment';
import PropTypes from 'prop-types';
import { azulTucar } from '../../../themes/variants/TucarColors';
import SwitchLabel from '../../../components/buttons/switchLabel';
import BarChart from './BarDonutChar';

const DriverGraph = ({ styleProps, allInfo, selecteDate }) => {
  const [dayArray, setDayArray] = useState([]);
  const [dataSeries, setDataSeries] = useState([]);
  const [totalKm, setTotalKm] = useState(0);
  const [weekGraph, setWeekGraph] = useState(true);
  const [totalUber, setTotalUber] = useState(0);
  const [totalTucar, setTotalTucar] = useState(0);
  const [click, setClick] = useState('Total');

  useEffect(() => {
    if (selecteDate && allInfo) {
      const aux = allInfo.filter((elem) => {
        if (elem.date === selecteDate) {
          return true;
        }
        return false;
      });
      if (aux.length > 0) {
        const days = [0, 1, 2, 3, 4, 5, 6].map((elem) => {
          return moment(aux[0].initialDatetime).add(elem, 'd').format('DD');
        });

        const auxDays = aux[0].billDays.reduce(
          (acc, cur) => ({ ...acc, [moment(cur.date).format('DD')]: { ...cur } }),
          {},
        );

        const kms = days.map((day) => {
          if (Object.keys(auxDays).includes(day)) {
            return Math.max(auxDays[day].km, auxDays[day].kmUber); // maximo entre estos dos
          }
          return 0;
        });

        const auxKm = days.map((day) => {
          if (Object.keys(auxDays).includes(day)) {
            return Math.max(auxDays[day].km, auxDays[day].kmUber) - auxDays[day].kmUber;
          }
          return 0;
        });

        const kmsUber = days.map((day) => {
          if (Object.keys(auxDays).includes(day)) {
            return auxDays[day].kmUber;
          }
          return 0;
        });

        const auxTotalKm = kms.reduce((acc, curr) => {
          return acc + curr;
        }, 0);

        const auxTotalKmUber = kmsUber.reduce((acc, curr) => {
          return acc + curr;
        }, 0);

        setTotalTucar(Math.max(auxTotalKm - auxTotalKmUber, 0));
        setTotalUber(auxTotalKmUber);
        setTotalKm(Math.max(auxTotalKm, 0));
        setDataSeries([
          {
            name: 'Kilómetros recorridos',
            data: auxKm,
            color: '#0057B8',
          },
          {
            name: 'Kilómetros Uber recorridos',
            data: kmsUber,
            color: '#000000',
          },
        ]);
        setDayArray(days);
      }
    }
  }, [selecteDate, allInfo]);

  const cicleClick = () => {
    if (click === 'Total') {
      setClick('Uber');
    } else if (click === 'Uber') {
      setClick('Otros');
    } else {
      setClick('Total');
    }
  };

  const hanldeGraphClick = (name) => {
    if (click !== name) {
      setClick(name);
    } else {
      setClick('Total');
    }
  };

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'fit-content(1fr)',
        gridTemplateRows: '1fr 12.5rem 1fr',
        marginBottom: '3rem',
      }}
    >
      <SwitchLabel
        styleProps={{ ...styleProps.switch }}
        right="Resumen diario"
        left="Resumen semanal"
        handle={() => {
          setWeekGraph(!weekGraph);
        }}
      />

      <Box
        onClick={cicleClick}
        sx={{
          ...styleProps.graph,
          marginTop: '20px',
          height: '12.5rem',
          width: 'fit-content(100%)',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <BarChart
          weekGraph={weekGraph}
          dataSeries={dataSeries}
          dayArray={dayArray}
          totalKm={totalKm}
          totalTucar={totalTucar}
          totalUber={totalUber}
          click={click}
        />
      </Box>

      <Box
        sx={{
          width: '100%',
          paddingInline: '0.625rem',
          transform: 'translateY(1.5rem)',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box
            sx={{
              gap: '2rem',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{ display: 'flex', gap: '5px' }}
              onClick={() => {
                hanldeGraphClick('Uber');
              }}
            >
              <CircleRoundedIcon sx={{ color: '#000', fontSize: '12px', margin: 0, padding: 0 }} />
              <Typography
                sx={{ fontFamily: 'Poppins-Regular', fontSize: '10px', verticalAlign: 'middle' }}
              >
                Km recorridos en Uber
              </Typography>
            </Box>
            <Box
              sx={{ display: 'flex', gap: '5px' }}
              onClick={() => {
                hanldeGraphClick('Otros');
              }}
            >
              <CircleRoundedIcon
                sx={{ color: azulTucar, fontSize: '12px', margin: 0, padding: 0 }}
              />
              <Typography
                sx={{ fontFamily: 'Poppins-Regular', fontSize: '10px', verticalAlign: 'middle' }}
              >
                Km recorridos en otros
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

DriverGraph.propTypes = {
  allInfo: PropTypes.arrayOf(PropTypes.shape({})),
  selecteDate: PropTypes.string.isRequired,
  styleProps: PropTypes.shape({
    switch: PropTypes.shape({}),
    graph: PropTypes.shape({}),
  }),
};

DriverGraph.defaultProps = {
  allInfo: [],
  styleProps: {
    switch: {},
    graph: {},
  },
};

export default DriverGraph;
