import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useOutletContext } from 'react-router-dom';
import { useReactiveVar, useQuery } from '@apollo/client';
import { getDriverStore } from '../../../../graphql/store';
import TopBar from '../../../../components/ui/TopNav';
import Tabs from '../../../../components/ui/Tabs';
import MaintenanceSection from './maintenanceSection';

import GET_CAR_APPOINTMENTS from '../../../../graphql/querys/car/getAppointments';
import LoadingProgress from '../../../../components/ui/LoadingProgress';

const Maintenance = () => {
  const [handlePageChange] = useOutletContext();
  const { rent } = useReactiveVar(getDriverStore);

  const { loading, data } = useQuery(GET_CAR_APPOINTMENTS.query, {
    ...GET_CAR_APPOINTMENTS.policies,
    variables: {
      carId: rent?.carId,
    },
  });

  useEffect(() => {
    handlePageChange(<TopBar title="Mantenciones" />, true);
  }, []);
  const [pendientArray, setPendientArray] = useState([]);
  const [doneArray, setDoneArray] = useState([]);
  const [absentArray, setAbsentArray] = useState([]);

  useEffect(() => {
    if (data?.car?.appointments?.appointments) {
      const auxArray = data.car.appointments.appointments;

      const auxDone = auxArray.filter((elem) => elem.stage === 'Salida');
      const auxPendient = auxArray.filter((elem) => elem.stage === 'Agendamiento');
      const auxAbsent = auxArray.filter((elem) => elem.stage === 'Inasistente');

      setPendientArray(auxPendient);
      setDoneArray(auxDone);
      setAbsentArray(auxAbsent);
    }
  }, [data]);

  const tabs = [
    { value: 'pendient', label: 'Pendientes' },
    { value: 'done', label: 'Realizadas' },
    { value: 'absent', label: 'Ausentes' },
  ];

  const [currentTab, setCurrentTab] = useState('pendient');
  const tabComponent = {
    pendient: <MaintenanceSection key="pendient" content={pendientArray} variant="map" />,
    done: <MaintenanceSection key="done" content={doneArray} variant="done" />,
    absent: <MaintenanceSection key="absent" content={absentArray} variant="absent" />,
  };

  if (loading) {
    return <LoadingProgress allScreen={false} heightContainer="100%" />;
  }

  if (rent?.carId) {
    return (
      <Box sx={{ height: '100%', display: 'grid', gridTemplateRows: 'max-content 1fr' }}>
        <Tabs
          containerStyle={{ marginBottom: '20px', height: 'max-content' }}
          currentTabName={currentTab}
          setCurrentTabFunction={setCurrentTab}
          tabs={tabs}
        />
        <Box sx={{ overflow: 'auto', height: '100%' }}>{tabComponent[currentTab]}</Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <Typography sx={{ fontFamily: 'Poppins-Regular', fontSize: '16px' }}>
        No tienes un auto asignado
      </Typography>
    </Box>
  );
};

export default Maintenance;
