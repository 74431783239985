const stylesTable = {
  totalCell: {
    borderLeft: '2px solid #ccc !important',
  },
  collapseTitle: {
    padding: '10px 20px 10px 35px',
  },
  floatingCenterScreen: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
  iconArrow: {
    position: 'absolute',
    top: '50%',
    right: '5px',
    transform: 'translateY(-50%)',
    fontSize: '20px',
  },
  stickyHeader: {
    position: 'sticky',
    left: 0,
    zIndex: 1,
    maxWidth: '180px',
  },
  headerColumn: {
    padding: '10px 20px',
    display: 'block',
    boxShadow: '2px 10px 15px -1px rgba(60, 60, 60, 0.2)',
    fontFamily: 'Poppins-Bold',
    fontSize: '13px',
    color: '#17171C',
    lineHeight: 1.2,
    backgroundColor: '#FFF',
  },
  headerText: {
    display: 'flow',
    boxShadow: '2px 10px 15px -1px rgba(60, 60, 60, 0.2)',
    fontFamily: 'Poppins-Regular',
    fontSize: '13px',
    color: '#5B5D71',
    lineHeight: 1.2,
    backgroundColor: '#FFF',
    padding: '0px',
  },
  positiveText: {
    padding: '0px',
    '&:first-of-type': {
      display: 'block',
      backgroundColor: '#FFF',
      boxShadow: '2px 10px 15px -1px rgba(60, 60, 60, 0.2)',
    },
    fontFamily: 'Poppins-Bold',
    fontSize: '13px',
    color: '#7FB418',
    lineHeight: 1.2,
  },
  negativeText: {
    '&:first-of-type': {
      display: 'block',
      backgroundColor: '#FFF',
      boxShadow: '2px 10px 15px -1px rgba(60, 60, 60, 0.2)',
    },
    padding: '0px',
    fontFamily: 'Poppins-Bold',
    fontSize: '13px',
    color: '#FF2300',
    lineHeight: 1.2,
  },
  regularText: {
    padding: '0px',
    fontFamily: 'Poppins-Light',
    fontSize: '13px',
    color: '#5B5D71',
    lineHeight: 1.2,
  },
  headerCell: {
    fontFamily: 'Poppins-Bold',
    fontSize: '13px',
    lineHeight: 1.2,
    color: '#fff',
  },
};

export default stylesTable;
