export const shiftObjToType = (shiftObj) => {
  if (shiftObj.am && shiftObj.pm) {
    return 'ALL';
  }
  if (shiftObj.am) {
    return 'AM';
  }
  if (shiftObj.pm) {
    return 'PM';
  }
  return 'FREE';
};

export const showTime = (timeObj) => {
  const startTime = timeObj?.startTime === '-' ? '--:--' : timeObj?.startTime;
  const endTime = timeObj?.endTime === '-' ? '--:--' : timeObj?.endTime;
  return `${startTime} a ${endTime} hrs.`;
};
