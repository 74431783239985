import React from 'react';
import PropTypes from 'prop-types';
import { Typography, IconButton, Box } from '@mui/material';
import { ErrorOutline, ExpandCircleDownOutlined } from '@mui/icons-material';

const CardButtonDash = ({
  IconElement,
  styleProps,
  content,
  tutorial,
  sendText,
  colors,
  handleGo,
  toGo,
  contentDirection,
  iconSize,
}) => {
  return (
    <Box
      sx={{
        ...styleProps.container,
        backgroundColor: colors.secondary,
        padding: 2,
        borderRadius: '15px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: contentDirection,
          gap: '5px',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <IconElement
          sx={{
            alignSelf: 'start',
            color: colors.primary,
            fontSize: iconSize,
            marginRight: '10px',
          }}
        />
        {content}
      </Box>
      {toGo ? (
        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
          <IconButton
            disabled={tutorial}
            onClick={handleGo}
            sx={{
              alignSelf: 'end',
              position: 'static',
              display: 'flex',
              gap: '5px',
              justifyContent: 'center',
              backgroundColor: colors.secondary,
              borderRadius: '7px',
              color: colors.primary,
            }}
          >
            <Typography
              sx={{ fontSize: '12px', fontFamily: 'Poppins-Medium', color: colors.primary }}
              noWrap
            >
              {sendText}
            </Typography>
            <ExpandCircleDownOutlined
              sx={{
                transform: 'rotate(270deg)',
                fontSize: '10px',
                color: colors.primary,
                borderRadius: '50px',
              }}
            />
          </IconButton>
        </Box>
      ) : null}
    </Box>
  );
};

CardButtonDash.propTypes = {
  IconElement: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  sendText: PropTypes.string,
  content: PropTypes.element,
  tutorial: PropTypes.bool,
  styleProps: PropTypes.shape({
    container: PropTypes.shape({}),
  }),
  colors: PropTypes.shape({
    primary: PropTypes.string,
    secondary: PropTypes.string,
  }),
  handleGo: PropTypes.func,
  toGo: PropTypes.bool,
  contentDirection: PropTypes.string,
  iconSize: PropTypes.string,
};

CardButtonDash.defaultProps = {
  iconSize: '1.875rem',
  contentDirection: 'row',
  toGo: true,
  IconElement: ErrorOutline,
  handleGo: () => {},
  sendText: 'Ir a',
  content: <Box />,
  tutorial: false,
  styleProps: { container: {} },
  colors: { primary: '#000', secondary: '#ccc' },
};

export default CardButtonDash;
