import PropTypes from 'prop-types';
import React from 'react';
import { Box, Typography } from '@mui/material';
import { Item, TucarTheme, Icon } from '@tucar/pit-stop';

const iconPlanTranslate = (itemName) => {
  switch (itemName) {
    case 'Costos de mantención.':
      return 'buildFilled';
    case 'Uso exclusivo del auto.':
      return 'electricCarFilled';
    case 'Kilometraje ilimitado.':
      return 'speedOutlined';
    case 'Seguro especial para uso en apps.':
      return 'beenhereFilled';
    case 'Asistencia en ruta las 24 horas.':
      return 'forumFilled';
    case 'Arriendo por tiempo ilimitado.':
      return 'directionsCarFilled';
    case 'Documentación del vehículo.':
      return 'textSnippetFilled';
    case 'Costo de las cargas electricidad.':
      return 'batteryChargingFullFilled';
    case 'Disponibilidad del auto 16 horas.':
      return 'timerFilled';
    case 'Multas de tránsito.':
      return 'driveFileRenameOutlineFilled';
    case 'Limpieza interior y exterior.':
      return 'localCarWashFilled';
    case 'Tag y peajes manuales.':
      return 'approvalFilled';
    case 'Elección del vehículo. Sujeto a disponibilidad.':
      return 'carRepairFilled';
    default:
      return 'controlPointOutlined';
  }
};

const PlanAdds = ({ includeArray }) => {
  if (!includeArray.length) return null;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
        paddingInline: '20px',
        marginBottom: '40px',
      }}
    >
      <Typography
        sx={{
          color: TucarTheme.colors.gray700,
          fontFamily: 'Poppins-Medium',
          fontSize: '16px',
          textAlign: 'center',
        }}
      >
        Tu plan incluye:
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '10px',
          justifyContent: 'center',
        }}
      >
        {includeArray.map((itemName) => (
          <Box
            key={`${itemName}-includes`}
            sx={{
              margin: 'auto',
              height: 'min-content',
              maxWidth: '118px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'start',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <Item style={{ padding: '10px' }}>
              <Icon
                name={iconPlanTranslate(itemName)}
                size="30px"
                color={TucarTheme.colors.blue500}
              />
            </Item>
            <Typography
              sx={{
                fontSize: '13px',
                color: TucarTheme.colors.gray700,
                fontFamily: 'Poppins-Regular',
                textAlign: 'center',
                lineHeight: 1,
              }}
            >
              {itemName}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

PlanAdds.propTypes = {
  includeArray: PropTypes.arrayOf(PropTypes.string),
};

PlanAdds.defaultProps = {
  includeArray: [],
};

export default PlanAdds;
