import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { useReactiveVar, useMutation } from '@apollo/client';

import BankAccountForm from '../../../components/ui/BankAccountForm';
import { getAuthStore, getDriverStore } from '../../../graphql/store';
import ADD_BANK from '../../../graphql/mutations/driver/addBank';
import OnBoardingTitle from '../../../components/extras/OnboardingTitle';
import ErrorModal from '../../../components/ui/ErrorModal';
import NewAccountingInfo from '../../app/profile/NewVersion/Accounting';
import OldAccountingInfo from '../../app/profile/OldVersion/Accounting';
import OldBankAccountForm from '../../../components/ui/OldBankAccountForm';

const OnBoardingBankAccount = () => {
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);
  const [addBank, { loading, data, error }] = useMutation(ADD_BANK.mutation);
  const [open, setOpen] = useState(false);

  const {
    verifiedList: { bank },
    labelSubscription,
  } = useReactiveVar(getDriverStore);

  const [values, setValues] = useState({
    uid,
    rol: 'Driver',
    bank: '',
    accountType: '',
    account: '',
    name: '',
    rut: '',
    email: '',
  });

  const handleSend = () => {
    setValues((currentValues) => ({
      ...currentValues,
      account: values.account.split(' ').join('').trim(),
    }));
    addBank({ variables: values }).catch(() => {});
  };

  useEffect(() => {
    if (!loading) {
      if (data) {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      }
      if (error) {
        setOpen(true);
      }
    }
  }, [loading]);

  if (bank) {
    return labelSubscription ? <NewAccountingInfo onBoarding /> : <OldAccountingInfo onBoarding />;
  }

  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'Column', gap: '10px' }}>
      <ErrorModal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        content="Ups, ha ocurrido un error de nuestra parte. Contacta a soporte pinchando en el chat."
        title="Error al enviar información"
        emojiSymbol="🤔"
      />
      <OnBoardingTitle noLineal title="Agregar cuenta bancaria" />
      {labelSubscription ? (
        <BankAccountForm values={values} setValues={setValues} handleSend={handleSend} />
      ) : (
        <OldBankAccountForm values={values} setValues={setValues} handleSend={handleSend} />
      )}
    </Box>
  );
};

export default OnBoardingBankAccount;
