import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { Loader } from '@tucar/pit-stop';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { useOutletContext } from 'react-router-dom';

import TopNav2 from '../../../components/ui/TopNav/newTopNav';
import SwitchCard from './components/switchCard';
import StatusCard from './components/statusCard';
import WhatItIsCard from './components/whatItIsCard';
import ExtraInfoCard from './components/extraInfoCard';
import GET_CASH_BLOCKING from '../../../graphql/querys/driver/getCashBlocking';
import { getAuthStore } from '../../../graphql/store';
import EmptyView from '../../../components/extras/EmptyView';

const CashBlock = () => {
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);
  const [showScreen, setShowScreen] = useState(false);
  const [handlePageChange] = useOutletContext();
  const [blocked, setBlocked] = useState(false);
  const [blockedBy, setBlockedBy] = useState('driver');
  const [getCashData, { loading, data }] = useLazyQuery(GET_CASH_BLOCKING.query, {
    ...GET_CASH_BLOCKING.policies,
    variables: {
      driverId: uid,
    },
  });

  useEffect(() => {
    getCashData();
    handlePageChange(false, true);
  }, []);

  useEffect(() => {
    if (!loading && data?.getBond?.uberSupplier) {
      setShowScreen(true);
    }
    if (!loading && data?.getDriverCashBlocking) {
      setBlocked(data?.getDriverCashBlocking?.block || false);
      setBlockedBy(data?.getDriverCashBlocking?.blockByType?.toLowerCase() || 'driver');
    }
  }, [loading]);

  if (loading) {
    return (
      <Box>
        <TopNav2 title="Bloqueo viajes en efectivo" />
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Loader size={32} pointRadio="4px" />
        </Box>
      </Box>
    );
  }

  if (!showScreen) {
    return (
      <Box>
        <TopNav2 title="Bloqueo viajes en efectivo" />
        <EmptyView />
      </Box>
    );
  }

  return (
    <Box>
      <TopNav2 title="Bloqueo viajes en efectivo" />
      <Box
        sx={{
          paddingInline: '30px',
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          marginBottom: '80px',
        }}
      >
        <WhatItIsCard />
        <StatusCard blocked={blocked} blockedBy={blockedBy} />
        <SwitchCard blocked={blocked} setBlocked={setBlocked} blockedBy={blockedBy} />
        <ExtraInfoCard />
      </Box>
    </Box>
  );
};

export default CashBlock;
