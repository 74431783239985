import React, { useEffect, useState } from 'react';
import { Loader, Icon, Button } from '@tucar/pit-stop';
import { Box } from '@mui/material';
import { useReactiveVar, useLazyQuery } from '@apollo/client';

import { getAuthStore } from '../../../../graphql/store';
import GET_SHIFT_PETITIONS from '../../../../graphql/querys/exchange/getShiftChangePetitions';
import PetitionTutorialButton from './tutorialButton';
import ShiftCard from '../shiftCard';
import { azulTucar } from '../../../../themes/variants/TucarColors';
import ParnerCard from './parnerCard';
import HistoryPetitions from '../historyPetitions';
import TopNav2 from '../../../../components/ui/TopNav/newTopNav';
import CreationPetitions from '../creationPetitions';
import PendientPetition from './pendientPetition';
import { getPartner, getPartnerId } from '../helpers';

const ShiftPetitionScreen = () => {
  const [currentTab, setCurrentTab] = useState('general');
  const [shiftOptions, setShiftOptions] = useState([]);
  const [assignationData, setAssignationData] = useState([]);
  const [currentShift, setCurrentShift] = useState([]);
  const [createdPetition, setCreatedPetition] = useState(null);
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);
  const [getPetitionsInfo, { data: petitionsInfo, loading: loadingPetitionsInfo, error }] =
    useLazyQuery(GET_SHIFT_PETITIONS.query, {
      ...GET_SHIFT_PETITIONS.policies,
      variables: {
        uid,
        driverId: uid,
        offset: 0,
        limit: 1,
      },
    });

  useEffect(() => {
    if (currentTab === 'general') getPetitionsInfo();
  }, [currentTab]);

  useEffect(() => {
    if (!loadingPetitionsInfo && petitionsInfo?.getDriverPlanV2) {
      if (petitionsInfo?.getShiftChangePetitions?.total > 0) {
        const { petitioner, expiration } = petitionsInfo.getShiftChangePetitions.instances[0] || {};
        setCreatedPetition({ imPetitioner: petitioner === uid, petitioner, expiration });
      } else {
        setCreatedPetition(null);
      }

      if (petitionsInfo.getAssignations?.instances.length > 0) {
        const driversData = petitionsInfo.getAssignations?.instances[0]?.drivers;
        setAssignationData(driversData);
        const currentDriver = driversData.find(({ _id: ident }) => ident === uid);
        setCurrentShift(
          currentDriver?.plan?.selectedShifts.map(({ type, startTime, endTime }) => {
            return {
              type,
              startTime,
              endTime,
            };
          }) || [],
        );
      }
      const posibleShifts = petitionsInfo.getDriverPlanV2?.plan?.shifts;
      const optionsObject = posibleShifts.reduce((acc, shiftInfo) => {
        const { _id: idShift, type, startTime, endTime } = shiftInfo;
        return { ...acc, [type]: { id: idShift, startTime, endTime } };
      }, {});
      setShiftOptions(optionsObject);
    }
  }, [loadingPetitionsInfo, petitionsInfo]);

  if (loadingPetitionsInfo || !assignationData.length) {
    return (
      <Box>
        <TopNav2 title="Turno compartido" />
        <Box
          sx={{
            paddingInline: '30px',
          }}
        >
          <PetitionTutorialButton />
        </Box>

        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Loader size={32} pointRadio="4px" />
        </Box>
      </Box>
    );
  }

  if (error) return <Box>Error</Box>;

  if (currentTab === 'create') {
    return (
      <CreationPetitions
        currentShift={currentShift}
        shiftOptions={shiftOptions}
        goBack={() => setCurrentTab('general')}
        uid={uid}
        partnerUid={getPartnerId(assignationData, uid)}
      />
    );
  }

  if (currentTab === 'history') {
    return <HistoryPetitions goBack={() => setCurrentTab('general')} />;
  }

  return (
    <Box>
      <TopNav2 title="Turno compartido" />
      <Box
        sx={{
          paddingInline: '30px',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          marginBottom: '80px',
        }}
      >
        <PetitionTutorialButton />
        <PendientPetition show={!!createdPetition} data={createdPetition} />
        <ShiftCard driversData={assignationData} />
        <Button
          disabled={createdPetition}
          fullWidth
          contentStyle={{
            justifyContent: 'center',
            display: 'grid',
            gridTemplateColumns: 'min-content 1fr',
          }}
          size="large"
          startAdornment={
            <Icon
              name="publishedWithChangesFilled"
              size="24px"
              color={createdPetition ? '#8E90A4' : azulTucar}
            />
          }
          endAdornment={
            <Box sx={{ transform: 'rotate(180deg)' }}>
              <Icon name="leftArrowSpecial" size="16px" color="#A8AABA" />
            </Box>
          }
          onClick={() => setCurrentTab('create')}
        >
          Solicitar cambio
        </Button>
        <Button fullWidth variant="ghost" onClick={() => setCurrentTab('history')}>
          Ver solicitudes anteriores
        </Button>
        <ParnerCard parnerData={getPartner(assignationData, uid)} />
      </Box>
    </Box>
  );
};

export default ShiftPetitionScreen;
