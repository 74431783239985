import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Box } from '@mui/material';
import { useReactiveVar, useLazyQuery } from '@apollo/client';
import { useOutletContext } from 'react-router-dom';
import { Loader } from '@tucar/pit-stop';

import { getAuthStore } from '../../../graphql/store';
import getUniqueKey from '../../../helpers/customKey';
import TopNav2 from '../../../components/ui/TopNav/newTopNav';
import EmptyView from '../../../components/extras/EmptyView';
import OneDateSelector from '../../../components/v2/OneDateSelector';
import UberCard from '../../../components/v2/UberCard';
import GET_UBER_TRIPS from '../../../graphql/querys/uber/getUberTrips';

function formatDate(date, start = true) {
  return moment(date).format(start ? 'YYYY-MM-DD[T00:00:00]' : 'YYYY-MM-DD[T23:59:59]');
}

const UberTripScreen = () => {
  const [handlePageChange] = useOutletContext();
  const [content, setContent] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);

  const [getUberTrips, { loading, data }] = useLazyQuery(GET_UBER_TRIPS.query, {
    ...GET_UBER_TRIPS.policies,
    variables: {
      uid,
      initialDatetime: formatDate(selectedDate),
      finalDatetime: formatDate(selectedDate, false),
    },
  });

  useEffect(() => handlePageChange(null, true), []);

  useEffect(() => {
    if (!loading && data && data?.getDriverBillDaysLimitDates) {
      const listOfTags = data.getDriverBillDaysLimitDates.reduce(
        (acc, curr) => [...acc, ...curr.uber],
        [],
      );
      setContent(listOfTags);
    }
  }, [data, loading]);

  useEffect(() => {
    if (selectedDate) {
      getUberTrips({
        initialDatetime: formatDate(selectedDate),
        finalDatetime: formatDate(selectedDate, false),
      });
    }
  }, [selectedDate]);

  const onChange = (date) => setSelectedDate(date);

  if (loading) {
    return (
      <Box>
        <TopNav2 title="Viajes en Uber" />
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Loader size={32} pointRadio="4px" />
        </Box>
      </Box>
    );
  }

  if (content.length === 0) {
    return (
      <Box>
        <TopNav2 title="Viajes en Uber" />
        <Box sx={{ paddingInline: '30px' }}>
          <OneDateSelector selectedDate={selectedDate} onChange={onChange} />
        </Box>
        <EmptyView />
      </Box>
    );
  }

  return (
    <Box>
      <TopNav2 title="Viajes en Uber" />
      <Box sx={{ paddingInline: '30px' }}>
        <OneDateSelector selectedDate={selectedDate} onChange={onChange} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '30px 30px',
          gap: '30px',
        }}
      >
        {content.map((uberTrip) => (
          <UberCard key={getUniqueKey()} uberTrip={uberTrip} />
        ))}
      </Box>
    </Box>
  );
};

export default UberTripScreen;
