import { gql } from '@apollo/client';

const GET_SHIFT_PETITIONS = {
  query: gql`
    query GetShiftPetitions($uid: ID!, $driverId: String, $offset: Int, $limit: Int) {
      getDriverPlanV2(driverId: $uid) {
        plan {
          shifts {
            _id
            type
            available
            startTime
            endTime
          }
        }
      }
      getShiftChangePetitions(
        driver: $driverId
        offset: 0
        status: created
        sortBy: "-createdAt"
        limit: 1
      ) {
        total
        instances {
          petitioner
          expiration
        }
      }
      getAssignations(driverId: $driverId, offset: $offset, limit: $limit) {
        instances {
          drivers {
            _id
            photo
            info {
              phone
              code
              firstname
              lastname
            }
            plan {
              selectedShifts {
                startTime
                endTime
                type
                name
                _id
              }
            }
          }
        }
      }
    }
  `,
  policies: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
};

export default GET_SHIFT_PETITIONS;
