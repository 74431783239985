import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { azulTucar } from '../../themes/variants/TucarColors';

export const upStepFunction = (setter, step) => {
  setter(step + 1);
};

export const downStepFunction = (setter, step) => {
  setter(step - 1);
};

export const emptyStep = {
  nextFunction: () => {},
  prevFunction: () => {},
  component: '',
  styleElement: {},
  title: '',
  content: null,
};

export const focusBoxTutorial = {
  zIndex: 5,
  position: 'relative',
  boxShadow: '0 0 0 max(100vh, 100vw) rgba(0, 0, 0, .5)',
};

export const DefaultContent = ({ textBlack, textColor }) => {
  return (
    <Box sx={{ display: 'inline-block', textAlign: 'center' }}>
      <Typography
        sx={{
          display: 'inline',
          fontFamily: 'Poppins-Regular',
          fontSize: '14px',
        }}
      >
        {textBlack}
      </Typography>
      {textColor ? (
        <Typography
          sx={{
            display: 'inline',
            fontFamily: 'Poppins-Regular',
            fontSize: '14px',
            color: azulTucar,
          }}
        >
          {textColor}
        </Typography>
      ) : null}
    </Box>
  );
};

DefaultContent.propTypes = {
  textBlack: PropTypes.string,
  textColor: PropTypes.string,
};

DefaultContent.defaultProps = {
  textBlack: '',
  textColor: '',
};
