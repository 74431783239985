import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Button, Card, Icon, Typography } from '@tucar/pit-stop';
import { azulTucar } from '../../../../../themes/variants/TucarColors';
import PressIconButton from '../../../../../components/buttons/PressIconButton';

const UberKmStep = ({ onNext }) => {
  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={{ position: 'relative', zIndex: 1 }}>
        <Box
          sx={{
            position: 'absolute',
            zIndex: 2,
            bottom: '-100%',
            width: '100%',
            '::before': {
              content: '""',
              zIndex: -1,
              position: 'absolute',
              bottom: '-130%',
              left: '-10%',
              width: '200vw',
              height: '130vh',
              borderRadius: '0px 0px 100vw 100vw',
              transform: 'translateX(-25%)',
              background: 'linear-gradient(180deg, #EAFFFE -20%, rgba(205, 201, 241, 0.80) 100%)',
              backdropFilter: 'blur(2px)',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              paddingBottom: '30px',
              paddingInline: '10%',
            }}
          >
            <Typography
              align="left"
              color={azulTucar}
              style={{ fontSize: '18px', fontWeight: 600 }}
            >
              Kilómetros en Uber
            </Typography>
            <Typography
              color={azulTucar}
              align="left"
              style={{ fontSize: '14px', fontWeight: 400, lineHeight: '16px' }}
            >
              En esta sección, podrás visualizar los KM recorridos semanales utilizando la app de
              Uber.
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <Typography color={azulTucar} style={{ fontSize: '14px', fontWeight: 500 }}>
              Paso 10/11
            </Typography>
            <Button
              size="small"
              onClick={() => {
                onNext();
                document.getElementById('section-10').scrollIntoView({ behavior: 'auto' });
              }}
            >
              Siguiente
            </Button>
          </Box>
        </Box>

        <Card
          style={{
            position: 'absolute',
            zIndex: 2,
            width: '100%',
            padding: '12px 16px',
            marginTop: '30px',
            gap: '20px',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <PressIconButton>
            <Icon name="equalizerRounded" color="#95D31C" size="36px" />
          </PressIconButton>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography color="#5B5D71" style={{ fontSize: '15px', fontWeight: 600 }}>
              El 89% de tus kilómetros
            </Typography>
            <Typography color="#5B5D71" style={{ fontSize: '14px', fontWeight: 500 }}>
              Han sido en viajes de Uber
            </Typography>
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

UberKmStep.propTypes = {
  onNext: PropTypes.func,
};

UberKmStep.defaultProps = {
  onNext: () => {},
};

export default UberKmStep;
