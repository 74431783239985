import React from 'react';
import PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';
import { Box } from '@mui/material';
import { getAuthStore } from '../../../graphql/store';
import GET_TICKETS from '../../../graphql/querys/tickets/getTickets';
import InfinityView from '../../../components/ui/infinityListView';
import { convertContent } from './auxFunctions';

const TicketList = ({ status, type }) => {
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '0px 20px 20px 20px',
        gap: '5px',
      }}
    >
      <InfinityView
        queryObject={GET_TICKETS}
        auxVars={{ status, rol: 'Driver', ref: uid }}
        primaryKey="getTickets"
        formatContent={convertContent}
        noContentText={`No tienes tickets ${type}`}
      />
    </Box>
  );
};

TicketList.propTypes = {
  status: PropTypes.arrayOf(PropTypes.string).isRequired,
  type: PropTypes.string.isRequired,
};

export default TicketList;
