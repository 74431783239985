import moment from 'moment';
import { subDays, addDays } from 'date-fns';

export const getStartOfWeek = (date, format = '') => {
  let startOfWeek = new Date(date);
  startOfWeek = subDays(startOfWeek, (date.getDay() + 6) % 7);
  const newStartOfWeek = new Date(
    startOfWeek.getFullYear(),
    startOfWeek.getMonth(),
    startOfWeek.getDate(),
    0,
    0,
    0,
  );
  if (format !== '') return moment(newStartOfWeek).format(format);

  return moment(newStartOfWeek).format('YYYY-MM-DD[T]HH:mm:ss');
};

export const getEndOfWeek = (date, format = '') => {
  let endOfWeek = new Date(date);
  endOfWeek = addDays(endOfWeek, 6 - ((date.getDay() + 6) % 7));
  const newEndOfWeek = new Date(
    endOfWeek.getFullYear(),
    endOfWeek.getMonth(),
    endOfWeek.getDate(),
    23,
    59,
    59,
  );
  if (format !== '') return moment(newEndOfWeek).format(format);

  return moment(newEndOfWeek).format('YYYY-MM-DD[T]hh:mm:ss');
};

export const getAddDate = (date, format = '', add = 0) => {
  const originalDate = addDays(new Date(date), add);
  const newEndOfWeek = new Date(
    originalDate.getFullYear(),
    originalDate.getMonth(),
    originalDate.getDate(),
    23,
    59,
    59,
  );
  if (format !== '') return moment(newEndOfWeek).format(format);

  return moment(newEndOfWeek).format('YYYY-MM-DD[T]hh:mm:ss');
};

export const capitalizeDate = (date, format = '') => {
  const dateObject = new Date(date);
  const formattedDate = moment(dateObject).format(format);
  return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
};

export const dateComparation = (date1, date2) => {
  const dateNoTime1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
  const dateNoTime2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
  return dateNoTime1.getTime() === dateNoTime2.getTime();
};

export const getDateList = (initDate) => {
  const newDate = new Date(initDate);
  const regularList = [...Array(7)].map((_, index) => {
    return addDays(newDate, index);
  });

  const todayWeekday = (new Date(initDate).getDay() + 6) % 7;
  const shiftedItems = regularList.splice(0, 7 - todayWeekday);
  regularList.push(...shiftedItems);
  return regularList;
};
