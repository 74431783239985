function insertDataLayer() {
  const dataLayer = window.dataLayer || [];
  dataLayer.push({
    _event: 'success_register',
    event: 'success_register',
    buttonName: 'registro tucar',
  });
}

export default insertDataLayer;
