import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useReactiveVar, useQuery } from '@apollo/client';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import { getAuthStore } from '../../../graphql/store';
import CategoryIcon from '../../../components/ui/categoryIcon';
import translateUberPro from '../../../helpers/translateUberPro';
import GET_UBER_GOALS from '../../../graphql/querys/benefits/getUberGoals';
import LoadingProgress from '../../../components/ui/LoadingProgress';

const TucarGoals = () => {
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);
  const [content, setContent] = useState([]);

  const { data, loading, error } = useQuery(GET_UBER_GOALS.query, {
    ...GET_UBER_GOALS.policies,
    variables: { driverId: uid },
  });

  const boxContent = (color, info, backgroundColor, block) => {
    if (block) {
      return (
        <Box
          key={`${info.objective}-${info.uberImage}`}
          sx={{
            display: 'flex',
            backgroundColor: '#fcfbfc',
            borderRadius: '15px',
            boxShadow: '0px 0px 5px #dbdbdb',
            gap: '10px',
            padding: '10px',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress
              thickness={2}
              variant="determinate"
              value={100}
              sx={{
                color: '#ccc',
              }}
              size="4rem"
            />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                width: '100%',
                height: '100%',
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'transparent',
                  borderRadius: '55px',
                  height: '90%',
                  width: '90%',
                }}
              >
                <LockOutlinedIcon sx={{ fontSize: '2rem', color: '#ccc' }} />
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              gap: '5px',
              justifyContent: 'center',
              flexDirection: 'column',
              opacity: '30%',
            }}
          >
            <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
              <CategoryIcon category={info?.uberImage} />
              <Typography sx={{ fontFamily: 'Poppins-Medium', fontSize: '14px', lineHeight: 1.2 }}>
                {info.discount}% de dcto en tu arriendo
              </Typography>
            </Box>
            <Typography sx={{ fontFamily: 'Poppins-Regular', fontSize: '12px' }}>
              Primero, completa el desafío categoría {translateUberPro(info.preName)} para acceder
              al dcto.
            </Typography>
          </Box>
        </Box>
      );
    }

    return (
      <Box
        key={`${info.objective}-${info.uberImage}`}
        sx={{
          display: 'flex',
          backgroundColor: '#fcfbfc',
          borderRadius: '15px',
          boxShadow: '0px 0px 5px #dbdbdb',
          gap: '10px',
          padding: '10px',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          <CircularProgress
            thickness={5}
            variant="determinate"
            value={info.ready < 1 ? info.ready * 100 : 100}
            sx={{
              color,
              backgroundColor,
              borderRadius: '55px',
            }}
            size="4rem"
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              width: '100%',
              height: '100%',
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {info.ready < 1 ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '55px',
                  height: '90%',
                  width: '90%',
                  border: '0px solid #fcfbfc',
                  backgroundColor: '#fcfbfc',
                }}
              >
                <Typography sx={{ fontFamily: 'Poppins-Medium' }}>
                  {info?.driver || 0}/{info?.objective}
                </Typography>
              </Box>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: color,
                  borderRadius: '55px',
                  height: '90%',
                  width: '90%',
                  border: '7px solid #fcfbfc',
                }}
              >
                <StarBorderOutlinedIcon sx={{ fontSize: '2rem', color: '#FFF' }} />
              </Box>
            )}
          </Box>
        </Box>

        {info.ready < 1 ? (
          <Box
            sx={{ display: 'flex', gap: '5px', justifyContent: 'center', flexDirection: 'column' }}
          >
            <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
              <CategoryIcon category={info?.uberImage} />
              <Typography sx={{ fontFamily: 'Poppins-Medium', fontSize: '14px', lineHeight: 1.2 }}>
                {info.discount}% de dcto en tu arriendo
              </Typography>
            </Box>
            <Typography sx={{ fontFamily: 'Poppins-Regular', fontSize: '12px' }}>
              ¡Te faltan {info.objective - info.driver} viajes para acceder al dcto. de categaría{' '}
              {translateUberPro(info.uberImage)} en Uber Pro.
            </Typography>
          </Box>
        ) : (
          <Box
            sx={{ display: 'flex', gap: '5px', justifyContent: 'center', flexDirection: 'column' }}
          >
            <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
              <CategoryIcon category={info?.uberImage} />
              <Typography sx={{ fontFamily: 'Poppins-Medium', fontSize: '14px', lineHeight: 1.2 }}>
                ¡Desafío completado!
              </Typography>
            </Box>
            <Typography sx={{ fontFamily: 'Poppins-Regular', fontSize: '12px' }}>
              Felicitaciones, tienes un {info.discount}% de dcto. en tu arriendo de la próxima
              semana.
            </Typography>
          </Box>
        )}
      </Box>
    );
  };

  useEffect(() => {
    if (data?.getAchievableUberGoals?.goals) {
      const colorList = ['#ffb947', '#afbfc3', '#000000'];
      const backgroundColorList = ['#fcebc5', '#e8eced', '#a7a7a7'];
      const uberImage = ['gold', 'platinum', 'diamond'];

      const auxContent = data.getAchievableUberGoals.goals.map((elem, index) => {
        let block;
        if (index === 0) {
          block = false;
        } else {
          block = !(
            data.getAchievableUberGoals.goals[index - 1].objectiveTrips <= elem.driverTrips
          );
        }
        const info = {
          preName: index > 0 ? uberImage[index - 1] : '',
          uberImage: uberImage[index],
          color: colorList[index],
          objective: elem.objectiveTrips,
          driver: elem.driverTrips,
          discount: elem.discount * 100,
          ready: (elem.driverTrips / elem.objectiveTrips).toFixed(2),
        };
        return boxContent(colorList[index], info, backgroundColorList[index], block);
      });
      setContent(auxContent);
    }
  }, [loading, data]);

  if (error) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Poppins-Regular',
            textAlign: 'center',
            fontSize: { xs: '14px', sm: '16px' },
          }}
        >
          Sucedió un error, prueba más tarde o contáctate con soporte para notificarlo
        </Typography>
      </Box>
    );
  }

  if (loading || content.length === 0) {
    return (
      <Box sx={{ heigth: '100%' }}>
        <LoadingProgress allScreen={false} heightContainer="100%" />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        overflow: 'auto',
        height: '100%',
        display: 'flex',
        paddingInline: '20px',
        paddingBottom: '10px',
        flexDirection: 'column',
        gap: '1rem',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {content}
    </Box>
  );
};

export default TucarGoals;
