import { gql } from '@apollo/client';

const SEND_UBER_HERO = {
  mutation: gql`
    mutation UberHero($uid: ID!) {
      uberHero(uid: $uid)
    }
  `,
  policies: {
    errorPolicy: 'all',
  },
};

export default SEND_UBER_HERO;
