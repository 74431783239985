import { gql } from '@apollo/client';

const GET_ADVICE_COUPLES = {
  query: gql`
    query GetAssignations(
      $offset: Int
      $limit: Int
      $driverId: String
      $labelId: ID!
      $skipGetLabel: Boolean = false
    ) {
      getCouples: getAssignations(offset: $offset, limit: $limit, driverId: $driverId) {
        instances {
          carId
          drivers {
            _id
            status {
              status
            }
          }
        }
      }
      getLabel(_id: $labelId) @skip(if: $skipGetLabel) {
        elements
      }
    }
  `,
  policies: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

export default GET_ADVICE_COUPLES;
