import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import ChargingStationOutlinedIcon from '@mui/icons-material/ChargingStationOutlined';
import { Marker } from '@react-google-maps/api';
import { useMapContext } from './mapContext';
import BluePin from './pinsSVG/bluePin.svg';
import MixPin from './pinsSVG/mixPin.svg';
import YellowPin from './pinsSVG/yellowPin.svg';

const MapMarker = ({ data, ident, position }) => {
  const { focusCar, carToFocus, setSearchActive } = useMapContext();
  const { _id: id } = useMemo(() => carToFocus || {}, [carToFocus]);

  const handleToggleOpen = () => {
    if (id !== ident) {
      focusCar(data, position);
      setSearchActive(true);
    } else {
      setSearchActive(false);
    }
  };

  const urlImg = () => {
    const { connectors } = data || { connectors: [] };
    const elementosUnicos = new Set(connectors.map(({ currentType }) => currentType));
    const justACType = elementosUnicos.size === 1 ? elementosUnicos.values().next().value : 'MIX';
    if (justACType === 'DC') return YellowPin;
    if (justACType === 'AC') return BluePin;
    return MixPin;
  };

  return (
    <Marker
      position={position}
      onClick={handleToggleOpen}
      icon={{
        url: urlImg(),
        scale: 7,
      }}
    >
      <Box
        style={{
          background: '#95D31C',
          border: '4px solid #fff',
          borderRadius: '40px 40px 40px 0px',
          padding: '10px',
          width: 'fit-content',
        }}
      >
        <ChargingStationOutlinedIcon sx={{ fontSize: '24px', color: '#FFF' }} />
      </Box>
    </Marker>
  );
};

MapMarker.propTypes = {
  data: PropTypes.shape({
    connectors: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  position: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }).isRequired,
  ident: PropTypes.string,
};

MapMarker.defaultProps = {
  data: {
    connectors: [],
  },
  ident: '',
};

export default MapMarker;
