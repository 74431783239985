import { gql } from '@apollo/client';
import { setAuth } from '../../store';

const SIGN_IN_TUCAR = {
  query: gql`
    query Query($email: String!, $password: String!) {
      signIn(email: $email, password: $password) {
        accessToken
        refreshToken
        data {
          uid
          email
          firstname
          lastname
          userType
          verified
        }
      }
    }
  `,
  policies: {
    fetchPolicy: 'no-cache',
    onCompleted: (response) => {
      if (response.signIn) {
        const { accessToken, refreshToken, data } = response.signIn;
        setAuth({ accessToken, refreshToken, data });
      }
    },
  },
};

export default SIGN_IN_TUCAR;
