import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, InputAdornment, Link, IconButton, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InputMask from 'react-input-mask';

import ComplexityBar from '../ComplexityBar';
import { availableSignUp, validateEmail } from '../../../helpers/auxFunctions';
import SubmitButton from '../../SubmitButton';

export default function ContactForm({ executeMutation, error, setError }) {
  const [errorPhone] = useState(false);
  const [viewPass, setViewPass] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [values, setValues] = useState({
    firstname: '',
    lastname: '',
    country: 'Chile',
    code: '+56',
    phone: '',
    email: '',
    password: '',
    confirmPassword: '',
    userType: 'driver',
    source: 'Facebook',
    client: 'person',
  });

  const handleChange = (name, event) => {
    if (name === 'region') {
      setValues((currentValues) => ({
        ...currentValues,
        city: '',
        commune: '',
        [name]: event,
      }));
    } else if (name === 'city') {
      setValues((currentValues) => ({
        ...currentValues,
        commune: '',
        [name]: event,
      }));
    } else {
      setValues((valores) => ({
        ...valores,
        [name]: event,
      }));
    }
  };

  const getPasswordError = () => {
    if (values.confirmPassword.length > 0) {
      return !(values.confirmPassword === values.password);
    }
    return false;
  };

  const handleClick = () => {
    if (availableSignUp(values, errorPhone)) {
      setLoadingButton(true);
      setError(false);
      executeMutation(values);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={(event) => event.preventDefault()}
      sx={{
        marginTop: '10px',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: '15px',
      }}
    >
      <TextField
        fullWidth
        size="small"
        sx={{ margin: '0px' }}
        required
        type="text"
        variant="outlined"
        label="Nombre"
        name="firstname"
        onChange={(e) => handleChange('firstname', e.target.value)}
        inputProps={{
          style: {
            fontFamily: 'Poppins-Regular',
          },
        }}
        InputLabelProps={{
          style: {
            fontFamily: 'Poppins-Regular',
            fontSize: '14px',
          },
        }}
      />
      <TextField
        fullWidth
        size="small"
        sx={{ margin: '0px' }}
        required
        type="text"
        variant="outlined"
        label="Apellido"
        name="lastname"
        onChange={(e) => handleChange('lastname', e.target.value)}
        inputProps={{
          style: {
            fontFamily: 'Poppins-Regular',
          },
        }}
        InputLabelProps={{
          style: {
            fontFamily: 'Poppins-Regular',
            fontSize: '14px',
          },
        }}
      />

      <InputMask
        mask="+56 9 9999 9999"
        maskChar={null}
        value={values.phone}
        onChange={(e) => {
          if (e.target.validity.valid || e.target.value === '') {
            handleChange('phone', e.target.value.replace('+56', '').replace(/ +/g, ''));
          }
        }}
      >
        {() => (
          <TextField
            required
            size="small"
            sx={{ margin: '0px' }}
            fullWidth
            type="text"
            variant="outlined"
            label="Teléfono"
            error={values.phone.length < 9 && values.phone !== ''}
            helperText={
              values.phone.length < 9 && values.phone !== ''
                ? 'El número ingresado no es válido'
                : ''
            }
            inputProps={{ style: { fontFamily: 'Poppins-Regular' } }}
            InputLabelProps={{
              style: { fontFamily: 'Poppins-Regular', paddingLeft: '5px', fontSize: '14px' },
            }}
          />
        )}
      </InputMask>

      <TextField
        fullWidth
        size="small"
        sx={{ margin: '0px' }}
        required
        type="text"
        variant="outlined"
        label="Correo"
        defaultValue={values.email}
        error={values.email ? !validateEmail(values.email) : false}
        helperText={
          values.email && !validateEmail(values.email) ? 'El correo ingresado no es válido' : ''
        }
        name="email"
        onChange={(e) => handleChange('email', e.target.value.toLowerCase())}
        inputProps={{
          style: {
            fontFamily: 'Poppins-Regular',
          },
        }}
        InputLabelProps={{
          style: {
            fontFamily: 'Poppins-Regular',
            fontSize: '14px',
          },
        }}
      />

      <TextField
        size="small"
        fullWidth
        sx={{ margin: '0px' }}
        required
        type={viewPass ? 'text' : 'password'}
        variant="outlined"
        id="Contraseña"
        label="Contraseña"
        autoComplete="false"
        name="password"
        onChange={(e) => handleChange('password', e.target.value)}
        InputLabelProps={{
          style: {
            fontFamily: 'Poppins-Regular',
            fontSize: '14px',
          },
        }}
        InputProps={{
          style: {
            fontFamily: 'Poppins-Regular',
            fontSize: '14px',
          },
          endAdornment: (
            <InputAdornment position="end">
              {viewPass ? (
                <IconButton onClick={() => setViewPass(false)}>
                  <VisibilityOffIcon />
                </IconButton>
              ) : (
                <IconButton onClick={() => setViewPass(true)}>
                  <VisibilityIcon />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />

      {values.password ? <ComplexityBar password={values.password} /> : null}

      <TextField
        size="small"
        fullWidth
        sx={{ margin: '0px' }}
        required
        type="password"
        variant="outlined"
        autoComplete="false"
        id="ConfirmarContraseña"
        label="Confirmar contraseña"
        error={getPasswordError()}
        helperText={getPasswordError() ? 'Las contraseñas no coinciden. ¡Inténtalo de nuevo!' : ''}
        name="confirmPassword"
        onChange={(e) => handleChange('confirmPassword', e.target.value)}
        inputProps={{
          style: {
            fontFamily: 'Poppins-Regular',
          },
        }}
        InputLabelProps={{
          style: {
            fontFamily: 'Poppins-Regular',
            fontSize: '14px',
          },
        }}
      />

      <Box sx={{ marginTop: '8px' }}>
        <Typography
          variant="text"
          sx={{ fontSize: '14px', lineHeight: 1, fontFamily: 'Poppins-Light' }}
          fontFamily="Poppins-Light"
        >
          Al seleccionar aceptar y continuar, aceptas los
          <Link
            href="https://tucar.com/terminos-condiciones"
            sx={{
              marginInline: '5px',
              lineHeight: 1,
              fontSize: '14px',
              fontFamily: 'Poppins-Light',
              textDecoration: 'underline',
              textDecorationColor: '#333333',
              color: '#333333',
            }}
          >
            Términos de Servicio
          </Link>
          y
          <Link
            href="https://tucar.com/politica-de-privacidad"
            sx={{
              marginInline: '5px',
              lineHeight: 1,
              fontSize: '14px',
              fontFamily: 'Poppins-Light',
              textDecoration: 'underline',
              textDecorationColor: '#333333',
              color: '#333333',
            }}
          >
            Políticas de Privacidad
          </Link>
          de Tucar.
        </Typography>
      </Box>

      <SubmitButton
        disabled={
          error
            ? !error || !availableSignUp(values, errorPhone)
            : loadingButton || !availableSignUp(values, errorPhone)
        }
        text="Aceptar y continuar"
        handler={handleClick}
      />
    </Box>
  );
}

ContactForm.propTypes = {
  executeMutation: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
};
