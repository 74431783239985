import { Box } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import BoxParagraph from './BoxParagraph';
import MixParagraph from './MixParagraph';

const BulletedList = ({ data, ident }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingLeft: '10px' }}>
      {data.map((elem, index) => {
        const identifier = `${ident}-${index}`;
        const contentObj = elem.children[0].children[0].children;
        return (
          <Box key={identifier} sx={{ display: 'flex', gap: '10px' }}>
            <FiberManualRecordIcon sx={{ fontSize: '8px', marginTop: '6px' }} />
            {contentObj.length === 1 ? (
              <BoxParagraph data={contentObj[0]} />
            ) : (
              <MixParagraph ident={identifier} data={contentObj} />
            )}
          </Box>
        );
      })}
    </Box>
  );
};

BulletedList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  ident: PropTypes.string,
};

BulletedList.defaultProps = {
  data: [],
  ident: '',
};

export default BulletedList;
