import { gql } from '@apollo/client';

const CREATE_SHIFT_PETITION = {
  mutation: gql`
    mutation CreateShiftChangePetition(
      $petitioner: String!
      $participants: [ShiftChangeParticipantInput]!
    ) {
      createShiftChangePetition(petitioner: $petitioner, participants: $participants) {
        _id
      }
    }
  `,
  policies: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

export default CREATE_SHIFT_PETITION;
