import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { useOutletContext } from 'react-router-dom';

import { getAuthStore } from '../../../graphql/store';
import TopBar from '../../../components/ui/TopNav';
import GET_EVENTS from '../../../graphql/querys/events/getEvents';
import InfinityView from '../../../components/ui/infinityListView';
import { convertContentEvent } from './auxFunctions';

const Events = () => {
  const [handlePageChange] = useOutletContext();

  useEffect(() => {
    handlePageChange(<TopBar title="Eventos" />, true);
  }, []);

  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);

  return (
    <Box sx={{ height: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '0px 15px 15px 15px',
          gap: '2px',
          height: '100%',
        }}
      >
        <InfinityView
          queryObject={GET_EVENTS}
          auxVars={{ userId: uid }}
          primaryKey="getEventsByUser"
          formatContent={convertContentEvent}
          noContentText="No tienes eventos registrados"
        />
      </Box>
    </Box>
  );
};

export default Events;
