import React from 'react';
import PropTypes from 'prop-types';

import { Box, Typography, TextField, Divider, Rating, styled } from '@mui/material';

import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import SubmitButton from '../../../../../components/SubmitButton';

const customIcons = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon sx={{ fontSize: '2rem' }} color="error" />,
    label: 'Muy en desacuerdo',
  },
  2: {
    icon: <SentimentDissatisfiedIcon sx={{ fontSize: '2rem' }} color="error" />,
    label: 'Desacuerdo',
  },
  3: {
    icon: <SentimentSatisfiedIcon sx={{ fontSize: '2rem' }} color="warning" />,
    label: 'Neutral',
  },
  4: {
    icon: <SentimentSatisfiedAltIcon sx={{ fontSize: '2rem' }} color="success" />,
    label: 'Satisfecho',
  },
  5: {
    icon: <SentimentVerySatisfiedIcon sx={{ fontSize: '2rem' }} color="success" />,
    label: 'Muy satisfecho',
  },
};

function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },
}));

const EvaluationForm = () => {
  return (
    <Box
      sx={{
        height: '100%',
        minHeight: '100%',
        width: '100%',
        display: 'grid',
        gridTemplateRows: 'max-content 1fr',
        gridTemplateColumns: '100%',
        gap: '10px',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <Typography
          sx={{
            fontSize: '1rem',
            textAlign: 'center',
            fontFamily: 'Poppins-Medium',
            paddingY: '10px',
          }}
        >
          Evaluación del taller
        </Typography>
        <Divider sx={{ color: '#ccc' }} />

        <Typography sx={{ fontSize: '14px', textAlign: 'left', fontFamily: 'Poppins-Regular' }}>
          ¿Cómo te atendió el personal?*
        </Typography>

        <StyledRating
          name="service"
          defaultValue={2}
          IconContainerComponent={IconContainer}
          getLabelText={(value) => customIcons[value].label}
          highlightSelectedOnly
          onChange={(e, newValue) => {
            // eslint-disable-next-line no-console
            console.log(e.target.name, newValue);
          }}
        />

        <Typography sx={{ fontSize: '14px', textAlign: 'left', fontFamily: 'Poppins-Regular' }}>
          ¿Qué te pareció el tiempo de espera?*
        </Typography>

        <StyledRating
          name="time"
          defaultValue={2}
          IconContainerComponent={IconContainer}
          getLabelText={(value) => customIcons[value].label}
          highlightSelectedOnly
          onChange={(e, newValue) => {
            // eslint-disable-next-line no-console
            console.log(e.target.name, newValue);
          }}
        />

        <Typography sx={{ fontSize: '14px', textAlign: 'left', fontFamily: 'Poppins-Regular' }}>
          En general, ¿Cómo evaluarías tu visita?*
        </Typography>

        <StyledRating
          name="visit"
          defaultValue={2}
          IconContainerComponent={IconContainer}
          getLabelText={(value) => customIcons[value].label}
          highlightSelectedOnly
          onChange={(e, newValue) => {
            // eslint-disable-next-line no-console
            console.log(e.target.name, newValue);
          }}
        />

        <Typography sx={{ fontSize: '14px', textAlign: 'left', fontFamily: 'Poppins-Regular' }}>
          ¿Tuviste algún problema?
        </Typography>
      </Box>
      <TextField
        variant="filled"
        sx={{
          height: '100%',
          '& .MuiFilledInput-root': {
            height: '100%',
            display: 'flex',
            alignItems: 'start',
          },
        }}
        label="Déjanos tus observaciones aquí"
        multiline
        inputProps={{
          style: {
            fontFamily: 'Poppins-Regular',
          },
        }}
        InputLabelProps={{
          style: {
            fontFamily: 'Poppins-Regular',
            fontSize: '14px',
          },
        }}
      />

      <SubmitButton disabled text="Enviar evaluación" />
    </Box>
  );
};

export default EvaluationForm;
