import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { Button, Card, Typography } from '@tucar/pit-stop';
import { getDriverStore } from '../../../../../graphql/store';
import { azulTucar } from '../../../../../themes/variants/TucarColors';
import { formatterCLP } from '../../../../../helpers/formatters';
import IconInfo from '../../../../../components/ui/IconInfoProfitCard';

const ProfitsCard = ({ data }) => {
  const { status } = useReactiveVar(getDriverStore);
  const disabled = status.substatus === 'new rent queue';
  const navigate = useNavigate();
  return (
    <Card style={{ gap: '20px', marginTop: '22px', padding: '22px', alignItems: 'unset' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography color="#5B5D71" style={{ fontSize: '16px', fontWeight: 500 }}>
          Ganancias totales
        </Typography>
        <Typography color={azulTucar} style={{ fontSize: '36px', fontWeight: 600 }}>
          {formatterCLP.format(data.totalGaining || 0)}
        </Typography>
      </Box>
      <Box sx={{ width: '100%', display: 'flex', gap: '5px', justifyContent: 'space-evenly' }}>
        <IconInfo
          title="Efectivo"
          iconName="moneyOutlined"
          color="#95D31C"
          amount={data.totalCash || 0}
        />
        <IconInfo
          title="Recaudaciones"
          iconName="electricCarFilled"
          color="#FABB03"
          amount={data.totalCollections || 0}
        />
        <IconInfo
          title="Descuentos"
          iconName="electricCarFilled"
          color="#E81F00"
          amount={data.totalExpenses || 0}
        />
      </Box>
      <Button
        disabled={disabled}
        onClick={() => navigate('/app/v2/balance', { replace: true })}
        style={{ width: 'max-content', alignSelf: 'end' }}
      >
        <Typography
          color={(!disabled && azulTucar) || null}
          style={{ fontSize: '14px', fontWeight: 600 }}
        >
          Ver detalle
        </Typography>
      </Button>
    </Card>
  );
};

ProfitsCard.propTypes = {
  data: PropTypes.shape({
    totalGaining: PropTypes.number,
    totalCash: PropTypes.number,
    totalCollections: PropTypes.number,
    totalExpenses: PropTypes.number,
  }),
};

ProfitsCard.defaultProps = {
  data: {
    totalGaining: 0,
    totalCash: 0,
    totalCollections: 0,
    totalExpenses: 0,
  },
};

export default ProfitsCard;
