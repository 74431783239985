import React from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress } from '@mui/material';
import { azulTucar } from '../../../themes/variants/TucarColors';

const LoadingProgress = ({ allScreen, heightContainer }) => {
  return (
    <Box
      sx={
        allScreen
          ? {
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }
          : {
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: heightContainer,
            }
      }
    >
      <CircularProgress sx={{ color: azulTucar }} />
    </Box>
  );
};

LoadingProgress.propTypes = {
  allScreen: PropTypes.bool,
  heightContainer: PropTypes.string,
};

LoadingProgress.defaultProps = {
  allScreen: true,
  heightContainer: 'max-content',
};

export default LoadingProgress;
