import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import TopBar from '../../../components/ui/TopNav';
import Tabs from '../../../components/ui/Tabs';
import { azulTucar } from '../../../themes/variants/TucarColors';
import TicketList from './ticketList';

const Tickets = () => {
  const navigate = useNavigate();
  const tabs = [
    { value: 'openTickets', label: 'Activos' },
    { value: 'closedTickets', label: 'Cerrados' },
  ];
  const [currentTab, setCurrentTab] = useState('openTickets');
  const tabComponent = {
    openTickets: <TicketList key="open" status={['Pending', 'Revision']} type="abiertos" />,
    closedTickets: <TicketList key="closed" status={['Rejected', 'Approved']} type="cerrados" />,
  };

  return (
    <Box>
      <TopBar title="Tickets" />
      <Box>
        <Tabs currentTabName={currentTab} setCurrentTabFunction={setCurrentTab} tabs={tabs} />
        <Box sx={{ maxHeight: '70vh', overflow: 'scroll' }}>
          <Box>{tabComponent[currentTab]}</Box>
        </Box>
      </Box>
      <Fab
        onClick={() => navigate('/app/tickets/create', { replace: true })}
        disableFocusRipple
        disableRipple
        size="large"
        color={azulTucar}
        sx={{
          backgroundColor: azulTucar,
          position: 'absolute',
          bottom: '80px',
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: '9',
        }}
      >
        <AddIcon sx={{ color: '#FFF' }} />
      </Fab>
    </Box>
  );
};

export default Tickets;
