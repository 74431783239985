/* eslint-disable no-console */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { getDriverStore, getAuthStore, getLeadStore } from '../../graphql/store';
import './style.css';

const IntercomApp = ({ show }) => {
  const { data } = useReactiveVar(getAuthStore);
  const { info } = useReactiveVar(getDriverStore);
  const { crmIdPrefix } = useReactiveVar(getLeadStore);

  window.intercomSettings = {
    user_id: `${crmIdPrefix}${data.uid}`,
    api_base: process.env.API_BASE_INTERCOM,
    app_id: process.env.APP_ID_INTERCOM,
    name: `${info.firstname} ${info.lastname}`,
    email: info.email,
    custom_launcher_selector: '#my_custom_link',
    hide_default_launcher: show,
  };

  window.Intercom('update', window.intercomSettings);

  const intercomShow = useCallback(() => {
    window.Intercom('boot', {
      app_id: process.env.APP_ID_INTERCOM,
    });
    // window.Intercom('hide');
  }, [window.Intercom]);

  intercomShow();

  return <Outlet />;
};

IntercomApp.propTypes = {
  show: PropTypes.bool,
};

IntercomApp.defaultProps = {
  show: false,
};

export default IntercomApp;
