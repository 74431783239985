import { gql } from '@apollo/client';
import { setPhoto } from '../../store/driver';

const GET_DRIVER_PHOTO = {
  query: gql`
    query getDriver($uid: ID!) {
      getDriver(uid: $uid) {
        photo
      }
    }
  `,
  policies: {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      if (response.getDriver) {
        const { photo } = response.getDriver;
        setPhoto(photo);
      }
    },
    errorPolicy: 'all',
  },
};

export default GET_DRIVER_PHOTO;
