function formatGetCouplesData(auxData, today, uid) {
  const driverList = auxData.drivers;

  driverList.sort((a, b) => {
    const auxA = a.plan.selectedShifts[today].startTime;
    const auxB = b.plan.selectedShifts[today].startTime;
    if (auxA < auxB) {
      return -1;
    }
    if (auxA > auxB) {
      return 1;
    }
    return 0;
  });

  const preFormatDrivers = driverList.map((elem, index) => {
    const shift = index;
    const last = !!(index !== 0 && index === driverList.length - 1);

    const todayShift = elem.plan.selectedShifts[today];
    return { ...elem, todayShift, shift, last };
  });

  // eslint-disable-next-line no-underscore-dangle
  const meInfo = preFormatDrivers.filter((elem) => elem._id === uid)[0];
  if (meInfo.shift === 0) {
    return {
      meShift: meInfo?.todayShift,
      prevDriver: null,
      nextDriver: preFormatDrivers.filter((elem) => elem.shift === meInfo.shift + 1)[0],
      direction:
        meInfo.status.status === 'rent' &&
        meInfo.todayShift?.startTime !== '-' &&
        driverList.length > 1
          ? auxData.exchangeAddress
          : auxData.parkingAddress,
      carInfo: auxData.car.info,
      shift: meInfo.shift,
      last: meInfo.last,
      status: meInfo.status.status,
    };
  }
  if (meInfo.shift > 0 && meInfo.last) {
    return {
      meShift: meInfo.todayShift,
      prevDriver: preFormatDrivers.filter((elem) => elem.shift === meInfo.shift - 1)[0],
      nextDriver: null,
      direction:
        meInfo.status.status === 'rent' && meInfo.todayShift.startTime !== '-'
          ? auxData.parkingAddress
          : auxData.exchangeAddress,
      carInfo: auxData.car.info,
      shift: meInfo.shift,
      last: meInfo.last,
      status: meInfo.status.status,
    };
  }
  return {
    meShift: meInfo.todayShift,
    prevDriver: preFormatDrivers.filter((elem) => elem.shift === meInfo.shift - 1)[0],
    nextDriver: preFormatDrivers.filter((elem) => elem.shift === meInfo.shift + 1)[0],
    direction: auxData.exchangeAddress,
    carInfo: null,
    shift: meInfo.shift,
    last: meInfo.last,
    status: meInfo.status.status,
  };
}

export default formatGetCouplesData;
