import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useOutletContext } from 'react-router-dom';
import TopBar from '../../../../components/ui/TopNav';

const DeductibleView = () => {
  const [handlePageChange] = useOutletContext();

  useEffect(() => {
    handlePageChange(<TopBar title="Deducibles" />, true);
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '20px',
        gap: '20px',
      }}
    >
      <Typography sx={{ fontFamily: 'Poppins-Regular', fontSize: '16px' }}>
        Los autos cuentan con 2 tipos de deducibles en caso de siniestros:
      </Typography>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '10px 1fr',
          gridTemplateRows: 'max-content',
          width: '100%',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: '#70c8db',
            height: '100%',
            borderRadius: '7px 0px 0px 7px',
          }}
        >
          <Box
            sx={{
              visibility: 'hidden',
              borderRadius: '0px 7px 7px 0px',
              backgroundColor: '#f0f9fc',
              flexDirection: 'column',
              padding: '15px',
              gap: '10px',
              minHeight: '100%',
            }}
          />
        </Box>
        <Box
          sx={{
            borderRadius: '0px 7px 7px 0px',
            backgroundColor: '#f0f9fc',
            display: 'flex',
            flexDirection: 'column',
            padding: '15px',
            gap: '10px',
          }}
        >
          <Typography sx={{ fontFamily: 'Poppins-SemiBold', fontSize: '16px' }}>
            Deducible general
          </Typography>
          <Box sx={{ display: 'inline-block' }}>
            <Typography sx={{ display: 'inline', fontFamily: 'Poppins-Medium', fontSize: '14px' }}>
              Cubre ante cualquier daño parcial ocasionado al vehículo
            </Typography>
            <Typography sx={{ display: 'inline', fontFamily: 'Poppins-Light', fontSize: '14px' }}>
              , sin importar su naturaleza. Tiene un valor de 10 UF, pero si el costo de reparación
              fuese menor, lo evaluaremos en nuestros talleres y, de ser así, te reembolsaremos la
              diferencia.
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '10px 1fr',
          gridTemplateRows: 'max-content',
          width: '100%',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: '#70c8db',
            height: '100%',
            borderRadius: '7px 0px 0px 7px',
          }}
        >
          <Box
            sx={{
              visibility: 'hidden',
              borderRadius: '0px 7px 7px 0px',
              backgroundColor: '#f0f9fc',
              flexDirection: 'column',
              padding: '15px',
              gap: '10px',
              minHeight: '100%',
            }}
          />
        </Box>
        <Box
          sx={{
            borderRadius: '0px 7px 7px 0px',
            backgroundColor: '#f0f9fc',
            display: 'flex',
            flexDirection: 'column',
            padding: '15px',
            gap: '10px',
          }}
        >
          <Typography sx={{ fontFamily: 'Poppins-SemiBold', fontSize: '16px' }}>
            Deducible pérdida total
          </Typography>
          <Box sx={{ display: 'inline-block' }}>
            <Typography sx={{ display: 'inline', fontFamily: 'Poppins-Light', fontSize: '14px' }}>
              Si tienes un accidente que cause la pérdida total del vehículo,
            </Typography>
            <Typography sx={{ display: 'inline', fontFamily: 'Poppins-Medium', fontSize: '14px' }}>
              {' '}
              solo pagarás el valor del deducible
            </Typography>
            <Typography sx={{ display: 'inline', fontFamily: 'Poppins-Light', fontSize: '14px' }}>
              , el cual tiene un valor de 30 UF. Contamos con facilidades de pago
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default DeductibleView;
