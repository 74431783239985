import React from 'react';
import PropTypes from 'prop-types';
import { Button, Stack, Box } from '@mui/material';
import uberLogo from '../../../../public/images/Uber_blanco.svg';
import { uberColor, blancoTucar } from '../../../themes/variants/TucarColors';

const UberButton = ({ disabled, content, onClick, href, ...props }) => {
  return (
    <Button
      disabled={disabled}
      href={href}
      sx={{
        ...props,
        color: blancoTucar,
        backgroundColor: uberColor,
        fontFamily: 'Poppins-Medium',
        fontSize: '14px',
        borderRadius: '27px',
        height: '40px',
        width: '100%',
        lineHeight: 1,
        textAlign: 'center',
        '&:hover': {
          backgroundColor: '#000000B3',
        },
        '&:disabled': {
          opacity: 0.7,
          color: '#FFF',
        },
      }}
      onClick={onClick}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-evenly"
        sx={{
          width: '100%',
          paddingLeft: '10px',
        }}
      >
        <img src={uberLogo} alt="uberLogo" />
        <Box paddingTop="4px" sx={{ color: '#FFF' }}>
          {content}
        </Box>
      </Stack>
    </Button>
  );
};

UberButton.propTypes = {
  disabled: PropTypes.bool,
  content: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  href: PropTypes.string,
};

UberButton.defaultProps = {
  disabled: false,
  onClick: () => {},
  href: '',
};

export default UberButton;
