const banks = [
  {
    name: 'Banco Santander',
    types: [
      { name: 'Cuenta Corriente', id: 0 },
      { name: 'Cuenta Vista', id: 1 },
      { name: 'Cuenta de Ahorro', id: 2 },
    ],
  },
  {
    name: 'Banco BICE',
    types: [
      { name: 'Cuenta Corriente', id: 0 },
      { name: 'Cuenta Vista', id: 1 },
      { name: 'Cuenta de Ahorro', id: 2 },
    ],
  },
  {
    name: 'Banco International',
    types: [
      { name: 'Cuenta Corriente', id: 0 },
      { name: 'Cuenta Vista', id: 1 },
      { name: 'Cuenta de Ahorro', id: 2 },
    ],
  },
  {
    name: 'Banco Itaú',
    types: [
      { name: 'Cuenta Corriente', id: 0 },
      { name: 'Cuenta Vista', id: 1 },
      { name: 'Cuenta de Ahorro', id: 2 },
    ],
  },
  {
    name: 'Banco de Chile / Edwards-Citi',
    types: [
      { name: 'Cuenta Corriente', id: 0 },
      { name: 'Cuenta Vista', id: 1 },
      { name: 'Cuenta de Ahorro', id: 2 },
    ],
  },
  {
    name: 'Corpbanca',
    types: [
      { name: 'Cuenta Corriente', id: 0 },
      { name: 'Cuenta Vista', id: 1 },
      { name: 'Cuenta de Ahorro', id: 2 },
    ],
  },
  {
    name: 'Banco Crédito e Inversiones',
    types: [
      { name: 'Cuenta Corriente', id: 0 },
      { name: 'Cuenta Vista', id: 1 },
      { name: 'Cuenta de Ahorro', id: 2 },
    ],
  },
  {
    name: 'Banco Estado',
    types: [
      { name: 'Cuenta Corriente', id: 0 },
      { name: 'Cuenta Vista', id: 1 },
      { name: 'Cuenta de Ahorro', id: 2 },
      { name: 'Cuenta Rut', id: 3 },
    ],
  },
  {
    name: 'Banco Falabella',
    types: [
      { name: 'Cuenta Corriente', id: 0 },
      { name: 'Cuenta Vista', id: 1 },
      { name: 'Cuenta de Ahorro', id: 2 },
    ],
  },
  // {
  //   name: 'Banco Security',
  //   types: [
  //     { name: 'Cuenta Corriente', id: 0 },
  //     { name: 'Cuenta Vista', id: 1 },
  //     { name: 'Cuenta de Ahorro', id: 2 },
  //     { name: 'Chequera electrónica', id: 3 },
  //   ],
  // },
  {
    name: 'Scotiabank',
    types: [
      { name: 'Cuenta Corriente', id: 0 },
      { name: 'Cuenta Vista', id: 1 },
      { name: 'Cuenta de Ahorro', id: 2 },
    ],
  },
  {
    name: 'HSBC Bank',
    types: [
      { name: 'Cuenta Corriente', id: 0 },
      { name: 'Cuenta Vista', id: 1 },
      { name: 'Cuenta de Ahorro', id: 2 },
    ],
  },
  {
    name: 'Banco Ripley',
    types: [
      { name: 'Cuenta Corriente', id: 0 },
      { name: 'Cuenta Vista', id: 1 },
      { name: 'Cuenta de Ahorro', id: 2 },
    ],
  },
  {
    name: 'Banco París',
    types: [
      { name: 'Cuenta Corriente', id: 0 },
      { name: 'Cuenta Vista', id: 1 },
      { name: 'Cuenta de Ahorro', id: 2 },
    ],
  },
  {
    name: 'Banco Consorcio',
    types: [
      { name: 'Cuenta Corriente', id: 0 },
      { name: 'Cuenta Vista', id: 1 },
      { name: 'Cuenta de Ahorro', id: 2 },
    ],
  },
  {
    name: 'Coopeuch',
    types: [
      { name: 'Cuenta Corriente', id: 0 },
      { name: 'Cuenta Vista', id: 1 },
      { name: 'Cuenta de Ahorro', id: 2 },
    ],
  },
];

export default banks;
