/* eslint-disable import/prefer-default-export */
import { makeVar } from '@apollo/client';

export const driverStore = makeVar({
  labelV2: false,
  photo: '',
  rent: {
    carId: '',
  },
  info: {
    address: '',
    adrapt: '',
    adrnumber: '',
    birth: '',
    city: '',
    code: '',
    commune: '',
    country: '',
    email: '',
    firstname: '',
    lastname: '',
    phone: '',
    region: '',
    rut: '',
    gender: '',
    parking: '',
    secondPhone: '',
    emergencyPhone: '',
    situation: false,
    source: '',
  },
  status: {
    banned: false,
    status: false,
    substatus: false,
    deleted: false,
    enabled: false,
    verified: false,
  },
  verifiedList: false,
  category: {
    uberPro: false,
    discount: false,
  },
});
