import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Collapse,
  Typography,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Divider,
  ClickAwayListener,
} from '@mui/material';
import moment from 'moment';
import CircleIcon from '@mui/icons-material/Circle';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LoopIcon from '@mui/icons-material/Loop';
import { azulTucar } from '../../../../../themes/variants/TucarColors';
import LoadingProgress from '../../../../../components/ui/LoadingProgress';
import translateDocsName from '../../../../../helpers/translateDocsName';

function translateDocState(state) {
  if (state === 'Pending') {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
        <CircleIcon sx={{ fontSize: '12px', color: '#FFDD00', paddingBottom: '5px' }} />
        <Typography fontSize="12px" fontFamily="Poppins-Medium">
          En revisión
        </Typography>
      </Box>
    );
  }
  if (state === 'Expired') {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
        <CircleIcon sx={{ fontSize: '12px', color: '#D80000', paddingBottom: '5px' }} />
        <Typography fontSize="12px" fontFamily="Poppins-Medium">
          Vencido
        </Typography>
      </Box>
    );
  }
  if (state === 'Approved') {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
        <CircleIcon sx={{ fontSize: '12px', color: '#C8D300', paddingBottom: '5px' }} />
        <Typography fontSize="12px" fontFamily="Poppins-Medium">
          Aprobado
        </Typography>
      </Box>
    );
  }
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
      <CircleIcon sx={{ fontSize: '12px', color: '#D80000', paddingBottom: '5px' }} />
      <Typography fontSize="12px" fontFamily="Poppins-Medium">
        Rechazado
      </Typography>
    </Box>
  );
}

const DocBox = ({ fileObj, handleUpload }) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (open) {
          setOpen(false);
        }
      }}
    >
      <Box
        sx={{
          borderRadius: '15px',
          backgroundColor: open ? '#FFF' : '#F3F3F3',
          transition: 'background-color 0.8s',
          boxShadow:
            '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        }}
      >
        <Stack
          onClick={handleClick}
          paddingLeft="15px"
          paddingRight="4px"
          borderRadius={open ? '15px 15px 0px 0px' : '15px'}
          direction="row"
          alignItems="center"
          height="45px"
          justifyContent="space-between"
        >
          <Typography
            sx={{
              color: '#333333',
              fontSize: '14px',
              fontFamily: 'Poppins-Regular',
              lineHeight: 1,
            }}
          >
            {translateDocsName(fileObj.name)}
          </Typography>
          {open ? (
            <IconButton onClick={handleClick}>
              <KeyboardArrowUpOutlinedIcon fontSize="small" sx={{ color: '#333333' }} />
            </IconButton>
          ) : (
            <IconButton onClick={handleClick}>
              <KeyboardArrowDownOutlinedIcon fontSize="small" sx={{ color: '#333333' }} />
            </IconButton>
          )}
        </Stack>

        <Collapse
          sx={{
            borderRadius: open ? '0px 0px 7px 7px' : '7px',
          }}
          in={open}
          timeout="auto"
          unmountOnExit
        >
          <Divider />
          {fileObj.loadingRefetch ? (
            <Box margin="20px 20px 20px 20px">
              <LoadingProgress allScreen={false} marginTop="0px" />
            </Box>
          ) : (
            <Box margin="10px 20px 10px 20px" sx={{ overflowX: 'scroll' }}>
              <Table size="small">
                <TableBody>
                  <TableRow colSpan={2}>
                    <TableCell
                      size="small"
                      sx={{
                        borderColor: 'white',
                        fontFamily: 'Poppins-Light',
                        fontSize: '12px',
                        lineHeight: 1,
                      }}
                      align="left"
                    >
                      Estado del documento
                    </TableCell>
                    <TableCell
                      size="small"
                      sx={{
                        borderColor: 'white',
                        fontFamily: 'Poppins-Light',
                        fontSize: '12px',
                        lineHeight: 1,
                      }}
                      align="right"
                    >
                      {translateDocState(fileObj.status)}
                    </TableCell>
                  </TableRow>
                  <TableRow colSpan={2}>
                    <TableCell
                      size="small"
                      sx={{
                        border: '0px',
                        fontFamily: 'Poppins-Light',
                        fontSize: '12px',
                        lineHeight: 1,
                      }}
                      align="left"
                    >
                      Fecha de vencimiento
                    </TableCell>
                    <TableCell
                      size="small"
                      sx={{
                        border: '0px',
                        fontFamily: 'Poppins-Light',
                        fontSize: '12px',
                        lineHeight: 1,
                      }}
                      align="right"
                    >
                      {fileObj.expire ? moment(fileObj.expire).format('DD/MM/YY') : '-'}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Box display="flex" justifyContent="center" alignItems="center">
                {fileObj.status === 'Approved' || fileObj?.carDocs ? null : (
                  <IconButton
                    size="small"
                    component="label"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      color: '#000',
                      fontFamily: 'Poppins-Light',
                      fontSize: '12px',
                    }}
                  >
                    <input
                      hidden
                      accept={fileObj.fileType}
                      onChange={(e) => {
                        handleUpload(e.target.files[0], fileObj.name, fileObj.id);
                      }}
                      type="file"
                    />
                    <LoopIcon size="small" sx={{ color: azulTucar, marginRight: '5px' }} />
                  </IconButton>
                )}
                <IconButton
                  size="small"
                  type="submit"
                  target="_blank"
                  href={fileObj.url}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: '#000',
                    fontFamily: 'Poppins-Light',
                    fontSize: '12px',
                  }}
                >
                  <VisibilityIcon size="small" sx={{ color: azulTucar }} />
                </IconButton>
              </Box>
            </Box>
          )}
        </Collapse>
      </Box>
    </ClickAwayListener>
  );
};

DocBox.propTypes = {
  handleUpload: PropTypes.func,
  fileObj: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    expire: PropTypes.string,
    status: PropTypes.string,
    extension: PropTypes.string,
    url: PropTypes.string,
    loadingRefetch: PropTypes.bool,
    fileType: PropTypes.string,
    carDocs: PropTypes.bool,
  }).isRequired,
};

DocBox.defaultProps = {
  handleUpload: () => {},
};

export default DocBox;
