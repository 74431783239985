export const backgroundStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '40px',
  paddingInline: '40px',
  height: 'calc(100vh - 80px)',
};

export const msgResultStyle = {
  fontSize: '24px',
  fontWeight: 600,
  lineHeight: 1.2,
  paddingTop: '40px',
  paddingBottom: '60px',
  textAlign: 'center',
};
