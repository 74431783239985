import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Typography, Button } from '@tucar/pit-stop';
import CurrentShiftCard from '../components/currentShiftCard';
import { azulTucar } from '../../../../../themes/variants/TucarColors';

const CalendarStep = ({ onNext }) => {
  return (
    <Box>
      <Box
        sx={{
          position: 'absolute',
          top: '0px',
          left: '0px',
          width: '100vw',
          height: '100vh',
          background: 'linear-gradient(0deg, #EAFFFE 0%, rgba(205, 201, 241, 0.80) 100%)',
          backdropFilter: 'blur(2px)',
          paddingInline: '27px',
          zIndex: 2,
          display: 'flex',
          flexDirection: 'column-reverse',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
          <Typography color={azulTucar} style={{ fontSize: '14px', fontWeight: 500 }}>
            Paso 1/6
          </Typography>
          <Button
            size="small"
            onClick={() => {
              onNext();
              document.getElementById('section-1').scrollIntoView({ behavior: 'auto' });
            }}
          >
            Siguiente
          </Button>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '30px 20px',
            width: 'calc(100vw - 50px)',
            gap: '10px',
          }}
        >
          <Typography
            align="center"
            color={azulTucar}
            style={{ fontSize: '18px', fontWeight: 600 }}
          >
            Calendario semanal
          </Typography>
          <Typography
            color={azulTucar}
            align="center"
            style={{ fontSize: '14px', fontWeight: 400, lineHeight: '16px' }}
          >
            Bienvenido a nuestro tutorial. En esta sección podrás visualizar tu horario semanal y el
            de tu compañero de turno. ¡Organiza tus días con facilidad y anticipación!
          </Typography>
        </Box>

        <CurrentShiftCard />
      </Box>
    </Box>
  );
};

CalendarStep.propTypes = {
  onNext: PropTypes.func,
};

CalendarStep.defaultProps = {
  onNext: () => {},
};

export default CalendarStep;
