import React from 'react';
import PropTypes from 'prop-types';
import { Box, Modal, useMediaQuery } from '@mui/material';

const styleLaptop = {
  position: 'fixed',
  width: '50%',
  alignItems: 'rigth',
  height: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '15px',
  boxShadow: '0px 11px 22px max(100vh, 100vw) rgba(0, 0, 0, .4)',
  pt: 2,
  px: 5,
  pb: 3,
  overflowY: 'scroll',
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '90vw',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '15px',
  boxShadow: '0px 11px 22px max(100vh, 100vw) rgba(0, 0, 0, .4)',
  textAlign: 'right',
  pt: 2,
  px: 2,
  pb: 3,
  maxHeight: '90vh',
  overflowY: 'scroll',
};

const InfoModal = ({ open, content, bgColor = '#FFF' }) => {
  const match = useMediaQuery('(max-width:700px)');

  return (
    <Modal
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      hideBackdrop
      open={open}
    >
      <Box>
        <Box
          sx={match ? { ...style, background: bgColor } : { ...styleLaptop, background: bgColor }}
        >
          <Box
            sx={{
              height: '100%',
              marginTop: '10px',
              display: 'flex',
              flex: 1,
              overflow: 'scroll',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Box>{content}</Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

InfoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  content: PropTypes.node,
  bgColor: PropTypes.string,
};

InfoModal.defaultProps = {
  content: null,
  bgColor: '#FFF',
};

export default InfoModal;
