import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CarRepairOutlinedIcon from '@mui/icons-material/CarRepairOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

import { Box, Typography, Divider } from '@mui/material';
import { azulTucar } from '../../../../themes/variants/TucarColors';
import ModalMaintenance from './evaluation';
import ViewModal from '../../../../components/extras/ViewModal';

const InfoBox = ({ title, direction, time, date, variant, detailContent, garageInfo }) => {
  const [open, setOpen] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY,
  });
  const tucarOfficeLocation = {
    lat: parseFloat(garageInfo?.lat),
    lng: parseFloat(garageInfo?.lon),
  };

  const renderMap = isLoaded ? (
    <GoogleMap
      mapContainerStyle={{ width: '100%', height: '100%', borderRadius: '15px' }}
      center={tucarOfficeLocation}
      zoom={15}
      options={{ minZoom: 2 }}
    />
  ) : (
    <Box>Cargando...</Box>
  );

  const content = () => {
    switch (variant) {
      case 'done':
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
              gap: '10px',
              paddingTop: '10px',
            }}
          >
            {/* <TextButton
              text="Evaluar taller"
              onClick={() => {
                setOpen(!open);
              }}
            /> */}
            <VisibilityOutlinedIcon
              sx={{ fontSize: '35px', color: azulTucar }}
              onClick={() => {
                setOpenDetails(!openDetails);
              }}
            />
          </Box>
        );
      case 'map':
        return (
          <Box
            sx={{
              display: 'grid',
              gridTemplateRows: '1fr max-content',
              height: '100%',
              width: '100%',
              minHeight: '20rem',
              gap: '20px',
              paddingTop: '20px',
            }}
          >
            {renderMap}

            {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography sx={{ fontFamily: 'Poppins-Regular', fontSize: '0.875rem' }}>
                ¿No puedes ir?
              </Typography>
              <TextButton text="Reagendar" />
            </Box> */}
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        paddingInline: '20px',
      }}
    >
      <Box sx={{ display: 'grid', gridTemplateColumns: '35px 1fr max-content', gap: '5px' }}>
        <ModalMaintenance open={open} setOpen={setOpen} />
        <ViewModal open={openDetails} setOpen={setOpenDetails} content={detailContent} />

        <CarRepairOutlinedIcon sx={{ color: azulTucar, fontSize: '35px' }} />
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography sx={{ fontFamily: 'Poppins-Medium', fontSize: '14px', alignSelf: 'left' }}>
            {title}
          </Typography>
          <Typography
            sx={{
              color: '#545454',
              fontFamily: 'Poppins-Regular',
              fontSize: '12px',
              alignSelf: 'left',
            }}
          >
            {direction}
          </Typography>
        </Box>
        <Box sx={{ color: '#545454', display: 'flex', flexDirection: 'column' }}>
          <Typography sx={{ fontFamily: 'Poppins-Medium', fontSize: '14px', alignSelf: 'right' }}>
            {time} hrs
          </Typography>
          <Typography
            sx={{
              color: '#545454',
              fontFamily: 'Poppins-Regular',
              fontSize: '12px',
              alignSelf: 'right',
            }}
          >
            {date}
          </Typography>
        </Box>
      </Box>

      {content()}

      <Divider sx={{ color: '#CCCCCC', marginTop: '20px' }} />
    </Box>
  );
};

InfoBox.propTypes = {
  title: PropTypes.string,
  direction: PropTypes.string,
  time: PropTypes.string,
  date: PropTypes.string,
  variant: PropTypes.string,
  detailContent: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.object]),
  garageInfo: PropTypes.shape({
    lat: PropTypes.string,
    lon: PropTypes.string,
  }),
};

InfoBox.defaultProps = {
  title: 'Mantención 10.000km',
  direction: 'Av. Paul Díaz 3355, Peñalolén',
  time: '16:30',
  date: '18/02/23',
  variant: 'map',
  detailContent: <Box sx={{ textAlign: 'center' }}>No data</Box>,
  garageInfo: {
    lat: '-33.408255',
    lon: '-70.571484',
  },
};

export default InfoBox;
