import React, { useEffect, useState } from 'react';
import { useOutletContext, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import NavBarTutorial from '../components/navBarTutorial';
import BasePetitions from './BasePetitions';
import SelectionStep from './steps/selectionStep';
import ResumeStep from './steps/resumeStep';
import FinalStep from './steps/finalStep';

const TutorialPetitions = () => {
  const navigate = useNavigate();
  const [handlePageChange] = useOutletContext();
  const [currentStep, setCurrentStep] = useState('calendar');
  useEffect(() => {
    handlePageChange(false, false, 'tutorial');
    document.getElementById('section-0').scrollIntoView({ behavior: 'auto' });
  }, []);

  return (
    <Box sx={{ scrollSnapType: 'y mandatory', overflowY: 'none' }}>
      <Box
        id="section-0"
        sx={{ position: 'relative', width: '100vw', height: 'calc(100vh)', overflow: 'hidden' }}
      >
        <BasePetitions step={currentStep} onNext={() => setCurrentStep('partner')} />
        <NavBarTutorial />
      </Box>
      <Box
        id="section-1"
        sx={{ position: 'relative', width: '100vw', height: 'calc(100vh)', overflow: 'hidden' }}
      >
        <BasePetitions step={currentStep} onNext={() => setCurrentStep('creation')} />
        <NavBarTutorial />
      </Box>
      <Box
        id="section-2"
        sx={{ position: 'relative', width: '100vw', height: 'calc(100vh)', overflow: 'hidden' }}
      >
        <BasePetitions step={currentStep} onNext={() => setCurrentStep('selection')} />
        <NavBarTutorial />
      </Box>
      <Box
        id="section-3"
        sx={{ position: 'relative', width: '100vw', height: 'calc(100vh)', overflow: 'hidden' }}
      >
        <SelectionStep
          onNext={() => document.getElementById('section-4').scrollIntoView({ behavior: 'auto' })}
        />
      </Box>
      <Box
        id="section-4"
        sx={{ position: 'relative', width: '100vw', height: 'calc(100vh)', overflow: 'hidden' }}
      >
        <ResumeStep
          onNext={() => document.getElementById('section-5').scrollIntoView({ behavior: 'auto' })}
        />
      </Box>
      <Box
        id="section-5"
        sx={{ position: 'relative', width: '100vw', height: 'calc(100vh)', overflow: 'hidden' }}
      >
        <FinalStep onNext={() => navigate('/app/v2/petitions', { replace: true })} />
      </Box>
    </Box>
  );
};

export default TutorialPetitions;
