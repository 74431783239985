import React from 'react';
import { Typography, Box } from '@mui/material';
import InfoModal from '../../info/infoModal';
import SubmitButton from '../../SubmitButton';

const ConnectionError = () => {
  return (
    <InfoModal
      open
      content={
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <Typography sx={{ fontFamily: 'Poppins-Medium', textAlign: 'center', fontSize: '20px' }}>
            No tienes conexión a internet
          </Typography>
          <Typography sx={{ fontFamily: 'Poppins-Regular', textAlign: 'center', fontSize: '14px' }}>
            Lo sentimos, no se puede establecer una conexión a internet en este momento. Por favor
            verifica tu conexión y vuelve a intentarlo más tarde.
          </Typography>
          <SubmitButton
            text="Volver a intentar"
            handler={() => {
              window.location.reload();
            }}
          />
        </Box>
      }
    />
  );
};

export default ConnectionError;
