import { gql } from '@apollo/client';

const GET_CONFIG_PLANS = {
  query: gql`
    query GetConfigPlans($country: String!) {
      getConfigAccounting(country: $country) {
        uberDiscount {
          Blue
          Diamond
          Gold
          Platinum
        }
        guarantee {
          total
        }
        currency {
          current
        }
      }
      getConfigPlans {
        name
        _id
        exclusive
        includes
        excludes
        active
        electric
        sections {
          startKm
          endKm
          variable
        }
        shifts {
          available
          name
          startTime
          endTime
          basePrice
          _id
        }
      }
    }
  `,
};

export default GET_CONFIG_PLANS;
