import React from 'react';
import { Box, Typography } from '@mui/material';
import { azulTucar } from '../../../themes/variants/TucarColors';
import { upStepFunction, downStepFunction, focusBoxTutorial, DefaultContent } from '../helpers';

const AllSteps = {
  1: {
    nextFunction: upStepFunction,
    prevFunction: () => {},
    redirectPrev: '/app/tutorial/menu',
    styleElement: {
      dataSelect: {
        ...focusBoxTutorial,
        borderRadius: '1rem',
      },
    },
    title: 'Semanas',
    content: (
      <DefaultContent
        textBlack="Selecciona la fecha de la semana que quieras revisar. Solo podrás elegir entre las últimas
    3 semanas."
      />
    ),
  },
  2: {
    nextFunction: upStepFunction,
    prevFunction: downStepFunction,
    styleElement: {
      container: {
        ...focusBoxTutorial,
        borderRadius: '1rem',
      },
    },
    title: 'Resultado semanal',
    content: (
      <Box sx={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
        <Typography sx={{ fontFamily: 'Poppins-Regular', fontSize: '14px', textAlign: 'center' }}>
          El resultado semanal muestra tus recaudaciones en apps, menos los descuentos del plan de
          arriendo.
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Poppins-Regular',
            fontSize: '14px',
            textAlign: 'center',
            color: azulTucar,
          }}
        >
          No incluye deudas pendientes de semanas anteriores.{' '}
        </Typography>
      </Box>
    ),
  },
  3: {
    nextFunction: upStepFunction,
    prevFunction: downStepFunction,
    styleElement: {
      buttonContainer: {
        ...focusBoxTutorial,
        borderRadius: '1rem',
        padding: '10px',
      },
      button: {
        ...focusBoxTutorial,
        boxShadow: '0 0 0 max(100vh, 100vw) rgba(0, 0, 0, .4)',
        borderRadius: '1rem',
        padding: '10px',
      },
    },
    title: 'Detalles resultado semanal',
    content: (
      <DefaultContent textBlack="Para ver el desglose de las recaudaciones y descuentos, selecciona “Ver balance”." />
    ),
  },
  4: {
    nextFunction: upStepFunction,
    prevFunction: downStepFunction,
    styleElement: {
      switch: {},
      graph: {
        ...focusBoxTutorial,
        borderRadius: '1rem',
      },
    },
    title: 'Gráfico kilómetros',
    content: (
      <Box sx={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
        <Typography sx={{ fontFamily: 'Poppins-Regular', fontSize: '14px', textAlign: 'center' }}>
          En el gráfico podrás ir monitoreando qué % del total de kilómetros fueron con la app de
          Uber.
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Poppins-Regular',
            fontSize: '14px',
            textAlign: 'center',
            color: azulTucar,
          }}
        >
          Recuerda que al menos un 50% de los km semanales deben ser con Uber.
        </Typography>
      </Box>
    ),
  },
  5: {
    nextFunction: upStepFunction,
    prevFunction: downStepFunction,
    styleElement: {
      switch: {
        ...focusBoxTutorial,
        borderRadius: '1rem',
      },
    },
    title: 'Resumen kilómetros',
    content: (
      <DefaultContent textBlack="Puedes ver el resumen semanal o diario de kilómetros recorridos." />
    ),
  },
  6: {
    nextFunction: upStepFunction,
    prevFunction: downStepFunction,
    styleElement: {
      payment: {
        ...focusBoxTutorial,
        borderRadius: '1rem',
      },
    },
    title: 'Montos pendientes',
    content: (
      <Box sx={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
        <Typography sx={{ fontFamily: 'Poppins-Regular', fontSize: '14px', textAlign: 'center' }}>
          Si tienes algún monto pendiente de pago, te aparecerá aquí.
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Poppins-Regular',
            fontSize: '14px',
            textAlign: 'center',
            color: azulTucar,
          }}
        >
          Si tu deuda persiste por más de 48 hrs, se inhabilitará el auto.
        </Typography>
      </Box>
    ),
  },
  7: {
    nextFunction: () => {},
    prevFunction: downStepFunction,
    styleElement: {
      shortcut: {
        ...focusBoxTutorial,
        borderRadius: '1rem',
      },
    },
    title: 'Atajos',
    content: (
      <DefaultContent textBlack="Algunos atajos útiles para que accedas más rápido a algunas funciones." />
    ),
  },
};

export default AllSteps;
