import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { Box, Typography } from '@mui/material';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';

import MapMarker from './MapMarker';
import { verdeText } from '../../../../themes/variants/TucarColors';

const Map = ({ location, address, color }) => {
  const [center, setCenter] = useState(location);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY,
  });

  const renderMap = isLoaded ? (
    <GoogleMap
      mapContainerStyle={{ width: '100%', height: '100%', borderRadius: '15px' }}
      center={center}
      zoom={15}
      options={{ minZoom: 2 }}
    >
      <MapMarker position={location} setCenter={setCenter} title="Lugar de Destino" />
    </GoogleMap>
  ) : (
    <Box>Cargando...</Box>
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        padding: '10px',
        boxShadow: '0px 0px 5px #dbdbdb',
        borderRadius: '15px',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <RoomOutlinedIcon sx={{ fontSize: '2.5em', color }} />
        <Typography
          sx={{
            height: '100%',
            verticalAlign: 'middle',
            fontSize: '12px',
            lineHeight: 1.2,
            fontFamily: 'Poppins-Regular',
          }}
        >
          {address}
        </Typography>
      </Box>
      <Box sx={{ flex: 2 }}>{renderMap}</Box>
    </Box>
  );
};

export default Map;

Map.propTypes = {
  address: PropTypes.string,
  location: PropTypes.shape({}),
  color: PropTypes.string,
};

Map.defaultProps = {
  color: verdeText,
  address: '',
  location: { lat: -33.408255, lng: -70.571484 },
};
