import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Alert } from '@tucar/pit-stop';

import { wrapperAlert } from '../styles';

const ErrorAlert = ({ title, msg, isOpen, onClose }) => {
  return (
    <Box
      sx={{
        display: isOpen ? 'flex' : 'none',
        ...wrapperAlert,
      }}
    >
      <Alert style={{ width: '100%' }} title={title} variant="error" onClose={onClose}>
        {msg}
      </Alert>
    </Box>
  );
};

ErrorAlert.propTypes = {
  title: PropTypes.string,
  msg: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

ErrorAlert.defaultProps = {
  title: '',
  msg: '',
  isOpen: false,
  onClose: () => {},
};

export default ErrorAlert;
