import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';

import { getAuthStore } from '../graphql/store';
import ConnectionError from './extras/ConnectionError';

const GuestGuard = () => {
  const [offline, setOffline] = useState(true);

  useEffect(() => {
    if (!window.navigator.onLine) {
      setOffline(true);
    } else {
      setOffline(false);
    }
  }, [window.navigator.onLine]);

  const navigate = useNavigate();
  const { accessToken } = useReactiveVar(getAuthStore);

  useEffect(() => {
    if (accessToken) {
      navigate('/app/', { replace: true });
    }
  });

  if (offline) return <ConnectionError />;

  return <Outlet />;
};

export default GuestGuard;
