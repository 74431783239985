import React from 'react';
import PropTypes from 'prop-types';

import EvaluationForm from './EvaluationForm';
import ViewModal from '../../../../../components/extras/ViewModal';

const ModalMaintenance = ({ open, setOpen }) => {
  return <ViewModal open={open} setOpen={setOpen} content={<EvaluationForm />} />;
};

export default ModalMaintenance;

ModalMaintenance.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

ModalMaintenance.defaultProps = {
  open: false,
  setOpen: () => {},
};
