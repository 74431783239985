import React, { useEffect, useState } from 'react';
import { Loader } from '@tucar/pit-stop';
import { Box } from '@mui/material';
import { useOutletContext } from 'react-router-dom';
import { useReactiveVar, useQuery } from '@apollo/client';

import { getAuthStore } from '../../../graphql/store';
import GET_ADVICE_COUPLES from '../../../graphql/querys/exchange/getAdviceAssigned';
import TopNav2 from '../../../components/ui/TopNav/newTopNav';
import EmptyView from '../../../components/extras/EmptyView';
import ShiftPetitionScreen from './shiftPetitions';

const ShiftPetitions = () => {
  const [handlePageChange] = useOutletContext();
  const [showScreen, setShowScreen] = useState(false);
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);

  const { data: assignationInfo, loading: loadingAssignationInfo } = useQuery(
    GET_ADVICE_COUPLES.query,
    {
      ...GET_ADVICE_COUPLES.policies,
      variables: {
        driverId: uid,
        labelId: 'SHIFT_CHANGE_PETITION',
        $skipGetLabel: true,
      },
    },
  );

  useEffect(() => {
    handlePageChange(null, true);
  }, []);

  useEffect(() => {
    if (assignationInfo?.getCouples?.instances?.length) {
      if (assignationInfo.getCouples.instances[0]?.drivers.length >= 2) {
        setShowScreen(true);
      }
    }
  }, [assignationInfo]);

  if (loadingAssignationInfo) {
    return (
      <Box>
        <TopNav2 title="Turno compartido" />
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Loader size={32} pointRadio="4px" />
        </Box>
      </Box>
    );
  }
  if (!showScreen) {
    return (
      <Box>
        <TopNav2 title="Turno compartido" />
        <EmptyView />
      </Box>
    );
  }

  return <ShiftPetitionScreen />;
};

export default ShiftPetitions;
