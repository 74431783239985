import { gql } from '@apollo/client';

const URL_LINK_UP_UBER = {
  query: gql`
    query Query($redirectUrl: String!) {
      urlLinkUpUber(redirectUrl: $redirectUrl)
    }
  `,
};

export default URL_LINK_UP_UBER;
