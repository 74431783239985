import { gql } from '@apollo/client';

const GET_EXCHANGE = {
  query: gql`
    query getExchanges($driverId: String, $offset: Int, $limit: Int) {
      getExchanges(driverId: $driverId, limit: $limit, offset: $offset) {
        instances {
          _id
          receiver {
            driver {
              _id
              photo
              info {
                firstname
                lastname
                phone
                code
              }
              plan {
                selectedShifts {
                  endTime
                  name
                  startTime
                }
              }
            }
            id
            status
            type
          }
          steps {
            optional
            domain
            status
            time
            type
          }
          exchangeType
          info {
            address
            alias
            code
            floor
            parkingSlot
            lat
            lon
          }
          time
          tz
          status
          deliverer {
            driver {
              _id
              photo
              info {
                firstname
                lastname
                phone
                code
              }
              plan {
                selectedShifts {
                  endTime
                  name
                  startTime
                }
              }
            }
            id
            status
            type
          }
          car {
            _id
            info {
              plate
              model
              brand
            }
          }
        }
      }
    }
  `,
  policies: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
    context: {
      ignorePattern: {
        pattern: ['Error fetching driver plan'],
      },
    },
  },
};

export default GET_EXCHANGE;
