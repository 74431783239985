import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { useOutletContext } from 'react-router-dom';

import ProfilePhoto from '../../../../public/images/profileImage.svg';

import TopBar from '../../../components/ui/TopNav';
import { getAuthStore, getDriverStore } from '../../../graphql/store';
import CategoryIcon from '../../../components/ui/categoryIcon';
import translateUberPro from '../../../helpers/translateUberPro';
import TucarGoals from './TucarGoals';
import UberProGoals from './UberProGoals';

const Benefits = () => {
  const [handlePageChange] = useOutletContext();
  const {
    data: { firstname, lastname },
  } = useReactiveVar(getAuthStore);
  const { photo, category } = useReactiveVar(getDriverStore);

  useEffect(() => {
    handlePageChange(<TopBar title="Beneficios" />, true);
  }, []);

  const currentTab = 'uberPro';
  const tabComponent = {
    tucarGoals: <TucarGoals key="tucarGoals" />,
    uberPro: <UberProGoals key="uberProGoals" />,
  };

  return (
    <Box sx={{ display: 'grid', gap: '10px', gridTemplateRows: 'max-content 1fr', height: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center' }}>
        <img
          src={photo || ProfilePhoto}
          alt="profilePhoto"
          style={{ objectFit: 'cover', height: '100px', width: '100px', borderRadius: '60px' }}
        />
        <Typography sx={{ lineHeight: 1, fontSize: '14px' }} fontFamily="Poppins-Regular">
          {firstname} {lastname}
        </Typography>

        <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
          <CategoryIcon category={category?.uberPro} />
          <Typography sx={{ lineHeight: 1, fontSize: '16px' }} fontFamily="Poppins-Medium">
            Categoría {translateUberPro(category?.uberPro)}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'grid',
          gap: '10px',
          gridTemplateRows: '1fr max-content',
          height: '100%',
        }}
      >
        {tabComponent[currentTab]}
      </Box>
    </Box>
  );
};

export default Benefits;
