import { gql } from '@apollo/client';

export const OLD_GET_CAR_CONFIG = {
  query: gql`
    query Car($carId: ID!) {
      car(carId: $carId) {
        info {
          color
          plate
        }
        carConfig {
          capacity
          year
          motor
          battery
          image
          model
          fuel
          brand
          doors
          category
          seats
          transmission
          performance
        }
      }
    }
  `,
  policies: { fetchPolicy: 'network-only', notifyOnNetworkStatusChange: 'true' },
};

const GET_CAR_CONFIG = {
  query: gql`
    query Car($carId: ID!) {
      getCarBasicTelemetry(carId: $carId) {
        _id
        charging
        connected
        soc
        odometer {
          firstMeasuredDate
          lastMeasuredDate
          odometer
        }
      }
      getDocument(uid: $carId) {
        _id
        files {
          name
          status
          expire
          extension
          url
        }
      }
      car(carId: $carId) {
        carConfig {
          year
          image
          model
          brand
          performance
        }
      }
    }
  `,
  policies: {
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
  },
};

export default GET_CAR_CONFIG;
