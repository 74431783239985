import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { verdeText } from '../../../../../themes/variants/TucarColors';
import saveCable from '../../../../../../public/images/shared-form/save-cable.svg';

const CableSecured = ({ color, optional }) => {
  return (
    <Box
      sx={{
        height: '100%',
        minHeight: '100%',
        width: '100%',
        display: 'grid',
        gridTemplateRows: 'max-content 1fr',
      }}
    >
      <Box
        sx={{
          height: 'max-content',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
        }}
      >
        <Typography
          sx={{
            fontSize: '1rem',
            textAlign: 'left',
            fontFamily: 'Poppins-Medium',
            color,
          }}
        >
          {`Guarda el cable ${optional ? '(Opcional)' : ''}`}
        </Typography>
        <Typography sx={{ fontSize: '14px', textAlign: 'left', fontFamily: 'Poppins-Regular' }}>
          Desconecta el cable del enchufe y guárdalo en la maleta del auto.
        </Typography>
      </Box>
      <Box
        sx={{
          height: '100%',
          minHeight: '100%',
          borderRadius: '55px',
          backgroundImage: `url(${saveCable})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      />
    </Box>
  );
};

CableSecured.propTypes = {
  optional: PropTypes.bool,
  color: PropTypes.string,
};

CableSecured.defaultProps = {
  optional: false,
  color: verdeText,
};
export default CableSecured;
