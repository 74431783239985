export function validateEmail(email) {
  const expresion = '^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$';
  const re = new RegExp(expresion, 'g');
  return re.test(email);
}

export function passwordComplexity(password) {
  const strongExp = `^(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[@#$%^"'&+=*!%()~{}]).*$`;
  const strongRegex = new RegExp(strongExp, 'g');
  const mediumExp = `^(?=.{7,})(((?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[a-z])(?=.*[@#$%^"'&+=*!%()~{}]))|((?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^"'&+=*!%()~{}]))|((?=.*[0-9])(?=.*[a-z])(?=.*[@#$%^"'&+=*!%()~{}]))).*$`;
  const mediumRegex = new RegExp(mediumExp, 'g');
  const okExp = `(?=.{6,})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))).*`;
  const okRegex = new RegExp(okExp, 'g');

  if (strongRegex.test(password)) {
    return 4;
  }
  if (mediumRegex.test(password)) {
    return 3;
  }
  if (okRegex.test(password)) {
    return 2;
  }
  if (password.length > 0) {
    return 1;
  }
  return 0;
}

export const noComune = [
  'San Pedro',
  'Melipilla',
  'Alhué',
  'María Pinto',
  'Tiltil',
  'El Monte',
  'Paine',
  'Buin',
  'Pirque',
  'San José de Maipo',
  'Talagante',
];

export function availableSignUp(values, errorPhone) {
  const isEmptyObject = (obj) => {
    const aux = { ...obj };
    delete aux.adrapt;
    delete aux.emergencyPhone;
    delete aux.secondPhone;
    return Object.values(aux).every((value) => value !== '');
  };

  const { email, password, confirmPassword, phone } = values;
  if (isEmptyObject(values) && validateEmail(email) && !errorPhone && phone.length === 9) {
    if (password === confirmPassword) {
      if (passwordComplexity(password) === 4) {
        return true;
      }
    }
  }
  return false;
}
