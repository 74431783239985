import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
    fontFamily: theme.fontFamily,
  },
  '& .MuiInputBase-input': {
    height: '12px',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.backgroundColor,
    fontSize: theme.fontSize,
    padding: '0',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: theme.fontFamily,
    '&:focus': {
      borderRadius: 4,
      borderColor: '#057B81A',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

export default BootstrapInput;
