import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Box, Divider, Typography } from '@mui/material';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import InfoBox from './infoBox';

function serviceTypeTranslate(service) {
  switch (service) {
    case 'C':
      return 'Cambio';
    case 'I':
      return 'Inspección';
    case 'R':
      return 'Realizar';
    default:
      return '';
  }
}

const MaintenanceSection = ({ content, variant }) => {
  const detailContent = (services) => {
    const auxServices = services.map(
      (elem) =>
        `${serviceTypeTranslate(elem.serviceType)} ${elem.name.toLowerCase().replace(':', '')}`,
    );
    return (
      <Box
        sx={{
          padding: '20px',
          overflowY: 'scroll',
          maxHeight: '100%',
        }}
      >
        <Typography sx={{ textAlign: 'center', fontSize: '1rem', fontFamily: 'Poppins-Medium' }}>
          Servicios prestados
        </Typography>
        <Divider color="#ccc" sx={{ marginY: '20px' }} />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          {auxServices.map((elem) => (
            <Box
              key={elem}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Poppins-Regular',
                  fontSize: '0.875rem',
                  lineHeight: 1,
                }}
              >
                {elem}
              </Typography>
              <CheckCircleRoundedIcon
                sx={{
                  fontFamily: 'Poppins-Regular',
                  fontSize: '1rem',
                  color: '#2d7f3b',
                }}
              />
            </Box>
          ))}
        </Box>
      </Box>
    );
  };

  if (content.length === 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <Typography sx={{ fontFamily: 'Poppins-Regular' }}>
          No existen mantenciones registradas
        </Typography>
      </Box>
    );
  }
  return (
    <Box>
      {content.map((elem) => {
        const appDate = moment(elem.appointmentDatetime, 'YYYY-MM-DD[T]HH:mm:ss');
        return (
          <InfoBox
            time={appDate.format('HH:mm')}
            key={elem.appointmentDatetime}
            date={appDate.format('DD/MM/YY')}
            variant={variant}
            detailContent={detailContent(elem.services)}
            garageInfo={elem.garage}
          />
        );
      })}
    </Box>
  );
};

MaintenanceSection.propTypes = {
  content: PropTypes.arrayOf(PropTypes.shape({})),
  variant: PropTypes.string,
};

MaintenanceSection.defaultProps = {
  content: [],
  variant: '',
};

export default MaintenanceSection;
