import { gql } from '@apollo/client';

const GET_EVENTS_V2 = {
  query: gql`
    query GetEventsByUser(
      $userId: String!
      $limit: Int
      $page: Int
      $end: String
      $start: String
      $subject: EventSubject
      $status: EventStatus
    ) {
      getEventsByUser(
        userId: $userId
        limit: $limit
        page: $page
        end: $end
        start: $start
        subject: $subject
        status: $status
      ) {
        metadata {
          total_pages
          actual_page
          total_docuements
          page_length
        }
        data {
          chargeDate
          createdAt
          updatedAt
          title
          type
          amount
          description
          subject
          status
          comments
          installments
          car
          charged {
            billRef
            rol
            ref
          }
          credited {
            billRef
            rol
            ref
          }
          files {
            ext
            name
            url
            date
          }
        }
      }
    }
  `,
  policies: {
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
  },
};

export default GET_EVENTS_V2;
