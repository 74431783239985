import React, { useState, Fragment } from 'react';
import { Box, Table, TableBody, TableRow, TableCell, Collapse } from '@mui/material';
import PropTypes from 'prop-types';
import PopoverTucar from '../popoverInfo';
import OpenIconButton from '../../buttons/OpenIconButton';

const TableGenerator = ({
  content,
  tableStyle,
  backgroundColor,
  border,
  lastColor,
  lastSize,
  lastFamily,
}) => {
  return (
    <Box
      sx={{
        backgroundColor,
        borderRadius: '7px',
        ...tableStyle.container,
      }}
    >
      <Table size="small">
        <TableBody>
          {content.map((row, ident) => {
            const [open, setOpen] = useState(false);

            let lastOne = !border;
            if (ident === content.length - 1) {
              lastOne = true;
            }
            const styleWithOutCollapse = row.collapse
              ? { '& > *': { borderBottom: 'unset !important', paddingBottom: '0px !important' } }
              : {};

            return (
              <Fragment key={row?.ident || `${row.right}`}>
                <TableRow
                  colSpan={2}
                  sx={{
                    ...tableStyle.tableRow,
                    ...row?.style,
                    ...styleWithOutCollapse,
                  }}
                >
                  <TableCell
                    size="small"
                    sx={{
                      ...tableStyle.tableCell,
                      fontSize: lastOne ? lastSize : '12px',
                      fontFamily: lastOne ? lastFamily : 'Poppins-Light',
                      color: lastOne ? lastColor : 'currentcolor',
                      lineHeight: 1,
                      borderBottomColor: lastOne ? 'transparent' : 'rgba(224, 224, 224, 1)',
                    }}
                    align="left"
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {row.right}
                      {row.popText ? <PopoverTucar popText={row.popText} /> : null}
                    </Box>
                  </TableCell>
                  <TableCell
                    size="small"
                    sx={{
                      ...tableStyle.tableCell,
                      fontSize: lastOne ? lastSize : '12px',
                      fontFamily: lastOne ? lastFamily : 'Poppins-Light',
                      color: lastOne ? lastColor : 'currentcolor',
                      borderBottomColor: lastOne ? 'transparent' : 'rgba(224, 224, 224, 1)',
                      lineHeight: 1,
                    }}
                    align="right"
                  >
                    <Box
                      sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr max-content',
                        alignItems: 'center',
                      }}
                    >
                      {row.left}
                      {row.collapse && (
                        <OpenIconButton open={open} handleClick={() => setOpen(!open)} />
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
                {row.collapse && (
                  <TableRow>
                    <TableCell colSpan={2} sx={{ padding: '0px 0px 0px 20px' }}>
                      <Collapse
                        sx={{
                          borderRadius: open ? '0px 0px 7px 7px' : '7px',
                        }}
                        in={open}
                        timeout="auto"
                        unmountOnExit
                      >
                        {row.collapse}
                      </Collapse>
                    </TableCell>
                  </TableRow>
                )}
              </Fragment>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
};

TableGenerator.propTypes = {
  content: PropTypes.arrayOf(PropTypes.shape({})),
  tableStyle: PropTypes.shape({
    tableRow: PropTypes.shape({}),
    tableCell: PropTypes.shape({}),
    container: PropTypes.shape({}),
  }),
  backgroundColor: PropTypes.string,
  border: PropTypes.bool,
  lastColor: PropTypes.string,
  lastSize: PropTypes.string,
  lastFamily: PropTypes.string,
};

TableGenerator.defaultProps = {
  tableStyle: {
    tableRow: { height: '30px', color: '#333333' },
    tableCell: { verticalAlign: 'baseline', fontSize: '12px' },
    container: { padding: '0px 20px 20px 20px' },
  },
  backgroundColor: '#F3F3F3',
  border: true,
  content: [],
  lastColor: 'currentColor',
  lastSize: '12px',
  lastFamily: 'Poppins-Light',
};

export default TableGenerator;
