import { gql } from '@apollo/client';

const GET_UBER_GOALS = {
  query: gql`
    query GetAchievableUberGoals($driverId: ID!) {
      getAchievableUberGoals(driverId: $driverId) {
        goals {
          discount
          objectiveKms
          objectiveTrips
          objectiveHours
          driverKms
          driverTrips
          driverHours
        }
      }
    }
  `,
  policies: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
};

export default GET_UBER_GOALS;
