import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Divider } from '@mui/material';
import { useMutation, useReactiveVar } from '@apollo/client';
import { getDriverStore } from '../../../../graphql/store';
import UPDATE_BASIC_INFO_DRIVER from '../../../../graphql/mutations/driver/updateBasicInfo';
import BasicInfoForm, { getAge } from './basicInfoForm';
import ErrorModal from '../../../../components/ui/ErrorModal';
import OnBoardingTitle from '../../../../components/extras/OnboardingTitle';

const BasicInfo = ({ setStep }) => {
  const [open, setOpen] = useState(false);
  const { info } = useReactiveVar(getDriverStore);

  const [updateDriverInfo, { loading, data, error }] = useMutation(
    UPDATE_BASIC_INFO_DRIVER.mutation,
    UPDATE_BASIC_INFO_DRIVER.policies,
  );
  const executeMutation = (variables) => updateDriverInfo({ variables });
  useEffect(() => {
    const basicDataCheck = ['address', 'adrnumber', 'birth', 'city', 'commune', 'region'];
    if (
      basicDataCheck.every(
        (key) => info[key] !== undefined && info[key] !== null && info[key] !== '',
      )
    ) {
      if (getAge(info.birth) >= 23 && info.region === 'Metropolitana de Santiago') {
        setStep('menu');
      }
    }
  }, []);

  useEffect(() => {
    if (!loading) {
      if (data) {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      }
      if (error) {
        setOpen(true);
      }
    }
  }, [loading]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: '20px',
      }}
    >
      <ErrorModal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        content="Ups, ha ocurrido un error de nuestra parte. Contacta a soporte pinchando en el chat"
        title="Error al enviar información"
        emojiSymbol="🤔"
      />
      <Box
        sx={{
          paddingInline: '20px',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
        }}
      >
        <OnBoardingTitle title="¿Quién eres?" subtitle="Comencemos con información básica." />
        <Divider
          sx={{
            width: '100%',
            color: '#EAEAEA',
          }}
        />
      </Box>
      <BasicInfoForm executeMutation={executeMutation} />
    </Box>
  );
};

export default BasicInfo;

BasicInfo.propTypes = {
  setStep: PropTypes.func,
};

BasicInfo.defaultProps = {
  setStep: () => {},
};
