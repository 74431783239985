/* eslint-disable import/prefer-default-export */
import { makeVar } from '@apollo/client';

export const planStore = makeVar({
  planId: false,
  planType: false,
  startDate: false,
  discount: false,
  selectedShifts: [],
});
