import { gql } from '@apollo/client';

const LINK_UBER_EMAIL = {
  mutation: gql`
    mutation Mutation($linkUberEmailId: ID!, $email: String!) {
      linkUberEmail(id: $linkUberEmailId, email: $email)
    }
  `,
  policies: {
    errorPolicy: 'all',
  },
};

export default LINK_UBER_EMAIL;
