import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@mui/material';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import { azulTucar } from '../../../themes/variants/TucarColors';

function StandardDashButton({ flex, Icon, text, onClick }) {
  return (
    <Button
      onClick={onClick}
      variant="contained"
      sx={{
        flex,
        minWidth: '40vw',
        position: 'relative',
        borderRadius: '1rem',
        boxShadow: '0px 0.063rem 0.188rem #00000033',
        height: '5rem',
        backgroundColor: '#F7F7F7',
        lineHeight: 1,
        textTransform: 'none',
        color: '#333333',
        '&:hover': {
          backgroundColor: '#F7F7F7',
          color: azulTucar,
          '& .MuiSvgIcon-root': { color: '#333333' },
        },
      }}
    >
      <Icon
        sx={{
          position: 'absolute',
          top: '0.625rem',
          left: '0.625rem',
          color: azulTucar,
          fontSize: '2rem',
          marginBottom: '5%',
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          bottom: '0.625rem',
          left: '0.625rem',
          textAlign: 'left',
          display: 'flex',
          justifyContent: 'space-between',
          minWidth: '100%',
        }}
      >
        <Typography
          variant="span"
          sx={{
            color: 'currentColor',
            fontFamily: 'Poppins-Light',
            fontSize: '0.9rem',
            lineHeight: 1.2,
          }}
        >
          {`${text}`}
        </Typography>
      </Box>
      <Box>
        <ExpandCircleDownOutlinedIcon
          size="small"
          sx={{
            fontSize: '0.9rem',
            color: azulTucar,
            position: 'absolute',
            transform: 'rotate(270deg)',
            right: '0.225rem',
            bottom: '0.8rem',
          }}
        />
      </Box>
    </Button>
  );
}

StandardDashButton.propTypes = {
  Icon: PropTypes.elementType.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  flex: PropTypes.string,
};

StandardDashButton.defaultProps = {
  onClick: () => {},
  flex: '1',
};

export default StandardDashButton;
