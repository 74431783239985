import { gql } from '@apollo/client';

const GET_TICKETS = {
  query: gql`
    query GetTickets(
      $rol: TicketRol
      $page: Int
      $limit: Int
      $ref: String
      $status: [TicketStatus]
    ) {
      getTickets(rol: $rol, page: $page, limit: $limit, ref: $ref, status: $status) {
        data {
          _id
          amount
          subject
          type
          updatedAt
        }
        metadata {
          total_docuements
        }
      }
    }
  `,
  policies: {
    fetchPolicy: 'network-only',
    // errorPolicy: 'all',
  },
};

export default GET_TICKETS;
