import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';

const BackButton = ({ text, handler }) => (
  <Button
    onClick={handler}
    variant="text"
    sx={{
      color: '#B3B3B3',
      backgroundColor: '#FFF',
      fontSize: '14px',
      fontFamily: 'Poppins-Medium',
      fontWeight: '400',
      textAlign: 'center',
      width: '100%',
      letterSpacing: 0,
      marginTop: '10px',
      '&:hover': {
        color: '#B3B3B3',
        backgroundColor: '#FFF',
      },
    }}
  >
    {text}
  </Button>
);

BackButton.propTypes = {
  text: PropTypes.string,
  handler: PropTypes.func,
};

BackButton.defaultProps = {
  handler: () => {},
  text: 'Atrás',
};

export default BackButton;
