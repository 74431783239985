import moment from 'moment';
import { verdeIcon, verdeText } from '../../../themes/variants/TucarColors';
import { plateFormatterHelper } from '../../../helpers/plateFormatterHelper';

function formatGetExchangeData(auxData, today, uid) {
  const { _id: idExchange, exchangeType, info, car, time, steps } = auxData;
  const { deliverer, receiver } = auxData;
  const auxTime = moment(time, 'YYYY-MM-DD[T]HH:mm:ss').format('HH:mm');

  const auxContent = {
    exchangeId: idExchange,
    id: uid,
    steps,
    carData: {
      model: `${car.info.brand} ${car.info.model}`,
      plate: plateFormatterHelper(car.info.plate),
    },
    infoCard: {
      title: 'Hora de entrega:',
      content: `A las ${auxTime} hrs.`,
    },
    map: {
      address: `${info.address} ${info.floor !== '-' ? ` Piso: ${info.floor}.` : ''} ${
        info.parkingSlot !== '-' ? ` Estacionamiento: ${info.parkingSlot}.` : ''
      }`,
      location: {
        lat: parseFloat(info.lat),
        lng: parseFloat(info.lon),
      },
    },
  };
  let comunInfo;

  const decideComunContent = (deliver) => {
    return {
      im: deliver ? 'Deliverer' : 'Receiver',
      swipeButton: deliver
        ? { after: 'Turno finalizado', before: 'Finalizar turno' }
        : { after: 'Turno iniciado', before: 'Iniciar turno' },
      color: deliver ? verdeIcon : verdeText,
      title: deliver ? 'Lugar de entrega del auto' : 'Lugar de retiro del auto',
    };
  };

  if (exchangeType === 'EXCHANGE-CAR') {
    auxContent.securityCode = info.code;

    if (deliverer.id === uid) {
      const todayShift = deliverer.driver.plan.selectedShifts[today];
      auxContent.shiftInfo = todayShift;
      comunInfo = decideComunContent(true);

      auxContent.contactCard = {
        car: false,
        photo: receiver.driver.photo,
        name: `${receiver.driver.info.firstname} ${receiver.driver.info.lastname}`,
        phone: `${receiver.driver.info.code}${receiver.driver.info.phone}`,
        shiftName: todayShift.name,
      };
    } else {
      const todayShift = receiver?.driver.plan.selectedShifts[today];
      auxContent.shiftInfo = todayShift;
      comunInfo = decideComunContent(false);
      auxContent.contactCard = {
        car: false,
        photo: deliverer?.driver.photo,
        name: `${deliverer?.driver.info.firstname} ${deliverer?.driver.info.lastname}`,
        phone: `${deliverer?.driver.info.code}${deliverer?.driver.info.phone}`,
        shiftName: todayShift?.name,
      };
    }
  } else {
    auxContent.securityCode = '';

    if (exchangeType === 'LEFT-CHARGING') {
      comunInfo = decideComunContent(true);
      const todayShift = deliverer.driver.plan.selectedShifts[today];
      auxContent.shiftInfo = todayShift;
    } else {
      const todayShift = receiver?.driver.plan.selectedShifts[today];
      auxContent.shiftInfo = todayShift;
      comunInfo = decideComunContent(false);
    }
    auxContent.contactCard = {
      car: true,
      model: `${car.info.brand} ${car.info.model}`,
      plate: plateFormatterHelper(car.info.plate),
    };
  }
  return { ...auxContent, ...comunInfo };
}

export default formatGetExchangeData;
