import React from 'react';
import DirectionsCarOutlinedIcon from '@mui/icons-material/DirectionsCarOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import MapIcon from '@mui/icons-material/Map';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import LocalActivityOutlinedIcon from '@mui/icons-material/LocalActivityOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import SwapHorizOutlinedIcon from '@mui/icons-material/SwapHorizOutlined';
import SourceOutlinedIcon from '@mui/icons-material/SourceOutlined';
import { azulTucar } from '../../../themes/variants/TucarColors';

const defaultPath = 'dashboard';

const newContent = [
  {
    name: 'Mapa de carga',
    path: '/app/chargers',
    icon: <MapIcon sx={{ color: azulTucar, fontSize: '30px' }} />,
  },
  {
    name: 'Suscripción',
    path: '/app/subscription',
    icon: <DirectionsCarOutlinedIcon sx={{ color: azulTucar, fontSize: '30px' }} />,
  },
  {
    name: 'Resultado semanal',
    path: '/',
    icon: <PaidOutlinedIcon sx={{ color: azulTucar, fontSize: '30px' }} />,
    content: [
      {
        subList: false,
        name: 'Resultado semanal',
        path: '/',
        icon: <PaidOutlinedIcon sx={{ color: azulTucar, fontSize: '30px' }} />,
      },
      {
        subList: true,
        name: 'Balance',
        path: '/app/balance',
        icon: (
          <FiberManualRecordIcon sx={{ color: azulTucar, fontSize: '7px', marginLeft: '20%' }} />
        ),
      },
      {
        subList: true,
        name: 'Historial de liquidaciones',
        path: '/app/liquidations',
        icon: (
          <FiberManualRecordIcon sx={{ color: azulTucar, fontSize: '7px', marginLeft: '20%' }} />
        ),
      },
      {
        subList: true,
        name: 'Movimientos',
        path: '/app/moviments',
        icon: (
          <FiberManualRecordIcon sx={{ color: azulTucar, fontSize: '7px', marginLeft: '20%' }} />
        ),
      },
      {
        subList: true,
        name: 'Eventos',
        path: '/app/events',
        icon: (
          <FiberManualRecordIcon sx={{ color: azulTucar, fontSize: '7px', marginLeft: '20%' }} />
        ),
      },
      {
        subList: true,
        name: 'Boletas',
        path: '/app/v2/vouchers',
        icon: (
          <FiberManualRecordIcon sx={{ color: azulTucar, fontSize: '7px', marginLeft: '20%' }} />
        ),
      },
    ],
  },
  {
    name: 'Pagos',
    external: false,
    path: '/app/payment',
    icon: <SwapHorizOutlinedIcon sx={{ color: azulTucar, fontSize: '30px' }} />,
  },
  {
    external: false,
    name: 'Beneficios',
    path: '/app/benefits',
    icon: <LocalActivityOutlinedIcon sx={{ color: azulTucar, fontSize: '30px' }} />,
  },
  {
    name: 'Soporte y ayuda',
    path: '/',
    icon: <SupportAgentOutlinedIcon sx={{ color: azulTucar, fontSize: '30px' }} />,
    content: [
      {
        subList: false,
        name: 'Soporte y ayuda',
        path: defaultPath,
        icon: <SupportAgentOutlinedIcon sx={{ color: azulTucar, fontSize: '30px' }} />,
      },
      {
        subList: true,
        external: true,
        name: 'Preguntas frecuentes',
        path: 'https://tucar.com/preguntas-frecuentes',
        icon: (
          <FiberManualRecordIcon sx={{ color: azulTucar, fontSize: '7px', marginLeft: '20%' }} />
        ),
      },
      {
        subList: true,
        external: true,
        name: 'Términos de condiciones',
        path: 'https://tucar.com/terminos-condiciones',
        icon: (
          <FiberManualRecordIcon sx={{ color: azulTucar, fontSize: '7px', marginLeft: '20%' }} />
        ),
      },
      {
        subList: true,
        external: true,
        name: 'Políticas de privacidad',
        path: 'https://tucar.com/politica-de-privacidad',
        icon: (
          <FiberManualRecordIcon sx={{ color: azulTucar, fontSize: '7px', marginLeft: '20%' }} />
        ),
      },
    ],
  },
];

const content = [
  {
    name: 'Vehículo',
    path: '/',
    icon: <DirectionsCarOutlinedIcon sx={{ color: azulTucar, fontSize: '30px' }} />,
    content: [
      {
        subList: false,
        name: 'Vehículo',
        path: '/',
        icon: <DirectionsCarOutlinedIcon sx={{ color: azulTucar, fontSize: '30px' }} />,
      },
      {
        subList: true,
        name: 'Ficha técnica',
        path: '/app/car/carsheet',
        icon: (
          <FiberManualRecordIcon sx={{ color: azulTucar, fontSize: '7px', marginLeft: '20%' }} />
        ),
      },
      {
        subList: true,
        name: 'Deducibles',
        path: '/app/car/deductibles',
        icon: (
          <FiberManualRecordIcon sx={{ color: azulTucar, fontSize: '7px', marginLeft: '20%' }} />
        ),
      },
      {
        subList: true,
        name: 'Mantenciones',
        path: '/app/car/maintenance',
        icon: (
          <FiberManualRecordIcon sx={{ color: azulTucar, fontSize: '7px', marginLeft: '20%' }} />
        ),
      },
      {
        subList: true,
        name: 'Documentos del vehículo',
        path: '/app/car/documents',
        icon: (
          <FiberManualRecordIcon sx={{ color: azulTucar, fontSize: '7px', marginLeft: '20%' }} />
        ),
      },
    ],
  },
  {
    name: 'Resultado semanal',
    path: '/',
    icon: <PaidOutlinedIcon sx={{ color: azulTucar, fontSize: '30px' }} />,
    content: [
      {
        subList: false,
        name: 'Resultado semanal',
        path: '/',
        icon: <PaidOutlinedIcon sx={{ color: azulTucar, fontSize: '30px' }} />,
      },
      {
        subList: true,
        name: 'Balance',
        path: '/app/balance',
        icon: (
          <FiberManualRecordIcon sx={{ color: azulTucar, fontSize: '7px', marginLeft: '20%' }} />
        ),
      },
      {
        subList: true,
        name: 'Historial de liquidaciones',
        path: '/app/liquidations',
        icon: (
          <FiberManualRecordIcon sx={{ color: azulTucar, fontSize: '7px', marginLeft: '20%' }} />
        ),
      },
      {
        subList: true,
        name: 'Movimientos',
        path: '/app/moviments',
        icon: (
          <FiberManualRecordIcon sx={{ color: azulTucar, fontSize: '7px', marginLeft: '20%' }} />
        ),
      },
      {
        subList: true,
        name: 'Eventos',
        path: '/app/events',
        icon: (
          <FiberManualRecordIcon sx={{ color: azulTucar, fontSize: '7px', marginLeft: '20%' }} />
        ),
      },
      {
        subList: true,
        name: 'Boletas',
        path: '/app/v2/vouchers',
        icon: (
          <FiberManualRecordIcon sx={{ color: azulTucar, fontSize: '7px', marginLeft: '20%' }} />
        ),
      },
    ],
  },
  {
    name: 'Suscripción',
    path: '/',
    icon: <SourceOutlinedIcon sx={{ color: azulTucar, fontSize: '30px' }} />,
    content: [
      {
        subList: false,
        name: 'Suscripción',
        path: defaultPath,
        icon: <SourceOutlinedIcon sx={{ color: azulTucar, fontSize: '30px' }} />,
      },
      {
        subList: true,
        name: 'Plan de arriendo',
        path: '/app/subscription',
        icon: (
          <FiberManualRecordIcon sx={{ color: azulTucar, fontSize: '7px', marginLeft: '20%' }} />
        ),
      },
      {
        subList: true,
        name: 'Solicitudes cambio de turno',
        path: '/app/petitions/check',
        icon: (
          <FiberManualRecordIcon sx={{ color: azulTucar, fontSize: '7px', marginLeft: '20%' }} />
        ),
      },
      {
        subList: true,
        name: 'Garantía',
        path: '/app/guarantee',
        icon: (
          <FiberManualRecordIcon sx={{ color: azulTucar, fontSize: '7px', marginLeft: '20%' }} />
        ),
      },
    ],
  },
  {
    name: 'Pagos',
    external: false,
    path: '/app/payment',
    icon: <SwapHorizOutlinedIcon sx={{ color: azulTucar, fontSize: '30px' }} />,
  },
  {
    external: false,
    name: 'Beneficios',
    path: '/app/benefits',
    icon: <LocalActivityOutlinedIcon sx={{ color: azulTucar, fontSize: '30px' }} />,
  },
  {
    name: 'Soporte y ayuda',
    path: '/',
    icon: <SupportAgentOutlinedIcon sx={{ color: azulTucar, fontSize: '30px' }} />,
    content: [
      {
        subList: false,
        name: 'Soporte y ayuda',
        path: defaultPath,
        icon: <SupportAgentOutlinedIcon sx={{ color: azulTucar, fontSize: '30px' }} />,
      },
      {
        subList: true,
        external: true,
        name: 'Preguntas frecuentes',
        path: 'https://tucar.com/preguntas-frecuentes',
        icon: (
          <FiberManualRecordIcon sx={{ color: azulTucar, fontSize: '7px', marginLeft: '20%' }} />
        ),
      },
      {
        subList: true,
        external: true,
        name: 'Términos de condiciones',
        path: 'https://tucar.com/terminos-condiciones',
        icon: (
          <FiberManualRecordIcon sx={{ color: azulTucar, fontSize: '7px', marginLeft: '20%' }} />
        ),
      },
      {
        subList: true,
        external: true,
        name: 'Políticas de privacidad',
        path: 'https://tucar.com/politica-de-privacidad',
        icon: (
          <FiberManualRecordIcon sx={{ color: azulTucar, fontSize: '7px', marginLeft: '20%' }} />
        ),
      },
    ],
  },
];

const defaultContent = content.map((item, ident) => {
  // eslint-disable-next-line no-param-reassign
  item.id = ident;
  return item;
});

export const newDefaultContent = newContent.map((item, ident) => {
  // eslint-disable-next-line no-param-reassign
  item.id = ident;
  return item;
});

export default defaultContent;
