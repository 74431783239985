import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { isBefore } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { Card, Typography } from '@tucar/pit-stop';
import { azulTucar } from '../../../../themes/variants/TucarColors';
import { capitalizeDate, getDateList } from '../../../../helpers/dateFormat';
import {
  getColorShift,
  translateShift,
  statusColor,
  translateStatus,
  translateLongShift,
} from '../helpers';
import { getAuthStore } from '../../../../graphql/store';
import getUniqueKey from '../../../../helpers/customKey';
import UpdatePetition from '../currentPetition/updatePetition';

const PetitionCard = ({ data, onlyRead, goBack }) => {
  const navigate = useNavigate();
  const { status, expiration, createdAt, participants, petitioner, _id: petitionId } = data;
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);
  const currentShift = participants.find(({ id }) => uid === id);
  const currentTypes = currentShift.futureShifts.reduce((acc, shift) => {
    if (!acc.includes(shift.type)) {
      acc.push(shift.type);
    }
    return acc;
  }, []);

  return (
    <Card
      style={{
        position: 'relative',
        alignItems: 'unset',
      }}
    >
      <Box
        style={{
          display: onlyRead ? 'none' : 'block',
          height: '100%',
          width: '12px',
          borderRadius: '12px 0 0 12px',
          position: 'absolute',
          top: 0,
          left: 0,
          backgroundColor: '#1F89FF',
        }}
      />
      <Box
        sx={{
          padding: onlyRead ? '20px 10px' : '20px 10px 20px 20px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {onlyRead ? (
          <Typography
            style={{
              textAlign: 'center',
              color: '#5B5D71',
              lineHeight: 1.2,
              fontSize: '16px',
              fontWeight: 600,
            }}
          >
            {capitalizeDate(new Date(createdAt), 'MMMM YYYY')}
          </Typography>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              style={{
                color: statusColor(status),
                fontSize: '16px',
                fontWeight: 600,
              }}
            >
              {translateStatus(status)}
            </Typography>

            <Typography
              style={{
                color: azulTucar,
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: 1.2,
              }}
            >
              {isBefore(new Date(), new Date(expiration)) ? 'Vence' : 'Venció'} el{' '}
              {capitalizeDate(new Date(expiration), 'dddd DD/MM')}
            </Typography>
          </Box>
        )}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
            flexWrap: 'wrap',
            paddingTop: '20px',
          }}
        >
          {getDateList(new Date(createdAt)).map((date, index) => {
            const shift = currentShift.futureShifts[index];
            const createdDate = new Date(createdAt);
            const color = createdDate.getUTCDate() === date.getUTCDate() ? '#1F89FF' : '#17171C';
            return (
              <Box
                key={getUniqueKey()}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Box
                  sx={{
                    width: '8px',
                    height: '8px',
                    borderRadius: '100%',
                    backgroundColor: '#1F89FF',
                    marginBottom: '8px',
                    visibility:
                      createdDate.getUTCDate() === date.getUTCDate() ? 'visible' : 'hidden',
                  }}
                />
                <Typography style={{ fontWeight: 400, color, fontSize: '12px', lineHeight: 1.2 }}>
                  {capitalizeDate(date, 'ddd').replace('.', '')}
                </Typography>
                <Typography
                  style={{
                    color,
                    paddingBottom: '5px',
                    fontWeight: 600,
                    fontSize: '14px',
                    lineHeight: 1.2,
                  }}
                >
                  {capitalizeDate(date, 'DD')}
                </Typography>

                <Box
                  sx={{
                    display: 'flex',
                    borderRadius: '4px',
                    flexDirection: 'column',
                    padding: '4px 6px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: getColorShift(shift.type),
                  }}
                >
                  <Typography
                    style={{ fontWeight: 500, color: '#FFF', fontSize: '12px', lineHeight: 1.2 }}
                  >
                    {translateShift(shift.type)}
                  </Typography>
                  <Typography
                    style={{ fontWeight: 500, color: '#FFF', fontSize: '12px', lineHeight: 1.2 }}
                  >
                    {shift.startTime === '-' ? '--:--' : shift.startTime}
                  </Typography>
                  <Typography
                    style={{ fontWeight: 500, color: '#FFF', fontSize: '12px', lineHeight: 1.2 }}
                  >
                    {shift.endTime === '-' ? '--:--' : shift.endTime}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: '5px',
            padding: '10px 20px 0px 20px',
          }}
        >
          {currentTypes.map((shiftType) => {
            return (
              <Box
                key={getUniqueKey()}
                sx={{ display: 'flex', flexDirection: 'row', gap: '4px', alignItems: 'center' }}
              >
                <Box
                  sx={{
                    height: '8px',
                    width: '8px',
                    borderRadius: '50%',
                    backgroundColor: getColorShift(shiftType),
                  }}
                />

                <Typography style={{ fontSize: '12px', color: '#5B5D71', textAlign: 'center' }}>
                  {translateLongShift(shiftType)}
                </Typography>
              </Box>
            );
          })}
        </Box>
        <Box sx={{ display: onlyRead ? 'none' : 'block', paddingTop: '20px' }}>
          {petitioner === uid && status === 'created' ? (
            <UpdatePetition petitionId={petitionId} imPetitioner onClose={goBack} />
          ) : (
            <Box
              sx={{
                display: status === 'created' ? 'flex' : 'none',
              }}
            >
              <UpdatePetition
                petitionId={petitionId}
                onClose={() => navigate('/app/v2/petitions/reject', { replace: true })}
                onAccept={() => navigate('/app/v2/petitions/success', { replace: true })}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Card>
  );
};

PetitionCard.propTypes = {
  goBack: PropTypes.func,
  onlyRead: PropTypes.bool,
  data: PropTypes.shape({
    _id: PropTypes.string,
    petitioner: PropTypes.string,
    status: PropTypes.string,
    expiration: PropTypes.string,
    createdAt: PropTypes.string,
    participants: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

PetitionCard.defaultProps = {
  goBack: () => {},
  onlyRead: false,
  data: {
    _id: '',
    petitioner: '',
    status: 'pendient',
    expiration: new Date(),
    createdAt: new Date(),
    participants: [],
  },
};

export default PetitionCard;
