import React from 'react';
import { Box } from '@mui/material';
import { Typography } from '@tucar/pit-stop';
import rocketMan from '../../../../public/images/background/RocketMan.png';

const EmptyView = () => {
  return (
    <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '13px',
        }}
      >
        <img src={rocketMan} alt="rockerMan" height="225px" width="204px" />
        <Typography
          style={{
            color: '#1F89FF',
            fontWeight: 700,
            lineHeight: 1.2,
            fontSize: '24px',
          }}
        >
          ¡Nada por acá!
        </Typography>
        <Typography
          style={{
            textAlign: 'center',
            color: '#2D2F39',
            fontWeight: 500,
            lineHeight: 1.2,
            fontSize: '14px',
            width: '80vw',
          }}
        >
          Actualmente, esta sección no cuenta con datos para mostrar. Estamos trabajando
          constantemente para brindarte la mejor experiencia posible.
        </Typography>
      </Box>
    </Box>
  );
};

export default EmptyView;
