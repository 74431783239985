import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Card, Typography, Icon } from '@tucar/pit-stop';
import PressIconButton from '../../buttons/PressIconButton';
import { formatterCLP } from '../../../helpers/formatters';
import { azulTucar } from '../../../themes/variants/TucarColors';

const TagCard = ({ tagInfo }) => {
  return (
    <Card
      style={{
        position: 'relative',
        alignItems: 'unset',
      }}
    >
      <Box
        style={{
          content: '',
          height: '100%',
          width: '12px',
          borderRadius: '12px 0 0 12px',
          position: 'absolute',
          top: 0,
          left: 0,
          backgroundColor: '#1F89FF',
        }}
      />
      <Box sx={{ padding: '20px 28px 20px 30px', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', gap: '20px', alignItems: 'start' }}>
          <PressIconButton size="40px">
            <Icon color="#1F89FF" size="17px" name="gpsFixedOutlined" />
          </PressIconButton>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <Typography
              style={{
                color: '#71758E',
                fontSize: '13px',
                fontWeight: 500,
              }}
            >
              {moment(tagInfo.datetimeWindow, 'YYYY-MM-DD[T]HH:mm:ss').format(
                'DD/MM/YYYY [a las] HH:mm [hrs.]',
              )}
            </Typography>

            <Typography
              style={{
                color: azulTucar,
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: 1.2,
              }}
            >
              {tagInfo.address}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            borderTop: '1px solid #C6C8D2',
            marginTop: '15px',
            display: 'flex',
            alignItems: 'end',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              marginTop: '12px',
              width: 'min-content',
              padding: '6px 26px',
              borderRadius: '10px',
              border: '1px solid var(--contorno-click-botn, #D6E3F3)',
              background:
                'linear-gradient(268deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.00) 52.74%), linear-gradient(270deg, #50CAFF 0%, #0478FF 100%)',
              boxShadow:
                '4px 4px 20px 0px rgba(111, 140, 176, 0.41), -6px -6px 20px 0px #FFF, 2px 2px 4px 0px rgba(114, 142, 171, 0.10)',
            }}
          >
            <Typography
              style={{
                color: '#FFF',
                fontSize: '14px',
                fontWeight: 500,
              }}
            >
              {formatterCLP.format(Math.ceil(tagInfo.amount))}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

TagCard.propTypes = {
  tagInfo: PropTypes.shape({
    datetimeWindow: PropTypes.string,
    amount: PropTypes.number,
    address: PropTypes.string,
  }),
};

TagCard.defaultProps = {
  tagInfo: {
    datetime: '',
    amount: 0,
    address: '',
  },
};

export default TagCard;
