import globalStore from './model';

export const setLoading = (loading) => {
  globalStore({ ...globalStore(), loading });
};

export const setError = (error) => {
  globalStore({ ...globalStore(), error });
};

export const resetGlobal = () => {
  globalStore({ ...globalStore(), loading: false, error: false });
};
