import { gql } from '@apollo/client';

const BLOCK_DRIVER_CASH = {
  mutation: gql`
    mutation BlockDriverCash($driverId: String!, $block: Boolean!) {
      blockDriverCash(driverId: $driverId, block: $block) {
        block
        blockByType
        updatedAt
      }
    }
  `,
  policies: {
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  },
};

export default BLOCK_DRIVER_CASH;
