import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '@tucar/pit-stop';
import { Typography, Box } from '@mui/material';
import ProfilePhoto from '../../../../../public/images/profileImage.svg';
import { azulTucar } from '../../../../themes/variants/TucarColors';

const UserShiftCard = ({ shift, color, photoImg }) => (
  <Card
    style={{
      width: '100%',
      padding: '20px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      gap: '5px',
    }}
  >
    <img
      src={photoImg || ProfilePhoto}
      alt="profilePhoto"
      style={{
        objectFit: 'cover',
        height: '58px',
        width: '58px',
        borderRadius: '50%',
        border: `3px solid ${color}`,
      }}
    />
    <Box style={{ display: 'flex', flexDirection: 'column', gap: '6px', marginLeft: '10px' }}>
      <Typography
        style={{
          color: '#2D2F39',
          fontSize: '16px',
          fontWeight: 500,
        }}
      >
        Turno {shift.type}
      </Typography>
      <Typography
        style={{
          color,
          fontSize: '16px',
          fontWeight: 600,
        }}
      >
        {shift.startTime} AM - {shift.endTime} PM
      </Typography>
    </Box>
  </Card>
);

UserShiftCard.propTypes = {
  shift: PropTypes.shape({
    type: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
  }),
  photoImg: PropTypes.string,
  color: PropTypes.string,
};

UserShiftCard.defaultProps = {
  shift: {
    type: 'FREE',
    startTime: '00:00',
    endTime: '00:00',
  },
  photoImg: '',
  color: azulTucar,
};

export default UserShiftCard;
