const getErrorMsgGraphql = (error) => {
  const { graphQLErrors, networkError } = error;
  if (graphQLErrors && graphQLErrors.length > 0) {
    return graphQLErrors[0].extensions.errors;
  }
  if (networkError) {
    return networkError.message;
  }
  return '';
};

export default getErrorMsgGraphql;
