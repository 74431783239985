import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { getDriverStore } from '../../../graphql/store';
import Payment from '../../general/payment';
import GuaranteeShow from './guaranteeShow';

const OnBoardingGuarantee = () => {
  const {
    verifiedList: { guarantee },
  } = useReactiveVar(getDriverStore);

  if (guarantee) {
    return <GuaranteeShow />;
  }
  return <Payment onBoarding />;
};

export default OnBoardingGuarantee;
