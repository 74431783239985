import React, { useEffect, useState } from 'react';
import { Button, Loader } from '@tucar/pit-stop';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useReactiveVar, useLazyQuery } from '@apollo/client';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import { getAuthStore } from '../../../../graphql/store';
import GET_PETITIONS from '../../../../graphql/querys/exchange/getPetitions';
import PetitionCard from './petitionCard';
import getUniqueKey from '../../../../helpers/customKey';
import TopNav2 from '../../../../components/ui/TopNav/newTopNav';
import { getEndOfWeek, getStartOfWeek } from '../../../../helpers/dateFormat';
import OneDateSelector from '../../../../components/v2/OneDateSelector';
import FilterSlide from './filterSlide';
import EmptyView from '../../../../components/extras/EmptyView';

const HistoryPetitions = ({ goBack }) => {
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);
  const [petitions, setPetitions] = useState([]);
  const [selectedDate, setSelectedDate] = useState();
  const [openFilter, setOpenFilter] = useState(false);
  const [status, setStatus] = useState(null);

  const [getPetitions, { data, loading }] = useLazyQuery(GET_PETITIONS.query, {
    ...GET_PETITIONS.policies,
    variables: {
      driver: uid,
      start: null,
      end: null,
      offset: 0,
      status: null,
      limit: 20,
      sortBy: '-createdAt',
    },
  });

  const onChange = (date) => setSelectedDate(date);

  useEffect(() => {
    getPetitions({ variables: {} });
  }, []);

  useEffect(() => {
    if (data && data.getShiftChangePetitions) {
      setPetitions(data.getShiftChangePetitions.instances);
    }
  }, [data]);

  useEffect(() => {
    getPetitions({
      variables: {
        start: selectedDate ? getStartOfWeek(selectedDate) : null,
        end: selectedDate ? getEndOfWeek(selectedDate) : null,
        status,
      },
    });
  }, [selectedDate, status]);

  if (loading) {
    return (
      <>
        <TopNav2
          title="Solicitudes anteriores"
          handlePageChange={goBack}
          prevPage="/app/v2/petitions"
        />
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Loader size={32} pointRadio="4px" />
        </Box>
      </>
    );
  }

  return (
    <>
      {petitions.length === 0 && <EmptyView />}
      <Box sx={{ position: 'relative' }}>
        <FilterSlide
          statusProp={status}
          onAccept={(newStatus) => {
            setStatus(newStatus);
            setOpenFilter(false);
          }}
          onClear={() => {
            setStatus(null);
            setOpenFilter(false);
            setSelectedDate(null);
          }}
          open={openFilter}
          onClose={() => setOpenFilter(false)}
        />
        <TopNav2
          title="Solicitudes anteriores"
          handlePageChange={goBack}
          prevPage="/app/v2/petitions"
        />
        <Box
          sx={{
            paddingInline: '30px',
            display: 'flex',
            alignItems: 'end',
            gap: '27px',
            justifyContent: 'center',
            paddingBottom: '30px',
          }}
        >
          <OneDateSelector selectedDate={selectedDate} onChange={onChange} />
          <Button
            onClick={() => setOpenFilter(true)}
            style={{ padding: '18px', height: 'auto' }}
            startAdornment={<FilterAltIcon sx={{ color: '#8E90A4' }} />}
          />
        </Box>
        <Box
          sx={{
            marginBottom: '80px',
            display: petitions.length > 0 ? 'flex' : 'none',
            flexDirection: 'column',
            gap: '30px',
            paddingInline: '25px',
          }}
        >
          {petitions.map((petition) => {
            return <PetitionCard key={getUniqueKey()} data={petition} goBack={goBack} />;
          })}
        </Box>
      </Box>
    </>
  );
};

HistoryPetitions.propTypes = {
  goBack: PropTypes.func,
};

HistoryPetitions.defaultProps = {
  goBack: () => {},
};

export default HistoryPetitions;
