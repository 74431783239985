/* eslint-disable import/prefer-default-export */
import { leadStore } from './model';

export const setLead = (newLead) => {
  leadStore({ ...leadStore(), ...newLead });
};

export const setMeeting = (meeting) => {
  leadStore({ ...leadStore(), meeting });
};

export const resetLead = () => {
  leadStore({
    ...leadStore(),
    crmId: false,
    calendarLink: false,
    meeting: {
      end: false,
      start: false,
      reschedule: false,
      cancel: false,
      link: false,
      status: false,
      id: false,
    },
  });
};
