import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { Card, Icon, Typography } from '@tucar/pit-stop';
import { useReactiveVar, useQuery } from '@apollo/client';
import { getDriverStore, getPlanStore, getAuthStore } from '../../../../../graphql/store';
import PressIconButton from '../../../../../components/buttons/PressIconButton';
import DoorControlCard from './DoorControlCard';
import { azulTucar } from '../../../../../themes/variants/TucarColors';
import GET_EXCHANGE_ADVICE from '../../../../../graphql/querys/exchange/getAdviceExchange';
import DropdownManager from '../../../../../components/ui/DropdownManager';

const ManageButtons = ({ openDoorShow }) => {
  const navigate = useNavigate();
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore) || { data: { uid: '' } };
  const [recevier, setReceiver] = useState(false);
  const [show, setShow] = useState(false);

  const { status } = useReactiveVar(getDriverStore);
  const { plan } = useReactiveVar(getPlanStore);

  const { data } = useQuery(GET_EXCHANGE_ADVICE.query, {
    ...GET_EXCHANGE_ADVICE.policies,
    variables: {
      driverId: uid,
      limit: 1,
      offset: 0,
    },
  });

  useEffect(() => {
    if (data?.getExchanges?.total > 0) {
      const currentInstance = data.getExchanges.instances.filter(
        (elem) => elem.status === 'Started' || elem.status === 'Pending',
      );

      if (currentInstance.length > 0) {
        const { _id: recevierId } = currentInstance[0].receiver?.driver || { _id: '' };
        setReceiver(uid === recevierId);
        setShow(true);
      }
    }
  }, [data]);

  if (status.status === 'rent') {
    return <DropdownManager setFilter={() => {}} dashboardMode disabledDoors={!openDoorShow} />;
  }

  if (status.status === 'frozen') {
    return (
      <Box sx={{ display: 'flex', gap: '15px' }}>
        <Card
          style={{ padding: '24px 16px', width: '100%' }}
          onClick={() => window.Intercom('show')}
        >
          <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
            <PressIconButton>
              <Icon name="chatBubbleOutlined" color={azulTucar} size="36px" />
            </PressIconButton>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                paddingInline: '20px',
              }}
            >
              <Typography
                color="#5B5D71"
                style={{ fontSize: '16px', fontWeight: 600, lineHeight: 1.2 }}
              >
                Tu cuenta está congelada
              </Typography>
              <Typography
                color="#5B5D71"
                style={{
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: 1.2,
                  marginTop: '5px',
                }}
              >
                Si quieres que se te asigne un auto, contacta a soporte.
              </Typography>
            </Box>
          </Box>
        </Card>
      </Box>
    );
  }

  if (status.status === 'rent queue') {
    return (
      <Box sx={{ display: 'flex', gap: '15px' }}>
        <Card style={{ padding: '24px 16px', width: '100%' }}>
          <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
            <PressIconButton>
              <Icon name="carRentalFilled" color={azulTucar} size="36px" />
            </PressIconButton>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                paddingInline: '20px',
              }}
            >
              <Typography
                color="#5B5D71"
                style={{ fontSize: '16px', fontWeight: 600, lineHeight: 1.2 }}
              >
                En lista de espera
              </Typography>
              <Typography
                color="#5B5D71"
                style={{
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: 1.2,
                  marginTop: '5px',
                }}
              >
                Estamos asignando un vehículo para ti.
              </Typography>
            </Box>
          </Box>
        </Card>
      </Box>
    );
  }

  if (plan?.exclusive && plan?.electric) {
    return <DoorControlCard exclusiveMode disabled={!openDoorShow} />;
  }

  return (
    <Box sx={{ display: 'flex', gap: '15px' }}>
      <Card
        disabled={!show}
        onClick={() => (show ? navigate('/app/manageshift', { replace: true }) : null)}
        clicked
        style={{
          justifyContent: 'space-between',
          padding: '12px 16px',
          width: '100%',
          backgroundColor: !show ? '#CFD0D8' : '#FFF',
        }}
      >
        <PressIconButton>
          <Icon name="electricCarFilled" color={!show ? '#CFD0D8' : '#95D31C'} size="36px" />
        </PressIconButton>
        <Typography
          color={!show ? '#8E90A4' : '#5B5D71'}
          style={{ fontSize: '16px', fontWeight: 600 }}
        >
          {recevier ? ' iniciar ' : ' finalizar '} turno
        </Typography>
      </Card>
      <DoorControlCard disabled={!openDoorShow} />
    </Box>
  );
};

export default ManageButtons;

ManageButtons.propTypes = {
  openDoorShow: PropTypes.bool,
};

ManageButtons.defaultProps = {
  openDoorShow: false,
};
