import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useOutletContext } from 'react-router-dom';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { Loader, Button as NormalButton } from '@tucar/pit-stop';
import { getAuthStore } from '../../../graphql/store';
import GET_VOUCHERS from '../../../graphql/querys/balance/getVouchers';
import TopNav2 from '../../../components/ui/TopNav/newTopNav';
import EmptyView from '../../../components/extras/EmptyView';
import VoucherBox from './voucherBox';
import getUniqueKey from '../../../helpers/customKey';

const Vouchers = () => {
  const [handlePageChange] = useOutletContext();
  const [content, setContent] = useState([]);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(1);

  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);
  const [getVouchers, { loading, data, error }] = useLazyQuery(GET_VOUCHERS.query, {
    ...GET_VOUCHERS.policies,
  });

  const getMoreVouchers = () => {
    getVouchers({
      variables: {
        uid,
        offset: offset + 1,
        limit: 10,
      },
    });
  };

  useEffect(() => {
    if (data?.getVouchers && content.length < total) {
      const { instances, total: totalData, offset: offsetData } = data.getVouchers;
      setContent([...content, ...instances]);
      setOffset(offsetData);
      setTotal(totalData);
    }
  }, [data, loading]);

  useEffect(() => {
    handlePageChange(null, true);
    getVouchers({
      variables: {
        uid,
        offset,
        limit: 10,
      },
    });
  }, []);

  if (loading && !content.length) {
    return (
      <>
        <TopNav2 title="Boletas" />
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Loader size={32} pointRadio="4px" />
        </Box>
      </>
    );
  }

  if (error) {
    return (
      <Box>
        <TopNav2 title="Boletas" />
        <EmptyView />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        overflowY: 'auto',
        height: '100%',
        background: '#F0F0F3',
        gap: '0px',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '40px',
      }}
    >
      <TopNav2 title="Boletas" />
      <Box sx={{ paddingInline: '30px', gap: '20px', display: 'flex', flexDirection: 'column' }}>
        <Box />
        {content.map((voucher) => {
          return <VoucherBox key={getUniqueKey()} data={voucher} />;
        })}

        {content.length && content.length < total ? (
          <NormalButton
            style={{ width: 'max-content', alignSelf: 'center' }}
            onClick={getMoreVouchers}
          >
            Cargar más
          </NormalButton>
        ) : null}

        {loading && (
          <Box sx={{ alignSelf: 'center' }}>
            <Loader size={32} pointRadio="4px" />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Vouchers;
