import { gql } from '@apollo/client';

const URL_SIGN_UP_UBER = {
  query: gql`
    query Query($redirectUrl: String!) {
      urlSingUpUber(redirectUrl: $redirectUrl)
    }
  `,
  policies: {},
};

export default URL_SIGN_UP_UBER;
