import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Button, IconButton, Typography } from '@tucar/pit-stop';
import { azulTucar } from '../../../../../themes/variants/TucarColors';

const CalendarStep = ({ onNext }) => {
  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        sx={{
          position: 'absolute',
          top: '0px',
          right: '0px',
          height: '300px',
          zIndex: 1,
          '::before': {
            content: '""',
            zIndex: -1,
            position: 'absolute',
            top: '-30px',
            right: '-80%',
            width: '661px',
            height: '661px',
            transform: 'translate(-0%, -55%)',
            background:
              'linear-gradient(180deg, rgba(234, 255, 254, 0.90) 20%, rgba(205, 201, 241, 0.90) 100%)',
            borderRadius: '661px',
            backdropFilter: 'blur(2px)',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            paddingRight: '30px',
            paddingBottom: '30px',
            width: 'calc(100vw - 120px)',
            gap: '10px',
          }}
        >
          <Typography align="left" color={azulTucar} style={{ fontSize: '18px', fontWeight: 600 }}>
            Calendario{' '}
          </Typography>
          <Typography
            color={azulTucar}
            align="left"
            style={{ fontSize: '14px', fontWeight: 400, lineHeight: '16px' }}
          >
            Selecciona un rango de fechas y descubre cómo tus datos en el dashboard se adaptan a tu
            periodo elegido. Obtén una perspectiva detallada de tu progreso.
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <Typography color={azulTucar} style={{ fontSize: '14px', fontWeight: 500 }}>
            Paso 6/11
          </Typography>
          <Button
            size="small"
            onClick={() => {
              onNext();
              document.getElementById('section-7').scrollIntoView({ behavior: 'auto' });
            }}
          >
            Siguiente
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          position: 'relative',
          '::before': {
            content: '""',
            position: 'absolute',
            width: '80px',
            height: '80px',
            top: '20px',
            left: '20px',
            zIndex: 1,
            transform: 'translate(-50%, -50%)',
            borderRadius: '100px',
            background: '#FFF',
          },
        }}
      >
        <IconButton
          style={{ backgroundColor: '#FFF', position: 'absolute', zIndex: 1 }}
          iconProps={{ color: azulTucar, name: 'calendarTodayOutlined', size: '18px' }}
        />
      </Box>
    </Box>
  );
};

export default CalendarStep;

CalendarStep.propTypes = {
  onNext: PropTypes.func,
};

CalendarStep.defaultProps = {
  onNext: () => {},
};
