import React from 'react';
import PropTypes from 'prop-types';
import TableGenerator from '../../../components/info/TableGenerator';

function translateEventType(eventType) {
  switch (eventType) {
    case 'Speed':
      return 'Exceso de velocidad';
    case 'Accident':
      return 'Accidente';
    case 'Mugged':
      return 'Asalto';
    case 'Fine':
      return 'Multa';
    case 'Inspection':
      return 'Inspección';
    case 'Fuel':
      return 'Combustible';
    case 'Battery':
      return 'Batería';
    case 'Delay':
      return 'Retrazo';
    case 'Cleaning':
      return 'Limpieza';
    case 'Damage':
      return 'Daño';
    case 'Incomes':
      return 'Ingresos';
    case 'Milage':
      return 'Kilometraje';
    case 'Discount':
      return 'Descuento';
    case 'Tag':
      return 'Tag';
    case 'Event':
      return 'Evento';
    case 'Guarantee':
      return 'Garantía';
    case 'Commission':
      return 'Comisión';
    case 'Garage':
      return 'Taller';
    case 'GPS':
      return 'GPS';
    case 'Failure':
      return 'Falla';
    case 'Maintenance':
      return 'Mantenimiento';
    case 'Repair':
      return 'Reparación';
    case 'SparePart':
      return 'Repuestos';
    case 'Other':
      return 'Otro';
    default:
      return 'No definido';
  }
}

const EventTable = ({ event }) => {
  const content = [
    {
      right: 'Tipo',
      left: translateEventType(event.type),
    },
    { right: 'Patente', left: event.car },
  ];

  return (
    <TableGenerator
      border={false}
      content={content}
      backgroundColor="#FFF"
      tableStyle={{
        container: { padding: '0px 20px 20px 0px' },
        tableRow: { height: '10px' },
      }}
    />
  );
};

EventTable.propTypes = {
  event: PropTypes.shape({
    type: PropTypes.string,
    title: PropTypes.string,
    positive: PropTypes.bool,
    amount: PropTypes.number,
    liquidation: PropTypes.string,
    car: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
};

export default EventTable;
