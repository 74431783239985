import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Button, Typography } from '@tucar/pit-stop';
import { Box } from '@mui/material';
import { azulTucar } from '../../../../../themes/variants/TucarColors';
import { formatterCLP } from '../../../../../helpers/formatters';

const liquidationCardStyle = {
  padding: '16px',
  gap: '20px',
  marginBottom: '20px',
  borderRadius: '10px',
  justifyContent: 'space-between',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  minWidth: 'max-content',
  boxShadow:
    '4px 4px 20px 0px rgba(111, 140, 176, 0.41), -6px -6px 20px 0px #FFF, 2px 2px 4px 0px rgba(114, 142, 171, 0.10)',
  '::before': {
    content: '""',
    position: 'absolute',
    background: '#FFF1CC',
    top: '2px',
    left: '2px',
    bottom: '2px',
    right: '2px',
    borderRadius: '8px',
    zIndex: 0,
  },
  '::after': {
    content: '""',
    position: 'absolute',
    background: 'linear-gradient(180deg,#FFF,#FFF,#E6EAF0)',
    borderRadius: '10px',
    top: '0px',
    left: '0px',
    bottom: '0px',
    right: '0px',
    zIndex: -1,
  },
};

const PaymentDebtCard = ({ data }) => {
  const navigate = useNavigate();

  const { settledCollection: paymentType, amount } = data;
  const translateSettleCollection = (status) => {
    switch (status) {
      case 'DriverBillWeek':
        return 'Liquidación';
      case 'Guarantee':
        return 'Garantía';
      default:
        return 'Pago';
    }
  };
  if (!amount) return null;

  return (
    <Box sx={liquidationCardStyle}>
      <Box sx={{ display: 'flex', flexDirection: 'column', zIndex: 1 }}>
        <Typography color="#5B5D71" style={{ fontSize: '14px', fontWeight: 500 }}>
          {translateSettleCollection(paymentType)} pendiente
        </Typography>
        <Typography color={azulTucar} style={{ fontSize: '16px', fontWeight: 600 }}>
          {formatterCLP.format(amount)}
        </Typography>
      </Box>
      <Button
        onClick={() => navigate('/app/payment', { replace: true })}
        contentStyle={{ width: 'max-content' }}
      >
        Ver más
      </Button>
    </Box>
  );
};

PaymentDebtCard.propTypes = {
  data: PropTypes.shape({
    settledCollection: PropTypes.string,
    amount: PropTypes.number,
  }),
};

PaymentDebtCard.defaultProps = {
  data: {
    settledCollection: 'DriverBillWeek',
    amount: 0,
  },
};

export default PaymentDebtCard;
