import { gql } from '@apollo/client';

import { setInfo, setVerifiedList } from '../../store';
import { setPhoto } from '../../store/driver';

const UPDATE_BASIC_INFO_DRIVER = {
  mutation: gql`
    mutation UpdateBasicInfoDriver(
      $uid: ID!
      $address: String
      $adrapt: String
      $adrnumber: String
      $birth: String
      $city: String
      $commune: String
      $region: String
    ) {
      updateInfoDriver(
        uid: $uid
        address: $address
        adrapt: $adrapt
        adrnumber: $adrnumber
        birth: $birth
        city: $city
        commune: $commune
        region: $region
      ) {
        _id
        photo
        info {
          address
          adrapt
          adrnumber
          birth
          city
          code
          commune
          country
          email
          firstname
          lastname
          phone
          region
          rut
          gender
          parking
          secondPhone
          emergencyPhone
        }
        verifiedList {
          auth
          bank
          docs
          info
          meeting
          plan
          app
          guarantee
        }
      }
    }
  `,
  policies: {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    onCompleted: (response) => {
      if (response.updateInfoDriver) {
        const { info, verifiedList, photo } = response.updateInfoDriver;
        setPhoto(photo);
        setInfo(info);
        setVerifiedList(verifiedList);
      }
    },
    errorPolicie: 'ignore',
  },
};

export default UPDATE_BASIC_INFO_DRIVER;
