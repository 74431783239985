import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PopupModal, useCalendlyEventListener } from 'react-calendly';
import SubmitButton from '../../../components/SubmitButton';
import { blancoTucar } from '../../../themes/variants/TucarColors';

const CalendlyWidget = ({ url, info, text, color, backgroundColor }) => {
  const [open, setOpen] = useState(false);
  const [schedueled, setSchedueled] = useState(false);
  const { email, code, phone, firstname, lastname } = info;

  const isCalendlyEvent = (e) => {
    return (
      e.origin === 'https://calendly.com' &&
      e.data.event &&
      e.data.event === 'calendly.event_scheduled' &&
      !schedueled
    );
  };
  window.addEventListener('message', (e) => {
    if (isCalendlyEvent(e)) {
      setSchedueled(true);
    }
  });

  useCalendlyEventListener({
    onEventScheduled: () => {
      setOpen(false);
      window.location.reload();
    },
  });

  return (
    <div>
      <SubmitButton
        handler={() => {
          setOpen(true);
        }}
        text={text}
        color={color}
        backgroundColor={backgroundColor}
      />
      <PopupModal
        url={url}
        pageSettings={{
          backgroundColor: 'ffffff',
          hideEventTypeDetails: false,
          hideLandingPageDetails: false,
          primaryColor: '00a2ff',
          textColor: '4d5055',
        }}
        rootElement={document.getElementById('app')}
        text={text}
        prefill={{
          email,
          name: `${firstname} ${lastname}`,
          firstName: firstname,
          lastName: lastname,
          customAnswers: {
            a2: `${code.replace('+', '')}${phone}`,
          },
        }}
        onModalClose={() => {
          setOpen(false);
          window.location.reload();
        }}
        open={open}
      />
    </div>
  );
};

CalendlyWidget.defaultProps = {
  text: 'Agendar retiro del auto',
  backgroundColor: 'primary.main',
  color: blancoTucar,
  info: {
    email: '',
    code: '',
    phone: '',
    firstname: '',
    lastname: '',
  },
};

CalendlyWidget.propTypes = {
  url: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  text: PropTypes.string,
  color: PropTypes.string,
  info: PropTypes.shape({
    email: PropTypes.string,
    code: PropTypes.string,
    phone: PropTypes.string,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
  }),
};

export default CalendlyWidget;
