import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import NavBar from './navbar';
import SideBar from './sidebar';
import NewNavBar from './newNavBar';

const NavigationBar = ({ newVersion = false }) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  if (newVersion) {
    return (
      <Box
        sx={{
          backgroundColor: '#F0F0F3',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <NewNavBar />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        backgroundColor: '#F0F0F3',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <NavBar mobile={{ mobileOpen, setMobileOpen }} />
      <SideBar mobile={{ mobileOpen, setMobileOpen }} />
    </Box>
  );
};

NavigationBar.propTypes = {
  newVersion: PropTypes.bool,
};

NavigationBar.defaultProps = {
  newVersion: false,
};

export default NavigationBar;
