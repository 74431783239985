import React from 'react';
import { format } from 'date-fns';
import {
  NotInterestedOutlined,
  More,
  SettingsOutlined,
  GarageOutlined,
  LocalFireDepartment,
  ReceiptLongOutlined,
  ChangeCircleOutlined,
} from '@mui/icons-material';
import EventTable from './EventTable';
import TucarElement from '../../../components/info/TucarElement';
import { formatterCLP } from '../../../helpers/formatters';
import { azulTucar } from '../../../themes/variants/TucarColors';

export function iconEventSelector(subject) {
  switch (subject) {
    case 'Sinister':
      return LocalFireDepartment;
    case 'Control':
      return ReceiptLongOutlined;
    case 'Exchange':
      return ChangeCircleOutlined;
    case 'Settlement':
      return SettingsOutlined;
    case 'Garage':
      return GarageOutlined;
    case 'Others':
      return More;
    default:
      return NotInterestedOutlined;
  }
}

export function translateEvent(title) {
  switch (title) {
    case 'Sinister':
      return 'Siniestro';
    case 'Control':
      return 'Control policíaco';
    case 'Exchange':
      return 'Intercambio';
    case 'Settlement':
      return 'Liquidaciones';
    case 'Garage':
      return 'Mecánico';
    case 'Others':
      return 'Otros';
    default:
      return title;
  }
}

export function symbolAmount(amount, positive) {
  if (amount !== 0) {
    return positive ? '+' : '-';
  }
  return '';
}
export function convertContentEvent(content) {
  const auxContent = content.filter((elem) => elem.status === 'Approved');
  return auxContent.map((event) => {
    const auxPrimaryContent = {
      title: translateEvent(event.title),
      resume: event.description,
      detail: format(new Date(event.createdAt), 'dd/MM/yy'),
      auxText: `${symbolAmount(event.amount, event.positive)}${formatterCLP.format(event.amount)}`,
    };
    return (
      <TucarElement
        variant="collapse"
        // eslint-disable-next-line no-underscore-dangle
        key={`${event._id}`}
        IconCard={iconEventSelector(event.subject)}
        primaryContent={auxPrimaryContent}
        secondaryContent={<EventTable event={event} />}
        iconCardStyle={{ color: `${event.positive ? azulTucar : '#333333'}` }}
        primaryContentStyle={{
          title: {
            color: `${event.positive ? azulTucar : 'currentColor'}`,
          },
          auxText: { color: `${event.positive ? azulTucar : 'currentColor'}` },
        }}
      />
    );
  });
}
