import React, { useEffect, useState } from 'react';
import { Loader, Typography } from '@tucar/pit-stop';
import { Box } from '@mui/material';
import { useOutletContext, useNavigate } from 'react-router-dom';
import { useReactiveVar, useQuery } from '@apollo/client';

import { getAuthStore } from '../../../../graphql/store';
import GET_PETITIONS from '../../../../graphql/querys/exchange/getPetitions';
import TopNav2 from '../../../../components/ui/TopNav/newTopNav';
import EmptyView from '../../../../components/extras/EmptyView';
import PetitionCard from '../historyPetitions/petitionCard';
import { getDateList } from '../../../../helpers/dateFormat';
import DifferenceCollapse from './differenceCollapse';
import UpdatePetition from './updatePetition';

const CurrentPetition = () => {
  const [handlePageChange] = useOutletContext();
  const [showScreen, setShowScreen] = useState(false);
  const [imPetitioner, setImPetitioner] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [differenceShift, setDifferenceShift] = useState([]);
  const navigate = useNavigate();

  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);
  const { data, loading } = useQuery(GET_PETITIONS.query, {
    ...GET_PETITIONS.policies,
    variables: {
      driver: uid,
      status: 'created',
      limit: 1,
      sortBy: '-createdAt',
      offset: 0,
    },
  });

  useEffect(() => handlePageChange(null, true), []);

  useEffect(() => {
    if (data?.getShiftChangePetitions?.total > 0) {
      setShowScreen(true);
      const petition = data?.getShiftChangePetitions?.instances[0];

      const { _id: petitionId, createdAt, petitioner, participants } = petition || {};
      const dateList = getDateList(new Date(createdAt));
      setImPetitioner(petitioner === uid);
      const userData = participants.find(({ id }) =>
        petitioner === uid ? id === uid : id !== uid,
      );

      const difference = dateList.reduce((curr, date, index) => {
        const current = userData.pastShifts[index];
        const future = userData.futureShifts[index];
        if (current.type !== future.type) {
          curr.push({
            date,
            current: current.type,
            future: future.type,
            currentTime: {
              startTime: current.startTime,
              endTime: current.endTime,
            },
            futureTime: {
              startTime: future.startTime,
              endTime: future.endTime,
            },
          });
        }
        return curr;
      }, []);

      setDifferenceShift(difference);
      setCurrentData({ ...petition, petitionId });
    }
  }, [data]);

  if (loading) {
    return (
      <Box>
        <TopNav2 title="Solicitud pendiente" prevPage="/app/v2/petitions" />
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Loader size={32} pointRadio="4px" />
        </Box>
      </Box>
    );
  }

  if (!showScreen || !currentData) {
    return (
      <Box>
        <TopNav2 title="Solicitud pendiente" prevPage="/app/v2/petitions" />
        <EmptyView />
      </Box>
    );
  }

  if (imPetitioner) {
    return (
      <Box sx={{ paddingBottom: '80px' }}>
        <TopNav2 title="Solicitud pendiente" prevPage="/app/v2/petitions" />

        <Box
          sx={{
            paddingInline: '27px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '38px',
          }}
        >
          <Typography
            style={{
              textAlign: 'center',
              color: '#2D2F39',
              lineHeight: 1.2,
              fontSize: '14px',
              fontWeight: 400,
              paddingTop: '20px',
            }}
          >
            Tu solicitud de cambio de turno a tu compañero sigue pendiente. Abajo encontrarás la
            información.
          </Typography>
          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '14px' }}>
            <Typography
              style={{
                color: '#1F89FF',
                lineHeight: 1.2,
                fontSize: '16px',
                fontWeight: 600,
              }}
            >
              Si tu compañero acepta, tu semana quedará así:
            </Typography>

            <PetitionCard data={currentData} onlyRead />
          </Box>

          <DifferenceCollapse differenceShift={differenceShift} />

          <UpdatePetition
            petitionId={currentData?.petitionId}
            imPetitioner={imPetitioner}
            onClose={() => navigate('/app/v2/petitions', { replace: true })}
          />
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ paddingBottom: '80px' }}>
      <TopNav2 title="Aprobar solicitud" prevPage="/app/v2/petitions" />
      <Box
        sx={{
          paddingInline: '27px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '38px',
        }}
      >
        <Typography
          style={{
            textAlign: 'center',
            color: '#2D2F39',
            lineHeight: 1.2,
            fontSize: '14px',
            fontWeight: 400,
            paddingTop: '20px',
          }}
        >
          Tu compañero ha solicitado cambiar su turno. Revisa la propuesta antes de confirmar.
        </Typography>
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '14px' }}>
          <Typography
            style={{
              color: '#1F89FF',
              lineHeight: 1.2,
              fontSize: '16px',
              fontWeight: 600,
            }}
          >
            Si aceptas, tu semana quedará así:
          </Typography>

          <PetitionCard data={currentData} onlyRead />
        </Box>

        <DifferenceCollapse differenceShift={differenceShift} />

        <UpdatePetition
          petitionId={currentData?.petitionId}
          onClose={() => navigate('/app/v2/petitions/reject', { replace: true })}
          onAccept={() => navigate('/app/v2/petitions/success', { replace: true })}
        />
      </Box>
    </Box>
  );
};

export default CurrentPetition;
