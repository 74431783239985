import { gql } from '@apollo/client';
import { getFintoc } from '@fintoc/fintoc-js';

const SEND_PAYMENT = {
  mutation: gql`
    mutation CreatePayment($driverId: String!, $amount: Int, $info: PaymentInfo) {
      createPayment(driverId: $driverId, amount: $amount, info: $info) {
        _id
        widgetToken
      }
    }
  `,
  policies: {
    onCompleted: async (response) => {
      if (response.createPayment) {
        const fintoc = await getFintoc();
        const widget = fintoc.create({
          publicKey: process.env.FINTOC_API_KEY,
          widgetToken: response.createPayment.widgetToken,
          holderType: 'individual',
          product: 'payments',
          country: 'cl',
          onSuccess: () => {
            // eslint-disable-next-line no-restricted-globals
            location.reload();
          },
          onExit: () => {
            // eslint-disable-next-line no-restricted-globals
            location.reload();
          },
        });

        widget.open();
      }
    },
    errorPolicy: 'all',
  },
};

export default SEND_PAYMENT;
