import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Card, Typography } from '@tucar/pit-stop';
import { capitalizeDate, getDateList } from '../../../../helpers/dateFormat';
import { getColorShift, translateShift, translateLongShift } from '../helpers';
import getUniqueKey from '../../../../helpers/customKey';

const PetitionResume = ({ createdAt, shiftConfig }) => {
  const currentTypes = [...new Set(shiftConfig.map((shift) => shift.type).filter(Boolean))];

  return (
    <Card
      style={{
        position: 'relative',
        alignItems: 'unset',
      }}
    >
      <Box
        sx={{
          padding: '20px 10px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography
          style={{
            textAlign: 'center',
            color: '#5B5D71',
            lineHeight: 1.2,
            fontSize: '16px',
            fontWeight: 600,
          }}
        >
          {capitalizeDate(new Date(createdAt), 'MMMM YYYY')}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
            flexWrap: 'wrap',
            paddingTop: '20px',
          }}
        >
          {getDateList(new Date(createdAt)).map((date, index) => {
            const shift = shiftConfig[index];
            const createdDate = new Date(createdAt);
            const color = createdDate.getUTCDate() === date.getUTCDate() ? '#1F89FF' : '#17171C';
            return (
              <Box
                key={getUniqueKey()}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Box
                  sx={{
                    width: '8px',
                    height: '8px',
                    borderRadius: '100%',
                    backgroundColor: '#1F89FF',
                    marginBottom: '8px',
                    visibility:
                      createdDate.getUTCDate() === date.getUTCDate() ? 'visible' : 'hidden',
                  }}
                />
                <Typography style={{ fontWeight: 400, color, fontSize: '12px', lineHeight: 1.2 }}>
                  {capitalizeDate(date, 'ddd').replace('.', '')}
                </Typography>
                <Typography
                  style={{
                    color,
                    paddingBottom: '5px',
                    fontWeight: 600,
                    fontSize: '14px',
                    lineHeight: 1.2,
                  }}
                >
                  {capitalizeDate(date, 'DD')}
                </Typography>

                <Box
                  sx={{
                    display: 'flex',
                    borderRadius: '4px',
                    flexDirection: 'column',
                    padding: '4px 6px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: getColorShift(shift.type),
                  }}
                >
                  <Typography
                    style={{ fontWeight: 500, color: '#FFF', fontSize: '12px', lineHeight: 1.2 }}
                  >
                    {translateShift(shift.type)}
                  </Typography>
                  <Typography
                    style={{ fontWeight: 500, color: '#FFF', fontSize: '12px', lineHeight: 1.2 }}
                  >
                    {shift?.startTime === '-' ? '--:--' : shift?.startTime}
                  </Typography>
                  <Typography
                    style={{ fontWeight: 500, color: '#FFF', fontSize: '12px', lineHeight: 1.2 }}
                  >
                    {shift?.endTime === '-' ? '--:--' : shift?.endTime}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: '5px',
            padding: '10px 20px 0px 20px',
          }}
        >
          {currentTypes.map((shiftType) => {
            return (
              <Box
                key={getUniqueKey()}
                sx={{ display: 'flex', flexDirection: 'row', gap: '4px', alignItems: 'center' }}
              >
                <Box
                  sx={{
                    height: '8px',
                    width: '8px',
                    borderRadius: '50%',
                    backgroundColor: getColorShift(shiftType),
                  }}
                />

                <Typography style={{ fontSize: '12px', color: '#5B5D71', textAlign: 'center' }}>
                  {translateLongShift(shiftType)}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Card>
  );
};

PetitionResume.propTypes = {
  createdAt: PropTypes.string,
  shiftConfig: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      startTime: PropTypes.string,
      endTime: PropTypes.string,
    }),
  ),
};

PetitionResume.defaultProps = {
  createdAt: '',
  shiftConfig: [],
};

export default PetitionResume;
