import React from 'react';
import { ThemeProvider as TucarThemeProvider } from '@tucar/pit-stop';
import { HashRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, Box } from '@mui/material';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import ErrorFallback from './components/ErrorFallback';
import client from './contexts/graphql';
import AuthContext from './contexts/AuthContext';
import Router from './routes';
import tucar from './themes/Tucar';
import './themes/fonts.css';

if (process.env.MODE !== 'development') {
  Sentry.init({
    environment: process.env.MODE,
    dsn: process.env.SENTRY_DNS,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

const content =
  process.env.MODE !== 'development' ? (
    <Sentry.ErrorBoundary fallback={ErrorFallback}>
      <Box sx={{ backgroundColor: '#F0F0F3' }}>
        <AuthContext>
          <Router />
        </AuthContext>
      </Box>
    </Sentry.ErrorBoundary>
  ) : (
    <Box sx={{ backgroundColor: '#F0F0F3' }}>
      <AuthContext>
        <Router />
      </AuthContext>
    </Box>
  );

const App = () => {
  return (
    <ApolloProvider client={client}>
      <HashRouter>
        <TucarThemeProvider>
          <ThemeProvider theme={tucar}>
            <CssBaseline />
            {content}
          </ThemeProvider>
        </TucarThemeProvider>
      </HashRouter>
    </ApolloProvider>
  );
};

export default App;
