import { gql } from '@apollo/client';

const GET_DRIVER_BILLWEEK = {
  query: gql`
    query GetDriverBillDaysParsedByWeek($uid: ID!, $limit: Int, $offset: Int) {
      getDriverBillDaysParsedByWeek(uid: $uid, limit: $limit, offset: $offset) {
        total
        offset
        limit
        instances {
          initialDatetime
          finalDatetime
          billDays {
            date
            km
            traveledSections {
              startKm
              endKm
              variable
              traveled
            }
            plan {
              plan {
                sections {
                  variable
                  endKm
                  startKm
                }
              }
              selectedShifts {
                basePrice
              }
            }
            currency
            category
            discount
            uber {
              category
              amount
              datetime
            }
            uberBreakdown {
              adjustment
              cashCollected
              netFare
              other
              taxes
              promotions
              tips
              toll
            }
            tag {
              amount
              address
              datetimeWindow
            }
            expenses {
              fines
              penaltys
              guarantee
              rent
              tag
              other
            }
            incomes {
              uber
              didi
              cabify
              beat
              other
            }
            events {
              updatedAt
              title
              type
              amount
              description
              subject
              status
              charged {
                billRef
                rol
                ref
              }
              credited {
                billRef
                rol
                ref
              }
            }
          }
        }
      }
    }
  `,
  policies: {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: 'true',
    errorPolicy: 'all',
  },
};

export default GET_DRIVER_BILLWEEK;
