import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Typography } from '@tucar/pit-stop';

const SwipeButton = ({ onActive, message }) => {
  const [isActivated, setIsActivated] = useState(false);
  const [startX, setStartX] = useState(null);

  const handleMouseDown = (e) => setStartX(e.clientX);
  const handleMouseMove = (e) => {
    if (startX === null) return;

    const distance = e.clientX - startX;
    const activationThreshold = 100;

    if (distance >= activationThreshold) setIsActivated(true);
  };

  const handleMouseUp = () => setStartX(null);
  const handleTouchStart = (e) => setStartX(e.touches[0].clientX);
  const handleTouchMove = (e) => {
    if (startX === null) return;

    const distance = e.touches[0].clientX - startX;
    const activationThreshold = 100;

    if (distance >= activationThreshold) setIsActivated(true);
  };

  const handleTouchEnd = () => setStartX(null);

  useEffect(() => {
    if (isActivated) {
      onActive();
      setStartX(null);
      setIsActivated(false);
    }
  }, [isActivated]);

  return (
    <div
      role="button"
      tabIndex={0}
      style={{
        width: '100%',
        position: 'relative',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px 24px',
        borderRadius: '100px',
        background: 'rgba(126, 224, 255, 0.40)',
        overflow: 'hidden',
      }}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <Box
        sx={{
          '::before': {
            content: `"${message}"`,
            position: 'absolute',
            top: '0px',
            left: '0px',
            width: '100%',
            height: '100%',
            backgroundColor: '#3FA372',
            padding: '10px 24px',
            borderRadius: '100px',
            textAlign: 'center',
            fontSize: '14px',
            fontWeight: 600,
            color: '#FFF',
            transform: 'translateX(-100%)',
            fontFamily: 'Poppins-Regular',
            animation: 'grow 3s ease-in-out 3s infinite',
          },
          '@keyframes grow': {
            from: { transform: 'translateX(-100%)' },
            '10%': { transform: 'translateX(0%)' },
            '90%': { transform: 'translateX(0%)' },
            to: { transform: 'translateX(100%)' },
          },
        }}
      >
        <Typography
          style={{
            fontSize: '14px',
            fontWeight: 600,
            color: '#323232',
            textAlign: 'center',
          }}
        >
          {message}
        </Typography>
      </Box>
    </div>
  );
};

export default SwipeButton;

SwipeButton.propTypes = {
  onActive: PropTypes.func,
  message: PropTypes.string,
};

SwipeButton.defaultProps = {
  onActive: () => {},
  message: '',
};
