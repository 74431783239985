import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import DirectionsCarOutlinedIcon from '@mui/icons-material/DirectionsCarOutlined';
import chargingCard from '../../../../../../public/images/shared-form/charging-car.svg';
import { verdeBackground, verdeIcon, verdeText } from '../../../../../themes/variants/TucarColors';

const RetireCar = ({ data, optional }) => {
  const content = {
    direction: {
      icon: FmdGoodOutlinedIcon,
      content: [
        {
          key: 'direction',
          title: 'Dirección',
          message: `: ${data?.map?.address}`,
        },
      ],
    },
    time: {
      icon: TimerOutlinedIcon,
      content: [
        {
          key: 'time',
          title: 'Horario',
          message: ` de retiro: Entre las ${data?.shiftInfo?.startTime} y  ${data?.shiftInfo?.endTime} hrs.`,
        },
      ],
    },
    model: {
      icon: DirectionsCarOutlinedIcon,
      content: [
        { key: 'model', title: 'Modelo', message: `: ${data?.carData?.model}` },
        { key: 'plate', title: 'Patente', message: `: ${data?.carData?.plate}` },
      ],
    },
  };

  const iconBoxStyle = {
    backgroundColor: verdeBackground,
    borderRadius: '7px',
    padding: '10px',
    width: '50px',
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const iconStyle = { fontSize: '2.5rem', color: verdeIcon };

  const iconMessage = (IconElem, auxContent) => {
    return (
      <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <Box sx={iconBoxStyle}>
          <IconElem sx={iconStyle} />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
          {auxContent.map((elem) => {
            return (
              <Box key={elem.key} sx={{ display: 'inline', textAlign: 'left', lineHeight: 1.2 }}>
                <Typography
                  variant="span"
                  fontFamily="Poppins-Medium"
                  sx={{ fontSize: '12px', display: 'inline', lineHeight: 1.2 }}
                >
                  {elem.title}
                  <Typography
                    fontFamily="Poppins-Regular"
                    sx={{ display: 'inline', lineHeight: 1.2, fontSize: '12px' }}
                  >
                    {elem.message}
                  </Typography>
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        height: '100%',
        minHeight: '100%',
        width: '100%',
        display: 'grid',
        gridTemplateRows: 'max-content 1fr',
      }}
    >
      <Box sx={{ height: 'max-content' }}>
        <Typography
          sx={{
            fontSize: '1rem',
            textAlign: 'left',
            fontFamily: 'Poppins-Medium',
            color: verdeText,
          }}
        >
          {`Retira el auto ${optional ? '(Opcional)' : ''}`}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          {iconMessage(content.direction.icon, content.direction.content)}
          {iconMessage(content.time.icon, content.time.content)}
          {iconMessage(content.model.icon, content.model.content)}
        </Box>
      </Box>
      <Box
        sx={{
          height: '100%',
          minHeight: '100%',
          borderRadius: '55px',
          backgroundImage: `url(${chargingCard})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      />
    </Box>
  );
};

RetireCar.propTypes = {
  optional: PropTypes.bool,
  data: PropTypes.shape({
    map: PropTypes.shape({
      address: PropTypes.string,
    }),
    shiftInfo: PropTypes.shape({
      startTime: PropTypes.string,
      endTime: PropTypes.string,
    }),
    carData: PropTypes.shape({
      model: PropTypes.string,
      plate: PropTypes.string,
    }),
  }),
};

RetireCar.defaultProps = {
  optional: false,
  data: {
    map: {
      address: '-',
    },
    shiftInfo: {
      startTime: '-',
      endTime: '-',
    },
    carData: {
      model: '-',
      plate: '-',
    },
  },
};
export default RetireCar;
