/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { verdeText } from '../../../../../themes/variants/TucarColors';
import carConnected from '../../../../../../public/images/shared-form/car-disconnected.svg';
import carDisconnected from '../../../../../../public/images/shared-form/car-connected.svg';

const ChargingStep = ({ color, optional }) => {
  const connect = true;

  return (
    <Box
      sx={{
        height: '100%',
        minHeight: '100%',
        width: '100%',
        display: 'grid',
        gridTemplateRows: 'max-content 1fr',
      }}
    >
      <Box
        sx={{
          height: 'max-content',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
        }}
      >
        <Typography
          sx={{
            fontSize: '1rem',
            textAlign: 'left',
            fontFamily: 'Poppins-Medium',
            color,
          }}
        >
          {`Conecta el auto al cargador ${optional ? '(Opcional)' : ''}`}
        </Typography>
        <Typography sx={{ fontSize: '14px', textAlign: 'left', fontFamily: 'Poppins-Regular' }}>
          Enchufa el conector al puerto de carga. Debes escuchar un "click" para asegurarte que
          quede bien conectado.
        </Typography>
      </Box>
      <Box
        sx={{
          height: '100%',
          minHeight: '100%',
          borderRadius: '55px',
          backgroundImage: `url(${connect ? carConnected : carDisconnected})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      />
    </Box>
  );
};

ChargingStep.propTypes = {
  optional: PropTypes.bool,
  color: PropTypes.string,
};

ChargingStep.defaultProps = {
  optional: false,
  color: verdeText,
};
export default ChargingStep;
