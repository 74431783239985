import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Box } from '@mui/material';
import { Button, Typography } from '@tucar/pit-stop';
import { getDateList } from '../../../../helpers/dateFormat';
import { shiftObjToType } from './auxFunctions';
import CREATE_SHIFT_PETITION from '../../../../graphql/mutations/plan/createShiftPetition';
import DifferenceCollapse from '../currentPetition/differenceCollapse';
import PetitionResume from './petitionResume';
import ErrorAlert from '../../../../components/v2/Alerts/Warnings';

const ConfirmationView = ({
  goBack,
  mutationData,
  futureConfig,
  shiftOptions,
  currentShift,
  onSuccess,
}) => {
  const dateList = getDateList(new Date());
  const [isError, setIsError] = useState(false);
  const [createPetition, { data, loading, error }] = useMutation(
    CREATE_SHIFT_PETITION.mutation,
    CREATE_SHIFT_PETITION.policies,
  );

  useEffect(() => {
    if (!loading && data) {
      if (data?.createShiftChangePetition !== null) {
        onSuccess();
      } else {
        setIsError(true);
      }
    }
    if (!loading && error) {
      setIsError(true);
    }
  }, [data, loading]);

  const hanldeSendPetition = () => {
    createPetition({
      variables: mutationData,
    });
  };

  const differenceShift = dateList.reduce((curr, date, index) => {
    const typeShift = shiftObjToType(futureConfig[index]);
    if (currentShift[index].type !== typeShift) {
      return [
        ...curr,
        {
          date,
          currentTime: {
            startTime: currentShift[index].startTime,
            endTime: currentShift[index].endTime,
          },
          futureTime: {
            startTime: shiftOptions[typeShift].startTime,
            endTime: shiftOptions[typeShift].endTime,
          },
        },
      ];
    }
    return curr;
  }, []);

  const shiftConfig = futureConfig.reduce((acc, shift) => {
    const shiftTranslation = shiftObjToType(shift);
    return [
      ...acc,
      {
        type: shiftTranslation,
        startTime: shiftOptions[shiftTranslation]?.startTime,
        endTime: shiftOptions[shiftTranslation]?.endTime,
      },
    ];
  }, []);

  return (
    <>
      <ErrorAlert
        onClose={() => setIsError(false)}
        isOpen={isError}
        title="Error al crear la petición"
        msg="Es posible que exista una petición pendiente activa. Caso contrario, revisar la conexión a
          internet o contactar a soporte para notificar el error."
      />

      <Box
        sx={{
          paddingInline: '27px',
          paddingBottom: '80px',
          display: 'flex',
          flexDirection: 'column',
          gap: '38px',
        }}
      >
        <Typography
          style={{
            color: '#2D2F39',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '1.2',
            textAlign: 'center',
          }}
        >
          Estás a punto de solicitarle el siguiente cambio de turno a tu compañero:
        </Typography>

        <Typography
          style={{
            color: '#1F89FF',
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '1.2',
            textAlign: 'start',
          }}
        >
          Si tu compañero acepta, tu semana quedará así:{' '}
        </Typography>

        <PetitionResume createdAt={new Date().toISOString()} shiftConfig={shiftConfig} />

        <DifferenceCollapse differenceShift={differenceShift} />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '10px',
            justifyContent: 'center',
          }}
        >
          <Button variant="ghost" onClick={goBack}>
            Cancelar
          </Button>
          <Button
            loading={loading}
            disabled={differenceShift.length === 0}
            onClick={hanldeSendPetition}
          >
            Enviar solicitud
          </Button>
        </Box>
      </Box>
    </>
  );
};

ConfirmationView.propTypes = {
  goBack: PropTypes.func,
  onSuccess: PropTypes.func,
  futureConfig: PropTypes.arrayOf(PropTypes.shape({})),
  currentShift: PropTypes.arrayOf(
    PropTypes.shape({
      startTime: PropTypes.string,
      endTime: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
  mutationData: PropTypes.shape({}),
  shiftOptions: PropTypes.shape({
    startTime: PropTypes.string,
    endTime: PropTypes.string,
  }),
};

ConfirmationView.defaultProps = {
  onSuccess: () => {},
  mutationData: {},
  goBack: () => {},
  futureConfig: [],
  currentShift: [],
  shiftOptions: {},
};

export default ConfirmationView;
