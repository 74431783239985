export const allInfoSet = [
  {
    date: '26 de dic., 2022',
    expenses: 11126,
    incomes: 50000,
    initialDatetime: '2022-12-26T00:00:00',
    kmOther: 1246,
    kmTucar: 350,
    kmUber: 700,
    total: 38874,
    billDays: [
      {
        date: '2022-12-28T00:00:00',
        km: 500,
        kmUber: 360,
        expenses: {
          fines: 0,
          guarantee: 0,
          other: 0,
          penaltys: 0,
          rent: 0,
          tag: 0,
        },
        incomes: {
          beat: 0,
          cabify: 0,
          didi: 0,
          other: 50000,
          uber: 0,
        },
      },
      {
        date: '2022-12-27T00:00:00',
        km: 1200,
        kmUber: 800,
        expenses: {
          fines: 0,
          guarantee: 0,
          other: 0,
          penaltys: 0,
          rent: 5563,
          tag: 0,
        },
        incomes: {
          beat: 0,
          cabify: 0,
          didi: 0,
          other: 0,
          uber: 0,
        },
      },
      {
        date: '2022-12-26T00:00:00',
        km: 1340,
        kmUber: 290,
        expenses: {
          fines: 0,
          guarantee: 0,
          other: 0,
          penaltys: 0,
          rent: 5563,
          tag: 0,
        },
        incomes: {
          beat: 0,
          cabify: 0,
          didi: 0,
          other: 0,
          uber: 0,
        },
      },
    ],
  },
];

export const dates = [
  { id: 0, name: '26 de dic., 2022' },
  { id: 1, name: '19 de dic., 2022' },
  { id: 2, name: '12 de dic., 2022' },
];
