import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Collapse, Typography, Box, IconButton } from '@mui/material';
import { useInView } from 'react-intersection-observer';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import BoxParagraph from './aux-items/BoxParagraph';
import BulletedList from './aux-items/BulletedList';
import MixParagraph from './aux-items/MixParagraph';
import NumeredList from './aux-items/NumeredList';
import TableBox from './aux-items/TableBox';

const AccordeonUnit = ({ data, notifyCheck, autoCheck }) => {
  const [open, setOpen] = useState(false);
  const { title, subtitle, id } = data;
  const content = subtitle.raw.children;
  const [check, setCheck] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: true,
    skip: !open,
  });

  useEffect(() => {
    if (inView) {
      setCheck(true);
      notifyCheck(id);
    }
  }, [inView]);

  useEffect(() => {
    if (autoCheck) {
      setCheck(true);
    }
  }, [autoCheck]);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Box
      sx={{
        height: '100%',
        minHeight: '100%',
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'max-content 1fr',
        gridTemplateRows: '1fr',
        gap: '10px',
      }}
    >
      <Box
        sx={{
          height: '100%',
          minHeight: '40px',
          display: 'grid',
          gridTemplateColumns: '1fr',
          gridTemplateRows: '1fr max-content',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Box
            id="progressBar"
            sx={{
              height: '100%',
              borderRadius: '20px',
              width: '4px',
              backgroundColor: check ? '#95D31C' : '#AAACBB',
              transition: 'backgroundColor 2s',
            }}
          />
        </Box>

        <CheckCircleRoundedIcon
          sx={{
            width: '100%',
            margin: 0,
            padding: 0,
            fontSize: '10px',
            color: check ? '#95D31C' : '#AAACBB',
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: '10px',
          flexDirection: 'column',
          alignItems: 'baseline',
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            gap: '10px',
            justifyContent: 'space-between',
            alignItems: 'baseline',
          }}
        >
          <Typography sx={{ fontFamily: 'Poppins-SemiBold', fontSize: '14px', textAlign: 'left' }}>
            {title}
          </Typography>
          <Box>
            {open ? (
              <IconButton onClick={handleClick} sx={{ margin: 0, padding: 0 }}>
                <KeyboardArrowUpOutlinedIcon sx={{ color: '#17171C' }} />
              </IconButton>
            ) : (
              <IconButton onClick={handleClick} sx={{ margin: 0, padding: 0 }}>
                <KeyboardArrowDownOutlinedIcon sx={{ color: '#17171C' }} />
              </IconButton>
            )}
          </Box>
        </Box>

        <Collapse id="rules" in={open} timeout="auto" unmountOnExit>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            {content.map((elem, index) => {
              const identifier = `${id}-${index}`;
              if (elem.type === 'paragraph' || elem.type === 'heading-two') {
                const textObj = elem.children;
                if (textObj.length > 1)
                  return <MixParagraph key={identifier} data={textObj} ident={identifier} />;
                return <BoxParagraph key={identifier} data={textObj[0]} />;
              }
              if (elem.type === 'bulleted-list') {
                const arrayObj = elem.children;
                return <BulletedList key={identifier} data={arrayObj} ident={identifier} />;
              }
              if (elem.type === 'numbered-list') {
                const arrayObj = elem.children;
                return <NumeredList key={identifier} data={arrayObj} ident={identifier} />;
              }
              if (elem.type === 'table') {
                const arrayObj = elem.children[0].children;
                return <TableBox key={identifier} data={arrayObj} ident={identifier} />;
              }
              return null;
            })}
          </Box>
          <Box ref={ref} />
        </Collapse>
      </Box>
    </Box>
  );
};

AccordeonUnit.propTypes = {
  notifyCheck: PropTypes.func,
  autoCheck: PropTypes.bool,
  data: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.shape({
      raw: PropTypes.shape({
        children: PropTypes.arrayOf(PropTypes.shape({})),
      }),
    }),
    id: PropTypes.string,
  }),
};

AccordeonUnit.defaultProps = {
  notifyCheck: () => {},
  autoCheck: false,
  data: {
    title: '',
    subtitle: {
      raw: { children: [] },
    },
    id: '',
  },
};

export default AccordeonUnit;
