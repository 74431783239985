import React from 'react';
import PropTypes from 'prop-types';
import { Box, Modal, IconButton, useMediaQuery } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

import { grisTucar2 } from '../../themes/variants/TucarColors';
import InitForm from './InitForm';
import { setCompleteForm } from '../../graphql/store';

const styleLaptop = {
  position: 'fixed',
  width: '50%',
  alignItems: 'rigth',
  height: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '15px',
  boxShadow: '0px 11px 22px #C1C1C1',
  pt: 2,
  px: 5,
  pb: 3,
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '90vw',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '15px',
  boxShadow: '0px 11px 22px #C1C1C1',
  textAlign: 'right',
  pt: 2,
  px: 2,
  pb: 3,
  height: '90vh',
};

const ModalExchange = ({ open, setOpen, data, loadingButton, refetchData }) => {
  const match = useMediaQuery('(max-width:700px)');

  const handleClose = () => {
    setOpen(false);
    setCompleteForm(false);
    refetchData();
  };

  return (
    <Modal
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      open={open}
    >
      <Box>
        <Box sx={match ? { ...style } : { ...styleLaptop }}>
          <IconButton
            sx={{ zIndex: 2, position: 'fixed', right: '10px', top: '10px' }}
            onClick={() => handleClose({ openAgain: true })}
          >
            <ClearIcon color={grisTucar2} />
          </IconButton>
          <InitForm
            handerBack={handleClose}
            data={data}
            loadingButton={loadingButton}
            refetchData={refetchData}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalExchange;

ModalExchange.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  data: PropTypes.shape({}),
  loadingButton: PropTypes.bool,

  refetchData: PropTypes.func,
};

ModalExchange.defaultProps = {
  open: false,
  setOpen: () => {},
  data: {},
  loadingButton: true,
  refetchData: () => {},
};
