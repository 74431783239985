import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useOutletContext } from 'react-router-dom';
import { useReactiveVar, useQuery } from '@apollo/client';

import { getDriverStore } from '../../../../graphql/store';
import TopBar from '../../../../components/ui/TopNav';
import GET_DOCUMENTS from '../../../../graphql/querys/docs/getDocuments';
import LoadingProgress from '../../../../components/ui/LoadingProgress';
import DocBox from '../../profile/OldVersion/Documents/DocBox';

const DocumentsCarView = () => {
  const [handlePageChange] = useOutletContext();
  const { rent } = useReactiveVar(getDriverStore);
  const [content, setContent] = useState([]);
  useEffect(() => {
    handlePageChange(<TopBar title="Documentos del vehículo" />, true);
  }, []);

  const { data, loading } = useQuery(GET_DOCUMENTS.query, {
    ...GET_DOCUMENTS.policies,
    skip: !rent?.carId,
    variables: { uid: rent?.carId },
  });

  useEffect(() => {
    if (data?.getDocument?.files) {
      const aux = data.getDocument.files.map((elem, ident) => {
        const auxObj = {
          ...elem,
          carDocs: true,
          fileType: 'application/pdf, image/*',
          loadingRefetch: false,
          id: ident,
        };

        return auxObj;
      });
      setContent(aux);
    }
  }, [data]);

  if (loading) {
    return (
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <LoadingProgress allScreen={false} heightContainer="100%" />
      </Box>
    );
  }

  if (rent?.carId && content.length > 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '20px',
          gap: '20px',
        }}
      >
        {content.map((doc) => (
          <DocBox key={doc.name} fileObj={doc} />
        ))}
      </Box>
    );
  }

  if (rent?.carId && content.length === 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
          padding: '20px',
        }}
      >
        <Typography sx={{ fontFamily: 'Poppins-Regular', fontSize: '16px', textAlign: 'center' }}>
          No existen documentos para este vehículo
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <Typography sx={{ fontFamily: 'Poppins-Regular', fontSize: '16px' }}>
        No existe arriendo activo
      </Typography>
    </Box>
  );
};
export default DocumentsCarView;
