import { gql } from '@apollo/client';

const GET_DRIVER_PAYMENT = {
  query: gql`
    query GetDriverPayment($driverId: ID!, $country: String!) {
      getDriverPayment(driverId: $driverId) {
        settledCollection
        title
        amount
        description
        settledRef
      }
      getConfigAccounting(country: $country) {
        guarantee {
          total
        }
      }
    }
  `,
  policies: {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: 'true',
    errorPolicy: 'all',
  },
};

export default GET_DRIVER_PAYMENT;
