import React from 'react';
import { Box } from '@mui/material';

import DocsForm from './DocsForm';
import OnBoardingTitle from '../../../components/extras/OnboardingTitle';

const OnBoardingDocuments = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      <OnBoardingTitle
        noLineal
        title="Documentos y antecedentes"
        subtitle="Asegúrate de que sean legibles y estén vigentes."
      />
      <DocsForm />
    </Box>
  );
};

export default OnBoardingDocuments;
