import React, { useEffect, useState } from 'react';
import { useOutletContext, useNavigate } from 'react-router-dom';
import {
  Box,
  BottomNavigationAction,
  Paper,
  BottomNavigation,
  Divider,
  Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import TopBar from '../../../components/ui/TopNav';
import TutorialModal from '../modal';
import MenuSteps from './steps';
import { emptyStep } from '../helpers';

const TutorialMenu = () => {
  const [step, setStep] = useState(1);
  const [handlePageChange] = useOutletContext();
  const [stepObject, setStepObject] = useState(emptyStep);
  const navigation = useNavigate();

  useEffect(() => {
    handlePageChange(<TopBar title="Tutorial" prevPage="/app/dashboard" tutorial />, true);
  }, []);

  useEffect(() => {
    if (Object.keys(MenuSteps).some((key) => key === `${step}`)) {
      setStepObject(MenuSteps[step]);
    }
  }, [step]);

  const styleButtonNav = { color: '#999999', minWidth: 'auto', paddingY: '0px' };
  const styleButtonNavSelected = { color: '#999999', minWidth: 'auto', paddingY: '0px' };

  const handleNext = () => {
    if (stepObject.redirectNext) {
      navigation(stepObject.redirectNext, { replace: true });
    }
    stepObject.nextFunction(setStep, step);
  };

  const handlePrev = () => {
    stepObject.prevFunction(setStep, step);
  };

  return (
    <Box>
      <TutorialModal
        redirectNext={stepObject?.redirectNext}
        numSteps={Object.keys(MenuSteps).length}
        activeStep={step}
        position={stepObject.ubication}
        next={handleNext}
        prev={handlePrev}
        open
        title={stepObject.title}
        content={stepObject.content}
      />
      <Box
        sx={{
          zIndex: 20,
          height: '60px',
          backgroundColor: '#FFF',
          top: 0,
          left: 0,
          right: 0,
          position: 'absolute',
        }}
      >
        <Box
          sx={{ height: '60px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <Typography
            sx={{
              textAlign: 'center',
              color: 'black',
              fontFamily: 'Poppins-Medium',
              fontSize: '1rem',
              margin: 0,
              lineHeight: 1,
            }}
          >
            Tutorial
          </Typography>
        </Box>
      </Box>

      <Box>
        <Paper
          sx={{
            boxShadow: '0 0 0 max(100vh, 100vw) rgba(0, 0, 0, .5)',
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 10,
            height: '60px',
          }}
          elevation={5}
        >
          <Divider height="2px" sx={{ background: 'rgba(0, 0, 0, .5)' }} />
          <BottomNavigation
            sx={{
              height: '60px',
            }}
            value={0}
          >
            <BottomNavigationAction
              sx={{
                ...styleButtonNav,
                ...stepObject?.styleElement?.profile,
                '&.Mui-selected': styleButtonNavSelected,
              }}
              icon={<AccountCircleIcon sx={{ fontSize: '30px' }} />}
            />

            <BottomNavigationAction
              sx={{
                ...styleButtonNav,
                ...stepObject?.styleElement?.home,
                '&.Mui-selected': styleButtonNavSelected,
              }}
              icon={<HomeOutlinedIcon sx={{ fontSize: '30px' }} />}
            />

            <BottomNavigationAction
              sx={{
                ...styleButtonNav,
                ...stepObject?.styleElement?.menu,
                '&.Mui-selected': styleButtonNav,
              }}
              icon={<MenuIcon sx={{ fontSize: '30px' }} />}
            />

            <BottomNavigationAction
              sx={{
                ...styleButtonNav,
                ...stepObject?.styleElement?.bell,
                '&.Mui-selected': styleButtonNavSelected,
              }}
              icon={<NotificationsNoneOutlinedIcon sx={{ fontSize: '30px' }} />}
            />
            <BottomNavigationAction
              sx={{
                ...styleButtonNav,
                ...stepObject?.styleElement?.intercome,
                '&.Mui-selected': styleButtonNav,
              }}
              icon={<SupportAgentOutlinedIcon sx={{ fontSize: '30px' }} />}
            />
          </BottomNavigation>
        </Paper>
      </Box>
    </Box>
  );
};

export default TutorialMenu;
