import React, { useState, useEffect } from 'react';
import { useOutletContext, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Box, Button, Divider } from '@mui/material';

import { GetUberStatusRedirect } from '../../../helpers/url';
import URL_SIGN_UP_UBER from '../../../graphql/querys/auth/urlSignUpUber';
import SignUpTucar from './tucar';
import SignUpUber from './uber';
import LoadingProgress from '../../../components/ui/LoadingProgress';
import OnBoardingTitle from '../../../components/extras/OnboardingTitle';

const SignUp = () => {
  const navigate = useNavigate();
  const [handlePageChange] = useOutletContext();
  const [tucarSignUp, setTucarSignUp] = useState(false);

  useEffect(() => {
    handlePageChange();
  }, []);

  const { loading, error, data } = useQuery(URL_SIGN_UP_UBER.query, {
    variables: {
      redirectUrl: `${
        process.env.TUCAR_AUTH_SERVICE
      }/api/users/signup-uber/${GetUberStatusRedirect()}`,
    },
  });

  if (loading || error) return <LoadingProgress />;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        paddingY: '30px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
        paddingInline: '20px',
        backgroundColor: '#FFFFFF',
      }}
    >
      {tucarSignUp ? (
        <>
          <OnBoardingTitle
            emojiSymbol="🚙💨"
            title="¡Bienvenido!"
            subtitle="Completa los siguientes campos para crear tu cuenta."
          />
          <Divider
            sx={{
              width: '100%',
              color: '#EAEAEA',
            }}
          />
          <SignUpTucar tucarSignUpState={{ tucarSignUp, setTucarSignUp }} />
        </>
      ) : (
        <Box sx={{ width: '320px', maxWidth: '100vw' }}>
          <OnBoardingTitle title="¡Arrienda un auto y gana dinero manejando en Uber!" showLogo />
          <Divider
            sx={{
              width: '100%',
              color: '#EAEAEA',
              marginBottom: '20px',
            }}
          />

          <SignUpUber urlSingUpUber={data.urlSingUpUber} />
          <SignUpTucar tucarSignUpState={{ tucarSignUp, setTucarSignUp }} />
          <Box
            sx={{ marginTop: '15px' }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Button
              sx={{
                height: '26px',
                fontSize: '14px',
                fontFamily: 'Poppins-Light',
                color: '#333333',
                textDecoration: 'underline',
                textDecorationColor: '#333333',
              }}
              onClick={() => navigate('/signin', { replace: true })}
            >
              Ya tengo una cuenta
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SignUp;
