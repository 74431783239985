import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useReactiveVar, useQuery } from '@apollo/client';
import { getAuthStore } from '../../../graphql/store';
import GET_EXCHANGE_ADVICE from '../../../graphql/querys/exchange/getAdviceExchange';
import SwipeButton from '.';

const SwipeShift = () => {
  const navigate = useNavigate();
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore) || { data: { uid: '' } };
  const [receiver, setReceiver] = useState(false);
  const [show, setShow] = useState(false);

  const { data } = useQuery(GET_EXCHANGE_ADVICE.query, {
    ...GET_EXCHANGE_ADVICE.policies,
    variables: {
      driverId: uid,
      limit: 1,
      offset: 0,
    },
  });

  useEffect(() => {
    if (data?.getExchanges?.total > 0) {
      const currentInstance = data.getExchanges.instances.filter(
        (elem) => elem.status === 'Started' || elem.status === 'Pending',
      );

      if (currentInstance.length > 0) {
        const { _id: recevierId } = currentInstance[0].receiver?.driver || { _id: '' };
        setReceiver(uid === recevierId);
        setShow(true);
      }
    }
  }, [data]);

  if (!show) return null;

  return (
    <SwipeButton
      onActive={() => navigate('/app/manageshift', { replace: true })}
      message={`Desliza para ${receiver ? ' iniciar ' : ' finalizar '} turno`}
    />
  );
};

export default SwipeShift;
