import { Box } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import BoxParagraph from './BoxParagraph';

const NumeredList = ({ data, ident }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingLeft: '10px' }}>
      {data.map((elem, index) => {
        const identifier = `${ident}-${index}`;
        const contentObj = elem.children[0].children[0];
        return (
          <BoxParagraph
            key={identifier}
            data={{
              ...contentObj.children[0],
              text: `${index + 1}. ${contentObj.children[0].text}`,
            }}
          />
        );
      })}
    </Box>
  );
};

NumeredList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  ident: PropTypes.string,
};

NumeredList.defaultProps = {
  data: [],
  ident: '',
};

export default NumeredList;
