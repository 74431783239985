import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@mui/material';
import { azulTucar } from '../../../themes/variants/TucarColors';

const SwitchLabel = ({ styleProps, left, right, handle }) => {
  const [extraProps, setExtraProps] = useState();
  const [open, setOpen] = useState(false);

  const clickSwitch = () => {
    handle();
    if (!open) {
      setOpen(true);
      setExtraProps({
        transform: 'translate(100%,0)',
        transition: '.3s',
      });
    } else {
      setOpen(false);
      setExtraProps({
        transform: 'translate(0,0)',
        transition: '.3s',
      });
    }
    handle();
  };

  return (
    <Box
      sx={{
        ...styleProps,
        backgroundColor: '#EAEAEA',
        position: 'relative',
        display: 'flex',
        width: '100%',
        height: '25px',
        borderRadius: '25px',
      }}
      onClick={clickSwitch}
    >
      <Box
        sx={{
          zIndex: '2',
          display: 'flex',
          justifyContent: 'space-between',
          borderRadius: '25px',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Box sx={{ width: '50%' }}>
          <Typography
            sx={{
              fontSize: '0.625rem',
              fontFamily: 'Poppins-Medium',
              color: !open ? 'white' : '#ADADAD',
              transition: 'color .3s',
              textAlign: 'center',
            }}
          >
            {left}
          </Typography>
        </Box>
        <Box sx={{ width: '50%' }}>
          <Typography
            sx={{
              fontSize: '0.625rem',
              fontFamily: 'Poppins-Medium',
              color: open ? 'white' : '#ADADAD',
              transition: 'color .3s',
              textAlign: 'center',
            }}
          >
            {right}
          </Typography>
        </Box>
      </Box>

      <Button
        variant="contained"
        disableElevation
        sx={{
          position: 'absolute',
          padding: '0px',
          margin: '0px',
          minWidth: 'auto',
          width: '50%',
          height: '100%',
          backgroundColor: azulTucar,
          borderRadius: '25px',
          boxShadow: 0,
          ...extraProps,
        }}
      />
    </Box>
  );
};

SwitchLabel.propTypes = {
  left: PropTypes.string,
  right: PropTypes.string,
  handle: PropTypes.func,
  styleProps: PropTypes.shape({}),
};

SwitchLabel.defaultProps = {
  left: 'Texto 1',
  right: 'Texto 2',
  handle: () => {},
  styleProps: {},
};

export default SwitchLabel;
