import React from 'react';
import PropTypes from 'prop-types';
import { InView } from 'react-intersection-observer';
import { FormControl, MenuItem, Typography, Select } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import BootstrapInput from './style';
import LoadingProgress from '../ui/LoadingProgress';

const DateSelector = ({
  handleChange,
  name,
  date,
  dateArray,
  fontSize,
  fontFamily,
  inViewFunction,
  infinity,
  loadView,
}) => {
  return (
    <FormControl size="small">
      <Select
        value={date || ''}
        variant="standard"
        displayEmpty
        input={<BootstrapInput sx={{ fontSize, fontFamily }} />}
        onChange={(e) => handleChange(name, e.target.value)}
        // eslint-disable-next-line react/no-unstable-nested-components
        IconComponent={(props) => (
          <KeyboardArrowDownIcon
            {...props}
            fontSize="small"
            sx={{ verticalAlign: 'center', marginLeft: '10px' }}
          />
        )}
      >
        {dateArray.map((dateInfo) => (
          <MenuItem value={dateInfo.name} key={dateInfo.id}>
            <Typography marginRight="0rem" fontFamily={fontFamily} fontSize={fontSize} noWrap>
              {dateInfo.name}
            </Typography>
          </MenuItem>
        ))}
        {loadView ? <LoadingProgress allScreen={false} /> : null}

        <InView
          onChange={async (inView) => {
            if (inView && infinity) {
              await inViewFunction().then(() => {});
            }
          }}
        />
      </Select>
    </FormControl>
  );
};

DateSelector.propTypes = {
  handleChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  date: PropTypes.string.isRequired,
  dateArray: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fontSize: PropTypes.string,
  fontFamily: PropTypes.string,
  inViewFunction: PropTypes.func,
  infinity: PropTypes.bool,
  loadView: PropTypes.bool,
};

DateSelector.defaultProps = {
  infinity: false,
  name: 'date',
  fontSize: '12px',
  fontFamily: 'Poppins-Light',
  inViewFunction: () => {},
  loadView: false,
};

export default DateSelector;
