import React from 'react';
import { IconButton, Typography } from '@tucar/pit-stop';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { azulTucar } from '../../../../../themes/variants/TucarColors';
import { getAuthStore, getPlanStore } from '../../../../../graphql/store';

function shiftTypeTranslation(shiftType) {
  switch (shiftType) {
    case 'PM':
      return 'tarde';
    case 'AM':
      return 'mañana';
    case 'ALL':
      return 'completo';
    case 'FREE':
      return 'libre';
    case 'RESERVED':
      return 'reservado';
    case 'NIGHT':
      return 'nocturno';
    default:
      return shiftType;
  }
}

const NameAndTurnBox = () => {
  const navigate = useNavigate();
  const { selectedShifts } = useReactiveVar(getPlanStore) || [];
  const {
    data: { firstname },
  } = useReactiveVar(getAuthStore);
  const today = (new Date().getDay() + 6) % 7;

  return (
    <Box
      sx={{
        marginTop: '23px',
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <IconButton
        onClick={() => navigate('/app/v2/tutorial/dashBoard', { replace: true })}
        style={{ background: '#FFF', width: '46px', height: '46px' }}
        iconProps={{ name: 'playArrowFilled', size: '38px', color: azulTucar }}
      />

      <Box>
        <Box sx={{ display: 'flex', gap: '3px', flexWrap: 'wrap' }}>
          <Typography
            color={azulTucar}
            style={{ fontSize: '18px', fontWeight: 500, lineHeight: 1.2 }}
          >
            Hola,{' '}
          </Typography>
          <Typography
            color={azulTucar}
            style={{ fontSize: '18px', fontWeight: 600, lineHeight: 1.2 }}
          >
            {firstname[0].toUpperCase() + firstname.slice(1).toLowerCase()} 🖖
          </Typography>
        </Box>
        {selectedShifts.length > 0 && (
          <Typography
            color="#5B5D71"
            style={{ fontSize: '14px', fontWeight: 500, lineHeight: 1.2 }}
          >
            Turno {shiftTypeTranslation(selectedShifts[today]?.type)} (
            {selectedShifts[today].startTime} a {selectedShifts[today].endTime} hrs.)
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default NameAndTurnBox;
