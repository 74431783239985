import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useReactiveVar } from '@apollo/client';
import { Box, Typography } from '@mui/material';

import { GetUberStatusRedirect } from '../../../helpers/url';
import QueryParams from '../../../components/QueryParams';
import SIGN_UP_UBER from '../../../graphql/mutations/auth/signUpUber';
import { getAuthStore, getGlobalStore, setLoading } from '../../../graphql/store';
import LoadingProgress from '../../../components/ui/LoadingProgress';
import ErrorModal from '../../../components/ui/ErrorModal';

function SignUpUber() {
  const query = QueryParams();
  const navigate = useNavigate();
  const { loading } = useReactiveVar(getGlobalStore);
  const { accessToken } = useReactiveVar(getAuthStore);
  const [open, setOpen] = useState(false);
  const [errorTitle, setErrorTitle] = useState('Error de registro');
  const [content, setContent] = useState('');
  const [signUpWithUber, { error }] = useMutation(SIGN_UP_UBER.mutation, {
    ...SIGN_UP_UBER.policies,
    variables: {
      code: query.get('code'),
      redirectUrl: `${
        process.env.TUCAR_AUTH_SERVICE
      }/api/users/signup-uber/${GetUberStatusRedirect()}`,
    },
  });

  useEffect(() => {
    if (error) {
      if (error.message.includes('Already existent')) {
        setErrorTitle('Correo ya registrado');
        setContent('Cuenta de Uber ya registrada.');
      } else if (error.message.includes('Phone')) {
        setErrorTitle('Teléfono ya registrado');
        setContent('Teléfono ya registrado.');
      } else {
        setErrorTitle('Error de registro');
        setContent('Error registro con cuenta uber.');
      }
      setOpen(true);
    }
  }, [error]);

  useEffect(() => {
    if (!accessToken) {
      setLoading(true);
      signUpWithUber();
    } else {
      setLoading(false);
      navigate('/thanks', { replace: true });
    }
  }, [accessToken]);

  return (
    <Box>
      <ErrorModal
        open={open}
        onClose={() => {
          setOpen(false);
          navigate('/signup', { replace: true });
        }}
        content={content}
        title={`${errorTitle}`}
      />
      {loading ? <LoadingProgress /> : <Typography>Bienvenido</Typography>}
    </Box>
  );
}

export default SignUpUber;
