import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { useReactiveVar } from '@apollo/client';
import { getAuthStore } from '../../../../graphql/store';

import GraphWeek from '../../../../components/info/GraphWeek';
import Tabs from '../../../../components/ui/Tabs';
import { formatterCLP, formatterKm } from '../../../../helpers/formatters';
import DetailBox from '../../../../components/info/DetailBox';
import RentDetailTable, { separateSeccions } from '../../../../components/info/RentDetailTable';
import { azulTucar } from '../../../../themes/variants/TucarColors';
import arrowTucar from '../../../../../public/images/customIcons/arrow/arrow_blue.svg';
import UberBreakdown from '../../../../components/info/UberBreakdown';

function translateUberCategory(name) {
  switch (name) {
    case 'fare':
      return 'Ingreso viaje Uber';
    case 'promotion':
      return 'Promoción';
    default:
      return 'Otro';
  }
}

const DetailBalance = ({ dateRange, billDays, typeDetail }) => {
  const { data } = useReactiveVar(getAuthStore);
  const [selectDay, setSelectDay] = useState(moment(dateRange[0]).format('DD/MM/YY'));
  const [content, setContent] = useState([]);
  const [specialContent, setSpecialContent] = useState({ tablePrimal: [], tableSecundary: [] });
  const [totalDay, setTotalDay] = useState(0);

  const tabs = {
    expenses: [
      { value: 'rent', label: 'Plan' },
      { value: 'tag', label: 'TAG' },
      { value: 'otherExpenses', label: 'Otros' },
    ],
    incomes: [
      { value: 'uber', label: 'Uber' },
      { value: 'otherIncomes', label: 'Reembolsos' },
    ],
  };

  const [currentTab, setCurrentTab] = useState(typeDetail === 'incomes' ? 'uber' : 'rent');
  const tabComponent = {
    rent: <RentDetailTable content={specialContent} backgroundColor="#FFF" />,
    tag: <DetailBox content={content} typeDetail="TAG" />,
    other: <Box>OTROS</Box>,
    uber: <UberBreakdown content={specialContent} backgroundColor="#FFF" />,
    otherExpenses: <DetailBox content={content} typeDetail="Otros" />,
    otherIncomes: <DetailBox content={content} typeDetail="reembolsos" />,
  };

  useEffect(() => {
    setContent([]);
    if (billDays) {
      const aux = billDays.filter((bill) => moment(bill.date).format('DD/MM/YY') === selectDay);
      if (aux.length > 0) {
        if (typeDetail === 'incomes') {
          setTotalDay(
            aux[0].incomes.beat +
              aux[0].incomes.cabify +
              aux[0].incomes.didi +
              aux[0].incomes.other +
              aux[0].incomes.uber,
          );
        } else {
          setTotalDay(
            aux[0].expenses.tag +
              aux[0].expenses.rent +
              aux[0].expenses.fines +
              aux[0].expenses.guarantee +
              aux[0].expenses.other +
              aux[0].expenses.penaltys,
          );
        }

        if (currentTab === 'uber') {
          const uberTravels = aux[0]?.uber || [];
          const auxContentUber = uberTravels.map((elem) => {
            return {
              title: translateUberCategory(elem.category),
              amount: elem.amount,
              date: elem.datetime,
            };
          });
          setSpecialContent({
            oldContent: auxContentUber,
            total: aux[0]?.incomes?.uber || 0,
            breakdown: aux[0]?.uberBreakdown,
          });
        }
        if (currentTab === 'otherIncomes' && aux[0].events) {
          const auxContentUber = aux[0].events.map((elem) => {
            if (elem.credited.ref === data.uid && elem.type !== 'Guarantee') {
              return {
                title: elem.title,
                amount: elem.amount,
                date: elem.updatedAt,
              };
            }
            return false;
          });
          setContent(auxContentUber.filter((elem) => elem));
        }
        if (currentTab === 'tag' && aux[0].tag) {
          const auxContentTAG = aux[0].tag.map((elem) => {
            return { title: elem.address, amount: elem.amount, date: elem.datetimeWindow };
          });
          setContent(auxContentTAG);
        }
        if (currentTab === 'rent') {
          const costoVariableTotal = separateSeccions(aux[0].traveledSections, {
            currency: aux[0].currency,
            totalKm: aux[0].km,
            discount: aux[0].discount,
          });
          const costoVariableTotalNumber = costoVariableTotal.reduce(
            (acc, curr) => acc + curr.costoKmAll,
            0,
          );
          setSpecialContent({
            totalKm: aux[0].km,
            total: aux[0].expenses.rent,
            basePrice: aux[0].plan.selectedShifts[0].basePrice / 7,
            currency: aux[0].currency,
            tablePrimal: [
              { right: 'KM recorridos', left: formatterKm.format(aux[0].km) },
              { right: 'Valor UF', left: formatterCLP.format(aux[0].currency) },
              {
                right: 'Descuento aplicado',
                left: `${aux[0].discount}%`,
                popText:
                  'Descuento de categoría Uber Pro o por cumplimiento de metas diarias. Siempre se respetará el mayor entre ambos.',
              },
              {
                right: 'Costo base diario',
                left: formatterCLP.format(
                  (aux[0].plan.selectedShifts[0].basePrice / 7) *
                    aux[0].currency *
                    (1 - aux[0].discount / 100),
                ),
              },
              {
                right: 'Costo variable',
                left: formatterCLP.format(costoVariableTotalNumber),
                collapse: costoVariableTotalNumber > 0 ? costoVariableTotal : false,
              },
            ],
          });
        }
        if (currentTab === 'otherExpenses') {
          const auxContentUber = aux[0].events.map((elem) => {
            if (elem.charged.ref === data.uid) {
              return {
                title: elem.title,
                amount: elem.amount,
                date: elem.updatedAt,
              };
            }
            return false;
          });
          setContent(auxContentUber.filter((elem) => elem));
        }
      }
    }
  }, [selectDay, currentTab]);

  useEffect(() => {
    if (dateRange && billDays) {
      setSelectDay(moment(dateRange[0]).format('DD/MM/YY'));
    }
  }, [dateRange, billDays]);

  const handleDay = (day) => {
    setSelectDay(day);
  };

  return (
    <Box>
      <Box sx={{ padding: '0px 20px 20px 20px' }}>
        <GraphWeek
          date={dateRange}
          billDays={billDays}
          typeGraph="interact"
          handleDay={handleDay}
          typeDetail={typeDetail}
        />
      </Box>

      <Box
        sx={{
          paddingInline: '20px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
          <img
            src={arrowTucar}
            alt="category"
            style={{
              width: '20px',
              transform: typeDetail === 'expenses' ? '' : 'rotate(180deg)',
            }}
          />
          <Typography sx={{ fontSize: '14px', fontFamily: 'Poppins-Medium', color: azulTucar }}>
            {typeDetail === 'expenses' ? 'Descuentos' : 'Recaudaciones'} del{' '}
            {moment(selectDay, 'DD/MM/YY').format('DD/MM')}
          </Typography>
        </Box>

        <Typography
          sx={{ fontSize: '14px', fontFamily: 'Poppins-Medium', color: azulTucar }}
          noWrap
        >
          {formatterCLP.format(totalDay)}
        </Typography>
      </Box>

      <Box sx={{ padding: '0px' }}>
        <Tabs
          containerStyle={{ marginBottom: '20px' }}
          currentTabName={currentTab}
          setCurrentTabFunction={setCurrentTab}
          tabs={tabs[typeDetail]}
        />
        <Box>
          <Box sx={{ paddingInline: '20px' }}>{tabComponent[currentTab]}</Box>
        </Box>
      </Box>
    </Box>
  );
};

DetailBalance.propTypes = {
  dateRange: PropTypes.arrayOf(PropTypes.string).isRequired,
  typeDetail: PropTypes.string,
  billDays: PropTypes.arrayOf(PropTypes.shape({})),
};

DetailBalance.defaultProps = {
  typeDetail: 'incomes',
  billDays: [],
};

export default DetailBalance;
