import { gql } from '@apollo/client';

const GET_DRIVER_BILLWEEK_RESUME = {
  query: gql`
    query GetDriverBillDaysParsedByWeek($uid: ID!, $limit: Int, $offset: Int) {
      getDriverBillDaysParsedByWeek(uid: $uid, limit: $limit, offset: $offset) {
        instances {
          initialDatetime
          billDays {
            km
            kmUber
            date
            expenses {
              fines
              penaltys
              guarantee
              rent
              tag
              other
            }
            incomes {
              uber
              didi
              cabify
              beat
              other
            }
            uberBreakdown {
              netFare
              taxes
              toll
              cashCollected
              other
              tips
              adjustment
              promotions
            }
          }
        }
      }
    }
  `,
  policies: {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: 'true',
    errorPolicy: 'all',
  },
};

export default GET_DRIVER_BILLWEEK_RESUME;
