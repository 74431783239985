import React, { useEffect, useState } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import OutboundOutlinedIcon from '@mui/icons-material/OutboundOutlined';
import { useQuery, useReactiveVar } from '@apollo/client';
import { useOutletContext } from 'react-router-dom';
import moment from 'moment';

import { getAuthStore } from '../../../graphql/store';
import TopBar from '../../../components/ui/TopNav';
import TucarElement from '../../../components/info/TucarElement';
import { formatterCLP } from '../../../helpers/formatters';
import { azulTucar } from '../../../themes/variants/TucarColors';
import GET_PAYMENTS from '../../../graphql/querys/balance/getPayments';
import LoadingProgress from '../../../components/ui/LoadingProgress';

import arrowTucar from '../../../../public/images/customIcons/arrow/arrow_blue.svg';
import arrowBlack from '../../../../public/images/customIcons/arrow/arrow.svg';

const Moviments = () => {
  const [handlePageChange] = useOutletContext();

  useEffect(() => {
    handlePageChange(<TopBar title="Movimientos" />, true);
  }, []);

  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);

  const { loading, data } = useQuery(GET_PAYMENTS.query, {
    ...GET_PAYMENTS.policies,
    variables: {
      ref: uid,
      status: 'succeeded',
    },
  });
  const [content, setContent] = useState([]);

  useEffect(() => {
    if (data) {
      const auxData = data.getPayments.instances;
      const auxContent = auxData.map((elem) => {
        return {
          title: elem.title,
          amount: elem.amount,
          positive: elem.payer === 'tucar',
          comment: elem.description,
          date: elem.date ? moment(elem.date).format('DD-MM-YYYY') : '-',
        };
      });
      setContent(auxContent);
    }
  }, [data, loading]);

  if (loading) {
    return <LoadingProgress allScreen={false} heightContainer="100%" />;
  }

  return (
    <Box sx={{ height: '100%' }}>
      {content.length > 0 ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            paddingInline: '20px',
            gap: '10px',
          }}
        >
          <Divider />
          {content.map((mov, index) => {
            const auxPrimaryContent = {
              title: mov.title,
              resume: mov.comment,
              detail: mov.date,
              auxText: mov.positive
                ? `+${formatterCLP.format(mov.amount)}`
                : `-${formatterCLP.format(mov.amount)}`,
            };
            return (
              <TucarElement
                // eslint-disable-next-line react/no-array-index-key
                key={`${mov.title}-${mov.date}-${index}`}
                primaryContent={auxPrimaryContent}
                variant="card"
                IconImg={mov.positive ? arrowTucar : arrowBlack}
                IconCard={OutboundOutlinedIcon}
                iconCardStyle={{
                  transform: !mov.positive ? 'rotate(0deg)' : 'rotate(180deg)',
                  color: mov.positive ? azulTucar : '#333333',
                }}
                primaryContentStyle={{
                  title: {
                    color: mov.positive ? azulTucar : '#333333',
                  },
                  auxText: {
                    color: mov.positive ? azulTucar : '#333333',
                  },
                }}
              />
            );
          })}
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Typography sx={{ fontFamily: 'Poppins-Regular', fontSize: '16px' }}>
            No tienes movimientos registrados
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Moviments;
