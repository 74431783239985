import React from 'react';
import { Box } from '@mui/material';
import LinealOnBoarding from './LinealOnBoarding';
import NoLinealOnBoarding from './NoLinealOnBoarding';
import TermsAndConditionModal from '../../components/info/termsAndCondition';

const OnBoarding = () => {
  const typeOnboarding = 'Nolineal';

  if (typeOnboarding === 'lineal') {
    return (
      <>
        <TermsAndConditionModal />
        <LinealOnBoarding />
      </>
    );
  }
  return (
    <Box
      sx={{
        height: '100vh',
        maxHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <TermsAndConditionModal />
      <NoLinealOnBoarding />
    </Box>
  );
};

export default OnBoarding;
