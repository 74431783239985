import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Collapse,
  Typography,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
} from '@mui/material';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import { azulTucar } from '../../../../../themes/variants/TucarColors';
import bankImage from '../../../../../helpers/bankImage';
import ConfirmModal from '../../../../../components/ui/ConfirmModal';

function selectColor(open, principal) {
  if (principal) {
    return '#0057B81A';
  }
  if (open) return '#FFF';
  return '#F3F3F3';
}

const AccountingInfo = ({ bank, principal, selectionFunction, refetch, removeFunction }) => {
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };

  const maskCreditCard = (card) => {
    const maskCard = card
      .trim()
      .split(' ')
      .map((elem, ident) => {
        if (ident < card.trim().split(' ').length - 1) {
          return '*'.repeat(4);
        }
        return elem;
      })
      .join(' ');
    return maskCard;
  };

  const creditCard = (card) => {
    return card;
  };

  return (
    <Box>
      <ConfirmModal
        open={confirmOpen}
        setOpen={setConfirmOpen}
        title="¿Seguro quieres eliminar esta cuenta bancaria?"
        content="Esta acción no podrá ser revertida una vez realizada"
        handleConfirm={async () => {
          // eslint-disable-next-line no-underscore-dangle
          await removeFunction({ uid: bank._id });
          await refetch();
        }}
      />
      <Box
        sx={{
          borderRadius: '15px',
          backgroundColor: selectColor(open, principal),
          transition: 'background-color 0.8s',
          boxShadow:
            '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        }}
      >
        <Stack
          onClick={handleClick}
          paddingLeft="7px"
          paddingRight="4px"
          borderRadius={open ? '15px 15px 0px 0px' : '15px'}
          direction="row"
          alignItems="center"
          height="45px"
          justifyContent="space-between"
        >
          <Box paddingLeft="10px" display="flex" alignItems="center" gap="5%" width="50%">
            <img src={bankImage(bank.bank)} alt="banco" width="25px" />
            <Typography
              sx={{
                color: '#333333',
                fontSize: '14px',
                fontFamily: 'Poppins-Regular',
                lineHeight: 1,
              }}
            >
              {bank.bank}
            </Typography>
          </Box>

          <Box>
            <Typography
              sx={{
                color: '#333333',
                fontSize: '10px',
                fontFamily: 'Poppins-Regular',
                lineHeight: 1,
              }}
            >
              {open ? creditCard(bank.account) : maskCreditCard(bank.account)}
            </Typography>
          </Box>

          {open ? (
            <IconButton onClick={handleClick}>
              <KeyboardArrowUpOutlinedIcon fontSize="small" sx={{ color: '#333333' }} />
            </IconButton>
          ) : (
            <IconButton onClick={handleClick}>
              <KeyboardArrowDownOutlinedIcon fontSize="small" sx={{ color: '#333333' }} />
            </IconButton>
          )}
        </Stack>

        <Collapse
          sx={{
            borderRadius: open ? '0px 0px 7px 7px' : '7px',
          }}
          in={open}
          timeout="auto"
          unmountOnExit
        >
          <Box margin="10px 20px 0px 20px" sx={{ overflowX: 'scroll' }}>
            <Table size="small">
              <TableBody>
                <TableRow colSpan={2}>
                  <TableCell
                    size="small"
                    sx={{ borderColor: 'white', fontFamily: 'Poppins-Light', lineHeight: 1 }}
                    align="left"
                  >
                    Nombre del Titular
                  </TableCell>
                  <TableCell
                    size="small"
                    sx={{ borderColor: 'white', fontFamily: 'Poppins-Light', lineHeight: 1 }}
                    align="right"
                  >
                    {bank.name}
                  </TableCell>
                </TableRow>
                <TableRow colSpan={2}>
                  <TableCell
                    size="small"
                    sx={{ borderColor: 'white', fontFamily: 'Poppins-Light', lineHeight: 1 }}
                    align="left"
                  >
                    Rut del titular
                  </TableCell>
                  <TableCell
                    size="small"
                    sx={{ borderColor: 'white', fontFamily: 'Poppins-Light', lineHeight: 1 }}
                    align="right"
                  >
                    {bank.rut}
                  </TableCell>
                </TableRow>
                <TableRow colSpan={2}>
                  <TableCell
                    size="small"
                    sx={{ borderColor: 'white', fontFamily: 'Poppins-Light', lineHeight: 1 }}
                    align="left"
                  >
                    Tipo de cuenta
                  </TableCell>
                  <TableCell
                    size="small"
                    sx={{ borderColor: 'white', fontFamily: 'Poppins-Light', lineHeight: 1 }}
                    align="right"
                  >
                    {bank.accountType}
                  </TableCell>
                </TableRow>
                <TableRow colSpan={2}>
                  <TableCell
                    size="small"
                    sx={{
                      border: '0px',
                      fontFamily: 'Poppins-Light',
                      lineHeight: 1,
                    }}
                    align="left"
                  >
                    Correo electrónico
                  </TableCell>
                  <TableCell
                    size="small"
                    sx={{
                      border: '0px',
                      fontFamily: 'Poppins-Light',
                      lineHeight: 1,
                    }}
                    align="right"
                  >
                    {bank.email}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
          <Box margin="10px 20px 0px 20px" display="flex" justifyContent="space-around">
            <Button
              fullWidth
              disabled={principal}
              type="submit"
              sx={{
                backgroundColor: azulTucar,
                marginTop: '10px',
                marginBottom: '20px',
                height: '40px',
                lineHeight: 1,
                boxShadow: '0',
                '&:disabled': {
                  boxShadow: '0',
                  backgroundColor: '#0057B833',
                  color: '#FFF',
                },
                '&:hover': {
                  boxShadow: '0',
                },
              }}
              variant="contained"
              onClick={async () => {
                // eslint-disable-next-line no-underscore-dangle
                await selectionFunction({ uid: bank._id });
                await refetch();
              }}
            >
              Cuenta principal
            </Button>
            {!principal ? (
              <>
                <Box marginX="10px" />
                <Button
                  fullWidth
                  type="submit"
                  sx={{
                    backgroundColor: open ? '#F3F3F3' : '#FFF',
                    transition: 'background-color 0.8s',
                    marginTop: '10px',
                    marginBottom: '20px',
                    height: '40px',
                    color: '#333333',
                    boxShadow: '0',
                    '&:hover': {
                      boxShadow: '0',
                      backgroundColor: '#F3F3F3',
                    },
                  }}
                  variant="contained"
                  onClick={() => {
                    setConfirmOpen(true);
                  }}
                >
                  Eliminar
                </Button>
              </>
            ) : null}
          </Box>
        </Collapse>
      </Box>
    </Box>
  );
};

AccountingInfo.propTypes = {
  bank: PropTypes.shape({
    _id: PropTypes.string,
    email: PropTypes.string,
    accountType: PropTypes.string,
    rut: PropTypes.string,
    name: PropTypes.string,
    account: PropTypes.string,
    bank: PropTypes.string,
  }).isRequired,
  principal: PropTypes.bool,
  selectionFunction: PropTypes.func,
  refetch: PropTypes.func,
  removeFunction: PropTypes.func,
};

AccountingInfo.defaultProps = {
  principal: false,
  selectionFunction: () => {},
  refetch: () => {},
  removeFunction: () => {},
};

export default AccountingInfo;
