import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { TableCell, TableRow, Collapse } from '@mui/material';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';

import { formatterCLP } from '../../../../../helpers/formatters';

import stylesTable from '../../style';

const UberBreakdownTable = ({ billDays }) => {
  const [open, setOpen] = useState(true);

  const totalBreakdown = billDays.reduce(
    (acc, elem) => {
      const { netFare, taxes, promotions, adjustment, tips, toll, other, cashCollected } =
        elem.uberBreakdown;
      return {
        netFare: netFare + acc.netFare,
        taxes: taxes + acc.taxes,
        promotions: promotions + acc.promotions,
        adjustment: adjustment + acc.adjustment,
        tips: tips + acc.tips,
        toll: toll + acc.toll,
        other: other + acc.other,
        cashCollected: cashCollected + acc.cashCollected,
      };
    },
    {
      netFare: 0,
      taxes: 0,
      promotions: 0,
      adjustment: 0,
      tips: 0,
      toll: 0,
      other: 0,
      cashCollected: 0,
    },
  );

  return (
    <>
      <TableRow onClick={() => setOpen(!open)}>
        <TableCell
          sx={{
            position: 'relative',
            ...stylesTable.headerColumn,
            ...stylesTable.stickyHeader,
            padding: '10px 20px',
          }}
        >
          Recaudaciones en Uber
          <ChevronRightRoundedIcon
            sx={{
              ...stylesTable.iconArrow,
              right: '5px',
              transform: open ? 'translateY(-50%) rotate(90deg)' : 'translateY(-50%) rotate(0deg)',
              transition: 'transform 0.3s ease',
            }}
          />
        </TableCell>
        {billDays.map((_, index) => {
          const key = `uber-${index}`;
          return <TableCell key={key} />;
        })}
        <TableCell sx={{ ...stylesTable.totalCell }} />
      </TableRow>

      <TableRow>
        <TableCell
          sx={{
            ...stylesTable.headerText,
            ...stylesTable.stickyHeader,
          }}
        >
          <Collapse sx={stylesTable.collapseTitle} in={open} timeout="auto" unmountOnExit>
            Ganancias
          </Collapse>
        </TableCell>
        {billDays.map((elem, index) => {
          const key = `Ganancias-${index}`;
          const { netFare, taxes } = elem.uberBreakdown;
          return (
            <TableCell key={key} align="center" sx={{ ...stylesTable.positiveText }}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                +{formatterCLP.format(netFare + taxes)}
              </Collapse>
            </TableCell>
          );
        })}
        <TableCell align="center" sx={{ ...stylesTable.positiveText, ...stylesTable.totalCell }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            +{formatterCLP.format(totalBreakdown.netFare + totalBreakdown.taxes)}
          </Collapse>
        </TableCell>
      </TableRow>

      <TableRow sx={{ backgroundColor: '#E3EDF7' }}>
        <TableCell
          sx={{
            ...stylesTable.headerText,
            ...stylesTable.stickyHeader,
          }}
        >
          <Collapse sx={stylesTable.collapseTitle} in={open} timeout="auto" unmountOnExit>
            Promociones
          </Collapse>
        </TableCell>
        {billDays.map((elem, index) => {
          const { promotions } = elem.uberBreakdown;
          const key = `Promociones-${index}`;
          return (
            <TableCell key={key} align="center" sx={{ ...stylesTable.regularText }}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                +{formatterCLP.format(promotions)}
              </Collapse>
            </TableCell>
          );
        })}
        <TableCell align="center" sx={{ ...stylesTable.regularText, ...stylesTable.totalCell }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            +{formatterCLP.format(totalBreakdown.promotions)}
          </Collapse>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell sx={{ ...stylesTable.headerText, ...stylesTable.stickyHeader }}>
          <Collapse sx={stylesTable.collapseTitle} in={open} timeout="auto" unmountOnExit>
            Otras ganancias
          </Collapse>
        </TableCell>
        {billDays.map((elem, index) => {
          const { adjustment } = elem.uberBreakdown;
          const key = `Reajustes-${index}`;
          return (
            <TableCell key={key} align="center" sx={{ ...stylesTable.regularText }}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                +{formatterCLP.format(adjustment)}
              </Collapse>
            </TableCell>
          );
        })}
        <TableCell align="center" sx={{ ...stylesTable.regularText, ...stylesTable.totalCell }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            +{formatterCLP.format(totalBreakdown.adjustment)}
          </Collapse>
        </TableCell>
      </TableRow>

      <TableRow sx={{ backgroundColor: '#E3EDF7' }}>
        <TableCell sx={{ ...stylesTable.headerText, ...stylesTable.stickyHeader }}>
          <Collapse sx={stylesTable.collapseTitle} in={open} timeout="auto" unmountOnExit>
            Propina
          </Collapse>
        </TableCell>
        {billDays.map((elem, index) => {
          const { tips } = elem.uberBreakdown;
          const key = `Propinas-${index}`;
          return (
            <TableCell key={key} align="center" sx={{ ...stylesTable.regularText }}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                +{formatterCLP.format(tips)}
              </Collapse>
            </TableCell>
          );
        })}
        <TableCell align="center" sx={{ ...stylesTable.regularText, ...stylesTable.totalCell }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            +{formatterCLP.format(totalBreakdown.tips)}
          </Collapse>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell
          sx={{
            ...stylesTable.headerText,
            ...stylesTable.stickyHeader,
          }}
        >
          <Collapse sx={stylesTable.collapseTitle} in={open} timeout="auto" unmountOnExit>
            Reembolsos
          </Collapse>
        </TableCell>
        {billDays.map((elem, index) => {
          const key = `Reembolsos-${index}`;
          const { toll, other } = elem.uberBreakdown;
          return (
            <TableCell key={key} align="center" sx={{ ...stylesTable.positiveText }}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                +{formatterCLP.format(toll + other)}
              </Collapse>
            </TableCell>
          );
        })}
        <TableCell align="center" sx={{ ...stylesTable.positiveText, ...stylesTable.totalCell }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            +{formatterCLP.format(totalBreakdown.toll + totalBreakdown.other)}
          </Collapse>
        </TableCell>
      </TableRow>

      <TableRow sx={{ backgroundColor: '#E3EDF7' }}>
        <TableCell sx={{ ...stylesTable.headerText, ...stylesTable.stickyHeader }}>
          <Collapse sx={stylesTable.collapseTitle} in={open} timeout="auto" unmountOnExit>
            Efectivo recibido
          </Collapse>
        </TableCell>
        {billDays.map((elem, index) => {
          const key = `EfectivoCobrado-${index}`;
          const { cashCollected } = elem.uberBreakdown;
          return (
            <TableCell key={key} align="center" sx={{ ...stylesTable.negativeText }}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                -{formatterCLP.format(cashCollected)}
              </Collapse>
            </TableCell>
          );
        })}
        <TableCell align="center" sx={{ ...stylesTable.negativeText, ...stylesTable.totalCell }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            -{formatterCLP.format(totalBreakdown.cashCollected)}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

UberBreakdownTable.propTypes = {
  billDays: PropTypes.arrayOf(PropTypes.shape({})),
};

UberBreakdownTable.defaultProps = {
  billDays: [],
};

export default UberBreakdownTable;
