import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { formatterCLP } from '../../../../../helpers/formatters';

const GraphText = ({ amount, positive, msg }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <Typography
        sx={{
          color: positive ? '#2189FF' : '#FF593F',
          fontSize: '36px',
          fontFamily: 'Poppins-SemiBold',
          lineHeight: 1.2,
        }}
      >
        {formatterCLP.format(Math.abs(amount))}
      </Typography>
      <Typography
        sx={{
          textAlign: 'center',
          color: '#2D2F39',
          fontFamily: 'Poppins-Regular',
          fontSize: '14px',
          lineHeight: 1.2,
        }}
      >
        {msg}
      </Typography>
    </Box>
  );
};

GraphText.propTypes = {
  amount: PropTypes.number,
  positive: PropTypes.bool,
  msg: PropTypes.string,
};

GraphText.defaultProps = {
  amount: 0,
  positive: true,
  msg: '',
};

export default GraphText;
