import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Typography } from '@tucar/pit-stop';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { formatterCLP } from '../../../../../helpers/formatters';

const DetailCard = ({ title, total, mode, remark }) => {
  const switchColorMode = (modeString) => {
    switch (modeString) {
      case 'incomes':
        return '#7FB418';
      case 'outcomes':
        return '#FF7B66';
      case 'cuotas':
        return '#0496FF';
      default:
        return '#7FB418';
    }
  };
  const switchBackgroundColor = (modeString) => {
    switch (modeString) {
      case 'incomes':
        return 'rgba(127, 180, 24, 0.30)';
      case 'outcomes':
        return 'rgba(255, 159, 143, 0.30)';
      case 'cuotas':
        return 'rgba(169, 219, 255, 0.30)';
      default:
        return 'rgba(169, 219, 255, 0.30)';
    }
  };

  if (remark) {
    return (
      <Box
        sx={{
          borderRadius: '10px',
          display: 'flex',
          backgroundColor: switchColorMode(mode),
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: 'row',
          padding: '14px 10px',
        }}
      >
        <Typography
          style={{ color: 'rgba(255, 255, 255, 0.88)', fontSize: '16px', fontWeight: 600 }}
        >
          {title}
        </Typography>

        <Typography
          style={{ color: 'rgba(255, 255, 255, 0.88)', fontSize: '16px', fontWeight: 600 }}
        >
          {formatterCLP.format(total)}
        </Typography>
      </Box>
    );
  }

  if (total === 0) {
    return null;
  }

  return (
    <Box
      sx={{
        borderRadius: '10px',

        display: 'flex',
        backgroundColor: switchBackgroundColor(mode),
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
        padding: '14px 10px',
      }}
    >
      <Typography style={{ color: '#474747', fontSize: '14px', fontWeight: 500 }}>
        {title}
      </Typography>
      <Box
        sx={{
          padding: '6px 10px',
          display: 'flex',
          flexDirection: 'row',
          gap: '10px',
          background: '#FFF',
          alignItems: 'center',
          borderRadius: '10px',
        }}
      >
        <TrendingUpIcon sx={{ color: switchColorMode(mode), fontSize: '17px' }} />
        <Typography style={{ color: switchColorMode(mode), fontSize: '17px' }}>
          {formatterCLP.format(total)}
        </Typography>
      </Box>
    </Box>
  );
};

DetailCard.propTypes = {
  title: PropTypes.string,
  total: PropTypes.number,
  mode: PropTypes.string,
  remark: PropTypes.bool,
};

DetailCard.defaultProps = {
  title: 'Ganancias por Uber',
  total: 0,
  mode: 'incomes',
  remark: false,
};

export default DetailCard;
