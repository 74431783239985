import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Loader } from '@tucar/pit-stop';
import { useOutletContext } from 'react-router-dom';
import { useQuery, useReactiveVar } from '@apollo/client';
import MapFilterSlide from './mapfilterSlide';
import ChargersMap from './map';
import DropdownManager from '../../../components/ui/DropdownManager';
import GET_CAR_PLUG_TYPES from '../../../graphql/querys/map/getCarPlugTypes';
import { getDriverStore } from '../../../graphql/store';

const Chargers = () => {
  const [handlePageChange] = useOutletContext();
  const [open, setOpen] = useState(false);
  const [selectedParams, setSelectedParams] = useState({});
  const { rent } = useReactiveVar(getDriverStore);
  const [plugTypes, setPlugTypes] = useState([]);

  const { loading, data } = useQuery(GET_CAR_PLUG_TYPES.query, {
    ...GET_CAR_PLUG_TYPES.policies,
    variables: {
      carId: rent?.carId,
      skip: !rent?.carId,
    },
  });

  const handleAccept = (status) => {
    if (status === 'AC') setSelectedParams({ plugTypes, currentType: 'AC' });
    if (status === 'DC') setSelectedParams({ plugTypes, currentType: 'DC' });
    setOpen(false);
  };

  useEffect(() => {
    handlePageChange(false, true);
  }, []);

  useEffect(() => {
    if (!loading && data && data?.getCarConfigPlugTypes) {
      setPlugTypes(data?.getCarConfigPlugTypes);
      setSelectedParams({ ...selectedParams, plugTypes: data?.getCarConfigPlugTypes });
    }
  }, [loading, data]);

  if (loading)
    return (
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Loader size={32} pointRadio="4px" />
      </Box>
    );

  return (
    <Box sx={{ position: 'relative', overflow: 'hidden', height: 'calc(100vh - 60px)' }}>
      <MapFilterSlide
        open={open}
        onClose={() => setOpen(false)}
        statusProp="all"
        onAccept={handleAccept}
        onClear={() => {
          setSelectedParams({ plugTypes });
          setOpen(false);
        }}
      />

      <Box
        sx={{
          position: 'absolute',
          top: '50px',
          right: '50%',
          transform: 'translateX(50%)',
          zIndex: 2,
          width: '90%',
        }}
      >
        <DropdownManager setFilter={() => setOpen(true)} />
      </Box>
      <ChargersMap params={selectedParams} />
    </Box>
  );
};

export default Chargers;
