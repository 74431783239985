import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Loader, Button } from '@tucar/pit-stop';

import { useReactiveVar, useLazyQuery } from '@apollo/client';
import { useOutletContext } from 'react-router-dom';

import { getAuthStore } from '../../../../graphql/store';
import GET_EVENTS_V2 from '../../../../graphql/querys/events/getEventsV2';
import TopNav2 from '../../../../components/ui/TopNav/newTopNav';
import EmptyView from '../../../../components/extras/EmptyView';
import EventCard from '../../balance/NewVersion/eventCards/EventCard';
import getUniqueKey from '../../../../helpers/customKey';
import EventDetails from '../../balance/NewVersion/eventCards/EventDetails';

const ExchangeEvents = () => {
  const [handlePageChange] = useOutletContext();
  const [eventList, setEventList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [showDetails, setShowDetails] = useState(null);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);

  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);

  const [getExchangeEvents, { data, loading }] = useLazyQuery(GET_EVENTS_V2.query, {
    ...GET_EVENTS_V2.policies,
    variables: {
      userId: uid,
      page,
      limit: 20,
      subject: 'Exchange',
      status: 'Approved',
    },
  });

  useEffect(() => {
    handlePageChange(null, true);
    getExchangeEvents();
  }, []);

  useEffect(() => {
    if (!loading && data) {
      const reformatData = data.getEventsByUser.data.map((event) => {
        return { ...event, car: { _id: event.car } };
      });
      setTotal(data.getEventsByUser.metadata.total_docuements);
      setEventList([...eventList, ...reformatData]);
      setFilteredList([
        ...filteredList,
        ...reformatData.filter((event) => event.credited.ref !== uid),
      ]);
    }
  }, [data, loading]);

  useEffect(() => {
    if (page > 0 && total < eventList.length) {
      getExchangeEvents({ page });
    }
  }, [page]);

  if (loading) {
    return (
      <Box>
        <TopNav2 title="Sanciones por Tucar" />
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Loader size={32} pointRadio="4px" />
        </Box>
      </Box>
    );
  }

  if (filteredList.length === 0) {
    return (
      <Box>
        <TopNav2 title="Sanciones por Tucar" />
        <EmptyView />
      </Box>
    );
  }

  if (showDetails) {
    return (
      <EventDetails
        prevPage="/app/v2/exchange-events"
        data={showDetails}
        setShowDetails={setShowDetails}
      />
    );
  }

  return (
    <Box sx={{ marginBottom: '60px' }}>
      <TopNav2 title="Sanciones por Tucar" />
      <Box
        sx={{
          padding: '0px 30px 30px 30px',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
        }}
      >
        {filteredList.map((eventInfo) => (
          <EventCard key={getUniqueKey()} eventInfo={eventInfo} setShowDetails={setShowDetails} />
        ))}
      </Box>
      {total > eventList.length && (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button
            onClick={() => {
              setPage(page + 1);
            }}
          >
            Cargar más
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default ExchangeEvents;
