import React from 'react';
import { Box } from '@mui/material';
import { Card, Typography } from '@tucar/pit-stop';
import gearImg from '../../../../../public/images/bubbleIconsPng/gear.png';
import { azulTucar } from '../../../../themes/variants/TucarColors';

const WhatItIsCard = () => {
  return (
    <Card style={{ padding: '18px', display: 'flex', flexDirection: 'row', gap: '12px' }}>
      <img style={{ height: '93px', width: '82px' }} src={gearImg} alt="gear-img" />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
        <Typography
          style={{ fontWeight: 600, fontSize: '16px', lineHeight: 1.2, color: azulTucar }}
        >
          ¿Para qué sirve?
        </Typography>
        <Typography
          style={{ fontSize: '13px', fontWeight: 400, lineHeight: 1.2, color: '#2D2F39' }}
        >
          Controla tu disponibilidad para aceptar pagos en efectivo en tus viajes. Esta función te
          ayuda a gestionar tu seguridad y cumplir con las políticas de Tucar.
        </Typography>
      </Box>
    </Card>
  );
};

export default WhatItIsCard;
