import { gql, useReactiveVar } from '@apollo/client';
import { setVerifiedList, getDriverStore } from '../../store';

const ADD_BANK = {
  mutation: gql`
    mutation Mutation(
      $uid: String!
      $accountType: String!
      $account: String!
      $bank: String!
      $email: String!
      $name: String!
      $rol: String!
      $rut: String!
    ) {
      createBankAccount(
        uid: $uid
        accountType: $accountType
        account: $account
        bank: $bank
        email: $email
        name: $name
        rol: $rol
        rut: $rut
      ) {
        _id
      }
    }
  `,
  policies: {
    onCompleted: (response) => {
      if (response.createBankAccount) {
        const { verifiedList } = useReactiveVar(getDriverStore);
        setVerifiedList({ ...verifiedList, bank: true });
      }
    },
    errorPolicie: 'ignore',
  },
};

export default ADD_BANK;
