import { gql } from '@apollo/client';

const GET_DRIVER_BILLDAY_LIMIT = {
  query: gql`
    query GetDriverBillDaysLimitDates(
      $uid: ID!
      $initialDatetime: String
      $finalDatetime: String
      $labelId: ID!
      $carId: ID!
      $skipCarStatus: Boolean!
    ) {
      paymentArray: getDriverPayment(driverId: $uid) {
        settledCollection
        amount
      }
      carStatus: getCarTelemetryStatus(carId: $carId) @skip(if: $skipCarStatus) {
        locked
      }
      getLabel(_id: $labelId) @skip(if: $skipCarStatus) {
        elements
      }
      getDriverBillDaysLimitDates(
        uid: $uid
        initialDatetime: $initialDatetime
        finalDatetime: $finalDatetime
      ) {
        date
        km
        kmUber
        incomes {
          uber
          other
        }
        expenses {
          fines
          penaltys
          guaranteePolicy
          guarantee
          rent
          tag
          other
        }
        uberBreakdown {
          promotions
          adjustment
          tips
          other
          cashCollected
          toll
          taxes
          netFare
        }
      }
    }
  `,
  policies: {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: 'true',
    errorPolicy: 'ignore',
  },
};

export default GET_DRIVER_BILLDAY_LIMIT;
