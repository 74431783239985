import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { verdeText } from '../../../../../themes/variants/TucarColors';
import openCar from '../../../../../../public/images/shared-form/open-car.svg';
import closedCar from '../../../../../../public/images/shared-form/closed-car.svg';

const DoorsClosed = ({ color, optional }) => {
  return (
    <Box
      sx={{
        height: '100%',
        minHeight: '100%',
        width: '100%',
        display: 'grid',
        gridTemplateRows: 'max-content 1fr',
      }}
    >
      <Box
        sx={{
          height: 'max-content',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
        }}
      >
        <Typography
          sx={{
            fontSize: '1rem',
            textAlign: 'left',
            fontFamily: 'Poppins-Medium',
            color,
          }}
        >
          {`${color !== verdeText ? 'Cerrar' : 'Abrir'} auto ${optional ? '(Opcional)' : ''}`}
        </Typography>
        <Typography sx={{ fontSize: '14px', textAlign: 'left', fontFamily: 'Poppins-Regular' }}>
          Ahora vamos a{' '}
          {color !== verdeText
            ? 'cerrar el auto, recuerda retirar tus pertenencias.'
            : 'abrir el auto para que puedas iniciar tu arriendo.'}
        </Typography>
      </Box>
      <Box
        sx={{
          height: '100%',
          minHeight: '100%',
          borderRadius: '55px',
          backgroundImage: `url(${color === verdeText ? openCar : closedCar})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      />
    </Box>
  );
};

DoorsClosed.propTypes = {
  optional: PropTypes.bool,
  color: PropTypes.string,
};

DoorsClosed.defaultProps = {
  optional: false,
  color: verdeText,
};
export default DoorsClosed;
