import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Typography, Loader } from '@tucar/pit-stop';

import { Box } from '@mui/material';
import { useQuery, useReactiveVar } from '@apollo/client';
import { getAuthStore } from '../../../../../graphql/store';
import GET_DOCUMENTS from '../../../../../graphql/querys/docs/getDocuments';
import DocBox from '../../../../../components/info/DocBox';

const Documents = ({ setMessageError, setOpenError }) => {
  const [content, setContent] = useState([]);
  const [change, setChange] = useState(-1);

  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);

  const {
    data: docsData,
    refetch,
    loading,
  } = useQuery(GET_DOCUMENTS.query, {
    variables: {
      uid,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      if (content.length > 0 && change !== -1) {
        content[change].loadingRefetch = false;
      }
    },
  });

  const handleUpload = (file, name, ident) => {
    content[ident].loadingRefetch = true;
    setChange(ident);
    const data = new FormData();
    data.append('files', file, `${name}.${file.name.split('.').pop()}`);
    data.append('id', uid);
    const config = {
      method: 'post',
      url: `${process.env.DOCUMENT_GATEWAY_URL}/api/v1/cl/document/attach`,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Apikey ${process.env.DOCUMENT_GATEWAY_API_KEY}`,
      },
      data,
    };
    axios(config)
      .then(() => {
        refetch();
      })
      .catch((error) => {
        setMessageError(error.message);
        setOpenError(true);
      });
  };

  useEffect(() => {
    if (docsData && docsData.getDocument && content.length === 0) {
      const aux = docsData.getDocument.files.map((elem, ident) => {
        const auxObj = { ...elem };
        // eslint-disable-next-line no-param-reassign
        auxObj.fileType = 'application/pdf, image/*';
        auxObj.loadingRefetch = false;
        auxObj.id = ident;
        return auxObj;
      });
      setContent(aux);
    }
  }, [loading, docsData]);

  if (loading)
    return (
      <Box
        sx={{
          zIndex: 1,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Loader size={32} pointRadio="4x" />
      </Box>
    );

  if (content.length === 0) {
    return (
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
        }}
      >
        <Typography align="center">No tienes documentos para mostrar</Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
        paddingInline: '20px',
      }}
    >
      {content.map((doc) => (
        <DocBox key={doc.name} fileObj={doc} handleUpload={handleUpload} />
      ))}
    </Box>
  );
};

Documents.propTypes = {
  setMessageError: PropTypes.func.isRequired,
  setOpenError: PropTypes.func.isRequired,
};

export default Documents;
