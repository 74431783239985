import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Box, Typography } from '@mui/material';

import VideoModal from '../../../components/extras/VideoModal';

import PlanItemInfo from './auxComponents/PlanItemInfo';
import getPlanDisponibilityHours from '../../../helpers/getPlanDisponibilityHours';
import selectColorMode from './auxComponents/selectColorMode';

const PlanItemFunction = ({ plan, mode, currency, selectPlan, discount }) => {
  const [openVideo, setOpenVideo] = useState(false);
  const disabled = mode === 'combustion-exclusive';
  return (
    <Box
      key={mode}
      sx={{
        display: 'flex',
        padding: '20px',
        flexDirection: 'column',
        justifyContent: 'center',
        borderRadius: '20px',
      }}
    >
      <VideoModal
        key="videoElectric"
        srcVideo="https://www.youtube.com/embed/0ccDYwHXQKI"
        title="¿Cómo funciona?"
        open={openVideo}
        handleClose={() => {
          setOpenVideo(false);
        }}
      />
      <Box
        sx={{
          boxShadow: '0px 0px 15px #ccc',
          padding: '20px',
          borderRadius: '20px',
        }}
      >
        <Box sx={{ width: '100%', paddingInline: '0px', display: 'flex', justifyContent: 'right' }}>
          <Box
            sx={{
              visibility: discount ? 'visible' : 'hidden',
              backgroundColor: !disabled ? selectColorMode(mode) : '#ccc',
              width: '55%',
              padding: '5px 0px 5px 0px',
              transform: 'translate(20px,-20px)',
              borderRadius: '0px 20px 0px 0px',
            }}
          >
            <Typography
              sx={{
                textAlign: 'center',
                fontFamily: 'Poppins-Regular',
                fontSize: '12px',
                color: '#fff',
                verticalAlign: 'center',
              }}
            >
              {discount}% de dcto
            </Typography>
          </Box>
        </Box>
        <PlanItemInfo
          disableElement={disabled}
          // eslint-disable-next-line no-underscore-dangle
          planId={plan._id}
          discount={discount}
          planIncludes={plan.includes}
          planExcludes={plan.excludes}
          name={plan.name}
          // eslint-disable-next-line no-underscore-dangle
          shiftId={!mode.includes('shared') ? plan.shifts[0]._id : null}
          mode={mode}
          currency={currency}
          disponibilityHours={getPlanDisponibilityHours(
            plan.shifts[0].startTime,
            plan.shifts[0].endTime,
          )}
          basePrice={plan.shifts[0].basePrice}
          handleButton={selectPlan}
          sections={plan.sections}
        />
      </Box>
    </Box>
  );
};

PlanItemFunction.propTypes = {
  plan: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    includes: PropTypes.arrayOf(PropTypes.string),
    excludes: PropTypes.arrayOf(PropTypes.string),
    sections: PropTypes.arrayOf(PropTypes.shape({})),
    shifts: PropTypes.arrayOf(
      PropTypes.shape({
        basePrice: PropTypes.number,
        _id: PropTypes.string,
        startTime: PropTypes.string,
        endTime: PropTypes.string,
      }),
    ),
  }),
  discount: PropTypes.number,
  mode: PropTypes.string,
  currency: PropTypes.number,
  selectPlan: PropTypes.func,
};

PlanItemFunction.defaultProps = {
  discount: 0,
  plan: {
    _id: '',
    name: '',
    includes: [],
    excludes: [],
    sections: [],
    shifts: [{ basePrice: 0, _id: '', startTime: '00:00', endTime: '23:59' }],
  },
  mode: '',
  currency: 0,
  selectPlan: () => {},
};

export default PlanItemFunction;
