import React from 'react';
import { styled } from '@mui/material/styles';
import { Tabs, Tab } from '@mui/material';

export const CssTabs = styled((props) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
))({
  zIndex: 4,
  '& .MuiTabs-flexContainer': {
    backgroundColor: '#FFF',
    display: 'flex',
    justifyContent: 'space-around',
  },
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    backgroundColor: '#0057b8',
    height: '2px',
  },
});

export const CssTab = styled((props) => <Tab disableRipple {...props} />)({
  fontSize: '12px',
  opacity: 0.3,
  '&.MuiButtonBase-root': {
    padding: '0px 15px',
    color: '#5c5c5c',
  },
  '&.Mui-selected': {
    opacity: 1,
  },
  '& .MuiTab-iconWrapper': {
    marginRight: '15px',
  },
});
